<template>
    <portal to="sidebar">
        <Sidebar>
            <div>
                <div class="fade-bottom">
                    <img
                        :src="sampleGroup.main_image.url"
                        class="h-64 w-full object-cover"
                        alt=""
                    />
                </div>
                <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                    <div class="flex items-center justify-between mb-8">
                        <h1 class="text-xl mb-0">{{ sampleGroup.heading }}</h1>
                        <button
                            v-if="!hasZoomed"
                            @click="zoom"
                            class="text-cobalt-500 font-semibold px-4 py-1 bg-cobalt-50 rounded flex gap-2 items-center"
                        >
                            <FeatherWrapper icon="zoom-in" />
                            <span>Zoom</span>
                        </button>
                        <button
                            v-else
                            @click="resetZoom"
                            class="text-cobalt-500 font-semibold px-4 py-1 bg-cobalt-50 rounded flex gap-2 items-center"
                        >
                            <FeatherWrapper icon="zoom-out" />
                            <span>Reset</span>
                        </button>
                    </div>
                    <ClosestSample
                        :closest-sample="closestSampleInfo"
                        @zoom="zoomToGroup(mapRef, closestSampleInfo)"
                        @unzoom="resetZoom"
                    />
                    <div v-html="sampleGroup.text" />
                </div>
            </div>
        </Sidebar>
    </portal>

    <MapLegend
        :items="[
            { name: 'Closest sample site', colour: 'hsl(141,96%,37%)', type: 'site' },
            {
                name: `Other ${sampleGroup.heading} sites`,
                colour: getClassicalColour('roman'),
                type: 'site',
            },
            {
                name: `${sampleGroup.heading} geographical area`,
                colour: getClassicalColour('roman'),
                type: 'main-area',
            },
            {
                name: `Roman Empire`,
                colour: getClassicalColour('roman'),
                type: 'area',
            },
            {
                name: `Other roman sites`,
                colour: '#ccc',
                type: 'site',
            },
        ]"
    />
    <BaseMap
        v-if="showMap && mode === 'map'"
        type="satellite"
        class="h-full"
        @map-loaded="onMapLoaded"
    />

    <MobileContent :title="'Roman map'" remove-padding>
        <div>
            <div class="fade-bottom">
                <img :src="sampleGroup.main_image?.url" class="h-64 w-full object-cover" alt="" />
            </div>
            <div class="py-6 px-4 sm:px-6 lg:px-8 prose mx-auto">
                <div class="flex items-center justify-between mb-8">
                    <h1 class="text-xl mb-0">{{ sampleGroup.heading }}</h1>
                    <button
                        v-if="!hasZoomed"
                        @click="zoom"
                        class="text-cobalt-500 font-semibold px-4 py-1 bg-cobalt-50 rounded flex gap-2 items-center"
                    >
                        <FeatherWrapper icon="zoom-in" />
                        <span>Zoom</span>
                    </button>
                    <button
                        v-else
                        @click="resetZoom"
                        class="text-cobalt-500 font-semibold px-4 py-1 bg-cobalt-50 rounded flex gap-2 items-center"
                    >
                        <FeatherWrapper icon="zoom-out" />
                        <span>Reset</span>
                    </button>
                </div>

                <div v-html="sampleGroup.text" />
            </div>
        </div>
    </MobileContent>
</template>

<script setup>
import { inject, ref } from "vue";
import BaseMap from "@/components/BaseMap.vue";
import Sidebar from "@/components/Sidebar.vue";
import {
    attachClassicalShapesToMap,
    attachClassicalSitesToMap,
    zoomToGroup,
} from "@/views/ancestry/recent/mapHelpers";
import MobileContent from "@/components/ancestry/MobileContent.vue";
import ClosestSample from "@/components/Classical/ClosestSample.vue";
import MapLegend from "@/components/MapLegend.vue";
import { getClassicalColour } from "@/composables/useClassicalContent";

const props = defineProps({
    introduction: Object,
    sampleGroup: Object,
    sampleSites: Object,
});

const showMap = ref(false);
const mode = ref("map");
const mapRef = ref({});
const hasZoomed = ref(false);
const result = inject("classical-result");
const closestSampleInfo = ref(null);

setTimeout(() => {
    showMap.value = true;
});

function zoom() {
    hasZoomed.value = true;
    const [sampleGroupFeature] = JSON.parse(props.sampleGroup.plain_text).features;
    zoomToGroup(mapRef.value, sampleGroupFeature);
}

function resetZoom() {
    hasZoomed.value = false;
    const [feature] = JSON.parse(props.introduction.plain_text).features;
    zoomToGroup(mapRef.value, feature);
}

function onMapLoaded(map) {
    mapRef.value = map;

    const sites = JSON.parse(props.sampleSites.plain_text).features;
    const [feature] = JSON.parse(props.introduction.plain_text).features;
    const [sampleGroupFeature] = JSON.parse(props.sampleGroup.plain_text).features;

    attachClassicalShapesToMap(map, {
        people: "roman",
        feature,
        type: "main",
    });

    attachClassicalShapesToMap(map, {
        people: "roman",
        feature: sampleGroupFeature,
        type: "sampleGroup",
    });

    zoomToGroup(map, feature);

    const siteInfo = attachClassicalSitesToMap(map, {
        sites,
        people: "roman",
        closestSample: result.value.romans.closest_sample,
    });

    closestSampleInfo.value = siteInfo.pop();
}
</script>
