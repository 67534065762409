<template>
    <div>
        <div
            class="flex items-center justify-between text-sm font-semibold text-gray-600 pb-2 border-b border-gray-200 mb-4"
        >
            <h3 v-text="result.title" />
            <span v-text="result.percentage + '%'" />
        </div>
        <ResultChildrenList :results="result.children" class="mb-6" />
    </div>
</template>

<script>
import ResultChildrenList from "@/components/ancestry/PreviousAncestryResults/ResultChildrenList.vue";
export default {
    components: { ResultChildrenList },
    props: {
        result: Object,
    },
};
</script>
