<template>
    <profile-form
        headerText="Identity"
        :buttonDisabled="buttonDisabled"
        :profile="profile"
        :partners="partners"
        :fieldData="{ type: identity }"
        @submit="onSubmit"
    >
        <p>Who does this DNA belong to?</p>

        {{ identity }}

        <div class="flex">
            <input
                v-model="identity"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="myself"
                type="radio"
                value="FOR_ME"
            />
            <label
                for="myself"
                class="cursor-pointer text-sm leading-none block mt-px font-semibold"
            >
                Myself
            </label>
        </div>

        <div class="mt-5 flex">
            <input
                v-model="identity"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="my-child"
                type="radio"
                value="FOR_CHILD"
            />
            <label
                for="my-child"
                class="cursor-pointer text-sm leading-none block mt-px font-semibold"
            >
                My child (Under 18)
            </label>
        </div>

        <div class="mt-5 flex">
            <input
                v-model="identity"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="someone-else"
                type="radio"
                value="FOR_ADULT"
            />
            <label
                for="someone-else"
                class="cursor-pointer text-sm leading-none block mt-px font-semibold"
            >
                Someone else
            </label>
        </div>
    </profile-form>
</template>

<script>
import ProfileForm from "@/components/Profile/Edit/ProfileForm.vue";

export default {
    name: "EditIdentity",

    props: {
        profile: {
            type: Object,
            required: true,
        },
        partners: {
            type: Array,
            required: true,
        },
    },

    components: {
        ProfileForm,
    },

    data() {
        return {
            identity: this.profile.type,
            initialIdentity: this.profile.type,
        };
    },

    methods: {
        onSubmit() {
            this.initialIdentity = this.identity;
        },
    },

    computed: {
        buttonDisabled() {
            return this.identity === this.initialIdentity;
        },
    },
};
</script>
