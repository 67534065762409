import images from "@/assets/assetMaps";
import Main from "@/views/signin/Main.vue";
import WhoDoesThisDNABelongTo from "@/views/upload/WhoDoesThisDNABelongTo.vue";
import Upload from "@/views/upload/Upload.vue";
import UploadLandingPage from "@/views/upload/UploadLandingPage.vue";
import UploadOptIn from "@/views/upload/UploadOptIn.vue";
import UploadFamilyNetworksUsername from "@/views/upload/UploadFamilyNetworksUsername.vue";
import UploadFamilyNetworksAvatar from "@/views/upload/UploadFamilyNetworksAvatar.vue";
import UploadCompleted from "@/views/upload/UploadCompleted.vue";

export default [
    {
        path: "/upload",
        component: Main,
        meta: { referrer: true },
        redirect: "/upload/start",
        children: [
            {
                path: "/upload/start",
                name: "upload-start",
                component: UploadLandingPage,
                meta: {
                    referrer: true,
                    image: images.ipad,
                    // auth: false,
                },
            },
            {
                path: "/upload/who-does-this-dna-belong-to",
                name: "who-does-this-dna-belong-to",
                component: WhoDoesThisDNABelongTo,
                meta: {
                    referrer: true,
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "/upload/upload-file",
                name: "upload-file",
                component: Upload,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "/upload/upload-family-networks-opt-in",
                name: "upload-family-networks-opt-in",
                component: UploadOptIn,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "/upload/upload-family-networks-username",
                name: "upload-family-networks-username",
                component: UploadFamilyNetworksUsername,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "/upload/upload-family-networks-avatar",
                name: "upload-family-networks-avatar",
                component: UploadFamilyNetworksAvatar,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "/upload/completed",
                name: "upload-completed",
                component: UploadCompleted,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
        ],
    },
];
