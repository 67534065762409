<template>
    <AlertMessage
        v-if="familyMatchingStore.optInStatus === 'notRunYet'"
        class="max-w-md"
        icon="users"
        type="info"
    >
        <template #title> You haven't been run yet </template>
        <template #body>
            You haven't been run through our family networks pipeline yet. We run our pipeline
            weekly and we'll email you if you get new matches in a run.
        </template>
    </AlertMessage>
    <AlertMessage v-else class="max-w-md" icon="users" type="warning">
        <template #title> You don't have any matches yet </template>
        <template #body>
            You've been run through our family networks pipeline but we haven't found you any
            matches. We run our pipeline weekly and have new people joining all the time. We'll send
            you an email as soon as you have matches available.
        </template>
    </AlertMessage>
</template>

<script setup>
import { useFamilyMatchingStore } from "@/store/familyMatching";
import AlertMessage from "@/components/AlertMessage.vue";

const familyMatchingStore = useFamilyMatchingStore();
</script>
