<template>
    <!--    <svg class="fill-current" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--        <rect width="34" height="3" />-->
    <!--        <rect x="4" y="5" width="27" height="2" />-->
    <!--        <rect x="7" y="9" width="3" height="25" />-->
    <!--        <rect x="19" y="9" width="2" height="25" />-->
    <!--        <rect x="19" y="9" width="3" height="25" />-->
    <!--        <rect x="25" y="9" width="3" height="25" />-->
    <!--        <rect x="13" y="9" width="3" height="25" />-->
    <!--    </svg>-->
    <svg class="fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="2" rx="1" />
        <rect x="2" y="4" width="20" height="2" rx="1" />
        <rect x="9" y="8" width="2" height="16" rx="1" />
        <rect x="5" y="8" width="2" height="16" rx="1" />
        <rect x="17" y="8" width="2" height="16" rx="1" />
        <rect x="13" y="8" width="2" height="16" rx="1" />
    </svg>
</template>
<script setup></script>
