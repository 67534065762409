import Ancestry from "@/views/ancestry/Ancestry.vue";

import RecentOverview from "@/views/ancestry/recent/RecentOverview.vue";
import RecentMap from "@/views/ancestry/recent/RecentMap.vue";
import RecentAncestryVersions from "@/views/ancestry/recent/RecentAncestryVersions.vue";

import Motherline from "@/views/ancestry/motherline/Motherline.vue";
import MotherlineOverview from "@/views/ancestry/motherline/MotherlineOverview.vue";
import MotherlineHistory from "@/views/ancestry/motherline/MotherlineHistory.vue";
import MotherlineCoverageMap from "@/views/ancestry/motherline/MotherlineCoverageMap.vue";
import MotherlineMigrationMap from "@/views/ancestry/motherline/MotherlineMigrationMap.vue";

import Fatherline from "@/views/ancestry/fatherline/Fatherline.vue";
import FatherlineOverview from "@/views/ancestry/fatherline/FatherlineOverview.vue";
import FatherlineHistory from "@/views/ancestry/fatherline/FatherlineHistory.vue";
import FatherlineCoverageMap from "@/views/ancestry/fatherline/FatherlineCoverageMap.vue";
import FatherlineMigrationMap from "@/views/ancestry/fatherline/FatherlineMigrationMap.vue";

import BookPdf from "@/views/ancestry/recent/BookPdf.vue";

function isYInMaintenanceMode() {
    if (localStorage.getItem("ldna-maintenance") === "2b731473-9e84-466c-a584-823c57a10ee2") {
        return false;
    }
    return String(process.env.VUE_APP_ENABLE_Y_MAINTENANCE_MODE) === "true";
}

export default [
    {
        path: "/ancestry",
        redirect: "/ancestry/overview",
        component: Ancestry,
        meta: { auth: true },
        children: [
            {
                path: "overview",
                name: "recent-overview",
                component: RecentOverview,
                meta: { auth: true },
            },
            {
                path: "recent/:groupId?",
                component: RecentMap,
                name: "recent-map",
                meta: { auth: true },
            },
            {
                path: "versions",
                name: "recent-versions",
                component: RecentAncestryVersions,
                meta: { auth: true },
            },
            {
                path: "book-pdf",
                name: "book-pdf",
                component: BookPdf,
                meta: { auth: true },
            },
        ],
    },
    {
        path: "/motherline",
        name: "motherline",
        redirect: "/motherline/overview",
        meta: { auth: true },
        component: Motherline,
        children: [
            {
                path: "overview",
                name: "motherline-overview",
                component: MotherlineOverview,
            },
            {
                path: "history",
                name: "motherline-history",
                component: MotherlineHistory,
            },
            {
                path: "distribution-map/:region?",
                name: "motherline-distribution-map",
                component: MotherlineCoverageMap,
            },
            {
                path: "migration-map",
                name: "motherline-migration-map",
                component: MotherlineMigrationMap,
            },
        ],
    },
    {
        path: "/fatherline",
        name: "fatherline",
        redirect: "/fatherline/overview",
        meta: { auth: true },
        component: Fatherline,
        children: [
            {
                path: "overview",
                name: "fatherline-overview",
                component: FatherlineOverview,
            },
            {
                path: "history",
                name: "fatherline-history",
                component: FatherlineHistory,
            },
            {
                path: "distribution-map/:region?",
                name: "fatherline-distribution-map",
                component: FatherlineCoverageMap,
            },
            {
                path: "migration-map",
                name: "fatherline-migration-map",
                component: FatherlineMigrationMap,
            },
        ],
    },
];
