<template>
    <div class="bg-white min-h-screen p-6 pt-12">
        <div v-if="fullProfile">
            <router-link
                :to="{ name: 'profile-view' }"
                class="bg-white font-semibold flex items-center text-cobalt-500"
            >
                <feather type="chevron-left" class="h-5 w-5 mr-2" />

                {{ fullProfile.profile.name }}'s DNA Profile
            </router-link>

            <div class="mx-auto pt-16" :class="$route.meta.fullWidth ? 'max-w-3xl' : 'max-w-xs'">
                <router-view :profile="fullProfile.profile" :partners="fullProfile.partners" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ProfileEdit",

    computed: {
        ...mapGetters(["accountTests", "profiles", "fullProfile"]),
    },

    async created() {
        if (!this.fullProfile) {
            await this.$store.dispatch("GET_FULL_PROFILE", this.$route.params.barcode);
        }
    },
};
</script>
