import Autosomal from "@/services/autosomal";
import loadingHelper from "@/services/store";

const { loadingState, loadingMutations } = loadingHelper("REGIONAL_HISTORY");

export default {
    state: {
        loadingStatus: loadingState.PENDING,
        activeGroup: "",
        regionalActiveGroup: "",
        history: {},
        regionalHistory: {},
    },
    getters: {
        regionalHistoryLoadingStatus: state => state.loadingStatus,
        getHistory: state => state.history[state.activeGroup],
        getRegionalHistory: state => state.regionalHistory[state.regionalActiveGroup],
        activeGroup: state => state.activeGroup,
    },
    mutations: {
        ...loadingMutations,
        GET_HISTORY(state, { testData }) {
            state.history = {
                ...state.history,
                ...testData,
            };
        },
        SET_ACTIVE_GROUP(state, group) {
            state.activeGroup = group;
        },
        GET_REGIONAL_HISTORY(state, { testData }) {
            state.regionalHistory = {
                ...state.regionalHistory,
                ...testData,
            };
        },
        SET_REGIONAL_ACTIVE_GROUP(state, group) {
            state.regionalActiveGroup = group;
        },
    },
    actions: {
        async GET_HISTORY({ commit, state }, { barcode, groupId }) {
            if (!state.history[groupId]) {
                const data = await Autosomal.getHistory({ barcode, groupId });
                commit("GET_HISTORY", data);
            }
            commit("SET_ACTIVE_GROUP", groupId);
        },
        async GET_REGIONAL_HISTORY({ commit, state }, { barcode, groupId }) {
            try {
                if (!state.regionalHistory[groupId]) {
                    commit(loadingState.PENDING);

                    const data = await Autosomal.getHistory({ barcode, groupId });

                    commit("GET_REGIONAL_HISTORY", data);
                }
                commit("SET_REGIONAL_ACTIVE_GROUP", groupId);
                commit(loadingState.SUCCESS);
            } catch (err) {
                console.error(err);
                commit(loadingState.FAILED);
            }
        },
        async RESET_HISTORY({ commit }) {
            commit("SET_ACTIVE_GROUP", "");
        },
    },
};
