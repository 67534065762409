<template>
    <svg
        id="Layer_1"
        enable-background="new 0 0 128 128"
        viewBox="0 0 128 128"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                d="m87.7 109.4-13.2-4.1c-2.2-.7-3.4-2.9-2.9-5.1l12.1-46.8c.9-3.5.4-7.3-1.5-10.4l-22-37.4c-.3-.5-.9-.9-1.5-.9h-43.4c-1.1 0-2 1.1-1.7 2.2 4.8 18.9 15.3 27.1 24.5 34.4 4.6 3.6 9 7.1 12.2 11.6-3.7 9-3.8 20.8-3.9 31.3-.1 8.3-.1 16.2-2.1 20.1-3 5.8-3.4 11.3-1.1 15 1.6 2.6 4.4 4.1 7.4 4 6.8 0 8.5-1.7 14.4-1.8 3-.1 6 .3 8.9 1 4.2 1 7.2.8 7.5.8 4.2 0 7.9-2.5 9.6-6.3 1.5-3.1-.1-6.6-3.3-7.6zm.2 6.1c-1.2 2.5-3.7 4.2-6.5 4.2-2.6 0-4.7-.2-6.7-.7-3.2-.8-6.4-1.1-9.7-1.1-6.5 0-8 1.7-14.4 1.8-1.8.1-3.5-.8-4.4-2.3-1.6-2.6-1.2-7 1.2-11.6s2.4-12.5 2.5-21.7c.1-10.5.2-22.4 4-30.8.2-.5.2-1.2-.1-1.7-3.5-5.3-8.3-9.1-13.4-13.2-8.8-7-18-14.1-22.7-30.3h40l21.5 36.4c1.4 2.3 1.8 5.1 1.1 7.8l-12 47c-1 4 1.3 8.1 5.2 9.3l13.2 4.1c1.2.4 1.8 1.7 1.2 2.8z"
            />
            <path
                d="m27.8 17c-.5-.8-1.5-1.1-2.4-.7-.8.5-1.1 1.5-.7 2.4.7 1.2 1.7 2.7 2.7 4.2.6.8 1.7.9 2.5.3.7-.6.9-1.6.4-2.4-.9-1.2-1.7-2.5-2.5-3.8z"
            />
            <path
                d="m56 44.3c-.9 2.6.4 5.6 3.1 6.5 1.9.7 4 .2 5.4-1.3l1.4-1.5 1.9.5c2.7.7 5.4-1 6.1-3.7.6-2.5-.7-5-3.1-5.9l-3.4-1.3-15.7-24c-.5-.8-1.6-1.1-2.4-.6s-1.1 1.6-.5 2.5l16 24.5c.2.3.5.5.8.7l4 1.6c.6.2 1 .8 1 1.4 0 1-.9 1.7-1.9 1.5l-2.9-.7c-.6-.1-1.3 0-1.7.5l-2.1 2.1c-.6.6-1.6.7-2.2.1-.5-.4-.6-1.1-.4-1.7l1.3-3.5c.2-.6.1-1.2-.3-1.7l-14.3-18.6c-.6-.8-1.7-.9-2.5-.3-.7.6-.9 1.6-.3 2.4l13.7 17.9z"
            />
            <path
                d="m73.5 59.8c1.9-2 1.9-5.2-.1-7.1-1.8-1.8-4.7-1.9-6.7-.3l-1.5 1.2-1.9-.8c-2.6-1-5.6.2-6.6 2.8-.8 1.9-.3 4 1.1 5.5l2 2-5.1 32.1c-.1 1 .5 1.8 1.5 2 .9.1 1.8-.5 2-1.4l5.3-33c.1-.5-.1-1.1-.5-1.5l-2.6-2.7c-.6-.6-.6-1.6 0-2.2.5-.4 1.1-.6 1.7-.3l2.9 1.2c.6.2 1.2.1 1.7-.2l2.3-1.8c.7-.5 1.6-.4 2.2.3.5.6.4 1.5-.1 2l-3 3.1c-.2.3-.4.6-.5.9l-4.4 28c-.2 1 .5 1.9 1.5 2 1 .2 1.9-.5 2-1.5l4.4-27.4z"
            />
            <path
                d="m114.4 53.5c-.2-.9-1.2-1.5-2.1-1.3l-9 2.1-1.4-3c-.4-.7-1.2-1.1-2-.9l-8.3 2c-.9.2-1.5 1.2-1.3 2.1s1.2 1.5 2.1 1.3l6.9-1.7 1.4 3c.4.7 1.2 1.1 2 1l10.4-2.4c.9-.3 1.5-1.3 1.3-2.2z"
            />
            <path
                d="m90.2 41c.8.6 1.9.4 2.5-.3l4.3-5.7 3 1.4c.7.4 1.6.1 2.1-.5l6.6-8.4c.6-.7.5-1.9-.2-2.5-.8-.6-1.9-.5-2.5.3l-5.8 7.3-3-1.4c-.7-.4-1.6-.1-2.1.5l-5.2 6.8c-.6.8-.5 1.9.3 2.5z"
            />
            <path
                d="m104.8 40.3-3.6 3.5-2.8-1.8c-.7-.5-1.6-.4-2.2.2l-3.3 3.4c-.7.7-.7 1.8 0 2.5s1.8.7 2.4 0l2.3-2.4 2.8 1.8c.7.4 1.6.4 2.2-.2l4.6-4.5c.7-.7.7-1.8.1-2.5s-1.8-.7-2.5 0z"
            />
        </g>
    </svg>
</template>
