<template>
    <div class="hidden md:flex flex-wrap gap-x-4 gap-y-2 px-4 py-2">
        <div class="flex items-center">
            <div
                class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                style="background-color: #00d4ff; opacity: 0.3"
            />
            Neanderthal area
        </div>
        <div class="flex items-center">
            <div
                class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                style="background-color: #00d4ff"
            />
            Neanderthal site
        </div>
        <div class="flex items-center">
            <div
                class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                style="background-color: #d400ff; opacity: 0.2"
            />
            Denisovan area
        </div>
        <div class="flex items-center">
            <div
                class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                style="background-color: #d400ff"
            />
            Denisovan site
        </div>
        <div class="flex items-center">
            <div class="h-3 w-3 rounded-full mr-2 flex-shrink-0" style="background-color: orange" />
            Neanderthal & Denisovan site
        </div>
        <div class="flex items-center">
            <div class="h-3 w-3 rounded-full mr-2 flex-shrink-0 bg-rose-500" />
            Closest sample site
        </div>
    </div>
</template>
