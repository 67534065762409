<script>
import BasicKitStatus from "@/components/Dashboard/StatusBar/KitStatus/BasicKitStatus.vue";
import CtaKitStatus from "@/components/Dashboard/StatusBar/KitStatus/CtaKitStatus.vue";
import { h } from "vue";

export default {
    render() {
        return h(
            "div",
            this.$slots.default({
                kitStatus: {
                    primaryColour: this.primaryColour,
                    secondaryColour: this.secondaryColour,
                    formattedDescription: this.formattedDescription,
                    formattedName: this.formattedName,
                    tailwindClass: this.tailwindClass,
                },
                cardDetails: this.cardDetails,
            })
        );
    },
    props: {
        status: String,
    },
    computed: {
        primaryColour() {
            return {
                shipped: "hsl(120, 43%, 56%)",
                returned: "hsl(120, 43%, 56%)",
                genotyping: "hsl(120, 43%, 56%)",
                testing: "hsl(120, 43%, 56%)",
                testing_failed: "hsl(333, 100%, 40%)",
                completed: "hsl(120, 43%, 56%)",
                cancelled: "hsl(333, 100%, 40%)",
                transfer_qc_failed: "hsl(333, 100%, 40%)",
            }[this.status];
        },
        secondaryColour() {
            return {
                shipped: "hsla(120, 43%, 56%, 0.08)",
                returned: "hsla(120, 43%, 56%, 0.08)",
                genotyping: "hsla(120, 43%, 56%, 0.08)",
                testing: "hsla(120, 43%, 56%, 0.08)",
                testing_failed: "hsla(333, 100%, 40%, 0.08)",
                completed: "hsla(120, 43%, 56%, 0.08)",
                cancelled: "hsla(333, 100%, 40%, 0.08)",
                transfer_qc_failed: "hsla(333, 100%, 40%, 0.08)",
            }[this.status];
        },
        cardDetails() {
            return {
                shipped: {
                    tailwindClass: "bg-emerald-500",
                    description: "Kit activated, waiting to receive your sample.",
                    secondaryText: "Swab and send your sample.",
                    button: {
                        icon: "play-circle",
                        text: "How to swab",
                        handler(vm) {
                            console.error("Handler not updated when modal system changed");
                            // vm.$modal.show("how-to-swab-video");
                        },
                    },
                    component: BasicKitStatus,
                },
                returned: {
                    tailwindClass: "bg-emerald-500",
                    description: "We have received your sample, thank you.",
                    secondaryText: "Results expected in 6-8 weeks.",
                    button: {
                        icon: "helix",
                        text: "Profiles",
                        handler(vm) {
                            vm.$router.push({ name: "profiles" });
                        },
                    },
                    component: BasicKitStatus,
                },
                genotyping: {
                    tailwindClass: "bg-emerald-500",
                    description: "We are testing your sample in our lab.",
                    secondaryText: "Results expected in 6-8 weeks.",
                    button: {
                        icon: "helix",
                        text: "Profiles",
                        handler(vm) {
                            vm.$router.push({ name: "profiles" });
                        },
                    },
                    component: BasicKitStatus,
                },
                testing: {
                    tailwindClass: "bg-emerald-500",
                    description: "We are testing your sample in our lab.",
                    secondaryText: "Results expected in 6-8 weeks.",
                    button: {
                        icon: "helix",
                        text: "Profiles",
                        handler(vm) {
                            vm.$router.push({ name: "profiles" });
                        },
                    },
                    component: BasicKitStatus,
                },
                testing_failed: {
                    tailwindClass: "bg-rose-500",
                    description: "Sorry we need to recollect your DNA.",
                    secondaryText: "The sample was poor quality.",
                    button: {
                        icon: "send",
                        text: "Request a new kit",
                        handler() {
                            window.open("https://support.livingdna.com/hc/en-us/requests/new");
                        },
                    },
                    component: BasicKitStatus,
                },
                completed: {
                    tailwindClass: "",
                    description: "",
                    secondaryText: "",
                    button: {
                        icon: "",
                        text: "",
                        handler(vm) {
                            vm.$router.push({ name: "profiles" });
                        },
                    },
                    component: CtaKitStatus,
                },
                cancelled: {
                    tailwindClass: "bg-rose-500",
                    description: "Sorry we need to recollect your DNA.",
                    secondaryText: "The sample was poor quality.",
                    button: {
                        icon: "send",
                        text: "Request a new kit",
                        handler(vm) {
                            vm.$router.push({ name: "profiles" });
                        },
                    },
                    component: BasicKitStatus,
                },
                transfer_qc_failed: {
                    tailwindClass: "bg-rose-500",
                    description: "Sorry, there was a problem with your upload.",
                    secondaryText: "We need you to reupload your DNA.",
                    button: {
                        icon: "upload",
                        text: "Reupload DNA",
                        handler(vm) {
                            vm.$router.push({
                                name: "reupload-dna",
                                params: {
                                    barcode: this.$store.getters.profile.barcode,
                                },
                            });
                        },
                    },
                    component: BasicKitStatus,
                },
            }[this.uploaderFixedStatus];
        },
        uploaderFixedStatus() {
            if (this.isUploader) {
                return this.status === "transfer_qc_failed" ? this.status : "completed";
            }
            return this.status || "complete";
        },
        formattedDescription() {
            return {
                shipped: "Kit activated, waiting to receive your sample.",
                returned: "We have received your sample, thank you.",
                genotyping: "We are testing your sample in our lab.",
                testing: "We are preparing your results.",
                testing_failed: "We need to collect further DNA, sorry.",
                complete: "Need text for complete!!!!!!",
            }[this.status];
        },
        tailwindClass() {
            return {
                shipped: "bg-emerald-500",
                returned: "bg-emerald-500",
                genotyping: "bg-emerald-500",
                testing: "bg-emerald-500",
                testing_failed: "bg-rose-500",
                transfer_qc_failed: "bg-rose-500",
                complete: "bg-emerald-500",
            }[this.status];
        },
        formattedName() {
            if (this.status === "transfer_qc_failed") {
                return "Upload failed";
            }
            const str = this.status.replace(new RegExp("_", "g"), " ").toLowerCase();
            return `${str.charAt(0).toLocaleUpperCase()}${str.substring(1)}`;
        },
        isUploader() {
            return this.$store.getters.profile.barcode.substr(0, 2) === "LT";
        },
    },
};
</script>
