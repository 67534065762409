<template>
    <a
        :href="vitlabString"
        target="_blank"
        rel="noopener"
        class="shadow rounded-lg bg-white flex flex-wrap overflow-hidden text-grey-700 hover:shadow-hover"
    >
        <div class="sm:w-3/5 p-6 flex flex-col">
            <h3 class="text-sm text-denim-700 font-semibold">
                Personalised Vitamins designed for you
            </h3>
            <p class="mt-2 mb-8 text-grey-600">
                You are unique, and so should be your vitamin routine! Adjusted to your wellbeing
                report results.
            </p>
            <a
                :href="vitlabString"
                target="_blank"
                rel="noopener"
                class="flex items-center mt-auto text-cobalt-500"
            >
                <span class="mr-2 font-semibold">See how your formula looks like</span>
                <Feather type="arrow-right" class="h-5 w-5 flex-shrink-0" />
            </a>
        </div>
        <div
            class="flex-1 relative bg-contain bg-right bg-no-repeat"
            :style="{ backgroundImage: `url('${vitaminExample}')` }"
        />
    </a>
</template>

<script>
import vitaminExample from "@/assets/images/dashboard/vitaminlab-pouch-b.png";

export default {
    data() {
        return {
            vitaminExample,
        };
    },
    props: {
        vitlabString: {
            type: String,
            required: true,
        },
    },
};
</script>
