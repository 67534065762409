<template>
    <ThePage class="max-w-5xl">
        <h1 class="text-2xl font-normal mt-4 mb-6">
            <Feather type="users" class="h-5 w-5 mr-2" />
            {{ firstName }}'s DNA relatives
        </h1>
        <div class="">
            <div class="shadow rounded-lg mt-12 overflow-hidden">
                <div class="fade-bottom relative">
                    <img :src="headerImage" alt="Magnifying glass on table" />
                    <h2
                        class="font-normal absolute text-3xl text-center left-0 right-0 z-10 text-white"
                        style="top: 50%; transform: translateY(-50%)"
                    >
                        Find living relatives
                    </h2>
                </div>
                <Space height="8" />
                <h1 class="font-normal text-center text-2xl my-0 px-12">
                    Join the Family Networks matching database to find people who share DNA with you
                    around the world
                </h1>
                <Space height="4" />
                <p class="px-16 my-0 text-center">
                    The Family Networks relative-finding tool is a free, opt-in service that
                    compares your DNA against other users in the database to find your relatives.
                    The tool currently detects matches up to 9th degree,which is approximately 4th
                    cousin level
                </p>
                <Space height="8" />
                <div class="flex flex-col items-center">
                    <button
                        @click="getStarted"
                        class="bg-cobalt-500 text-white font-semibold px-6 py-4 rounded hover:bg-cobalt-700"
                    >
                        Let’s go!
                    </button>
                    <Space height="2" />
                    <p class="text-grey-500 mt-4 mb-2">OR</p>
                    <a
                        href="https://support.livingdna.com/hc/en-us/articles/360013609959-Family-Matching-Overview"
                        class="flex items-center"
                        target="_blank"
                        rel="noopener"
                    >
                        <Feather type="help-circle" class="flex-none" />
                        <span class="ml-2 font-semibold">
                            Learn more about Family Networks
                        </span>
                    </a>
                    <Space height="6" />
                </div>
            </div>
        </div>
    </ThePage>
</template>

<script>
import ThePage from "@/components/Base/ThePage.vue";
import headerImage from "@/assets/images/family-network/not-run.png";
import { mapGetters } from "vuex";
import { optInMachine, optInService } from "@/views/FamilyNetworks/machines/optInMachine";

export default {
    name: "family-networks-start",
    components: {
        ThePage,
    },
    computed: {
        ...mapGetters(["firstName"]),
    },
    data: () => ({
        headerImage,
        // optInService: interpret(optInMachine),
        // Start with the machine's initial state
        current: optInMachine.initialState,
        // Start with the machine's initial context
        context: optInMachine.context,
    }),
    methods: {
        getStarted() {
            optInService.send({ type: "GET_STARTED" });
        },
    },
    created() {
        // create service
        optInService
            .onTransition(state => {
                if (state.value.settingDisplayName === "idle") {
                    this.$router.push({ name: "family-matching-display-name" });
                }
                // Update the current state component data property with the next state
                this.current = state;
                // Update the context component data property with the updated context
                this.context = state.context;
            })
            .start();
    },
};
</script>

<style scoped></style>
