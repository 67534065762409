<template>
    <div class="w-full xl:w-1/2">
        <TestStatus
            class="w-full"
            v-if="healthProfileStatus"
            @click="open = true"
            :status="healthProfileStatus.name"
        >
            <template #name>
                <div class="flex items-center">
                    <FeatherWrapper icon="heart" class="mr-2" />
                    Health profile
                </div>
            </template>
            <template v-slot:status="{ colours }">
                <p
                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                    :class="`${colours.bgClass} ${colours.textClass}`"
                    style="white-space: nowrap"
                >
                    {{ healthProfileStatus.name }}
                </p>
            </template>
        </TestStatus>
        <TransitionRoot as="template" :show="open">
            <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
                <div
                    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay
                            class="fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity"
                        />
                    </TransitionChild>

                    <!-- This element is to trick the browser into centering the modal contents. -->
                    <span
                        class="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                        >&#8203;</span
                    >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6"
                        >
                            <h1 class="mt-16 px-12 text-xl text-grey-700">Your health profile</h1>
                            <div class="px-12 pb-4">
                                <p v-if="healthProfileStatus.name === 'Not created'">
                                    Click
                                    <a href="/health" class="text-cobalt-500 font-semibold">here</a>
                                    to create your health profile
                                </p>
                            </div>
                            <table v-if="steps" class="mx-12 text-lg font-semibold mb-12 w-full">
                                <thead>
                                    <tr class="text-base text-grey-600">
                                        <td colspan="3">Status</td>
                                        <td class="hidden md:table-cell">Usually takes</td>
                                    </tr>
                                </thead>
                                <CompletedRow
                                    :key="step.name"
                                    v-for="step in steps.completeSteps"
                                    :step="step"
                                />
                                <InProgressRow
                                    :key="step.name"
                                    v-for="step in steps.activeSteps"
                                    :step="step"
                                />
                                <IncompleteRow
                                    :key="step.name"
                                    v-for="step in steps.incompleteSteps"
                                    :step="step"
                                />
                                <FailedRow
                                    :key="step.name"
                                    v-for="step in steps.failedSteps"
                                    :step="step"
                                />
                            </table>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script setup>
import axios from "axios";
import endpoints from "@/endpoints";
import moment from "moment";
import { healthProfile } from "@/views/dashboard/kitMachine";
import TestStatus from "../TestStatus.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import CompletedRow from "@/views/dashboard/StatusBar/CompletedRow.vue";
import InProgressRow from "@/views/dashboard/StatusBar/InProgressRow.vue";
import IncompleteRow from "@/views/dashboard/StatusBar/IncompleteRow.vue";
import FailedRow from "@/views/dashboard/StatusBar/FailedRow.vue";
import { ref } from "vue";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { getPatient } from "@/services/health";

const open = ref(false);
const healthProfileStatus = ref(undefined);
const steps = ref([]);

getPatient().then((res) => {
    healthProfileStatus.value = getHealthProfileStatus(res);
    steps.value = healthProfile[healthProfileStatus.value.slug];
});

function getHealthProfileStatus(healthProfile) {
    if (!healthProfile) {
        return {
            name: "Not created",
            slug: "notCreated",
        };
    }
    return {
        name: "Created",
        slug: "created",
    };
}
</script>
