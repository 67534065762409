<template>
    <h3 class="text-denim-700 text-xl mt-12 flex items-center font-normal">
        <feather v-if="icon" :type="icon" class="h-5 w-5 mr-4"></feather>
        <slot />
    </h3>
</template>

<script>
export default {
    props: {
        icon: String,
    },
};
</script>
