<template>
    <div class="shadow rounded-lg bg-white h-full flex flex-col p-6 border-t-4 border-male-500">
        <div>
            <div class="text-sm text-gray-600">Paternal haplogroup</div>
            <div class="text-male-500 text-2xl font-semibold">
                {{ sharingStore.fatherlineResult.haplogroup }}
            </div>
        </div>
        <div v-if="sharingStore.fatherlineResult.subclade" class="mt-4">
            <div class="text-sm text-gray-600">Paternal haplotype</div>
            <div class="text-male-500 text-2xl font-semibold">
                {{ sharingStore.fatherlineResult.subclade }}
            </div>
        </div>
        <!--        <p class="mt-4">-->
        <!--            Your maternal DNA is most commonly found in <strong>{{ maternalData.region }}</strong-->
        <!--        >.-->
        <!--        </p>-->
        <a href="#fatherline" class="flex items-center text-male-500 mt-4">
            <div class="flex-grow-0">
                <FeatherWrapper icon="male" />
            </div>

            <span class="ml-2 font-semibold">Fatherline</span>
        </a>
    </div>
</template>

<script setup>
import { useSharingStore } from "@/store/sharing";

const sharingStore = useSharingStore();
</script>
