<template>
    <div v-if="component">
        <component :is="component" :key="report.type" :report="report" />
    </div>
</template>

<script>
import Feather from "vue-feather";
import IsComplete from "@/components/Wellbeing/AllReports/ReportList/IsComplete.vue";
import IsPending from "@/components/Wellbeing/AllReports/ReportList/IsPending.vue";
import IsNotPurchased from "@/components/Wellbeing/AllReports/ReportList/IsNotPurchased.vue";

export default {
    components: { Feather },
    props: {
        report: {
            required: true,
            type: Object,
        },
    },
    computed: {
        component() {
            if (this.report.complete || this.report.phenotypeCmsData) {
                return IsComplete;
            }
            if (this.report.purchased) {
                return IsPending;
            }
            return IsNotPurchased;
        },
    },
};
</script>
