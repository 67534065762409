// eslint-disable-next-line import/no-cycle
import AncestryService from "@/services/ancestry";
import loadingHelper from "@/services/store";

const { loadingState, loadingMutations } = loadingHelper("PATERNAL_DATA");

const initialState = () => ({
    loadingStatus: loadingState.PENDING,
    id: 0,
    isUserBetaTester: false,
    ydnaState: "",
    testData: {
        content: {
            contentOne: {
                image: "",
                body: "",
            },
            contentTwo: {
                image: "",
                body: "",
            },
            citations: [],
            shortDescription: "",
        },
        distribution: [],
        haplogroup: "",
        haplogroupTree: [],
        migrationPaths: [],
        positiveMarkers: [],
        subclade: "",
    },
});

export default {
    state: initialState(),
    getters: {
        paternalDataLoadingStatus: state => state.loadingStatus,
        fatherlineContent: state => state.testData.content,
        paternalDistribution: state => state.testData.distribution,
        fatherlineHaplogroup: state => state.testData.haplogroup,
        fatherlineHaplogroupTree: state => state.testData.haplogroupTree,
        fatherlineMigrationPaths: state => state.testData.migrationPaths,
        fatherlinePositiveMarkers: state => state.testData.positiveMarkers,
        fatherlineSubclade: state => state.testData.subclade,
        fatherlineTestStatus: state => state.ydnaState,
    },
    mutations: {
        ...loadingMutations,
        SET_FATHERLINE_DATA(state, data) {
            const {
                content,
                distribution,
                haplogroup,
                haplogroupTree,
                migrationPaths,
                positiveMarkers,
                subclade,
            } = data.testData.ydna.results;
            state.id = data.id;
            state.isUserBetaTester = data.isUserBetaTester;
            state.ydnaState = data.testData.ydna.status;

            state.testData = {
                content,
                distribution,
                haplogroup,
                haplogroupTree,
                migrationPaths,
                positiveMarkers,
                subclade,
            };
        },

        CLEAR_PATERNAL_MODULE(state) {
            Object.assign(state, initialState());
        },
    },
    actions: {
        async GET_FATHERLINE_DATA({ commit, state }, barcode) {
            try {
                if (state.ydnaState.length === 0) {
                    commit(loadingState.PENDING);

                    commit(
                        "SET_FATHERLINE_DATA",
                        await AncestryService.getFatherlineHistory(barcode)
                    );

                    commit(loadingState.SUCCESS);
                }
            } catch (err) {
                console.error(err);
                commit(loadingState.FAILED);
            }
        },

        CLEAR_PATERNAL_MODULE({ commit }) {
            commit("CLEAR_PATERNAL_MODULE");
        },
    },
};
