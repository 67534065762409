import axios from "axios";
import endpoints from "@/endpoints";
import { CreatePaypalOrder } from "@/views/store/product/productService";

class OrderService {
    static async getShippingOptions(sku, country) {
        const {
            data: { shippingMethods },
        } = await axios.get(`${endpoints.shippingOptions}?sku=${sku}&countryCode=${country}`);
        return shippingMethods;
    }

    static async confirmPaypalOrder(order) {
        return axios.post(endpoints.checkout, order);
    }

    static async createPaymentIntentForOrder(order) {
        return axios.post(endpoints.createPaymentIntent, order);
    }

    static async checkoutOrder(order) {
        return axios.post(endpoints.checkout, order);
    }

    static async chargeStripeOrder(paymentIntentClientSecret, confirmCardPayment, card) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await confirmCardPayment(paymentIntentClientSecret, {
                    payment_method: {
                        card,
                    },
                });
                if (result.error) {
                    reject(new Error(result.error.message));
                }
                resolve();
            } catch (e) {
                console.log(e);
            }
        });
    }

    static async createPaypalButton(context, sendEventToPurchaseService) {
        const paypalButton = paypal.Buttons({
            style: {
                size: "responsive",
                color: "gold",
                label: "paypal",
                // tagline: 'false',
                height: 44,
                borderRadius: 80,
            },
            createOrder(data, actions) {
                const purchaseUnits = [
                    {
                        amount: {
                            currency_code: context.productVariant.currency,
                            value: context.totalPrice / 100,
                            breakdown: {
                                item_total: {
                                    currency_code: context.productVariant.currency,
                                    value: context.productPrice,
                                },
                            },
                        },
                    },
                ];
                purchaseUnits.forEach((unit) => {
                    if (context.productVariant.shippable) {
                        const shippingData = {
                            address: {
                                address_line_1: context.shippingAddress.lineOne,
                                address_line_2: context.shippingAddress.lineTwo,
                                admin_area_2: context.shippingAddress.city,
                                admin_area_1: context.shippingAddress.countryCode,
                                postal_code: context.shippingAddress.zip,
                                country_code: context.shippingAddress.countryCode,
                            },
                        };
                        Object.assign(unit, { shipping: shippingData });
                        Object.assign(unit.amount.breakdown, {
                            shipping: {
                                currency_code: context.productVariant.currency,
                                value: context.selectedShippingOption.shippingPrice / 100,
                            },
                        });
                    }
                });

                return actions.order.create({
                    intent: "CAPTURE",
                    application_context: {
                        brand_name: "Living DNA",
                        shipping_preference: context.productVariant.shippable
                            ? "SET_PROVIDED_ADDRESS"
                            : "NO_SHIPPING",
                    },
                    purchase_units: purchaseUnits,
                });
            },
            async onApprove(data) {
                const order = new CreatePaypalOrder(
                    context.profile.barcode,
                    context.productVariant,
                    context.shippingAddress,
                    context.billingAddress,
                    context.selectedShippingOption,
                    context.totalPrice,
                    data.orderID
                );
                console.log({ order });
                sendEventToPurchaseService("PAYPAL_PAYMENT_PLACED", { order });
            },
        });
        paypalButton.render("#paypal");
        console.log("pp", paypalButton);
        return paypalButton;
    }
}

export default OrderService;
