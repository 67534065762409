<template>
    <div class="flex items-center">
        <router-link :to="{ name: 'dashboard' }">
            <img :src="logo" alt="Living DNA Logo" class="w-32" />
        </router-link>
        <div class="flex items-center ml-4" v-if="partnerLogo">
            |
            <img :src="partnerLogo" alt="Living DNA Logo" class="w-32 ml-4" />
        </div>
    </div>
</template>

<script>
import logo from "@/assets/images/logos/living-dna-logo.png";

export default {
    data() {
        return {
            logo,
        };
    },
    computed: {
        partnerLogo() {
            return this.$store.state.userSignupModule.partner.image;
        },
    },
};
</script>
