const { VUE_APP_LAMBDA_UPLOAD_URL } = process.env;
const { VUE_APP_API, VUE_APP_MICROSERVICES_API, VUE_APP_MESSAGING_API, VUE_APP_CMS_URL } =
    process.env;

export default {
    matches: `${VUE_APP_API}/matches`,
    getMatches: (barcode) => `${VUE_APP_API}/matches/${barcode}`,
    match: `${VUE_APP_API}/match`,
    getMatch: (currentBarcode, matchBarcode) =>
        `${VUE_APP_API}/match/${currentBarcode}/${matchBarcode}`,
    sharedMatches: (barcode, matchBarcode, { perPage, pageNumber }) =>
        `${VUE_APP_API}/shared-matches/${barcode}/${matchBarcode}?page=${pageNumber}&itemsPerPage=${perPage}`,
    getSharedMatches: (currentBarcode, matchBarcode) =>
        `${VUE_APP_API}/shared-matches/${currentBarcode}/${matchBarcode}`,
    profile: `${VUE_APP_API}/profile`,
    getProfile: (barcode) => `${VUE_APP_API}/profile/${barcode}`,
    reuploadDnaFile: `${VUE_APP_API}/kit-upload/re-upload`,
    profileStatus: (barcode) => `${VUE_APP_API}/profile/${barcode}/status`,
    allConsents: (barcode) => `${VUE_APP_API}/profile/${barcode}/consents`,
    profiles: `${VUE_APP_API}/profiles`,
    chromosomes: `${VUE_APP_API}/shared-chromosomes`,
    getSharedDna: (currentProfileId, matchProfileId) =>
        `${VUE_APP_API}/shared-chromosomes/${currentProfileId}/${matchProfileId}`,
    tags: `${VUE_APP_API}/tags`,
    verifyActivation: `${VUE_APP_API}/tests/activation`,
    kitActivation: `${VUE_APP_API}/kit-activation`,
    kitActivationActivate: `${VUE_APP_API}/kit-activation/activate`,
    kitActivationComplete: `${VUE_APP_API}/kit-activation/complete/`,
    kitUploadComplete: (barcode) => `${VUE_APP_API}/kit-upload/complete/${barcode}`,
    account: `${VUE_APP_API}/account`,
    accountConfirm: `${VUE_APP_API}/account/confirm`,
    accountAddPartner: `${VUE_APP_API}/account/add-partner`,
    accountConfirmResend: `${VUE_APP_API}/account/confirm/resend`,
    accountCreatePassword: `${VUE_APP_API}/account/create-password`,
    checkNewPasswordToken: `${VUE_APP_API}/account/check-token`,
    accountResetPassword: `${VUE_APP_API}/account/reset-password`,
    testerDetails: `${VUE_APP_API}/kit-activation/tester-details`,
    kitUploadTesterDetails: `${VUE_APP_API}/kit-upload/tester-details`,
    loginApi: `${VUE_APP_API}/login_check`,
    validateEmail: `${VUE_APP_API}/validate-email`,
    refreshToken: `${VUE_APP_API}/refresh-token`,
    activationStatus: `${VUE_APP_API}/activation`,
    activationGuid: `${VUE_APP_API}/activation/guid/`,
    acceptInvitation: `${VUE_APP_API}/kit-activation/accept-invitation/`,
    bookletDeliveryAddress: `${VUE_APP_API}/booklet-delivery-address/`,
    adminUsers: `${VUE_APP_API}/admin/users`,
    adminActivations: `${VUE_APP_API}/admin/activations`,
    adminBarcodes: `${VUE_APP_API}/admin/barcodes`,
    lambdaUpload: VUE_APP_LAMBDA_UPLOAD_URL,
    login: "/login_check",
    checkPassword: `${VUE_APP_API}/login_check`,
    consents: "consents",
    tree: "tree",
    downloads: `${VUE_APP_API}/tests/download`,
    ecommerce: `${VUE_APP_MICROSERVICES_API}/legacy/ecommerce/api`,
    product: `${VUE_APP_MICROSERVICES_API}/legacy/ecommerce/api/product`,
    shippingOptions: `${VUE_APP_MICROSERVICES_API}/legacy/ecommerce/api/shipping`,
    productType: `${VUE_APP_MICROSERVICES_API}/legacy/ecommerce/api/product-type`,
    usersDefaultAddress: `${VUE_APP_API}/account/address`,
    checkout: `${VUE_APP_MICROSERVICES_API}/legacy/ecommerce/api/checkout/store`,
    createPaymentIntent: `${VUE_APP_MICROSERVICES_API}/legacy/ecommerce/api/checkout/store/createPaymentIntent`,
    validateInsights: `${VUE_APP_MICROSERVICES_API}/legacy/ecommerce/api/store/validateDependencies`,
    tests: `${VUE_APP_API}/tests`,
    getBrand: `${VUE_APP_API}/partner/brand`,
    users: `${VUE_APP_API}/user`,
    checkActivationCode: `${VUE_APP_API}/tests/activation`,
    ancestryDashboardData: (barcode) => `${VUE_APP_API}/tests/${barcode}/dashboard`,
    kitTests: `${VUE_APP_API}/kit/tests`,
    testsList: `${VUE_APP_API}/tests/list`,
    wellbeingPreview: (barcode) => `${VUE_APP_API}/tests/wellbeing/${barcode}`,
    wellbeingTrait: (barcode, trait) => `${VUE_APP_API}/tests/wellbeing/${barcode}/${trait}`,
    vitaminReport: (barcode) => `${VUE_APP_API}/tests/wellbeing/${barcode}/getvitaminlab`,
    upgradeTest: (barcode, test) => `${VUE_APP_API}/tests/${barcode}/${test}/update`,
    getPreviousResult: (test) => (barcode) =>
        `${VUE_APP_API}/tests/${barcode}/${test}/previousVersions`,
    getPreviousAutosomalResult(barcode) {
        return this.getPreviousResult("autosomal")(barcode);
    },
    getPreviousResultPdf: (barcode) =>
        `${VUE_APP_API}/tests/${barcode}/autosomal/previousVersionsPdf`,
    research: (barcode) => `${VUE_APP_API}/tests/${barcode}/research`,
    manager: `${VUE_APP_API}/profile/manager`,
    profileManager: (barcode) => `${VUE_APP_API}/profile/${barcode}/manager`,
    getMessages: (channel) => `${VUE_APP_MESSAGING_API}/list?channel=${channel}`,
    getNotifications: `${VUE_APP_MESSAGING_API}/notifications`,
    getBlocked: (channel) => `${VUE_APP_MESSAGING_API}/is-blocked?channel=${channel}`,
    sendMessage: `${VUE_APP_MESSAGING_API}/send`,
    blockChannel: `${VUE_APP_MESSAGING_API}/block`,
    avatar: (barcode) => `${VUE_APP_API}/profile/${barcode}/avatar`,
    uploadReferral: (barcode) => `${VUE_APP_API}/profile/${barcode}/upload-referral`,
    contacts: (barcode) => `${VUE_APP_MESSAGING_API}/contacts?barcode=${barcode}`,
    hydratedContacts: (barcode) => `${VUE_APP_API}/matches/${barcode}/contacts`,
    relationshipNotes: `${VUE_APP_API}/relationship-notes`,
    relationshipNotesByBarcode(barcode) {
        return `${this.relationshipNotes}/${barcode}`;
    },
    familyNetworkSharingPermissions: (barcode) =>
        `${VUE_APP_API}/profile/${barcode}/family-network-sharing`,
    vikingResult: (barcode) => `${VUE_APP_API}/tests/viking/${barcode}`,
    vikingCms: (type) => `${VUE_APP_API}/tests/viking/result/${type}`,
    archaicResult: (barcode) => `${VUE_APP_API}/tests/archaic/${barcode}`,
    classicalResult: (barcode) => `${VUE_APP_API}/tests/classical/${barcode}`,
    archaicCms: (type) => `${VUE_APP_API}/tests/archaic/result/${type}`,
    healthBackend: `${VUE_APP_API}/health`,
    sharedSegments: (relId) => `${VUE_APP_API}/shared-segments/${relId}`,
    matchGroups: `${VUE_APP_API}/match-groups`,
    matchGroupMembers: `${VUE_APP_API}/match-group-members`,
    haplogroups: (type, name) => `${VUE_APP_API}/${type}-haplogroup/${name}`,
    // bookPdf: (type = "") => `http://api.test/user/books/pdf${type ? `/${type}` : ""}`,
    bookPdf: (type = "") => `${VUE_APP_API}/user/books/pdf${type ? `/${type}` : ""}`,
    publicProfile: (uuid) => `${VUE_APP_API}/public-profiles/public/${uuid}`,
    publicProfileAdmin: (barcode) => `${VUE_APP_API}/public-profiles/${barcode}`,
};
