<template>
    <div class="flex items-center rounded overflow-hidden">
        <Feather :type="icon" class="h-5 w-5 m-3 flex-none" />
        <div class="flex-1">
            <slot />
        </div>
        <button
            @click="$emit('message-dismissed')"
            :class="`child-block-override focus:outline-none ${buttonClasses}`"
        >
            <Feather type="x" class="h-5 w-5 m-3 flex-none" />
        </button>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            required: true,
            type: String,
        },
        buttonClasses: {
            required: true,
            type: String,
        },
    },
};
</script>

<style></style>
