<template>
    <div>
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select
                v-model="viewMode"
                id="tabs"
                name="tabs"
                class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            >
                <option value="browser">Browser view</option>
                <option value="table">Table view</option>
            </select>
        </div>
        <div class="hidden sm:block">
            <div class="border-b border-gray-200">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
                    <button
                        @click="viewMode = 'browser'"
                        class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                        :class="
                            viewMode === 'browser'
                                ? 'border-cobalt-500 text-cobalt-500'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        "
                    >
                        Browser view
                    </button>
                    <button
                        @click="viewMode = 'table'"
                        class="whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                        :class="
                            viewMode === 'table'
                                ? 'border-cobalt-500 text-cobalt-500'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        "
                    >
                        Table view
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";

const props = defineProps({
    modelValue: String,
});

const emit = defineEmits(["update:modelValue"]);

const viewMode = ref(props.modelValue);

watch(viewMode, (value) => {
    emit("update:modelValue", value);
});
</script>
