<template>
    <MessageTag
        icon="x-circle"
        class="bg-rose-50 text-rose-500"
        button-classes="hover:bg-rose-100 focus:bg-rose-100"
        @message-dismissed="$emit('message-dismissed')"
    >
        <slot />
    </MessageTag>
</template>

<script>
import MessageTag from "./MessageTag.vue";

export default {
    components: { MessageTag },
};
</script>
