<template>
    <PageLoader v-if="loadingStatus === 'loading'" class="mt-6"> Result is loading </PageLoader>
    <div v-else-if="loadingStatus === 'failed'">
        <RecentAncestryKitPending
            v-if="$store.getters.profile.barcode.includes('LD')"
            class="max-w-xl m-auto mt-8"
        />
        <RecentAncestryUploadStatus class="max-w-xl m-auto mt-8" v-else />
    </div>
    <template v-else>
        <portal to="navigation">
            <RecentAncestryNavigation />
        </portal>
        <router-view />
    </template>
</template>

<script setup>
import RecentAncestryNavigation from "@/components/ancestry/Recent/RecentAncestryNavigation.vue";
import { useLoading } from "@/composables/useLoading";
import { useAutosomalStore } from "@/store/ancestry/autosomal";
import RecentAncestryKitPending from "@/components/RecentAncestry/RecentAncestryKitStatus.vue";
import RecentAncestryUploadStatus from "@/components/RecentAncestry/RecentAncestryUploadStatus.vue";

const autosomalStore = useAutosomalStore();
const { loadingStatus, finishLoading, failLoading } = useLoading();

autosomalStore.getAutosomalResults().then(finishLoading).catch(failLoading);
</script>
