<template>
    <div class="md:hidden">
        <div class="space-y-2 px-4 py-2" v-if="showLegend">
            <div v-for="item in items" class="flex items-center">
                <div
                    class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                    :style="{
                        backgroundColor: item.colour,
                    }"
                    :class="item.type === 'area' ? 'opacity-30' : ''"
                />
                {{ item.name }}
            </div>

            <button
                @click="showLegend = false"
                class="py-2 flex items-center text-sm font-semibold text-cobalt-500"
            >
                <Feather type="minus-circle" class="h-4 w-4 mr-2" />
                Hide legend
            </button>
        </div>
        <button
            v-else
            @click="showLegend = true"
            class="py-2 flex items-center text-sm font-semibold text-cobalt-500 mx-4 h-12"
        >
            <Feather type="plus-circle" class="h-4 w-4 mr-2" />
            Show legend
        </button>
    </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
    items: Array,
});

const showLegend = ref(false);
</script>
