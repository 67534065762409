<template>
    <div class="prose mx-auto">
        <h2>Historical DNA</h2>
        <p>
            Explore your ancestry with our advanced test, uncovering connections to Viking and
            Archaic roots. Discover the stories embedded in your genes as you browse shared results,
            offering a glimpse into the rich tapestry of human history. Gain insights into your
            unique identity and the fascinating journey that has shaped it.
        </p>
        <div class="mt-24">
            <div class="">
                <h3 class="not-prose relative overflow-hidden p-6">
                    <img :src="vikingBg" class="absolute inset-0 opacity-20 z-10" alt="" />
                    <span class="relative bg-white/70 z-20 backdrop-blur-sm">Viking</span>
                </h3>

                <div class="not-prose mt-6">
                    <h6 class="text-gray-600 text-sm">Closest group</h6>
                    <p class="text-gray-800 font-semibold text-lg">
                        {{ populationName }}
                    </p>
                </div>
                <div class="flex items-center justify-between w-1/2 space-x-2 py-2 mt-4">
                    <div>
                        <div class="text-sm text-grey-700 capitalize">Percentile</div>
                        <div class="text-xl font-semibold">
                            {{ sharingStore.vikingResult.percentile }}
                        </div>
                    </div>

                    <BarChart
                        class="flex-shrink-0"
                        :percentile="sharingStore.vikingResult.percentile"
                    />
                </div>
            </div>
        </div>

        <div class="mt-24">
            <!--            <img :src="archaicBg" class="absolute inset-0 opacity-20 z-10" alt="" />-->
            <div class="">
                <h3 class="not-prose relative overflow-hidden p-6">
                    <img
                        :src="archaicBg"
                        class="absolute left-0 right-0 top-[-30px] opacity-20 z-10"
                        alt=""
                    />
                    <span class="relative bg-white/70 z-20 backdrop-blur-sm">Archaic</span>
                </h3>
                <div class="not-prose mt-6">
                    <h6 class="text-gray-600 text-sm">Closest Neanderthal Remnant</h6>
                    <p class="text-gray-800 font-semibold text-lg">
                        {{
                            JSON.parse(sharingStore.archaicResult.results).neanderthal
                                .closest_sample.name
                        }}
                    </p>
                </div>
                <div class="flex items-center justify-between w-1/2 space-x-2 py-2 mt-4">
                    <div>
                        <div class="text-sm text-grey-700 capitalize">Neanderthal Percentile</div>
                        <div class="text-xl font-semibold">
                            {{
                                JSON.parse(sharingStore.archaicResult.results).neanderthal
                                    .percentiles["Living DNA customers"]
                            }}
                        </div>
                    </div>

                    <BarChart
                        class="flex-shrink-0"
                        :percentile="
                            JSON.parse(sharingStore.archaicResult.results).neanderthal.percentiles[
                                'Living DNA customers'
                            ]
                        "
                    />
                </div>
                <div class="flex items-center justify-between w-1/2 space-x-2 py-2 mt-4">
                    <div>
                        <div class="text-sm text-grey-700 capitalize">Denisovan Percentile</div>
                        <div class="text-xl font-semibold">
                            {{
                                JSON.parse(sharingStore.archaicResult.results).denisova.percentiles[
                                    "Living DNA customers"
                                ]
                            }}
                        </div>
                    </div>

                    <BarChart
                        class="flex-shrink-0"
                        :percentile="
                            JSON.parse(sharingStore.archaicResult.results).denisova.percentiles[
                                'Living DNA customers'
                            ]
                        "
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useSharingStore } from "@/store/sharing";
import BarChart from "@/components/charts/BarChart.vue";
import vikingBg from "@/assets/images/viking-background-2.jpg";
import archaicBg from "@/assets/images/archaic-background.jpg";

const sharingStore = useSharingStore();

const populationName = {
    swedishdanish_vikings: "Vikings of Denmark & Sweden",
    eastern_vikings: "Vikings of Eastern Europe",
    norwegian_vikings: "Vikings of Norway",
}[sharingStore.vikingResult.closestPop];
</script>
