<template>
    <Doughnut :chart-data="chartData" />
</template>

<script>
import Doughnut from "@/components/charts/Doughnut.vue";

export default {
    components: {
        Doughnut,
    },
    props: {
        regionData: {
            type: Array,
            required: true,
        },
    },

    computed: {
        unaccountedPercentage() {
            return (
                100 - this.regionData.reduce((acc, current) => acc + current.percentage, 0)
            ).toFixed(1);
        },
        chartValues() {
            return this.regionData.map((region) => region.percentage);
        },
        chartColours() {
            return this.regionData.map((region) => region.colour);
        },
        chartLabels() {
            return this.regionData.map((region) => region.title);
        },
        chartData() {
            return {
                datasets: [
                    {
                        data: [...this.chartValues, this.unaccountedPercentage],
                        backgroundColor: [...this.chartColours, "#e1e5ed"],
                    },
                ],
                labels: [],
            };
        },
    },
};
</script>
