<template>
    <div class="flex prose">
        <div class="bg-white m-auto px-16 pb-16 max-w-2xl">
            <p>
                I consent to the following information being shared with firms who Living DNA
                partner with in providing Family Networks as described in the Privacy Statement:
            </p>
            <ol class="ml-6">
                <li class="mb-2">My username</li>
                <li class="mb-2">
                    My relationship with each match who is a customer of that partner.
                </li>
                <li class="mb-2">
                    The DNA I share in common with those matches, if I have opted into such a
                    feature.
                </li>
            </ol>
            <p>
                You are <strong>not required</strong> to give this consent. It is optional. Your
                participation in Family Networks will not be impacted by giving or not giving it.
            </p>
            <OptInAdditionalForm
                :loading="loading"
                :decline-link="declineLink"
                @accept-additional-terms="acceptAdditionalTerms"
            />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import OptInAdditionalForm from "@/components/FamilyMatching/OptInAdditionalForm.vue";

export default {
    components: { OptInAdditionalForm },
    props: {
        barcode: {
            type: String,
            required: true,
        },
        declineLink: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        async acceptAdditionalTerms() {
            this.loading = true;
            try {
                await this.$store.dispatch("setFamilyMatchingAdditionalTermsAsAccepted", {
                    barcode: this.barcode,
                    http: axios,
                });
                this.$emit("additional-accepted");
            } catch (e) {
                alert("There was a problem saving your consent");
            }
        },
    },
};
</script>
