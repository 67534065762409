<template>
    <a
        v-if="unconfirmedAppointments.length"
        href="/health/appointments/confirm"
        class="bg-cobalt-50 text-cobalt-500 p-4 rounded mb-4 block"
    >
        You have a health test appointment that needs confirming, please
        <strong>click here</strong> to do so
    </a>
    <div>
        <div v-if="accountTests.length > 0">
            <h3 class="text-denim-700 text-2xl font-normal text-center">Add DNA Profile</h3>
            <p class="text-grey-700 text-center mt-4 mb-8 leading-normal">
                Get more from your Living DNA experience by adding more DNA profiles. You can easily
                compare results anytime.
            </p>
        </div>
        <div v-else>
            <h3 class="text-denim-700 text-2xl font-normal text-center">
                Welcome back, {{ name }}
            </h3>
            <p class="text-grey-700 text-center mt-4 mb-8 leading-normal">
                Your DNA adventure can't begin without DNA so how would you like to proceed?
            </p>
        </div>
        <router-link
            :to="{ name: 'who-does-this-dna-belong-to' }"
            class="mt-4 bg-cobalt-500 rounded w-full py-2 px-4 text-white font-semibold block text-center"
        >
            <span class="text-white">Upload DNA</span>
        </router-link>
        <router-link
            to="/kit-activation/enter-code"
            class="mt-2 border-2 border-cobalt-500 rounded w-full py-2 px-4 text-cobalt-500 font-semibold block text-center"
        >
            Activate DNA kit
        </router-link>

        <a
            v-if="isHealthEnabled()"
            href="/health/activate"
            class="mt-2 bg-cobalt-50 rounded w-full py-2 px-4 font-semibold block text-center"
        >
            <span class="text-cobalt-500">Activate health kit</span>
        </a>

        <div class="text-center mt-8">
            Don't have a DNA file or kit?
            <a
                href="https://livingdna.com/uk/#featured-products"
                target="_blank"
                rel="noopener"
                class="text-cobalt-500 font-semibold"
            >
                Buy kit
            </a>
        </div>
    </div>
</template>

<script>
import { isHealthEnabled } from "@/services/health";
import { mapGetters } from "vuex";
import { getUnconfirmedAppointments } from "@/services/health";

export default {
    data() {
        return {
            unconfirmedAppointments: [],
            isHealthEnabled,
        };
    },
    computed: {
        ...mapGetters(["accountTests"]),
        name() {
            if (this.$store.state.account) {
                return this.$store.state.account.firstName;
            }
            return "";
        },
    },
    async mounted() {
        await this.$store.dispatch("SET_ACCOUNT_TESTS");
        await this.$store.dispatch("getAccountData");
        this.unconfirmedAppointments = await getUnconfirmedAppointments(
            this.$store.getters.accountData
        );
    },
};
</script>

<style>
/* body a.text-white {
    @apply text-white !important;
} */
/* a.text-cobalt-500:visited {
    @apply text-cobalt-700 !important;
} */
</style>
