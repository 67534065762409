<template>
    <div class="flex pb-4 items-center">
        <span class="w-1/2 text-grey-500">{{ report.traitCmsData.title }}</span>
        <span class="flex items-center text-grey-500 font-semibold w-1/2">
            <span class="ml-auto">
                Locked
                <feather type="lock" class="fill-current text-grey-500 h-5 w-5 ml-2" />
            </span>
        </span>
    </div>
</template>

<script>
import Feather from "vue-feather";

export default {
    components: { Feather },
    props: {
        report: Object,
    },
};
</script>
