<template>
    <div class="flex mt-6">
        <div class="w-1/3 flex">
            <input
                type="radio"
                v-model="whoFor"
                value="Myself"
                id="myself"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
            />
            <label
                for="myself"
                class="cursor-pointer text-grey-500 text-sm leading-none block mt-px font-semibold"
            >
                Myself
            </label>
        </div>
        <div class="w-1/3 flex">
            <input
                type="radio"
                v-model="whoFor"
                value="MyChild"
                id="my-child"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
            />
            <label
                for="my-child"
                class="cursor-pointer text-grey-500 text-sm leading-none block mt-px font-semibold"
            >
                My child
                <span class="block w-full mt-1 text-xs">(Under 18)</span>
            </label>
        </div>
        <div class="w-1/3 flex">
            <input
                type="radio"
                v-model="whoFor"
                value="AnotherAdult"
                id="someone-else"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
            />
            <label
                for="someone-else"
                class="cursor-pointer text-grey-500 text-sm leading-none md:whitespace-no-wrap block mt-px font-semibold"
            >
                Someone else
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: String,
    },
    data() {
        return {
            whoFor: this.modelValue,
        };
    },
    mounted() {
        if (window.FS !== undefined) {
            window.FS.log(
                `ChooseKitUser mounted with value prop ${this.value} and data ${this.whoFor}`
            );
        }
    },
    watch: {
        whoFor: {
            immediate: true,
            handler(value) {
                if (window.FS !== undefined) {
                    window.FS.log(
                        `whoFor watcher triggered in ChooseKitUser with value ${value} and state ${this.whoFor}`
                    );
                }
                this.$emit("update:modelValue", this.whoFor);
            },
        },
    },
};
</script>

<style>
input[type="radio"]:checked + label {
    @apply text-grey-700;
}
</style>
