<script>
import { h } from "vue";
import { mapGetters } from "vuex";
import MaternalCardNotPurchased from "./MaternalCardNotPurchased.vue";
import MaternalCard from "@/components/Dashboard/Ancestry/MaternalCard/MaternalCard.vue";
import MaternalCardLoading from "./MaternalCardLoading.vue";
import MaternalCardFailed from "./MaternalCardFailed.vue";

export default {
    render() {
        return h(
            "div",
            this.$slots.default({
                component: this.component,
                maternalData: this.maternalData,
            })
        );
    },
    data() {
        return {
            component: {},
            maternalData: {},
        };
    },
    created() {
        if (!this.kitTests.autosomal.purchased) {
            this.component = MaternalCardNotPurchased;
            return;
        }
        this.component = MaternalCardLoading;
        this.$nextTick(() => {
            this.$store.getters.ancestryData
                .then(data => {
                    if (data.maternal) {
                        this.maternalData = data.maternal;
                        this.component = MaternalCard;
                    }
                })
                .catch(() => {
                    this.component = MaternalCardFailed;
                });
        });
    },
    computed: {
        ...mapGetters(["ancestryData", "kitTests"]),
    },
};
</script>
