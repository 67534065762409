<template>
    <Field
        name="countryCode"
        as="select"
        v-model="countryCode"
        class="border border-grey-300 rounded bg-grey-50 w-full p-2 focus:outline-none focus:border-cobalt-500"
    >
        <option v-if="showChoose" value="">Please choose</option>
        <option v-for="country in allowedCountries" :key="country.code" :value="country.code">
            {{ country.name }}
        </option>
    </Field>
</template>

<script>
import { ref, watch } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import countries from "@/countries";

export default {
    components: { Field, ErrorMessage },
    props: {
        modelValue: String,
        showChoose: Boolean,
        preventedCountries: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        if (props.preventedCountries.length > 0) {
            var allowedCountries = countries.filter(
                (country) => !props.preventedCountries.includes(country.code)
            );
        } else {
            var allowedCountries = countries;
        }
        const countryCode = ref(props.modelValue ? props.modelValue : "");
        watch(countryCode, (currentValue) => {
            emit("update:modelValue", currentValue);
        });
        return { countryCode, allowedCountries };
    },
};
</script>
