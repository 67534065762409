<template>
    <!-- sm:max-w-2xl -->
    <Modal :open="showModal" close-icon-color="#fff" width="2xl" @closed="$emit('closed')">
        <div v-if="loadingStatus === 'loading'">Loading</div>
        <div v-else-if="matchGroupStore.matchGroups.length">
            <h3 class="mb-4 font-semibold text-lg">Your matchbox</h3>
            <div v-for="group in matchGroupStore.matchGroups" class="mb-4 flex justify-between">
                <div class="flex items-center gap-4">
                    <div
                        class="rounded-full h-5 w-5"
                        :style="{ backgroundColor: group.colour }"
                    ></div>
                    <div>{{ group.name }}</div>
                </div>
                <div>
                    <div class="flex items-center gap-4">
                        <div>
                            {{ group.members.length }}
                            {{ pluralize("member", group.members.length) }}
                        </div>

                        <button
                            v-if="
                                !group.members
                                    .map((member) => member.barcode)
                                    .includes(match.barcode)
                            "
                            @click="addMatchToGroup(group)"
                            class="font-semibold text-cobalt-500 flex items-center gap-1 text-sm"
                            :class="
                                activeGroupIds.includes(group.id)
                                    ? 'loading loading-cobalt-500'
                                    : ''
                            "
                        >
                            Add
                            <FeatherWrapper
                                icon="plus"
                                :class="
                                    activeGroupIds.includes(group.id)
                                        ? 'text-transparent'
                                        : 'text-cobalt-500'
                                "
                            />
                        </button>
                        <button
                            v-else
                            @click="removeMatchFromGroup(group)"
                            class="font-semibold text-rose-500 flex items-center gap-1 text-sm"
                            :class="
                                activeGroupIds.includes(group.id) ? 'loading loading-rose-500' : ''
                            "
                        >
                            Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <p class="mb-4">You don't have any groups in your matchbox yet.</p>
            <p>
                Add one by going to your
                <router-link :to="{ name: 'matchbox' }" class="text-cobalt-500 font-semibold">
                    matchbox</router-link
                >
            </p>
        </div>
    </Modal>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";
import pluralize from "pluralize";
import endpoints from "@/endpoints";
import Modal from "@/components/Modal.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import { useMatchGroupStore } from "@/store/matchGroups";

const props = defineProps({
    showModal: Boolean,
    match: Object,
});

const loadingStatus = ref("loaded");
const matchGroupStore = useMatchGroupStore();
const activeGroupIds = ref([]);

async function addMatchToGroup(group) {
    activeGroupIds.value.push(group.id);
    axios
        .put(endpoints.matchGroupMembers, {
            barcode: props.match.barcode,
            match_group_id: group.id,
            rel_id: props.match.rel_id,
        })
        .then(async ({ data }) => {
            await matchGroupStore.getMatchGroups();
            loadingStatus.value = "loaded";
            activeGroupIds.value = activeGroupIds.value.filter((g) => g !== group.id);
        })
        .catch(() => alert("failed"));
}

async function removeMatchFromGroup(group) {
    activeGroupIds.value.push(group.id);
    const member = group.members.find((member) => member.barcode === props.match.barcode);
    await axios.delete(`${endpoints.matchGroupMembers}/${member.id}`);
    await matchGroupStore.getMatchGroups();
    activeGroupIds.value = activeGroupIds.value.filter((g) => g !== group.id);
}
</script>
