<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <Modal :open="open" width="xl">
        <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-4">
                <h3 class="text-lg leading-6 font-medium text-grey-700">Change Profile</h3>
                <p
                    v-if="$store.getters.profiles.length > 1"
                    class="mt-1 text-sm text-grey-600 mb-4"
                >
                    Select a profile below to switch
                </p>
            </div>
        </div>
        <PortalProfileSwitcherModalList @profile-changed="open = false" />
    </Modal>
</template>

<script>
import { watch, toRefs } from "vue";
import { useRouter } from "vue-router";
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import PortalProfileSwitcherModalList from "./PortalProfileSwitcherModalList.vue";
import Modal from "@/components/Modal.vue";

export default {
    props: {
        open: Boolean,
    },
    components: {
        Modal,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        PortalProfileSwitcherModalList,
    },
    setup(props, { emit }) {
        const { open } = toRefs(props);
        const router = useRouter();
        watch(open, () => {
            console.log("triggered");
            emit("state-change", open.value);
        });
        function goToProfiles() {
            emit("state-change", false);
            router.push({ name: "profiles" });
        }
        return { goToProfiles };
    },
};
</script>
