<template>
    <div>
        <ScaleMapper
            :level="resultValue"
            :trait="trait"
            :phenotype="phenotype"
            v-slot="{ primaryColour, secondaryColour, result }"
        >
            <button
                @click="show = true"
                class="p-2 inline-flex items-center rounded mb-6"
                :style="{ backgroundColor: secondaryColour, color: primaryColour }"
            >
                <span class="text-center mr-4 font-semibold">
                    {{ result }}
                </span>
                <Feather type="info" class="h-5 w-5 fill-current" />
            </button>
        </ScaleMapper>
        <Modal width="lg" :open="show">
            <div class="flex flex-col relative h-full">
                <button @click="show = false" class="cursor-pointer absolute top-0 right-0">
                    <feather type="x" class="h-5 w-5 fill-current text-grey-500 mr-6 mt-6" />
                </button>
                <h3 class="text-2xl font-normal mx-12 mt-12 flex-0">What does my result mean?</h3>
                <div ref="fadeInside" class="flex-1 overflow-auto relative">
                    <div class="mx-12 mb-4 mt-4">
                        <ResultDescriptionBody :result="resultValue" />
                    </div>
                </div>
                <div class="mb-12 mx-12 flex-0 mt-6">
                    <button
                        role="button"
                        class="bg-cobalt-500 text-white py-2 w-full rounded font-semibold flex items-center justify-center"
                        @click="show = false"
                    >
                        <Feather type="corner-up-left" class="h-5 w-5 fill-current mr-2" />
                        Return to report
                    </button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { ref } from "vue";
import Modal from "@/components/Modal";
// import Feather from "vue-feather";
import ScaleMapper from "@/components/Wellbeing/ScaleMapper.vue";
import ResultDescriptionBody from "@/components/Wellbeing/WellbeingReport/ResultDescriptionBody.vue";

export default {
    components: { ScaleMapper, ResultDescriptionBody, Modal },
    props: {
        resultValue: {
            type: Number,
            required: true,
        },
        trait: {
            type: String,
            required: true,
        },
        phenotype: {
            type: String,
            required: true,
        },
    },
    setup() {
        const show = ref(false);
        return { show };
    },
};
</script>
