<template>
    <div v-if="true">
        <div v-if="false">
            {{ state.value }}
            <span v-if="state.children.purchaser"
                >| {{ state.children.purchaser.state.value }}</span
            >
            {{ showModal }}
        </div>
        <template
            v-if="
                state.matches('idle') ||
                state.matches('fetchingProductInformation') ||
                state.matches('validatingInsights')
            "
        >
            <PageLoader class="mt-12">Loading product information</PageLoader>
        </template>
        <template v-else-if="state.matches('productLoadFailed')">
            <div class="max-w-2xl mx-auto p-4">
                <div class="rounded-md bg-rose-50 p-4">
                    <div class="flex items-center">
                        <Feather
                            type="x-circle"
                            class="h-5 w-5 text-rose-500 flex-shrink-0"
                            aria-hidden="true"
                        />
                        <div class="ml-3">
                            <h3 class="text-sm font-medium text-rose-500">
                                There was an issue loading the product. You can try reloading, if
                                the issue persists please contact support
                            </h3>
                        </div>
                    </div>
                </div>
                <button
                    @click="send('FETCH_PRODUCT')"
                    class="py-2 px-4 mt-6 rounded flex items-center justify-center bg-cobalt-500 hover:bg-cobalt-700 text-white font-semibold"
                >
                    Reload
                </button>
            </div>
        </template>
        <template v-else>
            <template v-if="state.matches('insightsNotValidated')">
                <div
                    v-html="state.context.errorMessage"
                    class="bg-rose-500 w-full px-5 py-5 flex justify-center description-text text-white font-semibold"
                ></div>
                <div class="py-12 px-6 container mx-auto">
                    <div class="rounded-lg w-full bg-white flex flex-wrap p-12 shadow mb-6">
                        <ProductImageViewer
                            :public-path="path"
                            :data-images="state.context.product.images"
                        />
                        <div class="lg:w-1/2 sm:w-full lg:pl-6">
                            <h2 class="text-denim-700 mb-2 text-3xl font-normal leading-normal">
                                {{ state.context.product.title }}
                            </h2>
                            <div
                                class="description-text text-grey-700 font-normal leading-normal mb-4"
                                v-html="state.context.product.shortDescription"
                            ></div>
                            <p class="text-grey-700 font-normal leading-normal mb-4">
                                You will receive:
                            </p>
                            <ul class="list-none text-grey-700 font-normal leading-normal mb-12">
                                <ProductListItem
                                    v-for="item in state.context.product.featureBulletPoints"
                                    :key="item.id"
                                    :text="item"
                                />
                            </ul>
                            <router-link
                                :to="{ name: 'products' }"
                                class="rounded bg-cobalt-500 text-white px-8 py-3 font-normal leading-normal focus:outline-none min-w-200 font-semibold hover:bg-cobalt-700"
                            >
                                <span class="text-white">Return to store</span>
                            </router-link>
                        </div>
                    </div>
                    <div class="flex lg:flex-row flex-col">
                        <div
                            class="rounded-lg w-full bg-white p-12 shadow lg:mr-6 lg:mb-0 mb-6 flex-grow"
                        >
                            <h3 class="text-denim-700 font-normal leading-normal text-2xl mb-4">
                                Description
                            </h3>
                            <div
                                v-html="state.context.product.longDescription"
                                class="description-text text-grey-700 font-normal leading-normal mb-0"
                            ></div>
                        </div>
                        <div class="rounded-lg w-full bg-cobalt-50 flex flex-col p-12">
                            <h3 class="text-denim-700 font-normal leading-normal text-2xl mb-4">
                                FAQs
                            </h3>
                            <FAQs :questions="state.context.product.faq"></FAQs>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <Modal :open="showModal" width="xl" @state-change="handleModalChange">
                    <span class="hidden">{{ purchaseState ? purchaseState.value : "" }}</span>
                    <div v-if="state.children.purchaser">
                        <div
                            class="w-full border-b border-grey-200 py-3 px-6 flex justify-between items-center"
                        >
                            Checkout
                            <button @click="showModal = false">
                                <Feather type="x" class="h-6 w-6 text-grey-600" />
                                <span class="sr-only">Close payment modal</span>
                            </button>
                        </div>

                        <div class="p-6">
                            <div v-if="state.children.purchaser.state.matches('confirmConditions')">
                                <div class="mb-8">
                                    <h3 class="text-denim-700 text-2xl">
                                        {{ state.context.product.title }}
                                    </h3>
                                    <p class="text-grey-500 text-sm mb-0">
                                        You are purchasing a
                                        {{ state.context.product.title }} for
                                    </p>
                                    <div class="inline-flex justify-between items-center w-full">
                                        <p class="text-grey-700 mt-2">
                                            {{ state.context.profile.name }} –
                                            {{ state.context.profile.barcode }}
                                        </p>
                                        <span class="text-xl text-denim-700">{{
                                            convertToCurrency(
                                                state.context.productPrice,
                                                state.context.productVariant.currency
                                            )
                                        }}</span>
                                    </div>
                                    <div
                                        v-if="hasNoneLdnaTest"
                                        class="warning-product-message bg-rose-50 w-full px-5 py-5 text-rose-500 mt-4 text-xs font-semibold"
                                    >
                                        <p class="my-0">
                                            The number of traits (up to 14) you receive is dependent
                                            on the genetic markers and quality of data generated
                                            from your original testing provider. Living DNA cannot
                                            guarantee the quality of other providers' data and
                                            therefore cannot guarantee the number of traits you will
                                            receive.
                                        </p>
                                    </div>
                                    <div
                                        v-else-if="state.context.product.warningMessage"
                                        v-html="state.context.product.warningMessage"
                                        class="bg-rose-50 w-full p-4 text-rose-500 mt-4 text-sm font-semibold rounded"
                                    />
                                    <!-- Viking warning message -->
                                    <div
                                        v-else-if="state.context.warningMessage"
                                        v-html="state.context.warningMessage"
                                        class="bg-rose-50 w-full p-4 text-rose-500 mt-4 text-sm font-semibold rounded"
                                    />
                                </div>
                                <div class="">
                                    <button
                                        @click="state.children.purchaser.send('CONFIRM')"
                                        class="bg-cobalt-500 font-semibold rounded p-3 text-center text-white w-full focus:outline-none hover:bg-cobalt-700"
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                            <div
                                v-else-if="
                                    state.children.purchaser.state.matches(
                                        'enterShippingDetails'
                                    ) ||
                                    state.children.purchaser.state.matches(
                                        'changingShippingAddress'
                                    ) ||
                                    state.children.purchaser.state.matches(
                                        'updatingShippingOptions'
                                    ) ||
                                    state.children.purchaser.state.matches('chooseShippingOption')
                                "
                                class="pb-32 md:pb-0"
                            >
                                <h3 class="mb-4">Shipping Address</h3>
                                <FormAddress
                                    @form-submitted="handleValidatedForm"
                                    form-type="shipping"
                                    :address="
                                        state.children.purchaser.state.context.shippingAddress
                                    "
                                    :is-for-kit="
                                        state.context.productVariant.productCategory === 'Kit'
                                    "
                                />
                            </div>
                            <div
                                v-else-if="
                                    state.children.purchaser.state.matches(
                                        'fetchingShippingOptions'
                                    )
                                "
                            >
                                <div class="loading-large h-16"></div>
                                <p class="text-center">Loading...</p>
                            </div>
                            <div
                                v-else-if="
                                    state.children.purchaser.state.matches(
                                        'choosingShippingAndPaymentMethod'
                                    )
                                "
                                class="pb-32 md:pb-0"
                            >
                                <div class="mb-8">
                                    <h3 class="text-denim-700 text-2xl">
                                        {{ state.context.product.title }}
                                    </h3>
                                    <p class="text-grey-500 text-sm mb-0">
                                        You are purchasing a
                                        {{ state.context.product.title }} for
                                    </p>
                                    <div class="inline-flex justify-between items-center w-full">
                                        <p class="text-grey-700 mt-2">
                                            {{ state.context.profile.name }} –
                                            {{ state.context.profile.barcode }}
                                        </p>
                                        <span class="text-xl text-denim-700">{{
                                            convertToCurrency(
                                                state.context.productPrice,
                                                state.context.productVariant.currency
                                            )
                                        }}</span>
                                    </div>
                                    <div
                                        v-if="hasNoneLdnaTest"
                                        class="warning-product-message bg-rose-50 w-full px-5 py-5 text-rose-500 mt-4 text-sm font-semibold"
                                    >
                                        <p class="my-0">
                                            The number of traits (up to 14) you receive is dependent
                                            on the genetic markers and quality of data generated
                                            from your original testing provider. Living DNA cannot
                                            guarantee the quality of other providers' data and
                                            therefore cannot guarantee the number of traits you will
                                            receive.
                                        </p>
                                    </div>
                                    <div
                                        v-else-if="state.context.product.warningMessage"
                                        v-html="state.context.product.warningMessage"
                                        class="warning-product-message bg-rose-50 w-full px-4 py-1 text-rose-500 mt-4 text-sm font-semibold rounded"
                                    />
                                    <!-- Viking warning message if < 15 index score -->
                                    <div
                                        v-else-if="state.context.warningMessage"
                                        v-html="state.context.warningMessage"
                                        class="bg-rose-50 w-full p-4 text-rose-500 mt-4 text-sm font-semibold rounded"
                                    />
                                </div>

                                <div v-if="this.state.context.productVariant.shippable">
                                    <h3 class="mb-2">Shipping Options:</h3>
                                    <div
                                        v-for="option in state.children.purchaser.state.context
                                            .shippingOptions"
                                        @click="
                                            state.children.purchaser.send('SET_SHIPPING_OPTION', {
                                                data: option,
                                            })
                                        "
                                    >
                                        <div
                                            class="border rounded mb-2 px-2 py-1 cursor-pointer"
                                            :class="{
                                                'border-cobalt-500 text-cobalt-500 bg-cobalt-50':
                                                    option.name ===
                                                    state.children.purchaser.state.context
                                                        .selectedShippingOption.name,
                                            }"
                                        >
                                            <div class="flex justify-between items-center">
                                                <p class="my-2">
                                                    {{ option.name }}
                                                </p>
                                                <span>{{
                                                    convertToCurrency(
                                                        option.shippingPrice / 100,
                                                        state.context.productVariant.currency
                                                    )
                                                }}</span>
                                            </div>
                                            <div class="capitalize"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <button
                                        class="mt-6 mb-4 bg-cobalt-500 font-semibold rounded p-3 text-center text-white w-full focus:outline-non hover:bg-cobalt-700"
                                        @click="payByCard"
                                    >
                                        Pay by card
                                    </button>
                                    <div id="paypal" class="rounded overflow-hidden"></div>
                                </div>
                                <div v-if="this.state.context.productVariant.shippable">
                                    <h3>Your order will be delivered to:</h3>
                                    <Address
                                        :address="
                                            state.children.purchaser.state.context.shippingAddress
                                        "
                                    />
                                    <button
                                        @click="
                                            state.children.purchaser.send('CHANGE_SHIPPING_ADDRESS')
                                        "
                                        class="font-semibold text-cobalt-500 text-sm"
                                    >
                                        Change
                                    </button>
                                </div>
                            </div>
                            <div
                                v-if="
                                    state.children.purchaser.state.matches('confirmingPaypalOrder')
                                "
                            >
                                <div class="loading-large h-16"></div>
                                <p class="text-center mt-4">
                                    Thank you for your order, we are now updating our records
                                </p>
                            </div>
                            <div
                                v-else-if="
                                    state.children.purchaser.state.value ===
                                        'enterBillingDetails' ||
                                    state.children.purchaser.state.matches('changingBillingAddress')
                                "
                                class="pb-32 md:pb-0"
                            >
                                <h3 class="mb-4">Billing Address</h3>
                                <FormAddress
                                    @form-submitted="handleValidatedBillingDetailsForm"
                                    form-type="billing"
                                    :address="state.children.purchaser.state.context.billingAddress"
                                />
                            </div>
                            <div
                                v-else-if="
                                    state.children.purchaser.state.matches('confirmFreeProduct') ||
                                    state.children.purchaser.state.matches('confirmingFreeProduct')
                                "
                                class="pb-32 md:pb-0"
                            >
                                <h3 class="mb-4">Please confirm your address</h3>
                                <FormAddress
                                    @form-submitted="handleValidatedBillingDetailsForm"
                                    form-type="billing"
                                    :address="state.children.purchaser.state.context.billingAddress"
                                />
                            </div>
                            <div
                                v-else-if="
                                    state.children.purchaser.state.matches('freeProductSuccess')
                                "
                            >
                                <div class="flex justify-center">
                                    <div class="bg-emerald-50 p-4 rounded-full mb-12">
                                        <Feather
                                            type="check"
                                            class="h-20 w-20 fill-current text-emerald-500"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3 class="mb-2">Success</h3>
                                    We've successfully added your free taster. We'll now start
                                    processing your results and send you an email when they're ready
                                    to view.
                                    <button
                                        @click="handleUpgradeSuccess"
                                        class="mt-4 bg-cobalt-500 font-semibold rounded p-3 text-center text-white w-full focus:outline-non hover:bg-cobalt-700"
                                    >
                                        Back to the store
                                    </button>
                                </div>
                            </div>
                            <div
                                v-else-if="
                                    state.children.purchaser.state.matches('freeProductError')
                                "
                            >
                                <div class="flex justify-center">
                                    <div
                                        class="bg-rose-50 p-4 rounded-full mb-12"
                                        @click="showModal = false"
                                    >
                                        <Feather
                                            type="x"
                                            class="h-20 w-20 fill-current text-rose-500"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3 class="mb-2">Error</h3>
                                    Unfortunately we weren't able to add your free taster. Feel free
                                    to try again and if the problem persists contact our support.
                                </div>
                            </div>
                            <div
                                v-else-if="
                                    state.children.purchaser.state.matches('enterCardDetails') ||
                                    state.children.purchaser.state.matches(
                                        'fetchingPaymentIntentClientSecret'
                                    ) ||
                                    state.children.purchaser.state.matches('chargingCard')
                                "
                                class="pb-32 md:pb-0"
                            >
                                <h2 class="text-xl mb-4">
                                    {{ state.context.product.title }}
                                </h2>
                                <table class="mb-10">
                                    <tr>
                                        <td class="pr-4">Product cost</td>
                                        <td>
                                            {{
                                                convertToCurrency(
                                                    state.context.productPrice,
                                                    state.context.productVariant.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="this.state.context.productVariant.shippable">
                                        <td>Shipping</td>
                                        <td>
                                            {{
                                                convertToCurrency(
                                                    state.children.purchaser.state.context
                                                        .selectedShippingOption.shippingPrice / 100,

                                                    state.context.productVariant.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                    <tr class="font-semibold">
                                        <td>Total</td>
                                        <td class="font-bold">
                                            {{
                                                convertToCurrency(
                                                    state.children.purchaser.state.context
                                                        .totalPrice / 100,
                                                    state.context.productVariant.currency
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </table>

                                <h3 class="mb-2">Enter your card details</h3>
                                <div id="card-element" class="bg-grey-50 rounded p-4"></div>
                                <button
                                    v-if="
                                        state.children.purchaser.state.matches(
                                            'fetchingPaymentIntentClientSecret'
                                        ) || state.children.purchaser.state.matches('chargingCard')
                                    "
                                    class="loading mt-4 bg-cobalt-500 font-semibold rounded p-3 text-center text-white w-full focus:outline-non hover:bg-cobalt-700"
                                >
                                    Submit
                                </button>
                                <div v-else>
                                    <button
                                        @click="chargeOrder"
                                        class="mt-4 bg-cobalt-500 font-semibold rounded p-3 text-center text-white w-full focus:outline-non hover:bg-cobalt-700"
                                    >
                                        Pay
                                        {{
                                            convertToCurrency(
                                                state.children.purchaser.state.context.totalPrice /
                                                    100,
                                                state.context.productVariant.currency
                                            )
                                        }}
                                        by card
                                    </button>
                                    <div class="flex justify-center mt-2">
                                        <button
                                            class="font-semibold text-sm text-grey-600 hover:text-grey-700"
                                            @click="
                                                state.children.purchaser.send(
                                                    'CHANGE_PAYMENT_METHOD'
                                                )
                                            "
                                        >
                                            Change payment method
                                        </button>
                                    </div>
                                </div>
                                <div class="md:flex mb-6 mt-12">
                                    <div
                                        v-if="this.state.context.productVariant.shippable"
                                        class="md:w-1/2 mb-12 md:mb-0"
                                    >
                                        <h3 class="mb-2">Shipping Address</h3>
                                        <Address
                                            class="mb-1"
                                            :address="
                                                state.children.purchaser.state.context
                                                    .shippingAddress
                                            "
                                        />
                                        <button
                                            @click="
                                                state.children.purchaser.send(
                                                    'CHANGE_SHIPPING_ADDRESS'
                                                )
                                            "
                                            class="font-semibold text-cobalt-500 text-sm"
                                        >
                                            Change
                                        </button>
                                    </div>
                                    <div class="md:w-1/2">
                                        <h3 class="mb-2">Billing Address</h3>
                                        <Address
                                            class="mb-1"
                                            :address="
                                                state.children.purchaser.state.context
                                                    .billingAddress
                                            "
                                        />
                                        <button
                                            @click="
                                                state.children.purchaser.send(
                                                    'CHANGE_BILLING_ADDRESS'
                                                )
                                            "
                                            class="font-semibold text-cobalt-500 text-sm"
                                        >
                                            Change
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <OrderSuccess
                                v-if="state.children.purchaser.state.matches('paymentSucceeded')"
                                :order-key="state.children.purchaser.state.context.order.orderKey"
                                :order-value="state.context.productPrice"
                                @return-to-store="handleModalChange(false)"
                            />
                            <div
                                v-if="
                                    state.children.purchaser.state.matches('paymentFailed') ||
                                    state.children.purchaser.state.matches('paypalPaymentFailed')
                                "
                            >
                                <div class="flex justify-center">
                                    <div
                                        @click="handleModalChange(false)"
                                        class="bg-rose-50 p-4 rounded-full mb-12"
                                    >
                                        <Feather
                                            type="x"
                                            class="h-20 w-20 fill-current text-rose-500"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3 class="mb-2">Payment failed</h3>
                                    {{ state.children.purchaser.state.context.paymentError }}
                                </div>
                            </div>
                            <div
                                v-if="
                                    state.children.purchaser.state.matches(
                                        'errorFetchingPaymentIntent'
                                    )
                                "
                            >
                                <div class="flex justify-center">
                                    <div class="bg-rose-50 p-4 rounded-full mb-12">
                                        <Feather
                                            type="x"
                                            class="h-20 w-20 fill-current text-rose-500"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h3 class="mb-2">Payment failed</h3>
                                    Something went wrong. Your card has not been charged, feel free
                                    to try again. If the issue persists we suggest getting in
                                    contact with us.
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div
                    v-if="state.context.product.bannerText"
                    v-html="state.context.product.bannerText"
                    class="bg-cobalt-500 w-full px-5 py-5 flex justify-center description-text text-white font-semibold"
                />
                <div class="py-12 px-6 container mx-auto">
                    <div class="rounded-lg w-full bg-white flex flex-wrap p-12 shadow mb-6">
                        <ProductImageViewer
                            :public-path="path"
                            :data-images="state.context.product.images"
                        />
                        <div class="lg:w-1/2 sm:w-full lg:pl-6">
                            <h2 class="text-denim-700 mb-2 text-3xl font-normal leading-normal">
                                {{ state.context.product.title }}
                            </h2>
                            <div class="inline-flex w-full mb-5 items-center">
                                <div
                                    :class="{
                                        'text-emerald-500': state.context.selectedPrice === 0,
                                    }"
                                    class="text-grey-700 mb-2 text-3xl font-normal mr-2"
                                >
                                    {{
                                        convertToCurrency(
                                            state.context.productPrice,
                                            state.context.productVariant.currency
                                        )
                                    }}
                                </div>
                                <span v-if="state.matches('productLoaded.vitlabProduct')"
                                    >per month</span
                                >
                            </div>
                            <div
                                class="description-text text-grey-700 font-normal leading-normal mb-4"
                                v-html="state.context.product.shortDescription"
                            ></div>
                            <p class="text-grey-700 font-normal leading-normal mb-4">
                                You will receive:
                            </p>
                            <ul class="list-none text-grey-700 font-normal leading-normal mb-12">
                                <ProductListItem
                                    v-for="item in state.context.product.featureBulletPoints"
                                    :key="item.id"
                                    :text="item"
                                />
                            </ul>
                            <button
                                @click="handlePurchaseProduct()"
                                class="rounded bg-cobalt-500 text-white px-4 py-3 font-normal leading-normal focus:outline-none min-w-200 font-semibold hover:bg-cobalt-700"
                            >
                                {{ buttonText }}
                            </button>
                        </div>
                    </div>
                    <div class="flex lg:flex-row flex-col">
                        <div
                            class="rounded-lg w-full bg-white p-12 shadow lg:mr-6 lg:mb-0 mb-6 flex-grow"
                        >
                            <h3 class="text-denim-700 font-normal leading-normal text-2xl mb-4">
                                Description
                            </h3>
                            <div
                                v-html="state.context.product.longDescription"
                                class="description-text text-grey-700 font-normal leading-normal mb-0"
                            ></div>
                        </div>
                        <div class="rounded-lg w-full bg-cobalt-50 flex flex-col p-12">
                            <h3 class="text-denim-700 font-normal leading-normal text-2xl mb-4">
                                FAQs
                            </h3>
                            <FAQs :questions="state.context.product.faq"></FAQs>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { interpret } from "xstate";
import { useActor } from "@xstate/vue";
import FormAddress from "./FormAddress";
import BaseTextInput from "@/components/inputs/BaseTextInput.vue";
import { loadStripe } from "@stripe/stripe-js";
import { productMachine, productService } from "@/views/store/product/productMachine";
import ProductImageViewer from "./ProductImageViewer.vue";
import ProductListItem from "./ProductListItem.vue";
import FAQs from "@/components/faqs/FAQs.vue";
import countries from "@/countries";
import Address from "./Address.vue";
import Modal from "@/components/Modal.vue";
import { useRoute } from "vue-router";
import store from "@/store/store";
import PageLoader from "@/components/PageLoader.vue";
import OrderSuccess from "./OrderSuccess.vue";
import { useOrderStore } from "@/store/orders";

// extend("required", {
//     ...required,
//     message: "The {_field_} field is required",
// });

let unsubscribe;

export default {
    data() {
        return {
            // productService: undefined,
            // state: undefined,
            path: process.env.BASE_URL,
            countries,
            stripe: undefined,
            card: undefined,
            sub: undefined,
            showModal: false,
            purchaseState: undefined,
        };
    },
    async beforeRouteEnter(to, from, next) {
        await store.dispatch("GET_REGION");
        if (!store.getters.getRegion) {
            next(`/store/set-region/${to.params.slug}`);
        }
        next();
    },
    setup() {
        const route = useRoute();
        const { state, send } = useActor(productService);
        send({
            type: "FETCH_PRODUCT",
            slug: route.params.slug,
            profile: store.getters.profile,
            user: store.getters.account,
        });

        unsubscribe = store.subscribe(({ type }) => {
            if (type === "SET_KIT_TESTS") {
                window.location.reload();
            }
        });

        return { state, send };
    },
    async created() {
        let subbed = false;
        productService.subscribe((newState) => {
            if (newState.value === "purchasingProduct" && !subbed) {
                const { state } = useActor(newState.children.purchaser);
                newState.children.purchaser.subscribe((childNewState) => {
                    const routeParams = this.$route.params;
                    if (childNewState.value === "paymentSucceeded") {
                        const orderStore = useOrderStore();
                        orderStore.setOrderDetails({
                            id: childNewState.context.order.orderKey,
                            product: routeParams.slug.toLowerCase(),
                            value: childNewState.context.productPrice,
                        });

                        this.$router.push({
                            name: "order-success",
                        });
                    }
                });
                this.purchaseState = state;
                subbed = true;
            }
        });

        this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_ID);
        const elements = this.stripe.elements();
        this.card = elements.create("card");
    },
    unmounted() {
        console.log("destroyed");
        productService.send("RESET");
        unsubscribe();
    },
    components: {
        ProductImageViewer,
        ProductListItem,
        FAQs,
        Address,
        Modal,
        FormAddress,
        PageLoader,
        OrderSuccess,
    },
    methods: {
        payByCard() {
            this.state.children.purchaser.send("PAY_BY_CARD");
        },
        convertToCurrency(value, type) {
            if (value === 0) {
                return "FREE";
            }
            if (typeof type !== "undefined") {
                return Number(value).toLocaleString(undefined, {
                    style: "currency",
                    currency: type,
                });
            }
            return Number(0).toLocaleString(undefined, { style: "currency", currency: "USD" });
        },
        handleShippingCountryChange($event) {
            // this.state.children.purchaser.send("INPUT", {
            //     input: "countryCode",
            //     value: $event.target.value,
            // });
            this.state.children.purchaser.send("CHANGE_SHIPPING_COUNTRY", {
                input: "countryCode",
                value: $event.target.value,
            });
        },
        handlePurchaseProduct() {
            // the reason we let the subscription open the window was to avoid showing it for vitlab products
            console.log(this.state.value);
            if (
                this.state.matches("purchasingProduct") ||
                (this.state.value.productLoaded && !this.state.value.productLoaded.vitlabProduct)
            ) {
                this.showModal = true;
            }
            this.send("START_PURCHASING_PRODUCT");
        },
        handleValidatedForm(address) {
            try {
                this.state.children.purchaser.send("SET_ADDRESS", {
                    address,
                });
                this.state.children.purchaser.send("CONFIRM");
            } catch (e) {
                this.state.children.purchaser.send("CONFIRM");
            }
        },
        handleValidatedBillingDetailsForm(address) {
            console.log(address);
            this.state.children.purchaser.send("SET_ADDRESS", {
                address,
            });
            this.state.children.purchaser.send("CONFIRM", {
                mount: this.card.mount,
            });
        },
        async chargeOrder() {
            this.state.children.purchaser.send("CHARGE_CARD", {
                stripe: this.stripe,
                card: this.card,
            });
        },
        async refreshKitTests() {
            console.log("refreshing kit tests");
            await this.$store.dispatch("SET_KIT_TESTS", this.$store.getters.profile.barcode);
        },
        async handleModalChange(modalState) {
            this.showModal = modalState;
            if (!modalState) {
                const shouldRefreshKitTests =
                    this.state.children.purchaser.state.matches("paymentSucceeded") ||
                    this.state.children.purchaser.state.matches("freeProductSuccess");
                this.state.children.purchaser.send("PAYMENT_MODAL_CLOSED");
                if (shouldRefreshKitTests) {
                    this.refreshKitTests();
                    this.$router.push({ name: "products" });
                }
            }
        },
        async handleUpgradeSuccess() {
            await this.refreshKitTests();
            this.$router.push({ name: "products" });
        },
    },
    computed: {
        buttonText() {
            return this.state.context.productPrice === 0 ? "Get free" : "Purchase";
        },
        hasNoneLdnaTest() {
            return !!(
                this.state.context.productVariant.sku.match("LDNA-WELLBEING-UPGRADE") &&
                this.state.context.profile.barcode.match("LT")
            );
        },
    },
};
</script>
