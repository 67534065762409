<template>
    <DoughnutChart :chartData="chartData" :options="options" />
</template>

<script>
import { defineComponent, ref } from "vue";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default defineComponent({
    name: "Home",
    components: { DoughnutChart },
    props: {
        chartData: Object,
    },
    setup() {
        const options = ref({
            events: [],
            animation: {
                duration: 0,
            },
            elements: {
                arc: {
                    borderWidth: 0,
                },
            },
        });

        return { options };
    },
});
</script>
