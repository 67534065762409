<template>
    <div>
        <h3 class="text-denim-700 text-2xl font-normal text-center">Sign up</h3>
        <form @submit.prevent="submit" class="mt-8">
            <TextInput
                v-model="firstName"
                label="First name"
                :error-bag="requestErrors"
                error-key="firstName"
            />
            <TextInput
                class="mt-2"
                v-model="lastName"
                label="Last name"
                :error-bag="requestErrors"
                error-key="lastName"
            />
            <TextInput
                class="mt-2"
                v-model="emailAddress"
                label="Email address"
                :error-bag="requestErrors"
                error-key="email"
            />
            <div class="mt-2">
                <label class="font-semibold block w-full text-sm mb-1">
                    Country of residence
                </label>
                <CountryPicker v-model="country" />
            </div>
            <PasswordInput
                class="mt-2"
                v-model="password"
                :new-password="true"
                :error-text="passwordError ? passwordError.detail : ''"
                @blur="validatePassword"
                @input="updatePasswordError"
            />
            <div class="mt-4">
                <div class="flex items-center">
                    <input
                        type="checkbox"
                        id="accept-terms"
                        v-model="acceptTerms"
                        class="form-checkbox rounded w-6 h-6 text-cobalt-500 mr-3 border-grey-500"
                    />
                    <label for="accept-terms" class="cursor-pointer text-xs">
                        I agree to the
                        <router-link to="/signup/view-terms">Terms of Service</router-link> and
                        <a
                            href="https://livingdna.com/uk/privacy-centre"
                            target="_blank"
                            rel="noopener"
                            >Privacy Policy</a
                        >
                    </label>
                </div>
                <div class="flex items-center mt-2">
                    <input
                        type="checkbox"
                        id="accept-marketing"
                        v-model="acceptMarketing"
                        class="form-checkbox rounded w-6 h-6 text-cobalt-500 mr-3 border-grey-500"
                    />
                    <label for="accept-marketing" class="cursor-pointer text-xs">
                        Email me with Living DNA news and promotions
                    </label>
                </div>
            </div>
            <div class="mt-4">
                <button
                    :class="{
                        loading: loading,
                        'bg-grey-200 text-grey-500': !readyToSubmit,
                        'bg-cobalt-500 text-white': readyToSubmit,
                    }"
                    :disabled="!readyToSubmit"
                    class="rounded w-full py-2 px-4 font-semibold"
                >
                    Create account
                </button>
            </div>
            <div class="mt-6 text-center">
                Already have an account? <router-link to="/signin">Log in</router-link>
            </div>
        </form>
    </div>
</template>

<script>
import TextInput from "@/components/inputs/TextInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput/PasswordInput.vue";
import CountryPicker from "@/components/CountryPicker/CountryCodePicker.vue";

function isJson(string) {
    try {
        JSON.parse(string);
        return true;
    } catch (e) {
        return false;
    }
}

export default {
    components: { TextInput, PasswordInput, CountryPicker },
    data() {
        return {
            firstName: "",
            lastName: "",
            emailAddress: "",
            country: {},
            password: "",
            acceptTerms: false,
            acceptMarketing: false,
            loading: false,
            requestErrors: [],
            passwordError: { detail: "" },
        };
    },
    methods: {
        submit() {
            this.loading = true;
            let partner;
            if (!isJson(this.$store.getters.referrer)) {
                partner = this.$store.getters.referrer;
            }
            this.$store
                .dispatch("createUserAccount", {
                    account: {
                        firstName: this.firstName,
                        lastName: this.lastName,
                        emailAddress: this.emailAddress,
                        password: this.password,
                        country: this.country,
                        acceptTerms: this.acceptTerms,
                        acceptMarketing: this.acceptMarketing,
                        partner,
                    },
                    $auth: this.$auth,
                })
                .then(() => {
                    this.$router.push({
                        name: "verification-email-sent",
                    });
                })
                .catch((err) => {
                    const errors = err.response?.data?.errors;
                    if (errors) {
                        this.requestErrors = err.response.data.errors;
                    } else {
                        this.requestErrors = ["Something went wrong creating the account"];
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        validatePassword() {
            if (this.passwordError && this.password.length < 8) {
                this.passwordError.detail =
                    "This password is too short. It must contain at least 8 characters.";
            }
        },
        updatePasswordError() {
            if (this.passwordError && this.passwordError.detail && this.password.length >= 8) {
                this.passwordError.detail = "";
            }
        },
    },
    watch: {
        requestErrors() {
            this.passwordError = this.requestErrors.find((err) => err.source === "first");
        },
    },
    computed: {
        readyToSubmit() {
            return (
                this.lastName &&
                this.emailAddress &&
                this.country &&
                this.acceptTerms &&
                this.password.length >= 8
            );
        },
    },
};
</script>
