import { mapGetters } from "vuex";

export const setDefaultProfile = {
    computed: mapGetters(["profiles"]),

    methods: {
        setDefaultProfile(barcode) {
            const { permission } = this.profiles.find(({ profile }) => profile.barcode === barcode);

            this.$store.dispatch("setDefaultProfile", permission.id);
        },
    },
};

export const setTest = {
    methods: {
        async setTest(barcode) {
            await this.$store.dispatch("SET_TEST_FROM_TESTS", barcode);
            await this.$store.dispatch("SET_KIT_TESTS", barcode);
        },
    },
};
