<template>
    <ThePage class="max-w-6xl" v-if="messagingCentreState">
        <h1 class="text-2xl font-normal mt-4 mb-6">
            <Feather type="users" class="h-5 w-5 mr-2" />
            {{ $store.getters.firstName }}'s messages
        </h1>
        <div v-if="messagingCentreState.matches('idle')">
            <p>You have exchanged messages with the following profiles</p>
            <div class="bg-white rounded-lg">
                <div
                    v-for="(contact, index) in messagingCentreState.context.contacts"
                    :key="contact.converstation_with"
                >
                    <router-link
                        :to="{
                            name: 'match-details',
                            params: { barcode: contact.conversation_with },
                        }"
                        class="px-3 sm:px-6 py-4 flex flex-wrap items-center border-grey-200 hover:shadow-key-hover border-grey-50"
                        :class="{
                            'border-b': index !== messagingCentreState.context.contacts.length - 1,
                        }"
                    >
                        <div class="md:w-2/6 text-grey-700 flex items-center">
                            <img
                                :src="
                                    messagingCentreState.context.hydratedContacts[
                                        contact.conversation_with
                                    ].photo || defaultAvatar
                                "
                                class="h-16 w-16 rounded-full mr-4"
                            />
                            <div>
                                <div>
                                    {{
                                        messagingCentreState.context.hydratedContacts[
                                            contact.conversation_with
                                        ].name
                                    }}
                                </div>
                                <div class="text-grey-600">
                                    {{ new Date(contact.sentAt).toLocaleString("en-US") }}
                                </div>
                            </div>
                            <FeatherWrapper
                                v-if="contact.blockedChannel"
                                icon="x-circle"
                                class="ml-2"
                            />
                        </div>
                        <div
                            v-html="contact.message"
                            class="md:w-3/6 text-grey-700 whitespace-no-wrap truncate py-4 md:py-0"
                        />
                        <div
                            v-if="contact.unread"
                            class="flex w-full md:w-auto md:flex-col items-center mr-12 text-grannysmith-500 md:mx-auto py-4 md:py-0"
                        >
                            <Feather type="mail" class="h-5 w-5 mr-2 md:mr-0" />
                            <p class="text-sm my-0">NEW</p>
                        </div>
                        <div
                            class="flex md:justify-end items-center w-full md:w-auto md:ml-auto mr-0"
                        >
                            View
                            <FeatherWrapper icon="chevron-right" class="ml-2" />
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-else-if="messagingCentreState.matches('error')" class="bg-white rounded-lg">
            Something went wrong
        </div>
        <div
            v-else-if="messagingCentreState.matches('noContacts')"
            class="bg-white rounded-lg p-6 text-center"
        >
            Looks like you haven't sent or received any messages on this profile yet. <br />
            <router-link :to="{ name: 'list-view' }" class="font-semibold"
                >Visit your match list to choose someone to message.</router-link
            >
        </div>
        <div
            v-else-if="messagingCentreState.matches('noContacts')"
            class="bg-white rounded-lg p-6 text-center"
        >
            Looks like you haven't sent or received any messages on this profile yet. <br />
            <router-link :to="{ name: 'list-view' }" class="font-semibold"
                >Visit your match list to choose someone to message.</router-link
            >
        </div>
        <div
            v-else-if="messagingCentreState.matches('noCachedContacts')"
            class="bg-white rounded-lg p-6 text-center"
        >
            Although it appears you may have exchanged messages with other users, it looks like
            everyone you've exchanged messages with has either subsequently opted out of family
            matching or deleted their profile. <br />
            <router-link :to="{ name: 'list-view' }" class="font-semibold"
                >Visit your match list to choose someone to message.</router-link
            >
        </div>
        <div v-else class="text-center">
            <div class="loading-large mb-6 mt-12" />
            Loading your conversations
        </div>
    </ThePage>
</template>

<script>
import { messagingCentreService } from "@/views/FamilyNetworks/FamilyMatching/MessagingCentre/messagingCentreMachine";
import defaultAvatar from "@/assets/images/avatar-default.png";
import ThePage from "@/components/Base/ThePage.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

let subbed;

export default {
    components: { FeatherWrapper, ThePage },
    data() {
        return {
            defaultAvatar,
            messagingCentreState: undefined,
        };
    },
    async created() {
        messagingCentreService.subscribe((state) => {
            this.messagingCentreState = state;
        });
        messagingCentreService.send("FETCH_CONTACTS", {
            barcode: this.$store.getters.profile.barcode,
        });
        if (!subbed) {
            this.storeSub = this.$store.subscribeAction({
                after: async ({ type }) => {
                    if (type === "SET_KIT_TESTS") {
                        messagingCentreService.send("FETCH_CONTACTS", {
                            barcode: this.$store.getters.profile.barcode,
                        });
                    }
                },
            });
            subbed = true;
        }
    },
    unmounted() {
        messagingCentreService.stop();
        messagingCentreService.start();
    },
};
</script>
