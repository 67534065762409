<template>
    <PageLoader v-if="loadingStatus === 'loading'" class="mt-12" />
    <div class="max-w-2xl px-4 py-8 m-auto prose" v-else>
        <h1>{{ references.heading }}</h1>
        <div v-html="references.text" />
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { getArchaicReferences } from "@/services/archaic";
import PageLoader from "@/components/PageLoader.vue";

export default {
    components: { PageLoader },
    setup() {
        const loadingStatus = ref("loading");
        const references = ref({});
        onMounted(async () => {
            references.value = await getArchaicReferences();
            loadingStatus.value = "loaded";
        });
        return { references, loadingStatus };
    },
};
</script>
