<template>
    <div class="flex items-center">
        <div
            class="h-3 w-3 mr-3 rounded-full"
            :class="circleBg"
            :style="{ backgroundColor: circleColour }"
        ></div>
        <div class="text-grey-500 text-sm">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: { circleBg: String, circleColour: String },
    computed: {
        thing() {
            return this.$store.state.getters.profile;
        },
    },
};
</script>
