<template>
    <div class="bg-white shadow-key rounded">
        <div class="py-4 px-6">
            <h3 class="text-lg">{{ title }}</h3>
        </div>
        <div class="px-6 pt-4 rounded-b">
            <ReportListItem v-for="report in reports" :key="report.type" :report="report" />
        </div>
    </div>
</template>

<script>
import Feather from "vue-feather";
import ScaleMapper from "@/components/Wellbeing/ScaleMapper.vue";
import ReportListItem from "@/components/Wellbeing/AllReports/ReportList/ReportListItem.vue";

export default {
    components: { ReportListItem, Feather, ScaleMapper },
    props: {
        title: {
            type: String,
            required: true,
        },
        reports: {
            type: Array,
            required: true,
        },
    },
};
</script>
