<template>
    <div>
        <div v-for="result in results" :key="result.id">
            <div
                class="flex items-center justify-between text-base font-normal text-gray-600 mb-2 mt-4"
            >
                <h4
                    class="font-normal text-grey-700"
                    :class="result.children.length > 0 ? 'text-base' : 'text-sm'"
                    v-text="result.title"
                />
                <div
                    v-text="result.percentage + '%'"
                    :class="result.children.length > 0 ? 'text-base' : 'text-sm'"
                />
            </div>
            <div
                v-for="child in result.children"
                :key="child.id"
                class="flex items-center justify-between text-sm mb-2 pl-4"
            >
                <div class="">{{ child.title }}</div>
                <div class="text-gray-600">{{ child.percentage }}%</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        results: Array,
    },
};
</script>
