<template>
    <div class="p-6 bg-white min-h-screen">
        <div class="max-w-4xl mx-auto md:pt-16">
            <div
                class="flex items-center md:items-baseline lg:items-center flex-col md:flex-row justify-between"
            >
                <div class="flex items-center text-denim-700">
                    <feather-wrapper
                        icon="helix"
                        :classes="['w-8 h-8']"
                        :containerClasses="['w-8 h-8']"
                    />

                    <h1 class="ml-4 text-3xl font-normal">Profiles</h1>
                </div>

                <div class="flex items-center flex-col sm:flex-row md:flex-col lg:flex-row">
                    <router-link
                        :to="{ name: 'who-does-this-dna-belong-to' }"
                        class="py-2 flex items-center font-semibold cursor-pointer text-cobalt-500 hover:text-cobalt-700"
                    >
                        <feather-wrapper icon="upload" />

                        <span class="ml-2">Upload DNA file</span>
                    </router-link>

                    <router-link
                        :to="{ name: 'enter-code' }"
                        class="flex items-center ml-0 sm:ml-8 md:ml-0 md:mt-2 lg:mt-0 lg:ml-8 bg-cobalt-500 px-4 py-2 rounded font-semibold cursor-pointer hover:bg-cobalt-700"
                    >
                        <feather-wrapper icon="toggle-right" class="text-white" />

                        <span class="ml-2 text-white">Activate kit</span>
                    </router-link>
                </div>
            </div>

            <!-- desktop -->
            <table class="mx-auto mt-24 w-full hidden lg:table" style="border-spacing: 0">
                <thead>
                    <tr class="text-grey-500 font-semibold text-sm">
                        <td class="pb-6"></td>
                        <td class="pb-6">Reference</td>
                        <td class="pb-6">Added</td>
                        <td class="pb-6">Type</td>
                        <td class="pb-6">Status</td>
                        <td class="pb-6"></td>
                    </tr>
                </thead>

                <tbody class="shadow-key rounded-lg">
                    <tr
                        v-for="(profile, index) in accountTests"
                        :key="profile.barcode"
                        class="tr--hover-animate first:rounded-t-lg last:rounded-b-lg border-grey-200 hover:shadow-key-hover"
                        :class="{ 'border-b': index !== accountTests.length - 1 }"
                    >
                        <td class="pl-6 py-5 font-semibold">
                            <router-link
                                class="link-colour"
                                :to="{ name: 'profile-view', params: { barcode: profile.barcode } }"
                            >
                                {{ profile.participantName }}
                            </router-link>
                        </td>

                        <td>{{ profile.barcode }}</td>

                        <td>{{ date(profile.orderDate.date) }}</td>

                        <td>{{ formattedTestType(profile.testType) }}</td>

                        <td>
                            <KitStatus :status="profile.raw_status" v-slot="{ kitStatus }">
                                <span
                                    class="py-2 px-3 text-sm font-semibold rounded"
                                    :style="{
                                        backgroundColor: kitStatus.secondaryColour,
                                        color: kitStatus.primaryColour,
                                    }"
                                >
                                    {{ kitStatus.formattedName }}
                                </span>
                            </KitStatus>
                        </td>

                        <td class="pr-6 text-cobalt-500">
                            <div class="flex">
                                <router-link
                                    class="ml-auto px-2"
                                    :to="{ name: 'download', params: { barcode: profile.barcode } }"
                                    aria-label="Download your raw DNA"
                                >
                                    <feather-wrapper icon="download" />
                                </router-link>

                                <router-link
                                    :to="`/profiles/${profile.barcode}`"
                                    class="ml-2 px-2"
                                    aria-label="View profile"
                                >
                                    <feather-wrapper icon="chevron-right" />
                                </router-link>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- mobile -->
            <div class="flex flex-col lg:hidden">
                <div
                    v-for="{
                        barcode,
                        participantName,
                        orderDate,
                        testType,
                        raw_status,
                    } in accountTests"
                    :key="barcode"
                    class="p-6 pb-3 mt-6 rounded shadow-key flex flex-col sm:flex-row flex-wrap flex-auto justify-between items-baseline sm:items-end"
                >
                    <p class="mt-0 mb-6 w-full leading-tight font-semibold text-denim-700 text-lg">
                        {{ participantName }}
                    </p>

                    <p class="sm:w-1/2 mt-0 mb-4">
                        <span class="mb-1 block text-grey-500 leading-cosy font-semibold text-sm">
                            Reference
                        </span>

                        <span>{{ barcode }}</span>
                    </p>

                    <p class="sm:w-1/2 mt-0 mb-4">
                        <span class="mb-1 block text-grey-500 leading-cosy font-semibold text-sm">
                            Added
                        </span>

                        <span>{{ date(orderDate.date) }}</span>
                    </p>

                    <p class="sm:w-1/2 mt-0 mb-4">
                        <span class="mb-1 block text-grey-500 leading-cosy font-semibold text-sm">
                            Type
                        </span>

                        <span class="capitalize">{{ formattedTestType(testType) }}</span>
                    </p>

                    <p class="sm:w-1/2 mt-0 mb-4">
                        <span class="mb-2 block text-grey-500 leading-cosy font-semibold text-sm">
                            Status
                        </span>

                        <KitStatus :status="raw_status" v-slot="{ kitStatus }">
                            <span
                                class="py-2 px-3 text-sm font-semibold rounded"
                                :style="{
                                    backgroundColor: kitStatus.secondaryColour,
                                    color: kitStatus.primaryColour,
                                }"
                            >
                                {{ kitStatus.formattedName }}
                            </span>
                        </KitStatus>
                    </p>

                    <router-link
                        :to="{ name: 'download', params: { barcode } }"
                        class="py-3 sm:w-1/2"
                    >
                        <p class="m-0 flex items-center">
                            <feather-wrapper icon="download" />

                            <span class="ml-2">Download raw DNA</span>
                        </p>
                    </router-link>

                    <router-link :to="`/profiles/${barcode}`" append class="py-3 sm:w-1/2">
                        <p class="m-0 flex items-center">
                            <feather-wrapper icon="eye" />

                            <span class="ml-2">View profile</span>
                        </p>
                    </router-link>
                </div>
            </div>

            <router-link
                :to="{ name: 'activation-start' }"
                class="inline-flex items-center ml-5 mt-6 font-semibold"
            >
                <feather-wrapper icon="plus" />

                <span class="ml-2">Add new profile</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import KitStatus from "@/components/utilities/KitStatus.vue";
import { formatDate } from "@/services/profile";

export default {
    name: "ProfileList",

    components: {
        FeatherWrapper,
        KitStatus,
    },

    computed: mapGetters(["accountTests"]),

    methods: {
        date: formatDate,
        formattedTestType(type) {
            if (type !== "upload") {
                return "DNA kit";
            }
            return "Upload";
        },
    },
};
</script>

<style>
.link-colour {
    @apply text-grey-700 !important;
}
</style>
