import { Machine, assign, interpret } from "xstate";
import { useNewWellbeing } from "@/services/health";

const wellbeingDashboardMachine = Machine(
    {
        initial: "idle",
        context: {
            profile: {},
            kitTests: {},
        },
        states: {
            idle: {
                on: {
                    INIT: {
                        actions: "cacheRequiredData",
                        target: "determiningState",
                    },
                },
            },
            determiningState: {
                on: {
                    "": [
                        {
                            cond: "shouldUseNewWellbeing",
                            target: "useNewWellbeing",
                        },
                        {
                            cond: "isFullCompleted",
                            target: "fullCompleted",
                        },
                        {
                            cond: "isUpgrading",
                            target: "upgrading",
                        },
                        {
                            cond: "isProcessingFull",
                            target: "processingFull",
                        },
                        {
                            cond: "isStarterCompleted",
                            target: "starterCompleted",
                        },
                        {
                            cond: "isProcessingStarter",
                            target: "processingStarter",
                        },
                        {
                            cond: "hasOnlyVitlabString",
                            target: "onlyVitlabString",
                        },
                        {
                            target: "noWellbeing",
                        },
                    ],
                },
            },
            useNewWellbeing: {},
            fullCompleted: {
                on: {
                    INIT: {
                        actions: "cacheRequiredData",
                        target: "determiningState",
                    },
                },
            }, // done
            upgrading: {
                on: {
                    INIT: {
                        actions: "cacheRequiredData",
                        target: "determiningState",
                    },
                },
            }, // done
            processingFull: {
                on: {
                    INIT: {
                        actions: "cacheRequiredData",
                        target: "determiningState",
                    },
                },
            }, // done
            starterCompleted: {
                on: {
                    INIT: {
                        actions: "cacheRequiredData",
                        target: "determiningState",
                    },
                },
            }, // done
            processingStarter: {
                on: {
                    INIT: {
                        actions: "cacheRequiredData",
                        target: "determiningState",
                    },
                },
            }, // done
            onlyVitlabString: {
                on: {
                    INIT: {
                        actions: "cacheRequiredData",
                        target: "determiningState",
                    },
                },
            },
            noWellbeing: {
                on: {
                    INIT: {
                        actions: "cacheRequiredData",
                        target: "determiningState",
                    },
                },
            },
        },
    },
    {
        guards: {
            shouldUseNewWellbeing: () => useNewWellbeing.value,
            isFullCompleted: (ctx) => ctx.kitTests.enduranceBloodVessel.status === "completed",
            isUpgrading: (ctx) =>
                ctx.kitTests.enduranceBloodVessel.status === "processing" &&
                ctx.kitTests.strength.status === "completed",
            isProcessingFull: (ctx) =>
                ctx.kitTests.enduranceBloodVessel.status === "processing" &&
                ctx.kitTests.strength.status === "processing",
            isStarterCompleted: (ctx) => ctx.kitTests.strength.status === "completed",
            isProcessingStarter: (ctx) => ctx.kitTests.strength.status === "processing",
            hasOnlyVitlabString: (ctx) => ctx.profile.vitlabString,
        },
        actions: {
            cacheRequiredData: assign({
                profile: (_, event) => event.profile,
                kitTests: (_, event) => event.kitTests,
            }),
        },
    }
);

export const wellbeingDashboardService = interpret(wellbeingDashboardMachine).start();

// The below can be used to easily provide testing values for the wellbeing machine
// this.wellbeingDashboardService.send('INIT', {
//     profile: {
//         ...this.$store.getters.profile,
//         vitlabString: '785845',
//     },
//     kitTests: {
//         enduranceBloodVessel: {
//             status: 'completed',
//         },
//         strength: {
//             status: 'completed',
//         },
//     },
// });

// Wellbeing WIP
// {{ wellbeingDashboardService.state.value }}
