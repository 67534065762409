import WellbeingService from "@/services/wellbeing/wellbeing";
import { hasSnps } from "@/services/wellbeing/wellbeingHelpers";

function randomItem(items) {
    return items[Math.floor(Math.random() * items.length)];
}

export default {
    state: {
        wellbeingLoadedFor: "",
        isWellbeingLoaded: false,
        wellbeing: {},
        selectedFocusIndex: 0,
    },
    getters: {
        wellbeing: state => state.wellbeing,
        selectedFocusIndex: state =>
            Number(localStorage.getItem("currentFocus") || state.selectedFocusIndex),
        isWellbeingLoaded: state => state.isWellbeingLoaded,
        traitBySlug: state => slug => state.wellbeing.find(t => t.traitCmsData.slug === slug),
        isFullWellbeingPurchased: state => WellbeingService.hasPurchased(state.wellbeing),
        isFullWellbeingPending: state => WellbeingService.isFullPending(state.wellbeing),
        isFullWellbeingComplete: state => WellbeingService.hasCompleted(state.wellbeing),
        completeWellbeingReports: state =>
            WellbeingService.completeWellbeingReports(state.wellbeing),
        fitnessReports: (state, getters) =>
            WellbeingService.filterToFitnessReports(getters.wellbeing),
        nutritionReports: (state, getters) =>
            WellbeingService.filterOutFitnessReports(getters.wellbeing),
        randomNutritionReport: (state, getters) =>
            randomItem(getters.nutritionReports.filter(hasSnps)),
        randomFitnessReport: (state, getters) => randomItem(getters.fitnessReports.filter(hasSnps)),
    },
    actions: {
        async fetchWellbeingData({ commit, state, getters }, barcode) {
            try {
                // if (state.wellbeingLoadedFor !== barcode) {
                const wellbeing = await WellbeingService.get(barcode);
                const mergedWellbeing = WellbeingService.mergeTestsAndCmsData(
                    wellbeing,
                    getters.kitTests
                );
                commit("setWellbeingPreview", { barcode, mergedWellbeing });
                // }
            } catch (e) {
                console.log(e);
            }
        },
        setSelectedFocusIndex({ commit }, index) {
            commit("setSelectedFocusIndex", index);
        },
    },
    mutations: {
        setWellbeingPreview(state, { barcode, mergedWellbeing }) {
            state.wellbeingLoadedFor = barcode;
            state.wellbeing = mergedWellbeing;
            state.isWellbeingLoaded = true;
        },
        setSelectedFocusIndex(state, index) {
            localStorage.setItem("currentFocus", index);
            state.selectedFocusIndex = index;
        },
    },
};
