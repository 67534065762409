<template>
    <profile-form
        headerText="Biological sex"
        :buttonDisabled="buttonDisabled"
        :profile="profile"
        :partners="partners"
        :fieldData="{ gender }"
        @submit="onSubmit"
    >
        <p class="my-4">
            We ask for this because it is used to provide you with high-quality, accurate DNA
            reports.
        </p>

        <div class="flex">
            <input
                v-model="gender"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="Male"
                type="radio"
                :value="1"
            />
            <label for="Male" class="cursor-pointer text-sm leading-none block mt-px font-semibold">
                Male
            </label>
        </div>

        <div class="mt-5 flex">
            <input
                v-model="gender"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="Female"
                type="radio"
                :value="2"
            />
            <label
                for="Female"
                class="cursor-pointer text-sm leading-none block mt-px font-semibold"
            >
                Female
            </label>
        </div>
    </profile-form>
</template>

<script>
import ProfileForm from "@/components/Profile/Edit/ProfileForm.vue";

export default {
    name: "EditSex",

    props: {
        profile: {
            type: Object,
            required: true,
        },
        partners: {
            type: Array,
            required: true,
        },
    },

    components: {
        ProfileForm,
    },

    data() {
        return {
            gender: this.profile.gender,
            initialGender: this.profile.gender,
        };
    },

    methods: {
        onSubmit() {
            this.initialGender = this.gender;
        },
    },

    computed: {
        buttonDisabled() {
            return this.gender === this.initialGender;
        },
    },
};
</script>
