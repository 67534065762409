<template>
    <AlertMessage :type="errorMessage.type" icon="alert-triangle" class="max-w-xl m-auto my-24">
        <template #title>{{ errorMessage.title }}</template>
        <template #body>
            <div class="prose" v-html="errorMessage.description" />
            <router-link
                v-if="errorMessage.link"
                :to="{ path: errorMessage.link }"
                class="mt-2 text-cobalt-500 font-semibold block"
                >Purchase here</router-link
            >
        </template>
    </AlertMessage>
</template>
<script setup>
import AlertMessage from "@/components/AlertMessage.vue";
import { computed } from "vue";
import store from "@/store/store";

const error = {
    type: "error",
    title: "Processing failed",
    description:
        "Unfortunately something went wrong providing your Classical DNA result. Please contact support at <a href='mailto:help@livingdna.com' class='font-semibold text-cobalt-500'>help@livingdna.com</a>",
    link: "",
};

const processing = {
    type: "info",
    title: "Result processing",
    description: "Your Classical DNA result is processing and should be available within 48 hours",
    link: "",
};

const errorMessages = {
    error,
    failed: error,
    noData: error,
    pending: processing,
    processing,
};

const errorMessage = computed(() => {
    const message = errorMessages[store.getters.kitTests.classical.status];
    if (!message) {
        throw new Error("Unhandled classical status");
    }
    return message;
});
</script>
