<template>
    <MigrationMap :result="motherlineStore.result" type="mtdna" />
</template>

<script setup>
import MigrationMap from "@/components/ancestry/MigrationMap.vue";
import { useMotherlineStore } from "@/store/parentalStore";

const motherlineStore = useMotherlineStore();
</script>
