<template>
    <div class="mt-12">
        <div class="lg:flex items-end justify-between pb-1">
            <h2 class="flex items-center text-lg leading-none">
                <feather-wrapper icon="sliders" class="mr-2" />
                Permissions
            </h2>

            <div class="mt-4">
                <div class="text-xs text-gray-400 leading-none">
                    Access and service controls for this profile
                </div>
            </div>
        </div>

        <div class="w-full mt-6 shadow-key rounded-lg flex-1" style="border-spacing: 0">
            <DetailsTr
                :class="{ 'bg-grey-50 text-grey-500': isManagerOfProfile }"
                title="Manager"
                :link="isManagerOfProfile ? false : 'edit/manager'"
                limit-width
                append
            >
                <div class="px-6">{{ manager }}</div>
                <template #tooltip>
                    Only the owner of a profile can add or remove a manager
                </template>
            </DetailsTr>
            <DetailsTr title="Research" :link="`/research/${this.profile.barcode}`" :border="false">
                <div class="px-6">{{ researchConsentText }}</div>
            </DetailsTr>
        </div>
    </div>
</template>

<script>
import DetailsTr from "@/components/Profile/View/DetailsTr.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import { fetchCurrentManager } from "@/services/manager";
import endpoints from "@/endpoints";
import axios from "axios";
import store from "@/store/store";

export default {
    data() {
        return {
            manager: "",
            isConsentedToResearch: null,
        };
    },
    props: {
        profile: {
            type: Object,
            required: true,
        },
    },
    async created() {
        await this.$store.dispatch("getAccountData");
        this.manager = await fetchCurrentManager(this.profile.barcode);
        await store.dispatch("getAccountData");
        console.log(store.getters.account);
        try {
            const { data: researchData } = await axios.get(
                endpoints.research(this.$route.params.barcode)
            );
            this.isConsentedToResearch = researchData.consent;
        } catch (e) {
            console.log("Failed to load research", e.body.message);
        }
    },
    computed: {
        researchConsentText() {
            return this.isConsentedToResearch ? "Participating" : "Not participating";
        },
        isManagerOfProfile() {
            return this.$store.getters.account
                ? this.manager === this.$store.getters.account.email
                : false;
        },
    },
    components: { DetailsTr, FeatherWrapper },
};
</script>
