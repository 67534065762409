<template>
    <div class="text-center">
        <h3 class="text-denim-700 text-2xl font-normal">Reset password</h3>
        <p>
            Enter the email address associated with your account, and we'll email you a link to
            reset your password
        </p>
        <form @submit.prevent="sendResetEmail">
            <TextInput
                v-model="email"
                label="email"
                :display-label="false"
                type="email"
                class="mt-6"
                :error-bag="errors"
                error-key="resettingPassword"
            />
            <button
                :class="{
                    loading: loading,
                    'bg-grey-200 text-grey-500': !readyToSubmit,
                    'bg-cobalt-500 text-white': readyToSubmit,
                }"
                :disabled="!readyToSubmit"
                class="mt-4 bg-cobalt-500 rounded w-full py-2 px-4 text-white font-semibold test-shadow"
            >
                Send reset link
            </button>
        </form>
        <div class="mt-4">
            <router-link to="/signin" class="text-grey-500">Cancel</router-link>
        </div>
    </div>
</template>

<script>
import TextInput from "@/components/inputs/TextInput.vue";

export default {
    components: { TextInput },
    data() {
        return {
            email: "",
            loading: false,
            errors: [],
        };
    },
    methods: {
        sendResetEmail() {
            this.loading = true;
            this.$store
                .dispatch("resetPassword", {
                    email: this.email,
                })
                .then(() => {
                    this.loading = false;
                    this.$router.push({
                        name: "reset-password-email-sent",
                        query: { email: this.email },
                    });
                })
                .catch((err) => {
                    console.log(err.response.data.errors);
                    this.errors = err.response.data.errors;
                    this.loading = false;
                });
        },
    },
    computed: {
        readyToSubmit() {
            console.log(this.email);
            return this.email.length > 0;
        },
    },
};
</script>
