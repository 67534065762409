import { Machine, assign } from "xstate";
import profileService from "@/services/profile";
import store from "../../../store/store";

export default new Machine(
    {
        id: "family-matching-display-name-machine",
        context: {
            name: "",
            terms: false,
            partners: false,
            profile: undefined,
            barcode: undefined,
            http: undefined,
        },
        initial: "idle",
        states: {
            idle: {
                on: {
                    INPUT: "receivedInput",
                },
            },
            receivedInput: {
                entry: ["cacheName", "cacheTerms", "cachePartners"],
                on: {
                    "": [
                        {
                            cond: {
                                type: "isInputValid",
                                minNameLength: 4,
                            },
                            target: "validForm",
                        },
                        { target: "inValidForm" },
                    ],
                },
            },
            validForm: {
                on: {
                    SAVE: "processing",
                    INPUT: "receivedInput",
                },
            },
            inValidForm: {
                on: {
                    INPUT: "receivedInput",
                },
            },
            processing: {
                invoke: {
                    src: "save",
                    onDone: "saved",
                    onError: {
                        target: "error",
                    },
                },
            },
            saved: {},
            error: {},
        },
    },
    {
        actions: {
            cacheName: assign((context, event) => ({
                name: event.name ? event.name : context.name,
            })),
            cacheTerms: assign((context, event) => ({
                terms: event.terms ? event.terms : context.terms,
            })),
            cachePartners: assign((context, event) => ({
                partners: event.partners ? event.partners : context.partners,
            })),
        },
        guards: {
            isInputValid: (context, event, { cond }) =>
                !!(context.name.length > cond.minNameLength && context.terms),
        },
        services: {
            save: context =>
                new Promise(async (resolve, reject) => {
                    try {
                        // save the name
                        await profileService.saveName(
                            context.barcode,
                            context.name,
                            context.profile
                        );
                        // save the main terms accepted
                        await store.dispatch("setFamilyMatchingMainTermsAsAccepted", {
                            barcode: context.barcode,
                            http: context.http,
                        });
                        // if partners terms accepted save that
                        if (context.partners) {
                            await store.dispatch("setFamilyMatchingAdditionalTermsAsAccepted", {
                                barcode: context.barcode,
                                http: context.http,
                            });
                        }
                        resolve();
                    } catch (e) {
                        reject(new Error(e.body.errors[0].detail));
                    }
                }),
        },
    }
);
