<template>
    <RightImageCard :image="{ src: image }" image-class="w-48 ml-auto">
        <template v-slot:body>
            <div class="w-full md:w-3/5 p-6 flex flex-col">
                <h3 class="text-base text-denim-700 mb-2">
                    What do your genetics reveal about your health, lifestyle and you?
                </h3>
                <div class="max-w-sm mb-4">
                    Your health, lifestyle and personal genetic insights are stored in your own
                    health portal. Discover what your genetics say about your fitness, nutrition,
                    allergies, personality, sleep and more.
                </div>
                <a href="/health" class="flex items-center">
                    <feather type="heart" class="h-5 w-5" />
                    <span class="ml-2 font-semibold text-cobalt-500">Visit your Health Portal</span>
                </a>
            </div>
        </template>
    </RightImageCard>
</template>

<script>
import tallImage from "@/assets/images/wellbeing/lemons-tan-tall.png";
import wideImage from "@/assets/images/wellbeing/lemons-tan-wide.png";
import RightImageCard from "@/components/cards/RightImageCard.vue";
import DashboardLinkWithIcon from "@/components/utilities/DashboardLinkWithIcon.vue";
import Feather from "vue-feather";

export default {
    components: { RightImageCard, Feather, DashboardLinkWithIcon },
    props: {
        imageType: String,
    },
    computed: {
        image() {
            if (this.imageType === "wide") {
                return wideImage;
            }
            return tallImage;
        },
    },
};
</script>
