<template>
    <FilterErrorBag :error-bag="errorBag" :error-key="errorKey" v-slot="{ errorMessage }">
        <div>
            <label
                v-if="displayLabel"
                :for="id"
                class="font-semibold block w-full"
                :class="disabled ? 'text-grey-500' : 'text-grey-700'"
                style="font-size: 14px"
            >
                {{ label }}
            </label>
            <input
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :type="type"
                :id="id"
                :placeholder="placeholder"
                class="mt-1 border bg-grey-50 border-grey-500 rounded w-full py-2 px-4 focus:outline-none focus:border-cobalt-500"
                :disabled="disabled"
                required
            />
            <div
                v-if="errorMessage && errorMessage.detail != 'The email is already used.'"
                class="mt-1 text-rose-500 text-sm"
            >
                {{ errorMessage.detail }}
            </div>
            <div
                v-if="errorMessage && errorMessage.detail === 'The email is already used.'"
                class="mt-1 text-rose-500 text-sm"
            >
                The email is already registered to another Living DNA account. Please try another or
                <router-link class="text-rose-500 underline" to="/signin">log in</router-link>
                if the email belongs to you.
            </div>
        </div>
    </FilterErrorBag>
</template>

<script>
import FilterErrorBag from "@/components/inputs/FilterErrorBag.vue";

export default {
    components: { FilterErrorBag },
    props: {
        modelValue: String,
        label: {
            type: String,
            required: true,
        },
        displayLabel: {
            type: Boolean,
            default: true,
        },
        placeholder: String,
        type: {
            type: String,
            default: "text",
        },
        errorBag: {
            type: Array,
            default: () => [],
        },
        errorKey: String,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        id() {
            return this.label.replace(" ", "-").toLowerCase();
        },
    },
};
</script>

<style>
/* a.text-rose-500 {
    @apply text-rose-500 !important;
} */
</style>
