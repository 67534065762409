<template>
    <template v-if="loadingStatus === 'failed'">
        <RecentAncestryKitStatus />
    </template>

    <div v-else class="bg-white rounded shadow p-6">
        <PageLoader v-if="loadingStatus === 'loading'" />

        <div v-else class="flex items-start">
            <div class="flex flex-col gap-4">
                <h3 class="font-semibold text-gray-500">Region breakdown</h3>
                <div v-for="region in regionsToDisplay">
                    <div class="flex items-center gap-4">
                        <div
                            class="rounded-full h-5 w-5"
                            :style="{ backgroundColor: region.colour }"
                        />
                        <div class="text-gray-700">
                            {{ region.title }}
                            <span class="text-gray-500 text-sm">({{ region.percentage }}%)</span>
                        </div>
                    </div>

                    <div class="ml-10 text-gray-500 text-sm">
                        + {{ region.children.length }}
                        {{ pluralize("subregion", region.children.length) }}
                    </div>
                </div>
                <div v-if="excludedRegionsCount > 0">
                    + {{ excludedRegionsCount }} more
                    {{ pluralize("region", excludedRegionsCount) }}
                </div>
                <router-link :to="{ name: 'recent-map' }" class="text-cobalt-500 font-semibold"
                    >View full breakdown</router-link
                >
            </div>

            <AutosomalDoughnut
                class="ml-auto"
                :region-data="regionsToDisplay"
                style="height: 204px; width: 225px"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import pluralize from "pluralize";
import { useAutosomalStore } from "@/store/ancestry/autosomal";
import { useLoading } from "@/composables/useLoading";
import AutosomalDoughnut from "@/components/Dashboard/Ancestry/AutosomalDoughnut.vue";
import Doughnut from "@/components/charts/Doughnut.vue";
import MessageCard from "@/components/Dashboard/Ancestry/MessageCard.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import RecentAncestryKitStatus from "@/components/RecentAncestry/RecentAncestryKitStatus.vue";

// todo autosomal update indicator

const { loadingStatus, finishLoading, failLoading } = useLoading();
const autosomalStore = useAutosomalStore();

autosomalStore.getAutosomalResults().then(finishLoading).catch(failLoading);

const regionsToDisplay = computed(() => {
    if (!autosomalStore.result.regions) {
        return [];
    }
    if (autosomalStore.result.regions.length > 1) {
        return autosomalStore.result.regions;
    }
    return autosomalStore.result.regions[0].children;
});

const excludedRegionsCount = computed(() => {
    return autosomalStore.result.regions.length - regionsToDisplay.value.length;
});
</script>
