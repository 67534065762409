<template>
    <div></div>
</template>

<script>
import Card from "@/components/cards/Card.vue";

export default {
    components: { Card },
};
</script>
