<template>
    <Overview>
        <template #sidebar>
            <div class="fade-bottom fade-bottom-g">
                <img
                    class="w-full h-64 object-cover object-top"
                    :src="introduction.main_image.url"
                    alt=""
                />
            </div>

            <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                <h1 class="text-xl">{{ introduction.heading }}</h1>
                <div v-html="introduction.text" />
            </div>
        </template>
        <template #desktopContent>
            <dl class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 max-w-4xl m-auto">
                <div
                    v-for="card in results"
                    class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                >
                    <dt>
                        <div
                            class="absolute rounded-md p-3 //border-2"
                            :class="[card.titleColor, card.bgColor]"
                        >
                            <FeatherWrapper
                                :icon="card.icon"
                                :classes="['block w-5 h-5 ']"
                                :container-classes="['block w-5 h-5']"
                            />
                        </div>
                        <p class="ml-16 truncate text-sm font-medium text-gray-500">
                            {{ card.title }}
                        </p>
                    </dt>
                    <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                        <p class="text-2xl font-semibold text-gray-900">
                            {{ card.score }}
                        </p>
                        <div class="absolute inset-x-0 bottom-0 px-4 py-4 sm:px-6 bg-gray-50">
                            <div class="text-sm">
                                <router-link
                                    :to="{ name: card.resultRoute }"
                                    class="font-semibold text-cobalt-500 hover:text-cobalt-700"
                                    >View<span class="sr-only"> {{ card.name }}</span></router-link
                                >
                            </div>
                        </div>
                    </dd>
                </div>
            </dl>
            <div class="flex flex-col gap-6 max-w-xl m-auto">
                <div v-for="card in results" class="flex items-center gap-12 justify-center">
                    <div
                        class="bg-white shadow rounded //p-6 flex-grow //border-t-4 border-red-600"
                    >
                        <img
                            :src="card.image"
                            class="h-32 w-full object-cover rounded-t object-center"
                            alt=""
                        />
                        <div class="p-6">
                            <div class="flex justify-between items-stretch">
                                <div class="space-y-4">
                                    <div>
                                        <h3
                                            class="font-semibold //text-sm leading-4 uppercase"
                                            :class="card.titleColor"
                                        >
                                            {{ card.title }}
                                        </h3>
                                        <div class="w-6 h-1 mt-1" :class="card.dividerColor" />
                                    </div>

                                    <div v-if="card.sampleGroup">
                                        <div class="text-sm text-gray-600">
                                            Closest sample group
                                        </div>
                                        <div class="text-2xl font-semibold" :class="card.textColor">
                                            {{ card.sampleGroup }}
                                        </div>
                                    </div>
                                    <div v-if="card.sampleName">
                                        <div class="text-sm text-gray-600">Closest sample</div>
                                        <div class="text-2xl font-semibold" :class="card.textColor">
                                            {{ card.sampleName }}
                                        </div>
                                    </div>
                                    <div
                                        v-if="!card.sampleGroup && !card.sample"
                                        class="h-32 pr-12"
                                    >
                                        We do not currently provide the closest sample or sample
                                        group for Egyptians due to limited availability of ancient
                                        samples, but hope to do so in the future.
                                    </div>
                                    <router-link
                                        :to="{ name: card.overviewRoute }"
                                        class="px-4 py-2 rounded inline-flex items-center gap-3 font-semibold text-sm"
                                        :class="[card.bgColor, card.textColor]"
                                    >
                                        <span>Explore results</span>
                                        <FeatherWrapper icon="arrow-right" />
                                    </router-link>
                                    <!--                                    <TextLinkRight-->
                                    <!--                                        :to="{ name: 'dashboard' }"-->
                                    <!--                                        icon="chevron-right"-->
                                    <!--                                        text="Explore results"-->
                                    <!--                                    />-->
                                </div>
                                <div class="flex-none">
                                    <DoughnutChart class="w-36 w-36" :chart-data="card.chartData" />
                                    <div
                                        class="text-center font-semibold text-4xl mt-4"
                                        :class="card.textColor"
                                    >
                                        {{ card.score }}
                                    </div>
                                    <div class="text-xs text-center text-gray-500">Index score</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Overview>
</template>
<script setup>
import DoughnutChart from "@/components/charts/Doughnut.vue";
import ampitheatre from "@/assets/images/classical/roman/ampitheatre.jpg";
import temple from "@/assets/images/classical/greek/temple.jpg";
import landscape from "@/assets/images/classical/egyptian/landscape.jpg";
import NavBar from "@/components/partials/NavBar.vue";
import Overview from "@/components/Overview.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import { inject } from "vue";

const introduction = inject("classical-introduction");
const result = inject("classical-result");

const exampleRomanChartData = {
    datasets: [
        {
            data: [
                result.value.romans.index.toFixed(2),
                100 - result.value.romans.index.toFixed(2),
            ],
            backgroundColor: ["#b91c1c", "#ddd"],
        },
    ],
    labels: [],
};

const exampleGreekChartData = {
    datasets: [
        {
            data: [
                result.value.greeks.index.toFixed(2),
                100 - result.value.greeks.index.toFixed(2),
            ],
            backgroundColor: ["#0369a1", "#ddd"],
        },
    ],
    labels: [],
};

const exampleEgpytianChartData = {
    datasets: [
        {
            data: [
                result.value.egyptians.index.toFixed(2),
                100 - result.value.egyptians.index.toFixed(2),
            ],
            backgroundColor: ["#a16207", "#ddd"],
        },
    ],
    labels: [],
};

const results = [
    {
        title: "Roman",
        score: result.value.romans.index.toFixed(2),
        icon: "roman",
        image: ampitheatre,
        sampleGroup: result.value.romans.closest_sample.group,
        sampleName: result.value.romans.closest_sample.sample,
        textColor: "text-red-600",
        titleColor: "text-red-700",
        dividerColor: "bg-red-700",
        borderColor: "border-red-700",
        bgColor: "bg-red-50",
        chartData: exampleRomanChartData,
        resultRoute: "roman-result",
        overviewRoute: "roman-overview",
    },
    {
        title: "Greek",
        score: result.value.greeks.index.toFixed(2),
        icon: "column",
        image: temple,
        sampleGroup: result.value.greeks.closest_sample.group,
        sampleName: result.value.greeks.closest_sample.sample,
        textColor: "text-sky-600",
        titleColor: "text-sky-700",
        dividerColor: "bg-sky-700",
        borderColor: "border-sky-700",
        bgColor: "bg-sky-50",
        chartData: exampleGreekChartData,
        resultRoute: "greek-result",
        overviewRoute: "greek-overview",
    },
    {
        title: "Egyptian",
        score: result.value.egyptians.index.toFixed(2),
        icon: "egyptian",
        image: landscape,
        textColor: "text-yellow-600",
        titleColor: "text-yellow-700",
        dividerColor: "bg-yellow-700",
        borderColor: "border-yellow-700",
        bgColor: "bg-yellow-50",
        chartData: exampleEgpytianChartData,
        resultRoute: "egyptian-result",
        overviewRoute: "egyptian-overview",
    },
];
</script>
