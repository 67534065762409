<template>
    <div>
        <form @submit.prevent="createPassword" v-if="pageLoaded">
            <h3 class="text-denim-700 text-2xl font-normal text-center">Complete sign up</h3>
            <p class="text-grey-700 leading-normal text-center">
                Please set a password for your account. Your password should be at least 8
                characters.
            </p>
            <PasswordInput
                class="mt-2"
                v-model="password"
                :new-password="true"
                :error-text="passwordError ? passwordError.detail : ''"
                @blur="validatePassword"
                @input="updatePasswordError"
            />
            <button
                :class="{
                    loading: loading,
                    'bg-grey-200 text-grey-500': !readyToSubmit,
                    'bg-cobalt-500 text-white': readyToSubmit,
                }"
                :disabled="!readyToSubmit"
                class="mt-6 rounded w-full py-2 px-4 font-semibold"
            >
                Create account
            </button>
        </form>
    </div>
</template>

<script>
import PasswordInput from "@/components/inputs/PasswordInput/PasswordInput.vue";

export default {
    components: { PasswordInput },
    data() {
        return {
            partnerCode: this.$route.params.partner,
            token: this.$route.params.token,
            password: "",
            pageLoaded: false,
            loading: false,
            partner: {},
            passwordError: { detail: "" },
        };
    },
    computed: {
        readyToSubmit() {
            return this.password.length;
        },
    },
    mounted() {
        this.getPartnerInformation();
    },
    methods: {
        getPartnerInformation() {
            this.$store.dispatch("getPartnerInformation", this.partnerCode).then(() => {
                this.partner = this.$store.state.userSignupModule.partner;
                this.pageLoaded = true;
            });
        },
        createPassword() {
            this.loading = true;
            this.$store
                .dispatch("createPassword", {
                    password: this.password,
                    token: this.token,
                })
                .then(() => {
                    this.$router.push({ name: "create-password-from-partner-success" });
                })
                .catch((e) => {
                    console.error(e);
                    this.loading = false;
                    this.passwordError.detail = "There was an error setting your password.";
                });
        },
        validatePassword() {
            if (this.passwordError && this.password.length < 8) {
                this.passwordError.detail =
                    "This password is too short. It must contain at least 8 characters.";
            }
        },
        updatePasswordError() {
            if (this.passwordError && this.passwordError.detail && this.password.length >= 8) {
                this.passwordError.detail = "";
            }
        },
    },
};
</script>
