<template>
    <ThePage v-if="currentLoadingState.matches('haveResearchData')" class="mb-16">
        <div v-if="false">
            {{ currentLoadingState.value }} |
            {{ currentLoadingState.context.consent }}
        </div>
        <router-link :to="{ name: 'profile-view' }" class="font-semibold flex items-center">
            <feather type="chevron-left" class="h-5 w-5 mr-2" />

            {{ name }}'s DNA Profile
        </router-link>
        <BasePageTitle> Research </BasePageTitle>
        <Card class="bg-white">
            <p>
                Living DNA are working with leading academic research partners on projects that will
                benefit society. We would love for you to be involved. The first of these projects
                is to map the world's genetic history and we need your help. None of the information
                provided will affect your direct ancestry result.
            </p>
            <ParticipantAgreement v-model="currentLoadingState.context.consent" />
        </Card>
        <form @submit.prevent="submitResearchInformation">
            <div
                v-if="
                    currentLoadingState.context.consent &&
                    currentLoadingState.context.initialParticipantRelations
                "
            >
                <ParticipantRelation
                    title="Your mother's mother"
                    v-model="currentLoadingState.context.initialParticipantRelations.mothers_mother"
                />
                <ParticipantRelation
                    title="Your mother's father"
                    v-model="currentLoadingState.context.initialParticipantRelations.mothers_father"
                />
                <ParticipantRelation
                    title="Your father's mother"
                    v-model="currentLoadingState.context.initialParticipantRelations.fathers_mother"
                />
                <ParticipantRelation
                    title="Your father's father"
                    v-model="currentLoadingState.context.initialParticipantRelations.fathers_father"
                />
            </div>
            <button
                type="submit"
                class="mt-6 bg-cobalt-500 text-white px-6 py-2 rounded"
                :class="{ loading: currentSavingState.matches('savingData') }"
            >
                Save
            </button>
            <div
                class="text-rose-500 text-sm mt-3"
                v-if="currentSavingState.matches('failedToSaveResearchData')"
            >
                There was an issue saving your research data. If the issue persists we recommend
                contacting support
            </div>
        </form>
    </ThePage>
    <ThePage v-else-if="currentLoadingState.matches('fetchingData')">
        <div>Loading...</div>
    </ThePage>
    <ThePage v-else-if="currentLoadingState.matches('failedToLoadResearchData')">
        <div>Research failed to load</div>
    </ThePage>
</template>

<script>
import axios from "axios";
import { clone } from "lodash";
import endpoints from "@/endpoints";
import { mapGetters } from "vuex";
import { Machine, interpret, assign } from "xstate";
import BasePageTitle from "@/components/Base/BasePageTitle.vue";
import ThePage from "@/components/Base/ThePage.vue";
import Card from "@/components/cards/Card.vue";
import ParticipantAgreement from "@/components/research/ParticipantAgreement.vue";
import ParticipantRelation from "@/components/research/ParticipantRelation.vue";

const basePersonalDetails = {
    first_name: "",
    last_name: "",
    date_of_birth: null,
};

const fetchResearchMachine = Machine(
    {
        id: "fetchResearch",
        initial: "idle",
        context: {
            initialParticipantRelations: {},
            consent: undefined,
        },
        states: {
            idle: {
                on: { MAKE_REQUEST: "fetchingData" },
            },
            fetchingData: {
                invoke: {
                    src: "fetchResearchData",
                    onDone: {
                        actions: ["cacheConsent", "cacheInitialParticipantRelations"],
                        target: "haveResearchData",
                    },
                    onError: {
                        target: "failedToLoadResearchData",
                    },
                },
            },
            haveResearchData: {
                // final: true,
            },
            failedToLoadResearchData: {
                // final: true,
            },
        },
    },
    {
        services: {
            fetchResearchData: async (context, event) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const result = await axios.get(
                            endpoints.research(context.vm.$route.params.barcode)
                        );
                        resolve(result.data);
                    } catch (e) {
                        console.log(e);
                        reject();
                    }
                }),
        },
        actions: {
            cacheConsent: assign({
                consent: (context, event) => event.data.consent,
            }),
            cacheInitialParticipantRelations: assign({
                initialParticipantRelations: (context, event) => {
                    if (
                        Object.prototype.toString.call(
                            event.data.ancestryTestParticipantRelations
                        ) === "[object Object]"
                    ) {
                        return event.data.ancestryTestParticipantRelations;
                    }
                    return {
                        mothers_mother: clone(basePersonalDetails),
                        mothers_father: clone(basePersonalDetails),
                        fathers_mother: clone(basePersonalDetails),
                        fathers_father: clone(basePersonalDetails),
                    };
                },
            }),
        },
    }
);

const saveResearchMachine = Machine({
    id: "saveResearch",
    initial: "idle",
    context: {},
    states: {
        idle: {
            on: { MAKE_REQUEST: "savingData" },
        },
        savingData: {
            on: {
                SUCCESS: "idle",
                FAILURE: "failedToSaveResearchData",
            },
        },
        failedToSaveResearchData: {
            final: true,
        },
    },
});

export default {
    components: {
        BasePageTitle,
        ThePage,
        Card,
        ParticipantAgreement,
        ParticipantRelation,
    },
    data() {
        return {
            participantRelations: {
                mothers_mother: clone(basePersonalDetails),
                mothers_father: clone(basePersonalDetails),
                fathers_mother: clone(basePersonalDetails),
                fathers_father: clone(basePersonalDetails),
            },
            loadingService: interpret(fetchResearchMachine),
            currentLoadingState: fetchResearchMachine.initialState,
            savingService: interpret(saveResearchMachine),
            currentSavingState: saveResearchMachine.initialState,
        };
    },
    async created() {
        this.initStateMachines();
        this.loadingService.send("MAKE_REQUEST");
    },
    methods: {
        async submitResearchInformation() {
            // console.log({
            //     mm: this.participantRelations.mothers_mother,
            //     mf: this.participantRelations.mothers_father,
            //     fm: this.participantRelations.fathers_mother,
            //     ff: this.participantRelations.fathers_father,
            // });
            this.savingService.send("MAKE_REQUEST");
            try {
                console.log("Saving participant relations", {
                    consent: this.currentLoadingState.context.consent,
                    participantRelations:
                        this.currentLoadingState.context.initialParticipantRelations,
                });
                await axios.post(endpoints.research(this.$route.params.barcode), {
                    consent: this.currentLoadingState.context.consent,
                    participantRelations:
                        this.currentLoadingState.context.initialParticipantRelations,
                });
                this.savingService.send("SUCCESS");
                this.$router.push({
                    name: "profile-view",
                    params: { barcode: this.$route.params.barcode },
                });
            } catch (e) {
                console.error("Error saving participant relations", e);
                this.savingService.send("FAILURE");
            }
        },
        initStateMachines() {
            saveResearchMachine.context.vm = this;
            fetchResearchMachine.context.vm = this;
            this.loadingService
                .onTransition((state) => {
                    this.currentLoadingState = state;
                })
                .start();
            this.savingService
                .onTransition((state) => {
                    this.currentSavingState = state;
                })
                .start();
        },
    },
    computed: {
        ...mapGetters(["profiles"]),
        selectedProfile() {
            return this.profiles.find(
                (profile) => profile.profile.barcode === this.$route.params.barcode
            ).profile;
        },
        name() {
            return this.selectedProfile.name;
        },
    },
};
</script>
