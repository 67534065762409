<template>
    <div class="text-center">
        <h3 class="text-denim-700 text-2xl font-normal">Check your inbox</h3>
        <p class="mt-4 leading-normal">
            We've just sent an email to <span class="underline">{{ userEmail }}</span
            >. <br />
            Follow the link in the email to verify <br />
            your account registration.
        </p>
        <div v-if="showSuccess" class="bg-emerald-50 py-2 px-4 text-emerald-500">
            Verification email resent
        </div>
        <div v-if="responseErrors" class="bg-rose-50 py-2 px-4 text-rose-500">
            {{ responseErrors.message }}
        </div>
        <div class="mt-24">
            <p class="mb-2">
                Problems receiving email?
                <button
                    @click="resendVerificationEmail"
                    class="text-cobalt-500 font-semibold"
                    :class="{ 'text-grey-500': loading }"
                    :disabled="loading"
                >
                    Resend
                </button>
            </p>
            <!--            Removed until there is an API endpoint to support this -->
            <!--            <p class="text-grey-500 text-sm mt-0 mb-2">OR</p>-->
            <!--            <router-link class="font-semibold" to="/signup/change-verification-email">Use another email address</router-link>-->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            responseErrors: false,
            showSuccess: false,
            loading: false,
        };
    },
    computed: {
        userEmail() {
            return this.$store.state.userSignupModule.userEmail;
        },
    },
    methods: {
        resendVerificationEmail() {
            console.log(this.userEmail);
            this.loading = true;
            this.$store
                .dispatch("resendVerificationEmail", {
                    email: this.userEmail,
                })
                .then(() => {
                    this.showSuccess = true;
                    this.loading = false;
                })
                .catch(err => {
                    this.responseErrors = { message: err.response.data.errors[0].detail };
                    this.loading = false;
                });
        },
    },
};
</script>
