<template>
    <AlertMessage
        v-if="familyMatchingStore.optInStatus === 'notRunYet'"
        class="mt-12 m-auto max-w-md"
        icon="users"
        type="info"
    >
        <template #title> You haven't been run yet </template>
        <template #body>
            You haven't been run through our family networks pipeline yet. We run our pipeline
            weekly and we'll email you if you get new matches in a run.
        </template>
    </AlertMessage>
    <AlertMessage v-else class="mt-12 m-auto max-w-md" icon="users" type="warning">
        <template #title> You don't have any matches yet </template>
        <template #body>
            You've been run through our family networks pipeline but we haven't found you any
            matches. We run our pipeline weekly and have new people joining all the time. We'll send
            you an email as soon as you have matches available.
        </template>
    </AlertMessage>
</template>

<script setup>
import headerImage from "@/assets/images/family-network/no-matches-header.jpg";
import AlertMessage from "@/components/AlertMessage.vue";
import threeCircles from "@/assets/images/family-network/three-circles.png";
import kitExample from "@/assets/images/family-network/kit-example.png";
import DashboardLinkWithIcon from "@/components/utilities/DashboardLinkWithIcon.vue";
import { useFamilyMatchingStore } from "@/store/familyMatching";

const familyMatchingStore = useFamilyMatchingStore();
</script>
