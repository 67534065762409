import axios from "axios";
import { range } from "lodash/util";
import { padStart } from "lodash";
import endpoints from "@/endpoints";

export const years = [
    {
        name: "Unknown",
        value: "",
    },
    ...range(new Date().getFullYear(), 1799).map((v) => {
        const value = v.toString();
        return {
            name: value,
            value,
        };
    }),
];

export const months = [
    {
        name: "Unknown",
        value: "",
    },
    {
        name: "January",
        value: "01",
    },
    {
        name: "February",
        value: "02",
    },
    {
        name: "March",
        value: "03",
    },
    {
        name: "April",
        value: "04",
    },
    {
        name: "May",
        value: "05",
    },
    {
        name: "June",
        value: "06",
    },
    {
        name: "July",
        number: "07",
    },
    {
        name: "August",
        value: "08",
    },
    {
        name: "September",
        value: "09",
    },
    {
        name: "October",
        value: "10",
    },
    {
        name: "November",
        value: "11",
    },
    {
        name: "December",
        value: "12",
    },
];

export const days = [
    {
        name: "Unknown",
        value: "",
    },
    ...range(1, 32).map((v) => ({
        name: v,
        value: padStart(v, 2, "0"),
    })),
];

export default class ResearchService {
    static endpoint = "https://api.mapbox.com/geocoding/v5/mapbox.places/";

    static accessToken =
        process.env.MAPBOX_TOKEN ||
        "pk.eyJ1IjoibGl2aW5nZG5hIiwiYSI6ImNqd3JrY3U0azA2Nzk0MHF2YWY1a3BxMjAifQ.1pDIxf43SxLeQ1zaOKdLLA";

    static search(location) {
        return axios.get(
            `${
                this.endpoint + location
            }.json?types=country,region,postcode,district,place,neighborhood,address&access_token=${
                this.accessToken
            }`
        );
    }

    static async getResearch({ barcode }) {
        const { data } = await axios.get(`${endpoints.tests}/${barcode}/research`);

        return data;
    }

    static async postResearch({ barcode, body }) {
        const response = await axios.post(`${endpoints.tests}/${barcode}/research`, body);

        return response;
    }
}
