<template>
    <div>
        <div>
            <h3 class="text-denim-700 text-2xl font-normal text-center mb-6">
                Select your health goals
            </h3>
            <p class="mb-4">
                Please select up to 3 health goals to focus your advice and recommendations.
            </p>
        </div>
        <form @submit.prevent="saveAnswers" class="mt-4">
            <BaseCheckbox
                v-model="patientAnswers.wellbeing_goals"
                label="Wellbeing goals"
                :options="wellbeingGoals"
                hide-label
            />
            <div v-if="errorMessage" class="bg-rose-50 text-rose-500 text-sm rounded p-4 mt-4">
                {{ errorMessage }}
            </div>
            <SubmitButton class="mt-4" :loading="saveStatus === 'saving'" hide-icon>
                Save
            </SubmitButton>
        </form>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import SubmitButton from "@/components/button/SubmitButton.vue";
import { useSaving, wellbeingGoals, saveQuestionnaireAnswers } from "@/services/health";
import store from "@/store/store";
import BaseCheckbox from "@/components/BaseCheckbox.vue";

const router = useRouter();
const { saveStatus, startSaving, finishSaving, errorMessage } = useSaving();

const initialData = {
    exercise_days: null,
    exercise_hours: null,
    alcohol_units: null,
    cigarettes: {},
    pregnant: null,
    pregnancy_trimester: null,
    menstrual_cycle: null,
    wellbeing_goals: [],
    purpose: null,
    height: {
        value: null,
        unit: "cm",
    },
    weight: {
        value: null,
        unit: "kg",
    },
    blood_pressure: {
        systolic: null,
        diastolic: null,
    },
    past_conditions: [],
    allergies: [],
    past_supplements: [],
    past_surgeries: [],
    family_medical_conditions: [],
    symptoms_past_month: [],
    prescription_drugs: [],
    recreational_drugs: [],
    diet_style: [],
    personal_health: "",
};

const patientAnswers = ref(initialData);

async function saveAnswers() {
    if (patientAnswers.value.wellbeing_goals.length === 0) {
        errorMessage.value = "Please choose at least one health goal";
        return;
    }
    if (patientAnswers.value.wellbeing_goals.length > 3) {
        errorMessage.value = "Please select 3 or fewer health goals";
        return;
    }
    startSaving();
    saveQuestionnaireAnswers({
        answers: patientAnswers.value,
        userUuid: store.getters.account.uuid,
    })
        .then(() => {
            finishSaving();
            router.push({ name: "activation-family-networks-opt-in" });
        })
        .catch((error) => {
            console.error(error);
            finishSaving();
        });
}
</script>
