<template>
    <div>
        <h3 class="text-denim-700 text-2xl font-normal text-center">
            Would you like to opt in to Family Networks?
        </h3>
        <p class="text-700 leading-normal text-center">
            Family networks is our free family matching service, where you can find relatives all
            across the world.
            <br /><br />
            We run the matching service every 1-2 weeks and by opting in now you can make sure that
            you will be included in the next run after your results are ready. <br /><br />You can
            opt out at any time, or if you choose not to opt in right now you do so in the future by
            clicking Relatives in the navigation.
        </p>
        <button
            v-if="optInService.state.matches('idle')"
            @click="optInService.send('OPT_IN', { barcode: $store.getters.profile.barcode })"
            class="mt-10 bg-cobalt-500 rounded w-full py-2 px-4 text-white font-semibold block w-full text-center hover:bg-cobalt-700"
        >
            Opt in
        </button>
        <button
            v-else-if="optInService.state.matches('optingIn')"
            class="loading mt-10 bg-cobalt-500 rounded w-full py-2 px-4 text-white font-semibold block w-full text-center hover:bg-cobalt-700"
        >
            Opt in
        </button>
        <div class="text-center mt-4">
            <router-link :to="{ name: 'upload-completed' }" class="text-grey-600 font-semibold">
                No thank you
            </router-link>
        </div>
    </div>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import { optIn } from "@/services/family-matching";

const optInMachine = Machine(
    {
        initial: "idle",
        context: {
            barcode: "",
        },
        states: {
            idle: {
                on: {
                    OPT_IN: {
                        actions: "cacheBarcode",
                        target: "optingIn",
                    },
                },
            },
            optingIn: {
                invoke: {
                    src: "optIn",
                    onDone: "completed",
                    onError: "error",
                },
            },
            completed: {
                type: "final",
            },
            error: {},
        },
    },
    {
        actions: {
            cacheBarcode: assign({
                barcode: (_, event) => event.barcode,
            }),
        },
        services: {
            optIn: (ctx) => optIn(ctx.barcode),
        },
    }
);

export default {
    data() {
        return {
            optInService: interpret(optInMachine).start(),
        };
    },
    created() {
        this.optInService.onTransition((state) => {
            if (state.done) {
                this.$router.push({ name: "upload-family-networks-username" });
            }
        });
    },
};
</script>
