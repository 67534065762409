<template>
    <router-link :to="to" class="bg-white font-semibold flex items-center text-cobalt-500">
        <feather-wrapper icon="chevron-left" class="mr-2" />

        <slot />
    </router-link>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

export default {
    props: {
        to: {},
    },
    components: { FeatherWrapper },
};
</script>
