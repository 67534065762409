<template>
    <div class="my-12">
        <h2 class="flex items-center text-lg">
            <feather-wrapper icon="cpu" class="mr-2" />
            Actions
        </h2>
        <div class="w-full mt-6 bg-white shadow-key rounded-lg flex-1 overflow-hidden">
            <router-link
                v-if="selectedTest.raw_status === 'transfer_qc_failed'"
                class="tr--hover-animate rounded-t-lg hover:shadow-key-hover cursor-pointer border-b border-grey-200 flex items-center"
                tag="tr"
                :to="{ name: 'reupload-dna' }"
            >
                <div class="pl-6 py-5 text-grey-500 w-3/5">Reupload your DNA</div>

                <div class="text-right pr-6 py-5 w-2/5">
                    <router-link
                        class="inline-flex items-center text-cobalt-500"
                        :to="{ name: 'reupload-dna' }"
                    >
                        <div class="mr-2 font-semibold">Reupload</div>

                        <feather-wrapper icon="chevron-right" />
                    </router-link>
                </div>
            </router-link>
            <router-link
                v-else
                class="tr--hover-animate rounded-t-lg hover:shadow-key-hover cursor-pointer border-b border-grey-200 flex items-center"
                tag="tr"
                :to="{ name: 'download', params: { barcode: profile.barcode } }"
            >
                <div class="pl-6 py-5 text-grey-500 w-3/5">Retrieve raw DNA data file</div>

                <div class="text-right pr-6 py-5 w-2/5">
                    <router-link
                        class="inline-flex items-center text-cobalt-500"
                        :to="{ name: 'download', params: { barcode: profile.barcode } }"
                    >
                        <div class="mr-2 font-semibold">Download</div>

                        <feather-wrapper icon="chevron-right" />
                    </router-link>
                </div>
            </router-link>
            <router-link
                class="tr--hover-animate rounded-lg hover:shadow-key-hover cursor-pointer flex items-center"
                tag="tr"
                :to="{ name: 'start-deletion', params: { barcode: profile.barcode } }"
            >
                <div class="pl-6 py-5 text-grey-500 w-3/5">Delete this DNA profile</div>

                <div class="text-right pr-6 py-5 w-2/5">
                    <router-link
                        class="inline-flex items-center text-rose-500"
                        :to="{ name: 'start-deletion', params: { barcode: profile.barcode } }"
                    >
                        <div class="mr-2 font-semibold">Delete</div>

                        <feather-wrapper icon="chevron-right" />
                    </router-link>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

export default {
    props: {
        profile: {
            type: Object,
            required: true,
        },
        selectedTest: {
            type: Object,
            required: true,
        },
    },
    components: { FeatherWrapper },
};
</script>
