import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/endpoints";
import store from "@/store/store";

function getShortValueFromType(type) {
    const shortValue = {
        motherline: "mtdna",
        fatherline: "ydna",
    }[type];
    if (!shortValue) {
        throw Error(`Could not determine short value for ${type}`);
    }
    return shortValue;
}

const parentalStoreOptions = (type) => ({
    state() {
        return {
            result: {},
            activeRegion: "",
        };
    },
    actions: {
        async getResult() {
            const { data } = await axios.get(
                `${endpoints.tests}/${store.getters.profile.barcode}/${type}`
            );
            this.result = data.testData[getShortValueFromType(type)].results;
        },
    },
});

export const useMotherlineStore = defineStore(
    "motherlineStore",
    parentalStoreOptions("motherline")
);

export const useFatherlineStore = defineStore(
    "fatherlineStore",
    parentalStoreOptions("fatherline")
);
