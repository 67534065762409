<template>
    <router-link
        :to="{ name: 'product', params: { slug: 'Wellbeing-Upgrade' } }"
        class="bg-copper-500 p-6 rounded-lg mt-6 flex justify-between"
    >
        <span class="text-white">You're missing out on {{ availableReportsCount }} reports.</span>
        <div class="flex items-center font-semibold">
            <feather type="shopping-cart" class="h-5 w-5 mr-2 text-white" />
            <span class="text-white">Unlock now</span>
        </div>
    </router-link>
</template>

<script>
import Feather from "vue-feather";

export default {
    components: { Feather },
    computed: {
        availableReportsCount() {
            return this.$store.getters.wellbeing.length - 2;
        },
    },
};
</script>
