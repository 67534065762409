<template>
    <div>
        <PageAlert v-if="!isFullWellbeingPurchased" />
        <div
            class="mt-12 mx-auto md:flex flex-wrap justify-between container px-4 md:px-12 items-center"
        >
            <router-link
                :to="{ name: 'wellbeing' }"
                class="flex flex-1 items-center text-cobalt-500"
            >
                <feather type="chevron-left" class="h-5 w-5 fill-current mr-1" />
                <div class="font-semibold">All reports</div>
            </router-link>
            <h1 class="text-lg">{{ traitData.title }}</h1>
            <div class="flex-1"></div>
        </div>
        <div class="max-w-3xl px-4 mx-auto mt-20">
            <div class="flex flex-start flex-wrap md:flex-no-wrap">
                <div class="w-full md:w-3/5 md:pr-8">
                    <ResultDescription
                        v-if="hasSnps"
                        :result-value="Number(phenotypeData.scalePosition.value)"
                        :trait="traitData.slug"
                        :phenotype="phenotypeData.phenotype"
                    />
                    <h2 class="font-normal text-2xl">
                        {{ $store.getters.firstName }},
                        {{ phenotypeData.adviceOneliner || noSnpsData.adviceOneLiner }}
                    </h2>
                    <div
                        v-html="
                            phenotypeData.adviceDefinition ||
                            (isUploader
                                ? noSnpsData.resultDetailUploader
                                : noSnpsData.resultDetailKit)
                        "
                    ></div>
                    <DefinitionInformation
                        :link-text="traitData.definitionLinkName"
                        :definition="traitData.definition"
                    />
                </div>
                <KeyTakeaway
                    :image-object="phenotypeData.keyTakeawayImage || null"
                    :key-takeaway-text="
                        phenotypeData.keyTakeaway ||
                        (isUploader
                            ? noSnpsData.keyRecommendationUploader
                            : noSnpsData.keyRecommendationKit)
                    "
                />
            </div>
            <PointsSection
                v-if="hasSnps"
                :positive-points="phenotypeData.positivePoints"
                :warning-points="phenotypeData.warningPoints"
            />
            <div
                v-if="hasSnps"
                class="mt-12 text-grey-500 text-center text-xs"
                v-html="phenotypeData.considerations"
            ></div>
        </div>
        <TraitEffectsList
            :title="traitData.traitEffectsHeading"
            :trait-effects="traitData.traitEffects"
        />
        <ScienceOf
            :trait-name="titleLowerCaseFirst"
            :heading="traitData.scienceOfHeading"
            :slug="traitData.slug"
        />
        <div class="mx-auto max-w-3xl px-4 mt-16 pb-12">
            <h2 class="text-lg">Limitations</h2>
            <div v-html="traitData.limitations"></div>
        </div>
    </div>
</template>

<script>
import Feather from "vue-feather";
import { shuffle } from "lodash";
import PageAlert from "@/components/Wellbeing/PageAlert.vue";
import ScaleMapper from "@/components/Wellbeing/ScaleMapper.vue";
import RelatedTrait from "@/components/Wellbeing/RelatedTrait.vue";
import TraitEffectsList from "@/components/Wellbeing/AllReports/TraitEffectsList.vue";
import KeyTakeaway from "@/components/Wellbeing/AllReports/KeyTakeaway.vue";
import DefinitionInformation from "@/components/Wellbeing/AllReports/DefinitionInformation.vue";
import PointsSection from "@/components/Wellbeing/AllReports/PointsSection.vue";
import ScienceOf from "@/components/Wellbeing/AllReports/ScienceOf.vue";
import ResultDescription from "@/components/Wellbeing/WellbeingReport/ResultDescription";

export default {
    components: {
        ResultDescription,
        ScienceOf,
        PointsSection,
        DefinitionInformation,
        KeyTakeaway,
        PageAlert,
        ScaleMapper,
        Feather,
        RelatedTrait,
        TraitEffectsList,
    },
    props: {
        phenotypeData: Object,
        traitData: Object,
        hasSnps: Boolean,
    },
    computed: {
        traitsInSameCategory() {
            if (this.traitData.category === "Fitness") {
                return this.$store.getters.fitnessReports;
            }
            if (this.traitData.category === "Nutrition") {
                return this.$store.getters.nutritionReports;
            }
            return [];
        },
        relatedTraits() {
            return this.traitsInSameCategory.filter((t) => t.traitCmsData).slice(0, 3);
        },
        titleLowerCaseFirst() {
            return this.lowerCaseFirst(this.traitData.title);
        },
        noSnpsData() {
            return {
                adviceOneLiner: `your ${this.titleLowerCaseFirst} results are inconclusive`,
                resultDetailUploader: `
                    <p>Your genetic data did not provide enough information to produce a scientifically reliable result. This can happen for various reasons:</p>
                    <div class="mb-2">1) The DNA company you tested with did not look at the part of your DNA that is relevant to this trait.</div>
                    2) The DNA company you tested with found inconclusive results on the mutation points relative to this trait.
                    <p>Because you uploaded your data, Living DNA can only process what your origin testing company has provided and cannot make improvements upon their data. We can, however, offer you an upgrade at a reduced price.</p>
                `,
                resultDetailKit: `
                    <p>Your genetic data did not provide enough information to produce a scientifically reliable result. It's rare, but we hate it when this happens!</p>
                `,
                keyRecommendationUploader: "Test with us and receive full wellbeing coverage.",
                keyRecommendationKit:
                    "As technology improves we will aim to deliver a result to you for this report.",
            };
        },
        isFullWellbeingPurchased() {
            return this.$store.getters.isFullWellbeingPurchased;
        },
        isUploader() {
            return this.$store.getters.isUploader;
        },
    },
    methods: {
        lowerCaseFirst(string) {
            return string.charAt(0).toLowerCase() + string.substring(1);
        },
    },
};
</script>

<style>
.v--modal-box {
    @apply rounded-lg  !important;
}
.text-fade-top {
    content: "";
    @apply fixed h-8;
    width: 576px;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.text-fade-bottom {
    content: "";
    @apply fixed w-full h-8;
    width: 576px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
</style>
