<template>
    <div class="shadow rounded-lg bg-white //flex-1 flex flex-col mb-6 lg:mb-0">
        <div class="flex items-stretch">
            <div class="w-1/2 p-6 flex flex-col">
                <h4 class="text-gray-500 font-semibold text-sm mb-2">
                    Preparing results (6-8 weeks)
                </h4>
                <p class="my-0">Did you know? Ötzi, the Iceman, belongs to mtDNA haplogroup K1.</p>
                <span class="flex items-center mt-auto block text-grey-500">
                    <feather-wrapper icon="map-pin" />
                    <span class="ml-2 font-semibold">Extended ancestry</span>
                </span>
            </div>
            <div class="text-right w-1/2">
                <img class="" :src="image" alt="Parental migration map" />
            </div>
        </div>
    </div>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import image from "@/assets/images/dashboard/Parental-Map.png";

export default {
    components: { FeatherWrapper },
    data() {
        return {
            image,
        };
    },
};
</script>
