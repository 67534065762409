/* eslint-disable no-use-before-define */
import axios from "axios";
import endpoints from "@/endpoints";

export function setPartnerReferral(
    partnerReferral,
    referralType = "email-link",
    date = new Date()
) {
    localStorage.setItem(
        "referrer",
        JSON.stringify({
            partnerReferral,
            referralType,
            date,
        })
    );
}

export function getPartnerReferral() {
    return JSON.parse(localStorage.getItem("referrer"));
}

export function hasPartnerReferral() {
    return IsJsonString(localStorage.getItem("referrer"));
}

export async function sendUploadReferralToServer(barcode, uploadReferral) {
    const res = await axios.post(
        endpoints.uploadReferral(barcode),
        storableReferralFromLocalStorageItem(uploadReferral)
    );
    console.log(res.data);
}

function storableReferralFromLocalStorageItem(referral) {
    return {
        referrer: referral.partnerReferral,
        referralType: referral.referralType,
    };
}

/**
 * @return {boolean}
 */
function IsJsonString(str) {
    if (str === null || str === "null") {
        return false;
    }
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
