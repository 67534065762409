<template>
    <profile-form
        headerText="Display name"
        :buttonDisabled="buttonDisabled"
        :profile="profile"
        :partners="partners"
        :fieldData="{ username }"
        @submit="onSubmit"
    >
        <slot name="helperText">
            <p class="my-4">This will be shown to your DNA matches.</p>
        </slot>

        <text-input
            v-model="username"
            label="display-name"
            :displayLabel="false"
            :placeholder="initialUsername"
        />
    </profile-form>
</template>

<script>
import ProfileForm from "@/components/Profile/Edit/ProfileForm.vue";
import TextInput from "@/components/inputs/TextInput.vue";

export default {
    name: "EditDisplayName",

    props: {
        profile: {
            type: Object,
            required: true,
        },
        partners: {
            type: Array,
            required: true,
        },
    },

    components: {
        ProfileForm,
        TextInput,
    },

    data() {
        return {
            username: this.profile.username,
            initialUsername: this.profile.username,
        };
    },

    methods: {
        onSubmit() {
            this.initialUsername = this.username;
        },
    },

    computed: {
        buttonDisabled() {
            return this.username.length === 0 || this.username === this.initialUsername;
        },
    },
};
</script>
