<template>
    <div class="prose m-auto">
        <h2>Recent Ancestry</h2>
        <p>
            Your autosomal results show you a view of your ancestry as a puzzle of populations which
            contribute to your ancestral profile. This accounts for the general location of your
            ancestors possibly as far back as 1500 years ago?
        </p>

        <p>
            As the number of ancestors doubles each generation the further back we go (2 parents, 4
            grandparents, 8 great-grandparents and so on) the further in time you go, the lower the
            autosomal DNA contribution from each ancestor.
        </p>
    </div>
    <div class="flex items-stretch h-[600px] max-w-5xl m-auto rounded lg:bg-gray-50 mt-12">
        <div class="overflow-y-scroll lg:min-w-[400px]">
            <SharingRecentAncestryRegionList
                :regions="sharingStore.autosomalResult"
                class="py-6 mx-6 h-full"
            />
        </div>
        <SharingRecentAncestryMap
            :autosomal-result="sharingStore.autosomalResult"
            class="hidden lg:block"
        />
    </div>
</template>

<script setup>
import { useSharingStore } from "@/store/sharing";
import SharingRecentAncestryMap from "@/views/sharing/SharingRecentAncestryMap.vue";
import SharingRecentAncestryRegionList from "@/views/sharing/SharingRecentAncestryRegionList.vue";

const sharingStore = useSharingStore();
</script>
