<template>
    <portal to="sidebar">
        <Sidebar>
            <div v-if="loadingStatus === 'loading'">
                <PageLoader class="mt-12">Loading</PageLoader>
            </div>
            <div v-else>
                <div class="fade-bottom">
                    <img
                        :src="neanderthal.mainImage.urlRaw"
                        :alt="neanderthal.mainImage.urlRaw.title"
                        class="fade-bottom"
                    />
                </div>
                <div class="pt-6 px-4 sm:px-6 lg:px-8 prose">
                    <h1 class="text-xl">{{ neanderthal.heading }}</h1>
                    <div v-html="neanderthal.text" />
                </div>
                <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                    <h1 class="text-xl">{{ denisovan.heading }}</h1>
                    <img :src="denisovan.mainImage.urlRaw" :alt="denisovan.mainImage.title" />
                    <div v-html="denisovan.text" />
                </div>
            </div>
        </Sidebar>
    </portal>

    <Map
        v-if="hasMounted && mode === 'map'"
        :map-data="mapData"
        :map-data-two="mapDataTwo"
        :map-points="sitesGeoJson"
        :closest-sample="archaicResult.neanderthal.closest_sample"
    />
    <MobileContent v-if="loadingStatus === 'loaded'" :title="neanderthal.heading" remove-padding>
        <div class="fade-bottom">
            <img :src="neanderthal.mainImage.urlRaw" :alt="neanderthal.mainImage.urlRaw.title" />
        </div>

        <div class="py-6 px-4 sm:px-6 lg:px-8 prose mx-auto">
            <div v-html="neanderthal.text" />

            <h1 class="text-xl">{{ denisovan.heading }}</h1>
            <img :src="denisovan.mainImage.urlRaw" :alt="denisovan.mainImage.title" />
            <div v-html="denisovan.text" />
        </div>
    </MobileContent>
</template>

<script>
import { ref, onMounted } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import VikingOverviewCard from "../vikings/VikingsOverviewCard.vue";
import { getArchaicNeanderthal, getArchaicDenisovan } from "@/services/archaic";
import Map from "./ArchaicGeoJsonMap.vue";
import PageLoader from "@/components/PageLoader.vue";
import Tabs from "../vikings/VikingMapTabs.vue";
import neanderthalGeoJson from "./neanderthalGeoJson.json";
import denisovanGeoJson from "./denisovanGeoJson.json";
import sitesGeoJson from "./sitesGeoJson.json";
import MobileContent from "@/components/ancestry/MobileContent.vue";

export default {
    props: { archaicResult: Object },
    components: { MobileContent, Sidebar, VikingOverviewCard, Map, PageLoader, Tabs },
    setup() {
        const hasMounted = ref(false);
        const loadingStatus = ref("loading");
        const neanderthal = ref({});
        const denisovan = ref({});
        const mode = ref("map");
        onMounted(async () => {
            neanderthal.value = await getArchaicNeanderthal();
            denisovan.value = await getArchaicDenisovan();
            loadingStatus.value = "loaded";
            hasMounted.value = true; // if we don't do this then map centering is thrown off by the sidebar
        });
        return {
            loadingStatus,
            neanderthal,
            denisovan,
            hasMounted,
            mode,
            mapData: JSON.stringify(neanderthalGeoJson),
            mapDataTwo: JSON.stringify(denisovanGeoJson),
            sitesGeoJson: JSON.stringify(sitesGeoJson),
        };
    },
};
</script>
