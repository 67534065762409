<template>
    <div class="bg-cobalt-50 rounded p-6 flex flex-col gap-4">
        <p class="text-cobalt-500 text-sm">
            <template v-if="type === 'details'">
                Unlock this level of detailed insights across all regions and subregions when you
                upgrade. Don't miss out on comprehensive information for every aspect of your
                ancestry journey. Enhance your experience and dive deeper into your heritage.
            </template>
            <template v-else>
                Discover more about your ancestry by diving deeper into all your regions and
                subregions. With our full autosomal upgrade you won't miss out on exploring
                {{ regionCount }} additional {{ pluralize("region", regionCount) }} and uncovering
                insights from {{ subregionCount }} {{ pluralize("subregion", subregionCount) }}.
                Even your smallest DNA contribution from a single region, at
                {{ autosomalStore.result.lowestPercentage }}%, holds a fascinating story waiting to
                be revealed.
            </template>
        </p>
        <router-link
            to="/store/autosomal-upgrade"
            class="text-cobalt-500 font-semibold flex items-center text-sm"
        >
            <feather-wrapper icon="zap" class="mr-2" />
            Upgrade now
        </router-link>
    </div>
</template>

<script setup>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import pluralize from "pluralize";
import { useAutosomalStore } from "@/store/ancestry/autosomal";
import { computed } from "vue";

const autosomalStore = useAutosomalStore();

const regionCount = computed(() => {
    return autosomalStore.regions.length;
});

const subregionCount = computed(() => {
    return autosomalStore.subregions.length;
});

defineProps({
    type: {
        type: String,
    },
});
</script>
