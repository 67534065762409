<!--
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
    <!--
      This example requires updating your template:

      ```
      <html class="h-full bg-white">
      <body class="h-full">
      ```
    -->
    <div class="h-full">
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-gray-900/80" />
                </TransitionChild>

                <div class="fixed inset-0 flex">
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full"
                    >
                        <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                            <TransitionChild
                                as="template"
                                enter="ease-in-out duration-300"
                                enter-from="opacity-0"
                                enter-to="opacity-100"
                                leave="ease-in-out duration-300"
                                leave-from="opacity-100"
                                leave-to="opacity-0"
                            >
                                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                                    <button
                                        type="button"
                                        class="-m-2.5 p-2.5"
                                        @click="sidebarOpen = false"
                                    >
                                        <span class="sr-only">Close sidebar</span>
                                        <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </TransitionChild>
                            <!-- Sidebar component, swap this element with another sidebar if you like -->
                            <div
                                class="flex grow flex-col gap-y-5 overflow-y-auto bg-denim-700 px-6 pb-4"
                            >
                                <div class="flex h-16 shrink-0 items-center -mx-2">
                                    <router-link
                                        :to="{ name: 'dashboard' }"
                                        class="flex items-center space-x-2 p-4"
                                    >
                                        <img
                                            class="h-8 w-auto"
                                            src="@/../public/vue-public/Logo.png"
                                            alt="Living DNA Logo"
                                        />
                                        <span
                                            class="bg-pacific-500 text-white px-2 rounded py-px text-sm"
                                        >
                                            Ancestry
                                        </span>
                                    </router-link>
                                </div>
                                <nav class="flex flex-1 flex-col">
                                    <ul role="list" class="flex flex-1 flex-col gap-y-7">
                                        <li>
                                            <ul role="list" class="-mx-2 space-y-1">
                                                <li v-for="item in navigation" :key="item.name">
                                                    <a
                                                        :href="item.href"
                                                        class="px-4 text-white py-3 rounded flex items-center w-full text-sm space-x-4 hover:bg-denim-800"
                                                    >
                                                        <FeatherWrapper :icon="item.icon" />
                                                        <span>{{ item.name }}</span>
                                                        <span
                                                            v-if="item.new"
                                                            class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pacific-500 text-white"
                                                        >
                                                            New feature
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="-mx-2">
                                            <button
                                                v-if="!hasPatient"
                                                @click="
                                                    (healthModalOpen = true), (sidebarOpen = false)
                                                "
                                                class="px-4 text-white py-3 rounded flex items-center w-full text-sm space-x-4 hover:bg-denim-800"
                                            >
                                                <FeatherWrapper icon="helix" class="text-white" />
                                                <span>Health </span>
                                                <span
                                                    class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-rose-500 text-white"
                                                >
                                                    Explore
                                                </span>
                                            </button>
                                            <a
                                                v-else
                                                href="/health"
                                                class="px-4 text-white py-3 rounded flex items-center w-full text-sm space-x-4 hover:bg-denim-800"
                                            >
                                                <FeatherWrapper icon="helix" class="text-white" />
                                                <span class="text-white">Health </span>
                                                <span
                                                    class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-rose-500 text-white"
                                                >
                                                    Explore
                                                </span>
                                            </a>
                                        </li>
                                        <portal-target name="help-button" />
                                    </ul>
                                </nav>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-denim-700 px-6 pb-4">
                <div class="flex h-16 shrink-0 items-center">
                    <router-link :to="{ name: 'dashboard' }" class="flex items-center space-x-2">
                        <img
                            class="h-8 w-auto"
                            src="@/../public/vue-public/Logo.png"
                            alt="Living DNA Logo"
                        />
                        <span class="bg-pacific-500 text-white px-2 rounded py-px text-sm">
                            Ancestry
                        </span>
                    </router-link>
                </div>
                <nav class="flex flex-1 flex-col">
                    <ul role="list" class="flex flex-1 flex-col">
                        <li>
                            <ul role="list" class="-mx-2 space-y-1">
                                <li v-for="item in navigation" :key="item.name">
                                    <router-link v-slot="{ isActive }" :to="item.href">
                                        <div
                                            :class="[
                                                isActive
                                                    ? 'bg-denim-800 text-white'
                                                    : 'text-white hover:bg-denim-800',
                                                'group flex items-center px-2 py-2 text-sm font-medium rounded-md mb-2 rounded',
                                            ]"
                                        >
                                            <FeatherWrapper
                                                :icon="item.icon"
                                                class="h-5 w-5 mr-4"
                                            />
                                            {{ item.name }}
                                            <span
                                                v-if="item.new"
                                                class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pacific-500 text-white"
                                            >
                                                New feature
                                            </span>

                                            <span
                                                v-else-if="item.update"
                                                class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-pacific-500 text-white"
                                            >
                                                Update
                                            </span>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li class="mt-auto -mx-2 mb-2">
                            <button
                                v-if="!hasPatient"
                                @click="(healthModalOpen = true), (sidebarOpen = false)"
                                class="px-2 text-white py-3 rounded flex items-center w-full text-sm space-x-4 hover:bg-denim-800"
                            >
                                <FeatherWrapper icon="helix" class="text-white" />
                                <span>Health </span>
                                <span
                                    class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-rose-500 text-white"
                                >
                                    Explore
                                </span>
                            </button>
                            <a
                                v-else
                                href="/health"
                                class="px-2 text-white py-3 rounded flex items-center w-full text-sm space-x-4 hover:bg-denim-800"
                            >
                                <FeatherWrapper icon="helix" class="text-white" />
                                <span class="text-white">Health </span>
                                <span
                                    class="ml-2 -mt-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-rose-500 text-white"
                                >
                                    Explore
                                </span>
                            </a>
                        </li>

                        <li class="-mx-2">
                            <portal-target name="help-button" />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class="lg:pl-72 h-full">
            <div
                class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
            >
                <button
                    type="button"
                    class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                    @click="sidebarOpen = true"
                >
                    <span class="sr-only">Open sidebar</span>
                    <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                </button>

                <!-- Separator -->
                <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

                <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <div class="flex flex-1">
                        <portal-target name="navigation" multiple />
                    </div>

                    <div class="flex items-center gap-x-4 lg:gap-x-6">
                        <!-- Profile dropdown -->
                        <Menu as="div" class="relative">
                            <MenuButton class="-m-1.5 flex items-center p-1.5">
                                <span class="sr-only">Open user menu</span>
                                <Avatar
                                    :path="$store.getters.profile.photo"
                                    class="h-8 w-8 rounded-full bg-gray-50 shadow-md"
                                />
                                <span class="hidden lg:flex lg:items-center">
                                    <span
                                        class="ml-4 text-sm font-semibold leading-6 text-gray-900"
                                        aria-hidden="true"
                                    >
                                        {{ $store.getters.profile.name }}
                                    </span>

                                    <ChevronDownIcon
                                        class="ml-2 h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </span>
                            </MenuButton>
                            <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <MenuItems
                                    class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                                >
                                    <MenuItem v-slot="{ active }">
                                        <button
                                            @click="modalOpen = true"
                                            :class="[
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-gray-700',
                                            ]"
                                        >
                                            Change profile
                                        </button>
                                    </MenuItem>
                                    <template v-for="item in userNavigation" :key="item.name">
                                        <RouterLink
                                            v-slot="{ href, navigate }"
                                            :to="item.href"
                                            custom
                                        >
                                            <MenuItem v-slot="{ active, close }">
                                                <a
                                                    :class="[
                                                        active ? 'bg-gray-100' : '',
                                                        'block px-4 py-2 text-sm text-gray-700',
                                                    ]"
                                                    :href="href"
                                                    @click.prevent="
                                                        navigate();
                                                        close();
                                                    "
                                                    >{{ item.name }}</a
                                                >
                                            </MenuItem>
                                        </RouterLink>
                                    </template>

                                    <MenuItem v-slot="{ active }">
                                        <button
                                            @click="onLogout"
                                            :class="[
                                                active ? 'bg-gray-100' : '',
                                                'block px-4 py-2 text-sm text-gray-700 w-full text-left',
                                            ]"
                                        >
                                            Sign out
                                        </button>
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </div>

            <div class="sticky top-0">
                <portal-target ref="test" name="secondary-navigation" multiple />
            </div>
            <div
                :class="
                    hasSubNav
                        ? 'h-[calc(100%-64px)] md:h-[calc(100%-132px)]'
                        : 'h-[calc(100%-64px)]'
                "
            >
                <div class="flex-1 relative z-0 flex overflow-hidden bg-grey-50 h-full">
                    <main
                        class="flex-1 relative z-0 overflow-y-auto focus:outline-none h-full"
                        id="main"
                    >
                        <!-- Start main area-->
                        <div class="absolute inset-0 h-full">
                            <router-view />
                        </div>
                        <!-- End main area -->
                    </main>
                    <portal-target name="sidebar" />
                </div>
            </div>
        </div>
    </div>
    <PortalProfileSwitcherModal :open="modalOpen" @state-change="modalOpen = $event" />
    <PortalHelpButton @modal-opened="sidebarOpen = false" />
    <HealthUpsellModal
        v-if="!hasPatient"
        :modal-data="modalData"
        :show="healthModalOpen"
        @closed="healthModalDismissed()"
    />
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { Bars3Icon, XMarkIcon, QuestionMarkCircleIcon } from "@heroicons/vue/24/outline";
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import { getPatient, useNewWellbeing, getModalData, isHealthEnabled } from "@/services/health";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import { useRoute, useRouter } from "vue-router";
import { useAuth } from "@websanova/vue-auth";
import Avatar from "@/components/Avatar.vue";
import HealthUpsellModal from "@/components/HealthUpsellModal.vue";
import PortalProfileSwitcherModal from "@/components/partials/PortalProfileSwitcherModal.vue";
import PortalHelpButton from "@/components/partials/PortalHelpButton.vue";
import store from "@/store/store";
import classicalRoutes from "@/router/classical";

const modalOpen = ref(false);
const healthModalOpen = ref(false);
const hasPatient = ref(false);
const modalData = ref({});
const test = ref(null);

const navigation = computed(() => [
    { name: "Dashboard", href: "/dashboard", icon: "home" },
    {
        name: "Recent Ancestry",
        href: "/ancestry",
        icon: "map-pin",
        update: !!store.getters.kitTests.autosomal.updateAvailable,
    },
    { name: "Motherline", href: "/motherline", icon: "female" },
    { name: "Fatherline", href: "/fatherline", icon: "male" },
    { name: "Family Matching", href: "/family-matching", icon: "users" },
    ...(useNewWellbeing.value ? [] : [{ name: "Wellbeing", href: "/wellbeing", icon: "heart" }]),
    { name: "Viking", href: "/vikings", icon: "viking-shield" },
    { name: "Neanderthal", href: "/archaic", icon: "spear" },
    { name: "Classical", href: "/classical", icon: "classical", new: true },
    { name: "Store", href: "/store", icon: "shopping-cart" },
    // { name: "Profiles", href: "/profiles", icon: "helix" },
    // { name: "Account", href: "/account", icon: "settings" },
]);

const userNavigation = [
    { name: "Profiles", href: "/profiles" },
    { name: "Account", href: "/account" },
];

const sidebarOpen = ref(false);

const router = useRouter();
const auth = useAuth();
function onLogout() {
    auth.logout();
    router.push({ name: "login" });
}

function healthModalDismissed() {
    healthModalOpen.value = false;
    localStorage.setItem("DISMISSED_HEALTH_MODAL", "true");
}

const routesWithSubNav = classicalRoutes.flatMap((route) => {
    return route.children.flatMap((child) => {
        return child.children?.map((c) => c.name);
    });
});

const route = useRoute();

const hasSubNav = computed(() => {
    return routesWithSubNav.includes(route.name);
});

getModalData()
    .then((res) => {
        modalData.value = res.data.data.NewLivingDnaProfileModal;
    })
    .then(() => {
        getPatient().then((data) => {
            hasPatient.value = !!data;
            console.log(store.getters.kitTests.gluten);
            if (
                !localStorage.getItem("DISMISSED_HEALTH_MODAL") &&
                (!modalData.value.wellbeing_users_only || store.getters.kitTests.gluten.purchased)
            ) {
                healthModalOpen.value = true;
            }
        });
    });
</script>
