import axios from "axios";
import endpoints from "@/endpoints";

export const fetchCurrentManager = async (barcode) => {
    try {
        const res = await axios.get(endpoints.profileManager(barcode));
        return res.data.email;
    } catch (e) {
        if (e.response.status === 404) {
            return "None";
        }
        throw e;
    }
};

export const removeCurrentManager = async (barcode) => {
    try {
        const res = await axios.delete(endpoints.manager, {
            data: {
                barcode,
            },
        });
        return res.data.manager;
    } catch (e) {
        console.log("Request to remove manager failed", e);
        throw e;
    }
};

export const addNewManager = async (barcode, managerEmail) => {
    try {
        const res = await axios.post(endpoints.manager, {
            barcode,
            email: managerEmail,
        });
        return res.data.manager;
    } catch (e) {
        console.log("Request to add manager failed", e);
        throw e;
    }
};

export const mapBackendErrorToFrontendText = (errorText) =>
    ({
        "User is owner of the profile":
            "This email address already has full management access as the Owner of the DNA profile",
        "User does not exist": `This email address is not associated with a Living DNA account.
                Please check your spelling and try again. <br> If this person does not yet have a
                Living DNA account, ask them to register first at
                <a href="https://my.livingdna.com/signup" class="text-rose-500 font-semibold">
                my.LivingDNA.com/signup</a>`,
    }[errorText]);
