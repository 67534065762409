export default {
    fitnessReports: [
        "enduranceBloodVessel",
        "enduranceCardiacResponse",
        "enduranceTrainability",
        "injuryRisk",
        "flexiblity",
        "recoveryTime",
        "strength",
    ],
    activeReports: [
        "enduranceBloodVessel",
        "enduranceCardiacResponse",
        "enduranceTrainability",
        "injuryRisk",
        // 'flexibility',
        "recoveryTime",
        "strength",
        // 'calcium',
        "carbohydrates",
        "gluten",
        "lactose",
        "omega3and6",
        // 'saturatedfats',
        "vitaminA",
        "vitaminB12",
        "vitaminC",
        "vitaminD",
    ],
};
