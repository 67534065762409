import MyTests, { upgradeTest } from "@/services/my-tests";

export default {
    state: {
        kitTests: {},
    },
    getters: {
        kitTests: state => state.kitTests,
        starterTestMode: state =>
            state.kitTests.autosomal.purchased === false &&
            state.kitTests.autosomalStarter.purchased,
        autosomalUpdateAvailable: state => {
            // console.log('g', state.kitTests.autosomal);
            return state.kitTests.autosomal.updateAvailable;
        },
        autosomalUpdateInProgress: state => state.kitTests.autosomal.status === "updating",
    },
    mutations: {
        SET_KIT_TESTS(state, kitTests) {
            state.kitTests = kitTests;
        },
        CLEAR_KIT_TESTS(state) {
            state.kitTests = {};
        },
        upgradeTest(state, test) {
            Object.assign(state.kitTests[test], {
                updateAvailable: false,
                status: "updating",
            });
        },
    },
    actions: {
        async SET_KIT_TESTS({ commit, getters }, barcode) {
            const tests = await MyTests.getKitTests(barcode, getters.selectedTest.raw_status);
            commit("SET_KIT_TESTS", tests);
        },
        CLEAR_KIT_TESTS({ commit }) {
            commit("CLEAR_KIT_TESTS");
        },
        async upgradeTest({ commit }, { barcode, test }) {
            await upgradeTest(barcode, test);
            commit("upgradeTest", test);
        },
    },
};
