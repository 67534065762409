import treeImage from "@/assets/images/signin/iStock-826220836.png";
import peopleImage from "@/assets/images/activation/iStock-826220836.png";
import activationImage from "@/assets/images/activation/activation-code.jpg";
import ipad from "@/assets/images/upload/ipad.png";

export default {
    treeImage,
    peopleImage,
    activationImage,
    ipad,
};
