<template>
    <BaseMap class="h-full" @map-loaded="mapLoaded" />
</template>

<script setup>
import { onMounted, onUnmounted, watch } from "vue";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { accessToken, mapStyle } from "@/views/ancestry/recent/mapboxConfig";
import { calculateOpacity } from "@/views/ancestry/recent/mapHelpers";
import { useRoute, useRouter } from "vue-router";
import BaseMap from "@/components/BaseMap.vue";

const genderColors = {
    fatherline: "#00A5B0",
    motherline: "#d40b55",
};

const color = (gender) => genderColors[gender];

const props = defineProps({
    coverageData: Object,
    gender: String,
});

const route = useRoute();
const router = useRouter();

let unwatch;

function createActiveLayer(map, featureId) {
    if (map?.getLayer("activeLayer")) {
        map.removeLayer("activeLayer");
    }

    map.addLayer({
        id: "activeLayer",
        type: "line",
        source: featureId,
        layout: {},
        paint: {
            "line-color": color(props.gender),
            "line-width": 2,
        },
    });

    const originalFeature = props.coverageData.data.features.find((f) => f.id === featureId);

    const bbox = turf.bbox(originalFeature.geometry);
    map.fitBounds(bbox, {
        padding: 50,
    });
}

function mapLoaded(map) {
    props.coverageData.data.features.forEach((feature) => {
        map.addSource(feature.id, {
            type: "geojson",
            data: {
                type: "Feature",
                geometry: {
                    type: feature.geometry.type,
                    coordinates: feature.geometry.coordinates,
                },
            },
        });

        map.addLayer({
            id: feature.id,
            type: "fill",
            source: feature.id,
            layout: {},
            paint: {
                "fill-color": color(props.gender),
                "fill-opacity": calculateOpacity(feature.properties.percentage),
            },
        });

        map.on("click", feature.id, ({ features }) => {
            const [currentFeature] = features;
            router.push({
                name: `${props.gender}-distribution-map`,
                params: { region: currentFeature.source },
            });
        });
    });

    unwatch = watch(
        () => route.params.region,
        () => {
            if (route.params.region) {
                createActiveLayer(map, route.params.region);
            } else {
                const bbox = turf.bbox(props.coverageData.data);
                map.fitBounds(bbox, { padding: 100 });
            }
        },
        {
            immediate: true,
        }
    );
}

onUnmounted(() => {
    unwatch();
});
</script>
