<template>
    <div class="shadow rounded-lg bg-white h-full flex flex-col p-6 border-t-4 border-rose-500">
        <div>
            <div class="text-sm text-gray-600">Maternal haplogroup</div>
            <div class="text-rose-500 text-2xl font-semibold">
                {{ sharingStore.motherlineResult.haplogroup }}
            </div>
        </div>
        <div v-if="sharingStore.motherlineResult.subclade" class="mt-4">
            <div class="text-sm text-gray-600">Maternal haplotype</div>
            <div class="text-rose-500 text-2xl font-semibold">
                {{ sharingStore.motherlineResult.subclade }}
            </div>
        </div>
        <!--        <p class="mt-4">-->
        <!--            Your maternal DNA is most commonly found in <strong>{{ maternalData.region }}</strong-->
        <!--        >.-->
        <!--        </p>-->
        <a href="#motherline" class="flex items-center text-rose-500 mt-4">
            <div class="flex-grow-0">
                <FeatherWrapper icon="female" />
            </div>

            <span class="ml-2 font-semibold">Motherline</span>
        </a>
    </div>
</template>

<script setup>
import { useSharingStore } from "@/store/sharing";

const sharingStore = useSharingStore();
</script>
