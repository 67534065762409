<template>
    <div class="w-full">
        <!--        <ChromosomeBrowserNotification />-->
        <HeaderImage />
        <div class="px-6 md:px-12 container mx-auto relative z-10 pb-12" :key="barcode">
            <AutosomalUpdateAvailable />
            <DashboardPromo />
            <StatusBar />
            <Ancestry />
            <FamilyMatching />
            <WellBeing v-if="!hasWellbeingFailed" />
        </div>
        <div
            v-if="loading"
            class="absolute inset-0 z-30"
            style="background-color: rgba(0, 0, 0, 0.3)"
        ></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChromosomeBrowserNotification from "./ChromosomeBrowserNotification.vue";
import HeaderImage from "@/components/Dashboard/HeaderImage.vue";
import StatusBar from "@/views/dashboard/StatusBar.vue";
import Ancestry from "@/components/Dashboard/Ancestry/Ancestry.vue";
import FamilyMatching from "@/components/Dashboard/FamilyMatching/FamilyMatching.vue";
import WellBeing from "@/components/Dashboard/Wellbeing/Wellbeing.vue";
import Offers from "@/components/Dashboard/Offers/Offers.vue";
import AutosomalUpdateAvailable, {
    useModal,
} from "@/components/modals/Dashboard/AutosomalUpdateAvailable.vue";
import DashboardPromo from "@/components/DashboardPromo.vue";

export default {
    components: {
        DashboardPromo,
        ChromosomeBrowserNotification,
        HeaderImage,
        StatusBar,
        Ancestry,
        WellBeing,
        Offers,
        AutosomalUpdateAvailable,
        FamilyMatching,
    },
    data() {
        return {
            barcode: 0,
            loading: false,
        };
    },
    created() {
        const { open } = useModal();

        if (this.autosomalUpdateAvailable && !this.dashboardModalClosed) {
            open();
        }
        this.$store.subscribe((mutation) => {
            if (mutation.type === "SET_KIT_TESTS") {
                this.barcode = this.$store.getters.profile.barcode;
            }
        });
    },
    computed: {
        ...mapGetters(["autosomalUpdateAvailable", "dashboardModalClosed"]),
        hasWellbeingFailed() {
            return this.$store.getters.kitTests.strength.status === "failed";
        },
    },
};
</script>

<style>
p {
    @apply leading-normal !important;
}
</style>
