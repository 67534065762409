<template>
    <div class="shadow-key rounded-lg bg-white flex-1 flex">
        <slot name="body"></slot>
        <div
            :class="`hidden md:block overflow-hidden rounded-r-lg fade-left bg-cover bg-white ${imageClass}`"
            :style="{ backgroundImage: `url('${image.src}')` }"
        />
    </div>
</template>

<script>
export default {
    props: {
        image: {
            required: true,
            type: Object,
        },
        imageClass: {
            type: String,
            default: "w-2/5",
        },
    },
};
</script>
