<template>
    <profile-form
        headerText="Lifetime"
        :buttonDisabled="buttonDisabled"
        :profile="profile"
        :partners="partners"
        :fieldData="{
            dateOfBirth: formattedDob,
            dateOfDeath: formattedDod,
        }"
        @submit="onSubmit"
    >
        <date-input class="mt-4" :input="dob" v-model="dateOfBirth"> Date of birth </date-input>

        <div class="flex items-center pt-4">
            <input
                v-model="isAlive"
                class="form-checkbox rounded w-6 h-6 text-cobalt-500 mr-3 border-grey-500"
                type="checkbox"
                id="isLiving"
            />
            <label for="isLiving" class="cursor-pointer text-sm font-semibold">
                Person is living
            </label>
        </div>

        <date-input v-if="!isAlive" v-model="dateOfDeath" class="pt-4" :input="dod">
            Date of death
        </date-input>
    </profile-form>
</template>

<script>
import ProfileForm from "@/components/Profile/Edit/ProfileForm.vue";
import DateInput from "@/components/inputs/DateInput.vue";

export default {
    name: "EditLifetime",

    props: {
        profile: {
            type: Object,
            required: true,
        },
        partners: {
            type: Array,
            required: true,
        },
    },

    components: {
        ProfileForm,
        DateInput,
    },

    watch: {
        isAlive(n) {
            if (n) {
                this.dateOfDeath = null;
            }
        },
    },

    data() {
        return {
            dateOfBirth: this.profile.dateOfBirth,
            isAlive: !this.profile.dateOfDeath,
            dateOfDeath: this.profile.dateOfDeath,
            initialDateOfBirth: this.profile.dateOfBirth,
            initialIsAlive: !this.profile.dateOfDeath,
            initialDateOfDeath: this.profile.dateOfDeath,
        };
    },

    methods: {
        formatDate(date) {
            if (typeof date === "string") {
                const [year, month, day] = date.split("-");
                return { year, month, day };
            }
            return date;
        },

        stringifyDate(date) {
            if (typeof date === "object") {
                return `${date.year}-${date.month}-${date.day}`;
            }
            return "";
        },

        onSubmit() {
            this.initialDateOfBirth = this.dateOfBirth;
            this.initialIsAlive = this.isAlive;
            this.initialDateOfDeath = this.dateOfDeath;
        },
    },

    computed: {
        dob() {
            return this.formatDate(this.dateOfBirth);
        },

        dod() {
            if (this.dateOfDeath === null) {
                return { year: "", month: "", day: "" };
            }
            return this.formatDate(this.dateOfDeath);
        },

        formattedDob() {
            return this.stringifyDate(this.dob);
        },

        formattedDod() {
            const date = this.stringifyDate(this.dod);
            return date === "--" ? null : date;
        },

        buttonDisabled() {
            return [
                this.initialDateOfBirth === this.dateOfBirth,
                this.initialIsAlive === this.isAlive,
                this.initialDateOfDeath === this.dateOfDeath,
            ].every((x) => x);
        },
    },
};
</script>
