import Product from "@/views/store/product/Product.vue";
import CountryPicker from "@/views/country-picker/CountryPicker.vue";
import Products from "@/views/store/Products.vue";
import StoreMaintenance from "@/views/store/StoreMaintenance.vue";
import OrderSuccess from "@/views/store/OrderSuccess.vue";

function isStoreInMaintenanceMode() {
    if (localStorage.getItem("ldna-maintenance") === "2b731473-9e84-466c-a584-823c57a10ee2") {
        return false;
    }
    return String(process.env.VUE_APP_ENABLE_STORE_MAINTENANCE_MODE) === "true";
}

export default [
    {
        path: "/store/set-region/:slug?",
        name: "product-set-region",
        component: CountryPicker,
        meta: { auth: true, type: "store" },
    },
    {
        path: "/store",
        name: "products",
        component: isStoreInMaintenanceMode() ? StoreMaintenance : Products,
        meta: { auth: true, type: "store" },
    },
    {
        path: "/store/success",
        name: "order-success",
        component: isStoreInMaintenanceMode() ? StoreMaintenance : OrderSuccess,
        meta: { auth: true },
    },
    {
        path: "/store/:slug",
        name: "product",
        component: isStoreInMaintenanceMode() ? StoreMaintenance : Product,
        meta: { auth: true, type: "store-slug" },
    },
    {
        path: "/store/**",
        redirect: "/store",
    },
];
