import Sharing from "@/views/sharing/Sharing.vue";

export default [
    {
        path: "/share/:uuid",
        component: Sharing,
        children: [
            // {
            //     path: "overview",
            //     name: "viking-overview",
            //     component: Overview,
            // },
            // {
            //     path: "results",
            //     name: "viking-results",
            //     component: VikingsResults,
            // },
            // {
            //     path: "maps",
            //     name: "viking-map",
            //     component: VikingsMaps,
            // },
        ],
    },
];
