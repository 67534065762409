// eslint-disable-next-line import/no-cycle
import AncestryService from "@/services/ancestry";
import loadingHelper from "@/services/store";

const { loadingState, loadingMutations } = loadingHelper("MATERNAL_DATA");

const initialState = () => ({
    loadingStatus: loadingState.PENDING,
    id: 0,
    isUserBetaTester: false,
    mtdnaState: "",
    testData: {
        content: {
            contentOne: {
                image: "",
                body: "",
            },
            contentTwo: {
                image: "",
                body: "",
            },
            citations: [],
            shortDescription: "",
        },
        distribution: [],
        haplogroup: "",
        haplogroupTree: [],
        migrationPaths: [],
        positiveMarkers: [],
        subclade: "",
    },
});

export default {
    state: initialState(),
    getters: {
        maternalDataLoadingStatus: state => state.loadingStatus,
        motherlineContent: state => state.testData.content,
        maternalDistribution: state => state.testData.distribution,
        motherlineHaplogroup: state => state.testData.haplogroup,
        motherlineHaplogroupTree: state => state.testData.haplogroupTree,
        motherlineMigrationPaths: state => state.testData.migrationPaths,
        motherlinePositiveMarkers: state => state.testData.positiveMarkers,
        motherlineSubclade: state => state.testData.subclade,
        motherlineTestStatus: state => state.mtdnaState,
    },
    mutations: {
        ...loadingMutations,
        SET_MOTHERLINE_DATA(state, data) {
            const {
                content,
                distribution,
                haplogroup,
                haplogroupTree,
                migrationPaths,
                positiveMarkers,
                subclade,
            } = data.testData.mtdna.results;

            state.id = data.id;
            state.isUserBetaTester = data.isUserBetaTester;
            state.mtdnaState = data.testData.mtdna.status;

            state.testData = {
                content,
                distribution,
                haplogroup,
                haplogroupTree,
                migrationPaths,
                positiveMarkers,
                subclade,
            };
        },
        CLEAR_MATERNAL_MODULE(state) {
            Object.assign(state, initialState());
        },
    },
    actions: {
        async GET_MOTHERLINE_DATA({ commit, state }, barcode) {
            try {
                if (state.mtdnaState.length === 0) {
                    commit(loadingState.PENDING);

                    commit(
                        "SET_MOTHERLINE_DATA",
                        await AncestryService.getMotherlineHistory(barcode)
                    );

                    commit(loadingState.SUCCESS);
                }
            } catch (err) {
                console.error(err);
                commit(loadingState.FAILED);
            }
        },

        CLEAR_MATERNAL_MODULE({ commit }) {
            commit("CLEAR_MATERNAL_MODULE");
        },
    },
};
