<template>
    <portal to="sidebar">
        <Sidebar>
            <div>
                <div class="fade-bottom">
                    <img
                        :src="sampleGroup.main_image?.url"
                        class="h-64 w-full object-cover"
                        alt=""
                    />
                </div>
                <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                    <div class="flex items-center justify-between mb-8">
                        <h1 class="text-xl mb-0">{{ sampleGroup.heading }}</h1>
                        <!--                        <button-->
                        <!--                            v-if="!hasZoomed"-->
                        <!--                            @click="zoom"-->
                        <!--                            class="text-cobalt-500 font-semibold px-4 py-1 bg-cobalt-50 rounded flex gap-2 items-center"-->
                        <!--                        >-->
                        <!--                            <FeatherWrapper icon="zoom-in" />-->
                        <!--                            <span>Zoom</span>-->
                        <!--                        </button>-->
                        <!--                        <button-->
                        <!--                            v-else-->
                        <!--                            @click="resetZoom"-->
                        <!--                            class="text-cobalt-500 font-semibold px-4 py-1 bg-cobalt-50 rounded flex gap-2 items-center"-->
                        <!--                        >-->
                        <!--                            <FeatherWrapper icon="zoom-out" />-->
                        <!--                            <span>Reset</span>-->
                        <!--                        </button>-->
                    </div>

                    <div v-html="sampleGroup.text" />
                </div>
            </div>
        </Sidebar>
    </portal>
    <!--    <div class="xl:hidden">-->
    <!--        <div v-if="loadingStatus === 'loading'" class="mt-12">-->
    <!--            <PageLoader class="mt-12">Loading</PageLoader>-->
    <!--        </div>-->
    <!--        <div v-else>-->
    <!--            <portal to="navigation">-->
    <!--                <Tabs v-model="mode" />-->
    <!--            </portal>-->

    <!--            <div v-if="mode === 'info'">-->
    <!--                <div class="fade-bottom">-->
    <!--                    <img-->
    <!--                        :src="vikingResult.poulationGroupCmsInformation.mainImage.urlRaw"-->
    <!--                        alt=""-->
    <!--                        class="fade-bottom"-->
    <!--                    />-->
    <!--                </div>-->
    <!--                <div class="py-6 px-4 sm:px-6 lg:px-8">-->
    <!--                    <h1 class="text-xl">{{ vikingResult.poulationGroupCmsInformation.heading }}</h1>-->
    <!--                    <div v-html="vikingResult.poulationGroupCmsInformation.text" />-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <BaseMap
        v-if="showMap && mode === 'map'"
        type="satellite"
        class="h-full"
        @map-loaded="onMapLoaded"
    />

    <MobileContent :title="'Egyptian map'" remove-padding>
        <div>
            <div class="fade-bottom">
                <img :src="sampleGroup.main_image?.url" class="h-64 w-full object-cover" alt="" />
            </div>
            <div class="py-6 px-4 sm:px-6 lg:px-8 prose mx-auto">
                <div class="flex items-center justify-between mb-8">
                    <h1 class="text-xl mb-0">{{ sampleGroup.heading }}</h1>
                    <button
                        v-if="!hasZoomed"
                        @click="zoom"
                        class="text-cobalt-500 font-semibold px-4 py-1 bg-cobalt-50 rounded flex gap-2 items-center"
                    >
                        <FeatherWrapper icon="zoom-in" />
                        <span>Zoom</span>
                    </button>
                    <button
                        v-else
                        @click="resetZoom"
                        class="text-cobalt-500 font-semibold px-4 py-1 bg-cobalt-50 rounded flex gap-2 items-center"
                    >
                        <FeatherWrapper icon="zoom-out" />
                        <span>Reset</span>
                    </button>
                </div>

                <div v-html="sampleGroup.text" />
            </div>
        </div>
    </MobileContent>
</template>

<script setup>
import { ref } from "vue";
import BaseMap from "@/components/BaseMap.vue";
import Sidebar from "@/components/Sidebar.vue";
import {
    attachClassicalShapesToMap,
    attachClassicalSitesToMap,
    zoomToGroup,
} from "@/views/ancestry/recent/mapHelpers";
import MobileContent from "@/components/ancestry/MobileContent.vue";

const props = defineProps({
    introduction: Object,
    sampleGroup: Object,
    sampleSites: Object,
});

const showMap = ref(false);
const mode = ref("map");
const mapRef = ref({});
const hasZoomed = ref(false);

setTimeout(() => {
    showMap.value = true;
});

function zoom() {
    hasZoomed.value = true;
    const [sampleGroupFeature] = JSON.parse(props.sampleGroup.plain_text).features;
    zoomToGroup(mapRef.value, sampleGroupFeature);
}

function resetZoom() {
    hasZoomed.value = false;
    const [feature] = JSON.parse(props.introduction.plain_text).features;
    zoomToGroup(mapRef.value, feature);
}

function onMapLoaded(map) {
    mapRef.value = map;

    const sites = JSON.parse(props.sampleSites.plain_text).features;
    const [feature] = JSON.parse(props.introduction.plain_text).features;
    const [sampleGroupFeature] = JSON.parse(props.sampleGroup.plain_text).features;

    attachClassicalShapesToMap(map, {
        people: "egypt",
        feature,
        type: "main",
    });

    attachClassicalShapesToMap(map, {
        people: "egypt",
        feature: sampleGroupFeature,
        type: "sampleGroup",
    });

    zoomToGroup(map, feature);

    attachClassicalSitesToMap(map, { sites, people: "egyptian" });
}
</script>
