<template>
    <main>
        <h1 class="font-normal text-2xl">Add a manager to your profile (optional)</h1>
        <form @submit.prevent="managerService.send({ type: 'SAVE', data: $event })">
            <div>
                <template
                    v-if="
                        currentManagerState.matches('hasNoManager') ||
                        currentManagerState.matches('startedEnteringManagerDetails') ||
                        currentManagerState.matches('savingAddManagerRequest') ||
                        currentManagerState.matches('errorAddingManager')
                    "
                >
                    <AddManagerText class="my-4" />
                    <div>
                        <label for="email" class="text-grey-700 font-semibold block w-full text-sm">
                            Email
                        </label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            class="mt-1 border border-grey-300 rounded w-full py-2 px-4 focus:outline-none focus:border-cobalt-500"
                            @input="
                                managerService.send({
                                    type: 'RECEIVED_INPUT',
                                    email: $event,
                                })
                            "
                        />
                    </div>

                    <div
                        v-if="currentManagerState.context.errorMessage"
                        v-html="currentManagerState.context.errorMessage"
                        class="text-rose-500 text-sm mt-4"
                    />

                    <SubmitButton
                        icon="save"
                        :disabled="!currentManagerState.matches('startedEnteringManagerDetails')"
                        :class="{
                            loading:
                                currentManagerState.matches('savingRemoveManagerRequest') ||
                                currentManagerState.matches('savingAddManagerRequest'),
                        }"
                    >
                        Save
                    </SubmitButton>
                </template>
                <template v-else-if="currentManagerState.matches('hasManager')">
                    <HaveExistingManagerText>
                        <div class="bg-grey-50 shadow my-8 rounded overflow-hidden">
                            <div class="flex items-stretch justify-between">
                                <div class="py-3 px-4 break-all">
                                    {{ currentManagerState.context.email }}
                                </div>
                                <button
                                    @click="managerService.send('REMOVE_MANAGER')"
                                    class="hover:bg-grey-200 flex px-4 cursor-pointer"
                                >
                                    <Feather
                                        type="x"
                                        class="my-auto fill-current text-grey-700 h-5 w-5"
                                    />
                                </button>
                            </div>
                        </div>
                    </HaveExistingManagerText>
                    <SubmitButton
                        icon="save"
                        @click.native="managerService.send('SAVE')"
                        :disabled="!currentManagerState.matches('hasRemovedManager')"
                        :class="{ loading: currentManagerState.matches('updatingManager') }"
                    >
                        Save
                    </SubmitButton>
                </template>
                <template
                    v-else-if="
                        currentManagerState.matches('hasRemovedManager') ||
                        currentManagerState.matches('savingRemoveManagerRequest')
                    "
                >
                    <HaveExistingManagerText>
                        <p class="bg-cobalt-50 text-cobalt-500 p-4 rounded">
                            You have removed the manager from your profile. Hit Save to confirm
                            these changes.
                        </p>
                    </HaveExistingManagerText>
                    <SubmitButton
                        icon="save"
                        @click.native="managerService.send('SAVE')"
                        :disabled="!currentManagerState.matches('hasRemovedManager')"
                        :class="{
                            loading: currentManagerState.matches('savingRemoveManagerRequest'),
                        }"
                    >
                        Save
                    </SubmitButton>
                </template>
                <template v-else-if="currentManagerState.matches('currentUserIsManager')">
                    Only the owner of a profile can add or remove a manager
                </template>
            </div>
            <div class="text-center">
                <router-link
                    :to="{ name: 'profile-view' }"
                    class="mt-2 p-2 inline-block text-grey-500"
                >
                    Cancel
                </router-link>
            </div>
        </form>
    </main>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import {
    fetchCurrentManager,
    removeCurrentManager,
    addNewManager,
    mapBackendErrorToFrontendText,
} from "@/services/manager";
import SubmitButton from "@/components/button/SubmitButton.vue";
import BaseTextInput from "@/components/Base/BaseTextInput.vue";
import AddManagerText from "@/components/Profile/Edit/Manager/AddManagerText.vue";
import HaveExistingManagerText from "@/components/Profile/Edit/Manager/HaveExistingManagerText.vue";

const createManagerMachine = (barcode, currentUserEmail) =>
    Machine(
        {
            id: "manager-machine",
            initial: "fetchingManagerInformation",
            context: {
                barcode,
                currentUserEmail,
                errorMessage: "",
                email: "",
            },
            states: {
                fetchingManagerInformation: {
                    invoke: {
                        src: "fetchCurrentManager",
                        onDone: [
                            {
                                target: "hasNoManager",
                                cond: (context, event) => event.data === "None",
                                actions: assign({
                                    email: "",
                                }),
                            },
                            {
                                target: "currentUserIsManager",
                                cond: (context, event) => event.data === currentUserEmail,
                            },
                            {
                                target: "hasManager",
                                actions: assign({
                                    email: (context, event) => event.data,
                                }),
                            },
                        ],
                    },
                },
                hasManager: {
                    on: {
                        REMOVE_MANAGER: {
                            target: "hasRemovedManager",
                            actions: assign({
                                email: () => "",
                            }),
                        },
                    },
                },
                hasNoManager: {
                    on: {
                        RECEIVED_INPUT: "startedEnteringManagerDetails",
                    },
                },
                currentUserIsManager: {
                    type: "final",
                },
                startedEnteringManagerDetails: {
                    on: {
                        SAVE: "savingAddManagerRequest",
                    },
                },
                hasRemovedManager: {
                    on: {
                        SAVE: "savingRemoveManagerRequest",
                    },
                },
                savingRemoveManagerRequest: {
                    invoke: {
                        src: "removeCurrentManager",
                        onDone: "hasNoManager",
                    },
                },
                savingAddManagerRequest: {
                    invoke: {
                        src: "addNewManager",
                        onDone: "fetchingManagerInformation",
                        onError: {
                            target: "errorAddingManager",
                            actions: assign({
                                errorMessage: (context, event) => {
                                    console.log(event.data);
                                    return event.data;
                                },
                            }),
                        },
                    },
                },
                errorAddingManager: {
                    on: {
                        SAVE: "savingAddManagerRequest",
                        RECEIVED_INPUT: {
                            actions: "cacheEmail",
                            target: "startedEnteringManagerDetails",
                        },
                    },
                },
            },
        },
        {
            services: {
                fetchCurrentManager: (context) =>
                    new Promise((resolve, reject) => {
                        try {
                            resolve(fetchCurrentManager(context.barcode));
                        } catch (e) {
                            reject(new Error(e));
                        }
                    }),
                addNewManager: (context, event) =>
                    new Promise(async (resolve, reject) => {
                        const data = new FormData(event.data.target);
                        try {
                            await addNewManager(context.barcode, data.get("email"));
                            resolve();
                        } catch (e) {
                            console.log(e.response.data.errors[0].title);
                            reject(
                                new Error(
                                    mapBackendErrorToFrontendText(e.response.data.errors[0].title)
                                )
                            );
                        }
                    }),
                removeCurrentManager: (context) =>
                    new Promise((resolve, reject) => {
                        try {
                            resolve(removeCurrentManager(context.barcode));
                        } catch (e) {
                            //
                        }
                    }),
            },
        }
    );

export default {
    data() {
        return {
            managerService: undefined,
            currentManagerState: undefined,
        };
    },
    components: {
        SubmitButton,
        BaseTextInput,
        AddManagerText,
        HaveExistingManagerText,
    },
    created() {
        const managerMachine = createManagerMachine(
            this.$route.params.barcode,
            this.$store.getters.account.email
        );
        this.managerService = interpret(managerMachine);
        this.currentManagerState = managerMachine.initialState;
        this.managerService
            .onTransition((state) => {
                this.currentManagerState = state;
            })
            .start();
    },
};
</script>
