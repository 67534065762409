<template>
    <input
        class="mt-1 border border-grey-500 rounded w-full py-2 px-4 focus:outline-none focus:border-cobalt-500"
        @input="$emit('input', $event)"
    />
</template>

<script>
export default {};
</script>
