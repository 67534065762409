<template>
    <AdvancedImage :cldImg="path" />
</template>

<script setup>
import { useImage } from "@/services/cloudinary";
import { AdvancedImage } from "@cloudinary/vue";

const props = defineProps({
    publicId: {
        required: true,
        type: String,
    },
    width: [Number, String],
});

const path = useImage(props.publicId, Number(props.width));
</script>
