<template>
    <router-link
        :to="linkTo"
        class="flex shadow-key bg-white rounded-lg overflow-hidden max-w-2xl text-cobalt-500"
        style="min-height: 200px"
    >
        <div class="md:w-1/2">
            <div class="p-4 flex flex-col h-full">
                <h2 class="text-grey-700 mb-4">{{ titleText }}</h2>
                <div class="text-grey-600 mb-auto no-inner-p-margin" v-html="descriptionText" />
                <TextLink :to="linkTo" :text="linkText" icon="arrow-up-right" />
            </div>
        </div>
        <div
            class="md:w-1/2 bg-cover text-grey-700 flex items-center justify-center"
            :style="{ backgroundImage: `url(${image.src})` }"
        />
    </router-link>
</template>

<script>
export default {
    props: {
        linkTo: Object,
        titleText: String,
        descriptionText: String,
        linkText: String,
        image: Object,
    },
};
</script>
