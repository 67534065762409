// eslint-disable-next-line import/prefer-default-export
export const kitNames = {
    "LDNA-000-AT": "Ancestry kit",
    "LDNA-000-MT": "Ancestry kit",
    "LDNA-001-AU": "Ancestry kit",
    "LDNA-001-CA": "Ancestry kit",
    "LDNA-001-CGL": "Ancestry kit",
    "LDNA-001-DCD": "Ancestry kit",
    "LDNA-001-EU": "Ancestry kit",
    "LDNA-001-NZ": "Ancestry kit",
    "LDNA-001-ROW": "Ancestry kit",
    "LDNA-001-UK": "Ancestry kit",
    "LDNA-001-US": "Ancestry kit",
    "LDNA-001-USA": "Ancestry kit",
    "LDNA-002-AU": "Ancestry kit",
    "LDNA-002-CA": "Ancestry kit",
    "LDNA-002-EU": "Ancestry kit",
    "LDNA-002-NZ": "Ancestry kit",
    "LDNA-002-ROW": "Ancestry kit",
    "LDNA-002-UK": "Ancestry kit",
    "LDNA-002-US": "Ancestry kit",
    "LDNA-002-USA": "Ancestry kit",
    "LDNA-FAM-001": "Ancestry kit",
    "LDNA-FMP-FULLANCESTRY-AU": "FMP ancestry kit",
    "LDNA-FMP-FULLANCESTRY-CA": "FMP ancestry kit",
    "LDNA-FMP-FULLANCESTRY-EU": "FMP ancestry kit",
    "LDNA-FMP-FULLANCESTRY-NZ": "FMP ancestry kit",
    "LDNA-FMP-FULLANCESTRY-ROW": "FMP ancestry kit",
    "LDNA-FMP-FULLANCESTRY-UK": "FMP ancestry kit",
    "LDNA-FMP-FULLANCESTRY-US": "FMP ancestry kit",
    "LDNA-GOUS-UK": "Gousto ancestry kit",
    "LDNA-SEQ-AU": "Sequencing ancestry kit",
    "LDNA-SEQ-CA": "Sequencing ancestry kit",
    "LDNA-SEQ-EU": "Sequencing ancestry kit",
    "LDNA-SEQ-NZ": "Sequencing ancestry kit",
    "LDNA-SEQ-ROW": "Sequencing ancestry kit",
    "LDNA-SEQ-UK": "Sequencing ancestry kit",
    "LDNA-SEQ-USA": "Sequencing ancestry kit",
    "LDNA-STACK-US": "Ancestry kit",
    "LDNA-STACK-WELLBEING-ANCESTRY-US": "Wellbeing & ancestry kit",
    "LDNA-STARTER-AU": "Starter kit",
    "LDNA-STARTER-CA": "Starter kit",
    "LDNA-STARTER-DIGITAL-AU": "Digital upload",
    "LDNA-STARTER-DIGITAL-CA": "Digital upload",
    "LDNA-STARTER-DIGITAL-EU": "Digital upload",
    "LDNA-STARTER-DIGITAL-NZ": "Digital upload",
    "LDNA-STARTER-DIGITAL-ROW": "Digital upload",
    "LDNA-STARTER-DIGITAL-UK": "Digital upload",
    "LDNA-STARTER-DIGITAL-US": "Digital upload",
    "LDNA-STARTER-EU": "Starter kit",
    "LDNA-STARTER-NZ": "Starter kit",
    "LDNA-STARTER-ROW": "Starter kit",
    "LDNA-STARTER-UK": "Starter kit",
    "LDNA-STARTER-USA": "Starter kit",
    "LDNA-WELLBEING-ANCESTRY-AU": "Wellbeing & ancestry kit",
    "LDNA-WELLBEING-ANCESTRY-CA": "Wellbeing & ancestry kit",
    "LDNA-WELLBEING-ANCESTRY-EU": "Wellbeing & ancestry kit",
    "LDNA-WELLBEING-ANCESTRY-NZ": "Wellbeing & ancestry kit",
    "LDNA-WELLBEING-ANCESTRY-ROW": "Wellbeing & ancestry kit",
    "LDNA-WELLBEING-ANCESTRY-UK": "Wellbeing & ancestry kit",
    "LDNA-WELLBEING-ANCESTRY-USA": "Wellbeing & ancestry kit",
    "LDNA-WELLBEING-AU": "Wellbeing kit",
    "LDNA-WELLBEING-CA": "Wellbeing kit",
    "LDNA-WELLBEING-EU": "Wellbeing kit",
    "LDNA-WELLBEING-NZ": "Wellbeing kit",
    "LDNA-WELLBEING-ROW": "Wellbeing kit",
    "LDNA-WELLBEING-UK": "Wellbeing kit",
    "LDNA-WELLBEING-USA": "Wellbeing kit",
};
