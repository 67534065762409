<template>
    <div
        style="height: 268px"
        class="bg-cover bg-center relative flex"
        :style="{ backgroundImage: `url(${image})` }"
    >
        <router-link
            :to="{ name: 'profile-view', params: { barcode: $store.getters.profile.barcode } }"
            class="flex items-center mt-6 text-white absolute top-0 right-0 z-10 mr-6"
        >
            <feather-wrapper icon="edit" class="text-white" />
            <span class="ml-2 font-semibold text-white">Edit profile</span>
        </router-link>
        <h2 class="m-auto text-white relative z-10 text-2xl text-center font-normal">
            Your DNA has a story to tell.<br />Explore it. Live it.
        </h2>
        <div
            class="absolute inset-0"
            style="background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);}"
        ></div>
    </div>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import image from "@/assets/images/dashboard/header-image.jpg";

export default {
    components: { FeatherWrapper },
    data() {
        return {
            image,
        };
    },
};
</script>
