<template>
    <div class="shadow rounded-lg bg-white h-full flex flex-col">
        <div class="h-32 overflow-hidden rounded-t-lg" :class="{ 'fade-bottom': fade }">
            <img :src="image.src" alt="" class="w-full" />
        </div>
        <div class="px-6 pb-6">
            <slot name="body"></slot>
        </div>
        <div class="mt-auto px-6 pb-6">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        image: {
            required: true,
            type: Object,
        },
        fade: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
