/* eslint-disable camelcase */
function createOrderObject(productVariant, deliver_to, bill_to, shipping, amount) {
    return {
        cart: {
            rows: [
                {
                    sku: productVariant.sku,
                    qty: 1,
                },
            ],
            shipping: shipping ? shipping.name : "", // is this actually what I'm supposed to do for no shipping?
            countryCode: deliver_to.countryCode, // shipping country?
        },
        currency: productVariant.currency,
        deliver_to: deliver_to?.lineOne ? deliver_to : bill_to,
        bill_to,
        amount,
    };
}

export function CreateStripeOrder(barcode, productVariant, deliver_to, bill_to, shipping, amount) {
    return {
        barcode,
        order: createOrderObject(productVariant, deliver_to, bill_to, shipping, amount),
        paymentMethod: "stripe",
    };
}

export function CreatePaypalOrder(
    barcode,
    productVariant,
    deliver_to,
    bill_to,
    shipping,
    amount,
    token
) {
    return {
        barcode,
        order: createOrderObject(productVariant, deliver_to, bill_to, shipping, amount),
        paymentMethod: "paypal",
        paymentToken: token,
    };
}

export function CreateFreeOrder(barcode, productVariant, deliver_to, bill_to, shipping) {
    return {
        barcode,
        order: createOrderObject(productVariant, deliver_to, bill_to, shipping, 0),
        paymentMethod: "stripe",
        token: "n/a",
    };
}
