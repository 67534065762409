<template>
    <div>
        <h3 class="text-denim-700 text-2xl font-normal text-center">Who is this kit for?</h3>
        <KitUser
            v-if="$store.getters.account"
            :loading="loading"
            :loading-message="loadingMessage"
            @submitted="saveWhoIsThisKitFor"
        />
    </div>
</template>

<script>
import axios from "axios";
import { isEmpty } from "lodash";
import KitUser from "@/components/activation/KitUser.vue";
import store from "@/store/store";
import { shouldCreateHealthProfile, createHealthProfile } from "@/services/health";

export default {
    components: { KitUser },
    async created() {
        await this.$store.dispatch("getAccountData");
    },
    data() {
        return {
            loading: false,
            loadingMessage: "Your kit is being activated, this process may take up to a minute.",
        };
    },
    beforeRouteEnter(to, from, next) {
        if (!store.state.activationModule.activationCode) {
            next({ name: "enter-code" });
        }
        next();
    },
    methods: {
        getType(user) {
            const types = {
                Myself: "FOR_ME",
                AnotherAdult: "FOR_ADULT",
                MyChild: "FOR_CHILD",
                Deceased: "FOR_DECEASED",
            };
            if (!isEmpty(user.thirdPartyInformation) && !user.thirdPartyInformation.living) {
                return types.Deceased;
            }
            return types[user.whoFor];
        },
        async saveWhoIsThisKitFor(user) {
            this.loading = true;
            const activationDetails = {
                activationDetails: {
                    activationCode: this.$store.state.activationModule.activationCode,
                    type: this.getType(user),
                    firstName: user.firstName,
                    lastName: user.lastName,
                    dateOfBirth: user.dob,
                    dateOfDeath: user.thirdPartyInformation.dateOfDeath
                        ? user.thirdPartyInformation.dateOfDeath
                        : "",
                    gender: Number(user.biologicalSex),
                    countryOfBirth: user.countryOfBirth.code,
                },
            };
            try {
                const res = await this.$store.dispatch("activateKit", {
                    activationDetails,
                    http: axios,
                });
                console.log(`Activated kit with a barcode of ${res.data.barcode}`);
                const profileList = await this.$store.dispatch("getProfiles");
                await this.$store.dispatch("FORCE_SET_ACCOUNT_TESTS");
                const accountTests = this.$store.getters.accountTests;
                console.log({ accountTests });
                const activatedTest = accountTests.find((test) => {
                    console.log(test);
                    return test.barcode === res.data.barcode;
                });
                console.log({ activatedTest });
                if (shouldCreateHealthProfile(activatedTest)) {
                    const details = {
                        userUuid: store.getters.account.uuid,
                        firstName: activationDetails.activationDetails.firstName,
                        lastName: activationDetails.activationDetails.lastName,
                        email: store.getters.account.email,
                        bioSex:
                            activationDetails.activationDetails.gender === 1
                                ? "Male"
                                : activationDetails.activationDetails.gender === 2
                                ? "Female"
                                : "None",
                        dateOfBirth: activationDetails.activationDetails.dateOfBirth,
                        linkedProfile: activatedTest.barcode,
                        region: user.countryOfBirth.region,
                        sku: activatedTest.product_sku,
                    };
                    const res = await createHealthProfile(details);
                }
                const newProfile = profileList.find(
                    (profile) => profile.profile.barcode === res.data.barcode
                );
                console.log("Found the new profile in the profile list", newProfile);
                await this.$store.dispatch("setDefaultProfile", newProfile.permission.id);
                if (shouldCreateHealthProfile(activatedTest)) {
                    this.$router.push({ name: "create-health-profile-goals" });
                } else {
                    this.$router.push({ name: "activation-family-networks-opt-in" });
                }
            } catch (err) {
                console.log(err);
                alert("There was a problem processing your request");
            }
            this.loading = false;
        },
    },
};
</script>
