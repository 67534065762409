<template>
    <portal to="sidebar">
        <Sidebar>
            <div class="p-6 prose bg-white">
                <h2>Motherline Distribution Map</h2>

                <p>
                    You're viewing your distribution map which shows you where people who share your
                    haplogroup live today.
                </p>
                <p>
                    Your distribution map shows you where in the world today people with your
                    maternal haplogroup are living. The percentage represents the number of people
                    in that region who share your haplotype.
                </p>
                <div class="space-y-1 m-auto">
                    <router-link
                        :to="{ name: 'motherline-distribution-map', params: { region: d.id } }"
                        v-for="d in motherlineStore.result.distribution.map(
                            ({ title, percentage, id }) => ({
                                title,
                                percentage,
                                id,
                            })
                        )"
                        class="flex justify-between no-underline px-4 py-1 rounded"
                        :class="d.id === $route.params.region ? 'bg-gray-100' : 'hover:bg-gray-50'"
                    >
                        <div class="flex items-center space-x-2">
                            <div class="rounded-full bg-rose-500 h-3 w-3 opacity-25" />
                            <div>{{ d.title }}</div>
                        </div>
                        <div class="flex items-center space-x-2">
                            <div>{{ d.percentage }}%</div>
                            <FeatherWrapper icon="chevron-right" class="text-gray-600" />
                        </div>
                    </router-link>
                </div>
            </div>
        </Sidebar>
    </portal>
    <DistributionMap v-if="showMap" :coverage-data="stagedCoverageData" :gender="'motherline'" />
    <MobileContent
        :title="
            $route.params.region
                ? `${activeRegion.title} - ${activeRegion.percentage}%`
                : 'Motherline Distribution Map'
        "
    >
        <div class="prose">
            <p>
                You're viewing your distribution map which shows you where people who share your
                haplogroup live today.
            </p>
            <p>
                Your distribution map shows you where in the world today people with your maternal
                haplogroup are living. The percentage represents the number of people in that region
                who share your haplotype.
            </p>
            <div class="space-y-1 m-auto">
                <router-link
                    :to="{ name: 'motherline-distribution-map', params: { region: d.id } }"
                    v-for="d in motherlineStore.result.distribution.map(
                        ({ title, percentage, id }) => ({
                            title,
                            percentage,
                            id,
                        })
                    )"
                    class="flex justify-between no-underline px-4 py-1 rounded"
                    :class="d.id === $route.params.region ? 'bg-gray-100' : 'hover:bg-gray-50'"
                >
                    <div class="flex items-center space-x-2">
                        <div class="rounded-full bg-rose-500 h-3 w-3 opacity-25" />
                        <div>{{ d.title }}</div>
                    </div>
                    <div class="flex items-center space-x-2">
                        <div>{{ d.percentage }}%</div>
                        <FeatherWrapper icon="chevron-right" class="text-gray-600" />
                    </div>
                </router-link>
            </div>
        </div>
    </MobileContent>
</template>

<script setup>
import { useMotherlineStore } from "@/store/parentalStore";
import Sidebar from "@/components/Sidebar.vue";
import { ref, computed } from "vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import DistributionMap from "@/components/ancestry/DistributionMap.vue";
import MobileContent from "@/components/ancestry/MobileContent.vue";
import { useRoute } from "vue-router";

const showMap = ref(false);

setTimeout(() => {
    showMap.value = true;
}, 500);

const route = useRoute();
const motherlineStore = useMotherlineStore();

const activeRegion = computed(() => {
    return motherlineStore.result.distribution.find((d) => d.id === route.params.region);
});

const stagedCoverageData = {
    type: "geojson",
    data: {
        id: "coverageMapData",
        type: "FeatureCollection",
        features: motherlineStore.result.distribution
            .map(({ geoJson, type, ...properties }) => {
                try {
                    const geoJsonObject = JSON.parse(geoJson);
                    if (!geoJsonObject.geometry) throw new Error("Missing geometry");
                    return {
                        ...geoJsonObject,
                        id: properties.id,
                        properties,
                    };
                } catch (error) {
                    console.error('Error processing entry:', error);
                    return null;  // Skip malformed entries
                }
            })
            .filter(feature => feature !== null), // Filter out null entries
    },
};
</script>
