<template>
    <svg
        class=""
        width="12px"
        height="9px"
        viewBox="0 0 12 9"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Path</title>
        <desc>Created with Sketch.</desc>
        <g id="Store" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
                id="Payment-Modal-Payment-Details-Same-Address"
                transform="translate(-38.000000, -140.000000)"
                fill="#60bf60"
                fill-rule="nonzero"
            >
                <g id="Payment-Capture" transform="translate(32.000000, 76.000000)">
                    <g id="Checkbox" transform="translate(0.000000, 56.000000)">
                        <path
                            d="M6.13846154,12.95 C6.04615385,12.86 6,12.725 6,12.635 C6,12.545 6.04615385,12.41 6.13846154,12.32 L6.78461538,11.69 C6.96923077,11.51 7.24615385,11.51 7.43076923,11.69 L7.47692308,11.735 L10.0153846,14.39 C10.1076923,14.48 10.2461538,14.48 10.3384615,14.39 L16.5230769,8.135 L16.5692308,8.135 L16.5692308,8.135 C16.7538462,7.955 17.0307692,7.955 17.2153846,8.135 L17.8615385,8.765 C18.0461538,8.945 18.0461538,9.215 17.8615385,9.395 L17.8615385,9.395 L10.4769231,16.865 C10.3846154,16.955 10.2923077,17 10.1538462,17 C10.0153846,17 9.92307692,16.955 9.83076923,16.865 L6.23076923,13.085 L6.13846154,12.95 Z"
                            id="Path"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: "product-tick",
};
</script>
