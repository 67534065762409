import { pipe } from "lodash/fp";
import * as turf from "@turf/turf";
import { flattenDepth } from "lodash";
import uuid from "uuid";
import axios from "axios";
import endpoints from "@/endpoints";

// const reversePoint = ([y, x]) => [parseFloat(x), parseFloat(y)];
const reversePoint = ([y, x]) => {
    const parsedX = parseFloat(x);
    const parsedY = parseFloat(y);
    if (isNaN(parsedX) || isNaN(parsedY)) {
        return [0, 0];  // Default coordinates if parsing fails
    }
    return [parsedX, parsedY];
};

const reversePoints = (points) => points.map(reversePoint);

const normalizePoints = (points) => {
    const temp = [points[0]];

    for (let i = 0; i < points.length - 1; i += 1) {
        const [first] = temp[temp.length - 1];
        const second = points[i + 1];

        if (first < second[0]) {
            temp.push(
                second[0] - first < first + 360 - second[0] ? second : [second[0] - 360, second[1]]
            );
        } else {
            temp.push(
                first - second[0] < second[0] + 360 - first ? second : [second[0] + 360, second[1]]
            );
        }
    }

    return temp;
};

const stagePathData = (migrationPaths, colors) => {
    return migrationPaths.map(({ id, title, migrationPath }, index) => {
        try {
            // Parse the JSON string into an object
            const pathData = JSON.parse(migrationPath);

            return pathData
                .filter(({ points }) => points && points.length > 1) // Ensure points exist and have more than one entry
                .map(({ points }) => ({
                    id,
                    title,
                    primaryColor: colors[index].primary,
                    secondaryColor: colors[index].secondary,
                    points: pipe(reversePoints, normalizePoints)(points), // Assuming reversePoints and normalizePoints are defined elsewhere
                }));
        } catch (error) {
            console.error("Error parsing migrationPath or processing data:", error);
            return []; // Return an empty array in case of error to maintain the structure
        }
    }).flat(); // Flatten the array since map returns an array of arrays
};
const addExtraCoords = (arr) => arr.map(({
 id, title, color, points,
}) => {
        const features = turf.bezierSpline(turf.lineString(points), {
            resolution: 10000,
        });

//        console.log(features, points);

        return {
            ...features,
            properties: {
                ...features.properties,
                id,
                title,
                color,
            },
        };
    });

export const convertToFeatures = (migrationPaths, colors) => pipe(
        (paths) => stagePathData(paths, colors),
        (paths) => flattenDepth(paths, 1),
        addExtraCoords,
    )(migrationPaths);
export const stageCircleData = (migrationPaths, colors) => {
    return migrationPaths.reduce((acc, { id, title, migrationPath }, index) => {
        let parsedMigrationPath;
        try {
            // Parse the escaped JSON string
            parsedMigrationPath = JSON.parse(migrationPath);
        } catch (error) {
            console.error('Error parsing migrationPath:', migrationPath, error);
            return acc;  // Skip this item if parsing fails
        }

        // Proceed if parsing was successful
        const features = parsedMigrationPath.map(({ points }) => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: reversePoint(points[points.length - 1]),
            },
            properties: {
                id,
                title,
                primaryColor: colors[index].primary,
                secondaryColor: colors[index].secondary,
            },
        }));

        return [...acc, ...features];
    }, []);
};

export function addUniqueIdsToMapLines(mapData) {
    return mapData.map((feature) => {
        return {
            ...feature,
            uniqueId: uuid(),
        };
    });
}

export const autosomalColors = [
    { primary: "#24408e", secondary: "#81858f" },
    { primary: "#68bc45", secondary: "#b5d3ab" },
    { primary: "#ff6633", secondary: "#ffc2b3" },
    { primary: "#007cba", secondary: "#a9c6d4" },
    { primary: "#ec008b", secondary: "#d8b0c8" },
    { primary: "#00bef2", secondary: "#a8c7d1" },
    { primary: "#c1482d", secondary: "#e6b1a5" },
    { primary: "#24408e", secondary: "#81858f" },
    { primary: "#68bc45", secondary: "#b5d3ab" },
    { primary: "#ff6633", secondary: "#ffc2b3" },
    { primary: "#007cba", secondary: "#a9c6d4" },
    { primary: "#ec008b", secondary: "#d8b0c8" },
    { primary: "#00bef2", secondary: "#a8c7d1" },
    { primary: "#c1482d", secondary: "#e6b1a5" },
    // "#53c1b0",
    // "#9933cc",
    // "#ccff00",
    // "#ff66ff",
    // "#8e6697",
    // "#f05d7b",
    // "#568283",
    // "#24408e",
    // "#68bc45",
    // "#ff6633",
    // "#007cba",
    // "#137744",
    // "#ec008b",
    // "#00bef2",
    // "#c1482d",
    // "#53c1b0",
    // "#9933cc",
    // "#ccff00",
    // "#ff66ff",
    // "#8e6697",
    // "#f05d7b",
    // "#568283",
    // "#a0ab3a",
];

async function getHaplogroupDescription(haplogroupTitle, type) {
    try {
        const { data } = await axios.get(endpoints.haplogroups(type, haplogroupTitle));
        return data;
    } catch (e) {
        console.error(e);
        return "failed";
    }
}

export function getHaplogroupDescriptions(haplogroups, type) {
    const haplogroupRequests = haplogroups.map((haplogroup) =>
        getHaplogroupDescription(haplogroup, type)
    );
    return Promise.all(haplogroupRequests);
}
