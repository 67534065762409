<template>
    <div v-if="closestSample" class="bg-gray-100 p-6 shadow">
        <h4 class="font-normal m-0">
            Closest Sample - <strong>{{ closestSample.properties.id }}</strong>
        </h4>
        <dl class="mt-3 space-y-1">
            <div class="flex gap-4 items-center">
                <dt class="sr-only">Location</dt>
                <FeatherWrapper icon="map" classes="text-gray-500" />
                <dd>{{ closestSample.properties.location }}</dd>
            </div>
            <div class="flex gap-4 items-center">
                <dt class="sr-only">Date</dt>
                <FeatherWrapper icon="calendar" classes="text-gray-500" />
                <dd>{{ closestSample.properties.date }}</dd>
            </div>
        </dl>
        <button v-if="!isZoomed" @click="zoom" class="mt-3 font-semibold text-cobalt-500">
            Zoom on map
        </button>
        <button v-else @click="unzoom" class="mt-3 font-semibold text-cobalt-500">Unzoom</button>
    </div>
</template>

<script setup>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import { ref } from "vue";

defineProps({
    closestSample: Object,
});

const emit = defineEmits(["zoom", "unzoom"]);

const isZoomed = ref(false);

function zoom() {
    isZoomed.value = true;
    emit("zoom");
}

function unzoom() {
    isZoomed.value = false;
    emit("unzoom");
}
</script>
