<template>
    <div>
        <h1 class="font-normal text-2xl">Delete {{ firstName }}'s Account</h1>
        <p>
            A DNA Account consists of your digital results, raw DNA data file, physical residual DNA
            sample (not applicable for uploads), and any personal information you have entered to
            identify this account, such as name and birthdate.
        </p>
        <p>
            By requesting data deletion for {{ firstName }}'s DNA Account, their DNA and its
            surrounding details will be deleted from the Living DNA database in its entirety, both
            physically and digitally.
        </p>
        <router-link
            :to="{ name: 'request-account-deletion' }"
            class="w-full py-2 px-4 mt-6 rounded flex items-center justify-center bg-cobalt-500 hover:bg-cobalt-700 text-white font-semibold"
        >
            <span class="mr-2 text-white"> Proceed with deletion </span>
            <Feather type="chevron-right" class="text-white" />
        </router-link>
        <router-link
            :to="{ name: 'account' }"
            class="text-center text-grey-500 font-semibold mt-4 block"
        >
            Cancel
        </router-link>
        <p class="mt-6 text-center">
            Visit our
            <a
                href="https://support.livingdna.com/hc/en-us/articles/360012499411-Who-do-I-speak-to-about-deleting-my-account-data-"
                target="_blank"
                rel="noopener"
                class="font-semibold"
            >
                Help Center
            </a>
            to learn more about your data deletion options
        </p>
    </div>
</template>

<script>
export default {
    props: {
        account: {
            required: true,
            type: Object,
        },
        firstName: {
            required: true,
            type: String,
        },
    },
};
</script>

<style>
/* a.text-grey-500 {
    @apply text-grey-500;
} */
</style>
