import { ref } from "vue";

export function useLoading(initial = "loading") {
    const loadingStatus = ref(initial);
    const errorMessage = ref("");

    function finishLoading() {
        loadingStatus.value = "loaded";
        return "done";
    }

    function failLoading(message) {
        loadingStatus.value = "failed";
        errorMessage.value = message;
        return "failed";
    }

    function resetLoading() {
        errorMessage.value = "";
        loadingStatus.value = "loading";
    }

    return { loadingStatus, finishLoading, failLoading, resetLoading, errorMessage };
}
