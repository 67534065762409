<template>
    <div class="max-w-xl m-auto">
        <div class="flex items-start p-6 bg-gray-50 rounded -mx-6 shadow">
            <div class="flex flex-col gap-4">
                <h3 class="font-semibold text-gray-500">Regional breakdown</h3>
                <div v-for="region in sharingStore.regions">
                    <div class="flex items-center gap-4">
                        <div
                            class="rounded-full h-5 w-5 shrink-0"
                            :style="{ backgroundColor: region.colour }"
                        />
                        <div class="text-gray-700">
                            {{ region.title }}
                            <span class="text-gray-500 text-sm">({{ region.percentage }}%)</span>
                        </div>
                    </div>
                </div>
            </div>

            <AutosomalDoughnut
                class="ml-auto"
                :region-data="sharingStore.regions"
                style="height: 204px; width: 225px"
            />
        </div>
        <div class="mt-12 flex gap-6">
            <MaternalCard v-if="sharingStore.motherlineResult" class="flex-grow" />
            <PaternalCard v-if="sharingStore.fatherlineResult" class="flex-grow" />
        </div>
    </div>
</template>
<script setup>
import AutosomalDoughnut from "@/components/Dashboard/Ancestry/AutosomalDoughnut.vue";
import { useSharingStore } from "@/store/sharing";
import MaternalCard from "./SharingMaternalCard.vue";
import PaternalCard from "./SharingPaternalCard.vue";

const sharingStore = useSharingStore();
</script>
