<template>
    <div>
        <div class="flex items-center">
            <div class="bg-emerald-50 rounded-full p-1 inline-flex mr-4">
                <Feather type="check" class="fill-current text-emerald-500 m-auto h-4 w-4" />
            </div>
            <h1 class="font-normal text-2xl my-0">Deletion request received</h1>
        </div>
        <p>
            Living DNA will now action your deletion request. You will receive an email when the
            deletion request has been completely fulfilled.
        </p>
        <p>
            Please note that some deletion operations, such as destroying your physical DNA sample
            (if one exists), may take up to three months to complete within compliance of GDPR
            legislation.
        </p>
        <router-link
            :to="{ name: 'profiles' }"
            class="w-full py-2 px-4 mt-6 rounded flex items-center justify-center
            bg-cobalt-500 hover:bg-cobalt-700 text-white font-semibold"
        >
            <Feather type="corner-up-left" class="text-white" />
            <span class="ml-2 text-white">
                Return to Profiles
            </span>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        profile: {
            type: Object,
            required: true,
        },
    },
};
</script>
