<template>
    <AlertMessage :type="errorMessage.type" icon="alert-triangle" class="max-w-xl m-auto my-24">
        <template #title>{{ errorMessage.title }}</template>
        <template #body>
            <div class="prose" v-html="errorMessage.description" />
            <router-link
                v-if="errorMessage.link"
                :to="{ path: errorMessage.link }"
                class="mt-2 text-cobalt-500 font-semibold block"
                >Purchase here</router-link
            >
        </template>
    </AlertMessage>
</template>
<script setup>
import AlertMessage from "@/components/AlertMessage.vue";
import store from "@/store/store";
import { computed } from "vue";

const props = defineProps({
    type: String,
});

const test = store.getters.kitTests[props.type];

const statusDescriptions = {
    cancelled: {
        type: "error",
        title: "Your test has been cancelled",
        description: "Looks like you don't have an deep ancestry test.",
    },
    genotyping: {
        type: "info",
        title: "Your test is currently processing",
        description:
            "Your deep ancestry results are processing. You will receive an email when your results are ready.",
    },
    returned: {
        type: "info",
        title: "Your test is currently processing",
        description:
            "Your deep ancestry results are processing. You will receive an email when your results are ready.",
    },
    shipped: {
        type: "info",
        title: "Your test is currently processing",
        description:
            "Your deep ancestry information results are processing. You will receive an email when your results are ready.",
    },
    testing: {
        type: "info",
        title: "Your test is currently processing",
        description:
            "Your deep ancestry results are processing. You will receive an email when your results are ready.",
    },
    testing_failed: {
        type: "error",
        title: "Your test has failed",
        description:
            "We have encountered an issue in processing your DNA. Please check your email for instruction.",
    },
    no_data: {
        uploader: {
            type: "warning",
            title: "Data unavailable",
            description:
                "DNA upload files do not contain the necessary markers for processing deep ancestry information information. To obtain the full story of your ancestors, purchase the Living DNA deep ancestry test.",
            link: "",
        },
        starter_kit: {
            type: "info",
            title: "Upgrade required",
            description:
                "Unlock the furthest-reaching history of your ancestors with the deep ancestry upgrade. See migration routes, your haplogroup, and where your DNA signature is commonly found today.",
            link: "/store/Deep-Ancestry-Upgrade",
        },
    },
};

const errorMessage = computed(() => {
    if (props.type === "ydna" && store.getters.profile.gender === 2) {
        return {
            title: "Y DNA result not available",
            description: `
                <p>Due to the way the Y Chromosome is passed down, from Male to Male, we are able to provide fatherline Y-DNA haplogroup to anyone who takes our test and is genetically male.</p>
                <p>Genetic females do not carry a Y Chromosome and therefore will not receive their YDNA haplogroup. To discover this information you could consider testing a direct male descendent such as a full brother or paternal uncle.</p>
            `,
            type: "info",
        };
    }
    const message = statusDescriptions[test.slug];
    if (message?.title) {
        return message;
    }
    if (test.slug === "no_data") {
        const isUploader = store.getters.profile.barcode.slice(0, 2) === "LT";
        return isUploader ? message.uploader : message.starter_kit;
    }
    return {
        title: "Failed to load data",
        description: "We have encountered an issue in loading your result",
        type: "error",
    };
});
</script>
