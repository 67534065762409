<template>
    <div class="p-6 bg-grey-50 relative">
        {{ name }}
        <h2 class="font-normal text-base text-grey-700">
            This relationship is expected to have a degree of separation of {{ match.degree }}
        </h2>
        <p class="my-0 text-grey-600">
            {{ match.degree }}{{ ordinal || "th" }} degree relationships share an average of
            {{ averagePercentage }}% ({{ averageSharedCm }} cM) of their DNA
        </p>
        <Space height="2" />
        <img class="hidden lg:block m-auto" :src="desktopDiagram" alt="Your relationship" />
        <img class="lg:hidden m-auto" :src="mobileDiagram" alt="Your relationship" />
        <button
            @click="showModal = true"
            class="absolute bottom-0 right-0 flex items-center font-semibold bg-white shadow p-2 rounded mr-4 mb-4 hover:shadow-hover text-grey-500"
        >
            Click to expand
            <Feather type="external-link" class="h-5 w-5 ml-2" />
        </button>
        <Modal width="6xl" :open="showModal" @state-change="showModal = $event">
            <img class="lg:hidden m-auto" :src="mobileDiagram" alt="Your relationship" />

            <div class="flex flex-col relative h-full">
                <button @click="showModal = false" class="cursor-pointer absolute top-0 right-0">
                    <feather type="x" class="h-5 w-5 fill-current text-grey-500 mr-6 mt-6" />
                </button>
                <div>
                    <div class="px-12">
                        <h1 class="text-2xl font-normal mt-12 flex-0">
                            Relationship prediction for between
                            {{ $store.getters.profile.name }} and {{ match.username || match.name }}
                        </h1>
                        <h2 class="font-normal text-base text-grey-700">
                            This relationship is expected to have a degree of separation of
                            {{ match.degree }}
                        </h2>
                        <p class="my-0 text-grey-600 text-xs">
                            {{ match.degree }}{{ ordinal || "th" }} degree relationships share an
                            average of {{ averagePercentage }}% ({{ averageSharedCm }} cM) of their
                            DNA
                        </p>
                        <Space height="4" />
                    </div>
                    <div class="px-4 py-3 bg-grey-50">
                        <h2 class="text-center text-lg mb-4 font-normal">
                            Here’s how {{ $store.getters.firstName }} might be connected to
                            {{ match.username || match.name }}
                        </h2>
                        <img
                            class="hidden lg:block m-auto"
                            :src="desktopDiagram"
                            alt="Your relationship"
                        />
                    </div>
                    <div class="px-12">
                        <Space height="4" />
                        <p class="my-0 text-xs">
                            The above relationships in green are our most likely predictions based
                            on amount of DNA shared and DNA segment length and location. In
                            relationships with greater degree distance, there is a higher (though
                            still nominal) possibility of a match being slightly closer or further
                            in relationship to you. Descendants of endogamous communities, in
                            particular, are more likely to appear as closer DNA relatives than their
                            true relationship to you, due to the reduced variablity of DNA within
                            the population.
                        </p>
                        <Space height="6" />
                        <div class="flex justify-center">
                            <button
                                @click="showModal = false"
                                class="inline-flex bg-cobalt-500 hover:bg-cobalt-700 px-4 py-3 items-center rounded text-white font-semibold"
                            >
                                <Feather type="corner-up-left" class="h-5 w-5 mr-2" />
                                Return to match profile
                            </button>
                        </div>
                        <Space height="6" />
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import { getRelationshipDetailsFromMatch } from "@/services/relationship";
import Modal from "@/components/Modal.vue";

export default {
    props: {
        match: Object,
    },
    setup() {
        const showModal = ref(false);
        return { showModal };
    },
    components: { Modal },
    computed: {
        ...mapGetters(["profile"]),
        relationship() {
            return getRelationshipDetailsFromMatch(this.match, this.profile);
        },
        desktopDiagram() {
            return this.relationship.desktop;
        },
        mobileDiagram() {
            return this.relationship.mobile;
        },
        averagePercentage() {
            return this.relationship.averagePercentage;
        },
        averageSharedCm() {
            return this.relationship.averageSharedCm;
        },
        ordinal() {
            return this.relationship.ordinal;
        },
        name() {
            return this.relationship.name;
        },
    },
};
</script>
