<template>
    <div class="pb-12">
        <h3 class="text-denim-700 text-2xl mt-12 flex items-center font-normal">
            <feather type="shopping-cart" class="h-5 w-5 mr-4"></feather>
            Offers
        </h3>
        <div class="mt-6 px-3">
            <div class="flex flex-wrap -mx-6 flex">
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <a
                        href="https://livingdna.com/uk/compare"
                        target="_blank"
                        rel="noopener"
                        class="shadow rounded-lg bg-white flex flex-wrap overflow-hidden text-grey-700 hover:shadow-hover"
                    >
                        <div class="sm:w-1/2 p-6 flex flex-col">
                            <h3
                                class="text-sm text-denim-700 font-semibold uppercase tracking-wide"
                            >
                                DNA Kits
                            </h3>
                            <p class="mt-2 mb-8">Check out our offers!</p>
                            <a
                                href="https://livingdna.com/uk/compare"
                                target="_blank"
                                rel="noopener"
                                class="flex items-center mt-auto text-cobalt-500"
                            >
                                <Feather type="shopping-cart" class="h-5 w-5 flex-shrink-0" />
                                <span class="ml-2 font-semibold">Shop now</span>
                            </a>
                        </div>
                        <div
                            class="w-full sm:w-1/2 relative bg-cover bg-right"
                            :style="{ backgroundImage: `url('${imageOne.src}')` }"
                        >
                            <div class="fade-l h-full w-48 absolute"></div>
                            <!--                            <img :src="imageOne.src" alt="Our new hardcover book" class="">-->
                        </div>
                    </a>
                </div>
                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <router-link
                        :to="{ name: 'products' }"
                        class="shadow rounded-lg bg-white flex flex-wrap overflow-hidden text-grey-700 hover:shadow-hover"
                    >
                        <div class="sm:w-1/2 p-6 flex flex-col">
                            <h3
                                class="text-sm text-denim-700 font-semibold uppercase tracking-wide"
                            >
                                Upgrades
                            </h3>
                            <p class="mt-2 mb-8">Visit the store section now</p>
                            <router-link
                                :to="{ name: 'products' }"
                                class="flex items-center mt-auto text-cobalt-500"
                            >
                                <Feather type="shopping-cart" class="h-5 w-5 flex-shrink-0" />
                                <span class="ml-2 font-semibold">Shop upgrades</span>
                            </router-link>
                        </div>
                        <div
                            class="w-full sm:w-1/2 relative bg-cover bg-right"
                            :style="{ backgroundImage: `url('${imageTwo.src}')` }"
                        >
                            <div class="fade-l h-full w-48 absolute"></div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RightImageCard from "@/components/cards/RightImageCard.vue";
import imageOne from "@/assets/images/dashboard/ancestry_wellbeing_kit.png";
import imageTwo from "@/assets/images/dashboard/results-upgrade.png";

export default {
    components: { RightImageCard },
    data() {
        return {
            imageOne: {
                src: imageOne,
            },
            imageTwo: {
                src: imageTwo,
            },
        };
    },
};
</script>
