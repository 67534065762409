<template>
    <div class="pb-12">
        <div
            class=""
            :style="{
                background:
                    'linear-gradient(180deg, rgba(23,53,115,1) 0%, rgba(26,59,128,1) 16%, rgba(60,88,147,1) 35%, rgba(249,249,251,1) 80%, rgba(249,249,251,1) 100%)',
            }"
        >
            <div class="container mx-auto py-12">
                <div class="flex">
                    <div class="px-4" style="min-width: 400px">
                        <div class="flex items-center">
                            <Feather type="heart" class="h-6 w-6 text-white flex-shrink-0" />
                            <h1 class="ml-4 text-2xl font-normal my-12 text-white flex-0">
                                {{ $store.getters.firstName }}'s Supplement Report
                            </h1>
                        </div>
                        <p class="text-white mb-0">
                            Based on your DNA we think you would benefit from adding the following
                            supplements to your diet. Click on any of the supplements discover the
                            benefits of adding it to your diet.
                        </p>
                        <a
                            :href="vitlabUrl"
                            target="_blank"
                            class="inline-flex items-center bg-rose-500 hover:bg-rose-700 text-white py-4 px-6 rounded justify-center mt-6"
                        >
                            <Feather type="truck" class="h-5 w-5 mr-4 text-white flex-shrink-0" />
                            <span class="text-white">Order your custom vitamin</span>
                        </a>
                    </div>
                    <div class="hidden xl:block">
                        <img class="h-auto" :src="vitaminImage" style="max-width: 548px" />
                    </div>
                </div>
            </div>

            <div class="px-4 container mx-auto">
                <VitaminReport
                    v-if="vitaminReport"
                    :vitamin-report="vitaminReport"
                    @supplement-selected="handleSupplementSelected"
                />
            </div>
        </div>

        <div class="px-4 container mx-auto mb-32">
            <div class="max-w-3xl m-auto" v-if="true">
                <h2 class="text-2xl font-normal text-center mb-8 mt-32" id="benefits">
                    Explore the benefits
                </h2>
                <div class="flex relative">
                    <div class="w-80 h-64 mx-auto z-20 relative">
                        <transition-group name="slide-left">
                            <CarouselCard
                                :key="supplement.name"
                                v-for="(supplement, index) in supplements"
                                v-show="index === currentCardIndex"
                            >
                                <div class="flex justify-center mb-3">
                                    <CarouselCardIcon
                                        :icon="supplement.slug"
                                        :color="supplement.color || '#ccc'"
                                    />
                                </div>
                                <CarouselCardTitle>
                                    {{
                                        supplement.name.indexOf(" (") > -1
                                            ? supplement.name.substr(
                                                  0,
                                                  supplement.name.indexOf(" (")
                                              )
                                            : supplement.name
                                    }}
                                </CarouselCardTitle>
                            </CarouselCard>
                        </transition-group>
                        <ChangeFocusButton
                            side="left"
                            @click="
                                currentCardIndex = previousCard(
                                    currentCardIndex,
                                    supplements.length
                                )
                            "
                        />
                        <ChangeFocusButton
                            side="right"
                            @click="
                                currentCardIndex = nextCard(currentCardIndex, supplements.length)
                            "
                        />
                    </div>
                    <div
                        class="bg-white rounded-t-lg p-8 h-32 w-full absolute mx-auto bottom-0 z-10"
                    />
                </div>
                <div class="py-12 mb-24 bg-white rounded-b-lg">
                    <div class="max-w-lg m-auto text-lg">
                        {{ supplements[currentCardIndex].benefit }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="subscriptionData.length === 0"
            class="max-w-4xl rounded flex flex-wrap mx-auto rounded-lg overflow-hidden shadow-key"
        >
            <div class="w-full md:w-1/2 bg-pacific-500 text-white px-4 text-center py-8">
                <h3 class="text-lg px-4 mb-4">
                    Your formula will be <br />
                    between 2-4 capsules.
                </h3>
                <img :src="vitlab" />
                <p>Your capsule is smaller than a paper clip</p>
                <p>
                    This custom supplement comes as capsules that are selected in the most bioactive
                    forms catered to your personal nutrition needs.
                </p>
                <a href="#" class="text-white font-semibold"
                    >Check out the FAQ for further information</a
                >
            </div>
            <div
                v-if="productInformation && vitlabUrl"
                class="w-full md:w-1/2 bg-white flex text-center py-8"
            >
                <div class="my-auto px-8 w-full">
                    <div class="flex text-2xl justify-center mb-4">
                        <div class="">
                            {{ keyToCurrency(productInformation.variants[0].currency) }}
                        </div>
                        <div class="leading-none" style="font-size: 55px">
                            {{ productInformation.variants[0].price.base / 100 }}
                        </div>
                        <div class="mt-auto mb-0">/mo</div>
                    </div>
                    <div class="line-through text-xl text-rose-500 mb-4">
                        {{ keyToCurrency(productInformation.variants[0].currency)
                        }}{{
                            (productInformation.variants[0].price.base / 100 + 10).toFixed(2)
                        }}/month
                    </div>
                    <div class="text-grey-600 mb-4">3 month formula</div>
                    <div class="text-rose-500 mb-10">
                        Compare at
                        {{ keyToCurrency(productInformation.variants[0].currency) }}129/mo+ at your
                        health food store
                    </div>
                    <a
                        :href="vitlabUrl"
                        target="_blank"
                        class="flex items-center bg-rose-500 hover:bg-rose-700 text-white text-xl py-4 px-10 rounded justify-center"
                    >
                        <Feather type="check" class="h-5 w-5 mr-1 text-white" />
                        <span class="text-white">Checkout</span>
                    </a>
                    <div class="flex mt-6">
                        <div class="flex items-center w-1/2 text-left">
                            <Feather
                                type="refresh-cw"
                                class="h-8 w-8 flex-shrink-0 mr-2 text-cobalt-500"
                            />
                            Billed every <br />
                            30 days
                        </div>
                        <div class="flex items-center w-1/2 text-left">
                            <Feather
                                type="package"
                                class="h-8 w-8 flex-shrink-0 mr-2 text-cobalt-500"
                            />
                            3 month supply shipped at once to help the environment
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-else
            class="max-w-xl rounded mx-auto rounded-lg overflow-hidden shadow-key p-6 bg-white"
        >
            Thank you for subscribing to Vitamin Lab. You can visit your dashboard and manage your
            subscription
            <a href="https://livingdna.getvitaminlab.com/account" target="_blank" rel="noopener"
                >here</a
            >.
        </div>

        <div class="max-w-3xl mx-auto px-4 mt-32 mb-16">
            <h4 class="font-normal text-xl">Limitations</h4>
            <p class="mt-4">
                Your DNA results are not diagnostic and do not determine whether you will develop a
                disease or health condition. This test is for informational purposes only. Always
                consult a physician before making major dietary or lifestyle changes that could
                impact your health.
            </p>
            <p class="mt-4">
                DNA is not the only deciding factor in how your body reacts to vitamins, nutrients,
                and exercise. Many lifestyle aspects contribute to the expression of your genes,
                meaning that DNA alone cannot predict whether your genetic predispositions are or
                ever will actively affect you. Factors such as (but not limited to) sleep,
                hydration, air quality, exercise, diet, and stress can impact and change how your
                body responds to the nutrition and fitness traits in your DNA.
            </p>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VitaminReport, { supplements } from "./VitaminReport.vue";
import ChangeFocusButton from "@/components/Wellbeing/AllReports/ChangeFocusButton.vue";
import CarouselCard from "@/components/Wellbeing/CarouselCard.vue";
import CarouselCardTitle from "@/components/Wellbeing/CarouselCardTitle.vue";
import CarouselCardIcon from "@/components/Wellbeing/CarouselCardIcon.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import vitaminImage from "@/assets/images/dashboard/vitaminlab-pouch-b.png";
import supplementsExample from "@/assets/images/wellbeing/supplements-example.jpg";
import supplementsBuyNow from "@/assets/images/wellbeing/supplements-buy-now.jpg";
import vitlab from "@/assets/images/wellbeing/vitlab.png";
import endpoints from "@/endpoints";
import { getVitaminReport, createVitlabUrl } from "@/services/wellbeing/vitlab";

function keyToCurrency(key) {
    const map = {
        USD: "$",
        GBP: "£",
        EUR: "€",
    };
    return map[key] || "$";
}

export default {
    components: {
        FeatherWrapper,
        VitaminReport,
        ChangeFocusButton,
        CarouselCard,
        CarouselCardTitle,
        CarouselCardIcon,
    },
    data() {
        return {
            currentCardIndex: 0,
            supplements,
            vitaminImage,
            supplementsExample,
            supplementsBuyNow,
            vitlab,
            productInformation: undefined,
            vitaminReport: undefined,
            vitlabUrl: undefined,
            keyToCurrency,
            subscriptionData: [],
        };
    },
    async created() {
        this.vitaminReport = await this.getVitaminReport(this.$store.getters.profile.barcode);
        this.productInformation = await this.getProductInformation();
        this.vitlabUrl = this.getVitlabUrl();
        this.subscriptionData = await this.getSubscriptionData(this.$store.getters.profile.barcode);
    },
    methods: {
        handleSupplementSelected(index) {
            this.currentCardIndex = index;
            document.getElementById("benefits").scrollIntoView();
        },
        nextCard(currentCardIndex, goalsCount) {
            if (currentCardIndex === goalsCount - 1) {
                return 0;
            }
            return currentCardIndex + 1;
        },
        previousCard(currentCardIndex, goalsCount) {
            if (currentCardIndex === 0) {
                return goalsCount - 1;
            }
            return currentCardIndex - 1;
        },
        getVitaminReport(barcode) {
            return getVitaminReport(barcode);
        },
        async getProductInformation() {
            try {
                const slug =
                    process.env.NODE_ENV === "production"
                        ? "Personalised-vitamins"
                        : "Vitaminlabs-supplement";
                const { data } = await axios.get(`${endpoints.ecommerce}/product/${slug}`);
                return data;
            } catch (e) {
                console.log("Something went wrong fetching product information", e);
            }
            return {};
        },
        getVitlabUrl() {
            return createVitlabUrl({
                vitlabString: this.vitaminReport.vitaminMix,
                profile: this.$store.getters.profile,
                user: this.$store.getters.accountData,
                variant: this.productInformation.variants[0],
            });
        },
        async getSubscriptionData(barcode) {
            const res = await axios.get(`${endpoints.ecommerce}/product/profile/${barcode}`);
            return res.data;
        },
    },
};
</script>
