<template>
    <Modal :open="showModal" width="xl" remove-padding @close="$emit('cancel')">
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
                <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                    :class="[style.bg50, style.text500]"
                >
                    <FeatherWrapper :icon="style.icon" class="m-auto" aria-hidden="true" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 class="text-base font-semibold leading-6 text-gray-900">
                        <slot name="title" />
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm text-gray-500">
                            <slot name="body" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
                type="button"
                class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                :class="[style.bg500, style.buttonHover, loadingStatus]"
                @click="$emit('confirm')"
            >
                <slot name="button" />
            </button>
            <button
                type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                @click="$emit('cancel')"
                ref="cancelButtonRef"
            >
                Cancel
            </button>
        </div>
    </Modal>
</template>
<script setup>
import Modal from "@/components/Modal.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import { computed } from "vue";

const props = defineProps({
    showModal: Boolean,
    loadingStatus: String,
    type: String,
});

const style = computed(
    () =>
        ({
            info: {
                icon: "alert-triangle",
                bg500: "bg-cobalt-500",
                buttonHover: "hover:bg-cobalt-700",
                bg50: "bg-cobalt-50",
                text500: "text-cobalt-500",
            },
            error: {
                icon: "alert-triangle",
                bg500: "bg-rose-500",
                buttonHover: "hover:bg-rose-700",
                bg50: "bg-rose-50",
                text500: "text-rose-500",
            },
        }[props.type])
);
</script>
