<template>
    <div class="shadow mt-12 rounded">
        <div style="height: 2px">
            <div
                :style="{ width: `${progress}%` }"
                style="height: 2px"
                class="bg-cobalt-500 animate-smooth"
            ></div>
        </div>
        <div class="flex items-stretch justify-between">
            <div class="py-3 px-4">{{ fileName }}</div>
            <!--            <div class="hover:bg-grey-50 flex px-4 cursor-pointer" @click="$emit('cancelled')">-->
            <!--                <vue-feather type="x" class="my-auto fill-current text-grey-500 h-5 w-5" />-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
import VueFeather from "vue-feather";

export default {
    components: { VueFeather },
    props: {
        fileName: {
            required: true,
            type: String,
        },
        progress: {
            type: Number,
        },
    },
    methods: {
        cancelUpload() {
            return true;
        },
    },
};
</script>
