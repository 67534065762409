import { defineStore } from "pinia";
import ProductService from "@/services/product";

// eslint-disable-next-line import/prefer-default-export
export const useProductsStore = defineStore("products", {
    state() {
        return {
            products: [],
        };
    },
    getters: {
        upgrades(state) {
            return state.products
                .filter((p) => p.categories === "Digital upgrade")
                .filter((p) => !p.purchased);
        },
    },
    actions: {
        async getProducts(barcode) {
            const products = await ProductService.getAllProducts(barcode);
            this.products = products.map((p) => ({
                ...p,
                // image: 'https://via.placeholder.com/468x200/00A5B0',
                // locked: p.categories === 'Merchandise',
                highlighted: false,
            }));
        },
    },
});
