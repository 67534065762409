import axios from "axios";
import endpoints from "@/endpoints";
import store from "@/store/store";

const { VUE_APP_PREPR_API_KEY } = process.env;

const ax = axios.create();

export async function useClassicalContent(slug) {
    const fixedUrl = `https://graphql.prepr.io/${VUE_APP_PREPR_API_KEY}`;
    try {
        const res = await ax({
            url: fixedUrl,
            method: "post",
            data: {
                query: `
            {
                ClassicalContentItem (slug: "${slug}") {
                  _id
                  title
                  _slug
                  content {
                   _id
                   heading
                   text
                   main_image {
                    _id
                    url
                   }
                   sub_images {
                    _id
                    url
                   }
                   plain_text
                  }
                 }
            }
      `,
            },
        });
        return res.data.data.ClassicalContentItem.content;
    } catch (e) {
        console.error("Error Details:", e.response ? e.response.data : e.message);
        throw new Error(e);
    }
}

export function getRequiredContent(required) {
    return Promise.all(required.map((t) => useClassicalContent(t)));
}

const colours = {
    roman: "#dc2626",
    greek: "#0284c7",
    egypt: "#b45309",
    egyptian: "#b45309",
};

export function getClassicalColour(type) {
    const colour = colours[type];
    if (!colour) {
        throw new Error(`No matching type: ${type}`);
    }
    return colour;
}

export async function getClassicalResult() {
    try {
        const { data } = await axios.get(endpoints.classicalResult(store.getters.profile.barcode));

        // Check if data is null or not an object
        if (!data || typeof data !== 'object' || !data.results) {
            return null;
        }

        // Try to parse data.results as JSON
        try {
            return JSON.parse(data.results);
        } catch (e) {
            console.error("Failed to parse data.results as JSON:", e);
            return null;
        }
    } catch (e) {
        console.error(e);
        throw new Error(e);
    }
}
