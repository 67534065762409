<template>
    <aside
        class="hidden relative xl:order-first xl:flex xl:flex-col flex-shrink-0 w-128 //border-r border-denim overflow-y-auto shadow-key"
    >
        <!-- Start secondary column (hidden on smaller screens) -->
        <div class="absolute inset-0 z-10">
            <slot />
        </div>
    </aside>
</template>
