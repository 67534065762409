<template>
    <div>
        <div class="prose m-auto">
            <h3>{{ sharingStore.name }}'s Motherline</h3>
            <div class="not-prose">
                <HaplogroupCards
                    class="max-w-full"
                    type="mtdna"
                    border
                    :result="{
                        haplogroup: sharingStore.motherlineResult.haplogroup,
                        subclade: sharingStore.motherlineResult.subclade,
                    }"
                />
            </div>
            <p>
                Mitochondrial or MT DNA is found in the mitochondria cells. It contains 16,500 DNA
                bases, meaning it is much smaller than the autosomal genome (3 billion) or the Y
                chromosome (50,000). You will see this if you choose to download your raw data.
            </p>

            <p>
                Mitochondrial DNA is passed directly to you by your mother, and to her by her mother
                and so on without undergoing any changes (recombination). This means your MT DNA
                information is identical to your mother, because of this we can tell you about your
                ancestral information from your mother.
            </p>
        </div>
        <SharingMotherlineMap class="hidden md:block mt-16" />
    </div>
</template>
<script setup>
import HaplogroupCards from "@/components/ancestry/HaplogroupCards.vue";
import SharingMotherlineMap from "@/views/sharing/SharingMotherlineMap.vue";
import { useSharingStore } from "@/store/sharing";

const sharingStore = useSharingStore();
</script>
