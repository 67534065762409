<template>
    <Card class="bg-white flex-1 flex flex-col">
        Not purchased
    </Card>
</template>

<script>
import Card from "@/components/cards/Card.vue";

export default {
    components: { Card },
};
</script>
