import Wellbeing from "@/views/wellbeing/Wellbeing.vue";
import Supplements from "@/views/wellbeing/Supplements/Supplements.vue";
import AllReports from "@/views/wellbeing/AllReports.vue";
import IndividualReport from "@/views/wellbeing/IndividualReport.vue";
import IndividualReportScience from "@/views/wellbeing/IndividualReportScience.vue";

export default [
    {
        path: "/wellbeing",
        component: Wellbeing,
        children: [
            {
                path: "",
                name: "wellbeing",
                component: AllReports,
                meta: {
                    scrollTopTo: 0,
                },
            },
            {
                path: "supplements",
                name: "supplements",
                component: Supplements,
                meta: {
                    scrollTopTo: 0,
                },
            },
            {
                path: ":report",
                name: "wellbeing-report",
                component: IndividualReport,
                meta: {
                    scrollTopTo: 0,
                },
            },
            {
                path: "/wellbeing/:report/science",
                name: "wellbeing-report-science",
                component: IndividualReportScience,
                meta: {
                    scrollTopTo: 0,
                },
            },
        ],
    },
];
