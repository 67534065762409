<template>
    <button @click="show = true" class="text-sm text-cobalt-500 font-semibold">
        Bulk add to matchbox
    </button>
    <Modal :open="show" width="lg" remove-padding @closed="show = false">
        <div class="space-y-6 p-6">
            <fieldset>
                <legend class="text-base font-semibold text-gray-900">
                    Bulk add shared matches to a matchgroup
                </legend>
                <div class="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                    <div
                        v-for="(group, sideIdx) in matchGroupStore.matchGroups"
                        :key="sideIdx"
                        class="relative flex items-start py-4"
                    >
                        <div class="min-w-0 flex-1 text-sm leading-6">
                            <label
                                :for="`side-${group.id}`"
                                class="select-none font-medium text-gray-900 flex items-center gap-2"
                            >
                                <span
                                    class="h-4 w-4 rounded-full"
                                    :style="{ backgroundColor: group.colour }"
                                />
                                <span>{{ group.name }}</span>
                            </label>
                        </div>
                        <div class="ml-3 flex h-6 items-center">
                            <input
                                v-model="selectedMatchbox"
                                :id="`side-${group.id}`"
                                name="plan"
                                type="radio"
                                :value="group.id"
                                class="h-4 w-4 border-gray-300 text-cobalt-500 focus:ring-cobalt-500"
                            />
                        </div>
                    </div>
                </div>
            </fieldset>
            <div v-if="sharedMatchesCount > 50" class="bg-cobalt-50 text-cobalt-500 text-sm p-4">
                Please note that that adding too many shared matches to a group may affect
                performance. If you notice things loading slowly this may be the cause
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
                type="button"
                class="inline-flex w-full justify-center rounded-md bg-cobalt-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cobalt-700 sm:ml-3 sm:w-auto"
                @click="open = false"
            >
                Add {{ totalCount }} matches to group
            </button>
            <button
                type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                @click="show = false"
            >
                Cancel
            </button>
        </div>
        {{ selectedMatchbox }}
    </Modal>
</template>
<script setup>
import Modal from "@/components/Modal.vue";
import { useMatchGroupStore } from "@/store/matchGroups";
import { ref } from "vue";
import { totalCount } from "../composables/useSharedMatches";

defineProps({
    show: Boolean,
    sharedMatchesCount: Number,
});

const matchGroupStore = useMatchGroupStore();
matchGroupStore.getMatchGroups();

const selectedMatchbox = ref({});
const method = ref("all");
</script>
