<template>
    <div v-if="false">
        {{ loadingStatus }}
        {{ $store.getters.kitTests.viking }}
    </div>
    <div class="h-full w-full bg-white">
        <template v-if="loadingStatus === 'loaded'">
            <portal to="navigation">
                <VikingsNavigation />
            </portal>
            <router-view v-if="vikingResult.closestPop" :viking-result="vikingResult" />
        </template>
        <VikingStatus v-else :status="loadingStatus" />
    </div>
</template>

<script>
import VikingsNavigation from "@/components/Vikings/VikingsNavigation.vue";
import { ref } from "vue";
import store from "@/store/store";
import { getVikingResult, getVikingPopulationGroup } from "@/services/vikings";
import VikingStatus from "./VikingStatus.vue";
import PageLoader from "@/components/PageLoader.vue";

// to handle
const actions = {
    completed: (loadingStatus, vikingResult) => {
        getVikingResult(store.getters.profile.barcode)
            .then((res) => (vikingResult.value = res))
            .then(() => (loadingStatus.value = "loaded"))
            .catch((e) => {
                console.error(e);
                loadingStatus.value = "loadingFailed";
            });
        loadingStatus.value = "loaded";
    },
    updating: (loadingStatus) => {
        loadingStatus.value = "updating";
    },
    error: (loadingStatus) => {
        loadingStatus.value = "testErrored";
    },
    failed: (loadingStatus) => {
        loadingStatus.value = "testFailed";
    },
    pending: (loadingStatus) => {
        loadingStatus.value = "resultPending";
    },
    noData: (loadingStatus) => {
        loadingStatus.value = "testNoData";
    },
    processing: (loadingStatus) => {
        loadingStatus.value = "resultPending";
    },
};

export default {
    components: { VikingsNavigation, VikingStatus, PageLoader },
    setup() {
        const loadingStatus = ref("loading");
        const vikingResult = ref({});
        if (false) {
            loadingStatus.value = "transferQcFailed";
        } else {
            if (store.getters.kitTests && store.getters.kitTests.viking) {
                if (!store.getters.kitTests.viking.purchased) {
                    loadingStatus.value = "notPurchased";
                } else if (actions[store.getters.kitTests.viking.status]) {
                    const action = actions[store.getters.kitTests.viking.status];
                    action(loadingStatus, vikingResult);
                }
            } else {
                loadingStatus.value = "failed";
            }
        }

        // store.subscribeAction({
        //     after: async ({ type }) => {
        //         switch (type) {
        //             case "SET_KIT_TESTS":
        //                 if (store.getters.kitTests && store.getters.kitTests.viking) {
        //                     if (!store.getters.kitTests.viking.purchased) {
        //                         loadingStatus.value = "notPurchased";
        //                     } else if (actions[store.getters.kitTests.viking.status]) {
        //                         const action = actions[store.getters.kitTests.viking.status];
        //                         action(loadingStatus, vikingResult);
        //                     }
        //                 } else {
        //                     loadingStatus.value = "failed";
        //                 }
        //         }
        //     },
        // });

        return { loadingStatus, vikingResult };
    },
};
</script>
