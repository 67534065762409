<template>
    <svg
        id="Capa_1"
        enable-background="new 0 0 510.947 510.947"
        viewBox="0 0 510.947 510.947"
        width="512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Lines_46_">
            <path
                d="m274.259 145.235c0-4.339-.245-8.622-.707-12.84h34.326v-15h-36.97c-5.273-21.55-16.523-40.771-31.884-55.798l35.503-40.482-11.277-9.891-35.592 40.583c-19.547-14.765-43.851-23.531-70.163-23.531-24.282 0-46.855 7.466-65.556 20.223l-35.522-40.503-11.277 9.891 34.957 39.859c-16.669 14.811-29.089 34.312-35.177 56.421h-33.131v15h30.049c-.724 5.255-1.107 10.617-1.107 16.068v56.659h-40.731v15h40.73v81.274h-28.941v15h28.941v29.685c0 15.191 2.909 29.715 8.194 43.043h-48.924v15h56.151c7.741 13.508 18.099 25.324 30.372 34.763l-31.132 29.606 10.337 10.869 33.563-31.918c15.105 8.736 32.335 14.174 50.704 15.347v50.56h15v-50.56c18.692-1.193 36.201-6.809 51.495-15.815l37.45 35.615 10.337-10.869-35.101-33.38c10.98-8.579 20.393-19.078 27.737-30.989h62.754v-15h-54.943c6.13-14.199 9.536-29.844 9.536-46.271v-26.457h33.619v-15h-33.619v-15.819h-15v57.276c0 56.22-45.651 101.958-101.765 101.958-56.113 0-101.764-45.738-101.764-101.958v-197.619c0-56.22 45.651-101.958 101.764-101.958s101.765 45.738 101.765 101.958v89.14h15v-14.252h45.407v-15h-45.407v-59.888z"
            />
            <path
                d="m177.471 79.545c-28.874 0-52.364 23.526-52.364 52.443s23.49 52.443 52.364 52.443 52.364-23.526 52.364-52.443-23.49-52.443-52.364-52.443zm0 89.886c-20.603 0-37.364-16.797-37.364-37.443s16.761-37.443 37.364-37.443 37.364 16.797 37.364 37.443-16.761 37.443-37.364 37.443z"
            />
            <path
                d="m140.107 251.945c0-14.647-11.901-26.563-26.53-26.563-14.628 0-26.53 11.916-26.53 26.563s11.901 26.564 26.53 26.564c14.629.001 26.53-11.916 26.53-26.564zm-38.06 0c0-6.376 5.172-11.563 11.53-11.563s11.53 5.188 11.53 11.563c0 6.377-5.172 11.564-11.53 11.564-6.357.001-11.53-5.187-11.53-11.564z"
            />
            <path
                d="m177.471 388.562c21.49 0 38.974-17.509 38.974-39.03s-17.483-39.029-38.974-39.029-38.974 17.509-38.974 39.029c.001 21.522 17.484 39.03 38.974 39.03zm0-63.059c13.219 0 23.974 10.779 23.974 24.029s-10.754 24.03-23.974 24.03-23.974-10.78-23.974-24.03 10.755-24.029 23.974-24.029z"
            />
            <path d="m209.963 205.123h15v15h-15z" />
            <path d="m209.963 240.096h15v15h-15z" />
            <path d="m91.732 305.055h15v15h-15z" />
            <path d="m91.732 340.028h15v15h-15z" />
            <path
                d="m259.259 251.945h15v17.067h-15z"
                transform="matrix(1 -.003 .003 1 -.863 .887)"
            />
            <path
                d="m421.881 213.601c4.001-.001 8.06-.348 12.133-1.066 18.261-3.219 34.212-13.41 44.914-28.695 10.703-15.285 14.824-33.759 11.604-52.021l-2.639-14.962-14.771 2.605 2.639 14.962c2.524 14.314-.715 28.809-9.119 40.812s-20.917 20.003-35.232 22.527c-29.698 5.233-58.104-14.661-63.339-44.351l-12.944-73.411c-5.234-29.691 14.661-58.104 44.352-63.34 29.694-5.229 58.104 14.66 63.339 44.351l2.405 13.641 14.771-2.605-2.405-13.641c-6.672-37.836-42.884-63.192-80.715-56.517-18.261 3.219-34.212 13.41-44.914 28.695-10.703 15.285-14.824 33.76-11.604 52.021l12.944 73.412c5.951 33.762 35.42 57.584 68.581 57.583z"
            />
            <path
                d="m467.826 89.426.043.245 2.604 14.773.001-.001v.001l14.772-2.605-2.605-14.772-.044-.247z"
            />
            <path
                d="m395.427 113.29h15v15h-15z"
                transform="matrix(.985 -.174 .174 .985 -14.856 71.822)"
            />
            <path
                d="m424.973 108.081h14.999v15h-14.999z"
                transform="matrix(.985 -.174 .174 .985 -13.5 76.85)"
            />
            <path
                d="m405.077 81.634h15v14.999h-15z"
                transform="matrix(.985 -.174 .174 .985 -9.21 72.989)"
            />
            <path
                d="m465.098 292.843c-36.104-13.14-76.164 5.541-89.305 41.643l-25.496 70.05c-6.342 17.425-5.491 36.334 2.395 53.245 7.887 16.911 21.825 29.717 39.249 36.059 7.72 2.81 15.729 4.208 23.729 4.208 10.057 0 20.097-2.21 29.516-6.603 16.911-7.886 29.717-21.824 36.059-39.248l5.197-14.277-14.096-5.131-5.197 14.277c-4.971 13.659-15.022 24.592-28.303 30.784-13.278 6.193-28.115 6.865-41.774 1.894s-24.593-15.022-30.785-28.303-6.865-28.116-1.894-41.774l25.496-70.05c10.311-28.329 41.747-42.989 70.078-32.678 28.33 10.312 42.989 41.749 32.678 70.078l-4.737 13.016 14.096 5.131 4.737-13.016c13.139-36.103-5.541-76.165-41.643-89.305z"
            />
            <path d="m482.692 404.363v-.001l-5.13 14.096 14.096 5.131 5.216-14.33-14.096-5.131z" />
            <path
                d="m403.392 391.341h15v15h-15z"
                transform="matrix(.342 -.94 .94 .342 -104.406 648.591)"
            />
            <path
                d="m431.583 401.602h15v14.999h-15z"
                transform="matrix(.342 -.94 .94 .342 -95.519 681.79)"
            />
            <path
                d="m427.578 368.75h15v15h-15z"
                transform="matrix(.342 -.94 .94 .342 -67.278 656.421)"
            />
        </g>
    </svg>
</template>
