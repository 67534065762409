<template>
    <ThePage class="max-w-6xl">
        <h1 class="text-2xl font-normal mt-4 mb-6">
            <Feather type="users" class="h-5 w-5 mr-2" />
            {{ $store.getters.firstName }}'s Multiview Browser
        </h1>
        <template v-if="loadingStatus === 'not-allowed'">
            <div class="relative">
                <div class="cover-image" />

                <div
                    class="flex flex-col items-start max-w-md m-auto gap-4 bg-white p-6 shadow-key rounded relative z-10 my-16 //border-t-4 border-cobalt-500"
                >
                    <div class="bg-cobalt-50 p-4 inline-block rounded-full">
                        <Feather type="lock" class="text-cobalt-500 block" />
                    </div>

                    <div>
                        <h2 class="text-lg font-semibold m-0 pb-2">Upgrade required</h2>
                        <div class="flex flex-col gap-4">
                            <div>
                                This tool can be used to establish how you and your match are
                                related. You will be able to see where and on which Chromosomes you
                                share DNA with your matches and which you do not.
                            </div>

                            <div>
                                Buy our autosomal upgrade to get access to chromosome browser, your
                                ancestry breakdown including maps, and the ability to share those
                                maps in family networks.
                            </div>
                        </div>
                        <router-link
                            :to="{ name: 'product', params: { slug: 'autosomal-upgrade' } }"
                            class="text-cobalt-500 font-semibold flex items-center mt-4"
                        >
                            <feather-wrapper icon="zap" class="mr-2" />
                            Find out more
                        </router-link>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <MatchboxSelector v-model="selectedMatchGroup" class="mb-4" />
            <div v-if="loadingStatus === 'loading'" class="mt-12">
                <PageLoader> Loading </PageLoader>
            </div>
            <div v-else-if="decoratedGroupMemberList.length">
                <FilterToggle class="mb-4" />
                <!-- <BrowserViewModeSelector v-model="viewMode" class="mb-4" /> -->

                <template v-if="viewMode === 'browser'">
                    <Legend />
                    <div class="space-y-8 browser">
                        <div v-for="chromosome in chromosomes" class="flex space-x-6">
                            <div>{{ chromosome.CHROM }}</div>
                            <div class="w-full flex flex-col gap-4">
                                <div v-for="member in decoratedGroupMemberList" class="flex gap-4">
                                    <div class="w-48 shrink-0 truncate">
                                        <router-link
                                            :to="{
                                                name: 'chromosome-browser',
                                                params: { barcode: member.barcode },
                                            }"
                                            class="underline font-semibold text-sm"
                                        >
                                            {{ member.username || member.name }}
                                        </router-link>
                                    </div>
                                    <div
                                        class="bg-grey-300 h-6 rounded-full relative overflow-hidden"
                                        :style="{
                                            width: `${
                                                (Number(chromosome.CM_LENGTH) /
                                                    lengthOfLongestChromosome) *
                                                100
                                            }%`,
                                        }"
                                    >
                                        <div
                                            v-for="result in getResultsForChromosome(
                                                member,
                                                chromosome
                                            )"
                                            class="h-full flex absolute bg-denim-700 hover:bg-cobalt-700"
                                            :style="{
                                                left: `${
                                                    (Number(result.CM_START) /
                                                        Number(chromosome.CM_LENGTH)) *
                                                    100
                                                }%`,
                                                width: `${
                                                    ((Number(result.CM_STOP) -
                                                        Number(result.CM_START)) /
                                                        Number(chromosome.CM_LENGTH)) *
                                                    100
                                                }%`,
                                            }"
                                        >
                                            <tippy trigger="click">
                                                <button class="h-full w-full"></button>
                                                <template #content>
                                                    <div class="bg-white shadow-md rounded p-6">
                                                        <div>
                                                            Segment length:
                                                            <strong
                                                                >{{
                                                                    (
                                                                        Number(result.CM_STOP) -
                                                                        Number(result.CM_START)
                                                                    ).toFixed(3)
                                                                }}
                                                                cM</strong
                                                            >
                                                        </div>
                                                        <div>
                                                            Start position:
                                                            <strong
                                                                >chr{{ result.CHROM }}:
                                                                {{
                                                                    Number(
                                                                        result.BP_START
                                                                    ).toLocaleString("en-GB")
                                                                }}</strong
                                                            >
                                                        </div>
                                                        <div>
                                                            End position:
                                                            <strong
                                                                >chr{{ result.CHROM }}:
                                                                {{
                                                                    Number(
                                                                        result.BP_STOP
                                                                    ).toLocaleString("en-GB")
                                                                }}</strong
                                                            >
                                                        </div>
                                                    </div>
                                                </template>
                                            </tippy>
                                        </div>
                                        <div
                                            v-for="area in getCommonAreasForChromosome(chromosome)"
                                            :key="area.CM_START"
                                            class="h-full flex absolute stripes opacity-50 pointer-events-none"
                                            :style="{
                                                left: `${
                                                    (Number(area.CM_START) /
                                                        Number(chromosome.CM_LENGTH)) *
                                                    100
                                                }%`,
                                                width: `${
                                                    ((Number(area.CM_STOP) -
                                                        Number(area.CM_START)) /
                                                        Number(chromosome.CM_LENGTH)) *
                                                    100
                                                }%`,
                                            }"
                                        />
                                        <Centromere :chromosome="chromosome" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else-if="viewMode === 'table'">
                    <div class="">
                        <div class="">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            class="sm:hidden pb-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                        >
                                            Segments
                                        </th>
                                        <th
                                            scope="col"
                                            class="hidden sm:table-cell pb-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                        >
                                            Chromosome
                                        </th>

                                        <th
                                            scope="col"
                                            class="hidden sm:table-cell pb-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                        >
                                            Username
                                        </th>

                                        <th
                                            scope="col"
                                            class="hidden px-3 pb-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                        >
                                            Segment length (cM)
                                        </th>
                                        <th
                                            scope="col"
                                            class="hidden px-3 pb-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                        >
                                            Start position
                                        </th>
                                        <th
                                            scope="col"
                                            class="hidden px-3 pb-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                        >
                                            End position
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for="result in dataFormattedForTable">
                                        <td
                                            class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0"
                                        >
                                            <span class="hidden sm:block">{{ result.CHROM }}</span>
                                            <dl class="font-normal sm:hidden">
                                                <dt class="mt-2">Chromosome</dt>
                                                <dd class="mt-1 truncate text-gray-700">
                                                    {{ result.CHROM }}
                                                </dd>
                                                <dd class="mt-1 truncate text-gray-700">
                                                    {{ result.username }}
                                                </dd>
                                                <dt class="mt-2">Segment length</dt>
                                                <dd class="mt-1 truncate text-gray-700">
                                                    {{ result.CM_LENGTH }}
                                                </dd>
                                                <dt class="mt-2">Start position</dt>
                                                <dd class="mt-1 truncate text-gray-500">
                                                    {{
                                                        Number(result.BP_START).toLocaleString(
                                                            "en-GB"
                                                        )
                                                    }}
                                                </dd>
                                                <dt class="mt-2">End position</dt>
                                                <dd class="mt-1 truncate text-gray-500">
                                                    {{
                                                        Number(result.BP_STOP).toLocaleString(
                                                            "en-GB"
                                                        )
                                                    }}
                                                </dd>
                                            </dl>
                                        </td>
                                        <td
                                            class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                                        >
                                            {{ result.username }}
                                        </td>
                                        <td
                                            class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                                        >
                                            {{ result.CM_LENGTH }}
                                        </td>
                                        <td
                                            class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                                        >
                                            {{ Number(result.BP_START).toLocaleString("en-GB") }}
                                        </td>
                                        <td
                                            class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"
                                        >
                                            {{ Number(result.BP_STOP).toLocaleString("en-GB") }}
                                        </td>
                                    </tr>

                                    <!-- More people... -->
                                </tbody>
                            </table>
                        </div>
                    </div></template
                >
            </div>
            <div v-else>
                <AlertMessage
                    v-if="!matchgroupStore.matchGroups.length"
                    class="mt-8 m-auto max-w-md"
                    type="warning"
                    icon="users"
                >
                    <template #title> Your matchbox is empty </template>
                    <template #body>
                        <div class="flex flex-col gap-4">
                            <div>Create your first group to use multiview chromosome browser</div>
                        </div>
                        <router-link
                            :to="{
                                name: 'matchbox',
                            }"
                            class="text-cobalt-500 font-semibold flex items-center mt-4"
                        >
                            <feather type="users" class="mr-2 h-5 w-5" />
                            Get started here
                        </router-link>
                    </template>
                </AlertMessage>
                <AlertMessage v-else class="mt-8 m-auto max-w-md" type="warning" icon="users">
                    <template #title> This matchbox group is empty </template>
                    <template #body>
                        <div class="flex flex-col gap-4">
                            <div>
                                Add people to it to compare them on our multiview browser. You can
                                add people to a matchbox group from your relatives list.
                            </div>
                        </div>
                        <router-link
                            :to="{
                                name: 'list-view',
                            }"
                            class="text-cobalt-500 font-semibold flex items-center mt-4"
                        >
                            <feather type="users" class="mr-2 h-5 w-5" />
                            View relatives list
                        </router-link>
                    </template>
                </AlertMessage>
            </div>
        </template>
    </ThePage>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useMatchGroupStore } from "@/store/matchGroups";
import axios from "axios";
import endpoints from "@/endpoints";
import Centromere from "@/components/ChromosomeBrowser/Centromere.vue";
import ThePage from "@/components/Base/ThePage.vue";
import { Tippy } from "vue-tippy";
import {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";
import Legend from "@/components/ChromosomeBrowser/Legend.vue";
import { useFilterStore } from "@/store/chromosomeBrowser";
import FilterToggle from "@/components/ChromosomeBrowser/FilterToggle.vue";
import BrowserViewModeSelector from "./BrowserViewModeSelector.vue";
import store from "@/store/store";
import AlertMessage from "@/components/AlertMessage.vue";
import MatchboxSelector from "@/components/MatchboxSelector.vue";

const matchgroupStore = useMatchGroupStore();
const filterStore = useFilterStore();

const loadingStatus = ref("loading");
const selectedMatchGroup = ref({});
const decoratedGroupMemberList = ref([]);
const viewMode = ref("browser");

matchgroupStore.$subscribe((mutation, state) => {
    if (!matchgroupStore.hasChromosomeBrowserAccess) {
        loadingStatus.value = "not-allowed";
    } else {
        watch(selectedMatchGroup, async (value) => {
            loadingStatus.value = "loading";
            if (!value || !value.membersAsMatches) {
                decoratedGroupMemberList.value = [];
                loadingStatus.value = "loaded";
            } else {
                decoratedGroupMemberList.value = await Promise.all(
                    value.membersAsMatches.map(async (member) => {
                        const res = await axios.get(endpoints.sharedSegments(member.rel_id));
                        return {
                            ...member,
                            sharedSegments: res.data.map((result) => ({
                                ...result,
                                CM_LENGTH: result.CM_STOP - result.CM_START,
                            })),
                        };
                    })
                );
                loadingStatus.value = "loaded";
            }
        });
    }
});

matchgroupStore.getMatchGroups().then(() => {
    selectedMatchGroup.value = matchgroupStore.matchGroups[0];
});

function getResultsForChromosome(member, chromosome) {
    return member.sharedSegments
        .filter((result) => result.CHROM === chromosome.CHROM)
        .filter((result) => {
            if (!filterStore.shouldFilterResults) {
                return result;
            }
            return result.CM_LENGTH >= filterStore.filterValue;
        });
}

const commonAreas = [
    {
        CHROM: 1,
        CM_START: "150.314",
        CM_STOP: "168.046",
    },
    {
        CHROM: 2,
        CM_START: "114.780",
        CM_STOP: "121.685",
    },
    {
        CHROM: 2,
        CM_START: "153.600",
        CM_STOP: "165.310",
    },
    {
        CHROM: 2,
        CM_START: "207.605",
        CM_STOP: "212.226",
    },
    {
        CHROM: 8,
        CM_START: "18.806",
        CM_STOP: "24.292",
    },
    {
        CHROM: 8,
        CM_START: "124.666",
        CM_STOP: "159.178",
    },
    {
        CHROM: 9,
        CM_START: "63.796",
        CM_STOP: "83.174",
    },
    {
        CHROM: 10,
        CM_START: "67.098",
        CM_STOP: "74.141",
    },
    {
        CHROM: 14,
        CM_START: "0.001",
        CM_STOP: "4.034",
    },
    {
        CHROM: 15,
        CM_START: "0.001",
        CM_STOP: "31.947",
    },
    {
        CHROM: 15,
        CM_START: "37.912",
        CM_STOP: "44.024",
    },
    {
        CHROM: 16,
        CM_START: "38.705",
        CM_STOP: "44.576",
    },
    {
        CHROM: 16,
        CM_START: "127.017",
        CM_STOP: "130.981",
    },
    {
        CHROM: 17,
        CM_START: "87.203",
        CM_STOP: "93.128",
    },
    {
        CHROM: 17,
        CM_START: "119.400",
        CM_STOP: "124.781",
    },
    {
        CHROM: 22,
        CM_START: "0.001",
        CM_STOP: "23.021",
    },
];

function getCommonAreasForChromosome(chromosome) {
    return commonAreas.filter((area) => area.CHROM === chromosome.CHROM);
}

const chromosomes = [
    {
        CHROM: 1,
        CM_LENGTH: 293.397,
        CM_POSITION: 150.8336,
    },
    {
        CHROM: 2,
        CM_LENGTH: 274.87663,
        CM_POSITION: 118.3734,
    },
    {
        CHROM: 3,
        CM_LENGTH: 227.8292,
        CM_POSITION: 109.1464,
    },
    {
        CHROM: 4,
        CM_LENGTH: 220.01794,
        CM_POSITION: 69.7887,
    },
    {
        CHROM: 5,
        CM_LENGTH: 208.95622,
        CM_POSITION: 60.9361,
    },
    {
        CHROM: 6,
        CM_LENGTH: 198.25632,
        CM_POSITION: 78.3466,
    },
    {
        CHROM: 7,
        CM_LENGTH: 190.56406,
        CM_POSITION: 80.8752,
    },
    {
        CHROM: 8,
        CM_LENGTH: 178.1536,
        CM_POSITION: 69.485,
    },
    {
        CHROM: 9,
        CM_LENGTH: 180.46379,
        CM_POSITION: 64.3246,
    },
    {
        CHROM: 10,
        CM_LENGTH: 183.11295,
        CM_POSITION: 62.5184,
    },
    {
        CHROM: 11,
        CM_LENGTH: 161.85604,
        CM_POSITION: 65.4944,
    },
    {
        CHROM: 12,
        CM_LENGTH: 175.13532,
        CM_POSITION: 55.4598,
    },
    {
        CHROM: 13,
        CM_LENGTH: 129.79234,
        CM_POSITION: 0.0001,
    },
    {
        CHROM: 14,
        CM_LENGTH: 116.76934,
        CM_POSITION: 0.0001,
    },
    {
        CHROM: 15,
        CM_LENGTH: 150.84068,
        CM_POSITION: 0.0001,
    },
    {
        CHROM: 16,
        CM_LENGTH: 131.13381,
        CM_POSITION: 58.4528,
    },
    {
        CHROM: 17,
        CM_LENGTH: 128.53434,
        CM_POSITION: 46.5217,
    },
    {
        CHROM: 18,
        CM_LENGTH: 120.14257,
        CM_POSITION: 40.1999,
    },
    {
        CHROM: 19,
        CM_LENGTH: 106.85774,
        CM_POSITION: 49.2801,
    },
    {
        CHROM: 20,
        CM_LENGTH: 110.2054,
        CM_POSITION: 50.987,
    },
    {
        CHROM: 21,
        CM_LENGTH: 64.65146,
        CM_POSITION: 0.5841,
    },
    {
        CHROM: 22,
        CM_LENGTH: 75.11309,
        CM_POSITION: 0.0001,
    },
];
const lengthOfLongestChromosome = Number(chromosomes[0].CM_LENGTH);

const dataFormattedForTable = computed(() => {
    return decoratedGroupMemberList.value.flatMap((member) => {
        return member.sharedSegments.map((segment) => ({
            username: member.username,
            ...segment,
        }));
    });
});
</script>
