<template>
    <div>
        <div v-if="loadStatus === 'loading'">
            <PageLoader class="mt-12">Loading your result</PageLoader>
        </div>
        <div class="bg-grey-50" v-else-if="loadStatus === 'loaded'">
            <div class="rounded bg-grey-100 text-center py-24 px-6">
                <h1 class="text-4xl mb-12 text-denim-700 font-semibold">
                    Your viking index is {{ vikingResult.percentile }}%
                </h1>
                <DoughnutChart :chart-data="chartData" />
                <div class="mt-16 text-2xl font-semibold text-denim-700">
                    You are most closely associated with the
                    {{ vikingResult.poulationGroupCmsInformation.title }}
                </div>
                <div class="flex">
                    <TextLinkRight
                        icon="arrow-right"
                        class="mt-6 block text-xl font-semibold mx-auto"
                        :to="{ name: 'viking-map' }"
                        text="Click here to view a map and more information"
                    />
                </div>
            </div>
            <div class="m-auto max-w-4xl px-4 py-12 prose">
                <h2 class="text-xl">{{ understanding.title }}</h2>
                <div v-html="understanding.text"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { getVikingUnderstanding } from "@/services/vikings";
import DoughnutChart from "@/components/charts/Doughnut.vue";
import PageLoader from "@/components/PageLoader.vue";

export default {
    props: {
        vikingResult: Object,
    },
    setup() {
        const loadStatus = ref("loading");
        const understanding = ref({});
        onMounted(async () => {
            understanding.value = await getVikingUnderstanding();
            loadStatus.value = "loaded";
        });
        return { loadStatus, understanding };
    },
    computed: {
        chartData() {
            return {
                datasets: [
                    {
                        data: [this.vikingResult.percentile, 100 - this.vikingResult.percentile],
                        backgroundColor: ["#173775", "#ddd"],
                    },
                ],
                labels: [],
            };
        },
    },
    components: { DoughnutChart, PageLoader },
};
</script>
