<template>
    <PageLoader v-if="loadingStatus === 'loading'" class="mt-12"> Loading </PageLoader>
    <template v-else-if="loadingStatus === 'loaded'">
        <div class="pt-8">
            <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div
                    class="relative isolate overflow-hidden bg-gray-300 px-6 py-24 text-center shadow-2xl sm:rounded-3xl sm:px-16"
                >
                    <h2
                        class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-800 sm:text-4xl"
                    >
                        Unlock your Viking past today.
                    </h2>
                    <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-700">
                        Have you ever wondered how much DNA you share with the Vikings? Discover
                        which Viking population groups you are genetically closest too.
                    </p>
                    <div class="mt-10 flex items-center justify-center gap-x-6">
                        <router-link
                            :to="{ name: 'product', params: { slug: 'Viking-Upgrade' } }"
                            class="rounded-md bg-cobalt-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-cobalt-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >Get started</router-link
                        >
                        <span class="font-semibold leading-6 text-gray-700"
                            >Only {{ productPrice }}</span
                        >
                    </div>
                    <svg
                        viewBox="0 0 1024 1024"
                        class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
                        aria-hidden="true"
                    >
                        <circle
                            cx="512"
                            cy="512"
                            r="512"
                            fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                            fill-opacity="0.7"
                        />
                        <defs>
                            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                                <stop stop-color="hsl(195 53% 42%)" />
                                <stop offset="1" stop-color="hsl(195 53% 42%)" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
        <div class="overflow-hidden bg-white py-24">
            <div class="mx-auto max-w-7xl px-6 lg:px-8">
                <div
                    class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2"
                >
                    <div class="lg:pr-8 lg:pt-4">
                        <div class="lg:max-w-lg">
                            <h2 class="text-base font-semibold leading-7 text-cobalt-500">
                                Viking ancestry
                            </h2>
                            <p
                                class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                            >
                                Discover your genetic similarity to Viking groups
                            </p>
                            <p class="mt-6 text-lg leading-8 text-gray-600">
                                With our Viking DNA analysis we estimate the amount of DNA you share
                                with the ancient Vikings, who lived 1,000 years ago in Scandinavia
                                and spread across Northern Europe and over to Iceland, Greenland and
                                even Canada.
                            </p>
                            <dl
                                class="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none"
                            >
                                <div
                                    v-for="feature in features"
                                    :key="feature.name"
                                    class="relative pl-9"
                                >
                                    <dt class="inline font-semibold text-gray-900">
                                        <FeatherWrapper
                                            :icon="feature.icon"
                                            class="absolute left-1 top-1 h-5 w-5 text-cobalt-500"
                                            aria-hidden="true"
                                        />
                                        {{ feature.name }}
                                    </dt>
                                    {{ " " }}
                                    <dd class="inline">{{ feature.description }}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <img
                        :src="vikingMap"
                        alt="Product screenshot"
                        class="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        width="2432"
                        height="1442"
                    />
                </div>
                <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                    <div
                        class="pt-24 contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8"
                    >
                        <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                            <img
                                src="https://static.livingdna.com/images/wellbeing/beach_raid_220228_115030.jpg"
                                alt=""
                                class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                            />
                        </div>
                        <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                            <img
                                :src="vikingResult"
                                alt=""
                                class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                            />
                        </div>
                        <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                            <img
                                src="https://static.livingdna.com/images/wellbeing/shield_wall_220228_112544.jpg"
                                alt=""
                                class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script setup>
import { ref } from "vue";
import ProductService from "@/services/product";

import vikingMap from "@/assets/images/teasers/viking/viking-map.png";
import vikingMap2 from "@/assets/images/teasers/viking/viking-map-2.png";
import vikingIntroduction from "@/assets/images/teasers/viking/viking-introduction.png";
import vikingResult from "@/assets/images/teasers/viking/viking-result.png";

const loadingStatus = ref("loading");
const productPrice = ref("");

function convertToCurrency(value, type) {
    if (value === 0) {
        return "FREE";
    }
    if (typeof type !== "undefined") {
        return Number(value)
            .toLocaleString(undefined, {
                style: "currency",
                currency: type,
            })
            .split(".")[0];
    }
    return Number(0).toLocaleString(undefined, { style: "currency", currency: "USD" });
}

ProductService.getProduct("Viking-Upgrade")
    .then((res) => {
        productPrice.value = convertToCurrency(
            res.variants[0].price.base / 100,
            res.variants[0].currency
        );
    })
    .finally(() => (loadingStatus.value = "loaded"));

const features = [
    {
        name: "Viking index.",
        description:
            'The Viking index tries to answer the question: "How much Viking DNA do you have?". This index shows the amount of DNA you share with the ancient Vikings that lived 1,000 years ago.',
        icon: "viking-shield",
    },
    {
        name: "Closest group.",
        description:
            "We tell you which of the 4 Viking Population groups we've identified is most similar to your DNA. These Viking groups are Norwegian Vikings, Swedish and Danish Vikings, British and North Atlantic Vikings, and Eastern European Vikings.",
        icon: "users",
    },
    // {
    //     name: "Database backups.",
    //     description:
    //         "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
    //     // icon: ServerIcon,
    // },
];
</script>
