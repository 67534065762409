<template>
    <div
        v-if="loadingStatus === 'loaded'"
        class="border-grey-200 border-b first:rounded-t-lg flex items-center justify-between px-6 py-5"
    >
        <div class="text-grey-500 whitespace-no-wrap pr-2 w-2/5 text-sm">Share this profile</div>

        <div>
            <Switch
                :model-value="enabled"
                @update:modelValue="onChange"
                :class="enabled ? 'bg-cobalt-500' : 'bg-gray-400'"
                class="relative inline-flex h-6 w-11 items-center rounded-full"
            >
                <span class="sr-only">Enable notifications</span>
                <span
                    :class="enabled ? 'translate-x-6' : 'translate-x-1'"
                    class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                />
            </Switch>
        </div>
    </div>
    <div
        v-if="enabled"
        class="border-grey-200 last:border-b-0 first:rounded-t-lg last:rounded-b-lg px-6 py-5"
    >
        <div class="flex items-center justify-between">
            <div class="text-grey-500 whitespace-no-wrap pr-2 text-sm">Your share link</div>
            <button
                @click="copy(`https://my.livingdna.com/share/${sharingId}`)"
                class="inline-flex items-center gap-x-2 text-xs text-cobalt-500"
            >
                <span v-if="!copied">Copy</span>
                <span v-else>Copied</span>
                <Feather :type="copied ? 'check' : 'clipboard'" class="h-4 w-4" />
            </button>
        </div>

        <div class="mt-2 text-xs">https://my.livingdna.com/share/{{ sharingId }}</div>
    </div>
    <div
        v-if="errorMessage"
        class="border-grey-200 last:border-b-0 first:rounded-t-lg last:rounded-b-lg px-6 py-5"
    >
        <div class="text-sm text-rose-500">
            {{ errorMessage }}
        </div>
    </div>
</template>
<script setup>
import { ref, watch, computed } from "vue";
import { Switch } from "@headlessui/vue";
import { useClipboard } from "@vueuse/core";
import { useLoading } from "@/composables/useLoading";
import axios from "axios";
import endpoints from "@/endpoints";
import { useRoute } from "vue-router";
import { nextTick } from "vue";

const { loadingStatus, finishLoading, failLoading } = useLoading();
const { copy, copied } = useClipboard();
const route = useRoute();
const enabled = ref(false);
const sharingId = ref("");
const errorMessage = ref("");

function onChange(value) {
    axios
        .post(endpoints.publicProfileAdmin(route.params.barcode), {
            active: value,
        })
        .then((res) => {
            sharingId.value = res.data.id;
            enabled.value = value;
        })
        .catch((err) => {
            enabled.value = !value;
            errorMessage.value =
                err.response?.data?.message ||
                "Something went wrong updating your sharing preference";
        });
}

axios
    .get(endpoints.publicProfileAdmin(route.params.barcode))
    .then((res) => {
        enabled.value = res.data.has_profile;
        sharingId.value = res.data.id;
        finishLoading();
    })
    .catch((e) => {
        failLoading("Something went wrong");
    });
</script>
