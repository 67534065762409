<template>
    <ClassicalNotPurchased v-if="!$store.getters.kitTests.classical.purchased" />
    <ClassicalNotComplete v-else-if="$store.getters.kitTests.classical.status !== 'completed'" />
    <template v-else>
        <portal to="navigation">
            <NavBar :tabs="tabs" />
        </portal>
        <router-view v-if="loadingStatus === 'loaded'" />
    </template>
</template>

<script setup>
import NavBar from "@/components/partials/NavBar.vue";
import { getClassicalResult, getRequiredContent } from "@/composables/useClassicalContent";
import { provide, ref } from "vue";
import { useLoading } from "@/composables/useLoading";
import ClassicalNotPurchased from "@/views/Classical/ClassicalNotPurchased.vue";
import ClassicalNotComplete from "@/views/Classical/ClassicalNotComplete.vue";

const mapCard = ref({});
const introduction = ref({});
const result = ref({});

const { loadingStatus, finishLoading } = useLoading();

Promise.all([
    getRequiredContent(["classical-map-card", "classical-introduction"]),
    getClassicalResult(),
])
    .then(([[mapCardRef, introductionRes], resultRes]) => {
        mapCard.value = mapCardRef;
        introduction.value = introductionRes;
        result.value = resultRes;
    })
    .finally(finishLoading);

provide("map-card", mapCard);
provide("classical-introduction", introduction);
provide("classical-result", result);

const tabs = [
    {
        name: "All Classical",
        to: { name: "classical-overview" },
        icon: "classical",
        children: [
            {
                name: "Overview",
                to: { name: "classical-overview" },
            },
        ],
    },
    {
        name: "Roman",
        to: { name: "roman" },
        icon: "roman",
        children: [
            {
                name: "Overview",
                to: { name: "roman-overview" },
            },
            {
                name: "Result",
                to: { name: "dashboard" },
            },
            {
                name: "Map",
                to: { name: "dashboard" },
            },
        ],
    },
    {
        name: "Greek",
        to: { name: "greek" },
        icon: "column",
        children: [
            {
                name: "Overview",
                to: { name: "greek-overview" },
            },
            {
                name: "Result",
                to: { name: "dashboard" },
            },
            {
                name: "Map",
                to: { name: "dashboard" },
            },
        ],
    },

    {
        name: "Egyptian",
        to: { name: "egyptian" },
        icon: "egyptian",
        children: [
            {
                name: "Overview",
                to: { name: "egyptian-overview" },
            },
            {
                name: "Result",
                to: { name: "dashboard" },
            },
            {
                name: "Map",
                to: { name: "dashboard" },
            },
        ],
    },
];
</script>
