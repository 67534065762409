<template>
    <div>
        <p class="text-center leading-normal">
            Please set a new password to log into your Living DNA account. Your password should be
            at least 8 characters.
        </p>
        <form @submit.prevent="saveNewPassword">
            <PasswordInput
                class="mt-2"
                v-model="password"
                :new-password="true"
                :error-text="passwordError ? passwordError.detail : ''"
                @blur="validatePassword"
                @input="updatePasswordError"
            />
            <button
                :class="{
                    loading: loading,
                    'bg-grey-200 text-grey-500': !readyToSubmit,
                    'bg-cobalt-500 text-white': readyToSubmit,
                }"
                :disabled="!readyToSubmit"
                class="mt-6 rounded w-full py-2 px-4 font-semibold"
            >
                Log in
            </button>
        </form>
    </div>
</template>

<script>
import PasswordInput from "@/components/inputs/PasswordInput/PasswordInput.vue";

export default {
    components: { PasswordInput },
    data() {
        return {
            password: "",
            loading: false,
            passwordError: { detail: "" },
        };
    },
    methods: {
        saveNewPassword() {
            this.loading = true;
            this.$store
                .dispatch("createNewPassword", {
                    resetPassword: {
                        plainPassword: {
                            first: this.password,
                            second: this.password,
                        },
                        token: this.$route.params.token,
                    },
                })
                .then(() => {
                    this.loading = false;
                    this.$router.push({ name: "login" });
                })
                .catch(() => {
                    this.passwordError.detail = "There was an error resetting your password.";
                    this.loading = false;
                });
        },
        validatePassword() {
            if (this.passwordError && this.password.length < 8) {
                this.passwordError.detail =
                    "This password is too short. It must contain at least 8 characters.";
            }
        },
        updatePasswordError() {
            if (this.passwordError && this.passwordError.detail && this.password.length >= 8) {
                this.passwordError.detail = "";
            }
        },
    },
    computed: {
        readyToSubmit() {
            return this.password.length >= 8;
        },
    },
};
</script>
