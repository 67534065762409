<template>
    <template v-if="loadingStatus === 'failed'">
        <RegionCardUploadFailed
            v-if="$store.getters.selectedTest.raw_status === 'transfer_qc_failed'"
        />
        <AlertMessage
            v-else-if="$store.getters.selectedTest.raw_status === 'testing_failed'"
            icon="alert-triangle"
            type="error"
        >
            <template #title>Testing failed</template>
            <template #body
                ><p>
                    Your file was successfully uploaded be we were unable to test it. This is
                    usually down to an issue with the sample, for example low sample quality.
                </p>
                <p class="mt-2">
                    For more information feel free to reach to our support team at
                    <a href="mailto:help@livingdna.com" class="text-cobalt-500 font-semibold"
                        >help@livingdna.com</a
                    >.
                </p></template
            >
        </AlertMessage>
        <RegionCardProcessing v-else-if="$store.getters.selectedTest.raw_status === 'testing'" />
        <MessageCard v-else type="error" title="Failed to load data">
            We have failed to load your DNA result. Please contact support at
            <a href="mailto:help@livingdna.com" class="text-cobalt-500 font-semibold"
                >help@livingdna.com</a
            >
            for assistance
        </MessageCard>
    </template>

    <div v-else class="bg-white p-6 rounded shadow">
        <PageLoader v-if="loadingStatus === 'loading'" />

        <div v-else class="flex flex-col gap-4">
            <h3 class="font-semibold">Starter breakdown</h3>
            <p>
                You are currently viewing the continental level breakdown. Unlock regional and
                subregional level breakdowns by purchasing the autosomal upgrade.
            </p>
            <p>
                You have {{ autosomalStore.result.regionCount }}
                {{ pluralize("region", autosomalStore.result.regionCount) }}
                <span v-if="autosomalStore.result.subregionCount > 0">
                    and {{ autosomalStore.result.subregionCount }}
                    {{ pluralize("subregions", autosomalStore.result.subregionCount) }}</span
                >
                to discover across {{ autosomalStore.result.regions.length }}
                {{ pluralize("continent", autosomalStore.result.regions.length) }}
            </p>
            <div class="flex items-start">
                <div class="flex flex-col gap-2">
                    <div v-for="region in autosomalStore.result.regions">
                        <div class="flex items-center gap-4">
                            <div
                                class="rounded-full h-5 w-5"
                                :style="{ backgroundColor: region.colour }"
                            />
                            <div class="text-gray-700">
                                {{ region.title }}
                                <span class="text-gray-500 text-sm"
                                    >({{ region.percentage }}%)</span
                                >
                            </div>
                        </div>
                        <div class="ml-10 text-gray-500 text-sm">
                            + {{ region.children.length }}
                            {{ pluralize("region", region.children.length) }}
                        </div>
                    </div>
                </div>
                <AutosomalDoughnut
                    class="ml-auto"
                    :region-data="autosomalStore.result.regions"
                    style="height: 180px; width: 180px"
                />
            </div>
            <div class="flex flex-col sm:flex-row gap-4 items-start sm:items-center max-sm:mt-4">
                <router-link
                    :to="{ name: 'product', params: { slug: 'autosomal-Upgrade' } }"
                    class="bg-cobalt-500 rounded font-semibold text-white hover:bg-cobalt-700 px-4 py-2"
                    >Purchase upgrade</router-link
                >
                <router-link
                    :to="{ name: 'recent-map' }"
                    class="text-cobalt-500 font-semibold text-sm"
                    >View continental breakdown</router-link
                >
            </div>
        </div>
    </div>
</template>

<script setup>
import { useLoading } from "@/composables/useLoading";
import { useAutosomalStore } from "@/store/ancestry/autosomal";
import pluralize from "pluralize";
import AutosomalDoughnut from "@/components/Dashboard/Ancestry/AutosomalDoughnut.vue";
import RegionCardUploadFailed from "@/components/Dashboard/Ancestry/RegionCard/RegionCardUploadFailed.vue";
import RegionCardPending from "@/components/Dashboard/Ancestry/RegionCard/RegionCardPending.vue";
import RegionCardProcessing from "@/components/Dashboard/Ancestry/RegionCard/RegionCardProcessing.vue";
import MessageCard from "@/components/Dashboard/Ancestry/MessageCard.vue";
import AlertMessage from "@/components/AlertMessage.vue";

const { loadingStatus, finishLoading, failLoading } = useLoading();

const autosomalStore = useAutosomalStore();
autosomalStore.getAutosomalResults().then(finishLoading).catch(failLoading);
</script>
