<template>
    <div class="mt-16">
        <div class="flex flex-wrap items-stretch -mx-4">
            <div v-for="point in positivePoints" class="mb-4 px-4 w-full md:w-1/2 lg:w-1/4">
                <div
                    class="border-t-2 border-emerald-500 bg-white shadow rounded p-4 text-sm h-full relative"
                >
                    <div
                        class="absolute bg-emerald-500 absolute p-1 text-white top-0 left-0 rounded-full flex h-4 w-4 -mt-2 -ml-2"
                    >
                        <feather type="check" class="h-2 w-2 m-auto fill-current" />
                    </div>
                    {{ point }}
                </div>
            </div>
            <div v-for="point in warningPoints" class="mb-4 px-4 w-full md:w-1/2 lg:w-1/4">
                <div
                    class="border-t-2 border-rose-500 bg-white shadow rounded p-4 text-sm h-full relative"
                >
                    <div
                        class="absolute bg-rose-500 absolute p-1 text-white top-0 left-0 rounded-full flex h-4 w-4 -mt-2 -ml-2"
                    >
                        <feather type="x" class="h-2 w-2 m-auto fill-current" />
                    </div>
                    {{ point }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["positivePoints", "warningPoints"], // todo what is blank state of points so I can validate properly?
};
</script>
