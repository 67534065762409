<template>
    <main>
        <header class="bg-denim-700">
            <div class="container py-4 px-6">
                <a href="https://livingdna.com/uk/">
                    <img :src="logo" alt="Living DNA logo" class="h-8" />
                </a>
            </div>
        </header>
        <div class="mt-8 px-6">
            <div>
                <h1 class="font-normal text-2xl text-center">Survey central</h1>
                <p class="mt-4 mb-0 mx-auto max-w-md">
                    As part of our ongoing research, Living DNA is actively collecting feedback on
                    the following regions for estimation refinement.
                </p>
            </div>
            <div>
                <div class="max-w-3xl mx-auto mt-12">
                    <div class="md:flex -mx-3">
                        <div class="md:w-1/2 px-3">
                            <a
                                href="https://livingdna.typeform.com/to/gdQwJE"
                                class="shadow-key hover:shadow-key-hover-hover rounded-lg bg-white flex-1 flex text-grey-700"
                            >
                                <div class="p-6 flex-1">
                                    <div class="mb-4">
                                        Has your Germanic ancestry been over or underestimated by
                                        >10%?
                                    </div>
                                    <div class="flex items-center font-semibold text-cobalt-500">
                                        <span class="mr-2 whitespace-no-wrap"
                                            >Take the Germanic Survey</span
                                        >
                                        <Feather type="arrow-right" class="h-5 w-5" />
                                    </div>
                                </div>
                                <div
                                    class="hidden md:block overflow-hidden rounded-r-lg fade-left bg-cover bg-white"
                                    style="width: 152px"
                                    :style="{ backgroundImage: `url('${germanicImage}')` }"
                                />
                            </a>
                        </div>
                        <div class="md:w-1/2 px-3 mt-6 md:mt-0">
                            <a
                                href="https://livingdna.typeform.com/to/W7mvqY"
                                class="shadow-key hover:shadow-key-hover-hover rounded-lg bg-white flex-1 flex text-grey-700"
                            >
                                <div class="p-6 flex-1">
                                    <div class="mb-4">
                                        Has your African ancestry been over or underestimated by
                                        >10%?
                                    </div>
                                    <div class="flex items-center font-semibold text-cobalt-500">
                                        <span class="mr-2 whitespace-no-wrap"
                                            >Take the Africa Survey</span
                                        >
                                        <Feather type="arrow-right" class="h-5 w-5" />
                                    </div>
                                </div>
                                <div
                                    class="hidden md:block overflow-hidden rounded-r-lg fade-left bg-cover bg-white"
                                    style="width: 152px"
                                    :style="{ backgroundImage: `url('${africanImage}')` }"
                                />
                            </a>
                        </div>
                    </div>
                    <!-- second row -->
                    <div class="md:flex -mx-3 mt-6">
                        <div class="md:w-1/2 px-3">
                            <a
                                href="https://livingdna.typeform.com/to/xrl1xL"
                                class="shadow-key hover:shadow-key-hover-hover rounded-lg bg-white flex-1 flex text-grey-700"
                            >
                                <div class="p-6 flex-1">
                                    <div class="mb-4">
                                        Has your Iberian ancestry been over or underestimated by
                                        >10%?
                                    </div>
                                    <div class="flex items-center font-semibold text-cobalt-500">
                                        <span class="mr-2 whitespace-no-wrap"
                                            >Take the Iberia Survey</span
                                        >
                                        <Feather type="arrow-right" class="h-5 w-5" />
                                    </div>
                                </div>
                                <div
                                    class="hidden md:block overflow-hidden rounded-r-lg fade-left bg-cover bg-white"
                                    style="width: 152px"
                                    :style="{ backgroundImage: `url('${iberianImage}')` }"
                                />
                            </a>
                        </div>
                        <div class="md:w-1/2 px-3 mt-6 md:mt-0">
                            <a
                                href="https://livingdna.typeform.com/to/v1xGHX"
                                class="shadow-key hover:shadow-key-hover-hover rounded-lg bg-white flex-1 flex text-grey-700"
                            >
                                <div class="p-6 flex-1">
                                    <div class="mb-4">
                                        Has your Italian ancestry been over or underestimated by
                                        >10%?
                                    </div>
                                    <div class="flex items-center font-semibold text-cobalt-500">
                                        <span class="mr-2 whitespace-no-wrap"
                                            >Take the Italy Survey</span
                                        >
                                        <Feather type="arrow-right" class="h-5 w-5" />
                                    </div>
                                </div>
                                <div
                                    class="hidden md:block overflow-hidden rounded-r-lg fade-left bg-cover bg-white"
                                    style="width: 152px"
                                    :style="{ backgroundImage: `url('${italianImage}')` }"
                                />
                            </a>
                        </div>
                    </div>
                    <p class="mt-10" style="font-size: 13px">
                        The science of estimating ancestry based on your DNA is strictly a
                        prediction tool. Living DNA strives to provide the most accurate, detailed
                        estimations possible within the limitations of what modern science can
                        reveal. While our team is currently focused on collecting feedback for
                        refinement on the regions listed above to coincide with our recent updates,
                        we will reach out via email and social channels when refining new regions
                        that may affect your results.
                    </p>
                    <p class="mt-4 text-sm text-denim-700 text-center">
                        Stay in the know on future surveys by following us on social
                    </p>
                    <div class="text-center">
                        <a
                            href="https://www.facebook.com/livingdna/"
                            target="_blank"
                            rel="noopener"
                            class="mr-4"
                        >
                            <img :src="fbImage" alt="Facebook" class="inline" />
                        </a>
                        <a
                            href="https://www.instagram.com/living_dna/"
                            target="_blank"
                            rel="noopener"
                            class="mr-4"
                        >
                            <img :src="instagramImage" alt="Facebook" class="inline" />
                        </a>
                        <a href="https://twitter.com/Living_DNA/" target="_blank" rel="noopener">
                            <img :src="twitterImage" alt="Facebook" class="inline" />
                        </a>
                    </div>
                    <p class="text-xs text-center mb-0 mt-6 mb-12">
                        Still have more questions about your ancestry predictions? Visit our
                        <a
                            href="https://support.livingdna.com/hc/en-us/sections/360003556899-Recent-ancestry-updates"
                            class="font-semibold"
                            >Support Center</a
                        >
                    </p>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import logo from "@/assets/images/logos/ldna-white.png";
import germanicImage from "@/assets/images/survey-central/germanic.png";
import africanImage from "@/assets/images/survey-central/africa.png";
import fbImage from "@/assets/images/social-icons/facebook-brand-colour.png";
import instagramImage from "@/assets/images/social-icons/instagram-brand-colour.png";
import twitterImage from "@/assets/images/social-icons/twitter-brand-colour.png";
import iberianImage from "@/assets/images/survey-central/iberia.png";
import italianImage from "@/assets/images/survey-central/italy.png";
import RightImageCard from "@/components/cards/RightImageCard.vue";

export default {
    data() {
        return {
            logo,
            germanicImage,
            africanImage,
            iberianImage,
            italianImage,
            fbImage,
            instagramImage,
            twitterImage,
        };
    },
    components: { RightImageCard },
};
</script>
