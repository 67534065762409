<template>
    <Field
        name="countryCode"
        as="select"
        v-model="countryCode"
        class="border border-grey-300 rounded bg-grey-50 w-full p-2 focus:outline-none focus:border-cobalt-500"
    >
        <option v-for="country in countries" :key="country.code" :value="country.code">
            {{ country.name }}
        </option>
    </Field>
</template>

<script>
import { ref, watch } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import countries from "@/countries";

export default {
    components: { Field, ErrorMessage },
    props: {
        modelValue: Object,
    },
    setup(props, { emit }) {
        const countryCode = ref(props.modelValue ? props.modelValue.code : "");
        watch(countryCode, (currentValue) => {
            emit(
                "update:modelValue",
                countries.find((country) => country.code === currentValue)
            );
        });
        return { countryCode, countries };
    },
};
</script>
