import axios from "axios";
import endpoints from "@/endpoints";

class Ancestry {
    static async getFatherlineHistory(barcode) {
        const { data } = await axios.get(`${endpoints.tests}/${barcode}/fatherline`);
        return data;
    }

    static async getMotherlineHistory(barcode) {
        const { data } = await axios.get(`${endpoints.tests}/${barcode}/motherline`);
        return data;
    }
}

export default Ancestry;
