<template>
    <div class="w-full h-full">
        <NotOptedInMessage
            v-if="familyMatchingStore.optInStatus === 'notOptedIn'"
            :barcode="barcode"
        />
        <template v-else>
            <!-- <ChromosomeBrowserBanner /> -->
            <portal to="navigation">
                <NavBar :tabs="tabs" />
            </portal>

            <router-view :key="barcode" />
        </template>
    </div>
</template>

<script setup>
import { computed } from "vue";
import store from "@/store/store";
import { useFamilyMatchingStore } from "@/store/familyMatching";
import NavBar from "@/components/partials/NavBar.vue";
import NotOptedInMessage from "./NotOptedInMessage.vue";

const barcode = computed(() => store.getters.profile.barcode);

const tabs = [
    {
        name: "Relatives List",
        to: { path: "/family-matching/list" },
        icon: "list",
    },
    {
        name: "Messaging centre",
        to: { path: "/family-matching/messaging-centre" },
        icon: "mail",
    },
    {
        name: "Matchbox",
        to: { path: "/family-matching/matchbox" },
        icon: "archive",
        // new: true,
    },
    {
        name: "Multiview browser",
        to: { path: "/family-matching/multiview-browser" },
        icon: "helix",
        // new: true,
    },
];

const familyMatchingStore = useFamilyMatchingStore();
</script>
