<template>
    <div>
        <MigrationMap :result="sharingStore.motherlineResult" type="mtdna" />
    </div>
</template>

<script setup>
import MigrationMap from "./SharingMigrationMap.vue";
import { useSharingStore } from "@/store/sharing";

const sharingStore = useSharingStore();
</script>
