<template>
    <ArchaicNotPurchased v-if="status === 'notPurchased'" />
    <div v-else class="w-full h-full flex flex-col items-center relative">
        <div class="cover-image"></div>
        <div class="absolute flex items-center top-0 left-0 pl-6 pt-6">
            <feather class="h-5 w-5 text-white cursor-pointer mr-2" type="chevron-left" />
            <router-link to="/dashboard">
                <p class="text-white font-semibold leading-normal my-0">Back to Dashboard</p>
            </router-link>
        </div>
        <div class="m-auto bg-white rounded shadow-key-hover z-10 max-w-lg">
            <div
                class="bg-cobalt-50 px-6 py-3 rounded-t flex items-center text-cobalt-500 text-sm font-semibold"
            >
                <feather type="info" class="h-5 w-5 fill-current mr-2" />
                {{ thing.title }}
            </div>
            <div class="p-6" v-html="thing.description" />

            <div v-if="thing.linkText" class="p-6 pt-0 flex justify-center">
                <router-link
                    :to="thing.linkTarget($store.getters.profile.barcode)"
                    class="bg-cobalt-500 text-white font-semibold px-6 py-4 rounded"
                >
                    <span class="text-white">{{ thing.linkText }}</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import ArchaicNotPurchased from "./ArchaicNotPurchased.vue";
import { mapGetters } from "vuex";

const dictionary = {
    transferQcFailed: {
        title: "We've encountered an error",
        description:
            "An issue has occurred in retrieving DNA information from your upload. Please reupload your data by clicking the button below",
        linkText: "Reupload",
        linkTarget: (barcode) => ({
            name: "reupload-dna",
            params: {
                barcode,
            },
        }),
    },
    loadingFailed: {
        title: "Something went wrong loading the page",
        description: "Please refresh to try again or contact support if the issue persists",
    },
    testErrored: {
        title: "Something went wrong processing your test",
        description: "Please see the dashboard for more information",
        linkText: "Dashboard",
        linkTarget: () => ({
            name: "dashboard",
        }),
    },
    testFailed: {
        title: "Something went wrong processing your test",
        description: "Please see the dashboard for more information",
        linkText: "Dashboard",
        linkTarget: () => ({
            name: "dashboard",
        }),
    },
    resultPending: {
        title: "Your results are currently being processed",
        description:
            "Please check back later, you will receive an email when your results have finished processing",
    },
    testNoData: {
        title: "Something went wrong processing your test",
        description: "Please see the dashboard for more information",
        linkText: "Dashboard",
        linkTarget: () => ({
            name: "dashboard",
        }),
    },
    notPurchased: {
        title: "What will you discover?",
        description: "Upgrade your kit to reveal your genetic similarity to archaic humans",
        linkText: "Purchase archaic human upgrade",
        linkTarget: () => ({
            path: "/store/Archaic-Upgrade ",
        }),
    },
};

const dictionary2 = {
    cancelled: {
        title: "Your test has been cancelled",
        description: "Looks like you don't have an archaic human test.",
    },
    completed: {
        title: "Testing is complete.",
        description: "",
    },
    genotyping: {
        title: "Your test is currently processing",
        description:
            "Your archaic human results are processing. You will receive an email when your results are ready.",
    },
    returned: {
        title: "Your test is currently processing",
        description:
            "Your archaic human results are processing. You will receive an email when your results are ready.",
    },
    shipped: {
        title: "Your test is currently processing",
        description:
            "Your archaic human results are processing. You will receive an email when your results are ready.",
    },
    testing: {
        title: "Your test is currently processing",
        description:
            "Your archaic human results are processing. You will receive an email when your results are ready.",
    },
    testing_failed: {
        title: "Your test has failed",
        description:
            "We have encountered an issue in processing your DNA. Please check your email for instruction..",
    },
    no_data: {
        uploader: {
            title: "Data unavailable",
            description:
                "DNA upload files do not contain the necessary markers for processing deep archaic human information. To obtain the full story of your ancestors, purchase the Living DNA archaic human test.",
            link: "",
        },
        starter_kit: {
            title: "Upgrade required",
            description:
                "Unlock the furthest-reaching history of your ancestors with the Deep archaic human Upgrade. See migration routes, your haplogroup, and where your DNA signature is commonly found today.",
            link: "/store/Deep-archaic human-Upgrade",
        },
    },
};

/**
 * @param {string} value
 */
function validatePropValue(value) {
    const allowedValues = Object.keys(dictionary);
    return allowedValues.includes(value);
}

export default {
    name: "archaic human-status",
    components: { ArchaicNotPurchased },
    props: {
        status: {
            required: true,
            type: String,
            validator: validatePropValue,
        },
    },
    computed: {
        ...mapGetters(["isUploader"]),
        thing() {
            return dictionary[this.status];
        },
    },
};
</script>

<style scoped>
.cover-image {
    background-image: url("@/assets/images/viking-status.jpg");
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    filter: blur(3px);
}
</style>
