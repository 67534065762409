<template>
    <div
        :to="{ name: 'match-details', params: { barcode: relative.barcode } }"
        class="px-3 sm:px-6 py-4 flex items-center border-grey-200 relative"
    >
        <div
            v-if="$store.getters.familyMatchingVersion === relative.version"
            class="absolute top-[8px] left-[4px] bg-pacific-500 text-white text-sm px-2 rounded"
        >
            New
        </div>
        <div class="w-5/6 md:w-2/5 text-grey-700 flex items-center">
            <div class="relative h-12 w-12 sm:h-16 sm:w-16 flex-none mr-6">
                <Avatar :path="relative.photo" />
                <span
                    :title="countryName"
                    class="rounded-full w-6 h-6 flag-icon-squared absolute bottom-0 right-0 border-2 border-white"
                    :class="[`flag-icon-${relative.countryOfBirth.toLowerCase()}`]"
                    style="
                        background-size: contain;
                        background-position: 50%;
                        background-position-x: 50%;
                        background-position-y: center;
                        background-repeat: no-repeat;
                    "
                />
            </div>
            <div class="text-grey-600">
                <h3 class="font-semibold text-grey-700 underline">
                    <router-link
                        :to="{ name: 'match-details', params: { barcode: relative.barcode } }"
                    >
                        {{ relative.username || relative.name }}
                    </router-link>
                </h3>
                <p class="my-0 font-semibold text-grey-700 lg:hidden">{{ name }}</p>
                <div>
                    <span class="flex items-center" v-if="relative.activityLevel === 'LESS_ACTIVE'">
                        Less active
                        <Trophy class="h-5 w-5 ml-2" style="color: #a97412" />
                    </span>
                    <span class="flex items-center" v-else-if="relative.activityLevel === 'ACTIVE'">
                        Active
                        <Trophy class="h-5 w-5 ml-2" style="color: #bec2cb" />
                    </span>
                    <span
                        class="flex items-center"
                        v-else-if="relative.activityLevel === 'VERY_ACTIVE'"
                    >
                        Very active
                        <Trophy class="h-5 w-5 ml-2" style="color: #d4af37" />
                    </span>
                </div>
                <div class="text-grey-600">
                    <span v-if="isManaged" class="flex items-center"
                        >DNA Manager <Feather type="briefcase" class="h-5 w-5 ml-2 text-grey-500"
                    /></span>
                    <span v-else class="flex items-center"
                        >DNA Owner <Feather type="user" class="h-5 w-5 ml-2 text-grey-500"
                    /></span>

                    <div class="text-grey-600 lg:hidden">
                        {{ relative.dnaSharedPercentage }} DNA shared ({{ formattedCm }})
                    </div>
                    <router-link
                        :to="{ name: 'chromosome-browser', params: { barcode: relative.barcode } }"
                        class="text-cobalt-500 hover:underline lg:hidden"
                    >
                        {{ relative.segmentCount }} shared
                        {{ pluralize("segment", relative.segmentCount) }}
                    </router-link>
                    <br />
                    <button
                        @click="editMatchbox"
                        class="mt-1 text-cobalt-500 lg:hidden hover:underline"
                    >
                        {{ matchingGroups.length ? "Edit matchbox" : "Add to matchbox" }}
                    </button>
                </div>
            </div>
        </div>
        <div class="w-1/6 md:w-3/5 flex justify-end items-center lg:justify-between">
            <div class="w-1/2">
                <p class="my-0 font-semibold text-grey-700 hidden lg:block">{{ name }}</p>
                <div class="text-grey-600 hidden lg:block">
                    {{ relative.dnaSharedPercentage }} DNA shared ({{ formattedCm }})
                </div>
                <router-link
                    :to="{ name: 'chromosome-browser', params: { barcode: relative.barcode } }"
                    class="text-cobalt-500 hover:underline hidden lg:block"
                >
                    {{ relative.segmentCount }} shared
                    {{ pluralize("segment", relative.segmentCount) }}
                </router-link>
            </div>
            <div class="w-2/5 text-grey-600 hidden lg:block text-cobalt-500">
                <div>
                    {{ sharedMatchesString }}
                </div>

                <button @click="editMatchbox" class="mt-1 text-cobalt-500 text-sm hover:underline">
                    {{ matchingGroups.length ? "Edit matchbox" : "Add to matchbox" }}
                </button>

                <div class="flex items-center gap-2 mt-1">
                    <div
                        v-for="group in matchingGroups"
                        class="rounded-full h-5 w-5"
                        :style="{ backgroundColor: group.colour }"
                    />
                </div>
            </div>
            <div class="flex items-center">
                <div
                    v-if="hasMessage"
                    class="flex flex-col items-center mr-12 text-grannysmith-500"
                >
                    <Feather type="mail" class="h-5 w-5" />
                    <p class="text-sm my-0"></p>
                </div>
                <router-link
                    :to="{ name: 'match-details', params: { barcode: relative.barcode } }"
                    class="bg-cobalt-50 text-cobalt-500 p-2 rounded-full"
                >
                    <Feather type="chevron-right" class="h-5 w-5 block" />
                </router-link>
            </div>
        </div>
        <MatchboxModal :show-modal="showModal" :match="relative" @closed="showModal = false" />
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { getRelationshipDetailsFromMatch } from "@/services/relationship";
import countries from "@/countries";
import Trophy from "@/components/icons/Trophy.vue";
import Avatar from "@/components/Avatar.vue";
import pluralize from "pluralize";
import MatchboxModal from "@/components/FamilyMatching/Matchbox/MatchboxModal.vue";
import { useMatchGroupStore } from "@/store/matchGroups";
import store from "@/store/store";

const matchGroupStore = useMatchGroupStore();

const props = defineProps({
    relative: {
        required: true,
        type: Object,
    },
    hasMessage: {
        type: Boolean,
    },
});

const matchingGroups = computed(() => {
    return matchGroupStore.matchGroups.filter((group) => {
        return !!group.members.map((member) => member.barcode).includes(props.relative.barcode);
    });
});

const showModal = ref(false);

function editMatchbox() {
    showModal.value = true;
}

const roundedCm = computed(() => (Math.round(props.relative.dnaShared * 100) / 100).toFixed(2));

const formattedCm = computed(() => `${roundedCm.value}cM`);

const name = computed(() => getRelationshipDetailsFromMatch(props.relative).name);

const sharedMatchesString = computed(() => {
    if (props.relative.sharedMatchesCount === 0) {
        return "No shared matches";
    }
    if (props.relative.sharedMatchesCount === 1) {
        return "1 shared match";
    }
    return `${props.relative.sharedMatchesCount} shared matches`;
});

const isManaged = computed(() => props.relative.permissionsType.includes("MANAGER"));

const countryName = computed(() => {
    const profilesCountry = countries.find(
        (country) => country.code === props.relative.countryOfBirth
    );
    return profilesCountry ? profilesCountry.name : "";
});
</script>
