<template>
    <svg
        id="Capa_1"
        enable-background="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m492.054 96.642c3.676-3.676 5.187-8.993 3.992-14.053s-4.923-9.14-9.855-10.784l-95.46-31.82c-5.388-1.795-11.332-.393-15.35 3.624-4.018 4.018-5.42 9.96-3.624 15.35l31.82 95.46c3.503 10.507 16.915 13.785 24.837 5.863l20.402-20.402c21.761 35.633 33.184 75.438 33.184 116.12 0 124.617-101.383 226-226 226-8.284 0-15 6.716-15 15s6.716 15 15 15c140.959 0 256-115.05 256-256 0-48.6-14.294-96-41.437-137.867zm-67.691 25.265-14.658-43.974 43.974 14.658z"
        />
        <path
            d="m15.382 412.313c-3.676 3.676-5.187 8.993-3.992 14.053s4.923 9.14 9.855 10.784l95.459 31.82c5.368 1.789 11.315.411 15.35-3.624 4.018-4.017 5.42-9.96 3.624-15.35l-31.82-95.459c-1.644-4.932-5.725-8.661-10.784-9.855-5.061-1.195-10.377.317-14.053 3.992l-18.65 18.65c-19.916-34.5-30.371-72.64-30.371-111.324 0-124.617 101.383-226 226-226 8.284 0 15-6.716 15-15s-6.716-15-15-15c-140.959 0-256 115.05-256 256 0 46.583 13.28 92.374 38.5 133.194zm67.691-25.265 14.658 43.974-43.974-14.658z"
        />
        <path
            d="m286 61c-38.851 0-70.891 29.695-74.63 67.58-4.622 1.617-9.16 3.476-13.589 5.593-8.146-8.135-19.386-13.173-31.781-13.173-24.813 0-45 20.187-45 45 0 12.395 5.038 23.635 13.173 31.781-8.649 18.09-13.173 37.966-13.173 58.219 0 13.094 1.867 25.971 5.545 38.384-3.531 6.419-5.545 13.786-5.545 21.616 0 24.813 20.187 45 45 45 1.632 0 3.243-.093 4.831-.263 24.028 19.564 53.977 30.263 85.169 30.263 15.64 0 30.875-2.64 45.406-7.838 8.74 4.978 18.836 7.838 29.594 7.838 33.084 0 60-26.916 60-60 0-10.758-2.86-20.854-7.838-29.594 5.198-14.531 7.838-29.766 7.838-45.406 0-35.197-13.544-67.286-35.688-91.342 3.736-9.037 5.688-18.762 5.688-28.658 0-41.355-33.645-75-75-75zm0 30c24.813 0 45 20.187 45 45 0 2.491-.229 4.958-.632 7.39-21.34-14.14-46.907-22.39-74.368-22.39-4.23 0-8.441.213-12.625.604 6.019-17.771 22.846-30.604 42.625-30.604zm-120 60c8.271 0 15 6.729 15 15s-6.729 15-15 15-15-6.729-15-15 6.729-15 15-15zm-15 165c0-8.271 6.729-15 15-15s15 6.729 15 15-6.729 15-15 15-15-6.729-15-15zm180 45c-16.542 0-30-13.458-30-30s13.458-30 30-30 30 13.458 30 30-13.458 30-30 30zm27.733-83.18c-8.301-4.347-17.731-6.82-27.733-6.82-33.084 0-60 26.916-60 60 0 10.002 2.473 19.432 6.82 27.733-7.118 1.503-14.408 2.267-21.82 2.267-19.871 0-39.092-5.581-55.665-15.958 6.645-7.845 10.665-17.98 10.665-29.042 0-24.813-20.187-45-45-45-4.739 0-9.307.741-13.6 2.105-.92-5.623-1.4-11.334-1.4-17.105 0-15.751 3.513-31.201 10.228-45.255 1.569.166 3.16.255 4.772.255 24.813 0 45-20.187 45-45 0-1.612-.089-3.204-.255-4.772 14.054-6.715 29.504-10.228 45.255-10.228 57.897 0 105 47.103 105 105 0 7.412-.764 14.702-2.267 21.82z"
        />
    </svg>
</template>
