<template>
    <div class="bg-white min-h-screen p-6 pt-12">
        <div v-if="firstName">
            <router-link :to="{ name: 'account' }" class="bg-white font-semibold flex items-center">
                <feather type="chevron-left" class="h-5 w-5 mr-2" />

                {{ firstName }}'s DNA Account
            </router-link>

            <div class="mx-auto pt-16" :class="$route.meta.fullWidth ? 'max-w-3xl' : 'max-w-xs'">
                <transition name="slide-left" mode="out-in">
                    <router-view :account="account" :first-name="firstName" />
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(["account"]),
        firstName() {
            return this.account.firstName;
        },
    },
};
</script>
