<template>
    <div class="mt-12">
        <div class="lg:flex items-end justify-between pb-1">
            <h2 class="flex items-center text-lg leading-none">
                <feather-wrapper icon="users" class="mr-2" />
                Sharing
            </h2>

            <div class="mt-4">
                <div class="text-xs text-gray-400 leading-none">
                    Share this profile with other people
                </div>
            </div>
        </div>
        <div class="w-full mt-6 shadow-key rounded-lg flex-1" style="border-spacing: 0">
            <ToggleRow v-if="loadingStatus === 'loaded' && tests.autosomal.purchased" />
            <div v-else-if="loadingStatus === 'loaded' && !tests.autosomal.purchased" class="p-6">
                <div class="flex flex-col gap-4 items-center">
                    <FeatherWrapper icon="lock" />
                    <p class="text-center max-w-xs">
                        You must have the full autosomal upgrade to share your results.
                    </p>
                </div>
            </div>
            <div v-else-if="loadingStatus === 'failed'">Something went wrong</div>
            <PageLoader v-else-if="loadingStatus === 'loading'" />
        </div>
    </div>
</template>

<script setup>
import ToggleRow from "@/components/Profile/View/ToggleRow.vue";
import MyTests from "@/services/my-tests";
import { ref } from "vue";
import { useLoading } from "@/composables/useLoading";

const props = defineProps({
    selectedTest: Object,
});

const tests = ref({});
const { loadingStatus, finishLoading, failLoading } = useLoading();

MyTests.getKitTests(props.selectedTest.barcode, props.selectedTest.raw_status)
    .then((res) => {
        tests.value = res;
        finishLoading();
    })
    .catch(failLoading);
</script>
