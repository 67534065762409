<template>
    <div>
        <div class="flex">
            <input
                v-model="fnConsent"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="display"
                type="radio"
                :value="true"
            />
            <label
                for="display"
                class="cursor-pointer text-sm leading-none block mt-px font-semibold"
            >
                Display
            </label>
        </div>

        <div class="mt-5 flex">
            <input
                v-model="fnConsent"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="hide"
                type="radio"
                :value="false"
            />
            <label for="hide" class="cursor-pointer text-sm leading-none block mt-px font-semibold">
                Hide
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: ["startingValue"],
    data() {
        return {
            fnConsent: this.startingValue,
        };
    },
    watch: {
        fnConsent() {
            this.$emit("value-changed", this.fnConsent);
        },
    },
};
</script>
