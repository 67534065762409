<template>
    <form @submit.prevent="$emit('accepted-main-terms')" class="mt-6">
        <div class="flex items-center">
            <input
                type="checkbox"
                id="accept-terms"
                v-model="acceptedTerms"
                class="form-checkbox rounded w-6 h-6 text-cobalt-500 mr-3 border-grey-500"
            />
            <label for="accept-terms" class="cursor-pointer text-xs">
                I agree to the
                <a href="https://livingdna.com/legal/Terms-Of-Use" target="_blank" rel="noopener"
                    >Terms of Service</a
                >
                and
                <a href="https://livingdna.com/uk/privacy-centre" target="_blank" rel="noopener"
                    >Privacy Policy</a
                >
            </label>
        </div>
        <div class="flex items-center mt-6">
            <button
                type="submit"
                class="bg-cobalt-500 py-2 px-4 flex items-center rounded"
                :class="{
                    'bg-gray-200 text-gray-600 cursor-default': !acceptedTerms,
                    'bg-cobalt-500 text-white': acceptedTerms,
                    loading,
                }"
                :disabled="!acceptedTerms"
            >
                <feather type="check" class="h-5 w-5 fill-current mr-2" />
                Yes, I consent
            </button>
            <router-link :to="{ name: 'dashboard' }" class="text-gray-600 ml-4">
                Back to dashboard
            </router-link>
        </div>
    </form>
</template>

<script>
export default {
    data: () => ({
        acceptedTerms: false,
    }),
    props: {
        loading: Boolean,
    },
};
</script>
