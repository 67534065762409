<template>
    <Trix v-model="bio" />
</template>

<script>
import Trix from "@/components/Trix/Trix.vue";

export default {
    data() {
        return {
            bio: this.body,
        };
    },
    components: { Trix },
    props: {
        body: {
            type: String,
            required: true,
        },
    },
};
</script>
