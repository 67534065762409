<template>
    <div
        class="xl:hidden absolute bottom-0 left-0 right-0 z-50 bg-white transition-all border-t border-gray-200"
        :class="expanded ? 'top-[25%]' : 'h-[85px]'"
    >
        <div class="h-full" :class="removePadding ? 'py-6' : 'p-6'">
            <div
                @click="toggleExpansion"
                class="flex items-center justify-between cursor-pointer px-6"
            >
                <div>{{ title }}</div>
                <FeatherWrapper
                    :icon="expanded ? 'chevron-down' : 'chevron-up'"
                    :container-classes="['bg-cobalt-50 p-2 rounded-full text-cobalt-500']"
                    :classes="['block']"
                />
            </div>
            <div
                class="overflow-y-scroll mt-6 pb-6"
                :class="pad && 'p-6'"
                style="height: calc(100% - 40px)"
            >
                <slot />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from "vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

const expanded = ref(false);

defineProps({
    title: String,
    pad: {
        type: Boolean,
        default: true,
    },
    removePadding: Boolean,
});

function toggleExpansion() {
    expanded.value = !expanded.value;
}
</script>
