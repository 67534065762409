<template>
    <div class="flex justify-center">
        <p class="bg-grey-50 rounded-lg w-full my-16 p-8 max-w-xl">
            The store is currently in maintenance mode. We might be adding new products, features,
            or upgrading your experience.
            <router-link class="block font-semibold mt-4" :to="{ name: 'dashboard' }"
                >Go to the Dashboard</router-link
            >
        </p>
    </div>
</template>
