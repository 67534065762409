<template>
    <div>
        <BaseSectionTitleSmall>
            {{ title }}
        </BaseSectionTitleSmall>
        <Card class="bg-white mt-4">
            <div>
                <div class="flex flex-wrap md:-mx-4">
                    <div class="w-full md:w-1/2 md:px-4 mb-4 md:mb-0">
                        <BaseTextInput
                            v-model="participantRelation.first_name"
                            label="First name"
                        />
                    </div>
                    <div class="w-full md:w-1/2 md:px-4">
                        <BaseTextInput v-model="participantRelation.last_name" label="Last name" />
                    </div>
                </div>
            </div>
            <div class="mt-6">
                <h3 class="font-normal text-base mb-3">Place of birth</h3>
                <div class="mt-4 flex flex-wrap -mx-4">
                    <div class="w-full md:w-1/3 px-4 mb-2 md:mb-0">
                        <label class="text-grey-700 font-semibold block w-full text-sm">
                            Country
                        </label>
                        <CountryCodeAdaptor
                            :code="participantRelation.country"
                            v-slot="{ countryObject }"
                        >
                            <CountryPicker
                                v-model="participantRelation.country"
                                showChoose
                                class="mt-1"
                                :value="countryObject"
                                @input="setParticipantRelationCountry"
                            />
                        </CountryCodeAdaptor>
                    </div>
                    <div class="w-full md:w-1/3 px-4 mb-2 md:mb-0">
                        <BaseTextInput
                            v-if="participantRelation.country"
                            v-model="participantRelation.county"
                            label="County"
                        />
                    </div>
                    <div class="w-full md:w-1/3 px-4 mb-2 md:mb-0">
                        <BaseTextInput
                            v-if="participantRelation.county"
                            v-model="participantRelation.city"
                            label="City"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-6">
                <label class="text-grey-700 font-semibold block w-full text-sm">
                    Year of birth (e.g. 1925)
                </label>
                <input
                    type="text"
                    class="mt-1 border border-grey-500 rounded w-32 py-2 px-4 focus:outline-none focus:border-cobalt-500"
                    :value="yearOfBirth"
                    @input="setDateOfBirth"
                />
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "@/components/cards/Card.vue";
import BaseSectionTitleSmall from "@/components/Base/BaseSectionTitleSmall.vue";
import BaseTextInput from "@/components/Base/BaseTextInput.vue";
import CountryPicker from "@/components/CountryPicker/CountryCodePicker.vue";
import DateInput from "@/components/inputs/DateInputDropdown.vue";
import DateAdaptor from "@/components/inputs/Adaptors/ResearchDateToStringAdaptor.vue";
import CountryCodeAdaptor from "@/components/CountryPicker/Adaptors/TwoLetterCountryCodeToObject.vue";

export default {
    components: {
        Card,
        BaseSectionTitleSmall,
        BaseTextInput,
        CountryPicker,
        DateInput,
        DateAdaptor,
        CountryCodeAdaptor,
    },
    props: {
        title: String,
        modelValue: Object,
    },
    data() {
        return {
            participantRelation: this.modelValue,
            yearOfBirth: this.setDateOfBirthFromOriginal(this.modelValue.date_of_birth),
        };
    },
    watch: {
        participantRelation(value) {
            console.log("value updated", value);
            this.$emit("update:modelValue", value);
        },
    },
    methods: {
        setParticipantRelationCountry(country) {
            console.log({ country: country.target.value });
            if (!country) {
                this.participantRelation.country = null;
            } else {
                this.participantRelation["country"] = country.target.value;
            }
        },
        setDateOfBirthFromOriginal(dateOfBirth) {
            console.log(dateOfBirth);
            if (dateOfBirth && dateOfBirth.date && dateOfBirth.date.date) {
                return this.getYearFromDateString(dateOfBirth.date.date);
            }
            return null;
        },
        getYearFromDateString(date) {
            const [year] = date.split("-");
            return year;
        },
        setDateOfBirth(event) {
            const year = event.target.value;
            console.log(year);
            if (year > 0 && year < 2000) {
                console.log("passes");
                this.participantRelation.date_of_birth = {};
                this.participantRelation.date_of_birth.date = {};
                this.participantRelation.date_of_birth.date.date = `${year}-01-01`;
            } else {
                this.participantRelation.date_of_birth = {};
            }
            console.log(this.participantRelation.date_of_birth);
        },
    },
    computed: {
        slug() {
            return this.title
                .replace(new RegExp(" ", "g"), "_")
                .replace(new RegExp("'", "g"), "")
                .toLowerCase();
        },
    },
};
</script>
