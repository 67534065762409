import axios from "axios";
import endpoints from "@/endpoints";

class DownloadService {
    static async getDownloadTestFile(uuid, type) {
        const { data } = await axios.get(`${endpoints.downloads}/${type}/${uuid}`, {
            responseType: "arraybuffer",
        });

        return data;
    }
}

export default DownloadService;
