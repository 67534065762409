<template>
    <div>
        <h2 class="flex items-center text-lg">
            <feather-wrapper icon="user" class="mr-2" />

            Details
        </h2>

        <div class="w-full mt-6 shadow-key rounded-lg flex-1" style="border-spacing: 0">
            <DetailsTr title="Name" link="edit/name" append>
                {{ profile.name }}
            </DetailsTr>

            <DetailsTr title="Display name" link="edit/display-name" append>
                <span>
                    {{ profile.username }}
                </span>
            </DetailsTr>

            <DetailsTr title="Identity" append>
                {{ identity }}
            </DetailsTr>

            <DetailsTr title="Biological sex" link="edit/biological-sex" append>
                {{ gender }}
            </DetailsTr>

            <DetailsTr :title="deathDateOrPresent" link="edit/lifetime" append>
                <template v-if="profile.dateOfDeath">
                    {{ profilesDateFormat(profile.dateOfBirth) }}
                    <span class="text-grey-500 mx-1">to</span>
                    {{ profilesDateFormat(profile.dateOfDeath) }}
                </template>

                <template v-else>
                    {{ profilesDateFormat(profile.dateOfBirth) }}
                </template>
            </DetailsTr>

            <DetailsTr title="Birthplace" link="edit/birthplace" append :border="false">
                {{ birthPlace }}
            </DetailsTr>
        </div>
    </div>
</template>

<script>
import { pipe } from "lodash/fp";
import { capitalize } from "lodash";
import moment from "moment";
import countries from "@/countries";
import DetailsTr from "@/components/Profile/View/DetailsTr.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

const changeForMeToMyself = (text) => (text === "For me" ? "Myself" : text);

export default {
    props: {
        profile: {
            type: Object,
            required: true,
        },
    },
    methods: {
        profilesDateFormat(date) {
            return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
        },
    },
    computed: {
        identity() {
            return pipe(
                (s) => s.split("_").join(" "),
                capitalize,
                changeForMeToMyself
            )(this.profile.type);
        },
        gender() {
            return {
                1: "Male",
                2: "Female",
            }[this.profile.gender];
        },
        deathDateOrPresent() {
            return this.profile.dateOfDeath ? "Lifetime" : "Date of birth";
        },
        birthPlace() {
            return countries.find(({ code }) => code === this.profile.countryOfBirth).name;
        },
    },
    components: { DetailsTr, FeatherWrapper },
};
</script>
