<template>
    <div
        class="bg-white flex-0 flex flex-col border-t-4 rounded shadow p-6"
        :class="getType().border"
    >
        <div class="flex items-stretch gap-8">
            <div class="flex flex-col">
                <h4 class="text-gray-500 font-semibold text-sm mb-2">{{ title }}</h4>
                <p class="my-0 text-grey-700">
                    <slot />
                </p>
                <router-link
                    v-if="false"
                    :to="{
                        name: 'reupload-dna',
                        params: { barcode: $store.getters.profile.barcode },
                    }"
                    class="flex items-center font-semibold mt-4 text-cobalt-500"
                >
                    <Feather type="upload" class="h-5 w-5 fill-current mr-2" />
                    Reupload your DNA
                </router-link>
            </div>
            <div class="flex grow-0">
                <feather
                    type="alert-circle"
                    class="fill-current h-24 w-24 m-auto opacity-50"
                    :class="getType().icon"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    title: String,
    type: String,
});

const types = {
    error: {
        text: "text-rose-500",
        icon: "text-rose-500",
        border: "border-rose-500",
    },
    warning: {
        text: "text-tangerine-500",
        icon: "text-tangerine-500",
        border: "border-tangerine-500",
    },
    info: {
        text: "text-cobalt-500",
        icon: "text-cobalt-500",
        border: "border-cobalt-500",
    },
};

function getType() {
    return types[props.type];
}
</script>
