<script>
import { h } from "vue";

function getPrimaryColour(phenotype, level) {
    if (phenotype === "snpsNotFound") {
        return "hsl(220, 23%, 74%)";
    }
    return {
        1: "hsl(333, 100%, 40%)",
        2: "hsl(333, 100%, 40%)",
        3: "hsl(220, 100%, 60%)",
        4: "hsl(120, 43%, 56%)",
        5: "hsl(120, 43%, 56%)",
    }[level];
}

function getSecondaryColour(phenotype, level) {
    if (phenotype === "snpsNotFound") {
        return "hsl(220, 25%, 98%)";
    }
    return {
        1: "hsla(333, 100%, 40%, 0.08)",
        2: "hsla(333, 100%, 40%, 0.08)",
        3: "hsla(220, 100%, 60%, 0.08)",
        4: "hsla(120, 43%, 56%, 0.08)",
        5: "hsla(120, 43%, 56%, 0.08)",
    }[level];
}

function getResult(phenotype, trait, level) {
    if (phenotype === "snpsNotFound") {
        return "Inconclusive";
    }
    if (trait === "gluten" && (level == 1 || level == 2)) {
        return "Variants found";
    }
    if (trait === "gluten" && (level == 3 || level == 3)) {
        return "Variants not found";
    }
    return {
        1: "Decreased",
        2: "Decreased",
        3: "Regular",
        4: "Increased",
        5: "Increased",
    }[level];
}

export default {
    setup(props, { slots }) {
        return () =>
            h(
                "div",
                slots.default({
                    primaryColour: getPrimaryColour(props.phenotype, props.level),
                    secondaryColour: getSecondaryColour(props.phenotype, props.level),
                    result: getResult(props.phenotype, props.trait, props.level),
                })
            );
    },
    props: {
        level: Number,
        trait: String,
        phenotype: String,
    },
};
</script>
