<template>
    <div class="bg-white min-h-screen p-6">
        <div class="max-w-3xl mx-auto pt-16 md:px-12">
            <h1 class="text-3xl text-denim-700 font-normal my-4">Your Account</h1>

            <div class="mt-24 w-full">
                <h2 class="flex items-center text-lg">
                    <feather-wrapper icon="user" class="mr-2" />

                    Details
                </h2>

                <div class="w-full mt-6 shadow-key rounded-lg flex-1" style="border-spacing: 0">
                    <router-link
                        tag="tr"
                        :to="{ name: 'account-name' }"
                        class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b cursor-pointer last:border-b-0 first:rounded-t-lg last:rounded-b-lg flex items-center"
                    >
                        <div class="pl-6 py-5 text-grey-500 text-sm font-semibold w-2/5">Name</div>

                        <div class="px-4 w-2/5 text-grey-700">
                            {{ accountData.firstName }} {{ accountData.lastName }}
                        </div>

                        <div class="w-1/5">
                            <div class="flex pr-6 py-5">
                                <feather-wrapper
                                    icon="chevron-right"
                                    class="text-cobalt-500 my-auto ml-auto"
                                />
                            </div>
                        </div>
                    </router-link>

                    <router-link
                        tag="tr"
                        :to="{ name: 'account-email' }"
                        class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b cursor-pointer last:border-b-0 first:rounded-t-lg last:rounded-b-lg flex items-center"
                    >
                        <div class="pl-6 py-5 text-grey-500 text-sm font-semibold w-2/5">
                            Email address
                        </div>

                        <div class="px-4 w-2/5 truncate text-grey-700">
                            {{ accountData.email }}
                        </div>

                        <div class="w-1/5">
                            <div class="flex pr-6 py-5">
                                <feather-wrapper
                                    icon="chevron-right"
                                    class="text-cobalt-500 my-auto ml-auto"
                                />
                            </div>
                        </div>
                    </router-link>

                    <router-link
                        tag="tr"
                        :to="{ name: 'account-password' }"
                        class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b last:border-b-0 first:rounded-t-lg last:rounded-b-lg cursor-pointer flex items-center"
                    >
                        <div class="pl-6 py-5 text-grey-500 text-sm font-semibold w-2/5">
                            Password
                        </div>

                        <div class="px-4 w-2/5 text-grey-700">********</div>

                        <div class="text-right w-1/5">
                            <feather-wrapper icon="chevron-right" />
                        </div>
                    </router-link>
                </div>

                <h2 class="flex items-center text-lg mt-12">
                    <feather-wrapper icon="mail" class="mr-2" />

                    Email Settings
                </h2>

                <div class="w-full mt-6 bg-white shadow-key rounded-lg flex-1">
                    <div
                        class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b last:border-b-0 first:rounded-t-lg last:rounded-b-lg cursor-pointer flex items-center"
                        @click="toggleMarketing()"
                    >
                        <div
                            class="pl-6 py-5 text-grey-500 text-sm font-semibold w-3/5"
                            style="width: 160px"
                        >
                            Marketing
                        </div>

                        <div v-if="!accountData.marketing" class="p-4 w-3/5">
                            <strong>Opted out of marketing -</strong> click to opt in
                        </div>

                        <div v-else class="p-4">
                            <strong>Opted into marketing -</strong> click to opt out
                        </div>
                    </div>
                </div>

                <h2 class="flex items-center text-lg mt-12">
                    <feather-wrapper icon="shield" class="mr-2" />

                    Your Data Privacy
                </h2>

                <div class="w-full mt-6 bg-white shadow-key rounded-lg flex-1">
                    <router-link
                        class="tr--hover-animate border-grey-200 hover:shadow-key-hover border-b last:border-b-0 first:rounded-t-lg last:rounded-b-lg cursor-pointer flex items-center justify-between"
                        :to="{ name: 'start-account-deletion' }"
                    >
                        <div class="pl-6 py-5 text-md text-center text-rose-500">
                            Permanently delete account
                        </div>

                        <div>
                            <a
                                class="block pr-6 py-5 text-right flex"
                                href="https://support.livingdna.com/hc/en-us/articles/360012499411-Who-do-I-speak-to-about-deleting-my-account-data-"
                                target="_blank"
                            >
                                <Feather
                                    type="chevron-right"
                                    class="h-5 w-5 fill-current text-rose-500 m-auto"
                                />
                            </a>
                        </div>
                    </router-link>
                </div>

                <h2 class="flex items-center text-lg mt-12">
                    <feather-wrapper icon="help-circle" class="mr-2" />

                    FAQ
                </h2>

                <table class="w-full mt-6 bg-white shadow-key rounded-lg flex-1">
                    <f-a-q
                        title="How do I delete my account data?"
                        link="https://support.livingdna.com/hc/en-us/articles/360012499411-Who-do-I-speak-to-about-deleting-my-account-data-"
                    />

                    <f-a-q
                        title="How do I change my portal email address?"
                        link="https://support.livingdna.com/hc/en-us/articles/360012527511-How-do-I-change-my-portal-email-address-"
                    />

                    <f-a-q
                        title="I've forgotten my password."
                        link="https://support.livingdna.com/hc/en-us/articles/360012498691-Help-I-have-forgotten-my-password-"
                    />
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { setDefaultProfile, setTest } from "@/mixins/profileSwitching";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import FAQ from "@/components/accounts/View/FAQ.vue";

export default {
    name: "ProfileView",

    mixins: [setDefaultProfile, setTest],

    components: {
        FeatherWrapper,
        FAQ,
    },

    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },

    computed: {
        ...mapGetters(["accountData"]),
    },

    methods: {
        openLink(link) {
            window.open(link);
        },
        toggleMarketing() {
            this.$store.dispatch("setMarketingPreference", !this.accountData.marketing);
        },
    },

    async created() {
        await this.$store.dispatch("getAccountData");
    },
};
</script>
<style>
.password-button {
    min-width: 100% !important;
}
</style>
