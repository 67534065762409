import { defineStore } from "pinia";
import Autosomal, { createMapItem, flattenChildren } from "@/services/autosomal";
import store from "@/store/store";
import axios from "axios";
import endpoints from "@/endpoints";

export const useSharingStore = defineStore("sharing", {
    state() {
        return {
            name: "",
            photo: "",
            autosomalResult: {},
            motherlineResult: {},
            fatherlineResult: {},
            vikingResult: {},
            archaicResult: {},
        };
    },
    getters: {
        regions(state) {
            return flattenChildren(state.autosomalResult);
        },
        subregions() {
            return flattenChildren(this.regions);
        },
        combinedRegionsAndSubregions() {
            return [...this.regions, ...this.subregions];
        },
        mapItems() {
            return this.combinedRegionsAndSubregions.map(createMapItem);
        },
    },
    actions: {
        async getPublicProfile(uuid) {
            const {
                data: { name, photo, autosomal, motherline, fatherline, viking, archaic },
            } = await axios.get(endpoints.publicProfile(uuid));
            const { speculative } = autosomal.testData.autosomal.data.current;

            this.name = name;
            this.photo = photo;
            this.autosomalResult = speculative;
            this.motherlineResult = motherline?.testData.mtdna.results;
            this.fatherlineResult = fatherline?.testData.ydna.results;
            this.vikingResult = viking;
            this.archaicResult = archaic;
            return "done";
        },
    },
});

export const useAutosomalStore = defineStore("autosomal", {
    state() {
        return {
            result: {},
            previousResults: {},
        };
    },
    getters: {
        regions(state) {
            return flattenChildren(state.result.regions);
        },
        subregions() {
            return flattenChildren(this.regions);
        },
        combinedRegionsAndSubregions() {
            return [...this.regions, ...this.subregions];
        },
        mapItems() {
            return this.combinedRegionsAndSubregions.map(createMapItem);
        },
    },
    actions: {
        async getAutosomalResults(barcode) {
            try {
                const results = await Autosomal.getResults({
                    query: "speculative",
                    barcode,
                });

                const activeResult = results.testData.autosomal.active_result;
                const { speculative, ...rest } = results.testData.autosomal.data.current;

                this.result = {
                    ...rest,
                    activeResult,
                    regions: speculative,
                };

                this.previousVersions = results.testData.autosomal.results;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async getPreviousVersions() {
            const { data } = await axios.get(
                endpoints.getPreviousAutosomalResult(store.getters.profile.barcode) + "?all=true"
            );
            this.previousResults = data;
        },
        async startUpdate() {
            await axios.post(endpoints.upgradeTest(store.getters.profile.barcode, "autosomal"));
        },
    },
});

function getShortValueFromType(type) {
    const shortValue = {
        motherline: "mtdna",
        fatherline: "ydna",
    }[type];
    if (!shortValue) {
        throw Error(`Could not determine short value for ${type}`);
    }
    return shortValue;
}

const parentalStoreOptions = (type) => ({
    state() {
        return {
            result: {},
            activeRegion: "",
        };
    },
    actions: {
        async getResult(barcode) {
            const { data } = await axios.get(`${endpoints.tests}/${barcode}/${type}`);
            this.result = data.testData[getShortValueFromType(type)].results;
        },
    },
});

export const useMotherlineStore = defineStore(
    "motherlineStore",
    parentalStoreOptions("motherline")
);

export const useFatherlineStore = defineStore(
    "fatherlineStore",
    parentalStoreOptions("fatherline")
);
