<template id="app">
    <div class="relative h-full bg-white">
        <router-view :key="store.getters.profile.barcode" />
    </div>
</template>

<script>
// import NewPortalModal from "@/components/modals/NewPortalModal.vue";
import store from "@/store/store";

export default {
    name: "App",
    // components: {
    //     NewPortalModal,
    // },
    setup() {
        return { store };
    },

    mounted() {
        this.storeSub = store.subscribeAction({
            after: async ({ type }) => {
                if (type === "SET_KIT_TESTS") {
                    await this.$store.dispatch("CLEAR_AUTOSOMAL_MODULE");
                    await this.$store.dispatch("CLEAR_PRODUCTS_MODULE");
                    await this.$store.dispatch("CLEAR_MATERNAL_MODULE");
                    await this.$store.dispatch("CLEAR_PATERNAL_MODULE");
                }
            },
        });
    },
    beforeDestroy() {
        this.storeSub();
    },
};
</script>
