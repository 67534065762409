<template>
    <div class="w-full h-full flex flex-col items-center relative">
        <div class="cover-image"></div>
        <div class="absolute flex items-center top-0 left-0 bg-white p-6 right-0">
            <feather class="h-5 w-5 text-cobalt-500 cursor-pointer mr-2" type="chevron-left" />
            <router-link to="/dashboard">
                <p class="text-cobalt-500 font-semibold leading-normal my-0">Back to Dashboard</p>
            </router-link>
        </div>
        <div class="m-auto bg-white rounded shadow-drama z-10 max-w-lg">
            <div
                class="bg-cobalt-50 px-6 py-3 rounded-t flex items-center text-cobalt-500 text-sm font-semibold"
            >
                <feather type="info" class="h-5 w-5 fill-current mr-2" />
                Family Matching
            </div>
            <div class="p-6">
                <p class="mt-0">
                    We are undertaking system improvements. This will allow us to offer new matching
                    tools, including chromsome browser. Your match list will be back soon and we'll
                    let you know when new features are available.
                </p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.cover-image {
    background-image: url("@/assets/images/fn-status.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    filter: blur(3px);
}
</style>
