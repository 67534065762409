<template>
    <div>
        <h3 class="text-denim-700 text-2xl font-normal text-center">Log in</h3>
        <form @submit.prevent="logIn" class="mt-8">
            <div>
                <label
                    for="email"
                    class="text-grey-700 font-semibold block w-full"
                    style="font-size: 14px"
                >
                    Email address
                </label>
                <input
                    v-model="username"
                    type="email"
                    id="email"
                    class="mt-1 border bg-grey-50 border-grey-500 rounded-sm w-full py-2 px-4 focus:outline-none focus:border-cobalt-500"
                />
            </div>
            <div class="mt-2">
                <PasswordInput v-model="password" />
            </div>
            <div
                class="text-sm mt-2 text-rose-500"
                v-if="loginStatus.meta && this.loginStatus.meta[`login.${this.loginStatus.value}`]"
            >
                {{ this.loginStatus.meta[`login.${this.loginStatus.value}`].message }}
            </div>
            <button
                :class="determineLoadingState"
                class="mt-4 bg-cobalt-500 rounded-sm w-full py-2 px-4 text-white font-semibold test-shadow"
            >
                Log in
            </button>
        </form>
        <div class="mt-6 text-center">
            Don't have an account?
            <router-link to="/signup" class="font-semibold text-cobalt-500">Sign up</router-link>
        </div>
    </div>
</template>

<script>
import { Machine, interpret } from "xstate";
import PasswordInput from "@/components/inputs/PasswordInput/PasswordInput.vue";
import { mapGetters } from "vuex";

const loginMachine = Machine({
    id: "login",
    initial: "idle",
    states: {
        idle: {
            on: { SUBMIT_LOGIN: "attemptingLogin" },
        },
        attemptingLogin: {
            on: {
                SUCCESS: "loginSucceeded",
                UNVERIFIED_ACCOUNT: "unverifiedAccount",
                INCORRECT_PASSWORD: "incorrectPassword",
                ACCOUNT_MARKED_FOR_DELETION: "accountMarkedForDeletion",
                UNSPECIFIED_ERROR: "unspecifiedError",
            },
        },
        loginSucceeded: {
            type: "final",
        },
        unverifiedAccount: {
            on: { SUBMIT_LOGIN: "attemptingLogin" },
            meta: {
                message:
                    "Your account is not yet verified. Please follow the link that we've just sent to your email.",
            },
        },
        incorrectPassword: {
            on: { SUBMIT_LOGIN: "attemptingLogin" },
            meta: {
                message:
                    "The email address or password you entered is not correct. Please try again or Sign Up below.",
            },
        },
        accountMarkedForDeletion: {
            on: { SUBMIT_LOGIN: "attemptingLogin" },
            meta: {
                message:
                    "You have requested deletion of an account with this email address. Please contact us",
            },
        },
        unspecifiedError: {
            on: { SUBMIT_LOGIN: "attemptingLogin" },
            meta: {
                message: "Something went wrong while logging in",
            },
        },
    },
});

export default {
    components: { PasswordInput },
    data() {
        return {
            username: "",
            password: "",
            loginService: interpret(loginMachine),
            loginStatus: loginMachine.initialState,
        };
    },
    computed: {
        ...mapGetters(["profiles"]),
        determineLoadingState() {
            return {
                loading:
                    this.loginStatus.value === "attemptingLogin" ||
                    this.loginStatus.value === "loginSucceeded",
            };
        },
    },
    created() {
        this.loginService
            .onTransition((state) => {
                this.loginStatus = state;
                if (state.value === "loginSucceeded") {
                    console.log(this.profiles);
                    // if (this.profiles.length === 0) {
                    //     this.$parent.$emit("login");
                    //     this.$router.push("/kit-activation/start");
                    // } else {
                    // this.$router.push("/dashboard");
                    // }
                }
            })
            .start();
    },
    methods: {
        send(event) {
            this.loginService.send(event);
        },
        async logIn() {
            this.loginService.send("SUBMIT_LOGIN");
            try {
                const redirect = this.$auth.redirect();
                await this.$auth.login({
                    data: {
                        _username: this.username,
                        _password: this.password,
                    },
                    redirect: {
                        path: redirect ? redirect.from.fullPath : "dashboard",
                        // query: redirect ? redirect.from.query : {},
                    },
                });
                // console.log(login);
                await this.$store.dispatch("getAccountData");
                await this.$store.dispatch("getProfiles");
                this.loginService.send("SUCCESS");
            } catch (err) {
                if (
                    err.response &&
                    err.response.status === 401 &&
                    err.response.data.message === "User account is disabled."
                ) {
                    this.loginService.send("UNVERIFIED_ACCOUNT");
                } else if (err.response && err.response.status === 401) {
                    this.loginService.send("INCORRECT_PASSWORD");
                } else if (err.response && err.response.status === 404) {
                    this.loginService.send("ACCOUNT_MARKED_FOR_DELETION");
                } else {
                    console.log("caught here");
                    this.loginService.send("UNSPECIFIED_ERROR");
                    console.error(err);
                }
            }
        },
    },
};
</script>
