<template>
    <div :class="containerClasses">
        <component v-if="isCustom" :is="icon" :class="classes" />

        <feather v-else :type="icon" :class="classes" />
    </div>
</template>

<script>
import FacebookOfficial from "@/components/feather/svg/facebook-official.vue";
import Female from "@/components/feather/svg/female.vue";
import GoogleOfficial from "@/components/feather/svg/google-official.vue";
import Helix from "@/components/feather/svg/helix.vue";
import Male from "@/components/feather/svg/male.vue";
import TShirt from "@/components/feather/svg/t-shirt.vue";
import VikingShield from "@/components/feather/svg/viking-shield.vue";
import Spear from "./svg/spear.vue";
import MaleFemale from "./svg/male-female.vue";
import Classical from "./svg/classical.vue";
import ClassicalTop from "./svg/classical-top.vue";
import Egyptian from "@/components/feather/svg/egyptian.vue";
import Column from "@/components/feather/svg/column.vue";
import Roman from "@/components/feather/svg/roman.vue";

export default {
    name: "FeatherWrapper",

    components: {
        FacebookOfficial,
        Female,
        GoogleOfficial,
        Helix,
        Male,
        TShirt,
        VikingShield,
        Spear,
        Classical,
        ClassicalTop,
        Egyptian,
        Column,
        Roman,
        MaleFemale,
    },

    props: {
        icon: {
            type: String,
            required: true,
        },
        classes: {
            type: Array,
            default: () => ["w-5 h-5"],
        },
        containerClasses: {
            type: Array,
            default: () => ["inline w-5 h-5"],
        },
    },

    data: () => ({
        customIcons: [
            "facebook-official",
            "female",
            "google-official",
            "helix",
            "male",
            "t-shirt",
            "viking-shield",
            "spear",
            "classical",
            "egyptian",
            "column",
            "roman",
            "classical-top",
            "male-female",
        ],
    }),

    computed: {
        isCustom() {
            return this.customIcons.some((icon) => icon === this.icon);
        },
    },
};
</script>
