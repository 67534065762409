<template>
    <StatusRow>
        <template #icon>
            <div class="rounded-full bg-cobalt-500 h-8 w-8 mr-4 flex">
                <Feather type="more-horizontal" class="h-5 w-5 m-auto text-white" />
            </div>
        </template>
        <template #name>
            {{ step.name }}
            <div class="md:hidden font-normal">
                <div class="text-base text-grey-600">{{ step.description }}</div>
                <div class="flex items-center text-sm py-2">
                    <Feather v-if="step.duration !== '-'" type="clock" class="h-5 w-5 mr-2" />
                    {{ step.duration }}
                </div>
            </div>
        </template>
        <template #description>
            <span class="text-grey-600 text-base">{{ step.description }}</span>
        </template>
        <template #duration>
            {{ step.duration }}
        </template>
    </StatusRow>
</template>

<script>
import StatusRow from "./StatusRow.vue";

export default {
    components: { StatusRow },
    props: {
        step: {
            type: Object,
            required: true,
        },
    },
};
</script>
