import { maxBy, flatMap, omit } from "lodash";
import Autosomal, {
    createMapItem,
    flattenChildren,
    normaliseSubRegionalLevel,
    getPreviousResult,
} from "@/services/autosomal";
import moment from "moment";
import historyModule from "@/store/autosomal/history";
import loadingHelper from "@/services/store";
import { groupBy } from "lodash";

const { loadingState, loadingMutations } = loadingHelper("AUTOSOMAL_COMPLETE");

function deduplicatePanelVersions(results) {
    const deduplicated = [];
    const grouped = groupBy(results, "panel_version");
    for (const group in grouped) {
        if (Object.hasOwn(grouped, group)) {
            deduplicated.push(grouped[group][0]);
        }
    }
    return deduplicated;
}
const initialState = () => ({
    loadingStatus: loadingState.PENDING,
    status: "created",
    summary: {
        autosomal: null,
        mtdna: null,
        ydna: null,
    },
    complete: [],
    standard: [],
    cautious: [],
    previousResult: null,
    allResults: {},
    resultDetails: {},
});

export default {
    state: initialState(),

    getters: {
        autosomalLoadingStatus: (state) => state.loadingStatus,
        autosomalStatus: (state) => state.status,

        autosomalComplete: (state) => state.complete,

        autosomalCompleteRegion: (state, { autosomalComplete }) =>
            flattenChildren(autosomalComplete),

        autosomalCompleteSubRegion: (state, { autosomalCompleteRegion }) =>
            flattenChildren(autosomalCompleteRegion),

        // filter to hide children -- sub-regional
        autosomalPercentageCompleteSubRegion: (state, { autosomalComplete }) =>
            normaliseSubRegionalLevel(autosomalComplete),

        autosomalCompleteMap: (
            state,
            {
                autosomalCompleteSubRegion,
                autosomalCompleteRegion,
                autosomalComplete,
                starterTestMode,
            }
        ) => ({
            data: [
                ...autosomalCompleteSubRegion.map(createMapItem),
                ...autosomalCompleteRegion.map(createMapItem),
                ...autosomalComplete.map(createMapItem).filter((item) => starterTestMode),
            ],
        }),
        autosomalMapSource: () => ({
            id: process.env.VUE_APP_MAP_TILESET_URL,
            type: "vector",
            url: `mapbox://${process.env.VUE_APP_MAP_TILESET_URL}`,
        }),
        autosomalCount: (state, { autosomalCompleteSubRegion, autosomalCompleteRegion }) =>
            autosomalCompleteSubRegion.length + autosomalCompleteRegion.length,
        topAncestryPercentage: (state, { autosomalCompleteRegion, autosomalComplete }) =>
            maxBy(autosomalCompleteRegion, (item) => item.percentage) ||
            maxBy(autosomalComplete, (item) => item.percentage),
        resultDetails: (state) => state.resultDetails,
        currentResultDetails: (state) => state.allResults[0],
        previousResultDetails: (state) => state.allResults[1],
        hasCompletedUpdate: (state, getters) =>
            state.allResults.length > 1 &&
            parseInt(getters.currentResultDetails.panel_version.split(".")[0], 10) > 1,
        getPreviousResult: (state) =>
            state.previousResult
                ? state.previousResult.testData.autosomal.data.current.speculative
                : false,
        getPreviousResultDate: (s, getters) =>
            moment(getters.previousResultDetails.date).format("MMMM D, YYYY"),
        getPreviousResultDateFileFormat: (s, getters) =>
            moment(getters.previousResultDetails.date).format("YYYY-MM-DD"),
        getCurrentResultDate: (s, getters) =>
            moment(getters.currentResultDetails.date).format("MMMM D, YYYY"),
    },
    mutations: {
        ...loadingMutations,
        SET_COMPLETE(state, { testData }) {
            state.allResults = deduplicatePanelVersions(testData.autosomal.results);
            state.complete = testData.autosomal.data.current.speculative;
            state.resultDetails = testData.autosomal.data.current;
        },
        SET_STATUS(state, status) {
            state.status = status;
        },
        setPreviousResult(state, result) {
            state.previousResult = result;
        },

        CLEAR_AUTOSOMAL_MODULE(state) {
            Object.assign(state, initialState());
        },
    },

    actions: {
        async getPreviousAutosomalResult({ commit }, barcode) {
            try {
                const results = await getPreviousResult(barcode);
                commit("setPreviousResult", results.data);
            } catch (e) {
                // throw e;
            }
        },

        async GET_COMPLETE({ commit, state }, { barcode, force }) {
            try {
                if (state.complete.length === 0 || force === true) {
                    commit(loadingState.PENDING);

                    commit(
                        "SET_COMPLETE",
                        await Autosomal.getResults({
                            query: "speculative",
                            barcode,
                        })
                    );

                    commit(loadingState.SUCCESS);
                }
            } catch (err) {
                console.error(err);
                commit(loadingState.FAILED);
            }
        },
        async SET_AUTOSOMAL_STATUS({ commit }, { status }) {
            commit("SET_STATUS", status);
        },

        CLEAR_AUTOSOMAL_MODULE({ commit }) {
            commit("CLEAR_AUTOSOMAL_MODULE");
        },
    },
    modules: {
        historyModule,
    },
};
