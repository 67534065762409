<template>
    <div>
        <Card class="bg-white px-12">
            <div class="flex text-lg">
                <div class="flex w-full md:w-1/2 pb-4">
                    <div class="w-3/4">Ingredient</div>
                    <div class="w-1/4">Dosage</div>
                </div>
                <div class="hidden md:flex w-1/2 pb-4">
                    <div class="w-3/4">Ingredient</div>
                    <div class="w-1/4">Dosage</div>
                </div>
            </div>
            <div class="flex flex-wrap">
                <div
                    :key="supplement.name"
                    v-for="(supplement, index) in supplements"
                    class="w-full md:w-1/2 py-2 flex items-center hover:font-semibold"
                    @click="$emit('supplement-selected', index)"
                >
                    <div
                        class="w-3/4 flex items-center"
                        :class="{ '-ml-8 mr-8': supplement.matched }"
                    >
                        <FeatherWrapper
                            v-if="supplement.used"
                            icon="helix"
                            class="mr-3 text-cobalt-500"
                        />
                        <FeatherWrapper
                            v-else-if="supplement.matched"
                            icon="helix"
                            class="mr-3 text-grey-500"
                        />
                        <button class="text-left md:text-centre hover:font-semibold">
                            {{ supplement.name }}
                        </button>
                    </div>
                    <div class="w-1/4">
                        {{ supplement.used ? supplement.decreased : supplement.regular }}
                    </div>
                </div>
            </div>
        </Card>

        <div class="flex items-center pt-4">
            <div class="flex items-center mr-6">
                <FeatherWrapper icon="helix" class="mr-2 text-grey-500" />
                Based on your DNA you need a normal dose of this supplement
            </div>
            <div class="flex items-center">
                <FeatherWrapper icon="helix" class="mr-2 text-cobalt-500" />
                Based on your DNA you need an increased dose of this supplement
            </div>
        </div>
    </div>
</template>

<script>
import { getVitaminReport } from "@/services/wellbeing/vitlab";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import Card from "@/components/cards/Card.vue";

const supplements = [
    {
        name: "Vitamin A (Acetate)",
        regular: "900 IU",
        decreased: "1500 IU",
        slug: "vitaminA",
        benefit:
            "Vitamin A is required for the proper development and functioning of our eyes, skin, immune system, and many other parts of our bodies.",
        color: "#FFA800",
    },
    {
        name: "Vitamin B1 (Thiamine-HCL)",
        regular: "1.2 mg",
        decreased: "7.5 mg",
        slug: "vitaminB1",
        benefit:
            "Thiamine Hydrochloride is important for the utilization of carbohydrates in the body as well as nerve function and cell growth.",
        color: "#064482",
    },
    {
        name: "Vitamin B2 (Riboflavin-5-Phosphate)",
        regular: "1 mg",
        decreased: "4 mg",
        slug: "vitaminB2",
        benefit:
            "B2 is involved in many processes in the body and is important for normal cell growth. It is required for, but not limited to, proper development of the skin, digestive tract, blood cells and brain function.",
        color: "#FFD230",
    },
    {
        name: "Vitamin B3 (as Niacinamide)",
        regular: "15 mg",
        decreased: "15 mg",
        benefit:
            "B3 is used in the body to maintain healthy cells, improve circulation and the metabolism of fats and sugars. Niacinamide prevents the flushing sensation typically associated with Niacin supplementation.",
        color: "#68902B",
        slug: "vitaminB3",
    },
    {
        name: "Vitamin B5 (Calcium Pantothenate)",
        regular: "5 mg",
        decreased: "5 mg",
        benefit:
            "B5 helps the breakdown of carbohydrates and fats for energy. It supports healthy skin, hair, eyes and liver.",
        color: "#CC005A",
        slug: "vitaminB5",
    },
    {
        name: "Vitamin B6 (Pyridoxal-5-phosphate)",
        regular: "1.5 mg",
        decreased: "5 mg",
        slug: "vitaminB6",
        benefit:
            "B6 is required for the proper function of sugars, fats, and proteins in the body. It is also required for the proper growth and development of the brain, nerves, skin, and many other parts of the body. Pyridoxal 5-Phosphate, or P5P, is the active form of Vitamin B6, the Pyridoxine form of B6 needs to be converted into P5P in the liver.",
        color: "#800080",
    },
    {
        name: "Folate (as L-5-MTHF)",
        regular: "200 mcg",
        decreased: "400 mcg",
        benefit:
            "5-Methyltetrahydrofolate is not affected by gastrointestinal pH or metabolic defects (MTHFR mutations) which assists with its bioavailability and utilization in the body. By choosing 5-Methyltetrahydrofolate you can help to reduce the risk of masking B12 deficiency and help to prevent neural tube defects. Folate is also beneficial for regulating homocysteine and used in the production of key neurotransmitters that influence behaviour and mood.",
        color: "#DE0F3F",
        slug: "folate",
    },
    {
        name: "Vitamin B12 (Hydroxocobalamin)",
        regular: "100 mcg",
        decreased: "200 mcg",
        slug: "vitaminB12",
        benefit:
            "Vitamin B12 is used to metabolize carbohydrates in the body, as well as support nerve function and cell growth. Hydroxocobalamin is used to treat low levels and B12 deficiencies.",
        color: "#283C73",
    },
    {
        name: "Vitamin C (Ascorbic Acid)",
        regular: "150 mcg",
        decreased: "250 mcg",
        slug: "vitaminC",
        benefit:
            "Vitamin C is a powerful antioxidant that boosts the immune system. It is important to get vitamin C from external sources; through additional supplementation it can help protect against additional oxidative stress.",
        color: "#56AEFF",
    },
    {
        name: "Vitamin D (Cholecalciferol)",
        regular: "20 mcg",
        decreased: "37.5 mcg",
        slug: "vitaminD",
        benefit:
            "Vitamin D is known as the sunshine vitamin and it is important for bone development, helps to regulate mood and supports immune system health. Vitamin D as Cholecalciferol is a form the body naturally creates and is highly bioavailable.",
        color: "#8A8841",
    },
    {
        name: "Vitamin E (Succinate)",
        regular: "40.2 mcg",
        decreased: "67 mcg",
        slug: "vitaminE",
        benefit:
            "Vitamin E is an antioxidant that may neutralize free radicals in the body. Succinate is derived from ​the primary form of Vitamin E, ​alpha-tocopherol, by converting the phenol component to esters in order to create a more stable compound.",
        color: "#A668A6",
    },
    {
        name: "Biotin",
        benefit:
            "Biotin is used to convert nutrients from the food we eat into energy in the body. It is also important for hair, skin and nail health. It is found in some foods but beneficial in supplemental form for additional support.",
        color: "#DE0F3F",
        genders: {
            1: {
                regular: "100 mcg",
                decreased: "100 mcg",
            },
            2: {
                regular: "500 mcg",
                decreased: "500 mcg",
            },
        },
        slug: "biotin",
    },
    {
        name: "Zinc (Citrate)",
        regular: "10 mg",
        decreased: "25 mg",
        slug: "zinc",
        benefit:
            "Zinc is an essential micronutrient important in many processes in our bodies. Zinc may enhance wound healing, boost immunity, skin conditions and sexual health.",
        color: "#05ABC4",
    },
    {
        name: "Calcium (Lithothamnium Corallioides Algae)",
        regular: "100 mg",
        decreased: "200 mg",
        slug: "calcium",
        benefit:
            "Lithothamnion corallioides algae is a mineral rich red marine algae. This pure form of calcium has high bioavailability and may be helpful in increasing bone density when taken supplementally.",
        color: "#A52A2A",
    },
    {
        name: "Manganese (Citrate)",
        regular: "2 mg",
        decreased: "2 mg",
        benefit:
            "Manganese is an important trace mineral involved in many bodily functions including; bone health, hormone production and wound healing. It is also involved in the production of enzymes and antioxidants.",
        color: "#5271FF",
        slug: "manganese",
    },
    {
        name: "Chromium (Picolinate)",
        regular: "200 mcg",
        decreased: "200 mcg",
        benefit:
            "Chromium is an essential trace mineral that helps to improve the insulin response and glucose metabolism. Chromium’s blood sugar balancing effects may help to reduce carbohydrate cravings.",
        color: "#29741D",
        slug: "chromium",
    },
    {
        name: "Iodine (Potassium Iodide)",
        regular: "100 mcg",
        decreased: "100 mcg",
        benefit:
            "Iodine is required but cannot be created by the body. It is not naturally present in high amounts in foods. Iodine helps to produce thyroid hormones and can kill fungus, bacteria and other microorganisms.",
        color: "#B3298D",
        slug: "iodine",
    },
    {
        name: "Magnesium (Glycinate)",
        regular: "100 mg",
        decreased: "150 mg",
        slug: "magnesium",
        benefit:
            "Magnesium Glycinate is a highly bioavailable, chelated form of Magnesium. Magnesium is helpful for muscle recovery and relaxation, bone health, mental health and digestive support. Supplementing with magnesium may also be beneficial to aid in sleep quality by helping to support the nervous system.",
        color: "#16A637",
    },
    {
        name: "Selenium (L-Selenomethionine)",
        benefit:
            "Selenium is an antioxidant which helps protect cells from damage and fight free radicals. It may provide cardiovascular support, aid in red blood cell production and help prevent chromosome damage.",
        color: "#FFA52D",
        genders: {
            1: {
                regular: "100 mcg",
                decreased: "100 mcg",
            },
            2: {
                regular: "50 mcg",
                decreased: "50 mcg",
            },
        },
        slug: "selenium",
    },
    {
        name: "Molybdenum (Citrate)",
        regular: "75 mcg",
        decreased: "75 mcg",
        benefit:
            "Molybdenum is an essential trace mineral which aids in the breakdown of harmful substances in the body.",
        color: "#066c92",
        slug: "molybdenum",
    },
    {
        name: "Boron (Citrate)",
        regular: "1 mg",
        decreased: "1 mg",
        benefit:
            "Well known for its bone health benefits, Boron is a trace mineral that has many uses in the body, including, but not limited to; wound healing, estrogen and testosterone production, vitamin d metabolism, and helping to raise the level of antioxidant enzymes and reducing menstrual cramps.",
        color: "#825696",
        slug: "boron",
    },
    {
        name: "Copper (Citrate)",
        regular: "1 mg",
        decreased: "1 mg",
        benefit:
            "Copper is a mineral involved in many processes such as; red blood cell production, immune function and nerve cell health. It is stored in the bones and muscles.",
        color: "#f87a7a",
        slug: "copper",
    },
];

export { supplements };

export default {
    components: { FeatherWrapper, Card },
    data() {
        return {
            supplements,
        };
    },
    props: {
        vitaminReport: {
            type: Object,
            required: true,
        },
    },
    async created() {
        console.log(this.vitaminReport);
        const { calledTraits, reducedTraits } = this.vitaminReport;
        this.supplements = this.supplements.map((supplement) => {
            if (supplement.genders) {
                supplement = {
                    ...supplement,
                    ...supplement.genders[this.$store.getters.profile.gender],
                };
            }
            if (calledTraits.includes(supplement.slug)) {
                supplement.matched = true;
            }
            if (reducedTraits.includes(supplement.slug)) {
                supplement.used = true;
            }
            return supplement;
        });
        // console.log('supplements', this.supplements);
    },
};
</script>
