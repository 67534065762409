<template>
    <div>
        <p>
            You have currently enabled the following account to have Manager access to your profile:
        </p>
        <slot />
        <p>
            You should only give Manager access to someone you know and trust with your DNA data,
            such as a close relative who is helping you with your ancestral research.
        </p>
        <p>
            Manager access gives the indicated account the ability to:
        </p>
        <ul class="list-disc ml-6">
            <li>View your results</li>
            <li>Opt this profile into services such as Family Networks</li>
            <li>Trigger optional updates for this profile</li>
            <li>Purchase upgrades for this profile</li>
            <li>Download your raw DNA data file</li>
        </ul>
    </div>
</template>
