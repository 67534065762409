<template>
    <Overview>
        <template #sidebar>
            <section class="prose">
                <div class="fade-bottom">
                    <img :src="family" class="w-full h-64 object-cover m-0" />
                </div>
                <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                    <h1 class="text-xl">Your fatherline</h1>
                    <div>
                        <p>
                            The Y chromosome is one of a pair of your sex chromosomes. It is passed
                            from father to sons, relatively unchanged which allows us to explore
                            your paternal lineage.
                        </p>

                        <p>
                            As females do not carry a Y chromosome they cannot test their paternal
                            ancestry themselves but could have a relative take a test to understand
                            this part of their heritage.
                        </p>

                        <h3>You and your paternal Haplogroup</h3>

                        <p>
                            By looking at genetic variants we are able to establish patterns of
                            similarity between Y Chromosomes. Like a tree as the trunk grows and
                            becomes stronger, it also branches off in different directions.
                        </p>

                        <p>
                            This means we are able to provide you with a unique code that is used to
                            describe a group of people who all share a common ancestor, known as
                            your paternal haplogroup.
                        </p>

                        <p>
                            Your paternal haplogroup can confirm with a very high degree of
                            confidence whether or not you share a common ancestor.
                        </p>
                    </div>
                    <!--                    <div v-html="" />-->
                </div>
            </section>
        </template>
        <template #desktopContent>
            <HaplogroupCards :result="fatherlineStore.result" type="ydna" />
            <OverviewCard
                :linkTo="{ name: 'fatherline-history' }"
                :title-text="'History of your haplogroup'"
                :description-text="'Discover the history of your haplogroup (and your haplotype if you have one)'"
                link-text="Haplotype history"
                :image="{
                    src: fatherlineHistory,
                    alt: 'Exploring history',
                }"
            />
            <OverviewCard
                :linkTo="{ name: 'fatherline-distribution-map' }"
                :title-text="'Distribution map'"
                :description-text="'See where people who share your haplogroup are living today'"
                link-text="View coverage map"
                :image="{
                    src: covererageMap,
                    alt: 'Coverage map',
                }"
            />
            <OverviewCard
                :linkTo="{ name: 'fatherline-migration-map' }"
                :title-text="'Migration map'"
                :description-text="'Explore the journey your ancestors have taken'"
                link-text="View migration map"
                :image="{
                    src: migrationMap,
                    alt: 'Migration map',
                }"
            />
        </template>
        <template #mobileContent>
            <section>
                <div class="fade-bottom">
                    <img :src="family" class="w-full h-64 object-cover m-0" />
                </div>
                <div class="py-6 px-4 sm:px-6 lg:px-8 prose mx-auto">
                    <h1 class="text-xl">Your fatherline</h1>
                    <div>
                        <p>
                            The Y chromosome is one of a pair of your sex chromosomes. It is passed
                            from father to sons, relatively unchanged which allows us to explore
                            your paternal lineage.
                        </p>

                        <p>
                            As females do not carry a Y chromosome they cannot test their paternal
                            ancestry themselves but could have a relative take a test to understand
                            this part of their heritage.
                        </p>

                        <h3>You and your paternal Haplogroup</h3>

                        <p>
                            By looking at genetic variants we are able to establish patterns of
                            similarity between Y Chromosomes. Like a tree as the trunk grows and
                            becomes stronger, it also branches off in different directions.
                        </p>

                        <p>
                            This means we are able to provide you with a unique code that is used to
                            describe a group of people who all share a common ancestor, known as
                            your paternal haplogroup.
                        </p>

                        <p>
                            Your paternal haplogroup can confirm with a very high degree of
                            confidence whether or not you share a common ancestor.
                        </p>
                    </div>
                    <!--                    <div v-html="" />-->
                </div>
            </section>
        </template>
    </Overview>
</template>
<script setup>
import Overview from "@/components/Overview.vue";
import OverviewCard from "@/components/OverviewCard.vue";
import migrationMap from "@/assets/images/motherline/example-migration-map.png";
import covererageMap from "@/assets/images/fatherline/distribution-map.png";
import fatherlineHistory from "@/assets/images/fatherline/fatherline-history.jpg";
import family from "@/assets/images/fatherline/family.jpg";
import { useFatherlineStore } from "@/store/parentalStore";
import HaplogroupCards from "@/components/ancestry/HaplogroupCards.vue";

const fatherlineStore = useFatherlineStore();
</script>
