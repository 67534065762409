<template>
    <portal to="sidebar">
        <Sidebar>
            <div v-if="loadingStatus === 'loading'">
                <PageLoader class="mt-12">Loading</PageLoader>
            </div>
            <div v-else>
                <div class="fade-bottom">
                    <img
                        :src="vikingResult.poulationGroupCmsInformation.mainImage.urlRaw"
                        alt=""
                        class="fade-bottom"
                    />
                </div>
                <div class="prose py-6 px-4 sm:px-6 lg:px-8">
                    <h1 class="text-xl">{{ vikingResult.poulationGroupCmsInformation.heading }}</h1>
                    <div v-html="vikingResult.poulationGroupCmsInformation.text" />
                </div>
            </div>
        </Sidebar>
    </portal>
    <Map v-if="hasMounted" :map-data="vikingResult.poulationGroupCmsInformation.geoJson" />
    <MobileContent :title="vikingResult.poulationGroupCmsInformation.heading" remove-padding>
        <div class="fade-bottom">
            <img
                :src="vikingResult.poulationGroupCmsInformation.mainImage.urlRaw"
                alt=""
                class="fade-bottom"
            />
        </div>
        <div class="py-6 px-4 sm:px-6 lg:px-8 prose mx-auto">
            <div v-html="vikingResult.poulationGroupCmsInformation.text" />
        </div>
    </MobileContent>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import { getVikingPopulationGroup } from "@/services/vikings";
import Map from "./VikingGeoJsonMap.vue";
import PageLoader from "@/components/PageLoader.vue";
import MobileContent from "@/components/ancestry/MobileContent.vue";

defineProps({ vikingResult: Object });

const hasMounted = ref(false);
const loadingStatus = ref("loading");
const populationGroup = ref({});
onMounted(async () => {
    populationGroup.value = await getVikingPopulationGroup("Population-A");
    loadingStatus.value = "loaded";
    hasMounted.value = true; // if we don't do this then map centering is thrown off by the sidebar
});
</script>
