<template>
    <div class="space-y-1 w-full">
        <label for="email" class="block">
            {{ label }}
            <span v-if="required" class="text-red-500" style="">*</span>
        </label>
        <Field
            style="scroll-margin: 34px"
            :rules="rules"
            :type="type"
            :id="id"
            :name="id"
            class="p-2 rounded bg-grey-50 focus:bg-white focus:outline-none focus:border-blue-500 border border-grey-300 w-full"
            :value="initialValue"
        />
        <ErrorMessage class="text-sm text-red-500" :name="id" />
    </div>
</template>

<script>
import { Field, ErrorMessage } from "vee-validate";
export default {
    components: { Field, ErrorMessage },
    props: {
        type: {
            type: String,
            default: "text",
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default(props) {
                const sanitizedName = props.name.replace(/-/g, " ");
                return `${sanitizedName[0].toUpperCase()}${sanitizedName.slice(1)}`;
            },
        },
        required: {
            type: Boolean,
        },
        initialValue: {
            type: String,
        },
    },
    methods: {
        rules(value) {
            if (this.required) {
                if (!value) {
                    return "This field is required";
                }
                return true;
            }
            return true;
        },
    },
    setup(props) {
        const id = props.name;
        return { id };
    },
};
</script>
