<template>
    <router-link :to="link" class="block h-full">
        <div
            class="text-grey-700 shadow-key hover:shadow-key-hover rounded-lg flex overflow-hidden h-full"
        >
            <div
                v-if="hasImage"
                class="w-1/3 bg-cover fade-right greyscale"
                :style="defaultKeyTakeawayImage"
            ></div>
            <div class="w-2/3 p-4 flex flex-col h-full">
                <p class="pb-4">
                    Your {{ title }} data has produced scientifically inconclusive results.
                </p>
                <router-link
                    :to="link"
                    class="flex items-center text-cobalt-500 font-semibold mt-auto"
                >
                    <div class="mr-2 flex items-center">Learn more</div>
                    <Feather type="arrow-right" class="h-4 w-4 flex-2 flex-none" />
                </router-link>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        trait: {
            required: true,
            type: Object,
        },
    },
    computed: {
        title() {
            return this.trait.traitCmsData.title;
        },
        link() {
            return { name: "wellbeing-report", params: { report: this.trait.traitCmsData.slug } };
        },
        hasImage() {
            return (
                this.trait.traitCmsData.phenotypes[0] &&
                this.trait.traitCmsData.phenotypes[0].keyTakeawayImage
            );
        },
        defaultKeyTakeawayImage() {
            return {
                backgroundImage: `url(${this.trait.traitCmsData.phenotypes[0].keyTakeawayImage[0].urlRaw}`,
            };
        },
    },
};
</script>
