import axios from "axios";
import { Machine, assign, interpret } from "xstate";
import endpoints from "@/endpoints";

async function fetchContacts(barcode) {
    console.log("here");
    const res = await axios.get(endpoints.contacts(barcode));
    return res.data;
}

async function hydrateContacts(contacts, barcode) {
    console.log("hydrating contacts");
    const res = await axios.post(
        endpoints.hydratedContacts(barcode),
        contacts.map((contact) => contact.conversation_with)
    );
    return res.data.matches;
}

const machine = Machine(
    {
        id: "messagingMachine",
        initial: "pending",
        context: {
            barcode: "",
            contacts: [],
            hydratedContacts: {},
        },
        states: {
            pending: {
                on: {
                    FETCH_CONTACTS: {
                        actions: "cacheBarcode",
                        target: "fetchingContacts",
                    },
                },
            },
            fetchingContacts: {
                invoke: {
                    src: (context) => fetchContacts(context.barcode),
                    onDone: [
                        {
                            cond: "noContacts",
                            target: "noContacts",
                        },
                        {
                            actions: "cacheContacts",
                            target: "hydratingContacts",
                        },
                    ],
                    onError: "error",
                },
            },
            hydratingContacts: {
                invoke: {
                    src: (context) => hydrateContacts(context.contacts, context.barcode),
                    onDone: {
                        actions: ["cacheHydratedContacts", "removeContactsThatCantBeHydrated"],
                        target: "checkStillHaveContacts",
                    },
                    onError: "error",
                },
            },
            checkStillHaveContacts: {
                on: {
                    "": [
                        {
                            cond: "noCachedContacts",
                            target: "noCachedContacts",
                        },
                        "idle",
                    ],
                },
            },
            idle: {
                on: {
                    CLEAR: {
                        actions: "clearData",
                    },
                    FETCH_CONTACTS: {
                        actions: "cacheBarcode",
                        target: "fetchingContacts",
                    },
                },
            },
            noContacts: {},
            noCachedContacts: {},
            error: {
                entry: (_, event) => console.error(event.data),
            },
        },
    },
    {
        guards: {
            noContacts: (_, event) => {
                console.log(event);
                console.log("contacts", _);
                return event.data.length === 0;
            },
            noCachedContacts: (_, event) => {
                return _.contacts.length === 0;
            },
        },
        actions: {
            cacheBarcode: assign({
                barcode: (_, event) => {
                    console.log("hello world", event);
                    return event.barcode;
                },
            }),
            cacheContacts: assign({
                contacts: (_, event) => {
                    console.log("caching Contacts", event);
                    return event.data;
                },
            }),
            cacheHydratedContacts: assign({
                hydratedContacts: (_, event) => {
                    console.log("caching hydrated contacts", event);
                    return event.data;
                },
            }),
            removeContactsThatCantBeHydrated: assign({
                contacts: (ctx) =>
                    ctx.contacts.filter(
                        (contact) => ctx.hydratedContacts[contact.conversation_with]
                    ),
            }),
            clearData: assign({
                barcode: "",
                contacts: [],
                hydratedContacts: {},
            }),
        },
    }
);

export const messagingCentreService = interpret(machine).start();
