<template>
    <div class="prose m-auto">
        <div class="flex items-center gap-4">
            <img
                v-if="sharingStore.photo"
                :src="sharingStore.photo"
                alt="Name's story"
                class="h-20 w-20 rounded-full object-cover shadow-lg"
            />
            <h1 class="text-2xl mb-0">{{ sharingStore.name }}'s Ancestry</h1>
        </div>

        <!--        <h3>Your Story</h3>-->
        <p class="font-semibold">
            These are <span>{{ sharingStore.name.split(" ")[0] }}'s ancestry results</span>. This is
            a public summary of their results which they have chosen to share with their friends and
            family
        </p>
        <p>
            Living DNA is a leading genetics company providing a detailed regional and subregion
            view of your ancestry. Using a simple mouth swab and custom built ancestry panels to
            provide detailed ancestry results stored in a secure online portal. Your ancestry
            results combine your autosomal DNA results with your motherline and fatherline (for
            genetic males) results.
        </p>

        <p>
            These results help you to uncover the history of your ancestors to discover the context
            of their migrations up to 10 generations back.
            <span
                >Pin point moments in time where your ancestors lived and discover the regions that
                shape who you have become today.</span
            >
        </p>
        <div class="not-prose">
            <a
                href="#explore"
                class="inline-flex items-center gap-2 rounded-full bg-cobalt-50 text-cobalt-500 px-6 py-2 text-sm font-semibold hover:bg-cobalt-500 hover:text-white transition-colors group"
            >
                <span> Unlock your story </span>
                <FeatherWrapper
                    icon="arrow-right"
                    :container-classes="['h-4 w-4']"
                    :classes="['h-4 w-4 text-cobalt-500 group-hover:text-white']"
                />
            </a>
        </div>
    </div>
</template>
<script setup>
import { useSharingStore } from "@/store/sharing";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

const sharingStore = useSharingStore();
</script>
