<template>
    <div
        :id="product.slug"
        class="overflow-hidden shadow rounded-lg bg-white flex flex-col border border-transparent cursor-pointer"
        :class="{
            'hover:shadow-hover text-grey-700': product.locked,
            'hover:shadow-hover': !product.locked,
            'border border-cobalt-500 shadow-focused': product.highlighted,
        }"
        @click="clicked"
    >
        <!-- <div
            class="md:h-32 h-32 fade-bottom overflow-hidden"
            :class="{ 'opacity-75 card-image-locked': product.locked }"
        >
            <img :src="product.image" :alt="product.title" />
        </div> -->
        <div aria-hidden="true" class="relative">
            <img :src="product.image" alt="" class="h-48 w-full object-cover object-center" />
            <div
                v-if="product.isOnSale"
                class="absolute top-0 right-0 left-0 bottom-0 bg-pacific-500/70 backdrop-blur-sm flex"
            >
                <div class="m-auto text-3xl text-white font-semibold">
                    <slot name="highlight-text" />
                </div>
            </div>
            <div class="absolute left-0 right-0 bottom-0 top-[80%] bg-gradient-to-t from-white" />
        </div>
        <div class="pb-6 pl-6 pr-6 mt-1 flex flex-col flex-1">
            <div class="flex flex-col sm:flex-row sm:items-end my-6">
                <h3
                    class="text-lg flex-1 font-semibold"
                    :class="{ 'text-denim-700': !product.locked, 'text-grey-500': product.locked }"
                >
                    {{ product.title }}
                </h3>

                <div
                    class="flex-initial text-lg font-normal"
                    :class="{ 'text-denim-700': !product.locked, 'text-grey-500': product.locked }"
                >
                    {{ convertToCurrency(priceText, product.currency || "USD") }}
                </div>
            </div>
            <div
                v-html="product.shortDescription"
                class="text-base font-normal text-gray-600 mb-4 flex-1"
            ></div>
            <div
                :class="{ 'text-cobalt-500': !product.locked, 'text-grey-500': product.locked }"
                class="opacity-100 text-base font-semibold"
            >
                <button class="flex items-center" @click="clicked">
                    <feather
                        :type="buttonIcon"
                        :class="{
                            'text-cobalt-500': !product.locked,
                            'text-grey-500': product.locked,
                        }"
                        class="w-5 h-5 mr-2"
                    />
                    <span class="font-semibold flex-1 text-left">{{ buttonText }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { first } from "lodash";

function convertToCurrency(value, type) {
    if (value === 0) {
        return "FREE";
    }
    if (typeof type !== "undefined") {
        return Number(value).toLocaleString(undefined, {
            style: "currency",
            currency: type,
        });
    }
    return Number(0).toLocaleString(undefined, { style: "currency", currency: "USD" });
}

export default {
    name: "product-card",
    props: {
        product: {
            required: true,
            default: {},
        },
        category: {
            required: false,
            default: "Merchandise",
        },
    },
    computed: {
        ...mapGetters(["products"]),
        buttonIcon() {
            if (this.product.locked) {
                return "lock";
            }
            switch (this.category) {
                case "Merchandise":
                    return "shopping-cart";
                case "Digital upgrade":
                    return "zap";
                default:
                    return "box";
            }
        },
        buttonText() {
            switch (this.category) {
                case "Merchandise":
                    if (this.product.locked) {
                        return `Requires ${
                            this.products.find(
                                (p) => p.slug === first(this.product.dependentProducts).slug
                            )?.title
                        }`;
                    }
                    return "Shop";
                case "Digital upgrade":
                    return "Upgrade";
                default:
                    return "Purchase";
            }
        },
        priceText() {
            if (this.product.price && this.product.price.isOnSale) {
                return this.product.price.sale / 100;
            }
            if (this.product.price && this.product.price.base) {
                return this.product.price.base / 100;
            }
            return 0;
        },
    },
    methods: {
        clicked() {
            this.$emit("card-clicked", this.product.slug);
        },
        convertToCurrency,
    },
};
</script>

<style scoped>
.card-image-locked {
    -webkit-filter: grayscale(100%); /*!* Safari 6.0 - 9.0 *!*/
    filter: grayscale(100%);
}
</style>
