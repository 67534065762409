<template>
    <router-link
        :to="{ name: 'wellbeing-report', params: { report: report.traitCmsData.slug } }"
        :key="report.type"
        class="flex pb-4 items-center"
    >
        <div class="w-1/2 text-grey-700">
            {{ report.traitCmsData.title }}
        </div>
        <div class="w-1/2 text-right">
            <ScaleMapper
                v-if="report && report.phenotypeCmsData"
                :level="Number(report.phenotypeCmsData.scalePosition.value)"
                :trait="report.traitCmsData.slug"
                :phenotype="report.totalPhenotypeSlug"
                v-slot="{ primaryColour, secondaryColour, result }"
            >
                <p
                    class="my-0 p-2 inline-block rounded font-semibold text-sm"
                    :style="{ backgroundColor: secondaryColour, color: primaryColour }"
                >
                    {{ result }}
                </p>
            </ScaleMapper>
        </div>
    </router-link>
</template>

<script>
import ScaleMapper from "@/components/Wellbeing/ScaleMapper.vue";

export default {
    components: { ScaleMapper },
    props: {
        report: Object,
    },
};
</script>
