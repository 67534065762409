<template>
    <div class="h-full">
        <Card class="bg-white flex flex-col h-full">
            <p class="mt-0">Get pumped about your results while you wait.</p>
            <button
                @click="open = true"
                class="flex items-center text-cobalt-500 font-semibold mt-auto"
            >
                <Feather type="video" class="h-5 w-5 fill-current mr-2" />
                Watch video
            </button>
        </Card>
        <TransitionRoot as="template" :show="open">
            <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
                <div
                    class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay
                            class="fixed inset-0 bg-grey-500 bg-opacity-75 transition-opacity"
                        />
                    </TransitionChild>

                    <!-- This element is to trick the browser into centering the modal contents. -->
                    <span
                        class="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true"
                        >&#8203;</span
                    >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full "
                        >
                            <div style="padding:62.25% 0 0 0;position:relative;">
                                <iframe
                                    src="https://player.vimeo.com/video/383764462"
                                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                    frameborder="0"
                                    allow="autoplay; fullscreen"
                                    allowfullscreen
                                />
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
        <!-- <modal
            name="wellbeing-results-video"
            height="auto"
            :adaptive="true"
            width="90%"
            :max-width="576"
        >
            <div style="padding:56.25% 0 0 0;position:relative;">
                <iframe
                    src="https://player.vimeo.com/video/383764462"
                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                />
            </div>
        </modal> -->
    </div>
</template>

<script>
import { ref } from "vue";
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";

import Feather from "vue-feather";
import Card from "@/components/cards/Card.vue";

export default {
    components: {
        Feather,
        Card,
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    setup() {
        const open = ref(false);

        return {
            open,
        };
    },
};
</script>
