import axios from "axios";
import endpoints from "@/endpoints";

export function getVitlabDomain() {
    if (process.env.NODE_ENV === "production") {
        return "livingdna.getvitaminlab.com";
    }
    return "livingdna.staging.getvitaminlab.com";
}

export function createVitlabUrl(
    { vitlabString, profile, user, variant },
    domain = getVitlabDomain()
) {
    return `https://${domain}/custom/${vitlabString}?g=${profile.gender}&user_id=${user.id}&u=${
        profile.barcode
    }&product_sku=${variant.sku}&currency=${variant.currency}`;
}

export async function getVitaminReport(barcode) {
    const res = await axios.get(endpoints.vitaminReport(barcode));
    return res.data;
}

/*
* g = gender code, 1 stands for male, 2 stands for female
userid = Living DNA user id (optional)
utm_source = source where link was clicked (optional)
utm_medium = i.e "email" (optional)
utm_campaign = name of campaign (optional)
currency=  available options are eur, gbp, usd (edited)
* */
