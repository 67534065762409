<template>
    <div class="flex flex-col sm:flex-row items-center justify-between">
        <div class="flex items-center">
            <router-link
                :to="{ name: 'edit-avatar', params: { barcode: profile.barcode } }"
                class="mr-4 flex flex-none rounded-full relative focus:outline-none hover:shadow-key-hover focus:shadow-key-hover"
                :class="profile.photo ? '' : 'bg-grey-300'"
                style="height: 4.3rem; width: 4.3rem"
            >
                <img
                    v-if="profile.photo"
                    :src="profile.photo"
                    alt="Your avatar"
                    class="rounded-full"
                />
                <Feather v-else type="camera" class="h-6 w-6 m-auto text-white" />
                <div
                    class="absolute bg-cobalt-500 rounded-full h-8 w-8 flex"
                    style="top: -8px; right: -8px"
                >
                    <Feather v-if="profile.photo" type="camera" class="h-4 w-4 text-white m-auto" />
                    <Feather v-else type="plus" class="h-4 w-4 text-white m-auto" />
                </div>
            </router-link>

            <h1 class="text-2xl text-denim-700 font-normal">{{ profile.name }}'s DNA Profile</h1>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        profile: {
            type: Object,
            required: true,
        },
    },
};
</script>
