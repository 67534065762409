<template>
    <router-link :to="to" class="flex items-center space-x-2">
        <FeatherWrapper :icon="icon" />
        <span class="font-semibold">{{ text }}</span>
    </router-link>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

export default {
    components: { FeatherWrapper },
    props: {
        to: Object,
        text: String,
        icon: String,
    },
};
</script>
