<!--
Wellbeing gets its own NavBar because the behaviour is so different to any other navbar, and adding flags etc. for the behaviour was bound to be a maintenance nightmare
Most of the template is copied from @/components/partials/NavBar.vue, but we add custom behaviour for the denim background when on the supplements page
-->

<template>
    <div class="bg-white h-full">
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select
                @change="navigateTo"
                id="tabs"
                name="tabs"
                class="block w-full focus:ring-cobalt-500 focus:border-cobalt-500 border-grey-300 rounded-md"
            >
                <option
                    v-for="tab in tabs"
                    :key="tab.name"
                    :selected="tab.current"
                    :value="JSON.stringify(tab.to)"
                >
                    {{ tab.name }}
                </option>
            </select>
        </div>
        <div
            class="hidden sm:block h-full"
            :class="[route.name === 'supplements' && false ? 'bg-denim-700' : 'bg-white']"
        >
            <div
                class="h-full"
                :class="
                    route.name === 'supplements' && false ? 'border-denim-800' : 'border-grey-200'
                "
            >
                <nav class="-mb-px flex h-full" aria-label="Tabs">
                    <router-link
                        v-for="tab in tabs"
                        :key="tab.name"
                        :to="tab.to"
                        :aria-current="tab.current ? 'page' : undefined"
                    >
                        <div
                            :class="[
                                route.name === 'supplements' && false
                                    ? tab.current
                                        ? 'text-white bg-denim-800 border-cobalt-500'
                                        : 'text-white border-transparent hover:bg-denim-800'
                                    : tab.current
                                    ? 'border-cobalt-500 text-cobalt-600'
                                    : 'border-transparent text-grey-600 hover:text-grey-700 hover:border-grey-300',
                                'group inline-flex items-center py-4 px-6 border-b-4 font-medium text-sm h-full',
                            ]"
                        >
                            <FeatherWrapper :icon="tab.icon" class="h-5 w-5 -ml-0.5 mr-2" />
                            <span>{{ tab.name }}</span>
                        </div>
                    </router-link>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

const initialTabs = [
    {
        name: "Wellbeing",
        to: { name: "wellbeing" },
        icon: "heart",
    },
    {
        name: "Supplements",
        to: { name: "supplements" },
        icon: "list",
    },
];

export default {
    components: { FeatherWrapper },
    setup() {
        const route = useRoute();
        const router = useRouter();

        console.log(route);

        const getTabs = () =>
            initialTabs.map((tab) => ({
                ...tab,
                current: route.name === tab.to.name,
            }));

        const tabs = ref(getTabs());

        watch(
            () => route.path,
            () => {
                tabs.value = getTabs();
            }
        );

        return {
            tabs,
            route,
            navigateTo: (event) => {
                router.push(JSON.parse(event.target.value));
            },
        };
    },
};
</script>
