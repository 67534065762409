<template>
    <PageLoader v-if="loadingStatus === 'loading'" class="mt-8" />
    <div v-else-if="loadingStatus === 'failed'">Issue loading</div>
    <div v-else>
        <div class="relative lg:h-64">
            <router-link
                to="/ancestry/recent"
                class="absolute backdrop-blur-sm bg-grey-700/40 px-4 py-2 md:py-4 flex items-center z-10 left-0 right-0"
            >
                <feather class="h-5 w-5 text-white cursor-pointer mr-2" type="chevron-left" />
                <p class="text-white font-semibold">Back to results</p>
            </router-link>
            <div
                class="lg:block fade-bottom bg-center bg-cover"
                :style="{ 'background-image': `url(${details.images[0]})`, minHeight: '256px' }"
            />
        </div>

        <div class="px-12 py-6">
            <div class="flex items-center justify-between mb-1">
                <h3 class="text-lg leading-tight font-semibold mr-4 flex-1 text-gray-700">
                    {{ details.title }}
                </h3>
                <p class="my-0 text-gray-400 font-semibold leading-tight text-lg flex-0">
                    {{ percentage }}%
                </p>
            </div>
            <h4 v-if="details.parentTitle" class="text-gray-500 font-normal">
                {{ details.parentTitle }}
            </h4>

            <AutosomalStarterAdvert
                v-if="$store.getters.starterTestMode"
                class="mt-4"
                type="details"
            />

            <div class="prose">
                <div
                    v-if="details.shortDescription"
                    class="recent-map"
                    v-html="details.shortDescription"
                />
                <img
                    v-if="details.images[1]"
                    :src="details.images[1]"
                    :alt="details.title"
                    class="mb-4"
                />
                <div v-if="details.fullDescriptionOne" v-html="details.fullDescriptionOne" />
                <img
                    v-if="details.images[2]"
                    :src="details.images[2]"
                    :alt="details.title"
                    class="mb-4"
                />
                <div v-if="details.fullDescriptionTwo" v-html="details.fullDescriptionTwo" />
                <template v-if="details.images.length > 3">
                    <img
                        v-for="imageUrl in details.images.slice(3)"
                        :src="imageUrl"
                        :alt="details.title"
                        class="mb-4"
                    />
                </template>
                <template v-if="details.citations.length">
                    <h3 class="text-grey-700 font-semibold mb-2">Citations</h3>
                    <ul class="ml-6 list-disc leading-tight mb-4 list-outside">
                        <li v-for="citation in details.citations" :key="citation.title">
                            <a
                                class="text-sm italic link"
                                :href="citation.source"
                                target="_blank"
                                >{{ citation.title }}</a
                            >
                        </li>
                    </ul>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import Autosomal from "@/services/autosomal";
import { watch, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useLoading } from "@/composables/useLoading";
import { useAutosomalStore } from "@/store/ancestry/autosomal";
import store from "@/store/store";
import AutosomalStarterAdvert from "@/components/ancestry/AutosomalStarterAdvert.vue";

const route = useRoute();
const details = ref({});
const { loadingStatus, finishLoading, failLoading, resetLoading } = useLoading();
const autosomalStore = useAutosomalStore();

const percentage = computed(() => {
    const currentRegion = autosomalStore.combinedRegionsAndSubregions.find((item) => {
        return item.groupId === route.params.groupId;
    });
    return currentRegion.percentage;
});

watch(
    () => route.params.groupId,
    (groupId) => {
        resetLoading();
        if (groupId) {
            Autosomal.getHistory({
                barcode: store.getters.profile.barcode,
                groupId,
            })
                .then((res) => {
                    details.value = res.testData[groupId];
                    finishLoading();
                })
                .catch((err) => {
                    debugger;
                });
        }
    },
    { immediate: true }
);
</script>
