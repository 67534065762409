<template>
    <div class="flex flex-col">
        <accordion
            :class="{ 'border-t border-cobalt-50': index > 0 }"
            v-for="(q, index) in questions"
            :key="q.id"
        >
            <template #header>
                <h4 class="text-cobalt-500 font-semibold leading-normal py-6">
                    {{ q.question }}
                </h4>
            </template>
            <div>
                <p v-html="q.answer" class="text-grey-700 font-normal leading-normal my-0 pb-6"></p>
            </div>
        </accordion>
    </div>
</template>

<script>
import Accordion from "@/components/accordion/Accordion.vue";

export default {
    name: "faqs",
    components: {
        Accordion,
    },
    props: {
        questions: {
            default: [],
            required: true,
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped></style>
