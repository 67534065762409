<template>
    <button
        @click="download"
        class="flex items-center gap-2 text-cobalt-500 font-semibold loading-cobalt-500"
        :class="loadingStatus"
    >
        <FeatherWrapper icon="download" />
        <span><slot /></span>
    </button>
</template>

<script setup>
import { getPdf } from "@/components/ancestry/pdfDownloader";
import store from "@/store/store";
import { useLoading } from "@/composables/useLoading";

const props = defineProps({
    type: String,
});

const emit = defineEmits(["error", "clearError"]);

const { loadingStatus, finishLoading, failLoading, resetLoading } = useLoading("loaded");

function download() {
    resetLoading();
    emit("clearError");
    getPdf(store.getters.profile.barcode, props.type)
        .then(finishLoading)
        .catch(() => {
            failLoading("Sorry, we could not download your file ");
            emit("error");
        });
}
</script>
