<template>
    <div>
        <div v-if="isAnyFieldComplete && !isEveryFieldComplete">
            You need to provide a full date, otherwise please leave all date fields as unknown.
        </div>
        <div class="md:flex items-center">
            <div class="mr-6 mb-2 md:mb-0">
                <label
                    :for="`${slug}_month`"
                    class="text-grey-700 font-semibold block w-full text-sm"
                >
                    Month
                </label>
                <select
                    class="form-select px-4 pl-2 pr-12 mt-1 border border-grey-500"
                    :id="`${slug}_month`"
                    v-model="month"
                >
                    <option
                        v-for="month in months"
                        :key="month.value"
                        :value="month.value"
                        v-text="month.name"
                    />
                </select>
            </div>
            <div class="mr-6 mb-2 md:mb-0">
                <label
                    :for="`${slug}_day`"
                    class="text-grey-700 font-semibold block w-full text-sm"
                >
                    Day
                </label>
                <select
                    class="form-select px-4 pl-2 pr-12 mt-1 border border-grey-500"
                    :id="`${slug}_day`"
                    v-model="day"
                >
                    <option
                        v-for="day in days"
                        :key="day.value"
                        :value="day.value"
                        v-text="day.name"
                    />
                </select>
            </div>
            <div>
                <label
                    :for="`${slug}_year`"
                    class="text-grey-700 font-semibold block w-full text-sm"
                >
                    Year
                </label>
                <select
                    class="form-select px-4 pl-2 pr-12 mt-1 border border-grey-500"
                    :id="`${slug}_year`"
                    v-model="year"
                >
                    <option
                        v-for="year in years"
                        :key="year.value"
                        :value="year.value"
                        v-text="year.name"
                    />
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import { days, months, years } from "@/services/research";

const propertiesToWatch = ["day", "month", "year"];

export default {
    data() {
        return {
            days,
            months,
            years,
            day: "",
            month: "",
            year: "",
            date: null,
        };
    },
    props: ["value", "slug"],
    created() {
        this.date = this.value;
        if (this.date === null || this.date === undefined) {
            this.year = "";
            this.month = "";
            this.day = "";
            return;
        }
        const [year, month, day] = this.date.split("-");
        const [strippedDay] = day.split(" ");
        this.year = year;
        this.month = month;
        this.day = strippedDay;
    },
    computed: {
        formattedDate() {
            if (!this.isEveryFieldComplete) {
                return null;
            }
            return {
                date: {
                    date: `${this.year}-${this.month}-${this.day}`,
                    precision: null,
                },
            };
        },
        isEveryFieldComplete() {
            return !this.emptyFields;
        },
        isAnyFieldComplete() {
            return !!this.completeFields;
        },
        emptyFields() {
            return propertiesToWatch.find(property => this[property] === "");
        },
        completeFields() {
            return propertiesToWatch.find(property => this[property] !== "");
        },
    },
    watch: {
        formattedDate: {
            immediate: true,
            handler(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>
