<template>
    <RightImageCard :image="{ src: image }" image-class="w-48 ml-auto">
        <template v-slot:body>
            <div class="w-full md:w-3/5 p-6 flex flex-col">
                <h3 class="text-base text-denim-700 mb-2">Get the full story</h3>
                <div class="max-w-sm mb-4">
                    Reveal all of your personalized recommendations for food, vitamins, exercises
                    and more based on your genetics and goals.
                </div>
                <!--                <div class="text-rose-500 text-2xl mt-4 mb-6 flex items-center">-->
                <!--                    £39-->
                <!--                    <div class="text-grey-500 line-through text-base ml-2">£49</div>-->
                <!--                </div>-->
                <DashboardLinkWithIcon
                    :to="{ name: 'product', params: { slug: 'Wellbeing-Upgrade' } }"
                    link-text="Upgrade to Wellbeing"
                    icon="shopping-cart"
                    class="mt-auto"
                />
            </div>
        </template>
    </RightImageCard>
</template>

<script>
import tallImage from "@/assets/images/wellbeing/lemons-tan-tall.png";
import wideImage from "@/assets/images/wellbeing/lemons-tan-wide.png";
import RightImageCard from "@/components/cards/RightImageCard.vue";
import DashboardLinkWithIcon from "@/components/utilities/DashboardLinkWithIcon.vue";
import Feather from "vue-feather";

export default {
    components: { RightImageCard, Feather, DashboardLinkWithIcon },
    props: {
        imageType: String,
    },
    computed: {
        image() {
            if (this.imageType === "wide") {
                return wideImage;
            }
            return tallImage;
        },
    },
};
</script>
