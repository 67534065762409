<template>
    <PageLoader v-if="loadingStatus === 'loading'" class="py-12" />
    <div class="h-full w-full" v-else-if="loadingStatus === 'loaded'">
        <portal to="navigation">
            <NavBar :tabs="tabs" />
        </portal>
        <router-view />
    </div>
    <ParentalPageError v-else-if="loadingStatus === 'failed'" type="ydna" />
</template>

<script setup>
import { useFatherlineStore } from "@/store/parentalStore";
import { useLoading } from "@/composables/useLoading";
import PageLoader from "@/components/PageLoader.vue";
import NavBar from "@/components/partials/NavBar.vue";
import ParentalPageError from "@/views/ancestry/ParentalPageError.vue";

const fatherlineStore = useFatherlineStore();
const { loadingStatus, finishLoading, failLoading } = useLoading();

fatherlineStore
    .getResult()
    .then(finishLoading)
    .catch((e) => {
        console.error(e);
        failLoading();
    });

const tabs = [
    {
        name: "Overview",
        to: { path: "/fatherline/overview" },
        icon: "home",
    },
    {
        name: "History",
        to: { path: "/fatherline/history" },
        icon: "list",
    },
    {
        name: "Distribution Map",
        to: { path: "/fatherline/distribution-map" },
        icon: "map-pin",
    },
    {
        name: "Migration Map",
        to: { path: "/fatherline/migration-map" },
        icon: "map",
    },
];
</script>
