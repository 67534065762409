<template>
    <BaseMap class="h-full" v-if="showMap" @map-loaded="mapLoaded" />
</template>

<script setup>
import { ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useAutosomalStore } from "@/store/ancestry/autosomal";
import { calculateOpacity, MapLayer, useMap } from "@/views/ancestry/recent/mapHelpers";
import BaseMap from "@/components/BaseMap.vue";
import store from "@/store/store";

const props = defineProps({
    autosomalResult: Object,
});

const router = useRouter();
const route = useRoute();
const autosomalStore = useAutosomalStore();

const showMap = ref(false);

setTimeout(() => {
    showMap.value = true;
}, 500);

let unwatch;

const mapSource = {
    id: process.env.VUE_APP_MAP_TILESET_URL,
    type: "vector",
    url: `mapbox://${process.env.VUE_APP_MAP_TILESET_URL}`,
};

async function mapLoaded(map) {
    const { boundingBoxMove, createActiveLayer, getFeatures } = useMap(map);

    map.addSource("tests", {
        type: "vector",
        url: mapSource.url,
    });

    const mi = autosomalStore.mapItems;

    autosomalStore.mapItems.forEach((mapElement) => {
        const layer = new MapLayer(
            mapElement.id,
            mapElement.properties.colour,
            mapElement.properties.code,
            store.getters.starterTestMode,
            calculateOpacity(
                typeof mapElement.properties.percentage !== "number"
                    ? 100
                    : mapElement.properties.percentage
            ),
            mapSource
        );
        map.addLayer(layer);

        map.on("click", mapElement.id, ({ features }) => {
            const [feature] = features;

            router.push({
                name: "recent-map",
                params: {
                    groupId: feature.layer.id,
                },
            });
        });
    });

    setTimeout(() => {
        const allFeatures = getFeatures();
        unwatch = watch(
            () => route.params.groupId,
            (groupId) => {
                if (groupId && !store.getters.starterTestMode) {
                    createActiveLayer(groupId);
                } else {
                    boundingBoxMove(allFeatures, 100);
                }
            },
            {
                immediate: true,
            }
        );
    }, 500);
}
</script>
