<template>
    <div>
        <div class="flex justify-between items-center">
            <h2 class="text-base text-grey-600 font-normal mb-2">{{ sectionTitle }}</h2>
            <div @click="openModel()" class="mr-6 text-grey-600 cursor-pointer">
                <Feather type="info" class="h-5 w-5" />
            </div>
        </div>
        <div class="bg-white rounded shadow-key">
            <RelativesListItem
                v-for="(relative, index) in relatives"
                :key="relative.id"
                :relative="relative"
                :hasMessage="relative.unreadMessage"
                :class="{ 'border-b': index !== relatives.length - 1 }"
            />
        </div>
    </div>
</template>

<script>
import RelativesListItem from "@/views/FamilyNetworks/FamilyMatching/ListView/RelativesListItem";
import { useModal } from "@/components/modals/FamilyMatchingDegreeDescription.vue";

export default {
    components: {
        RelativesListItem,
    },
    props: {
        degree: {
            type: Number,
            required: true,
        },
        relatives: {
            type: Array,
            required: true,
        },
    },
    computed: {
        relationshipDescription() {
            return {
                1: "immediate family",
                2: "2nd degree matches",
                3: "3rd degree matches",
                4: "4th degree matches",
                5: "5th degree matches",
                6: "6th degree matches",
                7: "7th degree matches",
                8: "8th degree matches",
                9: "9th degree matches",
            }[this.degree];
        },
        sectionTitle() {
            return `${this.$store.getters.firstName}'s ${this.relationshipDescription}`;
        },
    },
    data: () => ({
        diagramMap: {},
    }),
    methods: {
        openModel() {
            this.$emit("degreeSelected", this.degree);
            const { open } = useModal();
            open();
        },
    },
};
</script>
