<template>
    <button class="flex pb-4 items-center px-4 group">
        <div class="w-2/3 text-grey-700 group-hover:font-semibold">
            <slot name="name" />
        </div>
        <div class="text-right w-1/3">
            <slot name="status" v-bind:colours="colours" />
        </div>
    </button>
</template>

<script>
export default {
    props: ["status"],
    computed: {
        colours() {
            console.log(`Getting colours for ${this.status}`);
            return {
                activated: {
                    bgClass: "bg-emerald-50",
                    textClass: "text-emerald-500",
                },
                waiting: {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                processing: {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                received: {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                genotyping: {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                updating: {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                completed: {
                    bgClass: "bg-emerald-50",
                    textClass: "text-emerald-500",
                },
                testing: {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                cancelled: {
                    bgClass: "bg-rose-50",
                    textClass: "text-rose-500",
                },
                "cancelled failed": {
                    bgClass: "bg-rose-50",
                    textClass: "text-rose-500",
                },
                "testing failed": {
                    bgClass: "bg-rose-50",
                    textClass: "text-rose-500",
                },
                "processing failed": {
                    bgClass: "bg-rose-50",
                    textClass: "text-rose-500",
                },
                uploaded: {
                    bgClass: "bg-emerald-50",
                    textClass: "text-emerald-500",
                },
                "n/a": {
                    bgClass: "bg-grey-50",
                    textClass: "text-grey-700",
                },
                fallback: {
                    bgClass: "bg-copper-500",
                    textClass: "text-white",
                },
                pending: {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                added: {
                    bgClass: "bg-emerald-50",
                    textClass: "text-emerald-500",
                },
                "en route": {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                "not available": {
                    bgClass: "bg-grey-50",
                    textClass: "text-grey-600",
                },
                "opted in": {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                "not opted in": {
                    bgClass: "bg-grey-50",
                    textClass: "text-grey-600",
                },
                "not added": {
                    bgClass: "bg-grey-50",
                    textClass: "text-grey-600",
                },
                "finding matches": {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                "matching failed": {
                    bgClass: "bg-rose-50",
                    textClass: "text-rose-500",
                },
                active: {
                    bgClass: "bg-emerald-50",
                    textClass: "text-emerald-500",
                },
                expired: {
                    bgClass: "bg-rose-50",
                    textClass: "text-rose-500",
                },
                "expiring soon": {
                    bgClass: "bg-cobalt-50",
                    textClass: "text-cobalt-500",
                },
                "not created": {
                    bgClass: "bg-grey-50",
                    textClass: "text-grey-600",
                },
                created: {
                    bgClass: "bg-emerald-50",
                    textClass: "text-emerald-500",
                },
            }[this.status ? this.status.toLowerCase() : "fallback"];
        },
    },
};
</script>
