<template>
    <div class="lg:flex justify-between">
        <div class="relative lg:inline-block" style="min-width: 350px">
            <label for="search" class="sr-only">Search by initials or display name</label>
            <input
                id="search"
                type="text"
                v-model="searchQuery"
                class="py-2 px-3 bg-white border border-grey-300 rounded w-full lg:w-100 focus:outline-none focus:border-cobalt-500"
                placeholder="Search by initials or display name"
            />
            <div class="absolute top-0 right-0 bottom-0 flex mr-3">
                <Feather type="search" class="h-5 w-5 my-auto fill-current text-grey-300" />
            </div>
        </div>

        <div class="sm:flex space-x-4">
            <div class="flex items-center mt-2 lg:mt-0">
                <label for="sortBy" class="text-sm flex-none font-semibold mr-2 text-grey-600"
                    >Per page</label
                >
                <div class="relative w-full">
                    <select
                        id="perPage"
                        v-model="perPage"
                        class="w-full rounded appearance-none border-sm pl-4 pr-12 py-2 border border-grey-300 bg-white text-grey-600 focus:outline-none focus:border-cobalt-500"
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                    </select>
                    <div class="absolute top-0 right-0 bottom-0 flex mr-3">
                        <Feather
                            type="chevron-down"
                            class="h-4 w-4 my-auto fill-current text-grey-600"
                        />
                    </div>
                </div>
            </div>

            <div class="flex items-center mt-2 lg:mt-0">
                <label for="sortBy" class="text-sm flex-none font-semibold mr-2 text-grey-600"
                    >Sort by</label
                >
                <div class="relative w-full">
                    <select
                        id="sortBy"
                        v-model="sortBy"
                        class="w-full rounded appearance-none border-sm pl-4 pr-12 py-2 border border-grey-300 bg-white text-grey-600 focus:outline-none focus:border-cobalt-500"
                    >
                        <option value="closestMatch">Genetic distance</option>
                        <option value="dateAdded">Newest first</option>
                        <option value="name">Alphabetical</option>
                        <option value="sharedMaps">Has shared map</option>
                    </select>
                    <div class="absolute top-0 right-0 bottom-0 flex mr-3">
                        <Feather
                            type="chevron-down"
                            class="h-4 w-4 my-auto fill-current text-grey-600"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchQuery: this.initialSearchQuery,
            sortBy: this.initialSortBy,
            perPage: "10",
        };
    },
    props: {
        initialSearchQuery: String,
        initialSortBy: String,
    },
    watch: {
        searchQuery(value) {
            this.$emit("searchQueryUpdated", value);
        },
        sortBy: {
            // immediate: true,
            handler(value) {
                this.$emit("sortByUpdated", value);
            },
        },
        perPage: {
            handler(value) {
                this.$emit("perPageUpdated", value);
            },
        },
    },
};
</script>
