<template>
    <div>
        <h3 class="text-denim-700 text-2xl mt-12 flex items-center font-normal mb-6">
            <feather type="users" class="h-5 w-5 mr-4"></feather>
            Family Matching
        </h3>
        <NotOptedIn v-if="familyMatchingStore.optInStatus === 'notOptedIn'" />
        <OptedIn v-else />
    </div>
</template>

<script setup>
import { useFamilyMatchingStore } from "@/store/familyMatching";
import OptedIn from "./OptedIn.vue";
import NotOptedIn from "./NotOptedIn.vue";

const familyMatchingStore = useFamilyMatchingStore();
</script>
