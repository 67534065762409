import { Machine } from "xstate";
import ProfileService from "@/services/profile";

// eslint-disable-next-line import/prefer-default-export
export const familyMatchingSharingMachineFactory = (barcode, field) =>
    Machine({
        initial: "idle",
        states: {
            idle: {
                on: {
                    SET_NEW_PERMISSION_VALUE: "settingNewPermissionValue",
                },
            },
            settingNewPermissionValue: {
                invoke: {
                    src: (ctx, { value }) => {
                        console.log("called");
                        return ProfileService.setFamilyNetworkSharingPermissions({
                            barcode,
                            field,
                            value,
                        });
                    },
                    onDone: "done",
                    onError: {
                        target: "error",
                    },
                },
            },
            done: {
                type: "final",
            },
            error: {},
        },
    });
