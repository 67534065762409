import { createMachine, assign, interpret } from "xstate";
import { getMessages, sendMessage } from "@/services/family-matching";
import { useActor } from "@xstate/vue";
import { getBlocked, blockChannel } from "@/services/family-matching";

const messagingMachine = createMachine(
    {
        id: "messaging-machine",
        initial: "idle",
        context: {
            messages: [],
            loading: true,
            channel: undefined,
            currentMessage: undefined,
            errors: {},
        },
        states: {
            idle: {
                id: "idle",
                on: {
                    UPDATE_MESSAGES: {
                        actions: ["updateMessages"],
                    },
                    SEND_MESSAGE: "sendingMessage",
                    SOCKET_CONNECTED: "checkBlockedStatus",
                    AFTER_UNBLOCK: "gettingMessages",
                    BLOCK: "blockingConversation",
                },
            },
            loadedMessages: {
                on: {
                    UPDATE_MESSAGES: {
                        actions: ["updateMessages"],
                    },
                    SEND_MESSAGE: "sendingMessage",
                    SOCKET_CONNECTED: "checkBlockedStatus",
                    AFTER_UNBLOCK: "gettingMessages",
                    BLOCK: "blockingConversation",
                },
            },
            checkBlockedStatus: {
                entry: ["startLoading", "setChannel"],
                invoke: {
                    src: "checkBlockedStatus",
                    onDone: "gettingMessages",
                    onError: {
                        actions: (context, event) => {
                            console.error(event);
                            if (event.data) {
                                console.error(event.data);
                            }
                        },
                        target: "conversationBlocked",
                    },
                },
            },
            blockingConversation: {
                invoke: {
                    src: "blockChannel",
                    onDone: "conversationBlocked",
                    onError: {
                        actions: () =>
                            console.log("Something went wrong blocking the conversation"),
                    },
                },
            },
            conversationBlocked: {
                on: {
                    UNBLOCK: "unblockingChannel",
                },
            },
            unblockingChannel: {
                invoke: {
                    src: "unblockChannel",
                    onDone: "gettingMessages",
                    onError: {
                        actions: () =>
                            console.log("Something went wrong unblocking the conversation"),
                    },
                },
            },
            gettingMessages: {
                id: "gettingMessages",
                on: {
                    SEND_MESSAGE: "sendingMessage",
                },

                invoke: {
                    src: "getMessages",
                    onDone: {
                        target: "loadedMessages",
                        actions: "saveMessages",
                    },
                    onError: "error",
                },
                exit: ["stopLoading"],
            },
            sendingMessage: {
                on: {
                    UPDATE_MESSAGES: {
                        actions: ["updateMessages"],
                    },
                },
                entry: ["cacheCurrentMessage"],
                invoke: {
                    src: "sendMessage",
                    onDone: "loadedMessages",
                    onError: "error",
                },
            },
            error: {
                id: "error",
                entry: ["cacheError"],
                initial: "determiningErrorStatus",
                states: {
                    determiningErrorStatus: {
                        on: {
                            "": [{ target: "generalError" }],
                        },
                    },
                    generalError: {},
                },
            },
        },
    },
    {
        actions: {
            updateMessages: assign({
                messages: (context, event) => [...context.messages, event.message],
            }),
            cacheCurrentMessage: assign({ currentMessage: (context, event) => event.message }),
            saveMessages: assign({
                messages: (context, event) => {
                    console.log({ event });
                    return event.data;
                },
            }),
            setChannel: assign({ channel: (context, event) => event.channel }),
            startLoading: assign({ loading: (context, event) => true }),
            stopLoading: assign({ loading: (context, event) => false }),
            cacheError: assign({
                errors: (context, event) => {
                    context.errors[event.data.type] = event.data.message;
                    return context.errors;
                },
            }),
        },
        guards: {
            checkForBlockedStatus: (context, event) => {
                return context.errors.hasOwnProperty("CHANNEL_BLOCKED");
            },
        },
        services: {
            getMessages: ({ channel }) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const response = await getMessages(channel);
                        resolve(response.data);
                    } catch (e) {
                        reject(e.body);
                    }
                }),
            sendMessage: ({ channel, currentMessage }) =>
                new Promise(async (resolve, reject) => {
                    try {
                        const messages = await sendMessage(channel, currentMessage);
                        resolve(messages);
                    } catch (e) {
                        reject(e);
                    }
                }),
            checkBlockedStatus: ({ channel }) =>
                new Promise(async (resolve, reject) => {
                    const blocked = await getBlocked(channel);
                    console.log({ blocked });
                    blocked ? reject("Channel is blocked") : resolve();
                }),
            async blockChannel({ channel }) {
                console.log({ channel });
                await blockChannel(channel, true);
                ``;
            },
            async unblockChannel({ channel }) {
                console.log({ channel });
                await blockChannel(channel, false);
            },
        },
    }
);

export const messagingService = interpret(messagingMachine).start();
export const { state, send } = useActor(messagingService);
