<template>
    <form @submit.prevent="submit">
        <ChooseKitUser v-model="whoFor" />
        <div class="mt-4">
            <label for="first-name" class="text-grey-700 font-semibold block w-full text-sm">
                First name
            </label>
            <input
                :disabled="whoFor === 'Myself'"
                v-model="firstName"
                type="text"
                id="first-name"
                class="rounded w-full mt-1 appearance-none border-sm px-4 py-2 border border-grey-300 bg-grey-50 focus:outline-none focus:border-cobalt-500"
            />
        </div>
        <div class="mt-2">
            <label for="last-name" class="text-grey-700 font-semibold block w-full text-sm">
                Last name
            </label>
            <input
                :disabled="whoFor === 'Myself'"
                v-model="lastName"
                type="text"
                id="last-name"
                class="rounded w-full mt-1 appearance-none border-sm px-4 py-2 border border-grey-300 bg-grey-50 focus:outline-none focus:border-cobalt-500"
            />
        </div>
        <div class="mt-2">
            <label class="font-semibold block w-full text-sm mb-1"> Country of birth </label>
            <CountryPicker v-model="country" />
        </div>
        <div class="mt-2">
            <div class="md:flex justify-between">
                <div class="w-full md:w-1/3 mr-8">
                    <label
                        for="biological-sex"
                        class="text-grey-700 font-semibold block w-full text-sm"
                    >
                        Biological Sex
                    </label>
                    <select
                        id="biological-sex"
                        v-model="biologicalSex"
                        class="w-full rounded mt-1 appearance-none border-sm px-4 py-2 border border-grey-300 bg-grey-50"
                    >
                        <option value="select">&ndash;</option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                    </select>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                    <DateInput v-model="dob">
                        Date of birth <span class="text-grey-500">(MM/DD/YYYY)</span>
                    </DateInput>

                    <div
                        v-if="!dob.isValid && dob.year && dob.year > 1880"
                        class="text-xs text-rose-500 mt-1"
                    >
                        Invalid date, please check format
                    </div>
                </div>
            </div>
        </div>
        <component v-model="thirdPartyInformation" v-if="whoFor !== 'Myself'" :is="whoFor" />
        <div class="mt-4">
            <div
                v-if="loading && loadingMessage"
                class="mb-4 bg-cobalt-50 text-cobalt-500 rounded shadow-key p-2"
            >
                {{ loadingMessage }}
            </div>
            <button
                :class="{
                    loading: loading,
                    'bg-grey-200 text-grey-500': !readyToSubmit,
                    'bg-cobalt-500 text-white': readyToSubmit,
                }"
                :disabled="!readyToSubmit || loading"
                class="rounded w-full py-2 px-4 font-semibold"
            >
                {{ buttonText || "Activate Kit" }}
            </button>
        </div>
    </form>
</template>

<script>
import { mapGetters } from "vuex";
import ChooseKitUser from "@/components/activation/ChooseKitUser.vue";
import AnotherAdult from "@/components/activation/KitUserInformation/AnotherAdult.vue";
import MyChild from "@/components/activation/KitUserInformation/MyChild.vue";
import CountryPicker from "@/components/CountryPicker/CountryPicker.vue";
import DateInput from "@/components/inputs/DateInput.vue";

export default {
    components: {
        ChooseKitUser,
        AnotherAdult,
        MyChild,
        CountryPicker,
        DateInput,
    },
    props: {
        buttonText: String,
        loading: Boolean,
        loadingMessage: String,
    },
    data() {
        return {
            country: {},
            whoFor: "Myself",
            firstName: "",
            lastName: "",
            biologicalSex: "select",
            dob: "",
            havePermission: false,
            isParentGuardian: false,
            thirdPartyInformation: {},
        };
    },
    mounted() {
        if (window.FS !== undefined) {
            window.FS.log(`KitUser mounted with whoFor value ${this.whoFor}`);
        }
    },
    watch: {
        whoFor: {
            immediate: true,
            handler(value) {
                console.log("handler");
                if (window.FS !== undefined) {
                    window.FS.log(
                        `whoFor watcher in KitUser triggered with passed value ${value} and state ${this.whoFor}`
                    );
                }
                if (value === "Myself") {
                    this.firstName = this.account.firstName;
                    this.lastName = this.account.lastName;
                    this.thirdPartyInformation = {};
                } else {
                    this.firstName = "";
                    this.lastName = "";
                }
            },
        },
    },
    methods: {
        submit() {
            this.$emit("submitted", {
                whoFor: this.whoFor,
                firstName: this.firstName,
                lastName: this.lastName,
                biologicalSex: this.biologicalSex,
                dob: this.formattedDob,
                countryOfBirth: this.country,
                thirdPartyInformation: this.thirdPartyInformation,
            });
        },
    },
    computed: {
        ...mapGetters(["account"]),
        readyToSubmit() {
            const havePermission =
                this.whoFor === "Myself" || this.thirdPartyInformation.permission;
            console.log({
                fn: this.firstName,
                ln: this.lastName,
                bs: this.biologicalSex !== "select",
                c: this.country,
                // cc: this.country.code,
                dob: this.dobIsValid,
                hp: havePermission,
                hd: this.haveDeath,
            });
            return (
                this.firstName &&
                this.lastName &&
                this.biologicalSex !== "select" &&
                this.country &&
                this.country.code &&
                this.dobIsValid &&
                havePermission &&
                this.haveDeath
            );
        },
        dobIsValid() {
            if (this.whoFor === "Myself" || this.whoFor === "AnotherAdult") {
                if (this.dob.year == new Date().getFullYear() - 18) {
                    if (this.dob.month > new Date().getMonth() + 1) {
                        return false;
                    }
                    if (this.dob.month == new Date().getMonth() + 1) {
                        if (this.dob.day > new Date().getDate()) {
                            return false;
                        }
                    }
                }
                return this.dob.isValid && this.dob.year <= new Date().getFullYear() - 18;
            }
            return this.dob.isValid;
        },
        haveDeath() {
            if (this.whoFor === "Myself") {
                return true;
            }
            return (
                this.thirdPartyInformation.living || this.thirdPartyInformation.dateOfDeathIsValid
            );
        },
        formattedDob() {
            return `${this.dob.year}-${this.dob.month}-${this.dob.day}`;
        },
    },
};
</script>

<style>
/* TODO: move into global css? */
input[disabled="disabled"] {
    @apply border-none;
}
</style>
