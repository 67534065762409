<template>
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.8922 3.19125L49.7241 48.2984C50.0608 48.9636 49.5774 49.75 48.8319 49.75H3.16812C2.42258 49.75 1.93921 48.9636 2.2759 48.2984L25.1078 3.19126C25.4779 2.46007 26.5221 2.46008 26.8922 3.19125Z"
            class="stroke-current"
            stroke-width="4"
        />
        <rect x="12" y="39" width="12" height="2" rx="1" class="fill-current" />
        <rect x="24" y="24" width="12" height="2" rx="1" class="fill-current" />
        <rect x="21" y="31" width="7" height="2" rx="1" class="fill-current" />
        <rect x="18" y="18" width="7" height="2" rx="1" class="fill-current" />
        <rect x="31" y="43" width="7" height="2" rx="1" class="fill-current" />
    </svg>
</template>
