<template>
    <div class="accordion w-full">
        <slot name="full-header" :toggle="toggle" :rotate="show">
            <div class="accordion-button w-full flex justify-between items-start" @click="toggle">
                <slot name="header">HINT</slot>
                <div
                    class="text-2xl text-cobalt-500 font-semibold leading-normal cursor-pointer pt-4 mt-1"
                >
                    <div class="header-icon" :class="{ rotate: show }">
                        <i class="icon-chevron-down"></i>
                    </div>
                </div>
            </div>
        </slot>
        <transition
            name="accordion"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
        >
            <div class="body" v-show="show">
                <div class="body-inner">
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "accordion",
    data() {
        return {
            show: false,
        };
    },
    methods: {
        toggle() {
            this.show = !this.show;
        },
        beforeEnter(el) {
            el.style.height = "0";
        },
        enter(el) {
            el.style.height = `${el.scrollHeight}px`;
        },
        beforeLeave(el) {
            el.style.height = `${el.scrollHeight}px`;
        },
        leave(el) {
            el.style.height = "0";
        },
    },
};
</script>

<style lang="scss">
.accordion {
    /*max-width: 400px;*/
    /*margin-bottom: 20px;*/
    /*border-radius: 6px;*/
}

.accordion .header {
    height: 40px;
    /*line-height: 40px;*/
    padding: 0 40px 0 8px;
    position: relative;
    cursor: pointer;
}

.accordion .header-icon {
    transform: rotate(0deg);
    transition-duration: 0.3s;
}

.accordion .body {
    /*   display: none; */
    overflow: hidden;
    transition: 150ms ease-out;
}

.accordion .body-inner {
    overflow-wrap: break-word;
    /*   white-space: pre-wrap; */
}

.accordion .header-icon.rotate {
    transform: rotate(-180deg);
    transition-duration: 0.3s;
}

.accordion.purple {
    background-color: #8c618d;
}

.accordion.purple .body {
    border-color: #8c618d;
}
</style>
