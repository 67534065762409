<template>
    <div>
        <router-link to="/signup" class="font-semibold text-grey-500 a-grey">&lt; Back</router-link>
        <h3 class="text-denim-700 text-2xl mt-4 font-normal">Terms of Service</h3>
        <div class="relative">
            <div class="text-fade-around">
                <div class="text-sm" style="max-height: 388px; overflow-y: scroll">
                    <p>
                        PLEASE READ THESE TERMS AND CONDITIONS FOR USE OF OUR SITE CAREFULLY BEFORE
                        USING THIS SITE
                    </p>
                    <p><strong>What's in these terms?</strong></p>
                    <p>
                        We set out in this document the terms on which you may use our website,
                        livingdna.com (our site).
                    </p>
                    <p><strong>Who we are and how to contact us</strong></p>
                    <p>
                        livingdna.com is a site operated by Living DNA Limited ("We"). We are
                        registered in England and Wales under company number 10221519
                    </p>
                    <p>
                        and have our registered office at 4 King Square, Bridgwater, Somerset,
                        United Kingdom, TA6 3YF. Our principal place of business is at Unit G1,
                        Frome Business Park, Manor Road, Frome, Somerset, BA11 4FN
                    </p>
                    <p>
                        To contact us, please email help@livingdna.com or visit
                        <a href="https://www.livingdna.com/contact-us"
                            >https://www.livingdna.com/cont...</a
                        >
                        using our site, you confirm that you accept these terms of use and that you
                        agree to comply with them.
                    </p>
                    <p>If you do not agree to these terms, you must not use our site.</p>
                    <p>We recommend that you print a copy of these terms for future reference.</p>
                    <p>
                        There are other terms that may apply to you. These terms of use refer to the
                        following additional terms, which also apply to your use of our site:
                    </p>
                    <p><strong>Our Privacy statement.</strong></p>
                    <p>
                        Our Acceptable Use Policy, which sets out the permitted uses and prohibited
                        uses of our site. When using our site, you must comply with this Acceptable
                        use policy.
                    </p>
                    <p>
                        Our Cookie Policy, which sets out information about the cookies on our site.
                    </p>
                    <p>
                        If you open an account with us, or order services from our site, our Terms
                        of Service will apply.<br /><br />
                    </p>
                    <p><strong>We may make changes to these terms</strong></p>
                    <p>
                        We amend these terms of website use from time to time. Every time you wish
                        to use our site, please check these terms to ensure you understand the terms
                        that apply at that time.
                    </p>
                    <p><strong>We may make changes to our site</strong></p>
                    <p>
                        We may update and change our site from time to time including to reflect
                        changes to our products, our users' needs, our business priorities or for
                        regulatory reasons.
                    </p>
                    <p><strong>We may suspend or withdraw our site</strong></p>
                    <p>
                        Our site is made available free of charge, although we may charge for access
                        to specific parts of the site, and parts of our site are accessible only to
                        customers who maintain an account with us. This is generally people who have
                        subscribed for one or more or of our services.
                    </p>
                    <p>
                        We do not guarantee that our site, or any content on it, will always be
                        available or be uninterrupted. We may suspend or withdraw or restrict the
                        availability of all or any part of our site for business and operational
                        reasons. We will try to give you reasonable notice of any suspension or
                        withdrawal.
                    </p>
                    <p>
                        You are also responsible for ensuring that all persons who access our site
                        through your internet connection are aware of these terms of use and other
                        applicable terms and conditions, and that they comply with them.
                    </p>
                    <p><strong>You must keep your account details safe</strong></p>
                    <p>
                        If you choose, or you are provided with, a user identification code,
                        password or any other piece of information as part of our security
                        procedures, you must treat such information as confidential. You must not
                        disclose it to any third party including anyone to whom you have granted
                        management or collaboration rights to your Living DNA account.
                    </p>
                    <p>
                        We have the right to disable any user identification code or password,
                        whether chosen by you or allocated by us, at any time, if in our reasonable
                        opinion you have failed to comply with any of the provisions of these terms
                        of use.
                    </p>
                    <p>
                        If you know or suspect that anyone other than you knows your user
                        identification code or password, you must promptly notify us at
                        help@livingdna.com<br /><br />
                    </p>
                    <p><strong>How you may use material on our site</strong></p>
                    <p>
                        We are the owner or the licensee of all intellectual property rights in our
                        site, and in the material published on it. Those works are protected by
                        copyright laws and treaties around the world. All such rights are reserved.
                    </p>
                    <p>
                        You may print off one copy, and may download extracts, of any page(s) from
                        our site for your personal use and you may draw the attention of others
                        within your organisation to content posted on our site. You may print more
                        than one copy of your DNA test results or reports for your personal use.
                    </p>
                    <p>
                        You must not modify the paper or digital copies of any materials you have
                        printed off or downloaded in any way, and you must not use any
                        illustrations, photographs, video or audio sequences or any graphics
                        separately from any accompanying text.
                    </p>
                    <p>
                        Our status (and that of any identified contributors) as the authors of
                        content on our site must always be acknowledged.
                    </p>
                    <p>
                        You must not use any part of the content on our site for commercial purposes
                        without obtaining a licence to do so from us or our licensors.
                    </p>
                    <p>
                        If you print off, copy or download any part of our site in breach of these
                        terms of use, your right to use our site will cease immediately and you
                        must, at our option, return or destroy any copies of the materials you have
                        made.
                    </p>
                    <p><strong>Do not rely on information on this site</strong></p>
                    <p>
                        The content on our site is provided for general information only. It is not
                        intended to amount to advice on which you should rely. You must obtain
                        professional or specialist advice before taking, or refraining from, any
                        action on the basis of the content on our site.
                    </p>
                    <p>
                        Although we make reasonable efforts to update the information on our site,
                        we make no representations, warranties or guarantees, whether express or
                        implied, that the content on our site is accurate, complete or up to date,
                        particularly given the speed of scientific development.
                    </p>
                    <p><strong>We are not responsible for websites we link to</strong></p>
                    <p>
                        Where our site contains links to other sites and resources provided by third
                        parties, these links are provided for your information only. Such links
                        should not be interpreted as approval by us of those linked websites or
                        information you may obtain from them.
                    </p>
                    <p>We have no control over the contents of those sites or resources.</p>
                    <p><strong>User-generated content is not approved by us</strong></p>
                    <p>
                        This website may include information and materials uploaded by others users,
                        or communicated by other users using our site. This information and these
                        materials have not been verified or approved by us. The views expressed by
                        other users on our site do not represent our views or values.
                    </p>
                    <p>
                        If you wish to complain about information and materials uploaded by other
                        users please contact us on help@livingdna.com.
                    </p>
                    <p><strong>Our responsibility for loss or damage suffered by you</strong></p>
                    <p>Whether you are a consumer or a business user:</p>
                    <p>
                        We do not exclude or limit in any way our liability to you where it would be
                        unlawful to do so. This includes liability for death or personal injury
                        caused by our negligence or the negligence of our employees, agents or
                        subcontractors and for fraud or fraudulent misrepresentation.
                    </p>
                    <p>
                        Different limitations and exclusions of liability will apply to liability
                        arising as a result of the supply of any products or services to you.
                    </p>
                    <p><strong>Terms of Service.</strong></p>
                    <p>If you are a business user:</p>
                    <p>
                        We exclude all implied conditions, warranties, representations or other
                        terms that may apply to our site or any content on it.
                    </p>
                    <p>
                        We will not be liable to you for any loss or damage, whether in contract,
                        tort (including negligence), breach of statutory duty, or otherwise, even if
                        foreseeable, arising under or in connection with:
                    </p>
                    <p>
                        use of, or inability to use, our site; or use of or reliance on any content
                        displayed on our site.
                    </p>
                    <p>In particular, we will not be liable for:</p>
                    <p>loss of profits, sales, business, or revenue;</p>
                    <p>business interruption;</p>
                    <p>loss of anticipated savings;</p>
                    <p>loss of business opportunity, goodwill or reputation; or</p>
                    <p>any indirect or consequential loss or damage.<br /><br /></p>
                    <p><strong>If you are a consumer user:</strong></p>
                    <p>
                        Please note that we only provide our site for domestic and private use. You
                        agree not to use our site for any commercial or business purposes, and we
                        have no liability to you for any loss of profit, loss of business, business
                        interruption, or loss of business opportunity.
                    </p>
                    <p>
                        If defective digital content that we have supplied, damages a device or
                        digital content belonging to you and this is caused by our failure to use
                        reasonable care and skill, we will either repair the damage or pay you
                        compensation. However, we will not be liable for damage that you could have
                        avoided by following advice to apply an update offered to you free of charge
                        or for damage that was caused by you failing to correctly follow
                        instructions.
                    </p>
                    <p><strong>Uploading content to our site</strong></p>
                    <p>
                        Whenever you make use of a feature that allows you to upload content to our
                        site, or to make contact with other users of our site, you must comply with
                        the content standards set out in our Acceptable Use Policy.
                    </p>
                    <p>
                        You warrant that any such contribution does comply with those standards, and
                        you will be liable to us and indemnify us for any breach of that warranty.
                        This means you will be responsible for any loss or damage we suffer as a
                        result of your breach of warranty.
                    </p>
                    <p>
                        Any content other than genetic data that you upload to our site will be
                        considered non-confidential and non-proprietary. You retain all of your
                        ownership rights in your content, but you are required to grant us and other
                        users of our site a limited licence to use, store and copy that content and
                        to distribute and make it available to third parties.
                    </p>
                    <p>
                        If you upload genetic data to our site you retain all of your ownership
                        rights in your genetic data, and grant us a limited license to use, store
                        and copy that data only to the extent required to deliver the services which
                        you ask us to provide, in accordance with our Terms and to comply with law.
                    </p>
                    <p>
                        We also have the right to disclose your identity to any third party who is
                        claiming that any content posted or uploaded by you to our site constitutes
                        a violation of their intellectual property rights, or of their right to
                        privacy.
                    </p>
                    <p>
                        We have the right to remove any posting you make on our site if, in our
                        opinion, your post does not comply with the content standards set out in our
                        Acceptable Use Policy.
                    </p>
                    <p>You are solely responsible for securing and backing up your content.</p>
                    <p>We are not responsible for viruses and you must not introduce them</p>
                    <p>
                        We take care to protect our site, but cannot guarantee that it will be
                        secure or free from bugs or viruses. Our Privacy Policy explains for the
                        steps that we take to protect the security of personal information,
                        including that which is uploaded to our site.
                    </p>
                    <p>
                        You are responsible for configuring your information technology, computer
                        programmes and platform to access our site. You should use your own virus
                        protection software.
                    </p>
                    <p>
                        You must not misuse our site by knowingly introducing viruses, trojans,
                        worms, logic bombs or other material that is malicious or technologically
                        harmful. You must not attempt to gain unauthorised access to our site, the
                        server on which our site is stored or any server, computer or database
                        connected to our site. You must not attack our site via a denial-of-service
                        attack or a distributed denial-of service attack. By breaching this
                        provision, you would commit a criminal offence under the Computer Misuse Act
                        1990. We will report any such breach to the relevant law enforcement
                        authorities and we will cooperate with those authorities by disclosing your
                        identity to them. In the event of such a breach, your right to use our site
                        will cease immediately.
                    </p>
                    <p><strong>Rules about linking to our site</strong></p>
                    <p>
                        You may link to our home page, provided you do so in a way that is fair and
                        legal and does not damage our reputation or take advantage of it.
                    </p>
                    <p>
                        You must not establish a link in such a way as to suggest any form of
                        association, approval or endorsement on our part where none exists.
                    </p>
                    <p>
                        You must not establish a link to our site in any website that is not owned
                        by you.
                    </p>
                    <p>
                        Our site must not be framed on any other site, nor may you create a link to
                        any part of our site other than the home page save where we provide specific
                        functionality to enable you to do this. .
                    </p>
                    <p>We reserve the right to withdraw linking permission without notice.</p>
                    <p>
                        The website in which you are linking must comply in all respects with the
                        content standards set out in our Acceptable Use Policy.
                    </p>
                    <p>
                        If you wish to link to or make any use of content on our site other than
                        that set out above, please contact help@livingdna.com.
                    </p>
                    <p><strong>Which country's laws apply to any disputes?</strong></p>
                    <p>
                        If you are a consumer, please note that these terms of use, their subject
                        matter and their formation, are governed by English law.
                    </p>
                    <p>
                        You and we both agree that the courts of England and Wales will have
                        exclusive jurisdiction except that:
                    </p>
                    <p>
                        if you are a resident of Northern Ireland you may also bring proceedings in
                        Northern Ireland; and
                    </p>
                    <p>
                        and if you are resident of Scotland, you may also bring proceedings in
                        Scotland; and
                    </p>
                    <p>
                        If you are resident in the European Union, you may also bring proceedings in
                        your home State.
                    </p>
                    <p>
                        If you are a business, these terms of use, their subject matter and their
                        formation (and any non-contractual disputes or claims) are governed by
                        English law. We both agree to the exclusive jurisdiction of the courts of
                        England and Wales.
                    </p>
                </div>
            </div>
        </div>
        <router-link
            to="/signup"
            class="mt-4 bg-cobalt-500 rounded w-full py-2 px-4 a-white block text-white text-center font-semibold"
        >
            Go back
        </router-link>
    </div>
</template>

<style scoped>
body .a-white,
body .a-white:visited {
    @apply text-white !important;
}
body .a-grey,
body .a-grey:visited {
    @apply text-grey-500 !important;
}
.text-fade-around {
    position: relative;
}
.text-fade-around:before {
    content: "";
    @apply absolute w-full h-8;
    top: 0;
    background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.text-fade-around:after {
    content: "";
    @apply absolute w-full h-8;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}
</style>
