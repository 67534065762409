<template>
    <div v-if="loadingStatus === 'loading'">
        <PageLoader class="mt-12">Loading</PageLoader>
    </div>
    <Overview v-else>
        <template #sidebar>
            <div v-if="vikingIntroduction.mainImage">
                <div class="fade-bottom" style="width: 511px; background: #fff">
                    <img :src="vikingIntroduction.mainImage.urlRaw" alt="" class="fade-bottom" />
                </div>
                <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                    <h1 class="text-xl">{{ vikingIntroduction.heading }}</h1>
                    <div v-html="vikingIntroduction.text" />
                </div>
            </div>
        </template>
        <template #desktopContent>
            <VikingOverviewCard
                :linkTo="{ name: 'viking-results' }"
                :title-text="vikingResultsCard.heading"
                :description-text="vikingResultsCard.text"
                link-text="Load your results"
                :image="{
                    src: vikingResultsCard.mainImage.urlRaw,
                    alt: vikingResultsCard.mainImage.title,
                }"
            />
            <VikingOverviewCard
                :linkTo="{ name: 'viking-map' }"
                :title-text="vikingMapCard.heading"
                :description-text="vikingMapCard.text"
                link-text="Load your results"
                :image="{
                    src: vikingMapCard.mainImage.urlRaw,
                    alt: vikingMapCard.mainImage.title,
                }"
            />
        </template>
        <template #mobileContent>
            <div v-if="vikingIntroduction.mainImage">
                <div class="fade-bottom" style="background: #fff">
                    <img :src="vikingIntroduction.mainImage.urlRaw" alt="" class="fade-bottom" />
                </div>
                <div class="py-6 px-4 sm:px-6 lg:px-8 prose mx-auto">
                    <h1 class="text-xl">{{ vikingIntroduction.heading }}</h1>
                    <div v-html="vikingIntroduction.text" />
                </div>
            </div>
        </template>
    </Overview>
    <div v-if="false"></div>
</template>

<script>
import { ref, onMounted } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import VikingOverviewCard from "./VikingsOverviewCard.vue";
import { getVikingIntroduction, getVikingMapCard, getVikingResultsCard } from "@/services/vikings";
import PageLoader from "@/components/PageLoader.vue";
import Overview from "@/components/Overview.vue";

export default {
    props: { vikingResult: Object },
    components: { Sidebar, VikingOverviewCard, PageLoader, Overview },
    setup() {
        const loadingStatus = ref("loading");
        const vikingIntroduction = ref({});
        const vikingResultsCard = ref({});
        const vikingMapCard = ref({});
        onMounted(async () => {
            const [vikingIntroductionResult, vikingMapCardResult, vikingResultCardResult] =
                await Promise.all([
                    getVikingIntroduction(),
                    getVikingMapCard(),
                    getVikingResultsCard(),
                ]);

            vikingIntroduction.value = vikingIntroductionResult;
            vikingMapCard.value = vikingMapCardResult;
            vikingResultsCard.value = vikingResultCardResult;
            loadingStatus.value = "loaded";
            console.log(vikingIntroduction.value);
        });
        return { loadingStatus, vikingIntroduction, vikingResultsCard, vikingMapCard };
    },
};
</script>
