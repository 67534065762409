// import Account from '@/views/account/Account.vue';
import EditAccount from "@/views/account/EditAccount.vue";
import Name from "@/views/account/Fields/Name.vue";
import DeleteAccount from "@/views/account/Delete/DeleteAccount.vue";
import StartDeletion from "@/views/account/Delete/StartDeletion.vue";
import RequestDeletion from "@/views/account/Delete/RequestDeletion.vue";
import ConfirmDeletion from "@/views/account/Delete/ConfirmDeletion.vue";
import ReceivedDeletion from "@/views/account/Delete/ReceivedDeletion.vue";
import Email from "@/views/account/Fields/Email.vue";
import Password from "@/views/account/Fields/Password.vue";

export default [
    {
        path: "/account",
        name: "account",
        component: EditAccount,
        meta: { auth: true },
    },
    {
        path: "/account/name",
        name: "account-name",
        component: Name,
        meta: {
            auth: true,
        },
    },
    {
        path: "/account/email",
        name: "account-email",
        component: Email,
        meta: {
            auth: true,
        },
    },
    {
        path: "/account/password",
        name: "account-password",
        component: Password,
        meta: {
            auth: true,
        },
    },
    {
        path: "delete",
        component: DeleteAccount,
        redirect: "delete/start",
        children: [
            {
                path: "start",
                component: StartDeletion,
                name: "start-account-deletion",
            },
            {
                path: "request",
                component: RequestDeletion,
                name: "request-account-deletion",
            },
            {
                path: "confirm",
                component: ConfirmDeletion,
                name: "confirm-account-deletion",
            },
        ],
    },
];
