<template>
    <div v-if="hasFailed" class="bg-white p-6 rounded shadow">
        Something went wrong loading your family networks data
    </div>
    <div v-else-if="cards.map((card) => card.loading).filter(Boolean).length">
        <div class="bg-white rounded p-6"><PageLoader /></div>
    </div>
    <div v-else>
        <FamilyMatchingNoMatches v-if="!store.getters.familyMatching.length" />
        <template v-else>
            <div
                v-if="$store.getters.starterTestMode"
                class="w-full text-sm //lg:w-[600px] grow-0 p-6 bg-white rounded-lg shadow items-start mb-4"
            >
                <div class="flex items-start gap-6">
                    <div class="bg-cobalt-50 rounded-full p-4 inline-block">
                        <FeatherWrapper icon="helix" class="text-cobalt-500" />
                    </div>
                    <div>
                        <h3 class="text-gray-700 font-semibold text-base mb-1">
                            Unlock chromosome browser
                        </h3>
                        <p class="mb-4">
                            Upgrade to full ancestry to get chromosome browser, a fantastic tool for
                            discovering how you are connected to your matches.
                        </p>
                        <div class="flex items-center gap-2 text-cobalt-500">
                            <Feather type="zap" class="h-5 w-5" />
                            <router-link
                                :to="{ name: 'product', params: { slug: 'autosomal-upgrade' } }"
                                class="font-semibold"
                                >Upgrade here</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>

            <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                <div
                    v-for="card in cards"
                    class="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
                >
                    <dt>
                        <div class="absolute rounded-md bg-cobalt-500 p-3">
                            <FeatherWrapper
                                :icon="card.icon"
                                :classes="['block w-5 h-5 text-white']"
                                :container-classes="['block w-5 h-5']"
                            />
                        </div>
                        <p class="ml-16 truncate text-sm font-medium text-gray-500">
                            {{ card.name }}
                        </p>
                    </dt>
                    <dd class="ml-16 flex items-baseline pb-6 sm:pb-7">
                        <p class="text-2xl font-semibold text-gray-900">
                            {{ card.amount }}
                        </p>
                        <!--                        <p class="ml-2 flex items-baseline text-sm font-semibold text-green-600">-->
                        <!--                            <svg-->
                        <!--                                class="h-5 w-5 flex-shrink-0 self-center text-green-500"-->
                        <!--                                viewBox="0 0 20 20"-->
                        <!--                                fill="currentColor"-->
                        <!--                                aria-hidden="true"-->
                        <!--                            >-->
                        <!--                                <path-->
                        <!--                                    fill-rule="evenodd"-->
                        <!--                                    d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"-->
                        <!--                                    clip-rule="evenodd"-->
                        <!--                                />-->
                        <!--                            </svg>-->
                        <!--                            <span class="sr-only"> Increased by </span>-->
                        <!--                            122-->
                        <!--                        </p>-->
                        <div class="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                            <div class="text-sm">
                                <router-link
                                    :to="card.to"
                                    class="font-semibold text-cobalt-500 hover:text-cobalt-700"
                                    >View all<span class="sr-only">
                                        {{ card.name }}</span
                                    ></router-link
                                >
                            </div>
                        </div>
                    </dd>
                </div>
            </dl>
        </template>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { getNotifications } from "@/services/family-matching";
import store from "@/store/store";
import axios from "axios";
import FamilyMatchingNoMatches from "@/components/Dashboard/FamilyMatching/FamilyMatchingNoMatches.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

const messages = ref({ count: 0, loading: true });
const recentMatches = ref({ count: 0, loading: true });
const hasFailed = ref(false);

getNotifications()
    .then((res) => {
        messages.value = { count: res.length, loading: false };
    })
    .catch((e) => {
        console.error(e);
        hasFailed.value = true;
    });

store
    .dispatch("getFamilyMatchingData", {
        barcode: store.getters.profile.barcode,
        http: axios,
    })
    .then(() => {
        recentMatches.value = {
            count: store.getters.familyMatching.filter(
                (match) => match.version === store.getters.familyMatchingVersion
            ).length,
            loading: false,
        };
    })
    .catch((e) => {
        console.error(e);
        hasFailed.value = true;
    });

const totalMatches = computed(() => {
    return store.getters.familyMatchingCount;
});

const cards = computed(() => [
    {
        name: "Total matches",
        to: { name: "list-view" },
        icon: "users",
        amount: totalMatches,
        loading: false,
    },
    {
        name: "Recent matches",
        to: { name: "list-view", query: { sortBy: "dateAdded" } },
        icon: "plus-circle",
        amount: recentMatches.value.count,
        loading: recentMatches.value.loading,
    },
    {
        name: "Unread messages",
        to: { name: "messaging-centre" },
        icon: "mail",
        amount: messages.value.count,
        loading: messages.value.loading,
    },
]);
</script>
