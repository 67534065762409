<template>
    <div>
        <div v-if="!finishedSaving">
            <h3 class="text-denim-700 text-2xl font-normal text-center">Upload your DNA</h3>
            <p class="text-700 leading-normal text-center">
                We accept DNA from AncestryDNA, 23AndMe, MyHeritage, FamilyTreeDNA (Family Finder),
                Illumina, Gene by Gene, and National Geographic (Geno 2.0).
            </p>
            <p class="text-grey-500 text-center mb-0">Please upload a TXT, CSV, ZIP or GZ file.</p>
        </div>
        <div v-else>
            <h3 class="text-denim-700 text-2xl font-normal text-center">Success!</h3>
            <p class="text-700 leading-normal text-center">
                Your file has been uploaded. It will now be processed by our system to ensure
                compatibility. Once it passes, you will receive your results. If your file is
                incompatible, you will be contacted via email with the next steps.
            </p>
        </div>
        <UploadInput :finished-saving="finishedSaving" @file-uploaded="sendDetailsToServer" />
        <a
            href="https://support.livingdna.com/hc/en-us/articles/360012509652-What-format-does-my-file-need-to-be-in-to-successfully-upload-"
            class="mt-16 font-semibold block text-center"
            target="_blank"
            rel="noopener"
        >
            Need help?
        </a>
    </div>
</template>

<script>
import axios from "axios";
import { isEmpty } from "lodash";
import UploadInput from "@/components/inputs/UploadInput/UploadInput.vue";
import { getPartnerReferral, hasPartnerReferral } from "@/services/uploadReferral";

export default {
    components: { UploadInput },
    data() {
        return {
            finishedSaving: false,
        };
    },
    mounted() {
        if (!this.$store.getters.whoDoesThisDNABelongTo.firstName) {
            this.$router.push({ name: "who-does-this-dna-belong-to" });
        }
    },
    methods: {
        getType(user) {
            const types = {
                Myself: "FOR_ME",
                AnotherAdult: "FOR_ADULT",
                MyChild: "FOR_CHILD",
                Deceased: "FOR_DECEASED",
            };
            if (!isEmpty(user.thirdPartyInformation) && !user.thirdPartyInformation.living) {
                return types.Deceased;
            }
            return types[user.relationship];
        },
        sendDetailsToServer(fileName) {
            const payload = {
                testerDetails: {
                    file: fileName,
                    type: this.getType(this.$store.getters.whoDoesThisDNABelongTo),
                    ...this.$store.getters.whoDoesThisDNABelongTo,
                },
            };
            this.$store
                .dispatch("sendUploadUserDetailsToServer", { payload, http: axios })
                .then((res) => {
                    // this.$gtm.trackEvent({
                    //     event: "upload_complete",
                    //     barcode: res.data.barcode,
                    // });
                    const newPayload = { barcode: res.data.barcode, http: axios };
                    if (hasPartnerReferral()) {
                        const { partnerReferral, referralType, date } = getPartnerReferral();
                        const thirtyDaysAgo = new Date(
                            new Date().setDate(new Date().getDate() - 30)
                        );
                        if (new Date(date) >= thirtyDaysAgo) {
                            Object.assign(newPayload, {
                                partner: {
                                    barcode: res.data.barcode,
                                    referrer: partnerReferral,
                                    referralType,
                                },
                            });
                        }
                    }
                    this.$store.dispatch("completeUpload", newPayload).then(async () => {
                        const profileList = await this.$store.dispatch("getProfiles");
                        await this.$store.dispatch("FORCE_SET_ACCOUNT_TESTS");
                        const newProfile = profileList.find((profile) => {
                            console.log(profile, res, res.data.barcode);
                            return profile.profile.barcode === res.data.barcode;
                        });
                        console.log(newProfile, profileList, res.data);
                        await this.$store.dispatch("setDefaultProfile", newProfile.permission.id);
                        this.$router.push({ name: "upload-family-networks-opt-in" });
                    });
                });
        },
    },
};
</script>
