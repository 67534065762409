import axios from "axios";
import endpoints from "@/endpoints";

export async function getVikingResult(barcode) {
    const { data } = await axios.get(endpoints.vikingResult(barcode));
    return data;
}

const populations = {
    "Population-A": {
        title: "Population A",
        slug: "Population-A",
        id: "71361",
        heading: "Great Britain, Ireland & Iceland",
        text: "<p>The Viking raid on the monastery at Lindisfarne in 793 AD is commonly considered the first of such raids in the British Isles. In the following years they also raided Monkwearmouth-Jarrow Abbey on the coast of Northumbria, and Iona Abbey on the coast of Scotland.</p>\n<p>Vikings began settling on captured land from around 860 AD onwards, mainly in the north and east of England, at the time known as the kingdoms of Anglia, Mercia and Northumbria. In 886 AD King Alfred of the Kingdom of Wessex agreed that the Vikings could rule territories in Northumbria and Anglia. These Viking ruled territories were known as the Danelaw. However, soon after Saxon Kings sought to retake these lands by force.</p>\n<p>Islands to the north and west of Great Britain were settled by Norsemen from Norway. These included Iceland, Orkney, and the Faroe Islands to the north, where the Norsemen became fishermen and farmers. Orkney developed into a regional power that ruled the coastal regions of Scotland. To the west of Great Britain in the Irish Sea the Isle of Man was settled and became a Viking kingdom. Between 879 and 920 AD a colony was founded in Iceland. This became the origin for the colonisation of Greenland, and then North America. The remains of a Viking settlement at L’Anse aux Meadows in Newfoundland have been carbon dated to one millennia ago.</p>\n<p>The Vikings of Scandinavian Scotland mounted the first recorded raids on Ireland in 795 AD. After exploring for opportunities along the Irish coast and inland rivers by 812 AD the Viking raiders had reached as far as the south west. Then, after a pause of several years, the raiding became more intensive. This led to the first permanent occupations in Dublin in 841 AD.</p>",
        mainImage: {
            title: "GB IRL ICE MAP",
            urlHero:
                "https://static.livingdna.com/images/wellbeing/_heroCrop/GB_IRL_ICE_MAP.png?mtime=20220202140512&focal=none",
            urlRaw: "https://static.livingdna.com/images/wellbeing/GB_IRL_ICE_MAP.png?mtime=20220202140512&focal=none",
        },
        subImages: [
            {
                title: "Beach raid",
                urlThumbnail:
                    "https://static.livingdna.com/images/wellbeing/_productThumbnail/beach_raid.jpg?mtime=20220204134843&focal=none",
                urlRaw: "https://static.livingdna.com/images/wellbeing/beach_raid.jpg?mtime=20220204134843&focal=none",
            },
        ],
        geoJson: null,
    },
};

/**
 * @param {string} populationGroup
 */
export async function getVikingPopulationGroup(populationGroup) {
    // const { data } = await axios.get(endpoints.vikingCms(populationGroup));
    const data = populations[populationGroup];
    return data;
}

export async function getVikingIntroduction() {
    const { data } = await axios.get(endpoints.vikingCms("introduction"));
    return data;
}

export async function getVikingUnderstanding() {
    const { data } = await axios.get(endpoints.vikingCms("understanding"));
    return data;
}

export async function getVikingResultsCard() {
    const { data } = await axios.get(endpoints.vikingCms("vikingResultsCard"));
    return data;
}

export async function getVikingMapCard() {
    const { data } = await axios.get(endpoints.vikingCms("vikingMapCard"));
    return data;
}
