<template>
    <div class="bg-white px-4 pt-2 pb-4 md:rounded-lg shadow-key">
        <div class="md:hidden">
            <div v-for="region in rows" :key="region.properties.code" class="mt-5">
                <div class="font-semibold leading-tight mb-1">{{ region.properties.title }}</div>
                <div>Their percentage: {{ region.properties.percentage }}%</div>
                <div>
                    Your percentage:
                    {{ region.comparison ? region.comparison.currentProfile : "0" }}%
                </div>
            </div>
        </div>
        <table class="hidden md:table">
            <thead>
                <tr class="text-lg">
                    <td>Region name</td>
                    <td class="px-4 py-2">Their percentage</td>
                    <td>Your percentage</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="region in rows" :key="region.properties.code" class="mt-3">
                    <td class="pr-6">{{ region.properties.title }}</td>
                    <td class="px-4 py-1">{{ region.properties.percentage }}%</td>
                    <td>{{ region.comparison ? region.comparison.currentProfile : "0" }}%</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        rows: {
            type: Array,
            required: true,
        },
    },
};
</script>
