<template>
    <div v-if="isLoaded" class="bg-white min-h-screen p-6 pt-12">
        <div class="container">
            <TopOfPageBackLink :to="{ name: 'profiles' }"> Profiles </TopOfPageBackLink>
        </div>
        <div class="max-w-2xl mx-auto pt-16">
            <ProfileViewHeader :profile="profile" />
            <div class="mt-24 w-full">
                <div class="flex flex-col-reverse md:flex-row">
                    <div class="w-full">
                        <ProfileViewDetails :profile="profile" />
                        <ProfileViewPermissions :profile="profile" />
                        <ProfileViewSharingPermissions :profile="profile" />
                        <ProfileViewSharing :selected-test="selectedTest" />
                        <ProfileViewActions :profile="profile" :selected-test="selectedTest" />
                    </div>
                    <Overview :selected-profile="selectedTest" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileViewDetails from "./ProfileViewDetails.vue";
import ProfileViewPermissions from "./ProfileViewPermissions.vue";
import Overview from "@/views/profiles/ProfileView/Overview.vue";
import ProfileViewActions from "@/views/profiles/ProfileView/ProfileViewActions.vue";
import ProfileViewSharingPermissions from "@/views/profiles/ProfileView/ProfileViewSharingPermissions.vue";
import ProfileViewHeader from "@/views/profiles/ProfileView/ProfileViewHeader.vue";
import TopOfPageBackLink from "@/components/navigation/TopOfPageBackLink.vue";
import ProfileViewSharing from "@/views/profiles/ProfileView/ProfileViewSharing.vue";

export default {
    name: "ProfileView",

    components: {
        ProfileViewSharing,
        ProfileViewHeader,
        Overview,
        ProfileViewActions,
        ProfileViewDetails,
        ProfileViewPermissions,
        ProfileViewSharingPermissions,
        TopOfPageBackLink,
    },

    data() {
        return {
            isLoaded: false,
        };
    },

    computed: {
        ...mapGetters(["accountTests", "fullProfile"]),

        profile() {
            if (this.fullProfile) {
                return this.fullProfile.profile;
            }
            return null;
        },

        selectedTest() {
            return this.accountTests.find(({ barcode }) => barcode === this.$route.params.barcode);
        },
    },

    async created() {
        await this.$store.dispatch("GET_FULL_PROFILE", this.$route.params.barcode);
        await this.$store.dispatch("getDefaultProfile");
        this.isLoaded = true;
    },
};
</script>
