import Archaic from "@/views/archaic/Archaic.vue";
import Overview from "@/views/archaic/ArchaicOverview.vue";
import ArchaicResults from "@/views/archaic/ArchaicResults.vue";
import ArchaicMap from "@/views/archaic/ArchaicMap.vue";
import ArchaicTraits from "@/views/archaic/ArchaicTraits.vue";
import ArchaicReferences from "@/views/archaic/ArchaicReferences.vue";

export default [
    {
        path: "/archaic",
        component: Archaic,
        redirect: "/archaic/overview",
        children: [
            {
                path: "overview",
                name: "archaic-overview",
                component: Overview,
            },
            {
                path: "results",
                name: "archaic-results",
                component: ArchaicResults,
            },
            {
                path: "maps",
                name: "archaic-map",
                component: ArchaicMap,
            },
            {
                path: "traits",
                name: "archaic-traits",
                component: ArchaicTraits,
            },
            {
                path: "references",
                name: "archaic-references",
                component: ArchaicReferences,
            },
        ],
    },
];
