<template>
    <div>
        loading
    </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
    components: {
        ContentLoader,
    },
};
</script>
