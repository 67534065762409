<template>
    <div class="border-b border-grey-200 pb-5 mx-4 mt-12 md:flex justify-between">
        <h3 class="text-xl font-semibold leading-6 text-grey-700"><slot /></h3>
        <button
            @click="showModal = true"
            class="flex items-center space-x-2 text-cobalt-500 mt-4 md:mt-0"
        >
            <span class="font-semibold">Understanding your results</span>
            <Feather type="arrow-right" class="h-5 w-5" />
        </button>
    </div>
    <Modal :open="showModal" width="3xl" @closed="showModal = false">
        <slot name="info" />
    </Modal>
</template>

<script>
import { ref } from "vue";
import Modal from "@/components/Modal.vue";

export default {
    components: { Modal },
    setup() {
        const showModal = ref(false);
        return { showModal };
    },
};
</script>
