import MyTests from "@/services/my-tests";
import { isEmpty } from "lodash";
import kitTests from "@/store/tests/kitTests";

const initialState = () => ({
    tests: [],
    test: {},
});

export default {
    state: initialState(),
    getters: {
        accountTests: (state) => state.tests,
        selectedTest: (state) => state.test,
    },
    mutations: {
        SET_ACCOUNT_TESTS(state, tests) {
            state.tests = tests;
        },
        SET_TEST(state, test) {
            state.test = test;
        },
        SET_TEST_FROM_TESTS(state, barcode) {
            state.test = state.tests.find((value) => value.barcode === barcode);
        },
        CLEAR_TEST(state) {
            state.test = {};
        },
        CLEAR_TESTS_MODULE(state) {
            Object.assign(state, initialState());
        },
    },
    actions: {
        async SET_ACCOUNT_TESTS({ state, commit }) {
            // if (state.tests.length === 0) {
            const { ancestry } = await MyTests.getTests();
            commit("SET_ACCOUNT_TESTS", ancestry);
            // }
        },
        async FORCE_SET_ACCOUNT_TESTS({ commit }) {
            const { ancestry } = await MyTests.getTests();
            commit("SET_ACCOUNT_TESTS", ancestry);
        },
        async GET_TEST({ state, commit, dispatch }, barcode) {
            if (isEmpty(state.test)) {
                commit("SET_TEST", await MyTests.getTest(barcode));
            }
            if (state.test.barcode !== barcode) {
                await dispatch("CLEAR_TEST");

                commit("SET_TEST", await MyTests.getTest(barcode));
            }
        },
        async SET_TEST_FROM_TESTS({ commit }, barcode) {
            commit("SET_TEST_FROM_TESTS", barcode);
        },
        CLEAR_TEST({ commit }) {
            commit("CLEAR_TEST");
        },
        CLEAR_TESTS_MODULE({ commit }) {
            commit("CLEAR_TESTS_MODULE");
        },
    },
    modules: {
        kitTests,
    },
};
