import { defineStore } from "pinia";
import allRegions from "@/assets/images/store/success/ancestry/all-regions.png";
import percentages from "@/assets/images/store/success/ancestry/percentages.png";
import regions from "@/assets/images/store/success/ancestry/regions.png";
import chromBrowser from "@/assets/images/store/success/ancestry/chrom-browser.png";

const products = {
    "autosomal-upgrade": {
        name: "Autosomal Upgrade",
        postUpgradeText: `
            <p>
                You have successfully upgraded from your continental ancestry view to full autosomal. This
                means you’ve unlocked access to information and precise percentages for all your
                regions and subregions.
            </p>
        `,
        features: [
            {
                title: "All regions",
                description: `
                    <p>Explore all your regions and subregions accounting for the locations of your ancestors. Your autosomal results can be used to piece together the parts of your family history puzzle.</p>
                `,
                image: allRegions,
            },
            {
                title: "Precise percentages",
                description: `
                    <p>See the exact percentage of your DNA we have called as from each region. Look out for those small percentages they could be inherited from a more distant ancestor such as your great great grandparent.</p>
                `,
                image: percentages,
            },
            {
                title: "Region details",
                description: `
                    <p>Get detailed information about all your regions. You can discover the places your ancestors came from and in doing so get an in depth look into your family history.</p>
                `,
                image: regions,
            },
            {
                title: "Chromosome Browser",
                description: `
                    <p>You can now access chromosome browser in Family Networks. Use it to compare DNA segments and learn how you're connected to your matches in even more detail. </p>
                `,
                image: chromBrowser,
            },
        ],
    },
};

export const useOrderStore = defineStore("orders", {
    state() {
        return {
            orderDetails: {
                id: undefined,
                product: undefined,
                value: undefined,
            },
        };
    },
    getters: {
        productDetails(state) {
            return products[state.orderDetails.product.toLowerCase()];
        },
    },
    actions: {
        async setOrderDetails({ id, product, value }) {
            this.orderDetails = {
                id,
                product,
                value,
            };
        },
    },
});
