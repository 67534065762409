<template>
    <div class="flex max-md:flex-col gap-6 max-w-2xl">
        <div
            class="p-6 md:w-1/2 bg-white shadow rounded space-y-2"
            :class="border && `${borderColor} border-t-4`"
        >
            <p class="text-gray-600 text-sm">Your haplogroup is</p>
            <div :class="['text-6xl font-semibold  leading-none', textColor]">
                {{ result.haplogroup }}
            </div>
        </div>
        <div
            v-if="result.subclade"
            class="p-6 md:w-1/2 bg-white shadow rounded space-y-2"
            :class="border && `${borderColor} border-t-4`"
        >
            <p class="text-gray-600 text-sm">Your haplotype is</p>
            <div :class="['text-6xl font-semibold  leading-none', textColor]">
                {{ result.subclade }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    result: Object,
    type: String,
    border: Boolean,
});

const textColor = computed(() => {
    return {
        mtdna: "text-rose-500",
        ydna: "text-male-500",
    }[props.type];
});

const borderColor = computed(() => {
    return {
        mtdna: "border-rose-500",
        ydna: "border-male-500",
    }[props.type];
});
</script>
