<template>
    <div class="bg-grey-50 border border-grey-500 rounded flex items-center p-2 justify-around">
        <input
            placeholder="XXXX"
            type="text"
            class="w-20 bg-grey-50 text-center border-none"
            ref="inputPartOne"
            v-model="inputPartOne"
        />
        -
        <input
            placeholder="XXXX"
            type="text"
            class="w-20 bg-grey-50 text-center border-none"
            ref="inputPartTwo"
            v-model="inputPartTwo"
        />
        -
        <input
            placeholder="XXXX"
            type="text"
            class="w-20 bg-grey-50 text-center border-none"
            ref="inputPartThree"
            v-model="inputPartThree"
        />
    </div>
</template>
<!--
123456789876543
-->
<script>
export default {
    props: {
        modelValue: Array,
    },
    data() {
        return {
            inputPartOne: "",
            inputPartTwo: "",
            inputPartThree: "",
            carryOver: "",
        };
    },
    computed: {
        fullActivationCode() {
            return [this.inputPartOne, this.inputPartTwo, this.inputPartThree];
        },
    },
    methods: {
        focusInputPart(part) {
            if (part === 2) {
                this.$refs.inputPartTwo.focus();
            }
            if (part === 3) {
                this.$refs.inputPartThree.focus();
            }
        },
    },
    watch: {
        inputPartOne(value) {
            /* Specifically handles the case where a full code is posted in with hyphens. This is
               a target for refactoring in the future but testing and a specific edge case it should
               be ok! todo refactor */
            if (value.length === 14) {
                [this.inputPartOne, this.inputPartTwo, this.inputPartThree] = value.split("-");
            }
            if (value.length > 3) {
                if (this.inputPartOne.substring(4)) {
                    this.inputPartTwo = this.inputPartOne.substring(4);
                }
                this.inputPartOne = this.inputPartOne.substring(0, 4);
                this.focusInputPart(2);
            }
            this.inputPartOne = this.inputPartOne.toLocaleUpperCase();
        },
        inputPartTwo(value) {
            if (value.length > 3) {
                if (this.inputPartTwo.substring(4)) {
                    this.inputPartThree = this.inputPartTwo.substring(4);
                }
                this.inputPartTwo = this.inputPartTwo.substring(0, 4);
                this.focusInputPart(3);
            }
            this.inputPartTwo = this.inputPartTwo.toLocaleUpperCase();
        },
        inputPartThree(value) {
            if (value.length > 3) {
                this.inputPartThree = this.inputPartThree.substring(0, 4);
                this.$emit("input-completed");
            }
            this.inputPartThree = this.inputPartThree.toLocaleUpperCase();
        },
        fullActivationCode(code) {
            this.$emit("update:modelValue", code);
        },
    },
};
</script>
