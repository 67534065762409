import axios from "axios";
import endpoints from "@/endpoints";
import store from "@/store/store";
import { ref, computed } from "vue";

// interface HealthRequestOptions {
//   path: string;
//   method: string;
//   body?: object;
// }

export async function makeHealthRequest(options) {
    return await axios.post(endpoints.healthBackend, options);
}

export async function getPatient(user) {
    if (!user) {
        user = store.getters.account;
    }
    try {
        const patient = await makeHealthRequest({
            path: `/patients/${user.uuid}`,
            method: "GET",
        });
        return patient.data;
    } catch (e) {
        console.error(e);
        return false;
    }
}

export async function getUnconfirmedAppointments(user) {
    const { data } = await makeHealthRequest({
        path: `/orders/patient/${user.email}/missingAppointmentData`,
        method: "GET",
    });
    console.log({ data });
    return data;
}

export function isHealthEnabled() {
    return true;
}

export const useNewWellbeing = computed(() => {
    return store.getters.selectedTest.product_sku === "LDNA-YULIFE-UK";
});

export function shouldCreateHealthProfile(activatedTest) {
    return activatedTest.product_sku === "LDNA-YULIFE-UK";
}

export async function createHealthProfile(healthProfileDetails) {
    return await makeHealthRequest({
        path: "/patients",
        method: "POST",
        body: healthProfileDetails,
    });
}

export function useSaving() {
    const saveStatus = ref("idle");
    const errorMessage = ref("");
    function startSaving() {
        errorMessage.value = "";
        saveStatus.value = "saving";
    }
    function finishSaving() {
        saveStatus.value = "idle";
    }
    return { saveStatus, errorMessage, startSaving, finishSaving };
}

export const wellbeingGoals = [
    {
        id: "general-wellbeing",
        title: "I want to improve my general wellbeing",
        icon: "activity",
        name: "General Wellbeing",
    },
    {
        id: "monitor",
        title: "I want to monitor known health condition(s)",
        icon: "monitor",
        name: "Monitor conditions",
    },
    {
        id: "improve-control",
        title: "I want to improve control of my health condition(s)",
        icon: "sliders",
        name: "Improve Control",
    },
    {
        id: "improve-cardio",
        title: "I want to improve my cardiovascular health (this includes lowering cholesterol)",
        icon: "heart",
        name: "Improve Cardio",
    },
    {
        id: "lose-weight",
        title: "I want to lose weight",
        icon: "bar-chart",
        name: "Lose Weight",
    },
    {
        id: "more-active",
        title: "I want to become more active",
        icon: "map",
        name: "Be More Active",
    },
    {
        id: "reduce-illness",
        title: "I want to reduce my risks of developing a medical illness",
        icon: "user-minus",
        name: "Reduce Illness Risk",
    },
    {
        id: "less-tired",
        title: "I want to feel less tired",
        icon: "coffee",
        name: "Be Less Tired",
    },
    {
        id: "less-stressed",
        title: "I want to feel less stressed",
        icon: "user-check",
        name: "Feel Less Stress",
    },
    {
        id: "mange-hormones",
        title: "I want to manage my hormones (including improving fertility)",
        icon: "arrow-right",
        name: "Manage Hormones",
    },
];

export async function saveQuestionnaireAnswers({ answers, userUuid }) {
    const { data } = await makeHealthRequest({
        path: `/patients/${userUuid}/answers`,
        method: "PUT",
        body: {
            userUuid,
            answers: JSON.stringify(answers),
        },
    });
    return data;
}

const ax = axios.create();
export function getModalData() {
    return ax({
        url: "https://graphql.prepr.io/68bdbc0574620adc917543ab736fed3c13813986aa3e01159049989e3d38bfbb",
        method: "post",
        data: {
            query: `

            {
                NewLivingDnaProfileModal {
                    _id
                    header
                    body
                    image {
                        _id
                        url
                    }
                    tagline
                    wellbeing_users_only
                }
            }

      `,
        },
    });
}
