<template>
    <div>
        <h1 class="font-normal text-2xl">Add a profile picture</h1>
        <p class="mb-0">
            If you'd like to, you can add a profile picture to help other users identify you. It's
            entirely optional and you can update, add, or delete your profile picture at any time
            from your profile settings.
        </p>
        <AvatarInput
            v-if="state === 'loaded'"
            :key="$store.getters.fullProfile.profile.barcode"
            :profile="$store.getters.fullProfile.profile"
            @avatar-saved="handleAvatarAdded"
        />
        <div v-if="state === 'error'">
            There was a problem loading the avatar upload component
        </div>
        <div class="text-center mt-4">
            <router-link :to="{ name: 'upload-completed' }" class="text-grey-600 font-semibold">
                No thank you
            </router-link>
        </div>
    </div>
</template>

<script>
import AvatarInput from "@/components/inputs/UploadInput/AvatarInput/AvatarInput.vue";

export default {
    data() {
        return {
            state: "loading",
        };
    },
    components: { AvatarInput },
    methods: {
        handleAvatarAdded() {
            this.$router.push({ name: "upload-completed" });
        },
    },
    async created() {
        try {
            await this.$store.dispatch("GET_FULL_PROFILE", this.$store.getters.profile.barcode);
            this.state = "loaded";
        } catch (e) {
            console.error(e);
            this.state = "Error";
        }
    },
};
</script>
