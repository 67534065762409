<template>
    <div :id="id" class="w-full"></div>
</template>

<script setup>
import mapboxgl from "mapbox-gl";
import {
    accessToken,
    mapStyle,
    vikingAccessToken,
    vikingMapStyle,
} from "@/views/ancestry/recent/mapboxConfig";
import { onMounted } from "vue";

const props = defineProps({
    id: {
        type: String,
        default: "map",
    },
    type: String,
});

const emit = defineEmits(["mapLoaded"]);

if (props.type === "satellite") {
    mapboxgl.accessToken = vikingAccessToken;
} else {
    mapboxgl.accessToken = accessToken;
}

onMounted(() => {
    // const bounds = [
    //     [-180, -90], // Southwest coordinates
    //     [180, 90], // Northeast coordinates
    // ];
    const map = new mapboxgl.Map({
        container: props.id,
        style: props.type === "satellite" ? vikingMapStyle : mapStyle,
        center: [38.149898, 30.5389105],
        zoom: 2,
        fadeDuration: 0,
        maxZoom: 10,
        // renderWorldCopies: false,
        // maxBounds: bounds,
    });

    // map.setRenderWorldCopies(false);

    map.on("load", () => {
        emit("mapLoaded", map);
    });
});
</script>
