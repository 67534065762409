<template>
    <img :src="imagePath || defaultAvatar" alt="" class="rounded-full //w-full" />
</template>

<script>
import { pngUrlToJpg } from "@/services/cloudinary";
import defaultAvatar from "@/assets/images/avatar-default.png";

function convertedImagePath(path) {
    return pngUrlToJpg(path);
}

export default {
    props: {
        path: String,
    },
    setup() {
        return { defaultAvatar };
    },
    computed: {
        imagePath() {
            return convertedImagePath(this.path);
        },
    },
};
</script>
