<template>
    <transition name="slide-left" mode="out-in">
        <div>
            <router-view :profile="profile" :first-name="firstName" />
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        profile: {
            required: true,
            type: Object,
        },
    },
    computed: {
        firstName() {
            return this.profile.name.split(" ")[0];
        },
    },
};
</script>
