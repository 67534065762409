<template>
    <portal to="sidebar">
        <Sidebar>
            <div class="p-6 prose bg-white">
                <h2>{{ title }}</h2>
                <!--                <label class="flex items-center mb-4 gap-2">-->
                <!--                    <input type="checkbox" v-model="showAllGroups" />-->
                <!--                    Show all haplogroups on the map-->
                <!--                </label>-->

                <p>
                    You're viewing your migration map which shows the journey your ancestors may
                    have taken for you to get where you are today. Click on "Coverage Map" below to
                    see the regions where people with your haplogroup are living today.
                </p>

                <div class="flex gap-6">
                    <div class="w-24 flex-none">
                        <button
                            v-for="(properties, group, index) in circleGroups"
                            @click="activeIndex = index"
                            class="px-4 py-2 rounded mb-2 text-center w-full"
                            :class="
                                showAllGroups
                                    ? index === activeIndex
                                        ? `${colors.bgMain} text-white`
                                        : `${colors.bgSecondary} ${colors.text}`
                                    : index <= activeIndex
                                    ? `${colors.bgMain} text-white`
                                    : `${colors.bgSecondary} ${colors.text}`
                            "
                        >
                            {{ group }}
                        </button>
                    </div>
                    <div class="-mt-8 flex-shrink">
                        <h3>{{ Object.keys(circleGroups)[activeIndex] }}</h3>
                        <div
                            v-if="activeHaplogroup && activeHaplogroup.descriptionOne"
                            v-html="`${activeHaplogroup.descriptionOne.slice(0, 400)}...`"
                        />
                        <button @click="showModal = true" class="text-cobalt-500 font-semibold">
                            See all
                        </button>
                    </div>
                </div>

                <div class="flex justify-between mt-4">
                    <button
                        @click="activeIndex--"
                        class="px-4 py-2 rounded"
                        :class="
                            activeIndex !== 0
                                ? 'bg-cobalt-50 text-cobalt-500 font-semibold hover:bg-blue-100'
                                : 'bg-gray-100 text-gray-600'
                        "
                        :disabled="activeIndex === 0"
                    >
                        Previous
                    </button>
                    <template v-if="!showAllGroups">
                        <button
                            v-if="activeIndex !== Object.keys(circleGroups).length - 1"
                            @click="activeIndex = Object.keys(circleGroups).length - 1"
                            class="px-4 py-2 bg-cobalt-50 text-cobalt-500 font-semibold rounded"
                        >
                            Go to end
                        </button>
                        <button
                            v-else
                            @click="activeIndex = 0"
                            class="px-4 py-2 bg-cobalt-50 text-cobalt-500 font-semibold rounded"
                        >
                            Back to start
                        </button>
                    </template>

                    <button
                        @click="activeIndex++"
                        class="px-4 py-2 rounded"
                        :class="
                            activeIndex !== Object.keys(circleGroups).length - 1
                                ? 'bg-cobalt-50 text-cobalt-500 font-semibold hover:bg-blue-100'
                                : 'bg-gray-100 text-gray-600'
                        "
                        :disabled="activeIndex === Object.keys(circleGroups).length - 1"
                    >
                        Next
                    </button>
                </div>
            </div>
        </Sidebar>
    </portal>

    <BaseMap class="h-full" v-if="showMap" @map-loaded="mapLoaded" />

    <MobileContent :title="title">
        <div v-for="(properties, group, index) in circleGroups">
            <button
                @click="activeIndex = index"
                class="px-4 py-2 rounded mb-2 text-center w-full"
                :class="
                    showAllGroups
                        ? index === activeIndex
                            ? `${colors.bgMain} text-white`
                            : `${colors.bgSecondary} ${colors.text}`
                        : index <= activeIndex
                        ? `${colors.bgMain} text-white`
                        : `${colors.bgSecondary} ${colors.text}`
                "
            >
                {{ group }}
            </button>
            <div v-if="activeIndex === index" class="mt-4 mb-6">
                <div
                    v-if="activeHaplogroup && activeHaplogroup.descriptionOne"
                    v-html="`${activeHaplogroup.descriptionOne.slice(0, 400)}...`"
                />
                <button @click="showModal = true" class="text-cobalt-500 font-semibold">
                    See all
                </button>
            </div>
        </div>
    </MobileContent>

    <Modal :open="showModal" width="2xl" @closed="showModal = false">
        <div class="prose">
            <div v-html="activeHaplogroup.descriptionOne" />
            <div class="mt-4" v-html="activeHaplogroup.descriptionTwo" />
            <div class="text-center">
                <button
                    @click="showModal = false"
                    class="bg-cobalt-500 hover:bg-cobalt-700 text-white px-4 py-2 rounded font-semibold mt-4"
                >
                    Back to map
                </button>
            </div>
        </div>
    </Modal>
</template>

<script setup>
import { useMotherlineStore } from "@/store/parentalStore";
import BaseMap from "@/components/BaseMap.vue";
import {
    addUniqueIdsToMapLines,
    convertToFeatures,
    stageCircleData,
    autosomalColors,
    getHaplogroupDescriptions,
} from "@/services/parental";
import uuid from "uuid";
import { computed, ref, watch } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import Modal from "@/components/Modal.vue";
import * as turf from "@turf/turf";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import MobileContent from "@/components/ancestry/MobileContent.vue";

const showAllGroups = ref(true);
const activeIndex = ref(0);
const haplogroupDescriptions = ref([]);
const showModal = ref(false);
const showMap = ref(false);
const expanded = ref(false);

function toggleExpansion() {
    expanded.value = !expanded.value;
}

setTimeout(() => {
    showMap.value = true;
}, 500);

const props = defineProps({
    result: Object,
    type: String,
});

const colors = computed(() => {
    return {
        mtdna: {
            bgSecondary: "bg-rose-50",
            bgMain: "bg-rose-500",
            text: "text-rose-500",
        },
        ydna: {
            bgSecondary: "bg-male-50",
            bgMain: "bg-male-500",
            text: "text-male-500",
        },
    }[props.type];
});

const indexOffset = computed(() => {
    return {
        mtdna: 1,
        ydna: 1,
    }[props.type];
});

const title = computed(() => {
    return {
        mtdna: "Motherline Migration Map",
        ydna: "Fatherline Migration Map",
    }[props.type];
});

const circleSource = {
    type: "geojson",
    data: {
        id: "migrationCircles",
        type: "FeatureCollection",
        features: stageCircleData(props.result.migrationPaths, autosomalColors),
    },
};

const circleSourcesWithUniqueId = circleSource.data.features.map((circle) => {
    return {
        ...circle,
        uniqueId: uuid(),
        textId: uuid(),
    };
});

const maternalMigrationFeatures = convertToFeatures(props.result.migrationPaths, autosomalColors);

const circleGroups = _.groupBy(circleSourcesWithUniqueId, (i) => {
    return i.properties.title;
});

const mapLines = _.groupBy(addUniqueIdsToMapLines(maternalMigrationFeatures), (item) => {
    return item.properties.title;
});

getHaplogroupDescriptions(Object.keys(circleGroups), props.type).then((descriptions) => {
    haplogroupDescriptions.value = descriptions;
});

const layersToRemove = [];
const sourcesToRemove = [];

const activeHaplogroup = computed(() => {
    return haplogroupDescriptions.value.find(
        (d) => d.title === Object.keys(circleGroups)[activeIndex.value]
    );
});

function mapLoaded(map) {
    watch(
        [activeIndex, showAllGroups],
        () => {
            layersToRemove.forEach((item) => {
                if (map.getLayer(item)) {
                    map.removeLayer(item);
                }
            });
            sourcesToRemove.forEach((item) => {
                if (map.getSource(item)) {
                    map.removeSource(item);
                }
            });
            Object.entries(mapLines).forEach((group, index) => {
                const [title, entries] = group;
                const color = autosomalColors[index + indexOffset.value];
                if (index < activeIndex.value || showAllGroups.value) {
                    entries.forEach((feature) => {
                        sourcesToRemove.push(feature.uniqueId);
                        map.addSource(feature.uniqueId, {
                            type: "geojson",
                            data: {
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "LineString",
                                    coordinates: feature.geometry.coordinates,
                                },
                            },
                        });

                        layersToRemove.push(feature.uniqueId);
                        map.addLayer({
                            id: feature.uniqueId,
                            type: "line",
                            source: feature.uniqueId,
                            layout: {
                                "line-join": "round",
                                "line-cap": "round",
                            },
                            paint: {
                                "line-color":
                                    index === activeIndex.value - indexOffset.value
                                        ? color.primary
                                        : color.secondary,
                                "line-width": 6,
                            },
                        });
                    });
                }
            });

            Object.entries(circleGroups).forEach((group, index) => {
                const [title, entries] = group;
                const color = autosomalColors[index];
                if (index < activeIndex.value + 1 || showAllGroups.value) {
                    entries.forEach((circle) => {
                        sourcesToRemove.push(circle.uniqueId);
                        map.addSource(circle.uniqueId, {
                            type: "geojson",
                            data: {
                                type: "FeatureCollection",
                                features: [
                                    {
                                        type: "Feature",
                                        geometry: {
                                            type: "Point",
                                            coordinates: circle.geometry.coordinates,
                                        },
                                    },
                                ],
                            },
                        });

                        const cirlceId = uuid();
                        layersToRemove.push(cirlceId);

                        map.addLayer({
                            id: cirlceId,
                            type: "circle",
                            source: circle.uniqueId,
                            paint: {
                                "circle-color":
                                    index === activeIndex.value ? color.primary : color.secondary,
                                "circle-radius": {
                                    base: 16,
                                    stops: [
                                        [1, 16],
                                        [2, 20],
                                        [3, 24],
                                        [4, 28],
                                    ],
                                },
                            },
                            filter: ["==", "$type", "Point"],
                        });

                        const textId = uuid();
                        layersToRemove.push(textId);

                        map.addLayer({
                            id: textId,
                            type: "symbol",
                            source: circle.uniqueId,
                            // filter: ["has", "singles_count"],
                            layout: {
                                "text-field": circle.properties.title,
                                // "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                                "text-size": 12,
                            },
                            paint: {
                                "text-color": "#ffffff",
                                "text-opacity": 1.0,
                                "text-opacity-transition": {
                                    duration: 0,
                                },
                            },
                        });
                    });
                }
            });
        },
        {
            immediate: true,
        }
    );

    const bbox = turf.bbox(circleSource.data);
    map.fitBounds(bbox, { padding: 50 });
}
</script>
