<template>
    <main>
        <h1 class="font-normal text-2xl">{{ headerText }}</h1>

        <form @submit.prevent="onSubmit">
            <slot />

            <submit-button
                icon="save"
                :disabled="buttonDisabled"
                :class="{ loading: statusIsPending }"
            >
                Save
            </submit-button>

            <div class="text-center">
                <router-link
                    :to="{ name: 'profile-view' }"
                    class="mt-2 p-2 inline-block text-grey-500"
                >
                    Cancel
                </router-link>
            </div>
        </form>
    </main>
</template>

<script>
import SubmitButton from "@/components/button/SubmitButton.vue";
import { mapGetters } from "vuex";

export default {
    name: "ProfileForm",

    components: {
        SubmitButton,
    },

    props: {
        headerText: {
            type: String,
            required: true,
        },
        profile: {
            type: Object,
            required: true,
        },
        partners: {
            type: Array,
            required: true,
        },
        fieldData: {
            type: Object,
            required: true,
        },
        buttonDisabled: {
            type: Boolean,
        },
    },

    watch: {
        fullProfileLoadingStatus(n) {
            if (n === "FULL_PROFILE_LOADING_SUCCESS") {
                this.$router.push({ name: "profile-view" });
            }
        },
    },

    methods: {
        onSubmit() {
            const profile = {
                ...this.profile,
                ...this.fieldData,
            };
            // console.log({ profile });
            this.$store.dispatch("EDIT_FULL_PROFILE", {
                barcode: this.profile.barcode,
                payload: {
                    profile,
                    partners: this.partners,
                },
            });

            this.$emit("submit");
        },
    },

    computed: {
        ...mapGetters(["fullProfileLoadingStatus"]),

        statusIsPending() {
            return this.fullProfileLoadingStatus === "FULL_PROFILE_LOADING_PENDING";
        },
    },
};
</script>
