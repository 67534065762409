<template>
    <div class="text-center">
        <h3 class="text-denim-700 text-2xl font-normal">Check your inbox</h3>
        <p class="mt-4 leading-normal">
            We've just sent an email to <span class="underline">{{ email }}</span
            >.
        </p>
        <p class="mt-4">Follow the link in the email to complete your password reset.</p>
        <p class="mt-4 p-2 bg-cobalt-50 text-cobalt-500">
            Please make sure to check your spam folder to see if the email is there.
            <span class="font-semibold" v-if="hostPart === 'gmail.com'"
                >This is especially likely with @gmail.com email addresses</span
            >
        </p>
        <div v-if="showSuccess" class="bg-emerald-50 py-2 px-4 text-emerald-500 mt-4">
            Reset email resent
        </div>
        <div v-if="responseErrors" class="bg-rose-50 py-2 px-4 text-rose-500">
            {{ responseErrors }}
        </div>
        <div class="mt-24">
            <p class="mb-2">
                Problems receiving email?
                <button
                    @click="resendResetEmail"
                    class="text-cobalt-500 font-semibold"
                    :class="{ 'text-grey-500': loading }"
                    :disabled="loading"
                >
                    Resend
                </button>
            </p>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import store from "@/store/store";

const route = useRoute();

const email = route.query.email;
const [localPart, hostPart] = email.split("@");
const loading = ref(false);
const showSuccess = ref(false);
const responseErrors = ref(false);

function resendResetEmail() {
    loading.value = true;
    store
        .dispatch("resetPassword", {
            email: email,
        })
        .then(() => {
            showSuccess.value = true;
        })
        .catch((err) => {
            if (err.bodyText) {
                this.responseErrors = JSON.parse(err.bodyText).errors[0].detail;
            } else {
                console.error(err);
            }
        })
        .finally(() => (loading.value = false));
}
</script>
