// import Vue from "vue";
import axios from "axios";
import endpoints from "@/endpoints";
import uuidv1 from "uuid";
import { flatMap } from "lodash";

export function arrayOfStrings(str, count = 1, arr = []) {
    return arr.length < count ? arrayOfStrings(str, count, [...arr, str]) : arr;
}

export function shuffleArray(arr) {
    const array = [...arr];
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export default class Autosomal {
    static async getResults({ barcode, query }) {
        const { data } = await axios.get(`${endpoints.tests}/${barcode}/family?view=${query}`);
        //https://dnaworldwide.atlassian.net/browse/LM-491
        console.log(data);
        return data;
    }

    static async getSummary({ barcode }) {
        const { data } = await axios.get(`${endpoints.tests}/${barcode}/summary`);

        return data;
    }

    static async getHistory({ barcode, groupId }) {
        const { data } = await axios.get(`${endpoints.tests}/${barcode}/history/${groupId}`);

        return data;
    }
}

export async function getPreviousResult(barcode) {
    try {
        return await axios.get(endpoints.getPreviousAutosomalResult(barcode));
    } catch (e) {
        throw e;
    }
}

export function createMapItem(item) {
    return {
        properties: {
            colour: item.colour,
            title: item.title,
            code: item.code || uuidv1(),
            percentage: item.percentage || 1,
            group: item.groupId || uuidv1(),
            childrenCount: item.children.length,
        },
        id: item.groupId || uuidv1(),
    };
}

export function flattenChildren(array) {
    return flatMap(array, "children");
}

function removeInnerChildren(arr) {
    return arr.map(({ children, ...rest }) => rest);
}

export function normaliseSubRegionalLevel(arr) {
    return arr.map(region => ({
        ...region,
        isParent: true,
        parentLevel: 1,
        children: region.children.map(subRegion => ({
            ...subRegion,
            isParent: true,
            parentLevel: 2,
            children: removeInnerChildren(subRegion.children),
        })),
    }));
}
