<template>
    <div>
        <div class="flex flex-col">
            <div class="bg-emerald-50 rounded-full p-2 inline-flex mx-auto">
                <Feather type="check" class="fill-current text-emerald-500 m-auto h-8 w-8" />
            </div>
            <h1 class="mt-4 mb-0 font-normal text-2xl text-center">Kit activated</h1>
            <div class="bg-cobalt-50 text-cobalt-500 rounded p-6 mt-4">
                <div class="flex gap-4">
                    <div class="mt-1">
                        <FeatherWrapper icon="info" />
                    </div>

                    <p>
                        Ensure that the sticker attached to your specimen bag is used to seal it
                        once you have collected your sample.
                        <strong
                            >Without this we cannot identify your sample or begin to test
                            it.</strong
                        >
                    </p>
                </div>
            </div>
            <p class="mt-4 mb-0 text-grey-700 text-center">
                Congratulations! The kit is now activated, and you can proceed to the Dashboard,
                while you wait for the test results. You can expect to get your results in 6-8
                weeks.
            </p>
            <p v-if="fullProfile.profile.fnConsent" class="mt-4 mb-0 text-grey-700 text-center">
                You've opted into family networks! New family networks results are released
                approximately every 10 days. Once your results are back from the lab you will be
                included in the next run of family networks. This means you won't receive your
                family networks results at the same time as the rest of your results.
            </p>
            <p v-else class="mt-4 mb-0 text-grey-700 text-center">
                You haven't opted into family networks. If you change your mind you can opt in at
                any time by visiting the Relatives page. If this was a mistake you can go back and
                opt in now by
                <router-link :to="{ name: 'activation-family-networks-opt-in' }">
                    clicking here </router-link
                >.
            </p>
            <router-link
                :to="{ name: 'dashboard' }"
                class="bg-cobalt-500 text-white rounded py-2 text-center mt-6"
            >
                <span class="text-white font-semibold">Continue</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    async created() {
        await this.$store.dispatch("getDefaultProfile");
        await this.$store.dispatch("GET_FULL_PROFILE", this.$store.getters.profile.barcode);
        console.log(this.$store.getters.fullProfile);
    },
    computed: {
        ...mapGetters(["fullProfile"]),
    },
};
</script>
