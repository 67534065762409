import axios from "axios";
import Vuex from "vuex";
import VueJwtDecode from "vue-jwt-decode";
import endpoints from "@/endpoints";
import countries from "@/countries";
import mediaModule from "@/store/util/media";
import navigationModule from "@/store/util/navigation";
import logoutModule from "@/store/util/logout";
import fatherlineModule from "@/store/ancestry/fatherline";
import productModule from "@/store/product/product";
import checkoutModule from "@/store/checkout/checkout";
import researchModule from "@/store/research";
import wellbeingModule from "@/store/wellbeing/wellbeing";
import motherlineModule from "@/store/ancestry/motherline";
import migrationModule from "@/store/ancestry/migration";
import coverageModule from "@/store/ancestry/coverage";
import myTestModule from "@/store/tests";
import autosomalModule from "@/store/autosomal";
import activationModule from "@/store/activation/activation";
import userSignupModule from "@/store/user/signup";
import uploadModule from "@/store/uploads/uploads";
import profileModule from "@/store/profile/profile";
import dashboardModule from "@/store/dashboard/dashboard";
import UsersService from "@/services/users";
import autosomalUpgradeModule from "@/store/autosomalUpgrade/autosomalUpgrade";
import accountModule from "@/store/account/account";

export default new Vuex.Store({
    state: {
        region: localStorage.getItem("region") || false,
        token: localStorage.getItem("token") || "",
        referrer:
            localStorage.getItem("referrer") !== "undefined"
                ? localStorage.getItem("referrer")
                : null,
    },
    getters: {
        getRegion: (state) => state.region,
        referrer: (state) => state.referrer,
        token: (state) => state.token,
    },
    mutations: {
        setReferrer(state, payload) {
            state.referrer = payload;
            window.localStorage.setItem("referrer", payload);
        },
        SET_REGION(state, region) {
            state.region = region;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        CLEAR_TOKEN(state) {
            state.token = "";
        },
    },
    actions: {
        updatePassword(context, account) {
            return new Promise((resolve, reject) => {
                axios.put(`${endpoints.account}`, account).then(
                    (res) => {
                        resolve(res);
                    },
                    (res) => {
                        reject(res);
                    }
                );
            });
        },
        refreshToken() {
            return new Promise((resolve, reject) => {
                axios.post(endpoints.refreshToken).then(
                    (response) => {
                        resolve(response);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        setToken(context, payload) {
            context.commit("setToken", payload);
        },
        resetPassword(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(endpoints.accountResetPassword, payload).then(
                    (res) => {
                        resolve(res);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        createNewPassword(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(endpoints.accountCreatePassword, payload).then(
                    (res) => {
                        resolve(res);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        validateEmail(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(endpoints.accountConfirm, payload).then(
                    (res) => {
                        resolve(res);
                    },
                    (error) => {
                        reject(error);
                    }
                );
            });
        },
        setReferrer(context, payload) {
            if (payload) {
                context.commit("setReferrer", payload);
            }
        },
        getSignedURL(context, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(endpoints.lambdaUpload, payload)
                    .then((res) => {
                        resolve(res || "/");
                    })
                    .catch((res) => {
                        reject(res);
                    });
            });
        },
        async SET_REGION({ commit, getters }, payload) {
            const { region } = countries.find((country) => country.code === payload.data);
            await UsersService.setUsersRegion(getters.accountData.uuid, region);
            localStorage.setItem("region", region);
            commit("SET_REGION", region);
        },
        async GET_REGION({ commit, getters }) {
            const { region } = await UsersService.getUserInformation(getters.accountData.uuid);
            localStorage.setItem("region", region);
            commit("SET_REGION", region);
        },
        CLEAR_TOKEN({ commit }) {
            commit("CLEAR_TOKEN");
        },
        CLEAR_ACCOUNT_DATA({ commit }) {
            commit("CLEAR_ACCOUNT_DATA");
        },
    },
    modules: {
        productModule,
        checkoutModule,
        researchModule,
        myTestModule,
        autosomalModule,
        activationModule,
        userSignupModule,
        uploadModule,
        motherlineModule,
        fatherlineModule,
        migrationModule,
        coverageModule,
        profileModule,
        dashboardModule,
        wellbeingModule,
        autosomalUpgradeModule,
        accountModule,
        util: {
            modules: {
                mediaModule,
                navigationModule,
                logoutModule,
            },
        },
    },
});
