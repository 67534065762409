import axios from "axios";
import endpoints from "@/endpoints";

function createUrlObject(data) {
    const pdfBlob = new Blob([data], { type: "image/pdf" });
    return window.URL.createObjectURL(pdfBlob);
}

function download(pdf, path) {
    const a = document.createElement("a");
    a.href = pdf;
    a.download = path;
    a.click();
}

function createPath(barcode, type) {
    return `${barcode}-${type}.pdf`;
}

export async function getPdf(barcode, type) {
    const res = await axios.get(endpoints.bookPdf(type), {
        responseType: "arraybuffer",
    });
    const pdf = createUrlObject(res.data);
    download(pdf, createPath(barcode, type));
}
