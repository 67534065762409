import FamilyMatching from "@/views/FamilyNetworks/FamilyMatching/FamilyMatching.vue";
import ListView from "@/views/FamilyNetworks/FamilyMatching/ListView/RelativesListView.vue";
import MessagingCentre from "@/views/FamilyNetworks/FamilyMatching/MessagingCentre/MessagingCentre.vue";
import Matchbox from "@/views/FamilyNetworks/FamilyMatching/Matchbox/Matchbox.vue";
import MultiviewBrowser from "@/views/FamilyNetworks/FamilyMatching/MultiviewBrowserView.vue";
import DetailsView from "@/views/FamilyNetworks/FamilyMatching/DetailsView/DetailsView.vue";
import Conversation from "@/views/FamilyNetworks/FamilyMatching/DetailsView/Conversation.vue";
import Relationship from "@/views/FamilyNetworks/FamilyMatching/DetailsView/Relationship.vue";
import Start from "@/views/FamilyNetworks/FamilyMatching/OptInFlow/Start.vue";
import OptInDisplayName from "@/views/FamilyNetworks/FamilyMatching/OptInFlow/OptInDisplayName.vue";
import RelationshipNotes from "@/views/FamilyNetworks/FamilyMatching/DetailsView/RelationshipNotes.vue";
import Map from "@/views/FamilyNetworks/FamilyMatching/DetailsView/Map.vue";
import ChromosomeBrowser from "@/views/FamilyNetworks/FamilyMatching/DetailsView/ChromosomeBrowser.vue";
import FamilyMatchingMaintenanceMode from "@/views/FamilyNetworks/FamilyMatching/FamilyMatchingMaintenance.vue";

function isFMInMaintenanceMode() {
    if (localStorage.getItem("ldna-maintenance") === "2b731473-9e84-466c-a584-823c57a10ee2") {
        return false;
    }
    return String(process.env.VUE_APP_ENABLE_FM_MAINTENANCE_MODE) === "true";
}

export default [
    {
        path: "family-matching",
        name: "family-matching",
        redirect: "/family-matching/list",
        // component: FamilyMatching,
        component: isFMInMaintenanceMode() ? FamilyMatchingMaintenanceMode : FamilyMatching,
        children: [
            {
                path: "/family-matching/list",
                name: "list-view",
                component: ListView,
            },
            {
                path: "messaging-centre",
                name: "messaging-centre",
                component: MessagingCentre,
            },
            {
                path: "/family-matching/matchbox",
                name: "matchbox",
                component: Matchbox,
            },
            {
                path: "/family-matching/multiview-browser",
                name: "multiview-browser",
                component: MultiviewBrowser,
            },
            {
                path: "match/:barcode",
                name: "match-details",
                redirect: (to) => {
                    console.log(to);
                    return `/family-matching/match/${to.params.barcode}/conversation`;
                },
                component: DetailsView,
                children: [
                    {
                        path: "conversation",
                        name: "match-conversation",
                        component: Conversation,
                    },
                    {
                        path: "relationship",
                        name: "match-relationship",
                        component: Relationship,
                    },
                    {
                        path: "notes",
                        name: "match-notes",
                        component: RelationshipNotes,
                    },
                    {
                        path: "map",
                        name: "map",
                        component: Map,
                    },
                    {
                        path: "chromosome-browser",
                        name: "chromosome-browser",
                        component: ChromosomeBrowser,
                    },
                ],
            },
            {
                path: "family-matching/start",
                name: "family-matching-start",
                component: Start,
            },
            {
                path: "family-matching/choose-display-name",
                component: OptInDisplayName,
                name: "family-matching-display-name",
            },
            {
                path: "family-matching/pending",
                component: ListView,
                name: "family-matching-pending",
            },
        ],
    },
];
