<template>
    <router-link
        :to="{ name: 'reupload-dna', params: { barcode: this.$store.getters.profile.barcode } }"
        class="text-inherit"
    >
        <Card class="bg-white flex-0 flex flex-col border-t-4 border-tangerine-500">
            <div class="flex items-stretch gap-8">
                <div class="flex flex-col">
                    <h4 class="text-gray-500 font-semibold text-sm mb-2">Action required</h4>
                    <p>We have encountered an issue with processing your DNA, please reupload.</p>
                    <p class="mt-2">{{ $store.getters.selectedTest.errorMessage }}</p>
                    <router-link
                        :to="{
                            name: 'reupload-dna',
                            params: { barcode: $store.getters.profile.barcode },
                        }"
                        class="flex items-center font-semibold mt-4 text-cobalt-500"
                    >
                        <Feather type="upload" class="h-5 w-5 fill-current mr-2" />
                        Reupload your DNA
                    </router-link>
                </div>
                <div class="flex grow-0">
                    <feather
                        type="alert-circle"
                        class="fill-current text-tangerine-500 h-24 w-24 m-auto"
                    />
                </div>
            </div>
        </Card>
    </router-link>
</template>

<script>
import Feather from "vue-feather";
import Card from "@/components/cards/Card.vue";

export default {
    components: { Feather, Card },
};
</script>
