<template>
    <div v-if="usernameService">
        <h1 class="font-normal text-2xl">Add a username</h1>
        <p class="mb-0">
            If you'd like to, you can add a username to help other users identify you. Your username
            will be used in place of your profile name in family networks, and is what anyone else
            will see when viewing your profile. It's entirely optional and you can update, add, or
            delete your username at any time from your profile settings.
        </p>
        <div v-if="usernameService.state.matches('error')">Oh no</div>
        <form @submit.prevent="saveUsername" class="mt-6">
            <label for="username">Username</label>
            <BaseTextInput id="username" name="username" />
            <button
                v-if="usernameService.state.matches('idle')"
                class="mt-10 bg-cobalt-500 rounded w-full py-2 px-4 text-white font-semibold block w-full text-center hover:bg-cobalt-700"
            >
                Save
            </button>
            <button
                v-else-if="usernameService.state.matches('savingUsername')"
                class="loading mt-10 bg-cobalt-500 rounded w-full py-2 px-4 text-white font-semibold block w-full text-center hover:bg-cobalt-700"
            >
                Save
            </button>
        </form>
        <div class="text-center mt-4">
            <router-link
                :to="{ name: 'activation-family-networks-avatar' }"
                class="text-grey-600 font-semibold"
            >
                No thank you
            </router-link>
        </div>
    </div>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import BaseTextInput from "@/components/Base/BaseSimpleTextInput.vue";

export default {
    components: { BaseTextInput },
    data() {
        return {
            usernameService: undefined,
        };
    },
    async created() {
        await this.$store.dispatch("GET_FULL_PROFILE", this.$store.getters.profile.barcode);
        const usernameMachine = Machine(
            {
                initial: "idle",
                context: {
                    profile: {},
                    username: "",
                },
                states: {
                    idle: {
                        on: {
                            SAVE_USERNAME: {
                                target: "savingUsername",
                                actions: ["cacheProfile", "cacheUsername"],
                            },
                        },
                    },
                    savingUsername: {
                        invoke: {
                            src: "saveUsername",
                            onDone: "completed",
                            onError: "error",
                        },
                    },
                    completed: {
                        type: "final",
                    },
                    error: {},
                },
            },
            {
                actions: {
                    cacheProfile: assign({
                        profile: (_, event) => event.profile,
                    }),
                    cacheUsername: assign({
                        username: (_, event) => event.username,
                    }),
                },
                services: {
                    saveUsername: this.addUsername,
                },
            }
        );
        this.usernameService = interpret(usernameMachine).start();
        this.usernameService.onTransition((state) => {
            if (state.done) {
                this.$router.push({ name: "activation-family-networks-avatar" });
            }
        });
    },
    computed: {
        fullProfile() {
            return this.$store.getters.fullProfile;
        },
    },
    methods: {
        saveUsername(formSubmitEvent) {
            const formData = new FormData(formSubmitEvent.target);
            this.usernameService.send("SAVE_USERNAME", {
                profile: this.fullProfile.profile,
                partners: this.fullProfile.partners,
                username: formData.get("username"),
            });
        },
        addUsername(ctx, e) {
            return this.$store.dispatch("EDIT_FULL_PROFILE", {
                barcode: this.$store.getters.profile.barcode,
                payload: {
                    profile: {
                        ...e.profile,
                        username: e.username,
                    },
                    partners: e.partners,
                },
            });
        },
    },
};
</script>
