import OrderService from "@/services/order";
import { orderBy } from "lodash";

export default {
    state: {
        error: {
            code: null,
            message: null,
        },
        modalSection: "PAYMENT_METHOD",
        shippingAddress: {
            name: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            zip: "",
            phone: "",
            countryCode: "",
        },
        billingAddress: {
            name: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            zip: "",
            phone: "",
            countryCode: "",
        },
        shippingOptions: [],
        selectedShippingOption: {},
        product: {
            sku: "",
            currency: "",
            price: 0,
            shippable: false,
        },
    },
    getters: {
        modalSection: state => state.modalSection,
        productShippingOptions: state =>
            orderBy(
                state.shippingOptions.map(option => ({
                    ...option,
                    price: option.price / 100,
                }))
            ),
        totalPrice: state =>
            state.product.shippable
                ? state.selectedShippingOption.shippingPrice + state.product.price
                : state.product.price,
        totalPriceDecimal: (state, { totalPrice }) => totalPrice / 100,
        shippingPrice: state => state.selectedShippingOption.shippingPrice / 100 || 0,
        checkoutShippingAddress: state => state.shippingAddress,
        checkoutError: state => state.error,
    },
    mutations: {
        setShippingOption(state, selected) {
            state.selectedShippingOption = { ...selected, active: true };
        },
        setActiveShipping(state, selected) {
            state.shippingOptions = [
                ...state.shippingOptions.map(option => ({
                    ...option,
                    active: option.id === selected,
                })),
            ];
        },
        setCheckoutProduct(state, { sku, currency, price, shippable }) {
            state.product = {
                sku,
                currency,
                price,
                shippable,
            };
        },
        setShippingAddress(state, address) {
            state.shippingAddress = { ...address };
        },
        setBillingAddress(state, address) {
            state.billingAddress = { ...address };
        },
        setSection(state, name) {
            state.modalSection = name;
        },
        setError(state, error) {
            state.error = { ...error };
        },
        setShippingOptions(state, shippingOptions) {
            state.shippingOptions = [...shippingOptions];
        },
    },
    actions: {
        setModalSection({ commit }, name) {
            commit("setSection", name);
        },
        setError({ commit }, error) {
            commit("setError", error);
        },
        setCheckoutShippingOption({ state, commit }, shippingOption) {
            const selected = state.shippingOptions.find(option => option.id === shippingOption);

            commit("setShippingOption", selected);
            commit("setActiveShipping", selected.id);
        },
        async setShippingOptions({ commit, state }, sku) {
            const shippingOptions = await OrderService.getShippingOptions(
                sku,
                state.shippingAddress.countryCode
            );
            const options = orderBy(shippingOptions, item => item.shippingPrice, ["asc"]).map(
                (item, index) => ({
                    ...item,
                    id: index,
                    active: index < 1,
                })
            );
            commit("setShippingOptions", options);
            commit("setShippingOption", options[0]);
            // commit('setActiveShipping', options[0]);
        },
        setCheckoutDefaultState({ state, commit }, product) {
            commit("setCheckoutProduct", product);
            commit("setShippingOption", state.shippingOptions[0]);
            commit("setError", { message: null, code: null });
        },
        setShippingAddress({ commit }, address) {
            commit("setShippingAddress", address);
        },
        setCheckoutBillingAddress({ commit }, address) {
            commit("setBillingAddress", address);
        },
        async purchaseProduct({ state }, { barcode, token, type, account, shippable }) {
            return OrderService.checkout(
                state.product,
                state.selectedShippingOption,
                state.shippingAddress,
                state.billingAddress,
                barcode,
                token,
                type,
                account,
                shippable
            );
        },
        // async setShippingOptions({ commit }, region) {
        //     const shippingOptions = await OrderService.getShippingOptions(region);
        //     const options = orderBy(shippingOptions, item => item.prices[0].priceBase, ['asc']);
        //     commit('setShippingOptions', options);
        //     commit('setShippingOption', options[0]);
        //     commit('setActiveShipping', options[0]);
        // },
    },
};
