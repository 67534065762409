import axios from "axios";

const { VUE_APP_PREPR_API_KEY } = process.env;

const ax = axios.create();

export async function useSalesBanner() {
    const res = await ax({
        url: `https://graphql.prepr.io/${VUE_APP_PREPR_API_KEY}`,
        method: "post",
        data: {
            query: `
            {
                DashboardSalesBanner {
                    _id
                    enable
                    dna_test_name
                    title
                    description
                    product_slug
                    background_image {
                        _id
                        url
                    }
                    overlay_colour
                    text_colour
                    button_text
                    button_text_colour
                    background_colour
                    highlight_text
                }
            }
      `,
        },
    });
    return res.data.data.DashboardSalesBanner;
}
