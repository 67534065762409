<template>
    <div class="//bg-grey-50" :class="`h-${height} w-${width}`"></div>
</template>

<script>
export default {
    props: {
        height: [Number, String],
        width: [Number, String],
    },
};
</script>
