<template>
    <div class="lg:w-1/2 sm:w-full flex-col lg:pr-6 mb-10 lg:mb-0">
        <div class="rounded overflow-hidden mb-6">
            <img class="w-full" alt="" :src="mainProductImage.urlDetailed" />
        </div>
        <div class="flex flex-row">
            <div
                class="w-1/4"
                v-for="(image, index) in images"
                :key="image.id"
                :class="{ 'mr-6': index !== images.length - 1 }"
            >
                <img
                    class="cursor-pointer pointer rounded p-2"
                    alt=""
                    :src="image.urlThumbnail"
                    :class="{
                        'border-4 border-cobalt-500': image.main,
                        'border-4 border-grey-50 hover:border-grey-200': !image.main,
                    }"
                    @click="setMainImage(image)"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "product-image-viewer",
    props: {
        publicPath: String,
        dataImages: Array,
    },
    data() {
        return {
            images: this.dataImages,
        };
    },
    computed: {
        mainProductImage() {
            return this.images.find(image => image.main);
        },
    },
    methods: {
        /*
         * I inherited this way of handling the main image, and though I'm far from a fan, I think
         * I'm just going to leave it be for now, at least it's functional.
         * */
        setMainImage(image) {
            this.images = [
                ...this.images.map(i => {
                    if (i.id === image.id) {
                        Object.assign(i, { main: true });
                    } else {
                        Object.assign(i, { main: false });
                    }
                    return {
                        ...i,
                    };
                }),
            ];
        },
    },
};
</script>
