<template>
    <div class="relative w-full">
        <div
            :class="{
                'border-cobalt-500': open,
                'border-red': !!error,
                'border-grey-200': !error && !open,
                'border-none': disabled,
            }"
            @click="toggleSelectList()"
            class="bg-grey-50 rounded text-lg px-3 py-2 w-full border text-grey-700 flex justify-between items-center cursor-pointer"
        >
            <span class="text-grey-500" v-if="!selected.name">Select a country</span>
            <span class="flex items-center" v-if="selected.name">
                <span
                    class="border border-grey-200 p-1px rounded-full h-6 w-6 flex justify-center items-center"
                >
                    <span
                        class="rounded-full flag-icon flag-icon-squared"
                        :class="['flag-icon-' + selected.code.toLowerCase()]"
                    ></span>
                </span>
                <p class="pl-4 text-grey-900 inline my-0">{{ selected.name }}</p>
            </span>
            <i
                :class="{ 'dropdown-icon-open': open, 'dropdown-icon-closed': !open }"
                class="icon-dropdown-inactive text-grey-500"
            ></i>
        </div>
        <div v-if="open" class="absolute w-full z-10">
            <div class="mx-1 shadow-md bg-white rounded-b shadow-key-hover">
                <div class="w-full flex">
                    <input
                        ref="search"
                        v-model="searchValue"
                        class="border border-grey-200 m-1 px-3 py-2 flex-grow rounded outline-none"
                        type="text"
                        placeholder="Search"
                    />
                </div>
                <ul class="list-none max-h-dropdown overflow-scroll">
                    <li
                        @click="selectCountry(option)"
                        class="p-3 flex justify-start items-center cursor-pointer option"
                        v-for="option in filteredOptions"
                        :key="option.code"
                    >
                        <span
                            class="border border-grey-200 p-1px rounded-full h-6 w-6 mr-4 flex justify-center items-center"
                        >
                            <span
                                class="rounded-full flag-icon flag-icon-squared"
                                :class="['flag-icon-' + option.code.toLowerCase()]"
                            ></span>
                        </span>
                        <p class="text-grey-700 my-2">{{ option.name }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <p v-show="error" class="p-input-error text-red mt-1">{{ error }}</p>
    </div>
</template>
<script>
export default {
    name: "product-select",
    props: {
        value: {},
        options: {},
        error: {},
        label: {},
        disabled: {},
    },
    model: {
        prop: "value",
        event: "input",
    },
    $_veeValidate: {
        name() {
            return this.label;
        },
        value() {
            return this.value;
        },
    },
    computed: {
        filteredOptions() {
            return this.options.filter(option => {
                if (this.searchValue.length === 0) {
                    return true;
                }
                return option.name
                    .toLocaleLowerCase()
                    .startsWith(this.searchValue.toLocaleLowerCase());
            });
        },
    },
    watch: {
        open() {
            // todo hack for ref
            setTimeout(() => {
                this.$refs.search.focus();
            }, 1);
        },
        value() {
            this.findValue();
        },
    },
    data() {
        return {
            open: false,
            selected: {},
            searchValue: "",
        };
    },
    methods: {
        toggleSelectList() {
            if (!this.disabled) {
                this.open = !this.open;
            }
        },
        selectCountry(option) {
            this.selected = option;
            this.toggleSelectList();
            this.searchValue = "";
            this.$emit("input", option.code);
        },
        findValue() {
            this.selected = this.options.find(({ code }) => code === this.value) || {};
        },
    },

    mounted() {
        this.findValue();
    },
};
</script>

<style lang="scss">
.dropdown-icon-open {
    transition: transform 0.3s;
    transform: rotate(180deg);
}
.dropdown-icon-closed {
    transition: transform 0.3s;
    transform: rotate(0deg);
}
.option:hover {
    background-color: rgba(51, 119, 255, 0.08);
}
.p-input-error {
    font-size: 12px;
    line-height: 16px;
}
</style>
