<template>
    <div>
        <div class="flex justify-center">
            <div class="bg-emerald-50 p-4 rounded-full mb-12">
                <Feather type="check" class="h-20 w-20 fill-current text-emerald-500" />
            </div>
        </div>
        <div>
            <h3 class="mb-2">Payment succeeded</h3>
            Your order has been successfully placed, and a confirmation sent to your email address.
            If you need to get in touch, your order number is
            <strong>{{ orderKey.split("-").pop() }}</strong>
            <button
                @click="emit('returnToStore')"
                class="mt-4 bg-cobalt-500 font-semibold rounded p-3 text-center text-white w-full focus:outline-non hover:bg-cobalt-700"
            >
                Return to store
            </button>
        </div>
    </div>
</template>

<script setup>
import store from "@/store/store";

const props = defineProps({ orderKey: String, orderValue: Number });
const emit = defineEmits(["returnToStore"]);

(function (t, a, p) {
    t.TapfiliateObject = a;
    t[a] =
        t[a] ||
        function () {
            (t[a].q = t[a].q || []).push(arguments);
        };
})(window, "tap");

tap("create", "44271-8889f8", { integration: "javascript" });
tap("conversion", props.orderKey, props.orderValue, {
    customer_id: store.getters.account.id,
});
</script>
