<template>
    <div>
        <label for="dob" class="text-grey-700 font-semibold block w-full text-sm">
            <slot />
        </label>
        <div class="flex -mx-1">
            <div class="px-1 w-1/4">
                <input
                    type="text"
                    placeholder="MM"
                    v-model="month"
                    class="rounded mt-1 appearance-none border-sm text-center py-2 border border-grey-500 bg-grey-50 focus:outline-none focus:border-cobalt-500 w-full"
                />
            </div>
            <div class="px-1 w-1/4">
                <input
                    ref="day"
                    type="text"
                    placeholder="DD"
                    v-model="day"
                    class="rounded mt-1 appearance-none border-sm text-center py-2 border border-grey-500 bg-grey-50 focus:outline-none focus:border-cobalt-500 w-full"
                />
            </div>
            <div class="px-1 w-1/2">
                <input
                    ref="year"
                    type="text"
                    placeholder="YYYY"
                    v-model="year"
                    class="rounded mt-1 appearance-none border-sm text-center py-2 border border-grey-500 bg-grey-50 focus:outline-none focus:border-cobalt-500 w-full"
                />
            </div>
        </div>
    </div>
</template>

<script>
/**
 * To use `input` to make this reactive - and, as the date of birth can be
 * either string or object - I've defined the following computed property on the
 * parent component (used in EditLifetime.vue):
 *
    methodName() {
        if (typeof this.propName === 'string') {
            const [year, month, day] = this.propName.split('-');
            return { year, month, day };
        }
        return this.propName;
    },
 */

export default {
    props: {
        modelValue: {},
    },
    data() {
        if (typeof this.modelValue === "string") {
            return {
                year: this.modelValue.substr(0, 4),
                month: this.modelValue.substr(5, 2),
                day: this.modelValue.substr(8, 2),
            };
        }

        return {
            month: this.modelValue && this.modelValue.month ? this.modelValue.month : "",
            day: this.modelValue && this.modelValue.day ? this.modelValue.day : "",
            year: this.modelValue && this.modelValue.year ? this.modelValue.year : "",
        };
    },
    watch: {
        month(value) {
            if (value.length > 1) {
                this.$refs.day.focus();
            }
        },
        day(value) {
            if (value.length > 1) {
                this.$refs.year.focus();
            }
        },
        combinedDate() {
            this.$emit("update:modelValue", this.combinedDate);
        },
    },
    computed: {
        combinedDate() {
            const day = this.day.substr(0, 2).padStart(2, "0");
            const month = this.month.substr(0, 2).padStart(2, "0");
            const year = this.year.substr(0, 4).padStart(4, "0");
            return {
                year,
                month,
                day,
                isValid: this.isValid,
            };
        },
        isValid() {
            return this.dayIsValid && this.monthIsValid && this.yearIsValid;
        },
        dayIsValid() {
            return this.day >= 1 && this.day <= 31;
        },
        monthIsValid() {
            return this.month >= 1 && this.month <= 12;
        },
        yearIsValid() {
            return this.year >= 1880 && this.year <= new Date().getFullYear();
        },
    },
};
</script>
