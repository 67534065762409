<template>
    <portal to="secondary-navigation">
        <nav class="hidden sm:flex sm:space-x-8 sm:py-4 bg-white px-8" aria-label="Global">
            <router-link
                v-slot="{ isExactActive }"
                v-for="item in navigation"
                :key="item.name"
                :to="item.to"
            >
                <span
                    :class="[
                        isExactActive
                            ? 'bg-cobalt-50 text-cobalt-500'
                            : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                        'inline-flex items-center rounded-md py-2 px-3 text-sm font-medium',
                    ]"
                    >{{ item.name }}</span
                >
            </router-link>
        </nav>
    </portal>
    <router-view
        v-if="loadingStatus === 'loaded'"
        :introduction="introduction"
        :sample-group="sampleGroup"
        :sample-sites="sampleSites"
    />
</template>

<script setup>
import { getRequiredContent } from "@/composables/useClassicalContent";
import { useLoading } from "@/composables/useLoading";
import { inject, ref } from "vue";

const { loadingStatus, finishLoading } = useLoading();
const introduction = ref({});
const sampleGroup = ref({});
const sampleSites = ref({});

const result = inject("classical-result");

getRequiredContent([
    "roman-introduction",
    result.value.romans.closest_sample.slug,
    "sample-site-locations",
])
    .then(([introductionResult, sampleGroupResult, sampleSitesResult]) => {
        introduction.value = introductionResult;
        sampleGroup.value = sampleGroupResult;
        sampleSites.value = sampleSitesResult;
    })
    .finally(finishLoading);

const navigation = [
    { name: "Overview", to: { name: "roman-overview" } },
    { name: "Result", to: { name: "roman-result" } },
    { name: "Map", to: { name: "roman-map" } },
    { name: "References", to: { name: "roman-references" } },
];
</script>
