<template>
    <img :src="image" class="rounded-full inline h-24 w-24 mx-auto" />
</template>

<script>
export default {
    props: {
        image: {
            required: true,
            type: String,
        },
    },
};
</script>
