<template>
    <div class="w-full h-full bg-grey-50 flex items-center justify-center">
        <main
            style="max-width: 900px"
            class="md:w-11/12 w-full md:p-0 p-6 flex md:flex-row flex-col items-center"
        >
            <div class="flex items-center justify-center md:w-3/5 sm:w-4/5 w-full md:mr-12">
                <img class="" src="../../../public/vue-public/region-picker.png" alt="" />
            </div>
            <form
                class="md:w-2/5 sm:w-4/5 w-full font-light"
                @submit.prevent="onSubmit('country-picker')"
                data-vv-scope="country-picker"
            >
                <span
                    class="bg-emerald-50 rounded text-xs text-emerald-500 leading-none px-2 py-1"
                    aria-hidden="true"
                    >New</span
                >
                <h1 class="text-denim-700 mb-2 text-3xl font-normal leading-snug">
                    Introducing our new Living DNA store
                </h1>
                <p class="text-grey-700 font-normal leading-normal mb-4">
                    Please confirm your location to unlock access to the store, where you can now
                    purchase upgrades and products.
                </p>
                <CountryCodePicker v-model="country" />
                <button
                    class="min-w-200 rounded bg-cobalt-500 text-white font-semibold px-4 py-3 focus:outline-none mt-4"
                    :class="{ warning: error }"
                    type="submit"
                >
                    {{ buttonText() }}
                </button>
            </form>
        </main>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductSelect from "@/views/store/product/ProductSelect.vue";
import isCountryIncluded from "@/services/country-picker";
import countries from "@/countries";
import CountryCodePicker from "@/components/CountryPicker/CountryCodePicker.vue";

export default {
    name: "country-picker",
    components: {
        ProductSelect,
        CountryCodePicker,
    },
    data: () => ({
        country: "",
        countries,
        error: false,
    }),
    methods: {
        async onSubmit() {
            await this.$store.dispatch("SET_REGION", {
                data: this.country,
            });
            if (this.$route.params.slug) {
                this.$router.push({ name: "product", params: { slug: this.$route.params.slug } });
            } else {
                this.$router.push({ name: "products" });
            }
        },
        buttonText() {
            return this.error ? "Please select a country" : "View store";
        },
    },
    computed: {
        ...mapGetters(["getRegion"]),
        currentCountry() {
            return this.countries.find((country) => isCountryIncluded(country.code));
        },
    },
    mounted() {
        if (!this.getRegion) {
            this.country = this.currentCountry.code;
        }
        if (this.getRegion) {
            this.country = this.getRegion;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/base/defines.scss";

@media (max-width: 768px) {
    .country-page {
        padding: 36px 0;
        flex-direction: column;
    }
}

.country-image {
    min-width: 250px;
    max-width: 440px;
}

.picker-container {
    width: 295px;
}

.new {
    padding: 4px 8px;
    border-radius: 5px;
    background: rgba(92, 176, 66, 0.08);
    // background: #f2f9f0;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 300;
    color: #5cb042;
}

.warning {
    background: $alert-color;
}
</style>
