import { Machine, assign, interpret, send } from "xstate";
import ProductService from "@/services/product";
import OrderService from "@/services/order";
import { CreateStripeOrder, CreateFreeOrder } from "@/views/store/product/productService";
import { createVitlabUrl, getVitaminReport } from "@/services/wellbeing/vitlab";

let globalButton = {};

export const purchasingProductMachine = Machine(
    {
        initial: "determiningFirstStep",
        context: {
            product: undefined,
            productVariant: undefined,
            productPrice: undefined,
            order: undefined,
            shippingOptions: undefined,
            selectedShippingOption: undefined,
            totalPrice: undefined,
            billingAddress: undefined,
            shippingAddress: undefined,
            paymentError: undefined,
            stripe: undefined,
            mount: undefined,
            profile: undefined,
            card: undefined,
        },
        states: {
            determiningFirstStep: {
                on: {
                    "": [
                        {
                            cond: "isFreeProduct",
                            target: "confirmFreeProduct",
                        },
                        {
                            cond: "isNotShippable",
                            target: "choosingShippingAndPaymentMethod",
                        },
                        {
                            cond: "hasWarningMessage",
                            target: "confirmConditions",
                        },
                        {
                            target: "enterShippingDetails",
                        },
                    ],
                },
            },
            confirmConditions: {
                on: {
                    CONFIRM: [
                        {
                            cond: "isShippable",
                            target: "enterShippingDetails",
                        },
                        {
                            target: "choosingShippingAndPaymentMethod",
                        },
                    ],
                },
            },
            confirmFreeProduct: {
                on: {
                    CONFIRM: "confirmingFreeProduct",
                    SET_ADDRESS: {
                        actions: "cacheBillingAddress",
                    },
                },
            },
            confirmingFreeProduct: {
                invoke: {
                    src: "confirmFreeProduct",
                    onDone: "freeProductSuccess",
                    onError: "freeProductError",
                },
            },
            freeProductSuccess: {},
            freeProductError: {},
            enterShippingDetails: {
                on: {
                    CONFIRM: "fetchingShippingOptions",
                    SET_ADDRESS: {
                        actions: "cacheShippingAddress",
                    },
                },
            },
            changingShippingAddress: {
                on: {
                    CHANGE_SHIPPING_COUNTRY: "updatingShippingOptions",
                    CONFIRM: "enterCardDetails",
                    INPUT: {
                        actions: "cacheShippingAddress",
                    },
                },
            },
            updatingShippingOptions: {
                invoke: {
                    src: "fetchShippingOptions",
                    onDone: {
                        target: "chooseShippingOption",
                        actions: ["cacheShippingOptions", "setInitialShippingOption"],
                    },
                },
            },
            chooseShippingOption: {
                on: {
                    CONFIRM: {
                        target: "enterCardDetails",
                        actions: "cacheTotalPrice",
                    },
                    INPUT: {
                        actions: "cacheShippingAddress",
                    },
                    CHANGE_SHIPPING_COUNTRY: {
                        target: "updatingShippingOptions",
                        actions: "cacheShippingAddress",
                    },
                },
            },
            fetchingShippingOptions: {
                invoke: {
                    src: "fetchShippingOptions",
                    onDone: {
                        target: "choosingShippingAndPaymentMethod",
                        actions: ["cacheShippingOptions", "setInitialShippingOption"],
                    },
                },
            },
            choosingShippingAndPaymentMethod: {
                entry: ["cacheTotalPrice", "createPayPalButton"],
                on: {
                    SET_SHIPPING_OPTION: {
                        actions: [
                            "setSelectedShippingOption",
                            "cacheTotalPrice",
                            "createPayPalButton",
                        ],
                    },
                    PAY_BY_CARD: "enterBillingDetails",
                    START_PURCHASING_PRODUCT: {
                        actions: "createPayPalButton",
                    },
                    CHANGE_SHIPPING_ADDRESS: "enterShippingDetails",
                    PAYPAL_PAYMENT_PLACED: "confirmingPaypalOrder",
                },
            },
            confirmingPaypalOrder: {
                invoke: {
                    src: "confirmPaypalOrder",
                    onDone: {
                        target: "paymentSucceeded",
                        actions: "cacheOrderFromResponseBody",
                    },
                    onError: {
                        target: "paypalPaymentFailed",
                        actions: "cachePaypalError",
                    },
                },
            },
            enterBillingDetails: {
                on: {
                    SET_ADDRESS: {
                        actions: "cacheBillingAddress",
                    },
                    CONFIRM: {
                        target: "enterCardDetails",
                        actions: "cacheMountFunction",
                    },
                },
            },
            changingBillingAddress: {
                on: {
                    SET_ADDRESS: {
                        actions: "cacheBillingAddress",
                    },
                    CONFIRM: {
                        target: "enterCardDetails",
                    },
                },
            },
            fetchingPaymentIntentClientSecret: {
                invoke: {
                    src: "fetchPaymentIntentClientSecret",
                    onDone: {
                        target: "chargingCard",
                        actions: "cacheOrderFromResponseBody",
                    },
                    onError: {
                        target: "errorFetchingPaymentIntent",
                        actions: (ctx, event) => {
                            console.log(ctx, event);
                        },
                    },
                },
            },
            enterCardDetails: {
                entry: "mountCard",
                on: {
                    CHANGE_SHIPPING_ADDRESS: "changingShippingAddress",
                    CHANGE_BILLING_ADDRESS: "changingBillingAddress",
                    CHANGE_PAYMENT_METHOD: "choosingShippingAndPaymentMethod",
                    START_PURCHASING_PRODUCT: { actions: "mountCard" },
                    CHARGE_CARD: {
                        target: "fetchingPaymentIntentClientSecret",
                        actions: "cacheStripeAndCard",
                    },
                },
            },
            chargingCard: {
                invoke: {
                    src: "chargeOrder",
                    onDone: "paymentSucceeded",
                    onError: {
                        target: "paymentFailed",
                        actions: "cacheStripeChargeError",
                    },
                },
            },
            paymentSucceeded: {
                on: {
                    PAYMENT_MODAL_CLOSED: "paymentFlowCompleted",
                },
            },
            paymentFlowCompleted: {
                type: "final",
            },
            paymentFailed: {
                on: {
                    PAYMENT_MODAL_CLOSED: "enterCardDetails",
                },
            },
            paypalPaymentFailed: {
                on: {
                    PAYMENT_MODAL_CLOSED: "choosingShippingAndPaymentMethod",
                },
            },
            errorFetchingPaymentIntent: {
                on: {
                    PAYMENT_MODAL_CLOSED: "enterCardDetails",
                },
            },
            purchased: {
                type: "final",
            },
        },
    },
    {
        services: {
            fetchShippingOptions: (context) =>
                OrderService.getShippingOptions(
                    context.productVariant.sku,
                    context.shippingAddress.countryCode
                ),
            fetchPaymentIntentClientSecret: async (context) => {
                const order = new CreateStripeOrder(
                    context.profile.barcode,
                    context.productVariant,
                    context.shippingAddress,
                    context.billingAddress,
                    context.selectedShippingOption,
                    context.totalPrice
                );
                return OrderService.createPaymentIntentForOrder(order);
            },
            confirmFreeProduct: async (context) => {
                const order = new CreateFreeOrder(
                    context.profile.barcode,
                    context.productVariant,
                    context.shippingAddress,
                    context.billingAddress,
                    context.selectedShippingOption
                );
                const res = await OrderService.checkoutOrder(order);
                return res.data;
            },
            confirmPaypalOrder: (context, event) => OrderService.confirmPaypalOrder(event.order),
            chargeOrder: (context) =>
                OrderService.chargeStripeOrder(
                    context.order.paymentIntentClientSecret,
                    context.stripe.confirmCardPayment,
                    context.card
                ),
        },
        actions: {
            cacheShippingOptions: assign({
                shippingOptions: (_, event) => event.data,
            }),
            setInitialShippingOption: assign({
                selectedShippingOption: (_, event) => event.data[0],
            }),
            setSelectedShippingOption: assign({
                selectedShippingOption: (_, event) => event.data,
            }),
            cacheOrderFromResponseBody: assign({
                order: (_, event) => {
                    console.log(event);
                    return event.data.data.order;
                },
            }),
            cacheTotalPrice: assign({
                totalPrice: (context) =>
                    context.productVariant.shippable
                        ? context.selectedShippingOption.shippingPrice + context.productPrice * 100
                        : context.productPrice * 100,
            }),
            cacheBillingAddress: assign({
                billingAddress: (context, event) => {
                    console.log(event);
                    return event.address;
                },
            }),
            cacheShippingAddress: assign({
                shippingAddress: (context, event) => {
                    console.log(event);
                    return event.address;
                },
            }),
            cachePaypalError: assign({
                paymentError: (_, event) => {
                    console.error(event);
                    if (event.data && event.data.body && event.data.body.message) {
                        return `There was an issue placing your order. Your PayPal account/card may have been charged. You should contact LivingDNA's support and quote the error ${event.data.body.message}.`;
                    }
                    return "There was an issue placing your order. Your PayPal account/card may have been charged. You should contact LivingDNA's support";
                },
            }),
            cacheStripeAndCard: assign({
                stripe: (context, event) => event.stripe,
                card: (context, event) => event.card,
            }),
            cacheMountFunction: assign({
                mount: (_, event) => event.mount,
            }),
            mountCard: (context) => {
                setTimeout(() => {
                    context.mount("#card-element");
                }, 100);
            },
            cacheStripeChargeError: assign({
                paymentError: (_, event) => event.data.message,
            }),
            createPayPalButton: (context) => {
                if (globalButton.hide) {
                    globalButton.hide();
                }
                setTimeout(async () => {
                    // eslint-disable-next-line
                    const paypalButton = await OrderService.createPaypalButton(
                        context,
                        sendEventToPurchaseService
                    );
                    console.log(paypalButton);
                    globalButton = paypalButton;
                }, 50);
            },
        },
        guards: {
            isShippable: (context) => context.productVariant.shippable,
            isNotShippable: (context) => !context.productVariant.shippable,
            isFreeProduct: (context) => context.productVariant.price.base === 0,
            hasWarningMessage: (context) => context.product.warningMessage,
        },
    }
);

export const productMachine = Machine(
    {
        id: "productMachine",
        initial: "idle",
        context: {
            user: undefined,
            profile: undefined,
            productSlug: undefined,
            product: undefined,
            productVariant: undefined,
            vitlabString: "",
            vitlabUrl: "",
            warningMessage: "",
        },
        states: {
            idle: {
                on: {
                    FETCH_PRODUCT: {
                        target: "fetchingProductInformation",
                        actions: "cacheRequiredInformation",
                    },
                },
            },
            fetchingProductInformation: {
                invoke: {
                    src: "fetchProductInformation",
                    onDone: {
                        target: "validatingInsights",
                        actions: [
                            "cacheProductAndVariant",
                            "cacheProductPrice",
                            "setPayPalScriptInHeader",
                        ],
                    },
                    onError: "productLoadFailed",
                },
            },
            productLoadFailed: {
                on: {
                    FETCH_PRODUCT: {
                        target: "fetchingProductInformation",
                    },
                },
            },
            validatingInsights: {
                invoke: {
                    src: "validateInsights",
                    onDone: { actions: ["cacheWarningMessage"], target: "checkingProductType" },
                    onError: {
                        target: "insightsNotValidated",
                        actions: "cacheFailedInsightsErrorMessage",
                    },
                },
            },
            checkingProductType: {
                on: {
                    "": [
                        {
                            cond: (context) => context.product.category === "Vitamins supplement",
                            target: "#productMachine.productLoaded.vitlabProduct",
                        },
                        "#productMachine.productLoaded.ldnaProduct",
                    ],
                },
            },
            productLoaded: {
                initial: "ldnaProduct",
                states: {
                    ldnaProduct: {
                        on: {
                            START_PURCHASING_PRODUCT: "#productMachine.purchasingProduct",
                            FETCH_PRODUCT: {
                                target: "#productMachine.fetchingProductInformation",
                                actions: "cacheRequiredInformation",
                            },
                            RESET: "#productMachine.idle",
                        },
                    },
                    vitlabProduct: {
                        id: "vitlabProduct",
                        initial: "fetchingVitlabString",
                        states: {
                            fetchingVitlabString: {
                                invoke: {
                                    src: (context) => getVitaminReport(context.profile.barcode),
                                    onDone: {
                                        actions: ["cacheVitlabString", "createVitlabUrl"],
                                        target: "#vitlabProduct.vitlabProductReady",
                                    },
                                },
                            },
                            vitlabProductReady: {
                                on: {
                                    START_PURCHASING_PRODUCT: {
                                        actions: (context) => {
                                            // const confirm = window.confirm(`You are about to be redirected to the vitlab site at the url ${context.vitlabUrl}`);
                                            // if (confirm)
                                            window.open(context.vitlabUrl);
                                        },
                                    },
                                    RESET: "#productMachine.idle",
                                },
                            },
                        },
                    },
                },
            },
            purchasingProduct: {
                invoke: {
                    id: "purchaser",
                    src: "purchasingProductMachine",
                    data: {
                        profile: (context) => context.profile,
                        product: (context) => context.product,
                        productVariant: (context) => context.productVariant,
                        productPrice: (context) => context.productPrice,
                        shippingAddress: {},
                        billingAddress: {},
                    },
                    onDone: "productLoaded",
                },
                on: {
                    CONFIRM: {
                        actions: send("CONFIRM", {
                            to: "purchaser",
                        }),
                    },
                    INPUT: {
                        actions: send("INPUT", {
                            to: "purchaser",
                        }),
                    },
                    /*
                     * This is an interesting one. It handles the situation where we're on the final
                     * checkout page, close the modal, and then reopen it. The Stripe elements disappear
                     * when the modal is closed. In order to re-render them we forward this event. It
                     * only ever applies an action in the 'enteringCardDetails' state, in which case it
                     * causes the card element to remount.
                     * */
                    START_PURCHASING_PRODUCT: {
                        actions: send("START_PURCHASING_PRODUCT", {
                            to: "purchaser",
                        }),
                    },
                    PAYPAL_PAYMENT_PLACED: {
                        actions: send("PAYPAL_PAYMENT_PLACED", {
                            to: "purchaser",
                        }),
                    },
                    FETCH_PRODUCT: {
                        target: "fetchingProductInformation",
                        actions: "cacheRequiredInformation",
                    },
                },
            },
            insightsNotValidated: {
                on: {
                    FETCH_PRODUCT: {
                        target: "fetchingProductInformation",
                        actions: "cacheRequiredInformation",
                    },
                },
            },
        },
    },
    {
        actions: {
            cacheRequiredInformation: assign({
                productSlug: (context, event) => event.slug,
                profile: (context, event) => event.profile,
                user: (context, event) => event.user,
            }),
            cacheProductAndVariant: assign({
                product: (_, event) => event.data,
                productVariant: (_, event) => event.data.variants.find((variant) => variant.active),
            }),
            cacheProductPrice: assign({
                productPrice: (context, event) => {
                    if (context.productVariant.price && context.productVariant.price.isOnSale) {
                        return context.productVariant.price.sale / 100;
                    }
                    if (context.productVariant.price && context.productVariant.price.base) {
                        return context.productVariant.price.base / 100;
                    }
                    return 0;
                },
            }),
            cacheFailedInsightsErrorMessage: assign({
                errorMessage: (context, event) => {
                    if (context.product.category === "Digital upgrade") {
                        return `You already have this upgrade for ${context.profile.name}'s profile. Switch profiles or choose a different upgrade.`;
                    }
                    return "Unfortunately you do not have the required tests to purchase this product.";
                },
            }),
            cacheVitlabString: assign({
                vitlabString: (context, event) => event.data.vitaminMix,
            }),
            createVitlabUrl: assign({
                vitlabUrl: (context) =>
                    createVitlabUrl({
                        vitlabString: context.vitlabString,
                        profile: context.profile,
                        user: context.user,
                        variant: context.productVariant,
                    }),
            }),
            setPayPalScriptInHeader: (context) => {
                const { currency } = context.productVariant;
                if (document.getElementById("paypal_script") !== null) {
                    document.getElementById("paypal_script").outerHTML = "";
                }
                const script = document.createElement("script");
                script.src = `https://www.paypal.com/sdk/js?currency=${currency}&client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&disable-funding=card,ideal`;
                script.id = "paypal_script";
                const head = document.getElementsByTagName("head")[0];
                head.appendChild(script);
            },
            cacheWarningMessage: assign({
                warningMessage: (ctx, event) =>
                    event.data
                        ? "Based on your ancestry profile, your Viking index score is likely to be low and/or uninformative. You might not wish to purchase this upgrade."
                        : "",
            }),
        },
        services: {
            fetchProductInformation: (context) => ProductService.getProduct(context.productSlug),
            validateInsights: (context) =>
                new Promise(async (resolve, reject) => {
                    const { valid, warning } = await ProductService.validateInsights(
                        context.productVariant.sku,
                        context.profile.barcode
                    );
                    valid ? resolve(warning) : reject();
                }),
            purchasingProductMachine,
        },
    }
);

export const productService = interpret(productMachine).start();

function sendEventToPurchaseService(event, payload) {
    productService.state.children.purchaser.send(event, payload);
}
