<template>
    <div class="bg-white min-h-screen p-6 pt-12">
        <div>
            <router-link
                :to="{ name: 'family-matching-start' }"
                class="bg-white font-semibold flex items-center"
            >
                <feather type="chevron-left" class="h-5 w-5 mr-2" />
                Back
            </router-link>

            <div class="mx-auto pt-16" :class="$route.meta.fullWidth ? 'max-w-3xl' : 'max-w-xs'">
                <main>
                    <h1 class="font-normal text-2xl">Display name</h1>

                    <p>Choose how you would like your name to appear to your DNA matches.</p>

                    <form
                        v-if="displayNameMachine"
                        @submit.prevent="displayNameService.send('SAVE')"
                    >
                        <text-input
                            label="Name"
                            :value="displayNameContext.name"
                            @input.native="
                                displayNameService.send({
                                    type: 'INPUT',
                                    name: $event.target.value,
                                })
                            "
                        />

                        <checkbox-input
                            :label="termsText"
                            @input.native="
                                displayNameService.send({
                                    type: 'INPUT',
                                    terms: $event.target.checked,
                                })
                            "
                            :value="displayNameContext.terms"
                        />
                        <checkbox-input
                            label="(optional) I would like to be shown to DNA matches on partner sites"
                            @input.native="
                                displayNameService.send({
                                    type: 'INPUT',
                                    partners: $event.target.checked,
                                })
                            "
                            :value="displayNameContext.partners"
                        />

                        <!--                        <div-->
                        <!--                            v-if="displayNameCurrent.matches('errorSavingName')"-->
                        <!--                            class="mt-2 text-sm text-rose-500"-->
                        <!--                        >-->
                        <!--                            &lt;!&ndash;                {{ currentNameState.context.errorMessage }}&ndash;&gt;-->
                        <!--                        </div>-->

                        <SubmitButton
                            icon="save"
                            :disabled="matchesAny(['idle', 'inValidForm'])"
                            :class="{ loading: matchesAny(['processing']) }"
                            hide-icon
                        >
                            Save
                        </SubmitButton>
                    </form>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {
    optInService,
    optIntCurrent,
    optInContext,
} from "@/views/FamilyNetworks/machines/optInMachine";
import { interpret } from "xstate";
import displayNameMachine from "@/views/FamilyNetworks/machines/displayNameMachine";
import TextInput from "@/components/inputs/TextInput.vue";
import SubmitButton from "@/components/button/SubmitButton.vue";
import { mapGetters } from "vuex";
import CheckboxInput from "@/views/FamilyNetworks/FamilyMatching/OptInFlow/CheckboxInput.vue";

export default {
    name: "family-matching-display-name",
    components: {
        CheckboxInput,
        TextInput,
        SubmitButton,
    },
    computed: {
        ...mapGetters(["profile", "fullProfile"]),
        // displayNameService() {
        //     return interpret(this.displayNameMachine);
        // },
    },
    data: () => ({
        termsText:
            'I agree to the <a class="text-cobalt-500">Terms of Service</a> and <a class="text-cobalt-500">privacy policy</a>',
        displayNameMachine: undefined,
        displayNameCurrent: undefined,
        displayNameContext: undefined,
        displayNameService: undefined,
    }),
    methods: {
        getStarted() {
            this.$router.push({ name: "family-matching-display-name" });
            optInService.send("GET_STARTED");
        },
        matchesAny(states) {
            return !!states.find((state) => this.displayNameCurrent.matches(state));
        },
    },
    async created() {
        if (!this.fullProfile) {
            await this.$store.dispatch("GET_FULL_PROFILE", this.profile.barcode);
        }
        // create service
        optInService
            .onTransition((state) => {
                if (state.value.optedIn === "hasNotBeenRun") {
                    this.$router.push({ name: "list-view" });
                }
            })
            .start();
        this.displayNameMachine = displayNameMachine.withContext({
            ...displayNameMachine.context,
            name: this.fullProfile.profile.name,
            barcode: this.fullProfile.profile.barcode,
            profile: this.fullProfile.profile,
            http: axios,
        });
        this.displayNameCurrent = this.displayNameMachine.initialState;
        this.displayNameContext = this.displayNameMachine.context;
        this.displayNameService = interpret(this.displayNameMachine);
        this.displayNameService
            .onTransition((state) => {
                if (state.value === "saved") {
                    optInService.send({ type: "DISPLAY_NAME_SET" });
                    this.$store.dispatch("SET_KIT_TESTS", this.$store.getters.profile.barcode);
                }
                // Update the current state component data property with the next state
                this.displayNameCurrent = state;
                // Update the context component data property with the updated context
                this.displayNameContext = state.context;
            })
            .start();
    },
};
</script>
