<template>
    <div>
        <button
            @click="show = true"
            class="flex items-center text-cobalt-500 mt-4 mb-12 md:mb-0 text-left"
        >
            <feather type="help-circle" class="h-5 w-5 fill-current mr-2 flex-none"></feather>
            <span class="font-semibold">{{ linkText }}</span>
        </button>
        <Modal width="xl" :open="show">
            <div class="flex flex-col relative h-full">
                <button @click="show = false" class="cursor-pointer absolute top-0 right-0">
                    <feather type="x" class="h-5 w-5 fill-current text-grey-500 mr-6 mt-6" />
                </button>
                <h3 class="text-2xl font-normal mx-12 mt-12 flex-0">
                    {{ linkText }}
                </h3>
                <div class="flex-1 overflow-auto relative">
                    <div class="mx-12 mb-4 no-inner-p-margin" style="padding-top: 20px">
                        <div v-html="definition"></div>
                    </div>
                </div>
                <div class="mb-12 mx-12 flex-0 mt-6">
                    <button
                        role="button"
                        class="bg-cobalt-500 text-white py-2 w-full rounded font-semibold flex items-center justify-center"
                        @click="show = false"
                    >
                        <Feather type="corner-up-left" class="h-5 w-5 fill-current mr-2" />
                        Return to report
                    </button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { ref } from "vue";

export default {
    props: {
        linkText: {
            required: true,
            type: String,
        },
        definition: {
            required: true,
            type: String,
        },
    },
    components: { Modal },
    setup() {
        const show = ref(false);
        return { show };
    },
};
</script>
