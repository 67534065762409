<template>
    <RightImageCard :image="{ src: image }" image-class="w-48 ml-auto">
        <template v-slot:body>
            <div class="w-full md:w-3/5 p-6 flex flex-col">
                <h3 class="text-sm text-grey-500">My reports</h3>
                <div class="mt-2 mb-4">
                    <div
                        class="flex items-center mt-3"
                        v-for="report in limitedReports"
                        :key="report.traitCmsData.slug"
                    >
                        <ScaleMapper
                            :key="report.type"
                            :level="Number(report.phenotypeCmsData.scalePosition.value)"
                            v-slot="{ primaryColour, secondaryColour, result }"
                        >
                            <div
                                class="font-semibold px-2 py-px inline mr-4 text-sm rounded"
                                :style="{
                                    backgroundColor: secondaryColour,
                                    color: primaryColour,
                                }"
                            >
                                {{ result }}
                            </div>
                        </ScaleMapper>
                        <div>
                            {{ report.traitCmsData.title }}
                        </div>
                    </div>
                    <div v-if="reports.length > 3" class="text-sm text-gray-500 mt-3">
                        + {{ totalReports }} more reports
                    </div>
                </div>
                <router-link
                    :to="{ name: 'wellbeing' }"
                    class="flex items-center mt-auto text-cobalt-500"
                >
                    <feather type="clipboard" class="h-5 w-5 mr-2"></feather>
                    <span class="font-semibold">All reports</span>
                </router-link>
            </div>
        </template>
    </RightImageCard>
</template>

<script>
import image from "@/assets/images/wellbeing/lemons-blue-tall.png";
import Feather from "vue-feather";
import RightImageCard from "@/components/cards/RightImageCard.vue";
import ScaleMapper from "@/components/Wellbeing/ScaleMapper.vue";

export default {
    components: { RightImageCard, Feather, ScaleMapper },
    props: { reports: Array },
    data() {
        return {
            image,
        };
    },
    created() {
        console.log({ reports: this.reports });
    },
    computed: {
        limitedReports() {
            const reportsWithResults = this.reports.filter((report) => !!report.phenotypeCmsData);
            return reportsWithResults.slice(0, 3);
        },
        totalReports() {
            return this.$store.getters.wellbeing.length - 3;
        },
    },
};
</script>
