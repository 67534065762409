<template>
    <ThePage class="max-w-3xl py-16 prose prose-a:no-underline">
        <div v-if="!orderStore.orderDetails.id" class="bg-white p-6 rounded">
            Looks like the order information couldn't be loaded. This might be because you have
            refreshed the page. If you need any assistance please contact
            <a href="mailto:help@livingdna.com" class="text-cobalt-500 font-semibold"
                >help@livingdna.com</a
            >
        </div>
        <div v-else>
            <div class="text-center">
                <div class="space-y-6">
                    <FeatherWrapper
                        icon="check"
                        :classes="['h-16 w-16 text-emerald-500 block']"
                        :container-classes="['p-6 bg-emerald-50 rounded-full inline-block m-auto']"
                    />
                    <h3 class="font-semibold text-gray-900 text-xl">Payment Succeeded</h3>
                </div>
            </div>
            <div class="mt-12">
                <p>
                    Your Order ID is
                    <strong class="font-bold">{{
                        orderStore.orderDetails.id.split("-").pop()
                    }}</strong>
                </p>
            </div>
            <template v-if="orderStore.orderDetails.product === 'autosomal-upgrade'">
                <div
                    v-if="orderStore.productDetails.postUpgradeText"
                    v-html="orderStore.productDetails.postUpgradeText"
                />
                <div v-if="loadingStatus === 'loading'">
                    <div class="bg-white p-6">
                        <PageLoader />
                    </div>
                </div>
                <AlertMessage
                    v-else-if="
                        orderStore.productDetails.name === 'Autosomal Upgrade' &&
                        $store.getters.kitTests.autosomal.complete
                    "
                    type="info"
                    icon="helix"
                    class="max-w-xl mt-12"
                >
                    <template #title>Your upgraded results are available</template>
                    <template #body>
                        <p>
                            Good news! Your upgrade is complete and you can now view your results in
                            more detail
                        </p>
                        <router-link
                            :to="{ name: 'recent-map' }"
                            class="px-4 py-2 bg-cobalt-500 hover:bg-cobalt-700 text-white rounded inline-block mt-4 font-semibold"
                            >View your results here</router-link
                        >
                    </template>
                </AlertMessage>
                <AlertMessage v-else type="warning" icon="helix" class="max-w-2xl mt-12">
                    <template #title>Your results are still processing</template>
                    <template #body>
                        <p>
                            This process usually takes less than an hour. Please get in touch with
                            support at
                            <a
                                href="mailto:help@livingdna.com"
                                class="text-cobalt-500 font-semibold"
                                >help@livingdna.com</a
                            >
                            if you have any concerns
                        </p>
                    </template>
                </AlertMessage>
                <div class="mt-16">
                    <h2 class="text-2xl font-bold text-gray-900 mb-6">What to look out for</h2>
                    <div
                        v-for="(feature, index) in orderStore.productDetails.features"
                        :key="feature.title"
                    >
                        <div
                            class="flex max-md:flex-col max-md:gap-4 max-md:mb-12 items-center gap-12 my-6"
                            :class="index % 2 !== 0 ? 'flex-row-reverse' : ''"
                        >
                            <div class="prose">
                                <h3 class="text-lg font-semibold">{{ feature.title }}</h3>
                                <div v-html="feature.description" />
                            </div>
                            <img
                                :src="feature.image"
                                :alt="feature.title"
                                class="flex-none h-48 w-48 rounded-full object-cover border border-gray-300 m-0"
                            />
                        </div>
                    </div>
                    <div class="text-center">
                        <router-link
                            :to="{ name: 'recent-map' }"
                            class="px-4 py-2 bg-cobalt-500 hover:bg-cobalt-700 text-white rounded inline-block mt-4 font-semibold"
                            >View your results here</router-link
                        >
                    </div>
                </div>
            </template>
            <template v-else>
                Your order has been successfully placed, and a confirmation sent to your email
                address. If you need any help please contact
                <a href="mailto:help@livingdna.com" class="text-cobalt-500 font-semibold"
                    >help@livingdna.com</a
                >
                <div class="mt-6">
                    <router-link
                        :to="{ name: 'dashboard' }"
                        class="px-4 py-2 bg-cobalt-500 hover:bg-cobalt-700 text-white rounded inline-block font-semibold"
                        >Return to Dashboard</router-link
                    >
                </div>
            </template>
        </div>
    </ThePage>
</template>
<script setup>
import axios from "axios";
import ThePage from "@/components/Base/ThePage.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import { useOrderStore } from "@/store/orders";
import { useLoading } from "@/composables/useLoading";
import store from "@/store/store";
import endpoints from "@/endpoints";

const orderStore = useOrderStore();
const { loadingStatus, finishLoading } = useLoading();

setInterval(() => {
    if (!store.getters.kitTests.autosomal.complete) {
        store.dispatch("SET_KIT_TESTS", store.getters.profile.barcode);
    }
    finishLoading();
}, 3000);

if (orderStore.orderDetails.product === "personalised-ancestry-book-pdf") {
    axios.post(endpoints.bookPdf()).catch((err) => console.error(err));
}

tap("create", "44271-8889f8", { integration: "javascript" });
tap("conversion", orderStore.orderDetails.id, orderStore.orderDetails.value, {
    customer_id: store.getters.account.id,
});
</script>
