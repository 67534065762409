/* eslint-disable */
const SPLITNAME = "--REFRESHTOKEN--";

export default {
    request: function(req, token) {
        let splittoken = token.split(SPLITNAME);
        if (splittoken.length === 2) {
            if (req.url === this.options.refreshData.url) {
                req.data = {
                    refresh_token: splittoken[1],
                };
            }
        }

        if (req.url !== this.options.refreshData.url) {
            this.drivers.http.setHeaders.call(this, req, {
                Authorization: "Bearer " + splittoken[0],
            });
        }
    },

    response: function(res) {
        if (res.data.token && res.data.refresh_token) {
            const token = `${res.data.token}${SPLITNAME}${res.data.refresh_token}`;
            return token;
        }

        // let token = "";
        // let data = res.data;
        // if (data && data.hasOwnProperty("token")) {
        //     token = data.token;
        // }

        // console.log("here", { token, data });

        // if (data && data.hasOwnProperty("refresh_token")) {
        //     token += SPLITNAME + data.refresh_token;
        // }

        // console.log({ token });

        // return token;
    },
};
