import { defineStore } from "pinia";
import Autosomal, { createMapItem, flattenChildren } from "@/services/autosomal";
import store from "@/store/store";
import axios from "axios";
import endpoints from "@/endpoints";

export const useAutosomalStore = defineStore("autosomal", {
    state() {
        return {
            result: {},
            previousVersions: [],
            previousResults: {},
        };
    },
    getters: {
        regions(state) {
            return flattenChildren(state.result.regions);
        },
        subregions() {
            return flattenChildren(this.regions);
        },
        combinedRegionsAndSubregions() {
            return [...this.regions, ...this.subregions];
        },
        mapItems() {
            const itemsToMap = false ? this.result.regions : this.combinedRegionsAndSubregions;
            return itemsToMap.map(createMapItem);
        },
    },
    actions: {
        async getAutosomalResults(barcode = false) {
            try {
                const results = await Autosomal.getResults({
                    query: "speculative",
                    barcode: barcode || store.getters.profile.barcode,
                });

                const activeResult = results.testData.autosomal.active_result;
                const { speculative, ...rest } = results.testData.autosomal.data.current;

                this.result = {
                    ...rest,
                    activeResult,
                    regions: speculative,
                };

                this.previousVersions = results.testData.autosomal.results;
            } catch (e) {
                console.error(e);
                throw e;
            }
        },
        async getPreviousVersions() {
            const { data } = await axios.get(
                endpoints.getPreviousAutosomalResult(store.getters.profile.barcode) + "?all=true"
            );
            this.previousResults = data;
        },
        async startUpdate() {
            await axios.post(endpoints.upgradeTest(store.getters.profile.barcode, "autosomal"));
        },
    },
});
