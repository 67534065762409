<template>
    <Listbox as="div" v-if="matchgroupStore.matchGroups.length" v-model="selected">
        <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900"
            >Choose group</ListboxLabel
        >
        <div class="relative mt-1">
            <ListboxButton
                class="relative //w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-24 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            >
                <div class="flex items-center gap-2">
                    <div
                        :style="{ backgroundColor: modelValue.colour }"
                        class="h-4 w-4 rounded-full"
                    />
                    <span class="block truncate">{{ modelValue.name }}</span>
                </div>

                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <FeatherWrapper icon="chevron-down" class="text-gray-400" />
                </span>
            </ListboxButton>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="absolute z-50 mt-1 max-h-60 //w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ListboxOption
                        as="template"
                        v-for="matchGroup in matchgroupStore.matchGroups"
                        :key="matchGroup.id"
                        :value="matchGroup"
                        v-slot="{ active, selected }"
                    >
                        <li
                            :class="[
                                active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-8 pr-20',
                            ]"
                        >
                            <span
                                :class="[
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate',
                                ]"
                                >{{ matchGroup.name }} ({{ matchGroup.members.length }})</span
                            >

                            <span
                                :class="[
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                ]"
                            >
                                <div
                                    :style="{ backgroundColor: matchGroup.colour }"
                                    class="h-4 w-4 rounded-full mr-4"
                                />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>
<script setup>
import {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/vue";

import { useMatchGroupStore } from "@/store/matchGroups";
import { ref, watch } from "vue";

const matchgroupStore = useMatchGroupStore();

matchgroupStore.getMatchGroups();

const props = defineProps({
    modelValue: Object,
});

const emit = defineEmits(["update:modelValue"]);

const selected = ref(props.modelValue);

watch(selected, (value) => {
    emit("update:modelValue", value);
});
</script>
