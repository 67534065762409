<template>
    <div class="p-6">
        <div class="prose m-auto py-12">
            <h1 class="">Your motherline history</h1>
            <div class="h-4 w-16 bg-rose-500 mb-10 auto" />
            <HaplogroupCards :result="motherlineStore.result" type="mtdna" />
            <img :src="motherlineStore.result.content.contentOne.image" alt="" />
            <div v-html="motherlineStore.result.content.contentOne.body" />
            <img :src="motherlineStore.result.content.contentTwo.image" alt="" />
            <div v-html="motherlineStore.result.content.contentTwo.body" />
            <ul>
                <li v-for="citation in motherlineStore.result.content.citations">
                    <a v-if="citation.type === 'external_url'" :href="citation.source">
                        {{ citation.title }}
                    </a>
                    <div v-else>{{ citation.title }}</div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script setup>
import { useMotherlineStore } from "@/store/parentalStore";
import HaplogroupCards from "@/components/ancestry/HaplogroupCards.vue";

const motherlineStore = useMotherlineStore();
</script>
