<template>
    <div class="block h-full">
        <div
            class="text-grey-700 shadow-key hover:shadow-key-hover rounded-lg flex overflow-hidden h-full"
        >
            <div
                v-if="hasImage"
                class="w-1/3 bg-cover fade-right"
                :style="defaultKeyTakeawayImage"
            ></div>
            <div class="w-2/3 p-4 flex flex-col h-full">
                <p class="flex items-center text-gray-500 mt-0 pb-1 font-semibold text-sm">
                    <Feather type="loader" class="fill-current mr-1 h-4 w-4" />
                    Preparing results
                </p>
                <p class="pb-4">Your {{ title }} result is processing and will be ready soon.</p>
            </div>
        </div>
    </div>
</template>

<script>
import Feather from "vue-feather";

export default {
    components: { Feather },
    props: {
        trait: {
            required: true,
            type: Object,
        },
    },
    computed: {
        title() {
            return this.trait.traitCmsData.title;
        },
        link() {
            return { name: "wellbeing-report", params: { report: this.trait.traitCmsData.slug } };
        },
        hasImage() {
            return (
                this.trait.traitCmsData.phenotypes[0] &&
                this.trait.traitCmsData.phenotypes[0].keyTakeawayImage
            );
        },
        defaultKeyTakeawayImage() {
            return {
                backgroundImage: `url(${this.trait.traitCmsData.phenotypes[0].keyTakeawayImage[0].urlRaw}`,
            };
        },
    },
};
</script>
