<template>
    <div class="flex prose">
        <div class="bg-white m-auto px-16 pb-16 max-w-2xl">
            <h2 class="mt-0">Use of your Personal Information</h2>
            <ol class="list-reset mt-4">
                <li class="mb-2">
                    To my genetic data being analysed to compare it with the genetic data of other
                    users of the Family Networks Service, and generally for Living DNA to provide
                    its Family Network service to me.
                </li>
                <li class="mb-2">
                    To the following information from my profile in my Living DNA account being
                    shared with my Family Networks matches as part of the Family Networks service:
                </li>
                <ul class="ml-12 list-disc">
                    <li class="mb-1">
                        my name, or if I have chosen to use an alias on my profile, my alias
                    </li>
                    <li class="mb-1">my country of residence,</li>
                    <li class="mb-1">
                        any other information which I have opted in my profile to be ‘public’
                    </li>
                    <li class="mb-1">my ethnicity breakdown, if available</li>
                    <li class="mb-1">our shared DNA segments, if I opt into use this feature</li>
                </ul>
                <li class="mb-2">
                    To my personal information described in a) and b) above being shared with my
                    Family Networks matches even if they are located outside of the European Union.
                    I understand that I will not have protections of regulatory regime in the EU
                    when my information is passed outside of the EU.
                </li>
                <li class="mb-2">
                    To Living DNA retaining and accessing any messages which I may send or receive
                    using any messaging service which it may make available in connection with the
                    Family Networks Service.
                </li>
            </ol>
            <p>
                I understand that once my personal information has been shared with my matches as
                part of the Family Networks service, I should view that information as having been
                made public and that it may not be possible to retrieve it.
            </p>
            <OptInForm :loading="loading" @accepted-main-terms="optIn" />
        </div>
    </div>
</template>

<script>
import axios from "axios";
import OptInForm from "@/components/FamilyMatching/OptInForm.vue";

export default {
    components: { OptInForm },
    data() {
        return {
            loading: false,
        };
    },
    props: {
        barcode: {
            type: String,
            required: true,
        },
    },
    methods: {
        async optIn() {
            this.loading = true;
            try {
                await this.$store.dispatch("setFamilyMatchingMainTermsAsAccepted", {
                    barcode: this.barcode,
                    http: axios,
                });
                await this.$store.dispatch("SET_KIT_TESTS", this.barcode);
                this.$emit("accepted-main-terms");
            } catch (e) {
                alert("There was a problem saving your consent");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
