import Vue from "vue";
import axios from "axios";
import endpoints from "@/endpoints";

export default {
    state: {
        account: undefined,
    },
    getters: {
        account: state => state.account,
        accountData: state => state.account,
    },
    actions: {
        getAccountData(context) {
            // if (context.state.account === undefined) {
            return new Promise((resolve, reject) => {
                axios.get(endpoints.account).then(
                    res => {
                        context.commit("setAccountData", res);
                        resolve(res.data);
                    },
                    error => {
                        reject(error);
                    }
                );
            });
            // }
        },
        setMarketingPreference({ commit, getters }, preference) {
            return new Promise(async (resolve, reject) => {
                try {
                    const { email, username } = getters.accountData;
                    await axios.put(`${endpoints.account}`, {
                        account: {
                            email,
                            username,
                            marketing: preference,
                        },
                    });
                    commit("setMarketingPreference", preference);
                    resolve();
                } catch (e) {
                    reject(e);
                }
            });
        },
    },
    mutations: {
        setAccountData(state, payload) {
            /* FullStory user identification method,
               we trigger it when FS is defined (only production environment) and
               account information are undefined */
            if (window.FS !== undefined && !state.account) {
                FS.identify(payload.data.uuid, {
                    displayName: `${payload.data.firstName} ${payload.data.lastName}`,
                    email: payload.data.email,
                });
            }
            state.account = payload.data;
        },
        setMarketingPreference(state, preference) {
            state.account.marketing = preference;
        },
        CLEAR_ACCOUNT_DATA(state) {
            state.account = undefined;
        },
    },
};
