<template>
    <div class="md:hidden">
        <div class="space-y-2 px-4 py-2" v-if="showLegend">
            <div class="flex items-center">
                <div
                    class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                    style="background-color: #00d4ff; opacity: 0.3"
                />
                Neanderthal area
            </div>
            <div class="flex items-center">
                <div
                    class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                    style="background-color: #00d4ff"
                />
                Neanderthal site
            </div>
            <div class="flex items-center">
                <div
                    class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                    style="background-color: #d400ff; opacity: 0.2"
                />
                Denisovan area
            </div>
            <div class="flex items-center">
                <div
                    class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                    style="background-color: #d400ff"
                />
                Denisovan site
            </div>
            <div class="flex items-center">
                <div
                    class="h-3 w-3 rounded-full mr-2 flex-shrink-0"
                    style="background-color: orange"
                />
                Neanderthal & Denisovan site
            </div>
            <div class="flex items-center">
                <div class="h-3 w-3 rounded-full mr-2 flex-shrink-0 bg-rose-500" />
                Closest sample site
            </div>
            <button
                @click="showLegend = false"
                class="py-2 flex items-center text-sm font-semibold text-cobalt-500"
            >
                <Feather type="minus-circle" class="h-4 w-4 mr-2" />
                Hide legend
            </button>
        </div>
        <button
            v-else
            @click="showLegend = true"
            class="py-2 flex items-center text-sm font-semibold text-cobalt-500 mx-4 h-12"
        >
            <Feather type="plus-circle" class="h-4 w-4 mr-2" />
            Show legend
        </button>
    </div>
</template>

<script>
import { ref } from "vue";
export default {
    setup() {
        const showLegend = ref(false);
        return { showLegend };
    },
};
</script>
