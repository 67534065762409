<template>
    <div>
        <portal to="help-button">
            <button
                ref="helpButton"
                icon="help-circle"
                text="Help"
                @click="(showModal = true), $emit('modal-opened')"
                class="text-white hover:bg-denim-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full"
            >
                <Feather type="help-circle" class="h-5 w-5 mr-4" />
                Help
            </button>
        </portal>
        <Modal :open="showModal" width="lg" @state-change="showModal = $event">
            <ul class="space-y-4">
                <li>
                    <a
                        href="https://support.livingdna.com/hc/en-us"
                        target="_blank"
                        rel="noopener"
                        class="flex w-full items-center text-lg space-x-4 hover:bg-grey-50 p-2 rounded-md"
                    >
                        <div class="bg-cobalt-50 flex h-12 w-12 rounded-lg">
                            <Feather type="help-circle" class="h-6 w-6 text-cobalt-500 m-auto" />
                        </div>

                        <div>
                            <div class="font-semibold text-grey-600">Support</div>
                            <div class="text-grey-600 text-base">
                                Find answers in our support centre
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <a
                        href="https://livingdna.com/uk/legal/Terms-Of-Use"
                        target="_blank"
                        rel="noopener"
                        class="flex w-full items-center text-lg space-x-4 hover:bg-grey-50 p-2 rounded-md"
                    >
                        <div class="bg-tangerine-50 flex h-12 w-12 rounded-lg">
                            <Feather type="file-text" class="h-6 w-6 text-tangerine-500 m-auto" />
                        </div>

                        <div>
                            <div class="font-semibold text-grey-600">Terms and conditions</div>
                            <div class="text-grey-600 text-base">See our terms and conditions</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a
                        href="https://livingdna.com/uk/privacy-centre"
                        target="_blank"
                        rel="noopener"
                        class="flex w-full items-center text-lg space-x-4 hover:bg-grey-50 p-2 rounded-md"
                    >
                        <div class="bg-emerald-50 flex h-12 w-12 rounded-lg">
                            <Feather type="lock" class="h-6 w-6 text-emerald-500 m-auto" />
                        </div>

                        <div>
                            <div class="font-semibold text-grey-600">Privacy policy</div>
                            <div class="text-grey-600 text-base">
                                Learn how we store your data securely
                            </div>
                        </div>
                    </a>
                </li>
                <li class="text-center pt-4">
                    <button @click="showModal = false" class="font-semibold">Close</button>
                </li>
            </ul>
        </Modal>
    </div>
</template>

<script>
import { ref, watch } from "vue";
import Modal from "@/components/Modal.vue";

export default {
    setup() {
        const showModal = ref(false);

        return { showModal };
    },
    components: {
        Modal,
    },
};
</script>
