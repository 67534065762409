<template>
    <svg class="fill-current" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
            x="1"
            y="1"
            width="15"
            height="22"
            rx="2"
            class="stroke-current fill-none"
            stroke-width="2"
        />
        <rect x="6" y="8.5" width="5" height="7" rx="2" class="stroke-current fill-none" />
        <!--        <rect x="12" y="11" width="2" height="1" />-->
        <!--        <rect x="3" y="11" width="2" height="1" />-->
        <!--        <rect x="8" y="4" width="1" height="3" />-->
        <!--        <rect x="8" y="16" width="1" height="3" />-->
    </svg>
</template>
