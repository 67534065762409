import store from "@/store/store";
import axios from "axios";
import { ref, watch } from "vue";
import endpoints from "@/endpoints";
import { useLoading } from "@/composables/useLoading";

export const sharedMatches = ref([]);
export const totalCount = ref(0);

export const {
    loadingStatus: sharedMatchesLoadingStatus,
    finishLoading,
    resetLoading,
} = useLoading();

export const currentPage = ref(1);

export function getSharedMatches(barcode) {
    watch(
        currentPage,
        () => {
            resetLoading();
            axios
                .get(
                    endpoints.sharedMatches(store.getters.profile.barcode, barcode, {
                        perPage: 10,
                        pageNumber: currentPage.value,
                    })
                )
                .then((res) => {
                    sharedMatches.value = res.data.sharedMatches;
                    totalCount.value = res.data.total;
                    finishLoading();
                });
        },
        { immediate: true }
    );
}
