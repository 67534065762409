<template>
    <div v-if="mapSharingStatus === 'canShare'">
        To share your map like {{ name }}
        <router-link
            :to="{
                name: 'edit-fn-sharing-field',
                params: { barcode: $store.getters.profile.barcode, field: 'map' },
            }"
            >click here</router-link
        >
    </div>
    <div v-else-if="mapSharingStatus === 'requiresUpgrade'">
        Because you don't have the full ancestry upgrade we can't compare your map with
        {{ name }} and all their regions will show as gray. You can compare your map with other
        people (and share your own) by getting the recent ancestry upgrade
        <router-link :to="{ name: 'product', params: { slug: 'autosomal-upgrade' } }">
            here</router-link
        >
    </div>
</template>

<script>
import ProfileService from "@/services/profile";

export default {
    data() {
        return {
            mapSharingStatus: "loading",
        };
    },
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    async created() {
        try {
            const { map } = await ProfileService.getFamilyNetworkSharingPermissions(
                this.$store.getters.profile.barcode
            );
            if (!map) {
                if (this.$store.getters.kitTests.autosomal.purchased) {
                    this.mapSharingStatus = "canShare";
                } else {
                    this.mapSharingStatus = "requiresUpgrade";
                }
            }
        } catch (e) {
            this.mapSharingStatus = "error";
        }
        console.log(`Map sharing status is ${this.mapSharingStatus}`);
    },
};
</script>
