<template>
    <PageLoader v-if="loadingStatus === 'loading'" class="mt-12"> Loading information </PageLoader>
    <div v-else-if="loadingStatus === 'failed'">
        <div v-if="errorMessage === 'not_shared'">
            <AlertMessage type="error" class="max-w-xl m-auto mt-12">
                <template #title>URL does not match</template>
                <template #body
                    >The provided url does not match a shared profile, please ensure the url is
                    correct</template
                >
            </AlertMessage>
        </div>
        <div v-else class="mt-12 max-w-md mx-auto">Something went wrong</div>
    </div>
    <template v-else>
        <div class="pb-24 px-4" id="scrollArea">
            <SharingHeader :navigation="navigation" />

            <SharingDescription class="mt-6" />
            <SharingHighlight class="mt-12" />
            <div id="recentAncestry" ref="recentAncestry" class="mt-24">
                <SharingRecentAncestry />
            </div>

            <!--class="max-w-3xl m-auto px-4 mt-24"-->
            <div id="parental" ref="parental" class="mt-24">
                <div class="prose m-auto">
                    <h2>Parental DNA</h2>
                </div>
                <div class="mt-12" id="motherline">
                    <SharingMotherline v-if="sharingStore.motherlineResult" />
                    <SharingNoSectionMessage v-else>
                        {{ sharingStore.name }} does not have motherline results to share
                    </SharingNoSectionMessage>
                </div>
                <div class="mt-24" id="fatherline">
                    <SharingFatherline v-if="sharingStore.fatherlineResult" />
                    <SharingNoSectionMessage
                        v-else
                        :class="{ '-mt-12': !sharingStore.motherlineResult }"
                    >
                        {{ sharingStore.name }} does not have fatherline results to share
                    </SharingNoSectionMessage>
                </div>
            </div>

            <div id="historical" ref="historical" class="mt-24">
                <SharingHistorical v-if="sharingStore.vikingResult || sharingStore.archaicResult" />
                <SharingNoSectionMessage v-else>
                    {{ sharingStore.name }} does not have historical results to share
                </SharingNoSectionMessage>
            </div>
        </div>

        <SharingPromotionPanel id="explore" />
    </template>

    <!--
      Introduction about living dna
      - link to buy
      - uploading
      regions
      - doughnut breakdown
      - map + sidebar
      mt & Y
      - cards
      - distribution map
      - migration map
  -->
</template>

<script setup>
import { watch, ref } from "vue";
import { useWindowScroll } from "@vueuse/core";
import SharingHeader from "@/views/sharing/SharingHeader.vue";
import SharingDescription from "@/views/sharing/SharingDescription.vue";
import SharingRecentAncestry from "@/views/sharing/SharingRecentAncestry.vue";
import SharingMotherline from "@/views/sharing/SharingMotherline.vue";
import SharingFatherline from "@/views/sharing/SharingFatherline.vue";
import SharingHighlight from "@/views/sharing/SharingHighlight.vue";
import SharingPromotionPanel from "@/views/sharing/SharingPromotionPanel.vue";
import { useSharingStore } from "@/store/sharing";
import { useRoute } from "vue-router";
import { useLoading } from "@/composables/useLoading";
import AlertMessage from "@/components/AlertMessage.vue";
import SharingHistorical from "@/views/sharing/SharingHistorical.vue";
import SharingNoSectionMessage from "@/views/sharing/SharingNoSectionMessage.vue";

const sharingStore = useSharingStore();
const route = useRoute();
const { loadingStatus, finishLoading, failLoading, errorMessage } = useLoading();

sharingStore
    .getPublicProfile(route.params.uuid)
    .then(() => {
        if (
            !sharingStore.autosomalResult &&
            !sharingStore.motherlineResult &&
            !sharingStore.fatherlineResult
        ) {
            failLoading("no_sharing");
        }
        finishLoading();
    })
    .catch((err) => {
        if (err.response?.status === 404) {
            return failLoading("not_shared");
        }
        failLoading("general");
    });

const navigation = ref([
    { name: "Overview", id: "scrollArea", active: true },
    { name: "Recent Ancestry", id: "recentAncestry", active: false },
    { name: "Parental", id: "parental", active: false },
    { name: "Historical", id: "historical", active: false },
]);

const recentAncestry = ref(null);
const parental = ref(null);
const historical = ref(null);

const { y } = useWindowScroll();

watch(y, (value) => {
    console.log(value + 100 > recentAncestry.value.offsetTop);
    if (value + 100 < recentAncestry.value.offsetTop) {
        navigation.value = [
            { name: "Overview", id: "scrollArea", active: true },
            { name: "Recent Ancestry", id: "recentAncestry", active: false },
            { name: "Parental", id: "parental", active: false },
            { name: "Historical", id: "historical", active: false },
        ];
    } else if (
        value + 100 > recentAncestry.value.offsetTop &&
        value + 100 < recentAncestry.value.offsetTop + recentAncestry.value.offsetHeight
    ) {
        navigation.value = [
            { name: "Overview", id: "scrollArea", active: false },
            { name: "Recent Ancestry", id: "recentAncestry", active: true },
            { name: "Parental", id: "parental", active: false },
            { name: "Historical", id: "historical", active: false },
        ];
    } else if (
        value + 100 > parental.value.offsetTop &&
        value + 100 < parental.value.offsetTop + parental.value.offsetHeight
    ) {
        navigation.value = [
            { name: "Overview", id: "scrollArea", active: false },
            { name: "Recent Ancestry", id: "recentAncestry", active: false },
            { name: "Parental", id: "parental", active: true },
            { name: "Historical", id: "historical", active: false },
        ];
    } else if (
        value + 100 > historical.value.offsetTop &&
        value + 100 < historical.value.offsetTop + historical.value.offsetHeight
    ) {
        navigation.value = [
            { name: "Overview", id: "scrollArea", active: false },
            { name: "Recent Ancestry", id: "recentAncestry", active: false },
            { name: "Parental", id: "parental", active: false },
            { name: "Historical", id: "historical", active: true },
        ];
    }
});
</script>

<style>
#recentAncestry,
#parental,
#fatherline,
#motherline,
#historical {
    scroll-margin: 80px;
}
</style>
