<template>
    <PageLoader v-if="sharedMatchesLoadingStatus === 'loading'" class="mt-12">
        Loading your shared matches
    </PageLoader>
    <template v-else>
        <div id="sharedMatches" class="bg-white rounded shadow-key mt-2">
            <div
                class="px-3 sm:px-6 py-4 flex items-center border-grey-200 hover:shadow-key-hover"
                v-if="sharedMatches.length === 0"
            >
                <div class="w-2/3 text-grey-700 flex items-center">
                    <div class="relative h-12 w-12 sm:h-16 sm:w-16 flex-none mr-6">
                        <img :src="defaultAvatar" alt="" class="rounded-full w-full" />
                    </div>
                    <div>
                        <h3 class="font-semibold text-grey-600">
                            There were no matches found that share DNA with both
                            {{ match.username || match.name }} and {{ firstName }}
                        </h3>
                        <p class="my-0 text-2xl">0 relatives in common</p>
                    </div>
                </div>
            </div>
            <RelativesListItem
                v-for="(relative, index) in sharedMatches"
                :class="index !== sharedMatches.length - 1 && 'border-b border-grey-200'"
                :key="relative.id"
                :relative="relative"
            />
        </div>
        <div class="flex justify-center">
            <PaginateList
                class="mx-auto mt-6"
                :list-count="totalCount"
                :current-page="currentPage"
                :items-per-page="10"
                @page-changed="currentPage = $event"
            />
        </div>
    </template>
</template>

<script setup>
import {
    getSharedMatches,
    sharedMatches,
    totalCount,
    sharedMatchesLoadingStatus,
    currentPage,
} from "@/composables/useSharedMatches";
import RelativesListItem from "@/views/FamilyNetworks/FamilyMatching/ListView/RelativesListItem.vue";
import PaginateList from "@/views/FamilyNetworks/FamilyMatching/ListView/PaginateList.vue";

const props = defineProps({
    match: Object,
});

getSharedMatches(props.match.barcode);
</script>
