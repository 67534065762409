<template>
    <PageLoader v-if="loadingStatus === 'loading'" />
    <div v-else-if="loadingStatus === 'failed'">
        <div v-if="errorMessage === 'not_found'">
            <BookPdfNotPurchased class="max-w-xl mt-12 mx-auto" />
        </div>
        <div class="max-w-xl p-6 bg-white rounded mt-12 mx-auto shadow" v-else>
            We could not retrieve information about your book
        </div>
    </div>
    <div v-else class="max-w-lg mt-12 mx-auto">
        <div class="rounded bg-white shadow p-6">
            <div>
                <div v-if="bookDetails.status === 'pending'">
                    Your book PDF is being created, we'll email you once it's available to download
                </div>
                <div v-else-if="bookDetails.status === 'completed'">
                    <h3 class="font-semibold mb-2">Book ready to download</h3>
                    <p>
                        Your book for {{ bookDetails.customerName }} has been created and is
                        available to download.
                    </p>
                    <p class="mt-4">
                        Use the options below to download the contents and cover for the book. If
                        you need to re-download the book in the future you can return to this page
                        and download the book.
                    </p>
                    <div class="flex items-center gap-6 mt-4">
                        <BookPdfButton type="cover" @error="showError" @clear-error="clearError">
                            Download Cover
                        </BookPdfButton>
                        <BookPdfButton type="contents" @error="showError" @clear-error="clearError">
                            Download Contents
                        </BookPdfButton>
                    </div>
                    <div v-if="hasError" class="text-sm text-rose-500 mt-4">
                        Sorry, we weren't able to download your file, for more assistance please
                        contact
                        <a href="mailto:help@livingdna.com" class="font-semibold"
                            >help@livingdna.com</a
                        >.
                    </div>
                </div>
                <div v-else>
                    Something has gone wrong with your book, please contact
                    <a href="mailto:help@livingdna.com" class="font-semibold text-cobalt-500"
                        >help@livingdna.com</a
                    >
                    for more assistance
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref } from "vue";
import { useLoading } from "@/composables/useLoading";
import BookPdfButton from "@/components/ancestry/BookPdfButton.vue";
import { getBookPdfDetails } from "@/services/bookPdfService";
import BookPdfNotPurchased from "@/components/ancestry/BookPdfNotPurchased.vue";

const bookDetails = ref({});
const hasError = ref(false);

const { finishLoading, failLoading, loadingStatus, errorMessage } = useLoading();

getBookPdfDetails()
    .then((res) => {
        bookDetails.value = res.data;
    })
    .then(finishLoading)
    .catch((err) => {
        if (err.response.status === 404) {
            failLoading("not_found");
        } else {
            failLoading();
        }
    });

function showError() {
    hasError.value = true;
}

function clearError() {
    hasError.value = false;
}
</script>
