<script>
import { h } from "vue";

export default {
    render() {
        return h(
            "div",
            this.$slots.default({
                errorMessage: this.errorMessage,
            })
        );
    },
    props: {
        errorBag: {
            type: Array,
            default: () => [],
        },
        errorKey: String,
    },
    computed: {
        errorMessage() {
            console.log(this.errorBag);
            if (this.errorBag.length > 0) {
                return this.errorBag.find(err => err.source === this.errorKey);
            }
            return "";
        },
    },
};
</script>
