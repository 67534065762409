import { isEmpty } from "lodash";
import ResearchService from "@/services/research";

const isEmptyField = field => (isEmpty(field) ? null : field);

export default {
    state: {
        consent: false,
        participantRelations: {
            mothers_mother: {
                first_name: "",
                last_name: "",
                date_of_birth: {
                    date: {
                        date: null,
                    },
                    precision: 1,
                },
                country: "",
                county: "",
                city: "",
                // locations: [],
                // selectedLocation: {},
            },
            mothers_father: {
                first_name: "",
                last_name: "",
                date_of_birth: {
                    date: {
                        date: null,
                    },
                    precision: 1,
                },
                country: "",
                county: "",
                city: "",
                // locations: [],
                // selectedLocation: {},
            },
            fathers_mother: {
                first_name: "",
                last_name: "",
                date_of_birth: {
                    date: {
                        date: null,
                    },
                    precision: 1,
                },
                country: "",
                county: "",
                city: "",
                // locations: [],
                // selectedLocation: {},
            },
            fathers_father: {
                first_name: "",
                last_name: "",
                date_of_birth: {
                    date: {
                        date: null,
                    },
                    precision: 1,
                },
                country: "",
                county: "",
                city: "",
                // locations: [],
                // selectedLocation: {},
            },
        },
    },
    getters: {
        researchConsent: state => state.consent,
        getGrandParent: state => name => state.participantRelations[name],
    },
    mutations: {
        ON_LOAD_RESEARCH(state, { consent, ancestryTestParticipantRelations }) {
            state.consent = consent;

            if (consent) {
                Object.keys(ancestryTestParticipantRelations).forEach(relation => {
                    Object.keys(ancestryTestParticipantRelations[relation]).forEach(field => {
                        const currentValue = ancestryTestParticipantRelations[relation][field];

                        if (field === "date_of_birth") {
                            if (isEmpty(currentValue) || isEmpty(currentValue.date)) {
                                state.participantRelations[relation][field] = {
                                    ...currentValue,
                                    date: {
                                        ...currentValue.date,
                                        date: null,
                                    },
                                };
                            } else {
                                state.participantRelations[relation][field] = {
                                    ...state.participantRelations[relation][field],
                                    date: {
                                        ...state.participantRelations[relation][field].date,
                                        date: currentValue.date.date.split(" ")[0],
                                    },
                                };
                            }
                        } else if (currentValue && !isEmpty(currentValue)) {
                            state.participantRelations[relation][field] = currentValue;
                        }
                    });
                });
            }
        },

        SAVE_RESEARCH_SUCCESS(state, res) {
            console.log("success!", res);
        },
        SAVE_RESEARCH_FAILED(state, error) {
            console.log("failed!", error);
        },

        TOGGLE_AGREE_TO_RESEARCH(state, { choice }) {
            state.consent = choice;
        },
        ON_NAME_INPUT(state, { parent, name, value }) {
            state.participantRelations[parent][name] = value;
        },
        SAVE_BIRTHDAY(state, { parent, value }) {
            state.participantRelations[parent].date_of_birth.date.date = value;
        },
        CLEAR_BIRTHDAY(state, { parent }) {
            state.participantRelations[parent].date_of_birth.date.date = null;
        },

        SELECT_COUNTRY(state, { parent, country }) {
            state.participantRelations[parent].country = country;
        },
        SELECT_COUNTY(state, { parent, county }) {
            state.participantRelations[parent].county = county;
        },
        SELECT_CITY(state, { parent, city }) {
            state.participantRelations[parent].city = city;
        },
        // GET_LOCATIONS(state, { parent, features }) {
        //     state.participantRelations[parent].locations = features;
        // },
        // CLEAR_LOCATIONS(state, { parent }) {
        //     state.participantRelations[parent].locations = [];
        // },
        // SET_LOCATION(state, { parent, location }) {
        //     state.participantRelations[parent].selectedLocation = location;
        // },
    },
    actions: {
        async ON_LOAD_RESEARCH({ commit }, barcode) {
            commit("ON_LOAD_RESEARCH", await ResearchService.getResearch({ barcode }));
        },

        async SAVE_RESEARCH({ commit, state }, barcode) {
            return new Promise(async (resolve, reject) => {
                try {
                    const participantRelations = Object.keys(state.participantRelations).reduce(
                        (acc, relation) => {
                            const currentRelation = state.participantRelations[relation];

                            const checkDateOfBirth = field =>
                                isEmpty(currentRelation[field].date.date)
                                    ? null
                                    : currentRelation[field];

                            return {
                                ...acc,
                                [relation]: Object.keys(currentRelation).reduce(
                                    (accu, field) => ({
                                        ...accu,
                                        [field]:
                                            field === "date_of_birth"
                                                ? checkDateOfBirth(field)
                                                : isEmptyField(currentRelation[field]),
                                    }),
                                    {}
                                ),
                            };
                        },
                        {}
                    );

                    const res = await ResearchService.postResearch({
                        barcode,
                        body: {
                            consent: true,
                            participantRelations,
                        },
                    });

                    commit("SAVE_RESEARCH_SUCCESS", res);
                    console.log("gets here");
                    resolve();
                } catch (error) {
                    commit("SAVE_RESEARCH_FAILED", error);
                    reject();
                }
            });
        },

        async DO_NOT_CONSENT({ commit }, barcode) {
            return new Promise(async (resolve, reject) => {
                try {
                    const res = await ResearchService.postResearch({
                        barcode,
                        body: { consent: false },
                    });

                    commit("SAVE_RESEARCH_SUCCESS", res);
                    console.log("gets here 2");
                    resolve();
                } catch (error) {
                    commit("SAVE_RESEARCH_FAILED", error);
                    reject();
                }
            });
        },

        TOGGLE_AGREE_TO_RESEARCH({ commit }, payload) {
            commit("TOGGLE_AGREE_TO_RESEARCH", payload);
        },
        ON_NAME_INPUT({ commit }, payload) {
            commit("ON_NAME_INPUT", payload);
        },
        SAVE_BIRTHDAY({ commit }, payload) {
            commit("SAVE_BIRTHDAY", payload);
        },
        CLEAR_BIRTHDAY({ commit }, payload) {
            commit("CLEAR_BIRTHDAY", payload);
        },

        SELECT_COUNTRY({ commit }, payload) {
            commit("SELECT_COUNTRY", payload);
        },
        SELECT_COUNTY({ commit }, payload) {
            commit("SELECT_COUNTY", payload);
        },
        SELECT_CITY({ commit }, payload) {
            commit("SELECT_CITY", payload);
        },
        // GET_LOCATIONS({ commit }, payload) {
        //     commit('GET_LOCATIONS', payload);
        // },
        // CLEAR_LOCATIONS({ commit }, payload) {
        //     commit('CLEAR_LOCATIONS', payload);
        // },
        // SET_LOCATION({ commit }, payload) {
        //     commit('SET_LOCATION', payload);
        // },
    },
};
