<template>
    <section
        v-if="
            productsStore.upgrades.length > 0 &&
            !['testing_failed', 'transfer_qc_failed'].includes(
                store.getters.selectedTest.raw_status
            )
        "
    >
        <div class="py-8 sm:py-12">
            <div class="grid grid-cols-1 gap-4 xl:grid-cols-3 xl:items-stretch">
                <div class="grid p-6 bg-gray-100 rounded place-content-center sm:p-8">
                    <div class="max-w-md mx-auto text-center xl:text-left">
                        <header>
                            <h2 class="text-xl font-bold text-gray-700 sm:text-2xl">
                                Featured {{ pluralize("upgrades", productsStore.upgrades.length) }}
                            </h2>

                            <p class="mt-4 text-gray-500">
                                Discover even more with your Living DNA test/upgrade with our
                                upgrades
                            </p>
                        </header>

                        <router-link
                            :to="{ name: 'products' }"
                            class="inline-block px-12 py-3 mt-8 text-sm font-semibold text-white transition bg-cobalt-500 hover:bg-cobalt-700 rounded hover:shadow focus:outline-none focus:ring"
                        >
                            Shop All
                        </router-link>
                    </div>
                </div>

                <div class="xl:col-span-2 xl:py-8">
                    <ul class="flex items-stretch gap-4">
                        <li
                            v-for="product in productsStore.upgrades.slice(0, 2)"
                            :key="product.title"
                            class="w-1/2 flex flex-col gap-4"
                        >
                            <img
                                :src="product.image"
                                alt=""
                                class="object-cover w-full rounded aspect-video"
                            />

                            <h3
                                class="font-medium text-gray-900 group-hover:underline group-hover:underline-offset-4"
                            >
                                {{ product.title }}
                            </h3>

                            <p v-html="product.shortDescription"></p>

                            <div class="flex items-center gap-4 mt-auto">
                                <router-link
                                    :to="{
                                        name: 'product',
                                        params: { slug: product.slug },
                                    }"
                                    class="px-4 py-2 bg-cobalt-500 text-white rounded hover:bg-cobalt-700 font-semibold text-sm"
                                    >Unlock</router-link
                                >
                                <p class="mt-1 text-gray-700">
                                    {{
                                        convertToCurrency(
                                            product.price.base / 100,
                                            product.currency
                                        )
                                    }}
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useProductsStore } from "@/store/products";
import store from "@/store/store";
import { convertToCurrency } from "@/services/store";
import pluralize from "pluralize";

const productsStore = useProductsStore();

productsStore.getProducts(store.getters.profile.barcode);
</script>
