<template>
    <div v-if="mode === 'map' || mode === 'regionList'">
        <div class="flex px-6 py-5">
            <button
                class="px-4 py-1 rounded-full"
                @click="handleTabChange($event, 'map')"
                :class="mode === 'map' ? 'bg-cobalt-500 text-white' : 'bg-grey-200 text-grey-600'"
            >
                Show map
            </button>
            <div class="w-4"></div>
            <button
                class="px-4 py-1 rounded-full"
                @click="handleTabChange($event, 'regionList')"
                :class="
                    mode === 'regionList' ? 'bg-cobalt-500 text-white' : 'bg-grey-200 text-grey-600'
                "
            >
                Show region list
            </button>
        </div>
        <slot name="map" v-if="mode === 'map'" />
        <slot name="regionList" v-else-if="mode === 'regionList'" />
    </div>
    <div v-else-if="mode === 'notOptedIn'">
        <slot name="notOptedIn" />
    </div>
    <div v-else-if="mode === 'notUpgraded'">
        <slot name="notUpgraded" />
    </div>
    <div v-else-if="mode === 'loading'">
        <slot name="loading" />
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            required: true,
            validate(value) {
                return ["map", "regionList", "loading", "notOptedIn"].includes(value);
            },
        },
    },
    watch: {
        mode(value) {
            console.log(value, "in comp");
        },
    },
    methods: {
        handleTabChange(event, newTab) {
            event.target.blur();
            // selectedRegion = undefined;
            this.$emit("tab-change", newTab);
        },
    },
};
</script>
