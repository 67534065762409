<template>
    <div class="flex flex-col gap-2">
        <label class="flex items-center gap-2">
            <input
                type="checkbox"
                v-model="filterStore.shouldFilterResults"
                class="rounded text-cobalt-500"
            />
            <span
                >Only show segments longer than
                <input
                    type="text"
                    v-model="filterStore.filterValue"
                    class="text-sm rounded border-gray-300 w-16 p-2"
                />
                cM</span
            >
        </label>
    </div>
</template>

<script setup>
import { useFilterStore } from "@/store/chromosomeBrowser";

const filterStore = useFilterStore();
</script>
