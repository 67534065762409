<template>
    <div class="px-6 w-full lg:w-1/2 mb-10">
        <HasResult v-if="trait.purchased && hasSnps" :trait="trait" />
        <IsPreparingResults v-else-if="trait.purchased && !trait.complete" :trait="trait" />
        <HasNoResult v-else-if="trait.purchased && !hasSnps" :trait="trait" />
        <HasNotPurchased v-else :trait="trait" />
    </div>
</template>

<script>
import HasResult from "@/components/Wellbeing/AllReports/TraitInGoalCard/HasResult.vue";
import HasNoResult from "@/components/Wellbeing/AllReports/TraitInGoalCard/HasNoResult.vue";
import HasNotPurchased from "@/components/Wellbeing/AllReports/TraitInGoalCard/HasNotPurchased.vue";
import { hasSnps } from "@/services/wellbeing/wellbeingHelpers";
import IsPreparingResults from "@/components/Wellbeing/AllReports/TraitInGoalCard/IsPreparingResults.vue";

export default {
    components: { IsPreparingResults, HasNotPurchased, HasNoResult, HasResult },
    props: {
        trait: {
            required: true,
            type: Object,
        },
    },
    computed: {
        hasSnps() {
            return hasSnps(this.trait);
        },
    },
};
</script>
