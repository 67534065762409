const findPlace = (menuItems, placement) => menuItems.filter(x => x.placement === placement);

export default {
    state: {
        hasViewedNewChanges: window.localStorage.getItem("hasViewedNewChanges"),
        menuItems: [
            {
                text: "Dashboard",
                icon: "home",
                link: "/dashboard",
                placement: "mobile-main",
            },
            {
                text: "Ancestry",
                icon: "map-pin",
                link: "/ancestry",
                placement: "mobile-main",
                hasNotification: false,
            },
            {
                text: "Relatives",
                icon: "users",
                link: "/family-matching",
                placement: "mobile-sub",
                hasNotification: false,
            },
            {
                text: "Wellbeing",
                icon: "heart",
                link: "/wellbeing",
                placement: "mobile-main",
            },
            {
                text: "Store",
                icon: "shopping-cart",
                link: "/store",
                placement: "mobile-main",
            },
            {
                text: "Profiles",
                icon: "helix",
                link: "/profiles",
                placement: "mobile-sub",
            },
            {
                text: "Account",
                icon: "settings",
                link: "/account",
                placement: "mobile-sub",
            },
        ],
    },
    getters: {
        hasViewedNewChanges: state => state.hasViewedNewChanges,
        desktopItems: state => state.menuItems,
        mobileMainItems: ({ menuItems }) => findPlace(menuItems, "mobile-main"),
        mobileSubItems: ({ menuItems }) => findPlace(menuItems, "mobile-sub"),
    },
    mutations: {
        HIDE_PORTAL_CHANGES_MODAL(state) {
            state.hasViewedNewChanges = true;
        },
    },
    actions: {
        HIDE_PORTAL_CHANGES_MODAL({ commit }) {
            commit("HIDE_PORTAL_CHANGES_MODAL");
        },
    },
};
