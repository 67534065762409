<template>
    <div class="flex flex-col">
        <div class="bg-emerald-50 rounded-full p-2 inline-flex mx-auto">
            <Feather type="check" class="fill-current text-emerald-500 m-auto h-8 w-8" />
        </div>
        <h1 class="mt-4 mb-0 font-normal text-2xl text-center">Kit activated</h1>
        <p class="mt-4 mb-0 text-grey-700 text-center">
            Congratulations! The kit is now activated, and you can proceed to the Dashboard, while
            you wait for the test results.
        </p>
        <router-link
            :to="{ name: 'activation-family-networks-opt-in' }"
            class="bg-cobalt-500 text-white rounded py-2 text-center mt-6"
        >
            <span class="text-white">Continue</span>
        </router-link>
    </div>
</template>

<script>
export default {
    // async created() {}
};
</script>
