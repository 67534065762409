<template>
    <div class="bg-white min-h-screen p-6 pt-12">
        <div>
            <router-link :to="{ name: 'account' }" class="bg-white font-semibold flex items-center">
                <feather type="chevron-left" class="h-5 w-5 mr-2" />

                {{ currentNameState.context.initial.name.first }}'s Account
            </router-link>

            <div class="mx-auto pt-16" :class="$route.meta.fullWidth ? 'max-w-3xl' : 'max-w-xs'">
                <h1 class="font-normal text-2xl">Name</h1>
                <p>The name of the account holder</p>
                <div>
                    <div v-if="currentNameState.matches('fetchingNameInformation')">
                        Loading information
                    </div>
                    <div v-else-if="currentNameState.matches('fetchFailed')">
                        We were unable to load your data
                    </div>
                    <div v-else>
                        <form
                            @input="
                                nameInformationService.send({
                                    type: 'INPUT',
                                    event: $event.target.form.elements,
                                })
                            "
                            @submit.prevent="nameInformationService.send('SAVE')"
                        >
                            <BaseTextInput
                                type="text"
                                label="First name"
                                :value="currentNameState.context.name.first"
                            />
                            <BaseTextInput
                                type="text"
                                label="Last name"
                                class="mt-4"
                                :value="currentNameState.context.name.last"
                            />
                            <div
                                v-if="currentNameState.matches('saveFailed')"
                                class="text-rose-500 text-sm mt-2"
                            >
                                {{ currentNameState.context.errorMessage }}
                            </div>
                            <SubmitButton
                                icon="save"
                                :disabled="
                                    currentNameState.matches('informationIsUnchanged') ||
                                        currentNameState.matches('inputIsInvalid')
                                "
                                :class="{
                                    loading: currentNameState.matches('savingNameInformation'),
                                }"
                            >
                                Save
                            </SubmitButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import { fetchAccountData, saveAccountName } from "@/services/account";
import BaseTextInput from "@/components/Base/BaseTextInput.vue";
import SubmitButton from "@/components/button/SubmitButton.vue";
import store from "@/store/store";

const accountNameMachine = Machine(
    {
        id: "account-name",
        initial: "fetchingNameInformation",
        context: {
            name: {
                first: "",
                last: "",
            },
            initial: {
                name: {
                    first: "",
                    last: "",
                },
            },
            meta: {
                email: "",
                username: "",
            },
            errorMessage: "",
        },
        states: {
            fetchingNameInformation: {
                invoke: {
                    src: "fetchName",
                    onDone: {
                        target: "informationIsUnchanged",
                        actions: ["cacheName", "cacheInitialName", "cacheMeta"],
                    },
                    onError: {
                        target: "fetchFailed",
                    },
                },
            },
            informationIsUnchanged: {
                on: {
                    INPUT: {
                        target: "informationIsModified",
                        actions: "cacheName",
                    },
                },
            },
            informationIsModified: {
                on: {
                    "": [
                        {
                            target: "informationIsUnchanged",
                            cond: "isInformationUnchanged",
                        },
                        {
                            target: "inputIsInvalid",
                            cond: "isInputInvalid",
                        },
                        {
                            target: "inputIsValid",
                            cond: () => true,
                        },
                    ],
                },
            },
            inputIsValid: {
                on: {
                    SAVE: "savingNameInformation",
                    INPUT: {
                        target: "informationIsModified",
                        actions: "cacheName",
                    },
                },
            },
            inputIsInvalid: {
                on: {
                    INPUT: {
                        target: "informationIsModified",
                        actions: "cacheName",
                    },
                },
            },
            savingNameInformation: {
                invoke: {
                    src: "saveName",
                    onDone: "saveSucceeded",
                    onError: "saveFailed",
                },
            },
            saveSucceeded: {
                type: "final",
            },
            saveFailed: {
                entry: "cacheErrorMessage",
                on: {
                    INPUT: "informationIsModified",
                },
            },
            fetchFailed: {},
        },
    },
    {
        services: {
            fetchName: () =>
                new Promise(async (resolve, reject) => {
                    try {
                        const { accountData } = store.getters;
                        resolve({
                            name: {
                                first: accountData.firstName,
                                last: accountData.lastName,
                            },
                            email: accountData.email,
                            username: accountData.username,
                        });
                    } catch (e) {
                        reject(new Error(e));
                    }
                }),
            saveName: context =>
                new Promise(async (resolve, reject) => {
                    try {
                        await saveAccountName(
                            context.meta.email,
                            context.meta.username,
                            context.name.first,
                            context.name.last
                        );
                        resolve();
                    } catch (e) {
                        reject(new Error(e));
                    }
                }),
        },
        actions: {
            cacheName: assign({
                name: (context, event) => ({
                    first: event.data ? event.data.name.first : event.event[0].value,
                    last: event.data ? event.data.name.last : event.event[1].value,
                }),
            }),
            cacheInitialName: assign({
                initial: (context, event) => ({
                    name: {
                        first: event.data.name.first,
                        last: event.data.name.last,
                    },
                }),
            }),
            cacheMeta: assign({
                meta: (context, event) => ({
                    email: event.data.email,
                    username: event.data.username,
                }),
            }),
            cacheErrorMessage: assign({
                errorMessage: () => "There was a problem with your request",
            }),
        },
        guards: {
            isInformationUnchanged: context =>
                JSON.stringify(context.initial.name) === JSON.stringify(context.name),
            isInputInvalid: context =>
                context.name.first.length < 1 || context.name.last.length < 1,
        },
    }
);

export default {
    data() {
        return {
            nameInformationService: interpret(accountNameMachine),
            currentNameState: accountNameMachine.initialState,
        };
    },
    components: { SubmitButton, BaseTextInput },
    created() {
        this.nameInformationService
            .onTransition(async state => {
                if (state.done) {
                    await store.dispatch("getAccountData");
                    this.$router.push({ name: "account" });
                }
                this.currentNameState = state;
            })
            .start();
    },
};
</script>
