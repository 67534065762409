<template>
    <PageLoader v-if="loadingStatus === 'loading'" class="mt-12" />
    <div class="max-w-2xl px-4 py-8 m-auto prose" v-else>
        <h1>{{ references.heading }}</h1>
        <div v-html="references.text" />
    </div>
</template>

<script setup>
import { useClassicalContent } from "@/composables/useClassicalContent";
import { useLoading } from "@/composables/useLoading";
import { ref } from "vue";
import PageLoader from "@/components/PageLoader.vue";

const { loadingStatus, finishLoading } = useLoading();
const references = ref("");

useClassicalContent("egyptian-references")
    .then((result) => {
        references.value = result;
    })
    .finally(finishLoading);
</script>
