<template>
    <form @submit.prevent="handleSave" v-if="typeof fnConsent != 'undefined'">
        <br />
        {{ fieldText }}
        <div class="h-6" />
        <FamilyMatchingSharingPermissionControls
            :starting-value="fnConsent"
            @value-changed="fnConsent = $event"
        />
        <FamilyMatchingSharingPermissionControlsSave />
        <FamilyMatchingSharingPermissionControlsCancel />
    </form>
</template>

<script>
import { interpret } from "xstate";
import { familyMatchingSharingMachineFactory } from "./familyMatchingSharingMachine";
import ProfileService from "@/services/profile";
import FamilyMatchingSharingPermissionControls from "./FamilyMatchingSharingPermissionControls.vue";
import FamilyMatchingSharingPermissionControlsSave from "./FamilyMatchingSharingPermissionControlsSave.vue";
import FamilyMatchingSharingPermissionControlsCancel from "./FamilyMatchingSharingPermissionControlsCancel.vue";

const text = {
    yHaplogroup: "Show other users your LivingDNA determined fatherline Haplogroup",
    mtHaplogroup: "Show other users your LivingDNA determined motherline Haplogroup",
    map: "Share your map and common regions with other users",
};

export default {
    data() {
        return {
            fnConsent: undefined,
            service: undefined,
        };
    },
    async created() {
        try {
            const allFields = await ProfileService.getFamilyNetworkSharingPermissions(
                this.$route.params.barcode
            );
            this.fnConsent = allFields[this.$route.params.field];
        } catch (e) {
            this.fnConsent = false;
        }
        this.service = interpret(
            familyMatchingSharingMachineFactory(
                this.$route.params.barcode,
                this.$route.params.field
            )
        ).start();
        this.service.onTransition(state => {
            if (state.done) {
                this.$router.push({ name: "profile-view" });
            }
        });
    },
    components: {
        FamilyMatchingSharingPermissionControls,
        FamilyMatchingSharingPermissionControlsSave,
        FamilyMatchingSharingPermissionControlsCancel,
    },
    methods: {
        handleSave() {
            this.service.send("SET_NEW_PERMISSION_VALUE", {
                value: this.fnConsent,
            });
        },
    },
    computed: {
        fieldText() {
            const fieldText = text[this.$route.params.field];
            if (!fieldText) {
                console.warn(`Invalid field name: ${this.$route.params.field}`);
            }
            return fieldText;
        },
    },
};
</script>
