<template>
    <div>
        <MapTabs v-if="match" :mode="mode" @tab-change="handleTabChange">
            <template #map>
                <div class="mt-0 mb-2 px-6">
                    <strong>Understanding the map</strong> <br />
                    This map shows both yours and your matches' regions. The coloured regions are
                    the regions you share with your match. The deeper the colour the more DNA they
                    have from that region. The grey regions are ones you do not share with your
                    match.
                    <br />

                    <br />
                    <strong>Why is this helpful to know?</strong> <br />
                    Understanding the ancestry regions you and your match have in common can help
                    you with your research, as it is likely that specific DNA haplotypes from a
                    shared ancestry region have been inherited from your common ancestor, who will
                    share this region with you both.
                    <MapSharingNudge
                        class="py-2 px-6 bg-cobalt-50 rounded my-2"
                        :name="match.username || match.name"
                    />
                    <br />
                    <br />
                    <strong>Click on a region for more information</strong> <br />
                    <template v-if="selectedRegion">
                        {{ selectedRegion.properties.title }} -
                        {{ selectedRegion.properties.percentage }}%
                    </template>
                    <template v-else> Nothing selected </template>
                </div>
                <div style="height: 500px">
                    <Map @click="layerClick" :map-data="mapData" />
                </div>
            </template>
            <template #regionList>
                <div class="md:px-6 md:mb-6">
                    <MapRegionListTable :rows="regionsWithoutChildren" />
                </div>
            </template>
            <template #notOptedIn>
                <div
                    class="flex flex-col items-start max-w-md m-auto gap-4 bg-white p-6 shadow-drama rounded relative z-10 border-t-4 border-cobalt-500 my-16"
                >
                    <div class="bg-cobalt-50 p-4 inline-block rounded-full">
                        <Feather type="lock" class="text-cobalt-500 block" />
                    </div>

                    <div>
                        <h2 class="text-lg font-semibold m-0 pb-2">
                            <div>
                                {{ match.username || match.name }} does not have ancestry data to
                                share
                            </div>
                        </h2>
                    </div>
                </div>
            </template>
            <template #notUpgraded>
                <div
                    class="flex flex-col items-start max-w-md m-auto gap-4 bg-white p-6 shadow-drama rounded relative z-10 border-t-4 border-rose-500 my-16"
                >
                    <div class="bg-rose-50 p-4 inline-block rounded-full">
                        <Feather type="lock" class="text-rose-500 block" />
                    </div>

                    <div>
                        <h2 class="text-lg font-semibold m-0 pb-2">
                            <div>You need to purchase the autosomal ugprade to use shared maps</div>
                        </h2>
                    </div>

                    <router-link
                        to="/store/autosomal-upgrade"
                        class="text-cobalt-500 font-semibold flex items-center"
                    >
                        <FeatherWrapper icon="zap" class="mr-2" />
                        Upgrade now
                    </router-link>
                </div>
            </template>
            <template #loading>
                <div class="loading-large mt-8" />
                <div class="text-lg text-center mt-4 mb-8">Loading</div>
            </template>
        </MapTabs>
    </div>
</template>

<script>
import Map from "./MapImplementation.vue";
import {
    combineMatchAndCurrentProfileMapData,
    byPercentageDesc,
    constructMapDataFromResults,
} from "@/views/ancestry/recent/mapHelpers";
import MapSharingNudge from "./MapSharingNudge.vue";
import MapTabs from "./MapTabs.vue";
import MapRegionListTable from "@/views/FamilyNetworks/FamilyMatching/DetailsView/MapRegionListTable.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

const autosomalMapSource = {
    id: process.env.VUE_APP_MAP_TILESET_URL,
    type: "vector",
    url: `mapbox://${process.env.VUE_APP_MAP_TILESET_URL}`,
};

export default {
    props: {
        match: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            autosomalMapSource,
            mapData: undefined,
            map: undefined,
            selectedRegion: undefined,
            mode: "loading",
        };
    },
    async created() {
        if (!this.$store.getters.kitTests.autosomal.purchased) {
            this.mode = "notUpgraded";
        } else if (!this.match.autosomal) {
            this.mode = "notOptedIn";
        } else {
            const matchMapData = constructMapDataFromResults(
                this.match.autosomal.results.testData.autosomal.data.current.speculative
            );

            await this.$store.dispatch("GET_COMPLETE", {
                barcode: this.$store.getters.profile.barcode,
            });

            this.mapData = combineMatchAndCurrentProfileMapData(
                matchMapData,
                this.$store.getters.autosomalCompleteMap.data
            ).sort(byPercentageDesc);

            this.mode = "map";
        }
    },
    methods: {
        handleTabChange(newTab) {
            this.mode = newTab;
            this.selectedRegion = undefined;
        },
        layerClick(event) {
            if (event.feature) {
                console.log(
                    event.feature.properties.code,
                    this.regionsWithoutChildren[0].properties.code
                );
                this.selectedRegion = this.regionsWithoutChildren.find(
                    (region) => region.properties.code === event.feature.properties.code
                );
            }
        },
    },
    computed: {
        regionsWithoutChildren() {
            if (this.mapData) {
                return this.mapData.filter((region) => region.properties.childrenCount === 0);
            }
            return [];
        },
    },
    components: {
        MapRegionListTable,
        MapSharingNudge,
        Map,
        MapTabs,
        FeatherWrapper,
    },
};
</script>
