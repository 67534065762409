<template>
    <div @input="informationUpdated" @change="informationUpdated" class="mt-4">
        <div>
            <div class="flex items-center">
                <input
                    type="checkbox"
                    id="other-living"
                    v-model="living"
                    class="form-checkbox rounded w-6 h-6 text-cobalt-500 mr-3 focus:outline-none focus:border-cobalt-500 border-grey-500"
                />
                <label for="other-living" class="cursor-pointer text-xs">Person is living</label>
            </div>
            <div v-show="!living" class="mt-1">
                <DateInput v-model="dateOfDeath">
                    Approx. date of death <span class="text-grey-500">(MM/DD/YYYY)</span>
                </DateInput>
                <div
                    v-if="!dateOfDeath.isValid && dateOfDeath.year && dateOfDeath.year > 1880"
                    class="text-xs text-rose-500 mt-1"
                >
                    Invalid date, please check format
                </div>
            </div>
        </div>
        <div class="flex items-center mt-2">
            <input
                type="checkbox"
                id="have-permission"
                v-model="permission"
                class="form-checkbox rounded w-6 h-6 text-cobalt-500 mr-3 border-grey-500"
            />
            <label for="have-permission" class="cursor-pointer text-xs md:whitespace-no-wrap"
                >I have their explicit consent to manage their DNA data</label
            >
        </div>
    </div>
</template>

<script>
import DateInput from "@/components/inputs/DateInput.vue";

export default {
    components: { DateInput },
    props: {
        modelValue: Object,
    },
    data() {
        return {
            living: true,
            dateOfDeath: {},
            permission: false,
        };
    },
    mounted() {
        this.$emit("update:modelValue", this.information);
    },
    methods: {
        informationUpdated() {
            this.$emit("update:modelValue", this.information);
        },
    },
    computed: {
        information() {
            const information = {
                living: this.living,
                permission: this.permission,
            };
            if (!this.living) {
                Object.assign(information, {
                    dateOfDeath: `${this.dateOfDeath.year}-${this.dateOfDeath.month}-${this.dateOfDeath.day}`,
                    dateOfDeathIsValid: this.dateOfDeath.isValid,
                });
            }
            console.log(information);
            return information;
        },
    },
};
</script>
