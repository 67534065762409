<template>
    <Form @submit="emitFormSubmit">
        <BaseTextInput
            name="firstName"
            label="First name"
            class="mt-2"
            :initial-value="address.firstName"
            required
        />
        <BaseTextInput
            name="lastName"
            label="Last name"
            class="mt-2"
            :initial-value="address.lastName"
            required
        />
        <BaseTextInput
            name="lineOne"
            label="Address line one"
            class="mt-2"
            :initial-value="address.lineOne"
            required
        />
        <BaseTextInput
            name="lineTwo"
            label="Address line two"
            class="mt-2"
            :initial-value="address.lineTwo"
        />
        <BaseTextInput name="city" class="mt-2" :initial-value="address.city" required />
        <BaseTextInput name="zip" class="mt-2" :initial-value="address.zip" required />
        <div class="space-y-1">
            <label for="countryCode" class="block mt-2">Country *</label>
            <CountryCodePicker
                v-if="isForKit"
                v-model="address.countryCode"
                :prevented-countries="['FR']"
            />
            <CountryCodePicker v-else v-model="address.countryCode" />
        </div>
        <BaseTextInput name="phone" class="mt-2" :initial-value="address.phone" required />
        <button
            type="submit"
            class="mt-6 bg-cobalt-500 font-semibold rounded p-3 text-center text-white w-full focus:outline-non hover:bg-cobalt-700"
        >
            Continue
        </button>
    </Form>
</template>

<script>
import { Field, Form, ErrorMessage } from "vee-validate";
import BaseTextInput from "@/components/inputs/BaseTextInput.vue";
import CountryCodePicker from "@/components/CountryPicker/CountryCodePicker.vue";

export default {
    props: {
        formType: {
            type: String,
            required: true,
            validator(value) {
                console.log(value);
                return ["shipping", "billing"].includes(value);
            },
        },
        address: {
            type: Object,
            required: false,
            validator(value) {
                const keys = Object.keys(value);
                const requiredValues = [
                    "firstName",
                    "lastName",
                    "lineOne",
                    "lineTwo",
                    "city",
                    "zip",
                    "countryCode",
                ];
                if (keys.length === 0) {
                    return true;
                } else {
                    const notProvided = requiredValues.flatMap((value) => {
                        return keys.includes(value) ? [] : value;
                    });
                    if (notProvided.length > 0) {
                        console.warn("Default values for some fields not provided", notProvided);
                        return false;
                    }
                    return true;
                }
            },
        },
        isForKit: {
            type: Boolean,
        },
    },
    methods: {
        emitFormSubmit(values) {
            this.$emit("form-submitted", values);
        },
    },
    components: { Field, Form, ErrorMessage, BaseTextInput, CountryCodePicker },
};
</script>
