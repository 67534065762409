<template>
    <div class="h-full w-full">
        <div id="map" class="h-full w-full" />
    </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import store from "@/store/store";
import { accessToken, mapStyle } from "@/views/ancestry/recent/mapboxConfig";
import { MapLayer, calculateOpacity, ActiveLayer } from "@/views/ancestry/recent/mapHelpers";

export default {
    props: {
        mapData: Array,
    },
    setup(props, { emit }) {
        let activeCode = undefined;
        mapboxgl.accessToken = accessToken;
        const router = useRouter();
        const route = useRoute();

        onMounted(() => {
            const map = new mapboxgl.Map({
                container: "map",
                style: mapStyle,
                center: [-2.3018512, 51.2343397],
                zoom: 1,
            });

            map.on("load", () => {
                map.addSource("tests", {
                    // maybe rename this?
                    type: "vector",
                    url: store.getters.autosomalMapSource.url,
                });

                props.mapData.forEach((mapElement) => {
                    map.addLayer(
                        new MapLayer(
                            mapElement.id,
                            mapElement.properties.colour,
                            mapElement.properties.code,
                            false,
                            calculateOpacity(mapElement.properties.percentage),
                            store.getters.autosomalMapSource
                        )
                    );
                    map.on("click", mapElement.id, ({ features }) => {
                        if (map.getLayer("activeLayer")) {
                            map.removeLayer("activeLayer");
                        }
                        const [feature] = features;
                        emit("click", { feature });
                        const featureDetails = props.mapData.find(
                            (region) => region.id === feature.layer.id
                        );
                        console.log(featureDetails);
                        function findFeaturesByCode(code) {
                            return map.querySourceFeatures("tests", {
                                sourceLayer: process.env.VUE_APP_MAP_TILESET_URL,
                                filter: ["==", "code", code],
                            });
                        }
                        function boundingBoxMove(featureCollection, padding) {
                            const bbox = turf.bbox(featureCollection);
                            if (bbox.filter((item) => Number.isFinite(item)).length === 4) {
                                console.log(featureCollection);
                                map.fitBounds(bbox, { padding });
                            }
                        }
                        function getSelectedFeatures() {
                            const features = props.mapData.flatMap((popGroup) =>
                                findFeaturesByCode(feature.properties.code)
                            );
                            return {
                                type: "FeatureCollection",
                                features,
                            };
                        }
                        boundingBoxMove(getSelectedFeatures(), 100);
                        map.addLayer(
                            new ActiveLayer(
                                featureDetails.properties.colour,
                                featureDetails.properties.code
                            )
                        );
                    });
                });

                // wait until all map features are rendered
                setTimeout(() => {
                    function findFeaturesByCode(code) {
                        return map.querySourceFeatures("tests", {
                            sourceLayer: process.env.VUE_APP_MAP_TILESET_URL,
                            filter: ["==", "code", code],
                        });
                    }
                    function getFeatures() {
                        const features = props.mapData.flatMap((popGroup) =>
                            findFeaturesByCode(popGroup.properties.code)
                        );
                        return {
                            type: "FeatureCollection",
                            features,
                        };
                    }
                    function boundingBoxMove(featureCollection, padding) {
                        const bbox = turf.bbox(featureCollection);
                        if (bbox.filter((item) => Number.isFinite(item)).length === 4) {
                            console.log(featureCollection);
                            map.fitBounds(bbox, { padding });
                        }
                    }
                    const allFeatures = getFeatures();
                    watch(
                        () => route.params.groupId,
                        (groupId) => {
                            if (map.getLayer("activeLayer")) {
                                map.removeLayer("activeLayer");
                            }
                            if (groupId) {
                                const selectedGroup = props.mapData.find(
                                    (popGroup) => popGroup.id === route.params.groupId
                                );
                                function getSelectedFeatures() {
                                    const features = props.mapData.flatMap((popGroup) =>
                                        findFeaturesByCode(selectedGroup.properties.code)
                                    );
                                    return {
                                        type: "FeatureCollection",
                                        features,
                                    };
                                }
                                boundingBoxMove(getSelectedFeatures(), 100);
                                map.addLayer(
                                    new ActiveLayer(
                                        selectedGroup.properties.colour,
                                        selectedGroup.properties.code
                                    )
                                );
                            } else {
                                boundingBoxMove(allFeatures, 100);
                            }
                        },
                        {
                            immediate: true,
                        }
                    );
                }, 500);
            });
        });
    },
};
</script>
