<template>
    <div>
        <div v-if="loadingStatus === 'loading'">
            <PageLoader> Loading your result </PageLoader>
        </div>
        <div v-else class="px-8 py-12 max-w-4xl m-auto">
            <h1 class="text-2xl font-normal mb-12">
                {{ traitOverview.heading }}
            </h1>
            <img :src="traitOverview.mainImage.urlRaw" :alt="traitOverview.mainImage.alt" />
            <div class="mt-12 mb-24" v-html="traitOverview.text" />
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2">
                <ArchaicTraitsItem
                    v-for="trait in cmsTraits"
                    :key="trait.slug"
                    :result="archaicResult.traits[trait.slug]"
                    :cms-data="trait"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { getArchaicTrait, getArchaicTraits } from "@/services/archaic";
import ArchaicTraitsItem from "./ArchaicTraitsItem.vue";
import PageLoader from "@/components/PageLoader.vue";

export default {
    props: {
        archaicResult: Object,
    },
    components: { ArchaicTraitsItem, PageLoader },
    setup({ archaicResult }) {
        const traitNames = Object.keys(archaicResult.traits);
        console.log({ traitNames });
        const loadingStatus = ref("loading");
        const cmsTraits = ref([]);
        const traitOverview = ref({});
        onMounted(async () => {
            const promiseArr = traitNames.map((traitName) => getArchaicTrait(traitName));
            cmsTraits.value = await Promise.all(promiseArr);
            traitOverview.value = await getArchaicTraits();
            loadingStatus.value = "loaded";
        });

        return { loadingStatus, cmsTraits, traitOverview };
    },
};
</script>
