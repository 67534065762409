<template>
    <div>
        <h3 class="text-denim-700 text-2xl mt-12 flex items-center font-normal">
            <feather type="map-pin" class="h-5 w-5 mr-4"></feather>
            Ancestry
        </h3>
        <div class="flex mt-6 gap-4 max-xl:flex-wrap">
            <div class="flex flex-col w-full xl:w-1/2">
                <AutosomalStarter v-if="store.getters.starterTestMode" />
                <Autosomal v-else />
            </div>
            <div
                v-if="$store.getters.selectedTest.raw_status !== 'shipped'"
                class="w-full xl:w-1/2 flex flex-col"
            >
                <div v-if="$store.getters.selectedTest.raw_status !== 'testing_failed'">
                    <div v-if="kitTests.mtdna.purchased && kitTests.ydna.purchased">
                        <div v-if="parentalTestsComplete" class="flex gap-3">
                            <div class="w-full sm:w-1/2 mb-6 xl:mb-0">
                                <MaternalCardFactory v-slot="{ component, maternalData }">
                                    <component :is="component" :maternal-data="maternalData" />
                                </MaternalCardFactory>
                            </div>
                            <div class="w-full sm:w-1/2 px-3 mb-6 xl:mb-0">
                                <PaternalCardFactory v-slot="{ component, paternalData }">
                                    <component :is="component" :paternal-data="paternalData" />
                                </PaternalCardFactory>
                            </div>
                        </div>
                        <ParentalCardNotComplete v-else />
                    </div>
                    <ParentalCardNotPurchased
                        v-else-if="$store.getters.profile.barcode.includes('LT')"
                    />
                </div>
                <!-- <OfferCard /> -->
            </div>
        </div>
    </div>
    <FeaturedUpgrades v-if="$store.getters.selectedTest.raw_status !== 'shipped'" />
</template>

<script setup>
import { onMounted, computed } from "vue";
import axios from "axios";
import PaternalCardFactory from "./PaternalCard/PaternalCardFactory.vue";
import MaternalCardFactory from "./MaternalCard/MaternalCardFactory.vue";
import ParentalCardNotPurchased from "@/components/Dashboard/Ancestry/ParentalCard/ParentalCardNotPurchased.vue";
import ParentalCardNotComplete from "@/components/Dashboard/Ancestry/ParentalCard/ParentalCardNotComplete.vue";
import FeaturedUpgrades from "./FeaturedUpgrades.vue";
import Autosomal from "./Autosomal.vue";
import store from "@/store/store";
import AutosomalStarter from "@/components/Dashboard/Ancestry/AutosomalStarter.vue";

const kitTests = computed(() => store.getters.kitTests);

onMounted(() => {
    if (
        kitTests.value.autosomalStarter.purchased ||
        kitTests.value.autosomal.purchased ||
        kitTests.value.mtdna.purchased ||
        kitTests.value.ydna.purchased
    ) {
        store.dispatch("getAncestryData", {
            barcode: store.state.profileModule.profile.barcode,
            http: axios,
        });
    }
});

const parentalTestsComplete = computed(() => {
    if (store.getters.profile.gender === 1) {
        return kitTests.value.mtdna.complete && kitTests.value.ydna.complete;
    }
    return kitTests.value.mtdna.complete;
});
</script>
