import endpoints from "@/endpoints";
import axios from "axios";

const initialState = () => ({
    ancestryData: {
        maternal: {},
        paternal: {},
        regions: [],
    },
    familyMatching: [],
    familyMatchingCount: null,
    onboardingStatus: {},
    version: null,
});

export default {
    state: initialState(),
    getters: {
        ancestryData: (state) => state.ancestryData,
        familyMatching: (state) => state.familyMatching,
        randomMatch: (state) =>
            state.familyMatching[Math.floor(Math.random() * state.familyMatching.length)],
        familyMatchingCount: (state) => state.familyMatchingCount,
        onboardingStatus: (state) => state.onboardingStatus,
        familyMatchingVersion: (state) => state.version,
    },
    actions: {
        getAncestryData({ commit }, { barcode, http }) {
            commit(
                "SET_ANCESTRY_DATA",
                new Promise((resolve, reject) => {
                    http.get(endpoints.ancestryDashboardData(barcode))
                        .then((res) => resolve(res.data))
                        .catch((err) => reject(err));
                })
            );
        },
        getOnboardingStatus({ commit }, { barcode, http }) {
            return new Promise((resolve, reject) => {
                http.get(endpoints.profileStatus(barcode))
                    .then((res) => resolve(commit("SET_ONBOARDING_STATUS", res.data)))
                    .catch((err) => reject(err));
            });
        },
        getFamilyMatchingData({ commit }, { barcode, http }) {
            return new Promise((resolve, reject) => {
                http.get(endpoints.getMatches(barcode), {
                    params: {
                        sortBy: "dateAdded",
                        // query,
                        // page,
                        // itemsPerPage,
                    },
                })
                    .then((res) => {
                        commit("setFamilyMatchingCount", res.data.total);
                        commit("setFamilyMatchingVersion", res.data.version);
                        resolve(commit("setFamilyMatchingData", res.data.matches));
                    })
                    .catch((err) => reject(err));
            });
        },
        setVideoAsWatched({ commit }, { video, barcode, http }) {
            http.put(endpoints.allConsents(barcode), {
                [video]: true,
            })
                .then(() => commit("setVideoAsWatched", video))
                .catch(() => null); // todo can we catch this anywhere?
        },
        // todo move the fam match and research consents stuff to a consents store
        setFamilyMatchingAsVisited({ commit }, { barcode, http }) {
            http.put(endpoints.allConsents(barcode), {
                fnConsent: false,
            })
                .then(() => commit("setFamilyMatchingAsVisited"))
                .catch(() => null);
        },
        setFamilyMatchingMainTermsAsAccepted({ commit }, { barcode, http }) {
            return new Promise((resolve, reject) => {
                http.put(endpoints.allConsents(barcode), {
                    fnConsent: true,
                })
                    .then(() => resolve(commit("setFamilyMatchingMainTermsAsAccepted")))
                    .catch(() => reject());
            });
        },
        setFamilyMatchingAdditionalTermsAsAccepted({ commit }, { barcode, http }) {
            return new Promise((resolve, reject) => {
                http.put(endpoints.allConsents(barcode), {
                    partnersConsent: true,
                })
                    .then(() => resolve(commit("setFamilyMatchingAdditionalTermsAsAccepted")))
                    .catch(() => reject());
            });
        },
        setResearchAsVisited({ commit }, { barcode, http }) {
            http.put(endpoints.allConsents(barcode), {
                researchConsent: false,
            })
                .then(() => commit("setResearchAsVisited"))
                .catch(() => null);
        },
        CLEAR_DASHBOARD_MODULE({ commit }) {
            commit("CLEAR_DASHBOARD_MODULE");
        },
    },
    mutations: {
        SET_ANCESTRY_DATA(state, request) {
            state.ancestryData = request;
        },
        SET_ONBOARDING_STATUS(state, request) {
            state.onboardingStatus = request;
        },
        setFamilyMatchingData(state, data) {
            state.familyMatching = data;
        },
        setFamilyMatchingVersion(state, data) {
            state.version = data;
        },
        setFamilyMatchingCount(state, count) {
            state.familyMatchingCount = count;
        },
        CLEAR_DASHBOARD_MODULE(state) {
            Object.assign(state, initialState());
        },
        setVideoAsWatched(state, video) {
            state.onboardingStatus[video] = true;
        },
        setFamilyMatchingAsVisited(state) {
            state.onboardingStatus.familyNetworks = false;
        },
        setFamilyMatchingMainTermsAsAccepted(state) {
            state.onboardingStatus.familyNetworks = true;
        },
        setFamilyMatchingAdditionalTermsAsAccepted(state) {
            // state.onboardingStatus.partnersConsent = true;
        },
        setResearchAsVisited(state) {
            state.onboardingStatus.researchConsent = true;
        },
    },
};
