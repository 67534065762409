<template>
    <div>
        <component
            :is="component"
            :title="props.title"
            :body="props.body"
            :buttonText="props.buttonText"
            :routerLink="props.routerLink"
        />
    </div>
</template>

<script>
import store from "@/store/store";
import SuccessView from "@/components/activation/SuccessView.vue";
import ErrorView from "@/components/activation/ErrorView.vue";

export default {
    beforeRouteEnter(to, from, next) {
        store
            .dispatch("validateEmail", { token: to.params.token })
            .then(res => {
                next(vm => {
                    vm.component = SuccessView;
                    vm.props = {
                        title: "Email validated",
                        body:
                            "Congratulations! Your email has been verified and you're now able to log in and access your portal.",
                        buttonText: "Log in",
                        routerLink: { name: "login" },
                    };
                });
            })
            .catch(err => {
                console.log(err);
                next(vm => {
                    vm.component = ErrorView;
                    vm.props = {
                        title: "Unable to validate email",
                        body:
                            "Unfortunately your email could not be validated, this might be because you've already validated this email address.",
                        buttonText: "Log in",
                        routerLink: { name: "login" },
                    };
                });
            });
    },
    components: { SuccessView },
    data() {
        return {
            component: {},
            props: {},
        };
    },
};
</script>
