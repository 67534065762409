import endpoints from "@/endpoints";
import axios from "axios";

export const fetchAccountData = async () => {
    try {
        const response = await axios.get(endpoints.account);
        return response.data;
    } catch (e) {
        throw e;
    }
};

export const saveAccountName = async (email, username, firstName, lastName) => {
    try {
        const response = await axios.put(endpoints.account, {
            account: {
                email,
                username,
                firstName,
                lastName,
            },
        });
    } catch (e) {
        console.log("here");
        throw e;
    }
};

export const saveAccountEmail = async (email, username, firstName, lastName) => {
    try {
        const response = await axios.put(endpoints.account, {
            account: {
                email,
                username,
                firstName,
                lastName,
            },
        });
    } catch (e) {
        console.log("here");
        throw e;
    }
};

export async function saveNewPasswordForAccount(email, username, currentPassword, newPassword) {
    try {
        const response = await fetch(endpoints.account, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token").split("--")[0]}`,
            },
            body: JSON.stringify({
                account: {
                    email,
                    username,
                    oldPassword: currentPassword,
                    password: newPassword,
                },
            }),
        });

        if (!response.status !== 401) {
            throw new Error("The current password you provided does not match our records");
        }

        if (!response.ok) {
            throw new Error("Something went wrong saving your password");
        }

        return response.data;
    } catch (e) {
        console.log("here");
        throw e;
    }
}
