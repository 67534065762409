import ProfileList from "@/views/profiles/ProfileList/ProfileList.vue";
import ProfileView from "@/views/profiles/ProfileView/ProfileView.vue";
import ProfileEdit from "@/views/profiles/ProfileEdit/ProfileEdit.vue";
import EditName from "@/views/profiles/ProfileEdit/details/EditName.vue";
import EditDisplayName from "@/views/profiles/ProfileEdit/details/EditDisplayName.vue";
import EditIdentity from "@/views/profiles/ProfileEdit/details/EditIdentity.vue";
import EditSex from "@/views/profiles/ProfileEdit/details/EditSex.vue";
import EditLifetime from "@/views/profiles/ProfileEdit/details/EditLifetime.vue";
import EditBirthplace from "@/views/profiles/ProfileEdit/details/EditBirthplace.vue";
import EditPartners from "@/views/profiles/ProfileEdit/details/EditPartners.vue";
import EditFamilyMatching from "@/views/profiles/ProfileEdit/details/EditFamilyMatching.vue";
import ReuploadDna from "@/views/profiles/ProfileEdit/details/ReuploadDna.vue";
import FamilyMatchingOptIn from "@/views/profiles/ProfileEdit/details/FamilyMatchingOptIn.vue";
import EditManager from "@/views/profiles/ProfileEdit/details/EditManager.vue";
import FamilyMatchingOptInAdditional from "@/views/profiles/ProfileEdit/details/FamilyMatchingOptInAdditional.vue";
import EditAvatar from "@/views/profiles/ProfileEdit/details/EditAvatar.vue";
import EditBio from "@/views/profiles/ProfileEdit/details/EditBio.vue";
import StartDeletion from "@/views/profiles/ProfileEdit/details/Delete/StartDeletion.vue";
import DeleteProfile from "@/views/profiles/ProfileEdit/details/Delete/DeleteProfile.vue";
import RequestDeletion from "@/views/profiles/ProfileEdit/details/Delete/RequestDeletion.vue";
import ConfirmDeletion from "@/views/profiles/ProfileEdit/details/Delete/ConfirmDeletion.vue";
import ReceivedDeletion from "@/views/profiles/ProfileEdit/details/Delete/ReceivedDeletion.vue";
import EditFamilyMatchingSharingPermission from "@/views/profiles/ProfileEdit/details/FamilyMatchingSharingPermission/EditFamilyMatchingSharingPermission.vue";

export default [
    {
        path: "/profiles",
        name: "profiles",
        component: ProfileList,
    },
    {
        path: "/profiles/:barcode",
        name: "profile-view",
        component: ProfileView,
        meta: {
            scrollTopTo: 0,
        },
    },
    {
        path: "/profiles/:barcode/edit",
        component: ProfileEdit,
        children: [
            {
                path: "name",
                component: EditName,
            },
            {
                path: "display-name",
                component: EditDisplayName,
            },
            {
                path: "identity",
                component: EditIdentity,
            },
            {
                path: "biological-sex",
                component: EditSex,
            },
            {
                path: "lifetime",
                component: EditLifetime,
            },
            {
                path: "birthplace",
                component: EditBirthplace,
            },
            {
                path: "partners",
                component: EditPartners,
            },
            {
                path: "family-matching",
                component: EditFamilyMatching,
            },
            {
                path: "family-matching-opt-in",
                name: "family-matching-opt-in",
                component: FamilyMatchingOptIn,
                meta: {
                    fullWidth: true,
                },
            },
            {
                path: "manager",
                component: EditManager,
            },
            {
                path: "family-matching-opt-in-additional",
                name: "family-matching-opt-in-additional",
                component: FamilyMatchingOptInAdditional,
                meta: {
                    fullWidth: true,
                },
            },
            {
                path: "/profiles/:barcode/edit/reupload-dna",
                component: ReuploadDna,
                name: "reupload-dna",
            },
            {
                path: "avatar",
                component: EditAvatar,
                name: "edit-avatar",
            },
            {
                path: "bio",
                component: EditBio,
                name: "edit-bio",
                meta: {
                    fullWidth: true,
                },
            },
            {
                path: "fn-sharing/:field",
                component: EditFamilyMatchingSharingPermission,
                name: "edit-fn-sharing-field",
            },
            {
                path: "delete",
                component: DeleteProfile,
                redirect: "delete/start",
                children: [
                    {
                        path: "start",
                        component: StartDeletion,
                        name: "start-deletion",
                    },
                    {
                        path: "request",
                        component: RequestDeletion,
                        name: "request-deletion",
                    },
                    {
                        path: "confirm",
                        component: ConfirmDeletion,
                        name: "confirm-deletion",
                    },
                    {
                        path: "received",
                        component: ReceivedDeletion,
                        name: "received-deletion",
                    },
                ],
            },
        ],
    },
];
