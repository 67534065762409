<template>
    <Card class="bg-white h-full text-grey-700 flex flex-col">
        <p class="mt-0 mb-4">Add family and friends to make more discoveries.</p>
        <router-link
            :to="{ name: 'who-does-this-dna-belong-to' }"
            class="flex items-center mt-auto text-cobalt-500"
        >
            <feather-wrapper icon="upload" />
            <span class="ml-2 font-semibold">Upload more DNA</span>
        </router-link>
        <router-link :to="{ name: 'enter-code' }" class="flex items-center mt-2 text-cobalt-500">
            <feather-wrapper icon="toggle-left" />
            <span class="ml-2 font-semibold">Activate another kit</span>
        </router-link>
    </Card>
</template>

<script>
import Card from "@/components/cards/Card.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

export default {
    components: { Card, FeatherWrapper },
};
</script>
