<template>
    <div>
        <MigrationMap :result="sharingStore.fatherlineResult" type="ydna" />
    </div>
</template>

<script setup>
import MigrationMap from "./SharingMigrationMap.vue";
import { useSharingStore } from "@/store/sharing";

const sharingStore = useSharingStore();
</script>
