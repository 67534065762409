<template>
    <svg
        id="Capa_1"
        enable-background="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                d="m435.252 314.268c-3.587-2.072-8.174-.843-10.245 2.745l-45.991 79.66c-5.772-2.494-12.129-3.882-18.806-3.882-26.236 0-47.581 21.344-47.581 47.581 0 26.236 21.345 47.581 47.581 47.581s47.581-21.345 47.581-47.581c0-14.176-6.239-26.916-16.107-35.64l46.314-80.218c2.071-3.588.842-8.175-2.746-10.246zm-75.043 158.684c-17.965 0-32.581-14.616-32.581-32.581s14.616-32.581 32.581-32.581 32.581 14.615 32.581 32.581c.001 17.965-14.615 32.581-32.581 32.581z"
            />
            <path
                d="m288.064 432.871h-89.29c-3.608-22.685-23.3-40.081-46.984-40.081-26.236 0-47.581 21.344-47.581 47.581 0 26.236 21.345 47.581 47.581 47.581 23.684 0 43.376-17.396 46.984-40.081h89.29c4.142 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zm-136.273 40.081c-17.965 0-32.581-14.616-32.581-32.581s14.616-32.581 32.581-32.581 32.581 14.615 32.581 32.581c-.001 17.965-14.617 32.581-32.581 32.581z"
            />
            <path
                d="m106.797 381.315c1.389 2.406 3.91 3.751 6.502 3.751 1.272 0 2.562-.324 3.743-1.006 3.587-2.071 4.816-6.658 2.745-10.245l-45.357-78.56c12.507-8.582 20.731-22.974 20.731-39.255 0-26.236-21.345-47.581-47.581-47.581s-47.58 21.345-47.58 47.581 21.345 47.581 47.581 47.581c4.596 0 9.037-.668 13.245-1.89zm-91.797-125.315c0-17.965 14.616-32.581 32.581-32.581s32.58 14.616 32.58 32.581-14.616 32.581-32.581 32.581-32.58-14.616-32.58-32.581z"
            />
            <path
                d="m76.791 197.658c1.181.682 2.471 1.006 3.743 1.006 2.592 0 5.113-1.345 6.502-3.751l45.949-79.585c5.772 2.494 12.129 3.882 18.806 3.882 26.236 0 47.581-21.344 47.581-47.581s-21.345-47.581-47.581-47.581-47.581 21.345-47.581 47.581c0 14.176 6.239 26.916 16.107 35.64l-46.271 80.144c-2.072 3.587-.843 8.174 2.745 10.245zm75-158.61c17.965 0 32.581 14.616 32.581 32.581s-14.616 32.581-32.581 32.581-32.581-14.615-32.581-32.581 14.615-32.581 32.581-32.581z"
            />
            <path
                d="m223.936 79.129h89.29c3.608 22.685 23.3 40.081 46.984 40.081 26.236 0 47.581-21.344 47.581-47.581s-21.345-47.581-47.581-47.581c-23.684 0-43.376 17.396-46.984 40.081h-89.29c-4.142 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5zm136.273-40.081c17.965 0 32.581 14.616 32.581 32.581s-14.616 32.581-32.581 32.581-32.581-14.615-32.581-32.581 14.617-32.581 32.581-32.581z"
            />
            <path
                d="m464.419 208.419c-4.597 0-9.038.668-13.246 1.89l-45.788-79.309c-2.071-3.588-6.659-4.816-10.245-2.745-3.587 2.071-4.816 6.658-2.745 10.245l45.175 78.246c-12.507 8.582-20.731 22.974-20.731 39.254 0 26.236 21.345 47.581 47.581 47.581s47.58-21.345 47.58-47.581-21.345-47.581-47.581-47.581zm0 80.162c-17.965 0-32.581-14.615-32.581-32.581s14.616-32.581 32.581-32.581 32.581 14.616 32.581 32.581-14.616 32.581-32.581 32.581z"
            />
        </g>
    </svg>
</template>
