<template>
    <div>
        <h1 class="font-normal text-2xl">Confirm your password</h1>
        <p>Reenter your password to confirm deletion.</p>
        <PasswordInput
            @update:model-value="confirmPasswordService.send('INPUT', { data: $event })"
            :model-value="currentConfirmPasswordState.context.password"
            new-password
            :show-label="false"
        />
        <template v-if="currentConfirmPasswordState.matches('incorrectPassword')">
            <p class="text-sm text-rose-500 mb-0 mt-4">Your password was incorrect</p>
        </template>
        <template v-if="currentConfirmPasswordState.matches('unableToDeleteProfile')">
            <p class="text-sm text-rose-500 mb-0 mt-4">
                You've entered the <strong>correct</strong> password but we were unable to delete
                your account. Please contact customer service at EMAIL ADDRESS and we will be able
                to manually delete your account for you.
            </p>
        </template>
        <SubmitButton
            v-if="currentConfirmPasswordState.matches('hasInput')"
            @click.native="confirmPasswordService.send('SUBMIT')"
            class="bg-rose-500 hover:bg-rose-500"
            icon="trash-2"
        >
            Confirm deletion
        </SubmitButton>
        <SubmitButton
            v-else-if="currentConfirmPasswordState.matches('checkingInput')"
            class="bg-rose-500 hover:bg-rose-500 loading"
            icon="trash-2"
        >
            Confirm deletion
        </SubmitButton>
        <SubmitButton v-else class="bg-grey-500" icon="trash-2" disabled>
            Confirm deletion
        </SubmitButton>
        <router-link
            :to="{ name: 'account' }"
            class="text-center text-grey-500 font-semibold mt-4 block"
        >
            Cancel
        </router-link>
    </div>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import ProfileService from "@/services/profile";
import PasswordInput from "@/components/inputs/PasswordInput/PasswordInput.vue";
import SubmitButton from "@/components/button/SubmitButton.vue";
import endpoints from "@/endpoints";
import { sortBy, find } from "lodash";
import { pipe } from "lodash/fp";
import axios from "axios";

export default {
    components: { SubmitButton, PasswordInput },
    props: {
        profile: {
            required: true,
            type: Object,
        },
    },
    created() {
        const confirmPasswordMachine = Machine(
            {
                id: "confirm-password",
                initial: "idle",
                context: {
                    password: "",
                },
                states: {
                    idle: {
                        on: {
                            INPUT: {
                                actions: "cachePassword",
                                target: "hasInput",
                            },
                        },
                    },
                    hasInput: {
                        on: {
                            INPUT: [
                                {
                                    actions: "cachePassword",
                                    cond: "hasPasswordValue",
                                    target: "hasInput",
                                },
                                {
                                    actions: "cachePassword",
                                    target: "idle",
                                },
                            ],
                            SUBMIT: "checkingInput",
                        },
                    },
                    checkingInput: {
                        invoke: {
                            src: "checkPassword",
                            onDone: "deletingAccount",
                            onError: "incorrectPassword",
                        },
                    },
                    deletingAccount: {
                        invoke: {
                            src: "deleteAccount",
                            onDone: "deletedProfile",
                            onError: "unableToDeleteProfile",
                        },
                    },
                    unableToDeleteProfile: {},
                    deletedProfile: {
                        entry: "navigateToSuccessPage",
                    },
                    incorrectPassword: {
                        on: {
                            INPUT: [
                                {
                                    actions: "cachePassword",
                                    cond: "hasPasswordValue",
                                    target: "hasInput",
                                },
                                {
                                    actions: "cachePassword",
                                    target: "idle",
                                },
                            ],
                        },
                    },
                },
            },
            {
                services: {
                    checkPassword: async context =>
                        new Promise(async (resolve, reject) => {
                            try {
                                await ProfileService.checkPassword(
                                    this.$store.getters.account.email,
                                    context.password
                                );
                                resolve();
                            } catch (e) {
                                reject();
                            }
                        }),
                    deleteAccount: async () =>
                        new Promise(async (resolve, reject) => {
                            try {
                                await axios.delete(endpoints.account);
                                this.$auth.logout();
                                resolve();
                            } catch (e) {
                                reject(e);
                            }
                        }),
                },
                actions: {
                    cachePassword: assign({
                        password: (context, event) => event.data,
                    }),
                    navigateToSuccessPage: () => {
                        localStorage.removeItem("token");
                        this.$router.push({ name: "received-account-deletion" });
                    },
                },
                guards: {
                    hasPasswordValue: (context, event) => event.data.length,
                },
            }
        );
        this.confirmPasswordService = interpret(confirmPasswordMachine);
        this.currentConfirmPasswordState = confirmPasswordMachine.initialState;
        this.confirmPasswordService
            .onTransition(state => {
                this.currentConfirmPasswordState = state;
            })
            .start();
    },
    data() {
        return {
            confirmPasswordService: undefined,
            currentConfirmPasswordState: undefined,
        };
    },
};
</script>
