<template>
    <Modal
        :open="show"
        width="xl"
        name="autosomal-update-available-dashboard"
        title="Update available"
        icon="map"
        button-text="Go to ancestry"
        @primary-action="handleAction"
        @closed="handleClosed"
    >
        <div class="space-y-4">
            <h3 class="text-xl font-normal flex-0">Update available</h3>
            <div class="pb-2">
                Your ancestry results are better than ever! Visit the ancestry section to start your
                the latest and greatest estimates.
            </div>
            <button
                role="button"
                class="bg-cobalt-500 text-white py-2 w-full rounded font-semibold flex items-center justify-center"
                @click="handleAction"
            >
                <Feather type="map" class="h-5 w-5 fill-current mr-2" />
                <span class="text-white">Go to ancestry</span>
            </button>
            <button @click="close()" class="text-grey-600 font-semibold text-center block w-full">
                Not now
            </button>
        </div>
    </Modal>
</template>

<script>
import { ref } from "vue";
import Modal from "@/components/Modal.vue";

const show = ref(false);

export function useModal() {
    function open() {
        show.value = true;
    }
    function close() {
        show.value = false;
    }
    return { open, close };
}

export default {
    components: { Modal },
    setup() {
        const { close } = useModal();
        return { show, close };
    },
    methods: {
        handleClosed() {
            this.$store.dispatch("closeDashboardModal");
        },
        async handleAction() {
            await this.$store.dispatch("closeDashboardModal");
            this.$router.push({ path: "/ancestry/recent" });
        },
    },
};
</script>
