<template>
    <div class="bg-white">
        <div class="mx-auto max-w-7xl //p-24 //sm:px-6 //sm:py-32 //lg:px-8">
            <div
                class="relative isolate overflow-hidden bg-denim-800 px-6 pt-16 shadow-2xl //sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0"
            >
                <svg
                    viewBox="0 0 1024 1024"
                    class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                    aria-hidden="true"
                >
                    <circle
                        cx="512"
                        cy="512"
                        r="512"
                        fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                        fill-opacity="0.7"
                    />
                    <defs>
                        <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                            <stop stop-color="hsl(220, 100%, 60%)" />
                            <stop offset="1" stop-color="hsl(220, 100%, 60%)" />
                        </radialGradient>
                    </defs>
                </svg>
                <div
                    class="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left"
                >
                    <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Discover your ancestry in incredible detail
                    </h2>
                    <p class="mt-6 text-lg leading-8 text-blue-200">
                        If you're looking to get the most out of your Living DNA experience we
                        recommend you start here
                    </p>
                    <div class="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                        <router-link
                            :to="{ name: 'product', params: { slug: callOutProduct.slug } }"
                            class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >Unlock now</router-link
                        >
                        <div class="text-sm font-semibold leading-6 text-white">
                            Only {{ productPrice }}
                        </div>
                    </div>
                </div>
                <div class="relative mt-16 h-80 lg:mt-8">
                    <img
                        class="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10 object-center"
                        src="/vue-public/autosomal-map-2.png"
                        alt="App screenshot"
                        width="1824"
                        height="1080"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { convertToCurrency } from "@/services/store";

const props = defineProps({
    callOutProduct: Object,
});

const productPrice = computed(() => {
    return convertToCurrency(props.callOutProduct.price.base / 100, props.callOutProduct.currency);
});
</script>
