import { createRouter, createWebHistory } from "vue-router";
import { sortBy, find } from "lodash";
import { pipe } from "lodash/fp";
import $store from "@/store/store";
import upload from "@/router/upload";
import activation from "@/router/activation";
import account from "@/router/account";
import base from "@/router/default";
import ancestry from "@/router/ancestry";
import store from "@/router/store";
import dashboard from "@/router/dashboard";
import profiles from "@/router/profiles";
import vikings from "@/router/vikings";
import archaic from "@/router/archaic";
import classical from "@/router/classical";
import PageNotFound from "@/views/error/PageNotFound.vue";
import Research from "@/views/profiles/ProfileEdit/details/Research.vue";
import Download from "@/views/results/download/Download.vue";
import Portal from "@/components/partials/Portal.vue";
import wellbeing from "./wellbeing";
import familyMatching from "@/router/family-matching";
import SurveyCentral from "@/views/SurveyCentral.vue";
import {
    setPartnerReferral,
    hasPartnerReferral,
    sendUploadReferralToServer,
    getPartnerReferral,
} from "@/services/uploadReferral";
import sharing from "@/router/sharing";

window.dataLayer = window.dataLayer || [];

// const VueProgressBarOptions = {
//     color: "#d40b55",
//     failedColor: "#173775",
//     thickness: "2px",
//     transition: {
//         speed: "0.2s",
//         opacity: "0.5s",
//     },
//     autoRevert: true,
//     location: "top",
//     inverse: false,
// };

// Vue.use(VueProgressBar, VueProgressBarOptions);
// Vue.use(VueResourceProgressBarInterceptor);

const routes = [].concat(
    upload,
    activation,
    base,
    sharing,
    {
        path: "",
        name: "Portal",
        component: Portal,
        meta: { auth: true },
        redirect: "/dashboard",
        children: [
            ...wellbeing,
            ...dashboard,
            ...account,
            ...ancestry,
            ...store,
            ...profiles,
            ...familyMatching,
            ...vikings,
            ...archaic,
            ...classical,
            {
                path: "/research/:barcode",
                name: "research",
                component: Research,
                meta: { auth: true },
            },
            {
                path: "/download/:barcode",
                name: "download",
                component: Download,
                meta: { auth: true },
            },
        ],
        beforeEnter: async (to, from, next) => {
            await $store.dispatch("SET_ACCOUNT_TESTS");

            const profileList = await $store.dispatch("getProfiles");

            if (!profileList.length > 0) {
                return next({ name: "activate" });
            }

            if (to.query.hasOwnProperty("goTo")) {
                const first = profileList.find((p) => p.profile.barcode === to.query.goTo);
                await $store.dispatch("setDefaultProfile", first.permission.id);
            } else if (localStorage.getItem("EXTERNAL_PROFILE_REQUESTED")) {
                const first = profileList.find(
                    (p) => p.profile.barcode === localStorage.getItem("EXTERNAL_PROFILE_REQUESTED")
                );
                await $store.dispatch("setDefaultProfile", first.permission.id);
                localStorage.removeItem("EXTERNAL_PROFILE_REQUESTED");
            } else if (!profileList.find((p) => p.permission.isDefault)) {
                if (profileList.length > 1) {
                    const [first] = pipe(
                        (prof) => sortBy(prof, ["profile.barcode"]),
                        (prof) =>
                            sortBy(
                                prof,
                                ({ profile }) =>
                                    find(
                                        $store.getters.accountTests,

                                        ({ barcode }) => barcode === profile.barcode
                                    ).status !== "complete"
                            )
                    )(profileList);
                    await $store.dispatch("setDefaultProfile", first.permission.id);
                } else {
                    const [first] = profileList;

                    await $store.dispatch("setDefaultProfile", first.permission.id);
                }
            }

            const profile = await $store.dispatch("getDefaultProfile");
            await $store.dispatch("SET_TEST_FROM_TESTS", profile.barcode);
            await $store.dispatch("GET_TEST", profile.barcode);
            await $store.dispatch("SET_AUTOSOMAL_STATUS", {
                status: $store.getters.selectedTest.status,
            });
            await $store.dispatch("SET_KIT_TESTS", profile.barcode);
            await $store.dispatch("getAccountData");

            window.dataLayer.push({
                barcode: profile.barcode,
            });

            next();
        },
    },
    {
        path: "/survey-central",
        component: SurveyCentral,
    },
    {
        path: "/:pathMatch(.*)*",
        component: PageNotFound,
    }
);

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    // scrollBehavior(to) {
    //     if (to.meta.scrollTopTo || typeof to.meta.scrollTopTo === "number") {
    //         setTimeout(() => {
    //             // document.querySelector(".scroll-container").scrollTop = to.meta.scrollTopTo;
    //         }, 500);
    //     }
    // },
    scrollBehavior(to) {
        const el = document.querySelector("#main");
        if (el && !to.meta.dontScroll) {
            el.scrollTop = 0;
        }
    },
    routes,
});

router.beforeEach(async (to, from, next) => {
    window.dataLayer.push({
        barcode: $store.getters.profile.barcode,
    });

    if (to.query.upload_referral) {
        setPartnerReferral(to.query.upload_referral, to.query.type);
    }

    if (hasPartnerReferral()) {
        try {
            if (localStorage.getItem("token")) {
                await $store.dispatch("getDefaultProfile");
                sendUploadReferralToServer($store.getters.profile.barcode, getPartnerReferral());
            }
        } catch (e) {
            // empty catch because there's no useful error to display
            // Typically we get here because the token is expired. We could try refreshing the
            // token but that's going to happen anyway at some point, so I going to prefer to catch
            // the Exception silently and then keep making the http request again, eventually the
            // token is going to have been refreshed.
        }
    }

    if (to.meta.referrer) {
        if (to.query.referrer || $store.getters.referrer) {
            if (to.query.referrer) {
                $store.dispatch("setReferrer", to.query.referrer);
            }
        }
    }

    next();
});

export { router };
