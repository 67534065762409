<template>
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 480 480"
        style="enable-background:new 0 0 480 480;"
        xml:space="preserve"
    >
        <g>
            <g>
                <path
                    d="M312,296.008v8c0,39.764-32.235,72-72,72c-39.764,0-72-32.236-72-72v-8H0v16h152.36c4.191,45.287,42.159,79.946,87.64,80
			c45.481-0.054,83.449-34.712,87.64-80H480v-16H312z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M295.864,57.48c0.661-3.495-1.065-7.006-4.235-8.618c-3.939-2.002-8.755-0.433-10.757,3.506
			c-3.272,6.36-79.704,156.44-93.92,226.16c-1.951,8.033-2.942,16.269-2.952,24.536v0.944c0.04,30.911,25.089,55.96,56,56
			c30.911-0.04,55.96-25.089,56-56c-1.794-19.2-4.883-38.257-9.248-57.04C273.304,179.768,295.632,58.696,295.864,57.48z
			 M240,344.008c-22.091,0-40-17.909-40-40v-1.032c0.023-7.159,0.904-14.29,2.624-21.24c8.992-44.16,45.424-124.824,69.792-175.832
			c-5.216,42.448-9.752,102.248-1.352,144.208c4.206,17.74,7.192,35.748,8.936,53.896C280,326.099,262.091,344.008,240,344.008z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M120,328.008c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S133.255,328.008,120,328.008z M120,360.008
			c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S124.418,360.008,120,360.008z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M48,344.008c-17.673,0-32,14.327-32,32c0,17.673,14.327,32,32,32s32-14.327,32-32C80,358.335,65.673,344.008,48,344.008z
			 M48,392.008c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16S56.837,392.008,48,392.008z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M432,344.008c-17.673,0-32,14.327-32,32c0,17.673,14.327,32,32,32c17.673,0,32-14.327,32-32
			C464,358.335,449.673,344.008,432,344.008z M432,392.008c-8.837,0-16-7.163-16-16s7.163-16,16-16s16,7.163,16,16
			S440.837,392.008,432,392.008z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M360,328.008c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S373.255,328.008,360,328.008z M360,360.008
			c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S364.418,360.008,360,360.008z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M168,384.008c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S181.255,384.008,168,384.008z M168,416.008
			c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S172.418,416.008,168,416.008z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M312,384.008c-13.255,0-24,10.745-24,24s10.745,24,24,24s24-10.745,24-24S325.255,384.008,312,384.008z M312,416.008
			c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S316.418,416.008,312,416.008z"
                />
            </g>
        </g>
    </svg>
</template>
