<template>
    <ThePage class="max-w-6xl">
        <family-matching-degree-description
            :degree="selectedDegree"
        ></family-matching-degree-description>
        <h1 class="text-2xl font-normal mt-4 mb-6">
            <Feather type="users" class="h-5 w-5 mr-2" />
            {{ $store.getters.firstName }}'s DNA relatives
        </h1>
        <div v-if="currentRelativesState.matches('haveNoMatches')">
            <NoMatches />
        </div>
        <div v-else-if="currentRelativesState.matches('loading')" class="text-center">
            <Space height="8" />
            <div class="loading-large" />
            <Space height="4" />
            Loading...
        </div>
        <template v-else-if="currentRelativesState.matches('error')">
            <div class="rounded-md bg-rose-50 p-4">
                <div class="flex items-center">
                    <Feather
                        type="x-circle"
                        class="h-5 w-5 text-rose-500 flex-shrink-0"
                        aria-hidden="true"
                    />
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-rose-500">
                            There was an issue loading your matches. You can try reloading, if the
                            issue persists please contact support
                        </h3>
                    </div>
                </div>
            </div>
            <button
                @click="relativesService.send('LOAD')"
                class="py-2 px-4 mt-6 rounded flex items-center justify-center bg-cobalt-500 hover:bg-cobalt-700 text-white font-semibold"
            >
                Reload
            </button>
        </template>
        <template v-else>
            <div>
                <Filtering
                    :initial-sort-by="currentRelativesState.context.sortBy"
                    :initial-search-query="currentRelativesState.context.queryText"
                    @searchQueryUpdated="setSearchQuery"
                    @sortByUpdated="setSortBy"
                    @perPageUpdated="setPerPage"
                />
                <div v-if="currentRelativesState.matches('searching')" class="text-center">
                    <Space height="8" />
                    <div class="loading-large" />
                    <Space height="4" />
                    Searching...
                </div>
                <template v-if="currentRelativesState.matches('emptySearch')">
                    <Space height="8" />
                    Nothing matched your search criteria
                </template>
                <template v-if="currentRelativesState.matches('showingGroupedMatches')">
                    <div v-for="(relatives, degree) in groupedMatchList" :key="degree">
                        <Space height="6" />
                        <RelativesListSection
                            :degree="Number(degree)"
                            :relatives="relatives"
                            @degreeSelected="setSelectedDegree($event)"
                        />
                    </div>
                    <Space height="6" />
                    <div class="flex justify-center">
                        <PaginateList
                            :list-count="currentRelativesState.context.matchCount"
                            :current-page="currentRelativesState.context.currentPage"
                            :items-per-page="currentRelativesState.context.itemsPerPage"
                            @page-changed="relativesService.send('PAGE_CHANGED', { data: $event })"
                        />
                    </div>
                </template>
                <template v-else-if="currentRelativesState.matches('showingUngroupedMatches')">
                    <Space height="6" />
                    <div class="bg-white rounded shadow-key">
                        <RelativesListItem
                            v-for="relative in currentRelativesState.context.matchList"
                            :key="relative.id"
                            :relative="relative"
                            :hasMessage="relative.unreadMessage"
                            :class="{
                                'border-b':
                                    index !== currentRelativesState.context.matchList.length - 1,
                            }"
                        />
                    </div>
                    <Space height="6" />
                    <div class="flex justify-center">
                        <PaginateList
                            :list-count="currentRelativesState.context.matchCount"
                            :current-page="currentRelativesState.context.currentPage"
                            :items-per-page="currentRelativesState.context.itemsPerPage"
                            @page-changed="relativesService.send('PAGE_CHANGED', { data: $event })"
                        />
                    </div>
                </template>
            </div>
        </template>
    </ThePage>
</template>

<script>
import { Machine, interpret, assign, State } from "xstate";
import { getMatches, getNotifications } from "@/services/family-matching";
import { groupBy, find, sortBy } from "lodash";
import ThePage from "@/components/Base/ThePage.vue";
import Filtering from "@/views/FamilyNetworks/FamilyMatching/ListView/Filtering.vue";
import RelativesListSection from "@/views/FamilyNetworks/FamilyMatching/ListView/RelativesListSection.vue";
import RelativesListItem from "@/views/FamilyNetworks/FamilyMatching/ListView/RelativesListItem.vue";
import PaginateList from "@/views/FamilyNetworks/FamilyMatching/ListView/PaginateList.vue";
import NoMatches from "@/views/FamilyNetworks/FamilyMatching/ListView/NoMatches.vue";
import { Base64 } from "js-base64";
import FamilyMatchingDegreeDescription from "@/components/modals/FamilyMatchingDegreeDescription.vue";
import { useMatchGroupStore } from "@/store/matchGroups";

const listFilterActions = {
    SORT_BY_NAME: {
        actions: assign({ sortBy: "name", currentPage: 1 }),
        target: "searching",
    },
    SORT_BY_DEGREE: {
        actions: assign({ sortBy: "closestMatch", currentPage: 1 }),
        target: "searching",
    },
    SORT_BY_DATE_ADDED: {
        actions: assign({ sortBy: "dateAdded", currentPage: 1 }),
        target: "searching",
    },
    SET_PER_PAGE: {
        actions: assign({
            itemsPerPage: (context, event) => {
                return event.data;
            },
            currentPage: 1,
        }),
        target: "searching",
    },
    FILTER_BY_SHARED_MAP: {
        actions: assign({ sortBy: "sharedMaps", currentPage: 1 }),
        target: "searching",
    },
    QUERY_TEXT_CHANGED: {
        cond: (context, event) => event.data.length !== 1,
        actions: assign({ queryText: (context, event) => event.data, currentPage: 1 }),
        target: "searching",
    },
    PAGE_CHANGED: {
        actions: assign({
            currentPage: (context, event) => event.data,
        }),
        target: "searching",
    },
};

export default {
    components: {
        PaginateList,
        RelativesListItem,
        RelativesListSection,
        ThePage,
        Filtering,
        NoMatches,
        FamilyMatchingDegreeDescription,
    },
    setup() {
        const matchGroupStore = useMatchGroupStore();

        matchGroupStore.getMatchGroups();
    },
    created() {
        const relativesMachine = Machine(
            {
                id: "relatives-machine",
                initial: "loading",
                context: {
                    profile: this.$store.getters.profile,
                    matchList: [],
                    matchCount: undefined,
                    sortBy: this.$route.query.sortBy ? this.$route.query.sortBy : "closestMatch",
                    queryText: "",
                    currentPage: 1,
                    itemsPerPage: 10,
                },
                states: {
                    loading: {
                        invoke: {
                            src: "getMatches",
                            onDone: [
                                {
                                    cond: "noMatches",
                                    target: "haveNoMatches",
                                },
                                {
                                    cond: "shouldGroupMatches",
                                    actions: "cacheMatches",
                                    target: "showingGroupedMatches",
                                },
                                {
                                    actions: "cacheMatches",
                                    target: "showingUngroupedMatches",
                                },
                            ],
                            onError: {
                                actions: (ctx, event) => console.error({ event, data: event.data }),
                                target: "error",
                            },
                        },
                        on: listFilterActions,
                    },
                    error: {
                        on: {
                            LOAD: "loading",
                        },
                    },
                    searching: {
                        invoke: {
                            src: "getMatches",
                            onDone: [
                                {
                                    cond: "noMatches",
                                    target: "emptySearch",
                                },
                                {
                                    cond: "shouldGroupMatches",
                                    actions: "cacheMatches",
                                    target: "showingGroupedMatches",
                                },
                                {
                                    actions: "cacheMatches",
                                    target: "showingUngroupedMatches",
                                },
                            ],
                        },
                        on: listFilterActions,
                    },
                    showingGroupedMatches: {
                        on: listFilterActions,
                    },
                    showingUngroupedMatches: {
                        on: listFilterActions,
                    },
                    haveNoMatches: {
                        on: listFilterActions,
                    },
                    failedToLoad: {},
                    emptySearch: {
                        on: listFilterActions,
                    },
                },
            },
            {
                services: {
                    getMatches: (context) =>
                        new Promise(async (resolve, reject) => {
                            try {
                                // get the matches
                                const result = await getMatches(context.profile.barcode, {
                                    sortBy: context.sortBy,
                                    query: context.queryText,
                                    page: context.currentPage,
                                    itemsPerPage: context.itemsPerPage,
                                });
                                // get the notifications
                                const notifications = await getNotifications();
                                //
                                result.matches = result.matches.map((match) => {
                                    const sorted = sortBy([context.profile.barcode, match.barcode]);
                                    // channel hash
                                    const channelHash = `private-${Base64.encode(
                                        sorted.join(",")
                                    )}`;
                                    // find notification for this channel hash
                                    const found = find(
                                        notifications,
                                        (n) => n.channel === channelHash
                                    );
                                    // return with any found notification
                                    return {
                                        ...match,
                                        unreadMessage: found !== undefined,
                                        channelHash: channelHash,
                                    };
                                });
                                resolve(result);
                            } catch (e) {
                                console.log(e);
                                reject(new Error(e));
                            }
                        }),
                },
                guards: {
                    noMatches: (context, event) => event.data.total === 0,
                    shouldGroupMatches: (context) => context.sortBy === "closestMatch",
                },
                actions: {
                    cacheMatches: assign({
                        matchList: (context, event) => event.data.matches,
                        matchCount: (context, event) => event.data.total,
                    }),
                    navigateToMatchesView: () => {
                        this.$router.push({ name: "list-view" });
                    },
                },
            }
        );
        this.relativesService = interpret(relativesMachine);
        this.currentRelativesState = relativesMachine.initialState;
        if (this.$route.query.loadPrevious && localStorage.getItem("matchListState")) {
            const previousState = State.create(JSON.parse(localStorage.getItem("matchListState")));
            const resolvedState = relativesMachine.resolveState(previousState);
            this.relativesService.start(resolvedState);
        } else {
            this.relativesService.start();
        }
        this.relativesService.onTransition((state) => {
            this.currentRelativesState = state;
            localStorage.setItem("matchListState", JSON.stringify(state));
        });
    },
    computed: {
        groupedMatchList() {
            return groupBy(this.currentRelativesState.context.matchList, "degree");
        },
    },
    data() {
        return {
            loadMachineFromCache: this.$route.query.loadPrevious === "true",
            relativesService: undefined,
            currentRelativesState: undefined,
            selectedDegree: undefined,
        };
    },
    methods: {
        setSortBy(value) {
            console.log("called");
            const action = {
                closestMatch: "SORT_BY_DEGREE",
                name: "SORT_BY_NAME",
                dateAdded: "SORT_BY_DATE_ADDED",
                sharedMaps: "FILTER_BY_SHARED_MAP",
            }[value];
            this.relativesService.send(action);
        },
        setPerPage(value) {
            console.log(value);
            this.relativesService.send("SET_PER_PAGE", { data: value });
        },
        setSearchQuery(value) {
            console.log(this.currentRelativesState);
            this.relativesService.send("QUERY_TEXT_CHANGED", { data: value });
        },
        setSelectedDegree(degree) {
            this.selectedDegree = degree;
        },
    },
};
</script>
