import Vue from "vue";
import axios from "axios";
import endpoints from "@/endpoints";
import moment from "moment";
import { keyBy } from "lodash";

class MyTests {
    static async getTests() {
        // await axios.get(`${endpoints.tests}`).then(res => console.log(res));
        const {
            data: { caseRecords },
        } = await axios.get(`${endpoints.tests}`);
        console.log(caseRecords.ancestry);
        return caseRecords;
    }

    static async getTest(barcode) {
        const { data } = await axios.get(`${endpoints.tests}/${barcode}`);
        return data;
    }

    static async getKitTests(barcode, kitStatus) {
        const { data } = await axios.get(`${endpoints.kitTests}/${barcode}`);

        // const data = JSON.parse('[{"name":"Autosomal","dnaTestId":1,"type":"autosomal","status":"pending","updateAvailable":null,"version":"1.0.0","resultStatus":""},{"name":"Mitochondrial DNA","dnaTestId":2,"type":"mtdna","status":"pending","updateAvailable":null,"version":"1.0.0","resultStatus":""},{"name":"Y-DNA","dnaTestId":3,"type":"ydna","status":"pending","updateAvailable":null,"version":"1.0.0","resultStatus":""},{"name":"Strength","dnaTestId":9,"type":"strength","status":"pending","updateAvailable":"","version":"1.0.0","resultStatus":"not_viewed"},{"name":"Vitamin D","dnaTestId":21,"type":"vitaminD","status":"pending","updateAvailable":"","version":"1.0.0","resultStatus":"not_viewed"}]');

        const testsData = await axios.get(`${endpoints.testsList}`);

        const tests = Object.keys(testsData.data);

        const ancestryTests = [
            "mtdna",
            "ydna",
            "autosomalStarter",
            "autosomal",
            "familyMatching",
            "archaic",
            "viking",
        ];

        const modifiedTests = tests.map((t) => {
            // find the test in data array
            const test = data.find((item) => item.type === t);
            if (test) {
                return {
                    ...test,
                    testName: testsData.data[t],
                    purchased: true,
                    messageSlug: `tests.${test.type}.${this.createDataMock(
                        test.status,
                        kitStatus
                    )}`,
                    slug: this.createDataMock(test.status, kitStatus),
                    complete: test.status === "completed",
                    group: ancestryTests.includes(test.type) ? "ancestry" : "wellbeing",
                };
            }
            return {
                type: t,
                testName: testsData.data[t],
                purchased: false,
                complete: false,
                messageSlug: `tests.${t}.no_data`,
                slug: "no_data",
                group: ancestryTests.includes(t) ? "ancestry" : "wellbeing",
            };
        });
        const allTests = keyBy(modifiedTests, "type");
        const wellbeingStarterTests = ["strength", "vitaminD"];
        const usersWellbeingStarterTests = modifiedTests
            .filter((test) => wellbeingStarterTests.includes(test.type))
            .filter((test) => test.purchased);
        const completedWellbeingStarterTests = usersWellbeingStarterTests.filter(
            (test) => test.complete
        );
        const hasPurchasedWellbeingStarter = usersWellbeingStarterTests.length === 2;
        const hasCompletedWellbeingStarter = completedWellbeingStarterTests.length === 2;
        const wellbeingStarter = {
            type: "wellbeingStarter",
            testName: "Wellbeing Starter",
            purchased: hasPurchasedWellbeingStarter,
            complete: hasCompletedWellbeingStarter,
            group: "wellbeing",
        };
        Object.assign(allTests, { wellbeingStarter });
        return allTests;
    }

    static createDataMock(status, kitStatus) {
        if (status === "processing") {
            return "testing";
        }
        if (status === "failed") {
            if (kitStatus !== "completed") {
                return kitStatus;
            }
            return "failed";
        }
        if (status === "pending") {
            if (kitStatus !== "completed") {
                return kitStatus;
            }
            return "testing";
        }
        if (status === "completed") {
            return "completed";
        }
        return "no_data";
    }
}

export const upgradeTest = async (barcode, test) => {
    try {
        await axios.post(endpoints.upgradeTest(barcode, test));
    } catch (e) {
        throw Error(e);
    }
};

export default MyTests;

export const dateFormat = (resultDate) => moment(resultDate).format("Do MMMM YYYY");
