import { interpret, Machine } from "xstate";
import axios from "axios";
import store from "../../../store/store";

const optInMachine = new Machine(
    {
        id: "family-matching-opt-in-machine",
        initial: "determiningStatus",
        context: {},
        states: {
            determiningStatus: {
                invoke: {
                    src: "getOptInStatus",
                    onDone: "optedIn",
                    onError: "notOptedIn",
                },
            },
            optedIn: {
                id: "optedIn",
                on: {
                    PROFILE_CHANGED: "determiningStatus",
                },
                initial: "checkingRunStatus",
                states: {
                    checkingRunStatus: {
                        invoke: {
                            src: "getRunStatus",
                            onDone: "hasBeenRun",
                            onError: "hasNotBeenRun",
                        },
                    },
                    hasBeenRun: {},
                    hasNotBeenRun: {},
                },
            },
            notOptedIn: {
                id: "notOptedIn",
                on: {
                    PROFILE_CHANGED: "determiningStatus",
                    GET_STARTED: "settingDisplayName",
                },
            },
            settingDisplayName: {
                id: "settingDisplayName",
                on: {
                    PROFILE_CHANGED: "determiningStatus",
                },
                initial: "idle",
                states: {
                    idle: {
                        on: {
                            DISPLAY_NAME_SET: "termsAccepted",
                            BACK: "termsNotAccepted",
                        },
                    },
                    termsNotAccepted: {},
                    termsAccepted: {
                        on: { "": "#optedIn" },
                    },
                },
            },
            errorDetected: {
                id: "errorDetected",
                on: {
                    PROFILE_CHANGED: "determiningStatus",
                },
            },
        },
    },
    {
        actions: {},
        services: {
            getOptInStatus: () =>
                new Promise(async (resolve, reject) => {
                    if (store.getters.profile.barcode) {
                        await store.dispatch("getOnboardingStatus", {
                            barcode: store.getters.profile.barcode,
                            http: axios,
                        });
                        if (store.getters.onboardingStatus.familyNetworks) {
                            resolve();
                        } else {
                            reject();
                        }
                    } else {
                        reject();
                    }
                }),
            getRunStatus: () =>
                new Promise((resolve, reject) => {
                    const { familyMatching } = store.getters.kitTests;
                    if (familyMatching) {
                        familyMatching.status === "completed" ? resolve() : reject();
                    } else {
                        reject();
                    }
                }),
        },
    }
);

const optInService = interpret(optInMachine);
const optIntCurrent = optInMachine.initialState;
const optInContext = optInMachine.context;

export { optInMachine, optInService, optIntCurrent, optInContext };
