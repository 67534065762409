<template>
    <div>
        <div class="relative overflow-hidden" style="background-color: #eef5ff">
            <ScienceIconLeft class="absolute bottom-0 left-0 -mb-16" />
            <ScienceIconRight class="absolute bottom-0 right-0 -mb-16" />
            <div class="pt-12 pb-24 container px-12 items-center mx-auto">
                <router-link
                    :to="{ name: 'wellbeing-report', params: { report: traitData.slug } }"
                    class="flex items-center"
                >
                    <feather type="chevron-left" class="h-5 w-5 fill-current mr-1" />
                    <div class="font-semibold">Report overview</div>
                </router-link>
                <h1 class="text-2xl mt-12 text-center font-normal">{{ traitData.title }}</h1>
            </div>
        </div>
        <div class="relative max-w-lg mx-auto px-4">
            <div class="bg-white shadow-key rounded" style="margin-top: -53px">
                <div class="px-6 py-4 flex text-grey-500 text-sm justify-center">
                    <div class="w-1/3 text-center">Gene</div>
                    <div class="w-1/3 text-center">RSID</div>
                    <div class="w-1/3 text-center">Allele</div>
                </div>
                <div class="px-6 bg-grey-50 pt-6 rounded-b">
                    <div :key="snp.genotype" class="flex pb-6" v-for="snp in scienceData">
                        <div class="w-1/3 text-center">{{ snp.gene }}</div>
                        <div class="w-1/3 text-center">{{ snp.name }}</div>
                        <div class="w-1/3 text-center">{{ snp.genotype }}</div>
                    </div>
                </div>
            </div>
            <div class="mt-12">
                <h2 class="text-lg font-semibold mb-6">{{ traitData.heading }}</h2>
                <div v-if="hasSnps" class="flex justify-between mb-12 -mx-3">
                    <div
                        v-for="phenotype in traitData.phenotypes"
                        class="block flex-1 text-center mx-3"
                    >
                        <ScaleMapper
                            :level="Number(phenotype.scalePosition.value)"
                            v-slot="{ primaryColour, secondaryColour, result }"
                        >
                            <span
                                class="bg-grey-200 py-2 px-4 rounded block font-semibold text-grey-500"
                                :style="{
                                    backgroundColor:
                                        phenotype.slug === phenotypeData.slug
                                            ? secondaryColour
                                            : '',
                                    color:
                                        phenotype.slug === phenotypeData.slug ? primaryColour : '',
                                }"
                                >{{ result }}</span
                            >
                        </ScaleMapper>
                    </div>
                </div>
                <div v-html="traitData.scienceOfIntroduction"></div>
                <div v-if="hasSnps" v-html="phenotypeData.science"></div>
                <h3 class="text-lg mt-8">Population distribution</h3>
                <div v-html="traitData.populationDistribution"></div>
                <h3 class="text-sm mt-8">References</h3>
                <ul class="list-reset text-xs mt-2 mb-6 pb-12">
                    <li v-for="reference in traitData.references" class="mt-2">
                        {{ reference.sourceName }}
                        {{ reference.sourceMediumName }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ScaleMapper from "@/components/Wellbeing/ScaleMapper.vue";
import ScienceIconLeft from "@/components/Wellbeing/ScienceIconLeft.vue";
import ScienceIconRight from "@/components/Wellbeing/ScienceIconRight.vue";
import endpoints from "@/endpoints";

export default {
    components: { ScaleMapper, ScienceIconLeft, ScienceIconRight },
    data() {
        return {
            traitData: {},
            phenotypeData: {},
            scienceData: {},
            hasSnps: {},
        };
    },
    created() {
        axios
            .get(
                endpoints.wellbeingTrait(
                    this.$store.getters.profile.barcode,
                    this.$route.params.report
                )
            )
            .then((res) => {
                this.phenotypeData = JSON.parse(res.data.phenotypeCmsData);
                this.traitData = JSON.parse(res.data.traitCmsData);
                this.scienceData = res.data.snps;
                this.hasSnps = res.data.totalPhenotypeSlug !== "snpsNotFound";
            });
    },
};
</script>
