<template>
    <Overview>
        <template #sidebar>
            <div class="fade-bottom" style="width: 511px; background: #fff">
                <img :src="introduction.main_image.url" alt="" class="fade-bottom" />
            </div>
            <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                <h1 class="text-xl">{{ introduction.heading }}</h1>
                <div v-html="introduction.text" />
            </div>
        </template>
        <template #desktopContent>
            <PageLoader v-if="loadingStatus === 'loading'" />
            <div v-else class="space-y-6">
                <VikingsOverviewCard
                    :link-to="{ name: 'egyptian-result' }"
                    :title-text="resultsCard.heading"
                    :description-text="resultsCard.text"
                    link-text="Discover your result"
                    :image="{ src: resultsCard.main_image.url }"
                />
                <VikingsOverviewCard
                    :link-to="{ name: 'egyptian-map' }"
                    :title-text="mapCard.heading"
                    :description-text="mapCard.text"
                    link-text="Discover your result"
                    :image="{ src: mapCard.main_image.url }"
                />
            </div>
        </template>
        <template #mobileContent>
            <div class="fade-bottom">
                <img :src="introduction.main_image.url" alt="" class="fade-bottom" />
            </div>
            <div class="py-6 px-4 sm:px-6 lg:px-8 prose mx-auto">
                <h1 class="text-xl">{{ introduction.heading }}</h1>
                <div v-html="introduction.text" />
            </div>
        </template>
    </Overview>
</template>
<script setup>
import Overview from "@/components/Overview.vue";
import { getRequiredContent } from "@/composables/useClassicalContent";
import { inject, ref } from "vue";
import { useLoading } from "@/composables/useLoading";
import VikingsOverviewCard from "@/views/vikings/VikingsOverviewCard.vue";

const props = defineProps({
    introduction: Object,
});

const { loadingStatus, finishLoading } = useLoading();
const resultsCard = ref({});

const mapCard = inject("map-card");

getRequiredContent(["egypt-results-card"])
    .then(([resResultsCard]) => {
        resultsCard.value = resResultsCard;
    })
    .then(finishLoading);
</script>
