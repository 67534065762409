<template>
    <ThePage class="max-w-6xl">
        <h1 class="text-2xl font-normal mt-4 mb-6">
            <Feather type="users" class="h-5 w-5 mr-2" />
            {{ $store.getters.firstName }}'s Matchbox
        </h1>
        <template v-if="sortedMatches.length">
            <div v-for="group in sortedMatches" :key="group.name">
                <AppDisclosure as="div">
                    <template v-slot:title="{ open }">
                        <div
                            class="bg-grey-100 p-6 w-full text-left rounded flex items-center justify-between mt-4"
                        >
                            <h1 class="flex items-center">
                                <div
                                    :style="{ backgroundColor: group.colour }"
                                    class="h-4 w-4 rounded-full mr-4"
                                />
                                {{ group.name }} ({{ group.members.length }})
                            </h1>
                            <feather-wrapper :icon="`chevron-${open ? 'up' : 'down'}`" />
                        </div>
                    </template>
                    <template #body>
                        <div class="mt-4 px-2 flex items-center gap-6">
                            <button
                                @click="toggleEditModal(group)"
                                class="flex gap-2 items-center text-sm text-cobalt-500"
                            >
                                <FeatherWrapper icon="edit" />
                                <span>Edit this group</span>
                            </button>
                            <button
                                @click="toggleDeletionModal(group)"
                                class="flex gap-2 items-center text-sm text-rose-500"
                            >
                                <FeatherWrapper icon="trash" />
                                <span>Delete this group</span>
                            </button>
                        </div>
                        <!-- <div v-for="member in group.membersAsMatches"> -->
                        <div class="bg-white rounded shadow-key mt-4">
                            <template v-if="group.membersAsMatches.length">
                                <RelativesListItem
                                    v-for="(member, index) in group.membersAsMatches"
                                    :key="member.id"
                                    :relative="member"
                                    :hasMessage="member.unreadMessage"
                                    :class="{
                                        'border-b': index !== group.membersAsMatches.length - 1,
                                    }"
                                />
                            </template>
                            <template v-else>
                                <div class="p-6">
                                    You haven't added any members to this group yet. Add members to
                                    a group from your relatives list
                                </div>
                            </template>
                        </div>
                    </template>
                </AppDisclosure>
            </div>
            <button
                @click="showCreateModal = true"
                class="font-semibold text-cobalt-500 flex items-center gap-1 text-sm mt-4"
            >
                Add new group
                <FeatherWrapper icon="plus" class="text-cobalt-500" />
            </button>
        </template>
        <template v-else>
            <div class="text-center">
                <Feather type="archive" class="h-10 w-10 text-gray-500" />
                <h3 class="mt-2 text-lg font-semibold text-gray-900">
                    Get started by creating your first group here
                </h3>
                <p class="mt-3 text-gray-500 max-w-lg m-auto">
                    You can add and remove matches at any time, they will still appear in your main
                    match list. Add a colour to make each group stand out.
                </p>
                <div
                    class="mt-6 max-w-lg m-auto bg-cobalt-50 text-cobalt-500 p-3 flex gap-3 text-left rounded"
                >
                    <FeatherWrapper icon="info" />
                    <p>
                        We find the best place to start is with a specific region or family
                        line/surname.
                    </p>
                </div>
                <div class="mt-6">
                    <button
                        @click="showCreateModal = true"
                        type="button"
                        class="inline-flex items-center rounded-md bg-cobalt-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        <FeatherWrapper icon="plus" />
                        New Group
                    </button>
                </div>
            </div>
        </template>
    </ThePage>
    <Modal :open="showCreateModal" width="3xl" @closed="showCreateModal = false">
        <form @submit.prevent="createGroup" class="flex flex-col gap-4">
            <h3 class="text-xl font-semibold">Add a new match group</h3>
            <TextInput required label="Name" v-model="newGroupDetails.name" />
            <div>
                <span class="font-semibold block w-full" style="font-size: 14px">
                    Choose colour
                </span>
                <MatchGroupColourPicker class="mt-1" v-model="newGroupDetails.colour" />
            </div>

            <button
                class="bg-cobalt-500 text-white rounded w-full py-2 hover:bg-cobalt-700"
                icon="plus"
                :class="{ loading: loadingStatus === 'creating' }"
            >
                Add group
            </button>
        </form>
    </Modal>
    <Modal :open="showEditModal" width="3xl" @closed="showEditModal = false">
        <form @submit.prevent="editGroup" class="flex flex-col gap-4 h-auto">
            <h3 class="text-xl font-semibold">Edit group</h3>
            <TextInput label="Name" v-model="editGroupDetails.name" />
            <div>
                <span class="font-semibold block w-full" style="font-size: 14px">
                    Choose colour
                </span>
                <MatchGroupColourPicker class="mt-1" v-model="editGroupDetails.colour" />
            </div>

            <button
                class="bg-cobalt-500 text-white rounded w-full py-2 hover:bg-cobalt-700"
                :class="{ loading: loadingStatus === 'editing' }"
                icon="plus"
            >
                Edit group
            </button>
        </form>
    </Modal>
    <Modal
        width="xl"
        :open="showDeletionModal"
        remove-padding
        @closed="showDeletionModal = false"
        close-icon-color="#e5e7eb"
    >
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
                <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                    <svg
                        class="h-6 w-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                        />
                    </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">
                        Delete Group
                    </h3>
                    <div class="mt-2">
                        <p class="text-gray-500 py-4">
                            Are you sure you want to delete this group? This action cannot be undone
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button
                @click="deleteGroup()"
                type="button"
                class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                :class="{ loading: loadingStatus === 'deleting' }"
            >
                Delete Group
            </button>
            <button
                type="button"
                @click="showDeletionModal = false"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            >
                Back
            </button>
        </div>
    </Modal>
</template>

<script setup>
import { ref, computed } from "vue";
import ThePage from "@/components/Base/ThePage.vue";
import AppDisclosure from "@/components/AppDiscloser.vue";
import { useMatchGroupStore } from "@/store/matchGroups";
import RelativesListItem from "../ListView/RelativesListItem.vue";
import Modal from "@/components/Modal.vue";
import TextInput from "@/components/inputs/TextInput.vue";
import MatchGroupColourPicker from "@/components/MatchGroupColourPicker.vue";

const matchGroupStore = useMatchGroupStore();
const showCreateModal = ref(false);
const showEditModal = ref(false);
const showDeletionModal = ref(false);
const loadingStatus = ref("idle");
const newGroupDetails = ref({
    name: "",
    colour: "#fdba74",
});
const editGroupDetails = ref({
    id: undefined,
    name: "",
    colour: "",
});

const deleteGroupDetails = ref({});

matchGroupStore.getMatchGroups();

const sortedMatches = computed(() => {
    return matchGroupStore.matchGroups.sort((a, b) => (a.id > b.id ? 0 : 1));
});

async function createGroup() {
    loadingStatus.value = "creating";
    await matchGroupStore.addMatchGroup(newGroupDetails.value);
    showCreateModal.value = false;
    newGroupDetails.value = {
        name: "",
        colour: "#ef4444",
    };
    loadingStatus.value = "loaded";
}

function toggleEditModal(group) {
    editGroupDetails.value = {
        id: group.id,
        name: group.name,
        colour: group.colour,
    };
    showEditModal.value = true;
}

function toggleDeletionModal(group) {
    deleteGroupDetails.value = group;
    showDeletionModal.value = true;
}

async function editGroup() {
    loadingStatus.value = "editing";
    await matchGroupStore.editGroup(editGroupDetails.value);
    showEditModal.value = false;
    loadingStatus.value = "idle";
}

async function deleteGroup() {
    loadingStatus.value = "deleting";
    await matchGroupStore.deleteGroup(deleteGroupDetails.value);
    showDeletionModal.value = false;
    loadingStatus.value = "idle";
}
</script>
