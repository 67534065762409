import { defineStore } from "pinia";
import store from "@/store/store";

export const useFamilyMatchingStore = defineStore("familyMatching", {
    getters: {
        optInStatus() {
            const fm = store.getters.kitTests.familyMatching;
            if (!fm.purchased) {
                return "notOptedIn";
            }
            if (fm.status === "pending") {
                return "notRunYet";
            }
            return "optedIn";
        },
    },
});
