<template>
    <PageLoader v-if="loadingStatus === 'loading'" class="py-12" />
    <div class="h-full w-full" v-else-if="loadingStatus === 'loaded'">
        <portal to="navigation">
            <NavBar :tabs="tabs" />
        </portal>
        <router-view />
    </div>
    <ParentalPageError v-else-if="loadingStatus === 'failed'" type="mtdna" />
</template>

<script setup>
import { useMotherlineStore } from "@/store/parentalStore";
import { useLoading } from "@/composables/useLoading";
import PageLoader from "@/components/PageLoader.vue";
import NavBar from "@/components/partials/NavBar.vue";
import ParentalPageError from "@/views/ancestry/ParentalPageError.vue";

const motherlineStore = useMotherlineStore();
const { loadingStatus, finishLoading, failLoading } = useLoading();

motherlineStore
    .getResult()
    .then(() => {
        if (!motherlineStore.result) {
            throw new Error("No result");
        }
    })
    .then(finishLoading)
    .catch(failLoading);

const tabs = [
    {
        name: "Overview",
        to: { path: "/motherline/overview" },
        icon: "home",
    },
    {
        name: "History",
        to: { path: "/motherline/history" },
        icon: "list",
    },
    {
        name: "Distribution Map",
        to: { path: "/motherline/distribution-map" },
        icon: "map-pin",
    },
    {
        name: "Migration Map",
        to: { path: "/motherline/migration-map" },
        icon: "map",
    },
];
</script>
