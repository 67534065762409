import Vikings from "@/views/vikings/Vikings.vue";
import Overview from "@/views/vikings/Overview.vue";
import VikingsResults from "@/views/vikings/VikingsResults.vue";
import VikingsMaps from "@/views/vikings/VikingsMaps.vue";

export default [
    {
        path: "/vikings",
        component: Vikings,
        redirect: "/vikings/overview",
        children: [
            {
                path: "overview",
                name: "viking-overview",
                component: Overview,
            },
            {
                path: "results",
                name: "viking-results",
                component: VikingsResults,
            },
            {
                path: "maps",
                name: "viking-map",
                component: VikingsMaps,
            },
        ],
    },
];
