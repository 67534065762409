import Main from "@/views/signin/Main.vue";
import Start from "@/views/activation/Start.vue";
import WhoIsThisKitFor from "@/views/activation/WhoIsThisKitFor.vue";
import EnterCode from "@/views/activation/EnterCode.vue";
import KitActivated from "@/views/activation/KitActivated.vue";
import images from "@/assets/assetMaps";
import ReceivedDeletion from "@/views/account/Delete/ReceivedDeletion.vue";
import ActivationOptIn from "@/views/activation/ActivationOptIn.vue";
import ActivationFamilyNetworksUsername from "@/views/activation/ActivationFamilyNetworksUsername.vue";
import ActivationFamilyNetworksAvatar from "@/views/activation/ActivationFamilyNetworksAvatar.vue";
import ActivationCompleted from "@/views/activation/ActivationCompleted.vue";
import CreateHealthProfileGoals from "@/views/activation/CreateHealthProfileGoals.vue";

export default [
    {
        path: "/activate",
        name: "activate",
        component: Main,
        redirect: "/kit-activation/start",
    },
    {
        path: "/kit-activation",
        name: "activation",
        component: Main,
        redirect: "/kit-activation/start",
        children: [
            {
                path: "start",
                name: "activation-start",
                component: Start,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "enter-code",
                name: "enter-code",
                component: EnterCode,
                meta: {
                    image: images.activationImage,
                    auth: true,
                },
            },
            {
                path: "who-is-this-kit-for",
                name: "who-is-this-kit-for",
                component: WhoIsThisKitFor,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "success",
                name: "activation-success",
                component: KitActivated,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "received",
                component: ReceivedDeletion,
                name: "received-account-deletion",
                meta: {
                    image: images.peopleImage,
                },
            },
            {
                path: "family-networks-opt-in",
                name: "activation-family-networks-opt-in",
                component: ActivationOptIn,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "create-health-profile-goals",
                name: "create-health-profile-goals",
                component: CreateHealthProfileGoals,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "family-networks-username",
                name: "activation-family-networks-username",
                component: ActivationFamilyNetworksUsername,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "family-networks-avatar",
                name: "activation-family-networks-avatar",
                component: ActivationFamilyNetworksAvatar,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
            {
                path: "completed",
                name: "activation-completed",
                component: ActivationCompleted,
                meta: {
                    image: images.peopleImage,
                    auth: true,
                },
            },
        ],
    },
];
