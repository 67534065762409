<template>
    <div
        class="flex flex-col items-start gap-4 bg-white p-6 shadow-drama rounded relative z-10 border-t-4"
        :class="style.border"
    >
        <div class="p-4 inline-block rounded-full" :class="style.secondaryColor">
            <FeatherWrapper
                :icon="icon || 'users'"
                :classes="['h-5 w-5 block']"
                :class="style.mainColor"
            />
        </div>

        <div>
            <h2 class="text-lg font-semibold m-0 pb-2">
                <slot name="title" />
            </h2>
            <slot name="body" />
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    type: String,
    icon: String,
});

const types = {
    error: {
        mainColor: "text-rose-500",
        secondaryColor: "bg-rose-50",
        border: "border-rose-500",
    },
    info: {
        mainColor: "text-cobalt-500",
        secondaryColor: "bg-cobalt-50",
        border: "border-cobalt-500",
    },
    warning: {
        mainColor: "text-tangerine-500",
        secondaryColor: "bg-tangerine-50",
        border: "border-tangerine-500",
    },
};

const style = computed(() => {
    const type = types[props.type];
    if (!type) {
        throw new Error(`Invalid type passed : ${props.type}`);
    }
    return type;
});
</script>
