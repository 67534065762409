<template>
    <a
        href="https://support.livingdna.com/hc/en-us/articles/360012532931-Can-I-get-my-Fatherline-results-"
        class="shadow rounded-lg bg-white h-full flex flex-col"
        target="_blank"
        rel="noopener"
    >
        <div class="h-16 overflow-hidden rounded-t-lg fade-bottom">
            <img :src="image" alt="Explore your fatherline" class="w-full" />
        </div>
        <div class="px-6 pb-6">
            <p class="text-grey-700" style="font-size: 15px">
                Paternal haplogroup cannot be&nbsp;provided
            </p>
            <p class="text-grey-600 text-sm">
                We look at the Y chromosome to provide a paternal haplogroup. As genetic females
                don’t have this chromosome, it is not possible to generate a paternal haplogroup
                result.
            </p>
        </div>
        <span class="flex items-center mt-auto px-6 pb-6">
            <feather-wrapper icon="male" />
            <span class="ml-2 font-semibold">Learn more</span>
        </span>
    </a>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import image from "@/assets/images/dashboard/fatherline-header.png";

export default {
    components: { FeatherWrapper },
    props: { paternalData: Object },
    data() {
        return {
            image,
        };
    },
};
</script>
