<template>
    <RightImageCard class="h-full" :image="{ src: image }" image-class="w-3/5">
        <template v-slot:body>
            <div class="md:w-3/5 p-6 flex flex-col">
                <h3 class="text-sm text-gray-500 mb-2">
                    Preparing results ({{ isUpload ? "72 hours" : "6 to 8 weeks" }})
                </h3>
                <div>Did you know?</div>
                <div class="mt-1 mb-4">
                    Beta carotene, as its name suggests, is found in carrots, but can also be found
                    in spinach.
                </div>
                <div class="text-grey-500 mt-auto flex items-center">
                    <Feather type="clipboard" class="h-5 w-5 fill-current mr-2" />
                    Wellbeing reports
                </div>
            </div>
        </template>
    </RightImageCard>
</template>

<script>
import tallImage from "@/assets/images/wellbeing/lemons-tan-tall.png";
import wideImage from "@/assets/images/wellbeing/lemons-tan-wide.png";
import RightImageCard from "@/components/cards/RightImageCard.vue";
import Feather from "vue-feather";

export default {
    components: { RightImageCard, Feather },
    props: {
        imageType: String,
    },
    computed: {
        image() {
            if (this.imageType === "wide") {
                return wideImage;
            }
            return tallImage;
        },
        isUpload() {
            return this.$store.getters.profile.isUpload;
        },
    },
};
</script>
