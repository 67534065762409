<template>
    <div
        v-if="
            loadingStatus === 'loaded' &&
            salesBanner.enable &&
            !$store.getters.kitTests[salesBanner.dna_test_name].purchased
        "
        class="relative px-6 py-32 sm:px-12 sm:py-16 lg:px-16 mt-12 rounded-lg overflow-hidden"
    >
        <div class="absolute inset-0 overflow-hidden">
            <img
                :src="salesBanner.background_image.url"
                alt=""
                class="h-full w-full object-cover object-center blur-sm"
            />
        </div>
        <div
            aria-hidden="true"
            class="absolute inset-0 opacity-70"
            :style="{ backgroundColor: salesBanner.overlay_colour, backgroundOpacity: 0.7 }"
        ></div>
        <div class="relative mx-auto flex max-w-2xl flex-col items-center text-center">
            <h2
                class="text-3xl font-bold tracking-tight text-white sm:text-xl"
                :style="{ color: salesBanner.text_colour }"
            >
                {{ salesBanner.title }}
            </h2>
            <div class="mt-3" :style="{ color: salesBanner.text_colour }">
                <div v-html="salesBanner.description" class="drop-shadow-xl" />
            </div>
            <router-link
                :to="{ name: 'product', params: { slug: salesBanner.product_slug } }"
                class="mt-8 block w-full rounded-md px-6 py-2 text-sm font-semibold sm:w-auto transition-colors promo-button"
                :style="buttonVars"
                >{{ salesBanner.button_text }}</router-link
            >
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useSalesBanner } from "@/composables/useSalesBanner";
import { useLoading } from "@/composables/useLoading";

const { loadingStatus, finishLoading } = useLoading();
const salesBanner = ref({});

useSalesBanner()
    .then((salesBannerResult) => {
        salesBanner.value = salesBannerResult;
    })
    .then(finishLoading);

const buttonVars = computed(() =>
    loadingStatus.value === "loaded"
        ? {
              "--text-color": salesBanner.value.button_text_colour,
              "--bg-color": salesBanner.value.background_colour,
          }
        : {}
);
</script>

<style>
.promo-button {
    color: var(--text-color);
    background-color: var(--bg-color);
}
.promo-button:hover {
    background-color: var(--text-color);
    color: var(--bg-color);
}
</style>
