<template>
    <tr>
        <td class="pr-2">
            <slot name="icon" />
        </td>
        <td class="py-6 md:py-3 px-2">
            <slot name="name" />
        </td>
        <td class="px-2 hidden md:table-cell">
            <slot name="description" />
        </td>
        <td class="pl-2 hidden md:table-cell">
            <slot name="duration" />
        </td>
    </tr>
</template>
