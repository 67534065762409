<template>
    <main>
        <h1 class="font-normal text-2xl">Bio</h1>

        <p class="my-4">Write a description to help others identify you</p>

        <form @submit.prevent="saveBio">
            <EditBioTextArea :body="bioService.state.context.bio" />
            <div v-if="bioService.state.matches('error')" class="mt-2 text-sm text-rose-500">
                {{ bioService.state.context.error }}
            </div>

            <SubmitButton icon="save" :class="{ loading: matchesAny(['saving']) }" hide-icon>
                Save
            </SubmitButton>
            <div class="text-center">
                <router-link
                    :to="{ name: 'profile-view' }"
                    class="mt-2 p-2 inline-block text-grey-500"
                >
                    Cancel
                </router-link>
            </div>
        </form>
    </main>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import profileService from "@/services/profile";
import SubmitButton from "@/components/button/SubmitButton.vue";
import EditBioTextArea from "./EditBioTextArea.vue";

const bioMachine = Machine(
    {
        initial: "idle",
        context: {
            bio: "",
            error: "",
        },
        states: {
            idle: {
                on: {
                    INITIATE: {
                        actions: "cacheBio",
                        target: "initiated",
                    },
                },
            },
            initiated: {
                on: {
                    SAVE: "saving",
                },
            },
            saving: {
                invoke: {
                    src: "saveBio",
                    onDone: "saved",
                    onError: "error",
                },
            },
            saved: {
                type: "final",
            },
            error: {
                entry: "cacheError",
                on: {
                    SAVE: "saving",
                },
            },
        },
    },
    {
        actions: {
            cacheBio: assign({
                bio: (ctx, event) => event.bio,
            }),
            cacheError: assign({
                error: (ctx, event) => {
                    console.log(event);
                    return "Something went wrong saving your bio";
                },
            }),
        },
        services: {
            saveBio: async (ctx, event) => {
                await profileService.saveBio(event.profile, { bio: event.bio });
            },
        },
    }
);

export default {
    components: { EditBioTextArea, SubmitButton },
    methods: {
        matchesAny(states) {
            return states.some(this.bioService.state.matches);
        },
        saveBio(event) {
            const data = new FormData(event.target);
            this.bioService.send("SAVE", {
                bio: data.get("content"),
                profile: this.profile,
            });
        },
    },
    data() {
        return {
            bioService: undefined,
        };
    },
    props: ["profile"],
    created() {
        this.bioService = interpret(bioMachine).start();
        this.bioService.send("INITIATE", {
            bio: this.profile.bio,
        });
        this.bioService.onTransition((state) => {
            if (state.done) {
                this.$router.push({
                    name: "profile-view",
                    params: { barcode: this.profile.barcode },
                });
            }
        });
    },
};
</script>
