<template>
    <div class="rounded-full inline-flex h-24 w-24 p-4" :style="{ backgroundColor: color }">
        <component class="m-auto w-full text-white fill-current" :is="icon" />
    </div>
</template>

<script>
import Biotin from "./SupplementIcons/biotin.vue";
import Boron from "./SupplementIcons/boron.vue";
import Calcium from "./SupplementIcons/calcium.vue";
import Chromium from "./SupplementIcons/chromium.vue";
import Copper from "./SupplementIcons/copper.vue";
import Folate from "./SupplementIcons/folate.vue";
import Iodine from "./SupplementIcons/iodine.vue";
import Magnesium from "./SupplementIcons/magnesium.vue";
import Manganese from "./SupplementIcons/manganese.vue";
import Molybdenum from "./SupplementIcons/molybdenum.vue";
import Selenium from "./SupplementIcons/selenium.vue";
import VitaminA from "./SupplementIcons/vitaminA.vue";
import VitaminB1 from "./SupplementIcons/vitaminB1.vue";
import vitaminB2 from "./SupplementIcons/vitaminB2.vue";
import VitaminB3 from "./SupplementIcons/vitaminB3.vue";
import vitaminB5 from "./SupplementIcons/vitaminB5.vue";
import VitaminB6 from "./SupplementIcons/vitaminB6.vue";
import VitaminB12 from "./SupplementIcons/vitaminB12.vue";
import VitaminC from "./SupplementIcons/vitaminC.vue";
import VitaminD from "./SupplementIcons/vitaminD.vue";
import VitaminE from "./SupplementIcons/vitaminE.vue";
import Zinc from "./SupplementIcons/zinc.vue";

function getComponents() {
    for (let componentName of allComponentNames) {
        const component = import(`./SupplementIcons/${componentName}.vue`);
        const key = componentName[0].toUpperCase() + componentName.substring(1);
        components[key] = component;
    }
}

export default {
    components: {
        Biotin,
        Boron,
        Calcium,
        Chromium,
        Copper,
        Folate,
        Iodine,
        Magnesium,
        Manganese,
        Molybdenum,
        Selenium,
        VitaminA,
        VitaminB1,
        vitaminB2,
        VitaminB3,
        vitaminB5,
        VitaminB6,
        VitaminB12,
        VitaminC,
        VitaminD,
        VitaminE,
        Zinc,
    },
    props: {
        icon: {
            type: String,
            required: true,
            // validator: (icon) => {
            //     if (!allComponentNames.includes(icon)) {
            //         console.warn(`"${icon}" is not an available icon type.`);
            //         return false;
            //     }

            //     return true;
            // },
        },
        color: {
            type: String,
            required: true,
            validator: (color) => color.substr(0, 1) === "#",
        },
    },
};
</script>
