<template>
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 59.656 59.656"
        style="enable-background:new 0 0 59.656 59.656;"
        xml:space="preserve"
    >
        <g>
            <g>
                <path
                    d="M29.999,29.156c-4.963,0-9,4.037-9,9s4.037,9,9,9s9-4.037,9-9S34.962,29.156,29.999,29.156z M29.999,45.156
			c-3.859,0-7-3.141-7-7s3.141-7,7-7s7,3.141,7,7S33.859,45.156,29.999,45.156z"
                />
                <path
                    d="M58.78,27.535c-1.037-1.037-2.7-1.172-3.868-0.312c-3.94,2.899-9.526,2.474-12.99-0.99
			c-3.463-3.463-3.889-9.048-0.989-12.991c0.859-1.168,0.725-2.831-0.313-3.866c-0.618-0.619-1.466-0.931-2.35-0.868
			c-0.884,0.068-1.692,0.521-2.217,1.24c-3.488,4.788-3.914,11.08-1.5,16.247c-1.42-0.533-2.95-0.839-4.553-0.839
			c-1.762,0-3.441,0.356-4.974,0.994c2.496-5.197,2.1-11.566-1.423-16.402c-0.523-0.719-1.33-1.171-2.215-1.24
			c-0.87-0.062-1.731,0.248-2.352,0.867c-1.037,1.037-1.172,2.7-0.312,3.868c2.899,3.942,2.474,9.527-0.99,12.99
			c-3.464,3.464-9.05,3.889-12.99,0.99c-1.168-0.86-2.83-0.726-3.867,0.312c0,0,0,0-0.001,0c-0.619,0.62-0.936,1.478-0.867,2.352
			c0.069,0.885,0.521,1.691,1.24,2.215c2.789,2.032,6.089,3.027,9.381,3.027c2.512,0,5.015-0.589,7.284-1.73
			c-0.584,1.474-0.915,3.076-0.915,4.757c0,7.168,5.832,13,13,13s13-5.832,13-13c0-1.609-0.308-3.144-0.845-4.568
			c2.16,1.01,4.51,1.541,6.872,1.541c3.29,0,6.592-0.996,9.38-3.027c0.719-0.523,1.171-1.33,1.24-2.214
			C59.715,29.012,59.4,28.155,58.78,27.535z M2.426,30.484c-0.246-0.179-0.4-0.453-0.424-0.753
			c-0.023-0.296,0.079-0.573,0.288-0.782h0.001C2.484,28.754,2.743,28.654,3,28.654c0.197,0,0.394,0.06,0.558,0.181
			c4.734,3.479,11.436,2.969,15.59-1.188c4.156-4.155,4.667-10.857,1.188-15.59c-0.279-0.379-0.23-0.924,0.114-1.268
			c0.209-0.209,0.49-0.308,0.782-0.288c0.3,0.023,0.574,0.178,0.753,0.424c4.017,5.512,3.405,13.309-1.422,18.137
			C15.735,33.888,7.939,34.499,2.426,30.484z M29.999,49.156c-6.065,0-11-4.935-11-11s4.935-11,11-11
			c2.587,0,4.964,0.903,6.844,2.404c0.267,0.313,0.541,0.622,0.836,0.917c0.287,0.287,0.592,0.547,0.896,0.808
			c1.513,1.884,2.424,4.271,2.424,6.871C40.999,44.221,36.065,49.156,29.999,49.156z M57.229,30.484
			c-5.156,3.758-12.31,3.461-17.163-0.541c-0.554-0.678-1.175-1.299-1.854-1.854c-4.004-4.854-4.297-12.007-0.541-17.164
			c0.179-0.246,0.454-0.4,0.755-0.424c0.027-0.002,0.054-0.003,0.081-0.003c0.264,0,0.51,0.102,0.699,0.292
			c0.345,0.343,0.394,0.888,0.115,1.267c-3.479,4.732-2.969,11.435,1.187,15.59c4.156,4.157,10.858,4.669,15.59,1.188
			c0.38-0.279,0.923-0.229,1.269,0.114c0.208,0.209,0.311,0.486,0.287,0.781C57.629,30.031,57.475,30.305,57.229,30.484z"
                />
            </g>
        </g>
    </svg>
</template>
