<template>
    <div>
        <p class="my-0 text-grey-500 text-xs mb-2">
            Click on each of the result options below to view their explanation.
        </p>
        <div class="flex flex-wrap justify-between mb-6 -mx-3">
            <div v-for="(phenotype, index) in phenotypes" class="block flex-1 text-center mx-3">
                <ScaleMapper
                    :level="phenotype.level"
                    v-slot="{ primaryColour, secondaryColour, result }"
                >
                    <button
                        @click="setActivePhenotype($event, index)"
                        class="bg-grey-200 py-2 px-4 rounded block w-full font-semibold text-grey-500 focus:outline-none focus:shadow-hover hover:shadow-hover text-sm mb-1"
                        :style="{
                            backgroundColor: activeIndex === index ? secondaryColour : '',
                            color: activeIndex === index ? primaryColour : '',
                        }"
                    >
                        {{ phenotype.name }}
                    </button>
                </ScaleMapper>
            </div>
        </div>
        <div v-html="phenotypes[activeIndex].description"></div>
    </div>
</template>

<script>
import ScaleMapper from "@/components/Wellbeing/ScaleMapper.vue";
const phenotypes = [
    {
        name: "Decreased",
        level: 1,
        description: `
            <p>A <strong>decreased</strong> result means that you have one or more genetic markers that have the potential to cause a reduced performance of the trait being tested. For example, if you receive a “decreased” result for vitamin D, it means you might metabolize vitamin D less effectively than someone without those markers.</p>
            <p>In some cases, such as celiac disease risk, the phrase “decreased” has been changed to <strong>"variants detected"</strong>. A red result (variants detected) in celiac disease risk means that you might have the potential to process gluten in a negative way, as detailed in the report for that trait.</p>
        `,
    },
    {
        name: "Regular",
        level: 3,
        description: `
            <p>A <strong>regular</strong> result means that your genetic markers associated with the trait being tested do not positively or negatively impact that trait. For example, if you received a “regular” result for vitamin D, it means you are more likely to metabolize vitamin D at a normal rate than someone without those markers.</p>
            <p>In some cases, such as celiac disease risk, the phrase “regular” has been changed to <strong>"variants not found"</strong>. A blue result (variants not detected) in celiac disease risk means that you do not appear to have genetic markers associated with that risk, as detailed in that trait report.</p>
        `,
    },
    {
        name: "Increased",
        level: 5,
        description: `
            <p>An <strong>increased</strong> result means that you have one or more genetic markers that have the potential to cause an increased performance for the trait being tested. For example, if you received an “increased” result for tendon durability, it means you might have more durable tendons than someone without those markers.</p>
        `,
    },
];

export default {
    components: { ScaleMapper },
    methods: {
        setActivePhenotype(event, index) {
            this.activeIndex = index;
            event.target.blur();
        },
        getDefaultLevel(allPhenotypes, result) {
            return allPhenotypes.map(p => p.level).indexOf(result) || 0;
        },
    },
    props: {
        result: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            activeIndex: this.getDefaultLevel(phenotypes, this.result),
            phenotypes,
        };
    },
};
</script>
