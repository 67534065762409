<template>
    <form @submit.prevent="$emit('accept-additional-terms')" class="mt-6">
        <div class="flex items-center mt-6">
            <button
                type="submit"
                class="bg-cobalt-500 py-2 px-4 text-white flex items-center rounded"
                :class="{ loading: loading }"
            >
                <feather type="check" class="h-5 w-5 fill-current mr-2" />
                Yes, I consent
            </button>
            <router-link :to="declineLink" class="text-gray-600 ml-4"> No thank you </router-link>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        loading: Boolean,
        declineLink: Object,
    },
};
</script>
