<template>
    <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 relative">
        <button v-if="hasInfo" @click="showModal = true" class="absolute top-0 right-0 pt-4 pr-4">
            <Feather type="info" class="h-5 w-5 text-cobalt-500" />
        </button>

        <dt class="truncate text-sm font-medium text-gray-500">
            <slot name="title" />
        </dt>
        <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            <slot name="value" />
        </dd>
    </div>
    <Modal v-if="hasInfo" width="xl" :open="showModal" @closed="showModal = false">
        <slot name="info" />
    </Modal>
</template>

<script>
import { ref } from "vue";
import Modal from "@/components/Modal.vue";

export default {
    components: { Modal },
    setup(props, { slots }) {
        const showModal = ref(false);
        const hasInfo = !!slots.info;
        return { hasInfo, showModal };
    },
};
</script>
