import { Cloudinary } from "@cloudinary/url-gen";
import { scale } from "@cloudinary/url-gen/actions/resize";

const uploadFile = (cloudName) => (preset) => (file) =>
    new Promise((resolve, reject) => {
        console.log("file", file);
        const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        xhr.open("POST", url, true);
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                resolve(response.secure_url);
            }
            if (xhr.readyState === 4 && xhr.status >= 400) {
                const response = JSON.parse(xhr.responseText);
                reject(response.error.message);
            }
        };

        fd.append("upload_preset", preset);
        fd.append("tags", "browser_upload");
        fd.append("file", file);
        xhr.send(fd);
    });

const ldnaUploader = uploadFile("living-dna");
export const avatarUploader = ldnaUploader("wrv6t2nl");

export function pngUrlToJpg(url) {
    if (!url) {
        console.log("Not a string, or empty, ignoring");
        return url;
    }
    const indexOfFileType = url.indexOf(".png");
    if (indexOfFileType === -1) {
        console.log("Url is not .png, ignoring");
        return url;
    }
    return url.replace(".png", ".jpg");
}

export function useImage(publicId, width = null) {
    const cld = new Cloudinary({
        cloud: {
            cloudName: "living-dna",
        },
    });

    const myImg = cld.image(publicId);

    if (width) {
        myImg.resize(scale().width(width));
    }

    return myImg;
}
