<template>
    <div>
        <SubmitButton icon="save" :disabled="buttonDisabled" :class="{ loading: statusIsPending }">
            Save
        </SubmitButton>
    </div>
</template>

<script>
import SubmitButton from "@/components/button/SubmitButton.vue";

export default {
    components: { SubmitButton },
    data() {
        return {
            buttonDisabled: false,
            statusIsPending: false,
        };
    },
};
</script>
