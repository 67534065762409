<template>
    <div class="rounded-lg bg-white shadow-key overflow-hidden flex flex-col">
        <div class="fade-bottom">
            <img :src="cmsData.mainImage.urlRaw" :alt="cmsData.mainImage.alt" />
        </div>
        <div class="px-6 py-5 flex flex-col flex-1">
            <div class="space-y-3">
                <h2>{{ cmsData.heading }}</h2>
                <div v-html="cmsData.text" />
                <h4 class="pt-2 mb-4">Your result</h4>
                <div class="mb-4" v-html="result.total.totalPhenotype" />
                <button @click="showModal = true" class="text-sm text-cobalt-500 font-semibold">
                    Click for more info
                </button>
            </div>
            <div class="mt-auto pt-6">
                <div
                    class="py-2 px-4 rounded inline-block font-semibold"
                    :class="`${shortResult.bgColor} ${shortResult.textColor}`"
                >
                    {{ shortResult.friendlyName }}
                </div>
            </div>
        </div>
    </div>
    <Modal :open="showModal" @state-change="showModal = $event" width="3xl">
        <div class="space-y-8">
            <div v-if="Object.keys(result.subtraits).length > 1">
                The {{ cmsData.heading }} trait is made up of
                {{ Object.keys(result.subtraits).length }} subtraits
            </div>
            <div v-for="(subtraitDetails, name) in result.subtraits" :key="name">
                <h4>{{ name }}</h4>
                <p>{{ subtraitDetails.total.totalPhenotype }}</p>
                <div class="bg-white shadow-key rounded">
                    <div class="px-6 py-4 flex text-grey-500 text-sm justify-center">
                        <div class="w-1/5 text-center">Gene</div>
                        <div class="w-1/5 text-center">RSID</div>
                        <div class="w-1/5 text-center">Locus</div>
                        <div class="w-1/5 text-center">Genotype</div>
                        <div class="w-1/5 text-center">Phenotype</div>
                    </div>
                    <div
                        v-for="(details, snp) in subtraitDetails.snps"
                        :key="snp"
                        class="px-6 bg-grey-50 pt-6 rounded-b"
                    >
                        <div class="flex pb-6">
                            <div class="w-1/5 text-center">{{ details.gene }}</div>
                            <div class="w-1/5 text-center">{{ snp }}</div>
                            <div class="w-1/5 text-center">{{ details.locus }}</div>
                            <div class="w-1/5 text-center">{{ details.genotype }}</div>
                            <div class="w-1/5 text-center capitalize">{{ details.phenotype }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import { ref } from "vue";
import Modal from "@/components/Modal.vue";

const dict = {
    untested: {
        bgColor: "bg-grey-200",
        textColor: "text-grey-600",
        friendlyName: "Not found",
    },
    negative: {
        bgColor: "bg-rose-50",
        textColor: "text-rose-500",
        friendlyName: "Negative",
    },
    positive: {
        bgColor: "bg-emerald-50",
        textColor: "text-emerald-500",
        friendlyName: "Positive",
    },
};

export default {
    props: {
        result: Object,
        cmsData: Object,
    },
    components: { Modal },
    setup() {
        const showModal = ref(false);
        return { showModal };
    },
    computed: {
        shortResult() {
            const res = this.result.total.totalPhenotypeSlug.substring(
                this.result.total.totalPhenotypeSlug.indexOf("_") + 1
            );
            return dict[res];
        },
    },
};
</script>
