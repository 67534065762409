<template>
    <div>
        <h3 class="text-denim-700 text-2xl font-normal text-center">Enter activation code</h3>
        <p class="text-grey-700 text-center mt-4 mb-8 leading-normal">
            The activation code can be found inside the booklet included with your DNA testing kit.
        </p>
        <form @submit.prevent="submitActivationCode">
            <div>
                <CodeInput v-model="activationCode" />
            </div>
            <div v-if="error" class="text-sm text-rose-500 mt-2">{{ error }}</div>
            <button
                :class="{
                    loading: loading,
                    'bg-grey-200 text-grey-500': !codeValid,
                    'bg-cobalt-500 text-white': codeValid,
                }"
                :disabled="!codeValid"
                class="mt-4 rounded w-full py-2 px-4 font-semibold"
            >
                Continue
            </button>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import CodeInput from "@/components/activation/CodeInput.vue";

export default {
    components: { CodeInput },
    data() {
        return {
            activationCode: [],
            error: "",
            loading: false,
        };
    },
    computed: {
        formattedActivationCode() {
            return this.activationCode.join("-");
        },
        codeValid() {
            console.log(this.formattedActivationCode.length);
            return this.formattedActivationCode.length === 14;
        },
    },
    methods: {
        submitActivationCode() {
            this.error = "";
            this.loading = true;
            this.$store
                .dispatch("setActivationCode", {
                    code: this.formattedActivationCode,
                    http: axios,
                })
                .then(() => this.$router.push({ name: "who-is-this-kit-for" }))
                .catch((err) => {
                    console.error(err);
                    this.error = "This activation code has already been used used or is invalid";
                    this.loading = false;
                });
        },
    },
};
</script>
