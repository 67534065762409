<template>
    <li class="mb-4 flex items-center">
        <div class="rounded-full bg-emerald-50 p-2">
            <product-tick />
        </div>
        <p class="text-grey-700 ml-4 my-1">{{ text }}</p>
    </li>
</template>
<script>
import ProductTick from "@/views/store/product/ProductTick.vue";

export default {
    name: "product-list-item",
    components: { ProductTick },
    props: {
        text: {},
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
        };
    },
};
</script>
