<template>
    <div
        v-if="disabled"
        class="border-r border-grey-200 w-10 py-2 flex items-center justify-center font-semibold"
        :class="true ? 'text-grey-700' : 'text-cobalt-500'"
    >
        <slot />
    </div>
    <button
        v-else
        @click="$emit('page-change')"
        class="border-r border-grey-200 w-10 py-2 flex items-center justify-center font-semibold"
        :class="isCurrentPage ? 'text-grey-700' : 'text-cobalt-500'"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: "pagination-button",
    props: {
        isCurrentPage: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
    },
};
</script>
