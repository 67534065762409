<template>
    <MainOptInDetails :barcode="profile.barcode" @accepted-main-terms="goToNextStage" />
</template>

<script>
import MainOptInDetails from "@/components/FamilyMatching/MainOptInDetails.vue";

export default {
    components: { MainOptInDetails },
    props: {
        profile: {
            type: Object,
            required: true,
        },
    },
    methods: {
        goToNextStage() {
            this.$router.push({ name: "family-matching-opt-in-additional" });
        },
    },
};
</script>
