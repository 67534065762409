<template>
    <ContentLoader viewBox="0 0 250 110" primaryColor="#f3f3f3" secondaryColor="#ececec">
        <rect x="0" y="0" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="20" rx="3" ry="3" width="220" height="10" />
        <rect x="0" y="40" rx="3" ry="3" width="170" height="10" />
        <rect x="0" y="60" rx="3" ry="3" width="250" height="10" />
        <rect x="0" y="80" rx="3" ry="3" width="200" height="10" />
        <rect x="0" y="100" rx="3" ry="3" width="80" height="10" />
    </ContentLoader>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
    components: {
        ContentLoader,
    },
};
</script>
