export default {
    state: {
        modals: {
            dashboard: "not-viewed",
            detailsPage: "not-viewed",
        },
    },
    getters: {
        modals: state => JSON.parse(localStorage.getItem("modals")) || state.modals,
        updateModalClosed: (s, getters) => getters.modals.detailsPage === "closed",
        dashboardModalClosed: (s, getters) => {
            console.log(getters.modals.dashboard);
            return getters.modals.dashboard === "closed";
        },
    },
    actions: {
        closeUpdateModal({ commit, state, getters }) {
            commit("setModalStatus", {
                modal: "detailsPage",
                status: "closed",
                modals: getters.modals,
            });
            localStorage.setItem("modals", JSON.stringify(state.modals));
        },
        closeDashboardModal({ commit, state, getters }) {
            return new Promise(resolve => {
                commit("setModalStatus", {
                    modal: "dashboard",
                    status: "closed",
                    modals: getters.modals,
                });
                localStorage.setItem("modals", JSON.stringify(state.modals));
                resolve();
            });
        },
    },
    mutations: {
        setModalStatus(state, { modal, status, modals }) {
            state.modals = modals;
            state.modals[modal] = status;
        },
    },
};
