<template>
    <div class="mx-auto mb-6 md:mb-0 md:ml-24 md:mt-12 flex flex-row md:flex-col  items-start">
        <div class="flex flex-col sm:flex-row md:flex-col">
            <div>
                <div class="font-semibold text-grey-500 text-sm">Reference</div>
                <div class="mt-1">{{ selectedProfile.barcode }}</div>
            </div>

            <div class="md:mt-6 sm:ml-4 md:ml-0">
                <div class="font-semibold text-grey-500 text-sm">Added</div>
                <div class="mt-1">{{ date(selectedProfile.orderDate.date) }}</div>
            </div>
        </div>

        <div class="ml-4 md:ml-0 flex flex-col sm:flex-row md:flex-col">
            <div class="md:mt-6">
                <div class="font-semibold text-grey-500 text-sm">Type</div>
                <div class="mt-1 capitalize">{{ selectedProfile.testType }}</div>
            </div>

            <div class="md:mt-6 sm:ml-4 md:ml-0">
                <div class="font-semibold text-grey-500 text-sm">Status</div>
                <div class="mt-1">
                    <KitStatus :status="selectedProfile.raw_status" v-slot="{ kitStatus }">
                        <span
                            class="py-2 px-3 text-sm font-semibold rounded whitespace-no-wrap inline-block"
                            :style="{
                                backgroundColor: kitStatus.secondaryColour,
                                color: kitStatus.primaryColour,
                            }"
                        >
                            {{ kitStatus.formattedName }}
                        </span>
                    </KitStatus>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import KitStatus from "@/components/utilities/KitStatus.vue";
import { formatDate } from "@/services/profile";

export default {
    components: { KitStatus },

    props: {
        selectedProfile: Object,
    },

    methods: {
        date: formatDate,
    },
};
</script>
