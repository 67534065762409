export default {
    state: {
        autosomalColors: [
            "#24408e",
            "#68bc45",
            "#ff6633",
            "#007cba",
            "#137744",
            "#ec008b",
            "#00bef2",
            "#c1482d",
            "#53c1b0",
            "#9933cc",
            "#ccff00",
            "#ff66ff",
            "#8e6697",
            "#f05d7b",
            "#568283",
            "#24408e",
            "#68bc45",
            "#ff6633",
            "#007cba",
            "#137744",
            "#ec008b",
            "#00bef2",
            "#c1482d",
            "#53c1b0",
            "#9933cc",
            "#ccff00",
            "#ff66ff",
            "#8e6697",
            "#f05d7b",
            "#568283",
            "#a0ab3a",
        ],
        genderColor: {
            male: "#00A5B0",
            female: "#d40b55",
        },
        loadingColors: {
            light: "hsl(220, 25%, 94%)",
            dark: "hsl(220, 24%, 90%)",
        },
    },
    getters: {
        autosomalColors: state => state.autosomalColors,
        genderColor: state => gender => state.genderColor[gender],
        loadingColors: state => state.loadingColors,
    },
};
