import axios from "axios";
import endpoints from "@/endpoints";

function PresentedProduct(product) {
    if (product.images) {
        const modifiedProductImages = product.images.map((image, index) => ({
            ...image,
            main: index < 1,
            id: index,
        }));
        // assign the modified images
        Object.assign(product, { images: modifiedProductImages });
    }

    if (product.faq) {
        const modifiedProductFaqs = product.faq.map((q, index) => ({
            ...q,
            id: index,
        }));
        Object.assign(product, { faq: modifiedProductFaqs });
    }
    return product;
}

class ProductService {
    static async getProduct(slug) {
        const { data } = await axios.get(`${endpoints.product}/${slug}`);
        return new PresentedProduct(data);
    }

    static async validateInsights(sku, barcode) {
        const { data } = await axios.get(`${endpoints.validateInsights}`, {
            params: {
                productSku: sku,
                barcode,
            },
        });
        return data;
    }

    static async getAllProducts(barcode) {
        const {
            data: { data },
        } = await axios.get(`${endpoints.product}s/${barcode}`);
        return data;
    }

    static async getProductErrorDescription(type) {
        const list = {
            2: "Please purchase the ancestry upgrade or buy a kit in order to purchase this product.",
            3: "You have already purchased the Ancestry upgrade.",
        };
        return list[type];
    }
}

export default ProductService;
