<template>
    <OptInAdditionalDetails
        :barcode="profile.barcode"
        :decline-link="{ name: 'profile-view', params: { barcode: profile.barcode } }"
        @additional-accepted="returnToProfile"
    />
</template>

<script>
import OptInAdditionalDetails from "@/components/FamilyMatching/OptInAdditionalDetails.vue";

export default {
    components: { OptInAdditionalDetails },
    props: {
        profile: Object,
    },
    methods: {
        returnToProfile() {
            this.$router.push({ name: "profile-view", params: { barcode: this.profile.barcode } });
        },
    },
};
</script>
