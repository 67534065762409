<template>
    <button
        type="submit"
        class="w-full py-2 px-4 mt-6 rounded flex items-center justify-center
            bg-cobalt-500 hover:bg-cobalt-700 text-white font-semibold"
        :class="{ 'bg-grey-500 hover:bg-grey-500 cursor-default': disabled }"
        :disabled="disabled"
    >
        <feather-wrapper v-if="!hideIcon" :icon="icon" class="mr-2" />
        <slot />
    </button>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

export default {
    name: "SubmitButton",

    components: {
        FeatherWrapper,
    },

    props: {
        icon: String,
        hideIcon: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
        },
    },
};
</script>
