<template>
    <div>
        <div>
            <h3 class="text-denim-700 text-2xl font-normal text-center">Password Created</h3>
            <p class="text-grey-700 leading-normal text-center">
                Your password has been successfully created, you can now log in. Click the button
                below to be taken to the login page.
            </p>
            <router-link
                :to="{ name: 'login' }"
                class="bg-cobalt-500 hover:bg-cobalt-700 block w-full mt-6 rounded text-center py-2 px-4 font-semibold"
            >
                <span class="text-white">Go to login page</span>
            </router-link>
        </div>
    </div>
</template>
