<template>
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 480.004 480.004"
        style="enable-background:new 0 0 480.004 480.004;"
        xml:space="preserve"
    >
        <g>
            <g>
                <path
                    d="M479.202,292.692l0.072-0.024l-78.808-171.952l19.112-9.512l-7.152-14.32l-18.632,9.28l-2.52-5.496
			c-1.842-4.016-6.59-5.779-10.606-3.938c-1.742,0.799-3.139,2.196-3.938,3.938l-2.248,4.912c-13.56-6.298-28.329-9.566-43.28-9.576
			h-67.2v-16h16v-16H266.25l5.752-23.016c4.416-17.673-6.33-35.58-24.003-39.997c-17.673-4.416-35.58,6.33-39.997,24.003
			c-1.312,5.25-1.312,10.743,0,15.993l5.752,23.016h-13.752v16h16v16h-67.16c-14.966,0.012-29.75,3.288-43.32,9.6l-2.248-4.912
			c-1.841-4.016-6.59-5.779-10.606-3.938c-1.742,0.799-3.139,2.196-3.938,3.938l-2.52,5.496l-18.632-9.32l-7.152,14.336
			l19.112,9.552L0.73,292.668c-0.486,1.045-0.734,2.184-0.728,3.336c0.035,30.913,25.087,55.965,56,56h80
			c30.913-0.035,55.965-25.087,56-56c-0.02-1.149-0.293-2.28-0.8-3.312l-79.024-172.6c11.487-5.332,24-8.092,36.664-8.088h67.16
			v292.688l-27.312,27.312h-28.688c-22.08,0.026-39.974,17.92-40,40c0,4.418,3.582,8,8,8h224c4.418,0,8-3.582,8-8
			c-0.026-22.08-17.92-39.974-40-40h-28.688l-27.312-27.312V112.004h67.2c12.664-0.004,25.177,2.756,36.664,8.088L288.73,292.668
			c-0.486,1.045-0.734,2.184-0.728,3.336c0.035,30.913,25.087,55.965,56,56h80c30.913-0.035,55.965-25.087,56-56
			C479.983,294.855,479.71,293.724,479.202,292.692z M136.002,336.004h-80c-19.002-0.021-35.375-13.387-39.2-32h158.4
			C171.378,322.617,155.004,335.983,136.002,336.004z M171.538,288.004H20.466l73.488-160.328c1.342,0.36,2.754,0.36,4.096,0
			L171.538,288.004z M226.618,22.532c6.23-7.392,17.273-8.333,24.665-2.103c0.759,0.64,1.463,1.344,2.103,2.103
			c3.236,4.116,4.379,9.5,3.096,14.576l-6.728,26.896H230.25l-6.728-26.896C222.239,32.032,223.383,26.648,226.618,22.532z
			 M232.002,96.004v-16h16v16H232.002z M248.002,112.004v288h-16v-288H248.002z M320.002,448.004
			c10.168,0.012,19.229,6.418,22.632,16H137.37c3.403-9.582,12.464-15.988,22.632-16H320.002z M268.69,432.004h-57.376l16-16h25.376
			L268.69,432.004z M381.938,127.7c1.321,0.465,2.77,0.405,4.048-0.168l73.552,160.472H308.466L381.938,127.7z M424.002,336.004h-80
			c-19.002-0.021-35.375-13.387-39.2-32h158.4C459.378,322.617,443.004,335.983,424.002,336.004z"
                />
            </g>
        </g>
    </svg>
</template>
