import axios from "axios";
import endpoints from "@/endpoints";
import Vue from "vue";

const xhr = new XMLHttpRequest();

export default {
    state: {
        whoDoesThisDNABelongTo: {},
        signedUrl: "",
        uploadProgress: 0,
    },
    getters: {
        whoDoesThisDNABelongTo: state => state.whoDoesThisDNABelongTo,
        uploadProgress: state => state.uploadProgress,
    },
    actions: {
        setWhoDoesThisDNABelongTo({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit("setWhoDoesThisDNABelongTo", payload);
                resolve();
            });
        },
        sendUploadUserDetailsToServer({ commit }, { payload, http }) {
            return new Promise((resolve, reject) => {
                http.post(endpoints.kitUploadTesterDetails, payload)
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            });
        },
        completeUpload({ commit }, { barcode, http, partner = {} }) {
            return new Promise(resolve => {
                http.put(endpoints.kitUploadComplete(barcode), partner)
                    .then(() => resolve())
                    .catch(() => null);
            });
        },
        setSignedUrl({ commit }, url) {
            return new Promise(resolve => {
                commit("setSignedUrl", url);
                resolve();
            });
        },
        uploadToSignedUrl({ commit }, { signedUrl, file }) {
            return new Promise((resolve, reject) => {
                // const data = new FormData();
                // data.append('file', file);
                xhr.open("PUT", signedUrl, true);
                if (xhr.upload) {
                    xhr.upload.addEventListener("progress", event => {
                        if (event.lengthComputable) {
                            commit("setUploadProgress", (event.loaded / event.total) * 100);
                        }
                    });
                }
                xhr.setRequestHeader("Content-Type", file.type);
                xhr.send(file);
                xhr.onload = () => {
                    resolve();
                };
                xhr.onerror = arg => {
                    console.log(arg);
                    reject(arg);
                };
            });
        },
        cancelUploadRequest({ commit }) {
            xhr.abort();
            commit("resetUploadProgress");
        },
    },
    mutations: {
        setWhoDoesThisDNABelongTo(state, data) {
            state.whoDoesThisDNABelongTo = data;
        },
        setSignedUrl(state, url) {
            state.signedUrl = url;
        },
        setUploadProgress(state, progress) {
            state.uploadProgress = progress;
        },
        resetUploadProgress(state) {
            state.uploadProgress = 0;
        },
    },
};
