<script>
import { h } from "vue";
import countries from "@/countries";

export default {
    render() {
        return h(
            "div",
            this.$slots.default({
                countryObject: this.mappedCountry,
            })
        );
    },
    props: {
        code: {
            required: true,
        },
    },
    computed: {
        mappedCountry() {
            return countries.find((country) => country.code === this.code);
        },
    },
};
</script>
