<template>
    <div v-if="false">
        {{ state.value }}
    </div>
    <div class="w-full flex flex-col">
        <!--        <div v-if="Object.keys(messageErrors).length === 0"
                class="h-16 flex justify-center items-center bg-grey-50 w-full">-->
        <!--            <div class="flex flex-row items-center">-->
        <!--                <Feather type="info" class="mr-4 text-grey-700" />-->
        <!--                <p class="text-grey-700">-->
        <!--                    You are exchanging messages with Lake79, who manages Katie’s DNA profile.-->
        <!--                </p>-->
        <!--            </div>-->
        <!--        </div>-->
        <div
            v-if="state.matches('conversationBlocked')"
            class="h-16 flex justify-center items-center bg-rose-50 w-full"
        >
            <div class="flex flex-row items-center">
                <Feather type="info" class="mr-4 text-rose-500" />
                <p class="text-rose-500">
                    This conversation has been blocked by one or more of its members
                </p>
            </div>
        </div>
        <div
            :style="{ height: state.context.messages.length ? '550px' : '' }"
            id="messages"
            class="overflow-scroll px-6 py-4 bg-white"
        >
            <div
                v-if="
                    state.matches('checkBlockedStatus') ||
                    state.matches('gettingMessages') ||
                    state.matches('checkingBlockedStatus')
                "
            >
                <PageLoader class="mt-12 pb-12"> Loading your conversation </PageLoader>
            </div>
            <template
                v-else-if="state.matches('loadedMessages') || state.matches('sendingMessage')"
            >
                <div
                    v-if="state.context.messages.length === 0"
                    class="md:mx-24 sm:mx-12 my-6 mx-4 rounded-lg bg-grey-50 text-center p-4 text-grey-700"
                >
                    You have not yet exchanged messages with this user. Say hello!
                </div>
                <FamilyMatchingMessage
                    v-for="message in state.context.messages"
                    :message="message"
                    :profile-name="getProfileName(message.from_barcode)"
                    :key="message.id"
                />
            </template>
        </div>

        <div v-if="state.matches('loadedMessages')" class="h-18 px-6 py-4 bg-white">
            <!-- @keyup.enter="sendMessage" put back if we want-->
            <!-- <input
                v-model="message"
                type="text"
                class="form-input block flex-1 h-12 rounded border border-grey-500 bg-grey-50"
                placeholder="Say hello to your DNA relative"
            /> -->
            <div
                ref="message"
                contenteditable="true"
                placeholder="Write a message..."
                class="form-input block flex-1 rounded border-2 border-cobalt-500 bg-white p-4"
            ></div>
            <div class="flex justify-end mt-2">
                <button
                    @click="sendMessage()"
                    class="text-cobalt-500 py-2 px-4 flex items-center space-x-2 hover:text-cobalt-700"
                >
                    <span class="font-semibold mt-px">Send</span>
                    <Feather type="send" class="flex-none" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { sortBy } from "lodash";
import { mapGetters } from "vuex";
import FamilyMatchingMessage from "@/components/FamilyMatching/Messaging/Message.vue";
import Pusher from "pusher-js";
import { Base64 } from "js-base64";
import { state, send, messagingService } from "../../machines/messagingMachine";
import { onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import store from "@/store/store";
import PageLoader from "@/components/PageLoader.vue";

export default {
    name: "profile-messages",
    components: {
        FamilyMatchingMessage,
        PageLoader,
    },
    props: {
        match: {
            type: Object,
            required: true,
            default: {},
        },
    },
    computed: {
        ...mapGetters(["profile", "profiles", "messaging", "token", "account"]),
        jwtToken() {
            return this.token.split("--REFRESHTOKEN--")[0];
        },
    },
    data() {
        return {
            messages: [],
            messageErrors: {},
            message: null,
            messagingCurrent: null,
            messagingContext: null,
            sender: {
                profileId: null,
            },
            receiver: {
                profileId: null,
                barcode: this.$route.params.barcode,
            },
            MAX_MESSAGE_LENGTH: 1000,
        };
    },
    setup(props, { emit }) {
        const pusher = new Pusher(process.env.VUE_APP_PUSHER_PUBLIC_KEY, {
            cluster: process.env.VUE_APP_PUSHER_CLUSTER,
            authEndpoint: process.env.VUE_APP_PUSHER_AUTH_URL,
        });

        const route = useRoute();
        const sorted = sortBy([store.getters.profile.barcode, route.params.barcode]);
        const channelHash = `private-${Base64.encode(sorted.join(","))}`;

        const channel = pusher.subscribe(channelHash);

        channel.bind("message-received", (message) => {
            if (message.sender_id !== store.getters.account.uuid) {
                send({ type: "UPDATE_MESSAGES", message });
            }
        });

        channel.bind("channel-blocked", (status) => {
            if (status) {
                send({
                    type: "BLOCK",
                    data: {
                        type: "CHANNEL_BLOCKED",
                        message: "This conversation has been blocked by one or more of its members",
                    },
                });
            } else {
                send({ type: "UNBLOCK" });
                send({ type: "AFTER_UNBLOCK", channel: channelHash });
            }
        });

        onMounted(() => {
            console.log("this gets called", send);
            send({ type: "SOCKET_CONNECTED", channel: channelHash });
            // messagingService
            //     .onTransition((state) => {
            // used to handle scroll to end on new message
            //         setTimeout(() => {
            //             // this.scrollToEnd();
            //         }, 100);
            //     })
        });

        onUnmounted(() => {
            pusher.unsubscribe(channelHash);
        });

        return { state, send };
    },
    methods: {
        getProfileName(barcode) {
            if (barcode !== null) {
                return this.match.barcode === barcode
                    ? this.match.username || this.match.name
                    : this.profile.username || this.profile.name;
            }
            return undefined;
        },
        calculatePermissionOnBarcode() {
            return this.profiles.find((p) => {
                return p.profile.barcode === this.profile.barcode;
            }).permission.type;
        },
        sendMessage() {
            const message = this.$refs.message.innerText;
            if (message.length > 0) {
                send({
                    type: "SEND_MESSAGE",
                    message: {
                        message: message,
                        sender_type: this.calculatePermissionOnBarcode(),
                    },
                });
                send({
                    type: "UPDATE_MESSAGES",
                    message: {
                        id: messages.length + 1,
                        channel: this.state.context.channel,
                        message: message,
                        sender_id: this.account.uuid,
                        sender_type: this.calculatePermissionOnBarcode(),
                    },
                });
                this.$refs.message.innerText = null;
            }
        },
        scrollToEnd() {
            const container = this.$el.querySelector("#messages");
            container.scrollTop = container.scrollHeight;
        },
    },
};
</script>

<style>
[contenteditable="true"]:empty:before {
    content: attr(placeholder);
    pointer-events: none;
    display: block; /* For Firefox */
}
</style>
