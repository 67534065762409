<template>
    <ImagePanel :image-src="image">
        <transition name="slide-left" mode="out-in">
            <div>
                <keep-alive>
                    <router-view></router-view>
                </keep-alive>
            </div>
        </transition>
    </ImagePanel>
</template>

<script>
import ImagePanel from "@/components/structure/ImagePanel";

export default {
    components: { ImagePanel },
    data() {
        return {
            image: "",
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                this.image = to.meta.image;
            },
        },
    },
};
</script>

<style>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
}
</style>
