<template>
    <div class="prose pt-12 mx-auto px-4 max-w-2xl">
        <h1>Panel update versions</h1>
        <p>
            <strong>What is a panel?</strong> At Living DNA we use panels to compare your DNA and
            provide you with results. A panel contains groups of analysed individuals that have been
            identified through scientific selection as ones whose DNA is most representative of that
            location.
        </p>
        <p>
            These samples are ones we use to compare your DNA to in order to provide you with your
            detailed breakdown. The more high quality data we have the better the breakdown we can
            provide. Opting into research can help us do this, it also helps us expand the regions
            we can call for.
        </p>
        <p>
            <strong>What is an update?</strong> We regularly look at the reference data we have and
            where possible offer updates to users if we are able to provide more detail to the
            results you already have. This is done on a region or panel basis. This means some
            people will be offered updates when others do not.
        </p>
        <div
            v-if="!isConsentedToResearch"
            class="p-6 bg-cobalt-50 text-cobalt-500 not-prose rounded"
        >
            <h3 class="font-semibold mb-2">Opt into research</h3>
            <p class="text-sm">
                Consider participating in our research program to help enhance our DNA testing
                services. Your involvement directly contributes to improving our reference panels,
                ensuring more accurate and insightful results for you and our entire community of
                users.
            </p>
            <router-link
                :to="{ name: 'research', params: { barcode: $store.getters.profile.barcode } }"
                class="font-semibold mt-2 inline-flex items-center gap-2 group"
            >
                <span class="group-hover:underline">Opt in here</span>
                <FeatherWrapper icon="chevron-right" />
            </router-link>
        </div>
    </div>
    <div class="px-4 max-w-xl m-auto mt-16">
        <h2 class="mb-6 font-semibold text-xl">Major version history</h2>
        <div v-for="(version, index) in mappedVersions" class="relative">
            <div
                v-if="index !== versions.length - 1"
                class="absolute w-0.5 bg-gray-400 h-full left-8 z-0"
                style="transform: translateX(-0.5px)"
            ></div>
            <div class="flex gap-6 pb-16 relative z-20">
                <div
                    class="rounded-full h-16 w-16 flex shrink-0"
                    :class="
                        version.active
                            ? 'bg-denim-700 text-white'
                            : version.isUpdating
                            ? 'bg-cobalt-500 text-white'
                            : 'bg-gray-200'
                    "
                >
                    <span class="m-auto">{{ version.name }}</span>
                </div>
                <div class="pt-1">
                    <div>
                        <p
                            v-if="version.active"
                            class="text-xs bg-cobalt-50 text-cobalt-500 rounded-full px-2 py-0.5 inline-block font-semibold"
                        >
                            Current
                        </p>
                        <p
                            v-else-if="version.isUpdating"
                            class="text-xs bg-cobalt-50 text-cobalt-500 rounded-full px-2 py-0.5 inline-block font-semibold"
                        >
                            Update in progress
                        </p>

                        <h4 class="font-semibold mt-1">{{ version.title }}</h4>
                        <p class="text-sm text-gray-500">
                            {{
                                version.date
                                    ? `Received on ${new Date(version.date).toLocaleDateString()}`
                                    : "You have never had this version"
                            }}
                        </p>
                        <p class="text-sm mt-1 text-gray-700">
                            {{ version.description }}
                        </p>
                        <button
                            v-if="version.updateAvailable"
                            class="text-cobalt-500 font-semibold text-sm block mt-2"
                            @click="showUpdateModal = true"
                        >
                            Update to this version
                        </button>
<!--                        <button-->
<!--                            v-if="!version.active && version.hasResult"-->
<!--                            @click="showPreviousResultsModal(version.name)"-->
<!--                            class="text-cobalt-500 font-semibold text-sm block mt-2"-->
<!--                        >-->
<!--                            See overview of these results-->
<!--                        </button>-->
                    </div>
                </div>
            </div>
        </div>
        <Modal width="xl" :open="showModal" @closed="showModal = false">
            <PageLoader v-if="loadingStatus === 'loading'"> Loading previous result </PageLoader>
            <div v-else-if="loadingStatus === 'failed' && errorMessage === 'no_result'">
                Sorry, we were unable to load that result
            </div>
            <div v-else-if="loadingStatus === 'failed'">Something went wrong</div>
            <div v-else>
                <ResultList :results="previousResult" />
            </div>
        </Modal>
        <ConfirmationModal
            :show-modal="showUpdateModal"
            :type="updateLoadingStatus === 'failed' ? 'error' : 'info'"
            :loading-status="updateLoadingStatus"
            @confirm="startUpdate"
            @cancel="showUpdateModal = false"
        >
            <template #title>{{
                updateLoadingStatus === "failed" ? "Request failed" : "Confirm Update"
            }}</template>
            <template #body>
                <div v-if="updateLoadingStatus === 'failed'">
                    The request to update failed. You can try again below or please contact our
                    support team at
                    <a href="mailto:help@livingdna.com" class="text-cobalt-500 font-semibold"
                        >help@livingdna.com</a
                    >.
                </div>
                <div v-else>
                    This will update your results to this version. You will still be able to see a
                    list of results regional percentages from your old results but your portal will
                    be updated with new results using our latest processes.
                </div>
            </template>
            <template #button>Start update</template>
        </ConfirmationModal>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useAutosomalStore } from "@/store/ancestry/autosomal";
import Modal from "@/components/Modal.vue";
import { useLoading } from "@/composables/useLoading";
import ResultList from "@/components/ancestry/PreviousAncestryResults/ResultList.vue";
import store from "@/store/store";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import AlertMessage from "@/components/AlertMessage.vue";
import axios from "axios";
import endpoints from "@/endpoints";

const autosomalStore = useAutosomalStore();

const showModal = ref(false);
const showUpdateModal = ref(false);
const previousResult = ref({});
const isConsentedToResearch = ref(true);

axios.get(endpoints.research(store.getters.profile.barcode)).then((res) => {
    isConsentedToResearch.value = res.data.consent;
});

const { loadingStatus, finishLoading, resetLoading, failLoading, errorMessage } = useLoading();
const {
    loadingStatus: updateLoadingStatus,
    finishLoading: updateFinishLoading,
    resetLoading: updateResetLoading,
    failLoading: updateFailLoading,
} = useLoading();
updateFinishLoading();

const versions = ref([
    {
        name: "2.1.1",
        title: "Europe and Africa Panel Refinements",
        description:
            "Adjustments to the European admixture method to account for overestimated French, Pannonian, and North Eastern European and adjustments to the African ancestry method.",
    },
    {
        name: "2.1.0",
        title: "Africa Panel",
        description:
            "Add the Africa panel allowing for African results to be broken down into 72 distinct subregions.",
    },
    {
        name: "2.0.0",
        title: "Europe Panel",
        description:
            "Add several new subregions to Europe further breaking down Germany, Iberia, and distinguishing Finland and Russia and also Armenia and Cyprus.",
    },
    {
        name: "1.0.0",
        title: "Initial Release",
        description:
            "Our original methodology. This received multiple updates that were not released as standalone updates.",
    },
]);

const mappedVersions = computed(() => {
    return versions.value.map((v) => {
        const currentResult = autosomalStore.previousVersions.find(
            (pv) => pv.id === autosomalStore.result.activeResult
        );

        const version = autosomalStore.previousVersions.find((pv) => {
            if (pv.panel_version === "2.0.1") {
                return v.name === "2.0.0";
            }
            return pv.panel_version === v.name;
        });

        return {
            ...v,
            active: currentResult.panel_version === v.name,
            hasResult: !!version,
            updateAvailable: store.getters.kitTests.autosomal.updateAvailable === v.name,
            isUpdating:
                store.getters.kitTests.autosomal.status === "updating" &&
                store.getters.kitTests.autosomal.version === v.name,
            date: version?.date,
        };
    });
});

function showPreviousResultsModal(name) {
    resetLoading();
    showModal.value = true;
    autosomalStore
        .getPreviousVersions()
        .then(() => {
            let result;
            if (name === "2.0.0") {
                result = autosomalStore.previousResults["2.0.1"];
            } else {
                result = autosomalStore.previousResults[name];
            }

            if (!result) {
                failLoading("no_result");
            }

            const location = result.testData.autosomal.data.current;
            previousResult.value = location.speculative ? location.speculative : location.bestguess;
        })
        .then(finishLoading);
}

async function startUpdate() {
    updateResetLoading();
    try {
        await autosomalStore.startUpdate();
        showUpdateModal.value = false;
        await store.dispatch("SET_KIT_TESTS", store.getters.profile.barcode);
        updateFinishLoading();
    } catch (e) {
        updateFailLoading();
    }
}
</script>
