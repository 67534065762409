<template>
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 421.56 421.56"
        style="enable-background:new 0 0 421.56 421.56;"
        xml:space="preserve"
    >
        <g>
            <path
                d="M238.465,318.516c-15.264,15.264-40.1,15.264-55.363,0c-3.124-3.124-8.189-3.124-11.313,0
            c-3.125,3.124-3.125,8.189,0,11.313c10.751,10.751,24.873,16.126,38.995,16.126c14.122,0,28.244-5.375,38.995-16.126
            c3.125-3.124,3.125-8.189,0-11.313C246.655,315.392,241.589,315.392,238.465,318.516z"
            />
            <path
                d="M391.823,397.883c-18.74-20.752-19.533-73.63-17.315-128.342c8.163-7.667,13.277-18.545,13.277-30.601
            c0-10.587-3.946-20.264-10.433-27.659c2.726-55.804,4.172-93.122-13.529-127.58c-12.076-23.512-31.616-43.157-58.076-58.389
            C274.908,7.56,239.73-1.154,204.012,0.123c-31.374,1.118-60.583,15.735-80.725,40.26C97.279,41.93,73.62,56.084,60.891,77.96
            c-1.093,1.878-2.15,3.809-3.142,5.74c-17.711,34.477-16.266,71.792-13.54,127.59c-6.483,7.394-10.425,17.067-10.425,27.65
            c0,12.052,5.11,22.927,13.269,30.594c2.218,54.716,1.425,107.598-17.315,128.35c-1.772,1.962-2.46,4.674-1.838,7.244
            c0.622,2.57,2.474,4.667,4.947,5.602C53.94,418.7,74.368,421.55,92.805,421.55c19.153,0,36.157-3.078,49.506-6.688
            c23.195-6.272,39.555-15.076,45.137-18.325c7.597,1.252,15.39,1.915,23.336,1.915c7.944,0,15.735-0.662,23.33-1.914
            c5.583,3.25,21.942,12.052,45.135,18.324c17.807,4.815,34.315,6.698,49.249,6.698c24.877,0,45.382-5.227,60.215-10.832
            c2.474-0.935,4.325-3.031,4.947-5.602C394.283,402.557,393.595,399.846,391.823,397.883z M353.784,255.453v-39.715
            c0.002-0.105,0.002-0.211,0-0.317v-1.217c10.433,3.383,18,13.19,18,24.735c0,11.637-7.685,21.513-18.247,24.819
            C353.695,261.009,353.784,258.242,353.784,255.453z M71.981,91.011c0.865-1.683,1.786-3.367,2.739-5.004
            c10.537-18.109,30.69-29.513,52.594-29.762c0.025,0,0.085-0.001,0.111-0.002c2.43-0.051,4.704-1.203,6.183-3.131
            c17.265-22.521,43.134-36.007,70.974-37c32.719-1.167,64.942,6.81,93.181,23.066c23.745,13.669,41.182,31.108,51.826,51.833
            c14.168,27.58,14.588,57.581,12.265,109.13c-2.572-1.069-5.272-1.886-8.07-2.429v-8.135c0-4.418-3.582-8-8-8s-8,3.582-8,8v18.169
            c-110.199,1.177-169.855-51.902-185.773-68.509c-1.873-1.954-4.607-2.828-7.263-2.325c-2.659,0.503-4.883,2.316-5.912,4.819
            c-11.625,28.278-31.936,43.073-63.918,46.559c-4.059,0.442-7.133,3.87-7.133,7.953v1.468c-2.801,0.543-5.504,1.361-8.078,2.432
            C57.383,148.606,57.806,118.606,71.981,91.011z M68.031,263.759c-10.562-3.306-18.247-13.182-18.247-24.819
            c0-11.546,7.567-21.353,18-24.735v41.248C67.784,258.242,67.873,261.009,68.031,263.759z M48.488,399.143
            c14.266-24.35,16.74-64.829,14.926-120.065c2.124,0.656,4.322,1.14,6.574,1.452c9.099,51.227,45.568,93.105,93.695,109.943
            C140.536,400.433,96.726,413.631,48.488,399.143z M210.784,382.452c-70.028,0-127-56.972-127-127l0.001-52.227
            c30.003-4.937,51.364-19.707,64.905-44.909c11.526,10.515,30.162,24.936,56.426,37.575c39.49,19.004,84.087,28.377,132.668,27.858
            v31.703C337.784,325.481,280.812,382.452,210.784,382.452z M283.952,399.558c-10.258-2.742-19.109-6.055-26.096-9.075
            c48.142-16.83,84.624-58.715,93.725-109.953c2.25-0.312,4.445-0.795,6.567-1.45c-1.815,55.243,0.658,95.722,14.931,120.071
            C344.826,407.686,314.896,407.83,283.952,399.558z"
            />
            <path
                d="M115.164,231.744c-3.125,3.124-3.125,8.189,0,11.313c3.124,3.124,8.189,3.124,11.313,0c5.128-5.126,13.47-5.127,18.598,0
            c1.562,1.562,3.609,2.343,5.657,2.343s4.095-0.781,5.657-2.343c3.125-3.124,3.125-8.189,0-11.313
            C145.024,220.378,126.529,220.377,115.164,231.744z"
            />
            <path
                d="M306.405,243.057c3.125-3.124,3.125-8.189,0-11.313c-11.366-11.366-29.86-11.366-41.225,0
            c-3.125,3.124-3.125,8.189,0,11.313c3.124,3.124,8.189,3.124,11.313,0c5.128-5.127,13.471-5.126,18.598,0
            c1.562,1.562,3.609,2.343,5.657,2.343S304.843,244.619,306.405,243.057z"
            />
        </g>
    </svg>
</template>
