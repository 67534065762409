<template>
    <div>
        <h1 class="font-normal text-2xl">Profile photo</h1>
        <p class="mt-4">
            This image is displayed in your portal and will be visible to other users if you choose
            to participate in our community-facing services.
        </p>
        <AvatarInput :profile="profile" @avatar-saved="handleAvatarSaved" />
    </div>
</template>

<script>
import AvatarInput from "@/components/inputs/UploadInput/AvatarInput/AvatarInput.vue";

export default {
    props: {
        profile: {
            type: Object,
            required: true,
        },
    },
    components: { AvatarInput },
    methods: {
        async handleAvatarSaved() {
            console.log("hanled");
            await this.$store.dispatch("getDefaultProfile");
            this.$router.push({
                name: "profile-view",
                params: {
                    barcode: this.profile.barcode,
                },
            });
        },
    },
};
</script>
