<template>
    <div>
        <div v-if="relationshipNoteState" class="p-6">
            <!--            {{ relationshipNoteState.value }}-->
            <template v-if="matchesAny(['noNoteFound', 'addingNote'])">
                <form @submit.prevent="addNote">
                    <p>You can use the space below to save any notes about your match</p>
                    <RelationshipNotesTextarea>
                        <div class="pt-4">
                            <button
                                v-if="relationshipNoteState.matches('addingNote')"
                                type="submit"
                                class="loading px-6 py-2 bg-cobalt-500 text-white"
                            >
                                Save note
                            </button>
                            <button
                                v-else
                                type="submit"
                                class="rounded px-6 py-2 bg-cobalt-500 text-white"
                            >
                                Save notes
                            </button>
                            <span class="pl-4 text-emerald-500 font-semibold">{{ message }}</span>
                        </div>
                    </RelationshipNotesTextarea>
                </form>
            </template>
            <template v-if="matchesAny(['noteFound', 'updatingNote'])">
                <form @submit.prevent="updateNote">
                    <RelationshipNotesTextarea :body="relationshipNoteState.context.body">
                        <div class="pt-4">
                            <button
                                v-if="relationshipNoteState.matches('updatingNote')"
                                type="submit"
                                class="loading px-6 py-2 bg-cobalt-500 text-white"
                            >
                                Update note
                            </button>
                            <button
                                v-else
                                type="submit"
                                class="rounded px-6 py-2 bg-cobalt-500 text-white"
                            >
                                Update notes
                            </button>
                            <span class="pl-4 text-emerald-500 font-semibold">{{ message }}</span>
                        </div>
                    </RelationshipNotesTextarea>
                </form>
            </template>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { createMachine, assign, interpret } from "xstate";
import endpoints from "@/endpoints";
import RelationshipNotesTextarea from "./RelationshipNotesTextarea.vue";

const relationshipNotesMachine = createMachine(
    {
        initial: "idle",
        context: {
            body: undefined,
        },
        states: {
            idle: {
                on: {
                    FETCH_NOTE: "fetchingNote",
                },
            },
            fetchingNote: {
                invoke: {
                    src: "fetchNote",
                    onDone: [
                        {
                            cond: "noteEmpty",
                            target: "noNoteFound",
                        },
                        {
                            cond: "noteExists",
                            target: "noteFound",
                        },
                    ],
                    onError: "error",
                },
            },
            noteFound: {
                entry: assign({
                    body: (_, event) => event.data.data.body,
                }),
                on: {
                    UPDATE_NOTE: "updatingNote",
                },
            },
            noNoteFound: {
                on: {
                    ADD_NOTE: "addingNote",
                },
            },
            addingNote: {
                invoke: {
                    src: "addNote",
                    onDone: "noteFound",
                    onError: "error",
                },
            },
            updatingNote: {
                invoke: {
                    src: "updateNote",
                    onDone: "noteFound",
                    onError: "error",
                },
            },
            error: {},
        },
    },
    {
        services: {
            fetchNote: (_, event) => axios.get(endpoints.relationshipNotesByBarcode(event.subject)),
            addNote: (_, { subject, createdBy, body }) =>
                axios.post(endpoints.relationshipNotes, {
                    relationship_note: {
                        subject,
                        createdBy,
                        body,
                    },
                }),
            updateNote: (_, { subject, body }) =>
                axios.put(endpoints.relationshipNotesByBarcode(subject), { body }),
        },
        guards: {
            noteEmpty: (_, event) => event.data.status === 204,
            noteExists: (_, event) => event.data.status === 200,
        },
    }
);

export default {
    data() {
        return {
            relationshipNoteService: undefined,
            relationshipNoteState: undefined,
            message: undefined,
        };
    },
    created() {
        this.relationshipNoteService = interpret(relationshipNotesMachine).start();
        this.relationshipNoteService.send("FETCH_NOTE", { subject: this.$route.params.barcode });
        this.relationshipNoteService.onTransition((state) => {
            this.relationshipNoteState = state;
            if (["addingNote", "updatingNote"].includes(state.history.value)) {
                if (state.value === "noteFound") {
                    this.displaySuccessMessage();
                }
            }
        });
    },
    methods: {
        addNote(event) {
            const data = new FormData(event.target);
            this.relationshipNoteService.send("ADD_NOTE", {
                subject: this.$route.params.barcode,
                createdBy: this.$store.getters.profile.barcode,
                body: data.get("content"),
            });
        },
        updateNote(event) {
            const data = new FormData(event.target);
            this.relationshipNoteService.send("UPDATE_NOTE", {
                subject: this.$route.params.barcode,
                body: data.get("content"),
            });
        },
        matchesAny(states) {
            return states.some(this.relationshipNoteState.matches);
        },
        displaySuccessMessage() {
            this.message = "Your note was saved";
            setTimeout(() => {
                this.message = undefined;
            }, 3000);
        },
    },
    unmounted() {
        this.relationshipNoteService.stop();
    },
    components: { RelationshipNotesTextarea },
};
</script>
