<template>
    <svg
        id="light"
        enable-background="new 0 0 24 24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m23.5 9h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h4.5v-4.5c0-.276.224-.5.5-.5s.5.224.5.5v5c0 .276-.224.5-.5.5z"
        />
        <path
            d="m.5 21c-.276 0-.5-.224-.5-.5v-5c0-.276.224-.5.5-.5h5c.276 0 .5.224.5.5s-.224.5-.5.5h-4.5v4.5c0 .276-.224.5-.5.5z"
        />
        <path
            d="m.601 11c-.021 0-.043-.001-.065-.004-.273-.036-.467-.287-.432-.561.77-5.949 5.884-10.435 11.896-10.435 5.396 0 10.433 3.511 11.977 8.348.084.263-.062.544-.324.628-.267.084-.545-.062-.629-.325-1.415-4.433-6.051-7.651-11.024-7.651-5.511 0-10.198 4.112-10.904 9.564-.033.252-.248.436-.495.436z"
        />
        <path
            d="m12 24c-5.407 0-10.444-3.511-11.977-8.349-.083-.263.063-.544.325-.627.268-.084.545.062.628.326 1.405 4.432 6.041 7.65 11.024 7.65 5.511 0 10.198-4.112 10.904-9.564.036-.274.277-.462.561-.432.273.036.467.287.432.561-.771 5.949-5.885 10.435-11.897 10.435z"
        />
        <path
            d="m10.573 20c-.059 0-.117-.01-.175-.032-.219-.081-.352-.303-.321-.534l.853-6.434h-3.43c-.189 0-.362-.107-.447-.276s-.067-.372.047-.524l6-8c.139-.186.386-.25.597-.16.212.09.335.314.297.542l-.904 5.418h3.41c.184 0 .353.101.44.263s.078.359-.022.512l-5.927 9c-.095.143-.254.225-.418.225zm-2.073-8h3c.145 0 .281.062.376.17s.139.252.12.395l-.641 4.838 4.217-6.403h-3.072c-.147 0-.286-.064-.382-.177-.095-.112-.136-.26-.111-.405l.663-3.978z"
        />
    </svg>
</template>
