import axios from "axios";
import endpoints from "@/endpoints";

export function optIn(barcode) {
    return axios.put(endpoints.allConsents(barcode), {
        fnConsent: true,
    });
}

export const getMatches = async (barcode, { sortBy, query, page, itemsPerPage }) => {
    try {
        const { data: matchingDetails } = await axios.get(endpoints.getMatches(barcode), {
            params: {
                sortBy,
                query,
                page,
                itemsPerPage,
            },
        });
        return matchingDetails;
    } catch (e) {
        throw e;
    }
};

export const getMatchDetails = async (currentBarcode, matchBarcode) => {
    try {
        const { data: matchDetails } = await axios.get(
            endpoints.getMatch(currentBarcode, matchBarcode)
        );
        return {
            ...matchDetails,
            dnaShared: (Math.round(matchDetails.dnaShared * 100) / 100).toFixed(2),
        };
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const getSharedMatches = async (currentBarcode, matchBarcode) => {
    try {
        const { data: sharedMatches } = await axios.get(
            endpoints.getSharedMatches(currentBarcode, matchBarcode)
        );
        return sharedMatches;
    } catch (e) {
        throw e;
    }
};

export const getSharedDna = async (currentProfileId, matchProfileId) => {
    try {
        const { data: sharedDna } = await axios.get(
            endpoints.getSharedDna(currentProfileId, matchProfileId)
        );
        return sharedDna;
    } catch (e) {
        throw e;
    }
};

export const getMessages = async channel => axios.get(endpoints.getMessages(channel));

export const sendMessage = async (channel, { message, sender_type }) => {
    try {
        const { data } = await axios.post(endpoints.sendMessage, {
            channel,
            message,
            sender_type,
        });
        return data;
    } catch (e) {
        throw e;
    }
};

export const getBlocked = async channel => {
    const { data } = await axios.get(endpoints.getBlocked(channel));
    return data;
};

export const blockChannel = async (channel, block) => {
    try {
        const { data } = await axios.post(endpoints.blockChannel, {
            channel,
            block,
        });
        return data;
    } catch (e) {
        throw e;
    }
};

export const getNotifications = async () => {
    try {
        const { data } = await axios.get(endpoints.getNotifications);
        return data;
    } catch (e) {
        throw e;
    }
};
