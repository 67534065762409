<template>
    <div v-if="isLoading">
        <PageLoader class="mt-12"> Loading your wellbeing results </PageLoader>
    </div>
    <ReportFailedToLoad v-else-if="errors.length" />
    <WellbeingReport
        v-else
        :phenotype-data="report.phenotypeData"
        :trait-data="report.traitData"
        :has-snps="hasSnps"
    />
</template>

<script>
import { hasSnps } from "@/services/wellbeing/wellbeingHelpers";
import WellbeingReport from "@/views/wellbeing/WellbeingReport.vue";
import WellbeingService from "@/services/wellbeing/wellbeing";
import ReportFailedToLoad from "@/components/Wellbeing/ReportFailedToLoad.vue";
import PageLoader from "@/components/PageLoader.vue";

export default {
    components: { WellbeingReport, ReportFailedToLoad, PageLoader },
    data() {
        return {
            report: {},
            isLoading: true,
            errors: [],
        };
    },
    async beforeRouteUpdate(to, from, next) {
        this.loading = true;
        this.report = await this.getReport(to.params.report);
        this.isLoading = false;
        next();
    },
    async created() {
        try {
            await this.$store.dispatch("fetchWellbeingData", this.$store.getters.profile.barcode);
            this.report = await this.getReport(this.$route.params.report);
        } catch (e) {
            this.errors.push(e);
        }
        this.isLoading = false;
    },
    computed: {
        barcode() {
            return this.$store.getters.profile.barcode;
        },
        hasSnps() {
            return hasSnps(this.report);
        },
    },
    methods: {
        async getReport(reportSlug) {
            try {
                const report = await WellbeingService.getReport(this.barcode, reportSlug);
                return {
                    phenotypeData: JSON.parse(report.phenotypeCmsData),
                    traitData: JSON.parse(report.traitCmsData),
                    totalPhenotypeSlug: report.totalPhenotypeSlug,
                };
            } catch (e) {
                throw e;
            }
        },
    },
};
</script>
