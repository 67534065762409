<template>
    <button
        @click="$emit('unblock-conversation')"
        v-if="blockState.matches('conversationBlocked')"
        class="text-rose-500 font-semibold text-sm"
    >
        Unblock this profile
    </button>
    <button
        v-else-if="
            blockState.matches('blockingConversation') || blockState.matches('unblockingChannel')
        "
        class="loading loading-rose-500"
    >
        Block profile
    </button>
    <button v-else @click="$emit('block-conversation')" class="text-rose-500 font-semibold text-sm">
        Block this profile
    </button>
</template>

<script>
export default {
    props: {
        blockState: {
            type: Object,
            required: true,
        },
    },
    emits: ["blockConversation", "unblockConversation"],
};
</script>
