<template>
    <template v-if="loadingStatus === 'loaded'">
        <portal to="navigation">
            <ArchaicNavigation />
        </portal>
        <router-view v-if="archaicResult.barcode" :archaic-result="archaicResult" />
    </template>
    <ArchaicStatus v-else :status="loadingStatus" />
</template>

<script>
import { ref } from "vue";
import ArchaicNavigation from "./ArchaicNavigation.vue";
import { getArchaicResult } from "@/services/archaic";
import store from "@/store/store";
import ArchaicStatus from "./ArchaicStatus.vue";

const actions = {
    completed: (loadingStatus, archaicResult) => {
        getArchaicResult(store.getters.profile.barcode)
            .then((res) => {
                archaicResult.value = JSON.parse(res.results);
            })
            .then(() => (loadingStatus.value = "loaded"))
            .catch((e) => {
                console.error(e);
                loadingStatus.value = "loadingFailed";
            });
        loadingStatus.value = "loaded";
    },
    updating: (loadingStatus) => {
        loadingStatus.value = "updating";
    },
    error: (loadingStatus) => {
        loadingStatus.value = "testErrored";
    },
    failed: (loadingStatus) => {
        loadingStatus.value = "testFailed";
    },
    pending: (loadingStatus) => {
        loadingStatus.value = "resultPending";
    },
    noData: (loadingStatus) => {
        loadingStatus.value = "testNoData";
    },
    processing: (loadingStatus) => {
        loadingStatus.value = "resultPending";
    },
};

export default {
    components: { ArchaicNavigation, ArchaicStatus },
    setup() {
        const loadingStatus = ref("loading");
        const archaicResult = ref({});
        if (false) {
            loadingStatus.value = "transferQcFailed";
        } else {
            if (store.getters.kitTests && store.getters.kitTests.archaic) {
                if (!store.getters.kitTests.archaic.purchased) {
                    loadingStatus.value = "notPurchased";
                } else if (actions[store.getters.kitTests.archaic.status]) {
                    const action = actions[store.getters.kitTests.archaic.status];
                    action(loadingStatus, archaicResult);
                }
            } else {
                loadingStatus.value = "failed";
            }
        }

        // store.subscribeAction({
        //     after: async ({ type }) => {
        //         switch (type) {
        //             case "SET_KIT_TESTS":
        //                 if (store.getters.kitTests && store.getters.kitTests.archaic) {
        //                     if (!store.getters.kitTests.archaic.purchased) {
        //                         loadingStatus.value = "notPurchased";
        //                     } else if (actions[store.getters.kitTests.archaic.status]) {
        //                         const action = actions[store.getters.kitTests.archaic.status];
        //                         action(loadingStatus, archaicResult);
        //                     }
        //                 } else {
        //                     loadingStatus.value = "failed";
        //                 }
        //         }
        //     },
        // });

        return { loadingStatus, archaicResult };
    },
};
</script>
