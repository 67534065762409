<template>
    <div>
        <ResultListItem v-for="result in results" :key="result.id" :result="result" />
    </div>
</template>

<script>
import ResultListItem from "@/components/ancestry/PreviousAncestryResults/ResultListItem";
export default {
    components: { ResultListItem },
    props: {
        results: Array,
    },
};
</script>
