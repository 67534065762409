<template>
    <div>
        <div class="mt-3" v-for="item in region.children">
            <RecentAncestryRegionSectionTitle :item="item" />
            <RecentAncestrySubregionItem
                v-for="subregion in getChildrenToDisplay(item.children)"
                :subregion="subregion"
            />
            <div
                v-if="shouldLimitChildren(item.children)"
                class="pl-10 text-sm text-gray-600 mt-1 -ml-1"
            >
                + {{ item.children.length - 3 }} regions
            </div>
        </div>
    </div>
</template>

<script setup>
import RecentAncestrySubregionItem from "@/components/ancestry/Recent/RecentAncestrySubregionItem.vue";
import RecentAncestryRegionSectionTitle from "@/components/ancestry/Recent/RecentAncestryRegionSectionTitle.vue";
import store from "@/store/store";

function shouldLimitChildren(children) {
    return store.getters.starterTestMode && children.length > 3;
}
function getChildrenToDisplay(children) {
    return shouldLimitChildren(children) ? children.slice(0, 3) : children;
}

defineProps({ region: Object });
</script>
