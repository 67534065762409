<template>
    <div :class="{ 'flex-row-reverse': isSender }" class="flex mb-4">
        <div :class="{ 'arrow-left': !isSender, 'arrow-right': isSender }" class="mt-4"></div>
        <div class="sm:w-2/3">
            <div
                :class="{ 'bg-grey-100': !isSender, 'bg-cobalt-500 text-white': isSender }"
                class="rounded-lg p-4"
            >
                <span
                    v-if="!isSender"
                    class="py-1 px-3 rounded-lg text-grey-900 text-xs sm:text-sm mb-4"
                    :class="{ 'bg-grey-200': !isSender, 'bg-cobalt-500': isSender }"
                    >{{ messageisSenderType }} - {{ profileName }}</span
                >
                <p
                    class="my-0 whitespace-pre-line"
                    :class="{ 'mt-2 ml-2 text-grey-900': !isSender, 'text-white': isSender }"
                    v-html="message.message"
                ></p>
            </div>
            <div
                :class="{ 'text-right': isSender }"
                class="text-sm mt-1"
                :title="`${moment(message.createdAt).format('HH:mm')} on ${moment(
                    message.createdAt
                ).format('Do MMM YYYY')}`"
            >
                {{ moment(message.createdAt).fromNow() }}
            </div>
        </div>
    </div>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from "vuex";
export default {
    name: "family-matching-message",
    data() {
        return {
            moment,
        };
    },
    props: {
        message: {
            type: Object,
            required: true,
        },
        profileName: {
            type: String,
            // required: true,
        },
    },
    computed: {
        ...mapGetters(["account"]),
        messageSenderType() {
            return this.message.sender_type === "MANAGER" ? "Manager" : "Owner";
        },
        isSender() {
            return this.account.uuid === this.message.sender_id;
        },
    },
};
</script>

<style scoped>
.arrow-left {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 15px solid hsl(210, 25%, 95%);
}
.arrow-right {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 15px solid hsl(220, 100%, 60%);
}
</style>
