<template>
    <!--    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--        <path-->
    <!--            d="M3.4868 6.5L12 1.17925L20.5132 6.5H3.4868Z"-->
    <!--            class="stroke-current fill-none"-->
    <!--            stroke-width="2"-->
    <!--        />-->
    <!--        <rect x="5" y="9" width="2" height="8" class="fill-current" />-->
    <!--        <rect width="2" height="18" transform="matrix(0 1 1 0 3 18)" class="fill-current" />-->
    <!--        <rect width="2" height="22" transform="matrix(0 1 1 0 1 21)" class="fill-current" />-->
    <!--        <rect x="9" y="9" width="2" height="8" class="fill-current" />-->
    <!--        <rect x="13" y="9" width="2" height="8" class="fill-current" />-->
    <!--        <rect x="17" y="9" width="2" height="8" class="fill-current" />-->
    <!--    </svg>-->
    <svg class="fill-current" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.3354 0.824008L23.072 6.14476C23.2085 6.21242 23.2397 6.27478 23.2449 6.28669C23.2502 6.29891 23.2535 6.31386 23.2436 6.34134C23.2254 6.39165 23.1087 6.57075 22.7366 6.57075H1.26341C0.891312 6.57075 0.774594 6.39165 0.756395 6.34134C0.746456 6.31386 0.749751 6.29892 0.755098 6.28669C0.760307 6.27478 0.791493 6.21242 0.928019 6.14476L11.6646 0.824008C11.8637 0.725331 12.1363 0.725331 12.3354 0.824008Z"
            class="stroke-current fill-none"
            stroke-width="1.5"
        />
        <rect x="5" y="9" width="2" height="9" rx="1" />
        <rect width="2" height="18" rx="1" transform="matrix(0 1 1 0 3 19)" />
        <rect width="2" height="22" rx="1" transform="matrix(0 1 1 0 1 22)" />
        <rect x="9" y="9" width="2" height="9" rx="1" />
        <rect x="13" y="9" width="2" height="9" rx="1" />
        <rect x="17" y="9" width="2" height="9" rx="1" />
    </svg>
</template>
