<template>
    <div v-if="isUploader">
        <div class="p-6 bg-grey-50 h-full w-full">
            <main class="md:max-w-3xl lg:max-w-6xl mx-auto">
                <div class="shadow rounded-lg p-6 bg-white mb-12">
                    <div class="bg-cobalt-50 text-cobalt-500 rounded-lg p-4">
                        <p class="m-0">
                            Because you transferred your DNA file from another testing company you
                            are unable to download your DNA data from us. If you require the raw
                            data then you should redownload it from the original testing company.
                        </p>
                    </div>
                    <router-link :to="{ name: 'profiles' }" class="mt-6 block flex items-center">
                        <Feather type="chevron-left" class="h-5 w-5 fill-current mr-2" />
                        Back to profiles
                    </router-link>
                </div>
            </main>
        </div>
    </div>
    <div v-else-if="downloadEnabled">
        <div class="p-6 bg-grey-50 h-full w-full">
            <main class="md:max-w-3xl lg:max-w-6xl mx-auto">
                <router-link
                    :to="{ name: 'profile-view', barcode: $route.params.barcode }"
                    class="mt-6 block flex items-center"
                >
                    <Feather type="chevron-left" class="h-5 w-5 fill-current mr-2" />
                    Back to profiles
                </router-link>
                <div class="flex items-center container mx-auto px-4 text-denim-700">
                    <Feather type="download" class="h-6 w-6 fill-current" />
                    <h1 class="ml-4 text-2xl font-normal my-12">
                        Download raw data
                    </h1>
                </div>

                <div class="shadow rounded-lg p-6 bg-white mb-12">
                    <p>
                        At Living DNA, our first principle is that you are in control of your DNA
                        privacy. You can, therefore, download your DNA data at any time. We also
                        care that you understand what it is that you are downloading, and the power
                        of that information. If you wish to download your data, please first take a
                        moment to read the information below then click the checkbox to confirm your
                        consent.
                    </p>

                    <h2 class="text-lg mt-8">Defining "raw DNA data"</h2>
                    <h3 class="text-base mt-1 text-grey-700">
                        Not a copy of your ancestry or wellbeing results
                    </h3>

                    <p>
                        Your raw DNA data is a text-only data file that contains a select list of
                        your genetic markers and their resulting genotypes. It is the data Living
                        DNA uses to determine where your ancestry is from, what wellbeing traits you
                        might have, and more.
                    </p>
                    <p>
                        This file is not a copy of your ancestry or wellbeing results. It does not
                        contain your regional percentages, nutrition traits, or other wellbeing
                        likelihoods.
                    </p>

                    <h3 class="text-base mt-1 text-grey-700">Not suitable for medical diagnosis</h3>

                    <p>
                        Our DNA ancestry and wellbeing tests do not examine your entire genome and
                        is not intended for medical diagnosis. This raw data file only contains the
                        680,000 markers selected for analysis by Living DNA that are relevant to
                        Living DNA’s ancestry and wellbeing results processing. This file cannot be
                        relied on to provide sufficiently accurate data for purposes other than
                        those the test was designed or validated for.
                    </p>

                    <h2 class="text-lg mt-8">Privacy awareness</h2>
                    <p>
                        Controlling who sees your raw DNA data is important. Here are some things to
                        consider when wielding the power of your DNA:
                    </p>
                    <ol class="list-decimal pl-6">
                        <li class="pb-1 mt-4">
                            Your raw DNA data ceases to be protected by Living DNA’s strict privacy
                            values when uploaded to third-party sites and software tools. If you
                            choose to download your Living DNA data file and upload it to another
                            website or tool, please consider their privacy standards and whether
                            they are right for you.
                        </li>
                        <li class="pb-1 mt-4">
                            <p>
                                Your raw DNA data may reveal unreliable or unwanted information when
                                shared with third-party websites, software tools, and health
                                practitioners, such as:
                            </p>
                            <ul class="list-disc pl-6">
                                <li class="py-1">
                                    Your genetic relationship to other peoples in a database,
                                    including adoptees and criminal suspects
                                </li>
                                <li class="py-1">
                                    Your genetic predisposition to various health conditions, which
                                    may be observed by pharmaceutical companies and insurers
                                </li>
                            </ul>
                        </li>
                        <li class="py-1">
                            Genetic science is a relatively new field that is developing quickly.
                            This means that what you can learn from your genetic information in the
                            future is likely to increase significantly, and potentially in ways that
                            we cannot currently predict.
                        </li>
                    </ol>
                    <h2 class="text-lg mt-8">Responsibility</h2>
                    <p>
                        It is your choice whether or not to download your genetic data, and what you
                        do with it once you access it. Living DNA is not responsible for your data
                        and our use of it once you have chosen to download it, and accept no
                        liability for any consequences to you nor to any third party which happen
                        because of or which are related to your use or disclosure of your genetic
                        data.
                    </p>

                    <h2 class="text-lg mt-8">Consent</h2>

                    <label class="flex items-center cursor-pointer">
                        <div class="px-2 md:px-6">
                            <input class="cursor-pointer" type="checkbox" v-model="consent" />
                        </div>

                        <p class="text-sm">
                            I have read the information provided about gaining access to my genetic
                            data. I understand that I am solely responsible for my use or disclosure
                            of my genetic data, and for all consequences that flow from me
                            downloading, using or disclosing my data. I release Living DNA from any
                            liability which it may have as a consequence of me downloading, using or
                            disclosing my genetic data.
                        </p>
                    </label>

                    <div class="mt-4 pt-4 border-t-2 border-grey-200" v-if="consent">
                        <div v-if="isError">
                            <p class="error my-3 p-3 rounded bg-rose-50 text-rose-500 inline-block">
                                Sorry there was a problem downloading your {{ whichError }} data.
                                Please try again later.
                            </p>
                        </div>

                        <div>
                            <button
                                class="download-button"
                                @click="getDownloadTestFile('autosomal')"
                            >
                                <i class="fa fa-download"></i>

                                Download autosomal (family ancestry) raw marker data
                            </button>
                        </div>

                        <div>
                            <button class="download-button" @click="getDownloadTestFile('mtdna')">
                                <i class="fa fa-download"></i>

                                Download mtDNA (motherline) list of positive SNPs
                            </button>
                        </div>

                        <div v-if="gender === 'male'">
                            <button class="download-button" @click="getDownloadTestFile('ydna')">
                                <i class="fa fa-download"></i>

                                Download Y-DNA (fatherline) list of positive SNPs
                            </button>
                        </div>
                    </div>

                    <div v-else class="bg-grey-50 rounded-lg p-16 flex items-center">
                        <Feather type="lock" class="mr-2 text-grey-600" />
                        You need to consent to the terms above before being able to download your
                        raw data
                    </div>

                    <a
                        :href="download.href"
                        :download="download.fileName"
                        ref="downloadLink"
                        hidden
                    />
                </div>
            </main>
        </div>
    </div>
    <div v-else>
        <div class="flex justify-center">
            <p class="bg-grey-50 rounded-lg w-full my-16 p-8 max-w-xl">
                Data downloading is currently in maintenance mode. Once we're finished you'll be
                able to download your raw data again.
                <router-link
                    class="block font-semibold mt-4"
                    :to="{ name: 'profile-view', barcode: $route.params.barcode }"
                    >Back to profile</router-link
                >
            </p>
        </div>
    </div>
</template>

<script>
import Feather from "vue-feather";
import DownloadsService from "@/services/downloads";

export default {
    name: "download",
    components: { Feather },
    data: () => ({
        consent: false,
        isError: false,
        whichError: "",
        download: {
            href: "",
            fileName: "",
        },
    }),
    methods: {
        getDownloadTestFile(type) {
            DownloadsService.getDownloadTestFile(this.selectedTest.uuid, type)
                .then(response => {
                    const blob = new Blob([response]);
                    const fileName = `${type}.${type === "autosomal" ? "txt" : "csv"}`;

                    if (!window.navigator.msSaveOrOpenBlob) {
                        this.download.href = window.URL.createObjectURL(blob);
                        this.download.fileName = fileName;
                        this.simulateClick();
                    } else {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    }
                })
                .catch(() => {
                    this.isError = true;

                    switch (type) {
                        case "autosomal":
                            this.whichError = "Autosomal";
                            break;
                        case "mtdna":
                            this.whichError = "mtDNA";
                            break;
                        case "ydna":
                            this.whichError = "Y-DNA";
                            break;
                        default:
                            break;
                    }
                });
        },
        simulateClick() {
            this.$nextTick(() => {
                this.$refs.downloadLink.click();
            });
        },
    },
    computed: {
        selectedTest() {
            return this.$store.getters.accountTests.find(
                t => t.barcode === this.$route.params.barcode
            );
        },
        gender() {
            return this.selectedTest.participantGender;
        },
        isUploader() {
            return this.selectedTest.barcode.substr(0, 2) === "LT";
        },
        downloadEnabled() {
            if (String(process.env.VUE_APP_DISABLE_DATA_DOWNLOADS) === "false") {
                return true;
            }
            if (String(process.env.VUE_APP_DISABLE_DATA_DOWNLOADS) === "true") {
                return false;
            }
            console.warn(
                'Downloads enabled by default, set VUE_APP_DISABLE_DATA_DOWNLOADS to the string "true" in order to disable'
            );
            return true;
        },
    },
    watch: {
        consent() {
            this.$nextTick(() => {
                const scrollContainer = document.querySelector(".scroll-container");
                if (scrollContainer) {
                    scrollContainer.scrollTo(0, scrollContainer.scrollHeight);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.download-button {
    @apply bg-denim-700 text-white py-2 px-4 my-2 rounded inline-block;
}
</style>
