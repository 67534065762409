<template>
    <portal to="sidebar">
        <Sidebar class="bg-white">
            <template v-if="!$route.params.groupId">
                <div
                    class="lg:block fade-bottom bg-center bg-cover bg-gray-200"
                    :style="{ 'background-image': `url(${image})`, minHeight: '256px' }"
                />
                <RecentAncestryRegionList
                    :regions="autosomalStore.result.regions"
                    class="py-6 mx-6"
                />
            </template>

            <RecentAncestryDetails v-else />
        </Sidebar>
    </portal>

    <RecentAncestryMap :autosomal-result="autosomalStore.result" />

    <MobileContent :title="'Recent Ancestry Map'" :pad="false">
        <RecentAncestryRegionList
            v-if="!$route.params.groupId"
            :regions="autosomalStore.result.regions"
            class="py-6 px-6"
        />
        <RecentAncestryDetails v-else />
    </MobileContent>
</template>

<script setup>
import { watch, ref } from "vue";
import Sidebar from "@/components/Sidebar.vue";
import MobileContent from "@/components/ancestry/MobileContent.vue";
import RecentAncestryMap from "@/views/ancestry/recent/RecentAncestryMap.vue";
import { useRoute } from "vue-router";
import RecentAncestryRegionList from "@/components/ancestry/Recent/RecentAncestryRegionList.vue";
import RecentAncestryDetails from "@/views/ancestry/recent/RecentAncestryDetails.vue";
import { useAutosomalStore } from "@/store/ancestry/autosomal";
import Autosomal from "@/services/autosomal";
import store from "@/store/store";

const route = useRoute();
const autosomalStore = useAutosomalStore();
const image = ref(false);
const topResult = autosomalStore.result.regions[0].groupId;

Autosomal.getHistory({
    barcode: store.getters.profile.barcode,
    groupId: topResult,
})
    .then((res) => {
        const something = res.testData[topResult];
        image.value = something.images[0];
        // debugger;
        // finishLoading();
    })
    .catch((err) => {
        debugger;
    });
</script>
