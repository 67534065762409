<template>
    <div class="flex relative h-screen">
        <img :src="image" class="m-auto" alt="" />
        <div class="flex absolute inset-0 flex" style="background-color: rgba(0,0,0,0.3);">
            <div class="rounded-lg overflow-hidden shadow-key max-w-sm bg-white m-auto">
                <div class="h-1 bg-pacific-500"></div>
                <div class="p-6">
                    <h2 class="text-sm">What will you discover?</h2>
                    <p class="mt-2 mb-0">
                        Take the Living DNA test or upgrade your kit to reveal your genetic
                        attributes for nutrition and fitness.
                    </p>
                    <Link
                        link-text="Buy now"
                        icon="shopping-cart"
                        :to="{ name: 'product', params: { slug: 'Wellbeing-Upgrade' } }"
                        class="mt-6"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import image from "@/assets/images/wellbeing/Kit-Explodes-wellbeing.png";
import Link from "@/components/utilities/DashboardLinkWithIcon.vue";

export default {
    components: { Link },
    data() {
        return {
            image,
        };
    },
};
</script>
