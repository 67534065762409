<template>
    <div class="flex pb-4 items-center">
        <div class="w-1/2 text-grey-700">
            {{ report.traitCmsData.title }}
        </div>
        <div class="w-1/2 text-right">
            <div
                class="my-0 inline-block rounded font-semibold //text-sm text-grey-500 flex items-center"
            >
                <Feather type="loader" class="fill-current ml-auto mr-1 h-4 w-4" />
                Preparing
            </div>
        </div>
    </div>
</template>

<script>
import Feather from "vue-feather";

export default {
    components: { Feather },
    props: {
        report: Object,
    },
};
</script>
