<template>
    <div class="max-w-xs m-auto rounded">
        <div class="bg-white shadow-key rounded">
            <div class="px-6 py-4 flex text-grey-500 text-sm justify-center">
                <div class="text-center">Positive markers</div>
            </div>
            <div class="px-6 bg-grey-50 pt-6 rounded-b">
                <div :key="marker" class="flex pb-6" v-for="marker in markers">
                    <div class="text-center">{{ marker }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        markers: {
            type: Array,
            required: true,
        },
    },
};
</script>
