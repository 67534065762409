import Vue from "vue";
import moment from "moment";
import endpoints from "@/endpoints";
import axios from "axios";

export const formatDate = (date) => moment(date).format("MM/DD/YYYY");

async function saveField(profile, field) {
    try {
        const { data } = await axios.put(`${endpoints.profile}/${profile.barcode}`, {
            profile: {
                ...profile,
                ...field,
            },
        });
        return data;
    } catch (e) {
        console.log("Profile save error", e);
        throw e;
    }
}

class ProfileService {
    static async getDefaultProfile() {
        const {
            data: { profile },
        } = await axios.get(`${endpoints.profile}`);
        return profile;
    }

    static async getProfiles() {
        const { data } = await axios.get(`${endpoints.profiles}`);
        return data;
    }

    static async setDefaultProfile(id) {
        const { data } = await axios.put(`${endpoints.profile}/default/${id}`);
        return data;
    }

    static async getFullProfile(barcode) {
        const { data } = await axios.get(`${endpoints.profile}/${barcode}`);
        return data;
    }

    static async editProfile(barcode, payload) {
        const { data } = await axios.put(`${endpoints.profile}/${barcode}`, payload);
        return data;
    }

    static async editFamilyMatchingConsent(barcode, payload) {
        const { data } = await axios.put(endpoints.allConsents(barcode), payload);
        return data;
    }

    static async reuploadDnaFile(barcode, file) {
        console.log("service", barcode, file);
        const { data } = await axios.put(endpoints.reuploadDnaFile, {
            barcode,
            file,
        });
        return data;
    }

    static async saveName(barcode, name, profile) {
        return saveField(profile, { name });
    }

    static async saveAvatar(profile, photo) {
        try {
            const { data } = await axios.put(`${endpoints.profile}/${profile.barcode}`, {
                profile: {
                    ...profile,
                    photo,
                },
            });
            return data;
        } catch (e) {
            console.log("Profile save error", e);
            throw e;
        }
    }

    static async saveBio(profile, bio) {
        return saveField(profile, bio);
    }

    static async checkPassword(username, password) {
        try {
            const response = await fetch(endpoints.checkPassword, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ _username: username, _password: password }),
            });
            if (!response.ok) {
                throw new Error();
            }
        } catch (e) {
            throw e;
        }
    }

    static async deleteAvatar(barcode) {
        try {
            await axios.delete(endpoints.avatar(barcode));
        } catch (e) {
            console.log("there was an error", e);
        }
    }

    static async getFamilyNetworkSharingPermissions(barcode) {
        try {
            const { data } = await axios.get(endpoints.familyNetworkSharingPermissions(barcode));
            return data;
        } catch (e) {
            console.error(`Error fetching fn sharing permissions for ${barcode}`, e);
            throw new Error(e);
        }
    }

    static async setFamilyNetworkSharingPermissions({ barcode, field, value }) {
        try {
            const { data } = await axios.post(endpoints.familyNetworkSharingPermissions(barcode), {
                [field]: value,
            });
            return data;
        } catch (e) {
            console.error(`Error fetching fn sharing permissions for ${barcode}`, e);
            throw new Error(e);
        }
    }
}

export default ProfileService;

export const isUnder18 = (profile) =>
    moment().diff(moment(profile.dateOfBirth), "years", false) >= 18;
