import ProductService from "@/services/product";
import UsersService from "@/services/users";

const initialState = () => ({
    product: {
        title: "",
        type: "",
        design: "",
        category: "",
        shortDescription: "",
        errorText: "",
        longDescription: "",
        partnerProduct: false,
        faqs: [],
        colours: [],
        sizes: [],
        images: [],
        prices: [],
        listItems: [],
        variants: [],
        shippable: false,
    },
    activeVariant: {},
    insightsValidated: true,
    defaultAddress: {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        city: "",
        zip: "",
        phone: "",
        countryCode: "",
    },
    products: [],
});

export default {
    state: initialState(),
    getters: {
        activeProduct: state => state.product,
        activeProductFAQs: state => state.product.faqs,
        mainProductImage: state => state.product.images.find(image => image.main),
        productImages: state => state.product.images,
        activeVariant: state => state.activeVariant,
        selectedPrice: state => {
            if (state.activeVariant.price && state.activeVariant.price.isOnSale) {
                return state.activeVariant.price.sale / 100;
            }
            if (state.activeVariant.price && state.activeVariant.price.base) {
                return state.activeVariant.price.base / 100;
            }
            return 0;
        },
        selectedPriceUnit: state => {
            if (state.activeVariant.price && state.activeVariant.price.isOnSale) {
                return state.activeVariant.price.sale;
            }
            if (state.activeVariant.price && state.activeVariant.price.base) {
                return state.activeVariant.price.base;
            }
            return 0;
        },
        defaultAddress: state => state.defaultAddress,
        insightsValidated: state => state.insightsValidated,
        products: state => state.products,
    },
    mutations: {
        setProduct(state, product) {
            state.product = {
                ...product,
            };
        },
        setActiveVariant(state, activeVariant) {
            state.activeVariant = {
                ...activeVariant,
                shippable: activeVariant.shippable,
            };
        },
        setProductListItems(state, listItems) {
            state.product.listItems = [...listItems];
        },
        setProductMainImage(state, image) {
            state.product.images = [
                ...state.product.images.map(i => {
                    if (i.id === image.id) {
                        Object.assign(i, { main: true });
                    } else {
                        Object.assign(i, { main: false });
                    }
                    return {
                        ...i,
                    };
                }),
            ];
        },
        setDefaultAddress(state, address) {
            state.defaultAddress = { ...address };
        },
        setInsightsValidated(state, validated) {
            state.insightsValidated = validated;
        },
        setProducts(state, items) {
            state.products = items;
        },
        setProductHighlightState(state, payload) {
            state.products = [
                ...state.products.map(item => {
                    if (item.slug === payload.slug) {
                        return {
                            ...item,
                            highlighted: payload.highlighted,
                        };
                    }
                    return item;
                }),
            ];
        },
        CLEAR_PRODUCTS_MODULE(state) {
            Object.assign(state, initialState());
        },
    },
    actions: {
        async getProduct({ commit }, slug) {
            // get the product
            const product = await ProductService.getProduct(slug);
            // map over images to add main image boolean
            if (product.images) {
                const modifiedProductImages = product.images.map((image, index) => ({
                    ...image,
                    main: index < 1,
                    id: index,
                }));
                // assign the modified images
                Object.assign(product, { images: modifiedProductImages });
            }
            if (product.faq) {
                const modifiedProductFaqs = product.faq.map((q, index) => ({
                    ...q,
                    id: index,
                }));
                Object.assign(product, { faqs: modifiedProductFaqs });
            }
            // select an main variant
            const modifiedVariants = product.variants.map((variant, index) => ({
                ...variant,
                active: index < 1,
            }));
            Object.assign(product, { variants: modifiedVariants });
            // save the product into the store
            commit("setProduct", product);
            commit("setActiveVariant", product.variants.find(variant => variant.active));
        },
        changeActiveVariant({ commit }, variant) {
            commit("setActiveVariant", variant);
        },
        changeActiveProductImage({ commit, state }, id) {
            const image = state.product.images.find(i => i.id === id);
            commit("setProductMainImage", image);
        },

        async checkUserCanPurchaseProduct({ commit }, { sku, barcode }) {
            const validated = await ProductService.validateInsights(sku, barcode);
            commit("setInsightsValidated", validated);
        },
        async getProducts({ commit, state, getters: { selectedTest } }) {
            if (state.products.length === 0) {
                const products = await ProductService.getAllProducts(selectedTest.barcode);
                const temp = products.map(p => ({
                    ...p,
                    // image: 'https://via.placeholder.com/468x200/00A5B0',
                    // locked: p.categories === 'Merchandise',
                    highlighted: false,
                }));
                commit("setProducts", temp);
            }
        },
        async setHighlightedProduct({ commit, state }, slug) {
            const products = state.products.map(p => ({
                ...p,
                highlighted: slug === p.slug,
            }));
            commit("setProducts", products);
        },
        CLEAR_PRODUCTS_MODULE({ commit }) {
            commit("CLEAR_PRODUCTS_MODULE");
        },
    },
};
