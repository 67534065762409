<template>
    <div class="bg-white min-h-screen p-6 pt-12">
        <div>
            <router-link :to="{ name: 'account' }" class="bg-white font-semibold flex items-center">
                <feather type="chevron-left" class="h-5 w-5 mr-2" />

                {{ $store.getters.accountData.firstName }}'s Account
            </router-link>

            <div class="mx-auto pt-16" :class="$route.meta.fullWidth ? 'max-w-3xl' : 'max-w-xs'">
                <h1 class="font-normal text-2xl">Password</h1>
                <p>Change the password you use to sign in to the LivingDNA Portal</p>
                <Space height="4" />
                <div v-if="state">
                    <div v-if="state.matches('fetchingAccountInformation')">
                        Loading information
                    </div>
                    <div v-else-if="state.matches('fetchFailed')">
                        We were unable to load your data
                    </div>
                    <div v-else-if="state.matches('submitted')">
                        <p class="text-emerald-500 bg-emerald-50 p-4 rounded">
                            Your password updated successfully
                        </p>
                        <router-link
                            :to="{ name: 'account' }"
                            class="w-full py-2 px-4 mt-6 rounded flex items-center justify-center bg-cobalt-500 hover:bg-cobalt-700 text-white font-semibold"
                        >
                            <span class="text-white">Back to your account</span>
                        </router-link>
                    </div>
                    <div v-else>
                        <form @submit.prevent="send('SUBMIT')">
                            <div
                                class="text-sm text-rose-500 mb-2"
                                v-if="state.context.errorMessage"
                            >
                                {{ state.context.errorMessage }}
                            </div>
                            <p>Please enter your current password</p>
                            <PasswordInput
                                :new-password="true"
                                :show-label="false"
                                :model-value="state.context.currentPassword"
                                @input="
                                    send({
                                        type: 'CURRENT_PASSWORD_INPUT',
                                        data: $event,
                                    })
                                "
                            />
                            <p>Please enter the new password you would like to use</p>
                            <PasswordInput
                                :new-password="true"
                                :show-label="false"
                                :model-value="state.context.newPassword"
                                @input="
                                    send({
                                        type: 'NEW_PASSWORD_INPUT',
                                        data: $event,
                                    })
                                "
                            />
                            <SubmitButton
                                icon="save"
                                :disabled="!state.matches('readyToSubmit')"
                                :class="{ loading: state.matches('submitting') }"
                            >
                                Save
                            </SubmitButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Machine, assign, interpret } from "xstate";
import { useMachine } from "@xstate/vue";
import { fetchAccountData, saveNewPasswordForAccount } from "@/services/account";
import PasswordInput from "@/components/inputs/PasswordInput/PasswordInput.vue";
import SubmitButton from "@/components/button/SubmitButton.vue";

const PasswordMachine = Machine(
    {
        initial: "fetchingAccountInformation",
        context: {
            currentPassword: "",
            newPassword: "",
            email: "",
            username: "",
            errorMessage: "",
        },
        states: {
            fetchingAccountInformation: {
                invoke: {
                    src: fetchAccountData,
                    onDone: {
                        actions: "cacheAccountData",
                        target: "idle",
                    },
                    onError: "fetchFailed",
                },
            },
            idle: {
                on: {
                    CURRENT_PASSWORD_INPUT: { target: "dirty", actions: "cacheCurrentPassword" },
                    NEW_PASSWORD_INPUT: { target: "dirty", actions: "cacheNewPassword" },
                },
            },
            dirty: {
                on: {
                    CURRENT_PASSWORD_INPUT: { actions: "cacheCurrentPassword" },
                    NEW_PASSWORD_INPUT: [
                        {
                            cond: "passwordLongEnough",
                            actions: "cacheNewPassword",
                            target: "readyToSubmit",
                        },
                        { target: "dirty", actions: "cacheNewPassword" },
                    ],
                },
            },
            readyToSubmit: {
                on: {
                    CURRENT_PASSWORD_INPUT: { actions: "cacheCurrentPassword" },
                    NEW_PASSWORD_INPUT: [
                        {
                            cond: "passwordNotLongEnough",
                            actions: "cacheNewPassword",
                            target: "dirty",
                        },
                        { actions: "cacheNewPassword" },
                    ],
                    SUBMIT: "submitting",
                },
            },
            submitting: {
                entry: "clearErrorMessage",
                invoke: {
                    src: "saveNewPasswordForAccount",
                    onDone: "submitted",
                    onError: {
                        target: "readyToSubmit",
                        actions: "cacheErrorMessage",
                    },
                },
            },
            submitted: {},
            fetchFailed: {},
        },
    },
    {
        services: {
            saveNewPasswordForAccount: (context) =>
                saveNewPasswordForAccount(
                    context.email,
                    context.username,
                    context.currentPassword,
                    context.newPassword
                ),
        },
        actions: {
            cacheAccountData: assign({
                email: (context, event) => event.data.email,
                username: (context, event) => event.data.username,
            }),
            cacheCurrentPassword: assign({
                currentPassword: (ctx, event) => {
                    if (typeof event.data.target.value == "string") {
                        return event.data.target.value;
                    }
                    return ctx.currentPassword;
                },
            }),
            cacheNewPassword: assign({
                newPassword: (ctx, event) => {
                    if (typeof event.data.target.value == "string") {
                        return event.data.target.value;
                    }
                    return ctx.newPassword;
                },
            }),
            cacheErrorMessage: assign({
                errorMessage: (_, event) => event.data.message,
            }),
            clearErrorMessage: assign({
                errorMessage: "",
            }),
        },
        guards: {
            passwordLongEnough: (context, event) => event.data.target.value.length >= 8,
            passwordNotLongEnough: (context, event) => event.data.target.value.length < 8,
        },
    }
);

export default {
    data() {
        return {
            currentPasswordService: undefined,
        };
    },
    setup() {
        const { state, send, service } = useMachine(PasswordMachine);
        service.onTransition((state) => {
            if (state.done) {
                this.$router.push({ name: "account" });
            }
        });
        return { state, send };
    },
    components: { PasswordInput, SubmitButton },
};
</script>
