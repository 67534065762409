<template>
    <div>
        <Trix v-model="editorContent" />
        <slot />
    </div>
</template>

<script>
import Trix from "@/components/Trix/Trix.vue";

export default {
    components: { Trix },
    data() {
        return {
            editorContent: this.body,
        };
    },
    props: ["body"],
};
</script>

<style>
.trix-button-group--file-tools,
.trix-button-group--history-tools,
.trix-button--icon-code {
    @apply hidden !important;
}
.trix-content {
    @apply bg-white list-disc list-inside rounded p-6 !important;
}
.trix-content ul {
    @apply list-disc !important;
}
.trix-content ol {
    @apply list-decimal !important;
}
.trix-button--icon {
    @apply bg-white !important;
}
</style>
