import axios from "axios";
import endpoints from "@/endpoints";
import wellbeingFixtures from "@/services/wellbeing/wellbeingFixtures";
import { isEmpty } from "lodash";
// import { isCompleted } from './wellbeingHelpers';

const isWellbeing = test => test.group === "wellbeing";
const isPurchased = test => test.purchased;
const isPending = test => test.purchased && !test.complete;
const isTestComplete = test => test.complete;
const isIncomplete = test => !test.complete;
const isActive = (testType, activeReports) => activeReports.includes(testType);
// const isActive = testType => activeReports => activeReports.includes(testType);
// const hasCmsData = test => test.traitCmsData && test.phenotypeCmsData;
const sortPurchasedFirst = test => (test.purchased ? -1 : 1);
const sortHasResultFirst = test => {
    if (test.totalPhenotypeSlug === null || test.totalPhenotypeSlug === "snpsNotFound") {
        return -1;
    }
    return 1;
};
const combineTestAndTraits = allTraits => test => {
    const trait = allTraits.find(t => t.traitCmsData.slug === test.type);
    return {
        ...test,
        ...trait,
    };
};
const parseResultsData = trait =>
    Object.assign(trait, {
        traitCmsData: JSON.parse(trait.traitCmsData),
        phenotypeCmsData: JSON.parse(trait.phenotypeCmsData),
    });
const parsePreviewResultsData = trait => {
    const parsedTraitData = JSON.parse(trait.traitCmsData) || {};
    const parsedPhenotypeData = JSON.parse(trait.phenotypeCmsData) || {};
    const requiredTraitFields = {
        keyTakeaway: parsedTraitData.keyTakeaway,
        keyTakeawayImage: [
            {
                urlRaw: parsedTraitData.keyTakeawayImage
                    ? parsedTraitData.keyTakeawayImage[0].urlRaw
                    : "",
            },
        ],
        scalePosition: parsedTraitData.scalePosition,
    };
    const requiredPhenotypeFields = {
        slug: parsedPhenotypeData.slug,
        goals: parsedPhenotypeData.goals,
    };
    return Object.assign(trait, {
        traitCmsData: requiredTraitFields,
        phenotypeCmsData: requiredPhenotypeFields,
    });
};

const WellbeingService = {
    async get(barcode) {
        const { data } = await axios.get(endpoints.wellbeingPreview(barcode));
        return data;
    },
    async getReport(barcode, report) {
        try {
            const { data } = await axios.get(endpoints.wellbeingTrait(barcode, report));
            return data;
        } catch (e) {
            throw e;
        }
    },
    mergeTestsAndCmsData(cmsData, kitTests) {
        const parsedResultsData = cmsData.map(parseResultsData);
        return Object.values(kitTests)
            .filter(isWellbeing)
            .filter(test => isActive(test.type, wellbeingFixtures.activeReports))
            .map(combineTestAndTraits(parsedResultsData))
            .sort(sortHasResultFirst)
            .sort(sortPurchasedFirst);
    },
    hasPurchased(traits) {
        return Object.values(traits).filter(isPurchased).length > 2;
    },
    isFullPending(traits) {
        return Object.values(traits).filter(isPending).length > 2;
    },
    hasCompleted(traits) {
        if (isEmpty(traits)) {
            return false;
        }
        return traits.filter(isIncomplete).length === 0 && traits.filter(isPurchased).length > 0;
    },
    completeWellbeingReports(traits) {
        return traits.filter(trait => trait.complete);
    },
    filterToFitnessReports(traits) {
        return traits.filter(trait => wellbeingFixtures.fitnessReports.includes(trait.type));
    },
    filterOutFitnessReports(traits) {
        return traits.filter(trait => !wellbeingFixtures.fitnessReports.includes(trait.type));
    },
};

export default WellbeingService;
