<template>
    <div
        class="h-screen overflow-y-hidden bg-cover flex"
        :style="{ backgroundImage: `url(${image})` }"
    >
        <div class="m-auto bg-white rounded shadow-key max-w-lg">
            <div
                class="bg-cobalt-50 px-6 py-3 rounded-t flex items-center text-cobalt-500 text-sm font-semibold"
            >
                <feather type="info" class="h-5 w-5 fill-current mr-2" />
                {{ title }}
            </div>
            <div class="p-6" v-html="message" />
        </div>
    </div>
</template>

<script>
import Feather from "vue-feather";
import image from "@/assets/images/wellbeing/wellbeing-placeholder.png";

export default {
    components: { Feather },
    data() {
        return {
            image,
        };
    },
    computed: {
        title() {
            return "We've encountered an error";
        },
        message() {
            return 'An issue has occurred in retrieving DNA information from your upload. Please review <a class="font-semibold" href="https://support.livingdna.com/hc/en-us/articles/360012509652-What-format-does-my-file-need-to-be-in-to-successfully-upload-" target="_blank" rel="noopener">Living DNA\'s accepted file types</a> and try uploading again.';
        },
    },
};
</script>
