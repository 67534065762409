<template>
    <consents-form
        :barcode="profile.barcode"
        :buttonDisabled="buttonDisabled"
        :fieldData="{ partnersConsent }"
        @submit="onSubmit"
    >
        <p>
            Once opted into Family Networks, our relative matching service, you have the option to
            match with relatives in Living DNA’s partner databases.
        </p>

        <p>
            By opting into this optional service, you will be sharing the following data with Living
            DNA partners:
        </p>
        <ul class="pl-4 list-disc">
            <li>Your username</li>
            <li class="mt-2">
                Your relationship with each DNA match who is a customer of that partner
            </li>
            <li class="mt-2">The amount of DNA you share with matches mentioned above</li>
        </ul>

        <p>
            You are not required to give this consent. Your participation in Family Networks will
            not be impacted by your decision to opt in or out of this feature.
        </p>

        <div class="flex">
            <input
                v-model="partnersConsent"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="OptIn"
                type="radio"
                :value="true"
            />
            <label
                for="OptIn"
                class="cursor-pointer text-sm leading-none block mt-px font-semibold"
            >
                Opt In
            </label>
        </div>

        <div class="mt-5 flex">
            <input
                v-model="partnersConsent"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="OptOut"
                type="radio"
                :value="false"
            />
            <label
                for="OptOut"
                class="cursor-pointer text-sm leading-none block mt-px font-semibold"
            >
                Opt out
            </label>
        </div>
    </consents-form>
</template>

<script>
import ConsentsForm from "@/components/Profile/Edit/ConsentsForm.vue";

export default {
    name: "EditPartners",

    props: {
        profile: {
            type: Object,
            required: true,
        },
    },

    components: {
        ConsentsForm,
    },

    data() {
        return {
            partnersConsent: this.profile.partnersConsent,
            initialPartnersConsent: this.profile.partnersConsent,
        };
    },

    methods: {
        onSubmit() {
            this.initialPartnersConsent = this.partnersConsent;
        },
    },

    computed: {
        buttonDisabled() {
            return this.partnersConsent === this.initialPartnersConsent;
        },
    },
};
</script>
