<template>
    <div class="py-12 px-4 relative" style="background-color: #eef5ff">
        <ScienceIconLeft class="absolute bottom-0 left-0" />
        <ScienceIconRight class="absolute bottom-0 right-0" />
        <div class="relative z-20">
            <h2 class="text-lg text-center">The genetics of {{ traitName }}</h2>
            <p
                class="text-2xl max-w-lg m-auto text-center text-denim-700 mt-6 mb-0"
                v-html="heading"
            ></p>
            <div class="text-center">
                <router-link
                    :to="{ name: 'wellbeing-report-science', params: { report: slug } }"
                    class="mt-6 inline-flex items-center mx-auto text-cobalt-500"
                >
                    <span class="font-semibold">Explore the science</span>
                    <feather type="arrow-right" class="h-5 w-5 fill-current ml-2"></feather>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import ScienceIconLeft from "@/components/Wellbeing/ScienceIconLeft.vue";
import ScienceIconRight from "@/components/Wellbeing/ScienceIconRight.vue";

export default {
    components: { ScienceIconLeft, ScienceIconRight },
    props: {
        traitName: {
            required: true,
            type: String,
        },
        heading: {
            required: true,
            type: String,
        },
        slug: {
            required: true,
            type: String,
        },
    },
};
</script>
