import endpoints from "@/endpoints";
import axios from "axios";

export default {
    state: {
        user: {},
        userEmail: "",
        partner: {},
    },
    actions: {
        createUserAccount(context, { account, $auth }) {
            return new Promise((resolve, reject) => {
                const { password, ...safeToLog } = account;
                console.log(safeToLog);
                $auth
                    .register({
                        data: {
                            account: {
                                firstName: account.firstName,
                                lastName: account.lastName,
                                email: account.emailAddress,
                                username: account.emailAddress,
                                plainPassword: {
                                    first: account.password,
                                    second: account.password,
                                },
                                partner: account.partner,
                                country: account.country,
                                marketing: account.acceptMarketing,
                                termsOfService: account.acceptTerms,
                                privacyStatement: account.acceptTerms,
                                originalEmail: account.emailAddress,
                            },
                        },
                    })
                    .then(
                        () => resolve(context.commit("setUserEmail", account.emailAddress)),
                        (err) => reject(err)
                    );
            });
        },
        resendVerificationEmail(context, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(endpoints.accountConfirmResend, payload)
                    .then(() => resolve())
                    .catch((err) => reject(err));
            });
        },
        verifyEmailAddress({ commit }, token) {
            return new Promise((resolve, reject) => {
                axios
                    .post(endpoints.validateEmail, {
                        token,
                    })
                    .then(() => {
                        commit("markUserAsActivated");
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        createPassword({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .post(endpoints.accountCreatePassword, {
                        resetPassword: {
                            plainPassword: {
                                first: payload.password,
                                second: payload.password,
                            },
                            token: payload.token,
                        },
                    })
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
            });
        },
        getPartnerInformation({ commit }, brandCode) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${endpoints.getBrand}/${brandCode}`)
                    .then((res) => {
                        commit("setPartnerInformation", res.data);
                        resolve();
                    })
                    .catch((err) => console.log(err));
            });
        },
    },
    mutations: {
        setUserEmail(state, email) {
            state.userEmail = email;
        },
        setPartnerInformation(state, partner) {
            state.partner = partner;
        },
    },
};
