<template>
    <div>
        <template v-if="matches('reducedNumberOfPages')">
            <PaginationWrapper>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage - 1)
                    "
                    :disabled="currentPaginationState.context.currentPage === 1"
                >
                    <Feather type="chevron-left" class="h-5 w-5" />
                </PaginationButton>
                <PaginationButton
                    v-for="i in currentPaginationState.context.totalPageCount"
                    :key="i"
                    :disabled="i === currentPaginationState.context.currentPage"
                    @page-change="$emit('page-changed', i)"
                >
                    {{ i }}
                </PaginationButton>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage + 1)
                    "
                    :disabled="
                        currentPaginationState.context.currentPage ===
                        currentPaginationState.context.totalPageCount
                    "
                >
                    <Feather type="chevron-right" class="h-5 w-5" />
                </PaginationButton>
            </PaginationWrapper>
        </template>
        <template v-if="matches('onFirstPage')">
            <PaginationWrapper>
                <PaginationButton disabled>
                    <Feather type="chevron-left" class="h-5 w-5" />
                </PaginationButton>
                <PaginationButton
                    v-for="i in 5"
                    :key="i"
                    :disabled="i === currentPaginationState.context.currentPage"
                    @page-change="$emit('page-changed', i)"
                >
                    {{ i }}
                </PaginationButton>
                <PaginationButton disabled> ... </PaginationButton>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.totalPageCount)
                    "
                    :disabled="
                        currentPaginationState.context.currentPage ===
                        currentPaginationState.context.totalPageCount
                    "
                >
                    {{ currentPaginationState.context.totalPageCount }}
                </PaginationButton>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage + 1)
                    "
                >
                    <Feather type="chevron-right" class="h-5 w-5" />
                </PaginationButton>
            </PaginationWrapper>
        </template>
        <template v-if="matches('inRangeOfStart')">
            <PaginationWrapper>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage - 1)
                    "
                >
                    <Feather type="chevron-left" class="h-5 w-5" />
                </PaginationButton>
                <PaginationButton
                    v-for="i in 5"
                    :key="i"
                    :disabled="i === currentPaginationState.context.currentPage"
                    @page-change="$emit('page-changed', i)"
                >
                    {{ i }}
                </PaginationButton>
                <PaginationButton disabled> ... </PaginationButton>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.totalPageCount)
                    "
                    :disabled="
                        currentPaginationState.context.currentPage ===
                        currentPaginationState.context.totalPageCount
                    "
                >
                    {{ currentPaginationState.context.totalPageCount }}
                </PaginationButton>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage + 1)
                    "
                >
                    <Feather type="chevron-right" class="h-5 w-5" />
                </PaginationButton>
            </PaginationWrapper>
        </template>
        <template v-if="matches('inRangeOfEnd')">
            <PaginationWrapper>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage - 1)
                    "
                >
                    <Feather type="chevron-left" class="h-5 w-5" />
                </PaginationButton>
                <PaginationButton
                    @page-change="$emit('page-changed', 1)"
                    :disabled="currentPaginationState.context.currentPage === 1"
                >
                    1
                </PaginationButton>
                <PaginationButton disabled> ... </PaginationButton>
                <PaginationButton
                    v-for="i in range(
                        currentPaginationState.context.totalPageCount - 2,
                        currentPaginationState.context.totalPageCount + 1
                    )"
                    :key="i"
                    @page-change="$emit('page-changed', i)"
                    :disabled="i === currentPaginationState.context.currentPage"
                >
                    {{ i }}
                </PaginationButton>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage + 1)
                    "
                >
                    <Feather type="chevron-right" class="h-5 w-5" />
                </PaginationButton>
            </PaginationWrapper>
        </template>
        <template v-if="matches('onLastPage')">
            <PaginationWrapper>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage - 1)
                    "
                >
                    <Feather type="chevron-left" class="h-5 w-5" />
                </PaginationButton>
                <PaginationButton
                    @page-change="$emit('page-changed', 1)"
                    :disabled="currentPaginationState.context.currentPage === 1"
                >
                    1
                </PaginationButton>
                <PaginationButton disabled> ... </PaginationButton>
                <PaginationButton
                    v-for="i in range(
                        currentPaginationState.context.totalPageCount - 2,
                        currentPaginationState.context.totalPageCount + 1
                    )"
                    :key="i"
                    @page-change="$emit('page-changed', i)"
                    :disabled="i === currentPaginationState.context.currentPage"
                >
                    {{ i }}
                </PaginationButton>
                <PaginationButton disabled>
                    <Feather type="chevron-right" class="h-5 w-5" />
                </PaginationButton>
            </PaginationWrapper>
        </template>
        <template v-if="matches('inMiddlePage')">
            <PaginationWrapper>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage - 1)
                    "
                >
                    <Feather type="chevron-left" class="h-5 w-5" />
                </PaginationButton>
                <PaginationButton
                    @page-change="$emit('page-changed', 1)"
                    :disabled="currentPaginationState.context.currentPage === 1"
                >
                    1
                </PaginationButton>
                <PaginationButton disabled> ... </PaginationButton>
                <PaginationButton
                    v-for="i in range(
                        currentPaginationState.context.currentPage - 1,
                        currentPaginationState.context.currentPage + 2
                    )"
                    @page-change="$emit('page-changed', i)"
                    :disabled="i === currentPaginationState.context.currentPage"
                    :key="i"
                >
                    {{ i }}
                </PaginationButton>
                <PaginationButton disabled> ... </PaginationButton>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.totalPageCount)
                    "
                >
                    {{ currentPaginationState.context.totalPageCount }}
                </PaginationButton>
                <PaginationButton
                    @page-change="
                        $emit('page-changed', currentPaginationState.context.currentPage + 1)
                    "
                >
                    <Feather type="chevron-right" class="h-5 w-5" />
                </PaginationButton>
            </PaginationWrapper>
        </template>
    </div>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import { range } from "lodash";
import PaginationButton from "./PaginationButton.vue";
import PaginationWrapper from "./PaginationWrapper.vue";

export default {
    props: {
        listCount: {
            type: Number,
            required: true,
        },
        itemsPerPage: {
            type: [Number, String],
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
    },
    created() {
        const paginationMachineFactory = (listCount, itemsPerPage, currentPage, totalPageCount) =>
            Machine(
                {
                    id: "pagination-machine",
                    initial: "determiningPaginationState",
                    context: {
                        listCount,
                        itemsPerPage,
                        currentPage,
                        totalPageCount,
                    },
                    states: {
                        determiningPaginationState: {
                            on: {
                                "": [
                                    {
                                        cond: "isReducedNumberOfPages",
                                        target: "reducedNumberOfPages",
                                    },
                                    {
                                        cond: "isOnlyPage",
                                        target: "onOnlyPage",
                                    },
                                    {
                                        cond: "isFirstPage",
                                        target: "onFirstPage",
                                    },
                                    {
                                        cond: "isInRangeOfStart",
                                        target: "inRangeOfStart",
                                    },
                                    {
                                        cond: "isLastPage",
                                        target: "onLastPage",
                                    },
                                    {
                                        cond: "isInRangeOfEnd",
                                        target: "inRangeOfEnd",
                                    },
                                    {
                                        target: "inMiddlePage",
                                    },
                                ],
                            },
                        },
                        inRangeOfStart: {},
                        inRangeOfEnd: {},
                        inMiddlePage: {},
                        onOnlyPage: {},
                        onFirstPage: {},
                        reducedNumberOfPages: {},
                        onLastPage: {},
                    },
                },
                {
                    guards: {
                        isOnlyPage: (context) => context.totalPageCount === 1,
                        isFirstPage: (context) => context.currentPage === 1,
                        isReducedNumberOfPages: (context) => context.totalPageCount <= 6,
                        isLastPage: (context) => context.currentPage === context.totalPageCount,
                        isInRangeOfStart: (context) => context.currentPage <= 4,
                        isInRangeOfEnd: (context) =>
                            context.currentPage >= context.totalPageCount - 2,
                    },
                }
            );
        const totalPageCount = Math.ceil(this.listCount / this.itemsPerPage);
        const paginationMachine = paginationMachineFactory(
            this.listCount,
            this.itemsPerPage,
            this.currentPage,
            totalPageCount
        );
        this.paginationMachine = interpret(paginationMachine);
        this.currentPaginationState = paginationMachine.initialState;
        this.paginationMachine.onTransition((state) => {
            this.currentPaginationState = state;
        });
    },
    data() {
        return {
            range,
            paginationMachine: undefined,
            currentPaginationState: undefined,
        };
    },
    methods: {
        matches(state) {
            return this.currentPaginationState.matches(state);
        },
    },
    components: { PaginationButton, PaginationWrapper },
};
</script>
