<template>
    <div class="prose">
        <p>
            If you wish, you can share administrative privileges of your DNA profile with another
            Living DNA user.
        </p>
        <p>
            You should only give Manager access to someone you know and trust with your DNA data,
            such as a close relative who is helping you with your ancestral research.
        </p>
        <p>By inviting someone to become a Manager, you grant them the ability to:</p>
        <ul class="list-disc ml-6">
            <li>View your results</li>
            <li>Opt this profile into services such as Family Networks</li>
            <li>Trigger optional updates for this profile</li>
            <li>Purchase upgrades for this profile</li>
            <li>Download your raw DNA data file</li>
        </ul>
        <p>
            If you wish to proceed, enter the user’s email address below. Invites can only be sent
            to an email address associated with a Living DNA account.
        </p>
    </div>
</template>
