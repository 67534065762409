<template>
    <div class="shadow rounded-lg bg-white //flex-1 flex flex-col mb-6 lg:mb-0">
        <div class="flex items-stretch">
            <div class="w-full md:w-1/2 p-6 flex flex-col">
                <p class="mt-0 mb-6">
                    Purchase a Living DNA test to discover 50,000 years of your worldwide evolution.
                    Learn your MT and Y Haplotypes and get an exclusive member price for uploading.
                </p>
                <a
                    href="https://livingdna.com/int/kit/ancestry-dna-test"
                    class="flex items-center mt-auto text-cobalt-500"
                    target="_blank"
                    rel="noopener"
                >
                    <feather-wrapper icon="shopping-cart" />
                    <span class="ml-2 font-semibold">Order ancestry kit</span>
                </a>
            </div>
            <div class="text-right w-1/2 hidden md:block">
                <img class="" :src="image" alt="Parental migration map" />
            </div>
        </div>
    </div>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import Link from "@/components/utilities/DashboardLinkWithIcon.vue";
import mapImage from "@/assets/images/dashboard/Migration-Map.png";

export default {
    components: { Link, FeatherWrapper },
    data() {
        return {
            image: mapImage,
        };
    },
};
</script>
