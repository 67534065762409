<template>
    <consents-form
        :barcode="profile.barcode"
        :buttonDisabled="buttonDisabled"
        :fieldData="{ fnConsent }"
        @submit="onSubmit"
    >
        <div class="flex mt-4">
            <input
                v-model="fnConsent"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="OptIn"
                type="radio"
                :value="true"
            />
            <label
                for="OptIn"
                class="cursor-pointer text-sm leading-none block mt-px font-semibold"
            >
                Opt In
            </label>
        </div>

        <div class="mt-5 flex">
            <input
                v-model="fnConsent"
                class="form-radio bg-white h-4 w-4 mr-3 text-cobalt-500"
                id="OptOut"
                type="radio"
                :value="false"
            />
            <label
                for="OptOut"
                class="cursor-pointer text-sm leading-none block mt-px font-semibold"
            >
                Opt out
            </label>
        </div>
    </consents-form>
</template>

<script>
import ConsentsForm from "@/components/Profile/Edit/ConsentsForm.vue";

export default {
    name: "EditFamilyMatching",

    props: {
        profile: {
            type: Object,
            required: true,
        },
    },

    components: {
        ConsentsForm,
    },

    data() {
        return {
            fnConsent: this.profile.fnConsent,
            initialFnConsent: this.profile.fnConsent,
        };
    },

    methods: {
        onSubmit() {
            this.initialFnConsent = this.fnConsent;
        },
    },

    computed: {
        buttonDisabled() {
            return this.fnConsent === this.initialFnConsent;
        },
    },
};
</script>
