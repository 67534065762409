<template>
    <div>
        {{ currentConfirmPasswordState ? currentConfirmPasswordState.value : "" }}
        <h1 class="font-normal text-2xl">Confirm your password</h1>
        <p>Reenter your password to confirm deletion.</p>
        <form @submit.prevent="confirmPasswordService.send({ type: 'SUBMIT', data: $event })">
            <PasswordInput new-password :show-label="false" />
            <template v-if="currentConfirmPasswordState.matches('incorrectPassword')">
                <p class="text-sm text-rose-500 mb-0 mt-4">Your password was incorrect</p>
            </template>
            <template v-if="currentConfirmPasswordState.matches('unableToDeleteProfile')">
                <p class="text-sm text-rose-500 mb-0 mt-4">
                    You've entered the <strong>correct</strong> password but we were unable to
                    delete your profile. Please contact customer service at EMAIL ADDRESS and we
                    will be able to manually delete your profile for you.
                </p>
            </template>
            <SubmitButton class="bg-rose-500 hover:bg-rose-500" icon="trash-2">
                Confirm deletion
            </SubmitButton>
        </form>
        <!-- <SubmitButton
            v-else-if="currentConfirmPasswordState.matches('checkingInput')"
            class="bg-rose-500 hover:bg-rose-500 loading"
            icon="trash-2"
        >
            Confirm deletion
        </SubmitButton>
        <SubmitButton v-else class="bg-grey-500" icon="trash-2" disabled>
            Confirm deletion
        </SubmitButton> -->
        <router-link
            :to="{ name: 'profile-view', params: { barcode: profile.barcode } }"
            class="text-center text-grey-500 font-semibold mt-4 block"
        >
            Cancel
        </router-link>
    </div>
</template>

<script>
import axios from "axios";
import { Machine, interpret, assign } from "xstate";
import ProfileService from "@/services/profile";
import PasswordInput from "@/components/inputs/PasswordInput/PasswordInput.vue";
import SubmitButton from "@/components/button/SubmitButton.vue";
import endpoints from "@/endpoints";
import { sortBy, find } from "lodash";
import { pipe } from "lodash/fp";

export default {
    components: { SubmitButton, PasswordInput },
    props: {
        profile: {
            required: true,
            type: Object,
        },
    },
    created() {
        // states: idle, checkingPassword, passwordIncorrect, deletingProfile, unableToDeleteProfile, deletedProfile

        const confirmPasswordMachine = Machine(
            {
                id: "confirm-password",
                initial: "idle",
                context: {
                    password: "",
                },
                states: {
                    idle: {
                        on: {
                            SUBMIT: "checkingInput",
                        },
                    },
                    checkingInput: {
                        invoke: {
                            src: "checkPassword",
                            onDone: "deletingProfile",
                            onError: "incorrectPassword",
                        },
                    },
                    deletingProfile: {
                        invoke: {
                            src: "deleteProfile",
                            onDone: "deletedProfile",
                            onError: "unableToDeleteProfile",
                        },
                    },
                    unableToDeleteProfile: {},
                    deletedProfile: {
                        entry: "navigateToSuccessPage",
                    },
                    incorrectPassword: {
                        on: {
                            SUBMIT: "checkingInput",
                        },
                    },
                },
            },
            {
                services: {
                    checkPassword: async (context, event) =>
                        new Promise(async (resolve, reject) => {
                            const data = new FormData(event.data.target);
                            const password = data.get("password");
                            try {
                                await ProfileService.checkPassword(
                                    this.$store.getters.account.email,
                                    password
                                );
                                resolve();
                            } catch (e) {
                                reject();
                            }
                        }),
                    deleteProfile: async () =>
                        new Promise(async (resolve, reject) => {
                            try {
                                await axios.delete(
                                    endpoints.getProfile(this.$route.params.barcode)
                                );
                                const profileList = await this.$store.dispatch("getProfiles");
                                await this.$store.dispatch("FORCE_SET_ACCOUNT_TESTS");

                                /*
                                 * This code is copied from the router guard that does exactly the same
                                 * thing. Do I love it? No. Do I want to mess with it until I have the space
                                 * to refactor it properly? Not a chance.
                                 * */

                                if (!profileList.length > 0) {
                                    return this.$router.push({ name: "activate" });
                                }

                                if (!profileList.find((p) => p.permission.isDefault)) {
                                    if (profileList.length > 1) {
                                        const [first] = pipe(
                                            (prof) => sortBy(prof, ["profile.barcode"]),
                                            (prof) =>
                                                sortBy(
                                                    prof,
                                                    ({ profile }) =>
                                                        find(
                                                            this.$store.getters.accountTests,
                                                            ({ barcode }) =>
                                                                barcode === profile.barcode
                                                        ).status !== "complete"
                                                )
                                        )(profileList);

                                        await this.$store.dispatch(
                                            "setDefaultProfile",
                                            first.permission.id
                                        );
                                        await this.$store.dispatch(
                                            "SET_TEST_FROM_TESTS",
                                            first.profile.barcode
                                        );
                                    } else {
                                        const [first] = profileList;
                                        await this.$store.dispatch(
                                            "SET_TEST_FROM_TESTS",
                                            first.profile.barcode
                                        );
                                        await this.$store.dispatch(
                                            "setDefaultProfile",
                                            first.permission.id
                                        );
                                    }
                                }
                                resolve();
                            } catch (e) {
                                reject(e);
                            }
                        }),
                },
                actions: {
                    cachePassword: assign({
                        password: (context, event) => {
                            console.log("gets called", event.data.target.value);
                            return event.data.target.value;
                        },
                    }),
                    navigateToSuccessPage: () => {
                        this.$router.push({
                            name: "received-deletion",
                            params: { barcode: this.profile.barcode },
                        });
                    },
                },
                guards: {
                    hasPasswordValue: (context, event) => event.data.length,
                },
            }
        );
        this.confirmPasswordService = interpret(confirmPasswordMachine).start();
        this.currentConfirmPasswordState = confirmPasswordMachine.initialState;
        this.confirmPasswordService.onTransition((state) => {
            this.currentConfirmPasswordState = state;
        });
    },
    data() {
        return {
            confirmPasswordService: undefined,
            currentConfirmPasswordState: undefined,
        };
    },
};
</script>
