import Classical from "@/views/Classical/Classical.vue";
import ClassicalOverview from "@/views/Classical/ClassicalOverview.vue";
import RomanOverview from "@/views/Classical/RomanOverview.vue";
import GreekOverview from "@/views/Classical/GreekOverview.vue";
import EgyptianOverview from "@/views/Classical/EgyptianOverview.vue";
import Roman from "@/views/Classical/Roman.vue";
import Greek from "@/views/Classical/Greek.vue";
import Egyptian from "@/views/Classical/Egyptian.vue";
import RomanMap from "@/views/Classical/RomanMap.vue";
import RomanResult from "@/views/Classical/RomanResult.vue";
import RomanReferences from "@/views/Classical/RomanReferences.vue";
import GreekMap from "@/views/Classical/GreekMap.vue";
import EgyptianMap from "@/views/Classical/EgyptianMap.vue";
import EgyptianReferences from "@/views/Classical/EgyptianReferences.vue";
import GreekReferences from "@/views/Classical/GreekReferences.vue";
import GreekResult from "@/views/Classical/GreekResult.vue";
import EgyptianResult from "@/views/Classical/EgyptianResult.vue";

export default [
    {
        path: "/classical",
        component: Classical,
        redirect: "/classical/overview",
        children: [
            {
                path: "overview",
                name: "classical-overview",
                component: ClassicalOverview,
            },
            {
                path: "roman",
                name: "roman",
                component: Roman,
                redirect: { name: "roman-overview" },
                children: [
                    {
                        path: "overview",
                        name: "roman-overview",
                        component: RomanOverview,
                    },
                    {
                        path: "map",
                        name: "roman-map",
                        component: RomanMap,
                    },
                    {
                        path: "result",
                        name: "roman-result",
                        component: RomanResult,
                    },
                    {
                        path: "references",
                        name: "roman-references",
                        component: RomanReferences,
                    },
                ],
            },
            {
                path: "greek",
                name: "greek",
                component: Greek,
                redirect: { name: "greek-overview" },
                children: [
                    {
                        path: "overview",
                        name: "greek-overview",
                        component: GreekOverview,
                    },
                    {
                        path: "result",
                        name: "greek-result",
                        component: GreekResult,
                    },
                    {
                        path: "map",
                        name: "greek-map",
                        component: GreekMap,
                    },
                    {
                        path: "map",
                        name: "greek-references",
                        component: GreekReferences,
                    },
                ],
            },
            {
                path: "egyptian",
                name: "egyptian",
                component: Egyptian,
                redirect: { name: "egyptian-overview" },
                children: [
                    {
                        path: "overview",
                        name: "egyptian-overview",
                        component: EgyptianOverview,
                    },
                    {
                        path: "overview",
                        name: "egyptian-result",
                        component: EgyptianResult,
                    },
                    {
                        path: "map",
                        name: "egyptian-map",
                        component: EgyptianMap,
                    },
                    {
                        path: "map",
                        name: "egyptian-references",
                        component: EgyptianReferences,
                    },
                ],
            },
        ],
    },
];
