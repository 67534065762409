<template>
    <div class="flex gap-x-4 gap-y-2 flex-wrap">
        <button
            type="button"
            v-for="colour in colours"
            @click="activeColour = colour"
            class="h-12 w-12 rounded flex"
            :class="colour === activeColour ? 'bg-cobalt-500' : 'bg-transparent'"
        >
            <div
                type="button"
                @click="activeColour = colour"
                class="h-10 w-10 rounded-full border-2 border-white m-auto"
                :style="{ backgroundColor: colour }"
            />
        </button>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";

const colours = [
    "#fdba74",
    "#f97316",
    "#ef4444",
    "#c2410c",
    "#86efac",
    "#84cc16",
    "#22c55e",
    "#10b981",
    "#15803d",
    "#14b8a6",
    "#06b6d4",
    "#93c5fd",
    "#3b82f6",
    "#1e40af",
    "#c4b5fd",
    "#6366f1",
    "#8b5cf6",
    "#6d28d9",
    "#f9a8d4",
    "#ec4899",
    "#f43f5e",
    "#be185d",
];

const props = defineProps({
    modelValue: String,
});

const emit = defineEmits(["update:modelValue"]);

const activeColour = ref(props.modelValue);

watch(activeColour, (value) => {
    emit("update:modelValue", value);
});
</script>
