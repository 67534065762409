<template>
    <NavigationButton
        :side="side"
        class="absolute"
        :class="`${side}-0 -m${sideNameToMarginCharacter} md:-m${sideNameToMarginCharacter}-20`"
        style="top: 50%; transform: translateY(-50%)"
        @click="$emit('click')"
    />
</template>

<script>
import NavigationButton from "@/components/Wellbeing/AllReports/NavigationButton.vue";

export default {
    components: { NavigationButton },
    props: {
        side: String,
    },
    computed: {
        sideNameToMarginCharacter() {
            return {
                right: "r",
                left: "l",
            }[this.side];
        },
    },
};
</script>
