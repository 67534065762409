<template>
    <div>
        <div class="p-4 border border-grey-500 rounded">
            <div
                class="h-72 overflow-auto pr-4"
                role="region"
                aria-label="Living DNA Global Research Participant Agreement"
            >
                <h2 class="sub-heading">Living DNA Global Research Participant Agreement</h2>
                <p class="term-text">
                    At Living DNA we offer customers the ability to find out more about themselves
                    by paying for DNA testing services. While this is our main business model, we
                    also want our services to benefit society as a whole; for example, by research
                    that helps us understand more about our global population make up and health.
                </p>
                <p class="term-text">
                    If you are interested in participating in our research, please read on.
                </p>
                <h2 class="sub-heading">What's the purpose</h2>
                <p class="term-text">
                    Our Living DNA Global Research Project has been setup to look at genetics to
                    help understand how all humans are all connected, and the role genetics has in
                    relation to our health and lifestyle. This may result in patents and other
                    intellectual property for Living DNA. It is the intention to publish information
                    on our findings; however only aggregated and anonymous data will be published.
                </p>
                <p class="term-text">
                    Your raw genetic data and other related information may be passed to
                    researchers. We hope that this will benefit society in the long term. Whilst the
                    scope of research may change over time, the underlying intention will remain the
                    same. We are working with leading academics and scientists to review your
                    feedback.
                </p>
                <p class="term-text">
                    The data and samples we collect and samples will only be used for ethically and
                    scientifically approved research. Careful safeguards, in line with ISO:27001,
                    ensures the confidentiality of your data and samples.
                </p>
                <p class="term-text">
                    It's also important to know that you are in charge of how your DNA and the data
                    derived from it will be used, so you can opt out of this project at any time. We
                    will analyse your DNA and information derived from it without linking it to your
                    name. Also for this reason, it will not be possible for you to withdraw data
                    that has already been analysed in a de-identified manner (because it will not be
                    possible to know which data stems from you).
                </p>
                <h2 class="sub-heading">Types of research</h2>
                <p class="term-text">
                    The initial focus of the research is to map world genetic ancestry to show how
                    interconnected we all are, utilizing data to provide fine scale ancestry
                    information. We do this to in order to maintain the cutting edge nature of the
                    Living DNA product, delivering value for our customers. In the medium-to-long
                    term we see this supporting health and lifestyle related activities. Full
                    details of research projects will be made available in the Research section of
                    the Living DNA website, however the type and scope of research may change
                    without notice to the participant.
                </p>
                <h2 class="sub-heading">Research funding</h2>
                <p class="term-text">
                    The Living DNA Global Research Project is being funded by Living DNA Ltd, a
                    &ldquo;for profit&rdquo; company. As the project expands, funds may be secured
                    from other sources.
                </p>
                <h2 class="sub-heading">Future use</h2>
                <p class="term-text">
                    The scope and type of research that may be beneficial to society in the future
                    may be different to that which is done today. We cannot foresee exactly how the
                    database will be used in the future; data and samples may serve research that
                    cannot yet be envisaged. In all cases we will be working with the purpose of
                    showing how all humans are connected and the role genetics plays in our
                    lifestyle and health. Any research conducted will only proceed if given ethical
                    approval by independent assessors.
                </p>
                <h2 class="sub-heading">Re-contacting and feedback</h2>
                <p class="term-text">
                    As a participant, you will have access to your raw genetic data file, which can
                    be downloaded from the secure Living DNA customer platform. You will be updated
                    on the progress of any research and findings via the email address that is
                    linked to your account. It is your responsibility to keep your contact
                    information up-to-date on the Living DNA platform. We may ask you further
                    questions for specific research projects and you will have the option to provide
                    this information or not.
                </p>
                <h2 class="sub-heading">Risks and benefits of participating</h2>
                <p class="term-text">
                    The main risk of participating in the research project is in finding out
                    information that you were not aware of, such as discovering your ethnicity is
                    not as you thought, or that you are in a risk category for a health condition
                    you were not aware of.
                </p>
                <p class="term-text">
                    With all studies there is a risk of data loss or misplacement. The Living DNA
                    Global Research Project operate under ISO:27001 for information security and
                    take great care to ensure the integrity of your data so that the risk of loss or
                    leakage is minimized.
                </p>
                <h2 class="sub-heading">Withdrawal of your consent</h2>
                <p class="term-text">
                    In-line with established DNA biobanks, the Living DNA Global Research Project
                    has established two levels of withdrawal of consent.
                </p>
                <ol class="list-decimal pl-4">
                    <li class="pb-3">
                        No Further Contact &ndash; This means that the research team at the Living
                        DNA Global Research Project would no longer contact you directly but would
                        have permission to retain and use information and samples previously
                        provided. Those that choose this level of withdrawal leave the database
                        intact, supporting researchers with the ability to use the information in a
                        way that aims to bring a true benefit to society. Please contact the Living
                        DNA Global Research Team at
                        <a href="mailto:research@livingdna.com">research@livingdna.com</a> as there
                        are a number of options available.<br />
                    </li>
                    <li class="pb-3">
                        No Further Use &ndash; In addition to longer contacting the participant to
                        obtain further information, any information and samples previously collected
                        would not be available to researchers. If this option is chosen then your
                        information will not be used in future research, but it is not possible to
                        remove your data from research that has already begun. The data and
                        information will be held, allowing you the option to change your consent in
                        the future.
                    </li>
                </ol>
                <p class="term-text">
                    These withdrawal of consent options are for the Living DNA Global Research
                    Project only and are unrelated to the consent settings you choose for the Living
                    DNA commercial product.
                </p>
                <p class="pb-0 font-bold">V 1.1 Last Updated 20/09/2016</p>
            </div>
        </div>
        <div class="mt-8">
            <div class="flex items-center">
                <input
                    type="radio"
                    id="one"
                    :value="true"
                    v-model="isOptedIn"
                    class="mr-2 h-4 w-4 form-radio bg-white mr-3 text-cobalt-500"
                />
                <label for="one"
                    >Yes, I would like to be part of research that will benefit society, knowing I
                    can change my mind at any point</label
                >
            </div>
            <div class="flex items-center mt-2">
                <input
                    type="radio"
                    id="two"
                    :value="false"
                    v-model="isOptedIn"
                    class="mr-2 h-4 w-4 form-radio bg-white mr-3 text-cobalt-500"
                />
                <label for="two">No, I would not like to be part of any research projects</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isOptedIn: this.modelValue,
        };
    },
    props: {
        modelValue: Boolean,
    },
    watch: {
        isOptedIn(value) {
            this.$emit("update:modelValue", value);
        },
    },
};
</script>

<style lang="scss" scoped>
.scroll-box-container {
    padding: 16px 0 16px 16px;
    border: 1px solid #bbb;
    border-radius: 5px;
    margin-bottom: 16px;
}

.sub-heading {
    margin-bottom: 16px;
    font-size: 1.25rem;
    color: #6d6e71;
}

.term-text {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.25;
}
</style>
