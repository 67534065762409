<template>
    <form @submit.prevent="submit" class="text-center">
        <p class="leading-normal text-grey-700">
            We'll update your account to use the below email for verification and log in.
        </p>
        <TextInput
            v-model="newEmail"
            class="mt-6"
            label="alternative-email"
            :display-label="false"
            placeholder="New email address"
        />
        <button
            :class="{
                loading: loading,
                'bg-grey-200 text-grey-500': !readyToSubmit,
                'bg-cobalt-500 text-white': readyToSubmit,
            }"
            :disabled="!readyToSubmit"
            class="mt-4 rounded w-full py-2 px-4 font-semibold"
        >
            Update email
        </button>
        <router-link
            class="text-grey-500 font-semibold mt-4 inline-block"
            to="/signup/verification-email-sent"
            >Cancel</router-link
        >
    </form>
</template>

<script>
import TextInput from "@/components/inputs/TextInput.vue";

export default {
    components: { TextInput },
    data() {
        return {
            newEmail: "",
            loading: false,
        };
    },
    computed: {
        readyToSubmit() {
            return this.newEmail.length > 0;
        },
    },
    methods: {
        submit() {},
    },
};
</script>

<style>
/* body a.text-grey-500 {
    @apply text-grey-500 !important;
}
body a.text-grey-600 {
    @apply text-grey-600 !important;
} */
</style>
