import UnaddedWellbeing from "@/views/dashboard/StatusBar/UnaddedTests/FullWellbeing.vue";
import UnaddedTasterWellbeing from "@/views/dashboard/StatusBar/UnaddedTests/TasterWellbeing.vue";
import UnaddedAutosomal from "@/views/dashboard/StatusBar/UnaddedTests/FullAutosomal.vue";
import UnaddedTasterAutosomal from "@/views/dashboard/StatusBar/UnaddedTests/TasterAutosomal.vue";
import UnaddedFamilyNetworks from "@/views/dashboard/StatusBar/UnaddedTests/FamilyNetworks.vue";
import UnaddedLDParentalfrom from "@/views/dashboard/StatusBar/UnaddedTests/LDParental.vue";
import UnaddedLTParentalfrom from "@/views/dashboard/StatusBar/UnaddedTests/LTParental.vue";
import UnaddedViking from "@/views/dashboard/StatusBar/UnaddedTests/Viking.vue";
import UnaddedArchaic from "@/views/dashboard/StatusBar/UnaddedTests/Archaic.vue";
import UnaddedClassical from "@/views/dashboard/StatusBar/UnaddedTests/Classical.vue";

const testDictionary = {
    autosomal: {
        name: "Autosomal",
        unpurchasedComponent: {
            lt: UnaddedAutosomal,
            ld: UnaddedAutosomal,
        },
    },
    autosomalStarter: {
        name: "Autosomal taster",
        unpurchasedComponent: {
            lt: UnaddedTasterAutosomal,
            ld: UnaddedTasterAutosomal,
        },
    },
    mtdna: {
        name: "MT DNA",
        unpurchasedComponent: {
            lt: UnaddedLTParentalfrom,
            ld: UnaddedLDParentalfrom,
        },
    },
    ydna: {
        name: "Y DNA",
        unpurchasedComponent: {
            lt: UnaddedLTParentalfrom,
            ld: UnaddedLDParentalfrom,
        },
    },
    strength: {
        name: "Wellbeing taster",
        unpurchasedComponent: {
            lt: UnaddedTasterWellbeing,
            ld: UnaddedTasterWellbeing,
        },
    },
    injuryRisk: {
        name: "Wellbeing",
        unpurchasedComponent: {
            lt: UnaddedWellbeing,
            ld: UnaddedWellbeing,
        },
    },
    familyMatching: {
        name: "Family matching",
        unpurchasedComponent: {
            lt: UnaddedFamilyNetworks,
            ld: UnaddedFamilyNetworks,
        },
    },
    wellbeingStarter: {
        name: "Wellbeing taster",
        unpurchasedComponent: {
            lt: UnaddedTasterWellbeing,
            ld: UnaddedTasterWellbeing,
        },
    },
    viking: {
        name: "Viking",
        unpurchasedComponent: {
            lt: UnaddedViking,
            ld: UnaddedViking,
        },
    },
    archaic: {
        name: "Neanderthal",
        unpurchasedComponent: {
            lt: UnaddedArchaic,
            ld: UnaddedArchaic,
        },
    },
    classical: {
        name: "Classical",
        unpurchasedComponent: {
            lt: UnaddedClassical,
            ld: UnaddedClassical,
        },
    },
};

export function getTestInformation(test) {
    return testDictionary[test.type] || test.testName;
}
