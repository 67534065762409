<template>
    <div>
        <TopImageCard
            :image="{
                src:
                    trait.phenotypeCmsData && trait.phenotypeCmsData.keyTakeawayImage
                        ? trait.phenotypeCmsData.keyTakeawayImage[0].urlRaw
                        : 'https://source.unsplash.com/random',
            }"
            :fade="false"
        >
            <template v-slot:body>
                <h5 class="font-semibold text-grey-500 text-sm mt-6">
                    {{ trait.traitCmsData.category }}
                </h5>
                <h4 class="mt-1 text-grey-700 text-base font-normal">
                    {{ trait.traitCmsData.title }}
                </h4>
            </template>
            <template v-slot:footer>
                <template v-if="trait.purchased">
                    <IconRight
                        :to="{
                            name: 'wellbeing-report',
                            params: { report: trait.traitCmsData.slug },
                        }"
                        :text="trait.traitCmsData.title"
                        icon="arrow-right"
                    />
                </template>
            </template>
        </TopImageCard>
    </div>
</template>

<script>
import TopImageCard from "@/components/cards/TopImageCard.vue";
import IconRight from "@/components/Links/IconRight.vue";

export default {
    components: { TopImageCard, IconRight },
    props: {
        trait: {
            type: Object,
            required: true,
        },
    },
};
</script>
