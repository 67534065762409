<template>
    <Overview>
        <template #sidebar>
            <div class="fade-bottom" style="width: 511px; background: #fff">
                <img
                    src="https://images.unsplash.com/photo-1517732306149-e8f829eb588a?auto=format&fit=crop&q=80&w=2344&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                    class="h-64 object-cover object-center w-full"
                />
            </div>
            <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                <h1 class="text-xl">Recent Ancestry</h1>
                <div>
                    <h3>The science</h3>
                    <p>
                        Your Autosomal ancestry is determined by a combination of bits of DNA called
                        ‘autosomes’ inherited by both your mother and father. You receive roughly
                        50% from each of your biological parents. We share autosomal DNA with
                        everyone we’re related to, both directly and indirectly.
                    </p>
                    <p>
                        By looking at individual parts of your ‘autosomes’ called SNP’s, which are
                        inherited from generation to generation we can use them to establish
                        ancestry patterns. This means we can identify the population groups you
                        share a proportion of your DNA with.
                    </p>
                    <p>
                        Everybody has autosomal chromosomes which means both males and females can
                        be tested with the same level of accuracy.
                    </p>
                    <h3>Your DNA puzzle</h3>
                    <p>
                        Your autosomal results show you a view of your ancestry as a puzzle of
                        populations which contribute to your ancestral profile. This accounts for
                        the general location of your ancestors possibly as far back as 1500 years
                        ago?
                    </p>
                    <p>
                        As the number of ancestors doubles each generation the further back we go (2
                        parents, 4 grandparents, 8 great-grandparents and so on) the further in time
                        you go, the lower the autosomal DNA contribution from each ancestor.
                    </p>
                    <h3>Putting the puzzle together</h3>
                    <p>
                        Your autosomal results can be used to piece together the parts of your
                        family history puzzle. By putting the pieces together you get a full picture
                        of what makes up you. You can discover the places your ancestors came from
                        and in doing so get an in depth look into your family history. Look out for
                        those small percentages they could be inherited from a more distant ancestor
                        such as your great great grandparent.
                    </p>
                </div>
            </div>
        </template>
        <template #desktopContent>
            <div
                v-if="$store.getters.starterTestMode"
                class="p-6 bg-white shadow rounded space-y-2 max-w-2xl"
            >
                <div class="flex items-center gap-6">
                    <FeatherWrapper icon="lock" :classes="['h-6 w-6 text-gray-400']" />
                    <div>
                        <p>
                            You are currently viewing your starter results. Unlock all your regions
                            and get access to advanced family matching tools by upgrading
                        </p>
                        <router-link
                            to="/store/autosomal-upgrade"
                            class="text-cobalt-500 font-semibold flex items-center mt-2"
                        >
                            <feather-wrapper icon="zap" class="mr-2" />
                            Upgrade now
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="flex max-md:flex-col gap-6 max-w-2xl">
                <div class="p-6 md:w-1/2 bg-white shadow rounded space-y-2">
                    <p class="text-gray-600 text-sm">Total regions</p>
                    <div :class="['text-6xl font-semibold  leading-none text-cobalt-500']">
                        {{ autosomalStore.regions.length }}
                    </div>
                </div>
                <div class="p-6 md:w-1/2 bg-white shadow rounded space-y-2">
                    <p class="text-gray-600 text-sm">Total subregions</p>
                    <div :class="['text-6xl font-semibold  leading-none text-cobalt-500']">
                        {{ autosomalStore.subregions.length }}
                    </div>
                </div>
            </div>
            <VikingOverviewCard
                :linkTo="{ name: 'recent-map' }"
                title-text="Recent Ancestry Results"
                description-text="View a map of your regions and subregions and discover them in incredible detail"
                link-text="Load your results"
                :image="{
                    src: mapImage,
                }"
            />
            <VikingOverviewCard
                :linkTo="{ name: 'recent-versions' }"
                title-text="Version history"
                description-text="View refinements to our reference panels and see your previous results"
                link-text="View version history"
                :image="{
                    src: versionsImage,
                }"
            />
        </template>
        <template #mobileContent>
            <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                <h1 class="text-xl">Recent Ancestry</h1>
                <div>
                    <h3>The science</h3>
                    <p>
                        Your Autosomal ancestry is determined by a combination of bits of DNA called
                        ‘autosomes’ inherited by both your mother and father. You receive roughly
                        50% from each of your biological parents. We share autosomal DNA with
                        everyone we’re related to, both directly and indirectly.
                    </p>
                    <p>
                        By looking at individual parts of your ‘autosomes’ called SNP’s, which are
                        inherited from generation to generation we can use them to establish
                        ancestry patterns. This means we can identify the population groups you
                        share a proportion of your DNA with.
                    </p>
                    <p>
                        Everybody has autosomal chromosomes which means both males and females can
                        be tested with the same level of accuracy.
                    </p>
                    <h3>Your DNA puzzle</h3>
                    <p>
                        Your autosomal results show you a view of your ancestry as a puzzle of
                        populations which contribute to your ancestral profile. This accounts for
                        the general location of your ancestors possibly as far back as 1500 years
                        ago?
                    </p>
                    <p>
                        As the number of ancestors doubles each generation the further back we go (2
                        parents, 4 grandparents, 8 great-grandparents and so on) the further in time
                        you go, the lower the autosomal DNA contribution from each ancestor.
                    </p>
                    <h3>Putting the puzzle together</h3>
                    <p>
                        Your autosomal results can be used to piece together the parts of your
                        family history puzzle. By putting the pieces together you get a full picture
                        of what makes up you. You can discover the places your ancestors came from
                        and in doing so get an in depth look into your family history. Look out for
                        those small percentages they could be inherited from a more distant ancestor
                        such as your great great grandparent.
                    </p>
                </div>
            </div>
        </template>
    </Overview>
</template>
<script setup>
import Overview from "@/components/Overview.vue";
import VikingOverviewCard from "@/views/vikings/VikingsOverviewCard.vue";
import mapImage from "@/assets/images/ancestry/example-map-2.jpeg";
import versionsImage from "@/assets/images/ancestry/versions-2.png";
import { useAutosomalStore } from "@/store/ancestry/autosomal";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import endpoints from "@/endpoints";
import axios from "axios";

const autosomalStore = useAutosomalStore();
</script>
