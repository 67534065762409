<template>
    <router-link :to="to" class="mt-6 inline-flex items-center mx-auto">
        <span class="font-semibold flex-1">{{ text }}</span>
        <feather :type="icon" class="h-5 w-5 fill-current ml-2 flex-2"></feather>
    </router-link>
</template>

<script>
import Feather from "vue-feather";

export default {
    components: { Feather },
    props: {
        to: Object,
        text: String,
        icon: String,
    },
};
</script>
