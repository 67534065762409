<template>
    <div class="prose">
        <template v-if="hasFailedTransferQc">
            <h1 class="font-normal text-2xl">Reupload DNA</h1>
            <p>{{ errorMessage }}</p>
            <p>
                We accept DNA from AncestryDNA, 23AndMe, MyHeritage, FamilyTreeDNA (Family Finder),
                Illumina, Gene by Gene, and National Geographic (Geno 2.0).
            </p>
            <p class="text-gray-400">Please upload a TXT, CSV, ZIP or GZ file.</p>
            <UploadInput
                embedded
                :processing-completed="isDataSaved"
                @file-uploaded="uploadCompleted"
            />
            <a
                href="https://support.livingdna.com/hc/en-us/articles/360012509652-What-format-does-my-file-need-to-be-in-to-successfully-upload-"
                class="mt-16 font-semibold block text-center"
                target="_blank"
                rel="noopener"
            >
                Need help?
            </a>
        </template>
        <template v-else>
            <h1 class="font-normal text-2xl">
                Your transfer {{ isTesting ? "is testing" : "was ok" }}
            </h1>
            <p>There's nothing you need to do right now</p>
            <router-link :to="{ name: 'dashboard' }" class="font-semibold">
                Return to dashboard
            </router-link>
        </template>
    </div>
</template>

<script>
import UploadInput from "@/components/inputs/UploadInput/UploadInput.vue";

export default {
    components: { UploadInput },
    props: {
        profile: Object,
    },
    data() {
        return {
            isDataSaved: false,
        };
    },
    methods: {
        async uploadCompleted(fileName) {
            try {
                await this.$store.dispatch("reuploadDnaFile", {
                    barcode: this.profile.barcode,
                    file: fileName,
                });
                await this.$store.dispatch("FORCE_SET_ACCOUNT_TESTS");
                if (this.$store.getters.profile.barcode === this.profile.barcode) {
                    await this.$store.dispatch("SET_TEST_FROM_TESTS", this.profile.barcode);
                }
                this.isDataSaved = true;
                this.$router.push({
                    name: "profile-view",
                    params: { barcode: this.profile.barcode },
                });
            } catch (e) {
                alert(e.message);
            }
        },
    },
    computed: {
        currentTest() {
            return this.$store.getters.accountTests.find(
                (test) => test.barcode === this.profile.barcode
            );
        },
        hasFailedTransferQc() {
            return this.currentTest.raw_status === "transfer_qc_failed";
        },
        isTesting() {
            return this.currentTest.raw_status === "testing";
        },
        errorMessage() {
            return this.$store.getters.accountTests.find(
                ({ barcode }) => barcode === this.$route.params.barcode
            ).errorMessage;
        },
    },
};
</script>
