<template>
    <div>
        <div v-if="loadingStatus === 'loading'">
            <PageLoader class="mt-12">Loading your result</PageLoader>
        </div>
        <div class="bg-grey-50" v-else-if="loadingStatus === 'loaded'">
            <div class="rounded bg-grey-100 text-center py-24 px-6">
                <h1 class="text-4xl mb-12 text-yellow-700 font-semibold">
                    Your Egyptian index is {{ result.egyptians.index.toFixed(2) }}
                </h1>
                <DoughnutChart :chart-data="chartData" />
                <!--                <div class="mt-16 text-2xl font-semibold text-denim-700">-->
                <!--                    You are most closely associated with the-->
                <!--                    {{ vikingResult.poulationGroupCmsInformation.title }}-->
                <!--                </div>-->
                <div class="flex">
                    <TextLinkRight
                        icon="arrow-right"
                        class="mt-12 block text-xl font-semibold mx-auto"
                        :to="{ name: 'egyptian-map' }"
                        text="Click here to view a map and more information"
                    />
                </div>
            </div>
            <div class="m-auto px-4 py-24 mx-auto prose">
                <div>
                    <h3 class="mt-0">Percentile</h3>
                    <p>
                        Your percentile is derived from comparing your Egyptian result to that of
                        other users on our platform. It essentially reflects the percentage of users
                        whose index score is lower than yours.
                    </p>
                    <p>
                        You are in the {{ result.egyptians.percentile }}th percentile, which means
                        your index score is higher than {{ result.egyptians.percentile }}% of the
                        users in our system. This allows you to gauge your position relative to the
                        broader Living DNA user base.
                    </p>
                </div>
                <div class="flex not-prose mt-12">
                    <div class="flex items-center justify-between gap-6">
                        <div>
                            <div class="text-xl font-semibold">
                                {{ result.egyptians.percentile }}
                            </div>
                        </div>

                        <BarChart class="flex-shrink-0" :percentile="result.egyptians.percentile" />
                    </div>
                </div>
            </div>
            <div class="px-4 py-12 bg-gray-100">
                <div class="m-auto prose">
                    <h2 class="text-xl">{{ understanding.heading }}</h2>
                    <div v-html="understanding.text"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, inject } from "vue";
import DoughnutChart from "@/components/charts/Doughnut.vue";
import { getClassicalColour, getRequiredContent } from "@/composables/useClassicalContent";
import { useLoading } from "@/composables/useLoading";
import ArchaicResultsPercentile from "@/views/archaic/ArchaicResultsPercentile.vue";
import BarChart from "@/components/charts/BarChart.vue";

defineProps({
    vikingResult: Object,
});

const { loadingStatus, finishLoading } = useLoading();
const understanding = ref({});

const result = inject("classical-result");

getRequiredContent(["understanding-your-results"])
    .then(([understandingResult]) => {
        understanding.value = understandingResult;
    })
    .then(finishLoading);

const chartData = computed(() => ({
    datasets: [
        {
            data:
                result.value.egyptians.index <= 100
                    ? [result.value.egyptians.index, 100 - result.value.egyptians.index]
                    : [100, 0],
            backgroundColor: [getClassicalColour("egypt"), "#ddd"],
        },
    ],
    labels: [],
}));
</script>
