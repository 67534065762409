<template>
    <div class="bg-cobalt-50 text-white py-4">
        <div class="container mx-auto px-6 md:px-12">
            <div class="flex items-center gap-3">
                <Feather type="info" class="text-cobalt-500 h-5 w-5 flex-shrink-0" />
                <p class="text-sm text-cobalt-500 m-0">
                    New functionality added to chromosome browser -
                    <router-link
                        :to="{ name: 'multiview-browser' }"
                        class="font-medium text-cobalt-700 underline hover:text-denim-700"
                        >Check it out here.</router-link
                    >
                </p>
            </div>
        </div>
    </div>
</template>
