<template>
    <label
        for="fileInput"
        class="mt-6 py-6 text-center border border-dashed border-grey-500 cursor-pointer block relative"
        :class="[isDraggedOver ? 'bg-cobalt-500' : 'bg-grey-50']"
    >
        <vue-feather type="upload" class="fill-current text-grey-500 h-5 w-5"></vue-feather>
        <p class="text-grey-500 font-semibold">Drag and drop your file or</p>
        <p class="text-cobalt-500 mt-5 font-semibold">Browse</p>
        <input
            @dragover="isDraggedOver = true"
            @dragleave="isDraggedOver = false"
            @change="$emit('file-added', $event)"
            type="file"
            id="fileInput"
            class="absolute top-0 bottom-0 w-full h-full block opacity-0"
        />
    </label>
</template>

<script>
import VueFeather from "vue-feather";

export default {
    components: { VueFeather },
    data() {
        return {
            isDraggedOver: false,
        };
    },
};
</script>
