import { flattenDepth } from "lodash";
import { pipe } from "lodash/fp";
import * as turf from "@turf/turf";

const reversePoint = ([y, x]) => [parseFloat(x), parseFloat(y)];

const reversePoints = (points) => points.map(reversePoint);

const normalizePoints = (points) => {
    const temp = [points[0]];

    for (let i = 0; i < points.length - 1; i += 1) {
        const [first] = temp[temp.length - 1];
        const second = points[i + 1];

        if (first < second[0]) {
            temp.push(
                second[0] - first < first + 360 - second[0] ? second : [second[0] - 360, second[1]]
            );
        } else {
            temp.push(
                first - second[0] < second[0] + 360 - first ? second : [second[0] + 360, second[1]]
            );
        }
    }

    return temp;
};

const stagePathData = (migrationPaths, colors) =>
    migrationPaths.map(({ id, title, migrationPath }, index) => {
        return migrationPath
            .filter(({ points }) => points.length > 1)
            .map(({ points }) => ({
                id,
                title,
                color: colors[index],
                points: pipe(reversePoints, normalizePoints)(points),
            }));
    });

const addExtraCoords = (arr) =>
    arr.map(({ id, title, color, points }) => {
        const features = turf.bezierSpline(turf.lineString(points), {
            resolution: 10000,
        });

        console.log(features, points);

        return {
            ...features,
            properties: {
                ...features.properties,
                id,
                title,
                color,
            },
        };
    });

const convertToFeatures = (migrationPaths, colors) =>
    pipe(
        (paths) => stagePathData(paths, colors),
        (paths) => flattenDepth(paths, 1),
        addExtraCoords
    )(migrationPaths);

const stageCircleData = (migrationPaths, colors) =>
    migrationPaths.reduce(
        (acc, { id, title, migrationPath }, index) => [
            ...acc,
            ...migrationPath.map(({ points }) => ({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: reversePoint(points[points.length - 1]),
                },
                properties: {
                    id,
                    title,
                    color: colors[index],
                },
            })),
        ],
        []
    );

export default {
    getters: {
        maternalMigrationFeatures: (state, { motherlineMigrationPaths, autosomalColors }) =>
            convertToFeatures(motherlineMigrationPaths, autosomalColors),

        maternalGeojsonSource: (state, { maternalMigrationFeatures }) => ({
            type: "geojson",
            data: {
                id: "maternalMigration",
                type: "FeatureCollection",
                features: maternalMigrationFeatures,
            },
        }),

        maternalMigrationCircles: (state, { motherlineMigrationPaths, autosomalColors }) =>
            stageCircleData(motherlineMigrationPaths, autosomalColors),

        maternalCircleSource: (state, { maternalMigrationCircles }) => ({
            type: "geojson",
            data: {
                id: "maternalMigrationCircles",
                type: "FeatureCollection",
                features: maternalMigrationCircles,
            },
        }),

        paternalMigrationFeatures: (state, { fatherlineMigrationPaths, autosomalColors }) =>
            convertToFeatures(fatherlineMigrationPaths, autosomalColors),

        paternalGeojsonSource: (state, { paternalMigrationFeatures }) => ({
            type: "geojson",
            data: {
                id: "paternalMigration",
                type: "FeatureCollection",
                features: paternalMigrationFeatures,
            },
        }),

        paternalMigrationCircles: (state, { fatherlineMigrationPaths, autosomalColors }) =>
            stageCircleData(fatherlineMigrationPaths, autosomalColors),

        paternalCircleSource: (state, { paternalMigrationCircles }) => ({
            type: "geojson",
            data: {
                id: "paternalMigrationCircles",
                type: "FeatureCollection",
                features: paternalMigrationCircles,
            },
        }),
    },
};
