<template>
    <Modal @state-change="show = $event" :open="show" width="2xl">
        <h3 class="text-2xl font-normal mt-12 flex-0 px-12">
            {{ title }}
        </h3>

        <div>
            <div class="h-full overflow-auto">
                <p class="my-0 px-12">
                    {{ degree }}{{ ordinal }} degree relationships share an average of
                    {{ diagramMap.averagePercentage }}% ({{ diagramMap.averageSharedCm }} cM) of
                    their DNA
                </p>
                <img
                    :src="diagramMap.desktop"
                    alt="Your update is in progress"
                    class="my-6 bg-grey-50 p-6"
                />
                <p class="px-12">
                    The above relationships in green are our most likely predictions based on amount
                    of DNA shared and DNA segment length and location. In relationships with greater
                    degree distance, there is a higher (though still nominal) possibility of a match
                    being slightly closer or further in relationship to you. Descendants of
                    endogamous communities, in particular, are more likely to appear as closer DNA
                    relatives than their true relationship to you, due to the reduced variablity of
                    DNA within the population.
                </p>
            </div>
        </div>

        <div class="w-full flex justify-center mt-4">
            <button
                role="button"
                class="bg-cobalt-500 text-white py-2 w-1/2 rounded font-semibold flex items-center justify-center"
                @click="close()"
            >
                <Feather type="corner-up-left" class="h-5 w-5 fill-current mr-2" />
                Return to match list
            </button>
        </div>
    </Modal>
</template>

<script>
import { ref } from "vue";
import image from "@/assets/images/ancestry-uprade/map-watercolor-asset.png";
import Modal from "@/components/Modal.vue";
import { getRelationshipDetailsFromDegree } from "@/services/relationship";

const show = ref(false);

export function useModal() {
    function open() {
        show.value = true;
    }
    function close() {
        show.value = false;
    }
    return { open, close };
}

export default {
    components: { Modal },
    setup() {
        const { close } = useModal();
        return { show, close };
    },
    props: {
        degree: {
            type: Number,
        },
    },
    data() {
        return {
            image,
            diagramMap: {},
        };
    },
    computed: {
        ordinal() {
            return this.diagramMap.ordinal ? this.diagramMap.ordinal : "th";
        },
        title() {
            return `Relationship possibilities for ${this.degree}${this.ordinal} degree DNA matches`;
        },
    },
    watch: {
        degree: function (newVal, oldVal) {
            // watch it
            this.diagramMap = getRelationshipDetailsFromDegree(newVal) || {};
        },
    },
    mounted() {
        this.diagramMap = getRelationshipDetailsFromDegree(this.degree) || {};
    },
};
</script>
