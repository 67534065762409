<template>
    <Card class="bg-white flex flex-col">
        <div class="flex items-stretch">
            <div class="w-3/5 flex flex-col">
                <h4 class="text-gray-500 font-semibold text-sm mb-2">Preparing results</h4>
                <p class="my-0 mb-4">
                    Did you know? 41% of our American customers have Scandinavian ancestry
                </p>
                <span class="flex items-center mt-auto text-grey-500">
                    <feather-wrapper icon="map" />
                    <span class="ml-2 font-semibold">Recent ancestry</span>
                </span>
            </div>
            <div class="w-2/5">
                <Doughnut :chart-data="chartData" disabled />
            </div>
        </div>
    </Card>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import Card from "@/components/cards/Card.vue";
import Doughnut from "@/components/charts/Doughnut.vue";

export default {
    components: {
        FeatherWrapper,
        Card,
        Doughnut,
    },
    data() {
        return {
            testType: {
                name: "mt",
                status: "incomplete",
            },
        };
    },
    computed: {
        // chartValues() {
        //     return this.limitedRegionData.map(region => region.percentage);
        // },
        // chartColours() {
        //     return this.limitedRegionData.map(region => region.colour);
        // },
        // chartLabels() {
        //     return this.limitedRegionData.map(region => region.title);
        // },
        chartData() {
            return {
                datasets: [
                    {
                        data: [48, 20, 10, 8],
                        backgroundColor: ["#03b2e2", "#eae5ed", "#eae5ed", "#eae5ed"],
                    },
                ],
            };
        },
    },
};
</script>
