<template>
    <div class="bg-white min-h-screen p-6 pt-12">
        <div>
            <router-link :to="{ name: 'account' }" class="bg-white font-semibold flex items-center">
                <feather type="chevron-left" class="h-5 w-5 mr-2" />

                {{ $store.getters.accountData.firstName }}'s Account
            </router-link>

            <div class="mx-auto pt-16" :class="$route.meta.fullWidth ? 'max-w-3xl' : 'max-w-xs'">
                <h1 class="font-normal text-2xl">Email</h1>
                <p>The email address of the account holder</p>
                <div class="bg-cobalt-50 text-cobalt-500 rounded-lg p-4">
                    <p class="m-0">
                        For security reasons, after changing your email address you will be logged
                        out and required to log back in again.
                    </p>
                </div>
                <Space height="4" />
                <div v-if="currentEmailState">
                    <div v-if="currentEmailState.matches('fetchingAccountInformation')">
                        Loading information
                    </div>
                    <div v-else-if="currentEmailState.matches('fetchFailed')">
                        We were unable to load your data
                    </div>
                    <div v-else>
                        <form @submit.prevent="emailService.send('SAVE')">
                            <div
                                class="text-sm text-rose-500 mb-2"
                                v-if="currentEmailState.context.errorMessage"
                            >
                                {{ currentEmailState.context.errorMessage }}
                            </div>
                            <BaseTextInput
                                type="text"
                                label="Email"
                                :value="currentEmailState.context.email"
                                @input="
                                    emailService.send({ type: 'INPUT', data: { email: $event } })
                                "
                            />
                            <SubmitButton
                                icon="save"
                                :disabled="
                                    currentEmailState.matches('informationIsUnchanged') ||
                                        currentEmailState.matches('inputIsInvalid')
                                "
                                :class="{
                                    loading: currentEmailState.matches('savingEmailInformation'),
                                }"
                            >
                                Save
                            </SubmitButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import { fetchAccountData, saveAccountEmail } from "@/services/account";
import BaseTextInput from "@/components/Base/BaseTextInput.vue";
import SubmitButton from "@/components/button/SubmitButton.vue";

const accountEmailMachine = Machine(
    {
        id: "account-email",
        initial: "fetchingAccountInformation",
        context: {
            email: "",
            initial: {
                email: "",
            },
            meta: {
                name: {
                    first: "",
                    last: "",
                },
                username: "",
            },
            errorMessage: "",
        },
        states: {
            fetchingAccountInformation: {
                invoke: {
                    src: "fetchAccountInformation",
                    onDone: {
                        target: "informationIsUnchanged",
                        actions: ["cacheEmail", "cacheInitialInformation", "cacheMeta"],
                    },
                    onError: {
                        target: "fetchFailed",
                    },
                },
            },
            informationIsUnchanged: {
                on: {
                    INPUT: {
                        target: "informationIsModified",
                        actions: "cacheEmail",
                    },
                },
            },
            informationIsModified: {
                on: {
                    "": [
                        {
                            target: "informationIsUnchanged",
                            cond: "isInformationUnchanged",
                        },
                        {
                            target: "inputIsInvalid",
                            cond: "isInputInvalid",
                        },
                        {
                            target: "inputIsValid",
                            cond: () => true,
                        },
                    ],
                },
            },
            inputIsValid: {
                on: {
                    SAVE: "savingEmailInformation",
                    INPUT: {
                        target: "informationIsModified",
                        actions: "cacheEmail",
                    },
                },
            },
            inputIsInvalid: {
                on: {
                    INPUT: {
                        target: "informationIsModified",
                        actions: "cacheEmail",
                    },
                },
            },
            savingEmailInformation: {
                invoke: {
                    src: "saveName",
                    onDone: "saveSucceeded",
                    onError: "saveFailed",
                },
            },
            saveSucceeded: {
                type: "final",
            },
            saveFailed: {
                entry: "cacheErrorMessage",
                on: {
                    INPUT: "informationIsModified",
                },
            },
            fetchFailed: {},
        },
    },
    {
        services: {
            fetchAccountInformation: () =>
                new Promise(async (resolve, reject) => {
                    try {
                        const accountData = await fetchAccountData();
                        resolve({
                            name: {
                                first: accountData.firstName,
                                last: accountData.lastName,
                            },
                            email: accountData.email,
                            username: accountData.username,
                        });
                    } catch (e) {
                        reject(new Error(e));
                    }
                }),
            saveName: context =>
                new Promise(async (resolve, reject) => {
                    try {
                        await saveAccountEmail(
                            context.email,
                            context.meta.username,
                            context.meta.name.first,
                            context.meta.name.last
                        );
                        resolve();
                    } catch (e) {
                        reject(new Error(e.body.message));
                    }
                }),
        },
        actions: {
            cacheEmail: assign({
                email: (context, event) => event.data.email,
            }),
            cacheInitialInformation: assign({
                initial: (context, event) => ({
                    email: event.data.email,
                }),
            }),
            cacheMeta: assign({
                meta: (context, event) => ({
                    name: {
                        first: event.data.name.first,
                        last: event.data.name.last,
                    },
                    username: event.data.username,
                }),
            }),
            cacheErrorMessage: assign({
                errorMessage: (ctx, event) => {
                    console.log(event);
                    return event.data.message;
                },
            }),
        },
        guards: {
            isInformationUnchanged: context => context.initial.email === context.email,
            isInputInvalid: context =>
                context.email.length < 1 || context.email.indexOf("@") === -1,
        },
    }
);

export default {
    components: { BaseTextInput, SubmitButton },
    data() {
        return {
            currentEmailState: undefined,
            emailService: undefined,
        };
    },
    async created() {
        await this.$store.dispatch("getAccountData");
        // this.$bus.on("window-resize", ({ width }) => {
        //     this.windowWidth = width;
        // });
        this.emailService = interpret(accountEmailMachine);
        this.currentEmailState = accountEmailMachine.initialState;
        this.emailService
            .onTransition(state => {
                if (state.done) {
                    this.$auth.logout();
                }
                this.currentEmailState = state;
            })
            .start();
    },
};
</script>
