<template>
    <div class="w-full md:w-2/5 max-w-xs md:pl-4">
        <div class="rounded-lg shadow-key bg-white overflow-hidden">
            <div class="fade-bottom">
                <img
                    class="h-48 w-full object-cover object-center"
                    v-if="imageObject"
                    :src="imageObject[0].urlRaw"
                />
            </div>
            <div
                v-html="keyTakeawayText"
                class="text-denim-700 text-lg p-6 no-inner-p-margin"
            ></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imageObject: {
            required: true,
            validator: (prop) => typeof prop === "object" || prop === null,
        },
        keyTakeawayText: {
            required: true,
            type: String,
        },
    },
};
</script>
