<template>
    <div class="">
        <template v-if="true">
            <div class="bg-grey-50 text-denim-700">
                <div class="flex items-center container mx-auto px-4">
                    <Feather type="heart" class="h-6 w-6" />
                    <h1 class="ml-4 text-2xl font-normal my-12">
                        {{ $store.getters.firstName }}'s Wellbeing
                    </h1>
                </div>
                <h2 class="text-2xl font-normal text-center mb-8">Choose a focus</h2>
                <div class="flex relative">
                    <div class="w-64 h-64 mx-auto z-20 relative">
                        <transition-group name="slide-left">
                            <CarouselCard
                                :key="goal.name"
                                v-for="(goal, index) in allGoals"
                                v-show="index === currentCardIndex"
                            >
                                <template #image>
                                    <CarouselCardImage :image="goal.image" />
                                </template>
                                <CarouselCardTitle class="mt-4">
                                    {{ goal.name }}
                                </CarouselCardTitle>
                            </CarouselCard>
                        </transition-group>
                        <ChangeFocusButton
                            side="left"
                            @click="
                                currentCardIndex = previousCard(currentCardIndex, allGoals.length)
                            "
                        />
                        <ChangeFocusButton
                            side="right"
                            @click="currentCardIndex = nextCard(currentCardIndex, allGoals.length)"
                        />
                    </div>
                    <div
                        class="bg-white rounded-lg p-8 h-32 w-full absolute mx-auto bottom-0 z-10"
                    ></div>
                </div>
            </div>
            <div class="bg-white no-inner-p-margin">
                <div
                    v-if="traitsInCurrentGoal.length > 0"
                    class="max-w-5xl mx-auto overflow-hidden"
                >
                    <div class="flex flex-wrap items-stretch -mx-6 py-16 px-4">
                        <TraitInGoalCard
                            v-for="trait in traitsInCurrentGoal"
                            :key="trait.type"
                            :trait="trait"
                        />
                    </div>
                </div>
            </div>
        </template>
        <div class="max-w-3xl mx-auto px-4 pt-12">
            <h2 class="text-2xl font-normal">All reports</h2>
            <div class="flex flex-wrap -mx-6 overflow-x-hidden">
                <div class="w-full md:w-1/2 px-6 pt-8">
                    <ReportList title="Nutrition" :reports="nutritionReports" />
                </div>
                <div class="w-full md:w-1/2 px-6 pt-8">
                    <ReportList title="Fitness" :reports="fitnessReports" />
                </div>
            </div>
        </div>
        <div class="flex mt-8">
            <router-link
                :to="{ name: 'product', params: { slug: 'Wellbeing-Upgrade' } }"
                v-if="!isFullWellbeingPurchased"
                class="bg-cobalt-500 py-3 px-12 text-white m-auto rounded flex items-center hover:bg-cobalt-700 font-semibold"
            >
                <feather type="unlock" class="fill-current h-5 w-5 mr-2 text-white" />
                <span class="text-white">Unlock all</span>
            </router-link>
        </div>
        <div class="max-w-3xl mx-auto px-4 mt-8 pb-12">
            <h4 class="font-normal text-xl">Limitations</h4>
            <p class="mt-4">
                Your DNA results are not diagnostic and do not determine whether you will develop a
                disease or health condition. This test is for informational purposes only. Always
                consult a physician before making major dietary or lifestyle changes that could
                impact your health.
            </p>
            <p class="mt-4">
                DNA is not the only deciding factor in how your body reacts to vitamins, nutrients,
                and exercise. Many lifestyle aspects contribute to the expression of your genes,
                meaning that DNA alone cannot predict whether your genetic predispositions are or
                ever will actively affect you. Factors such as (but not limited to) sleep,
                hydration, air quality, exercise, diet, and stress can impact and change how your
                body responds to the nutrition and fitness traits in your DNA.
            </p>
        </div>
    </div>
</template>

<script>
import { uniqBy, flatten } from "lodash";
import wellbeingNotPurchased from "@/assets/images/wellbeing/hellogeorge.png";
import ReportList from "@/components/Wellbeing/ReportList.vue";
import TraitInGoalCard from "@/components/Wellbeing/AllReports/TraitInGoalCard.vue";
import ChangeFocusButton from "@/components/Wellbeing/AllReports/ChangeFocusButton.vue";
import CarouselCard from "@/components/Wellbeing/CarouselCard.vue";
import CarouselCardTitle from "@/components/Wellbeing/CarouselCardTitle.vue";
import CarouselCardImage from "@/components/Wellbeing/CarouselCardImage.vue";

export default {
    components: {
        CarouselCardImage,
        ReportList,
        TraitInGoalCard,
        ChangeFocusButton,
        CarouselCard,
        CarouselCardTitle,
    },
    props: {
        traits: {
            required: true,
        },
    },
    created() {
        console.log("traits", this.traits);
    },
    data() {
        return {
            currentCardIndex: this.$store.getters.selectedFocusIndex,
            wellbeingNotPurchased,
        };
    },
    computed: {
        isFullWellbeingPurchased() {
            return this.$store.getters.isFullWellbeingPurchased;
        },
        nutritionReports() {
            return this.traits.filter((trait) => !this.fitnessTraits().includes(trait.type));
        },
        fitnessReports() {
            return this.traits.filter((trait) => this.fitnessTraits().includes(trait.type));
        },
        allGoals() {
            const allGoals = flatten(
                Object.values(this.traits)
                    .filter((t) => t.traitCmsData && t.traitCmsData.goals)
                    .map((t) => t.traitCmsData.goals)
            );
            return uniqBy(allGoals, "name")
                .sort((a) => (a.name === "All traits" ? -1 : 1))
                .map(this.applyTraitsToGoal);
        },
        traitsInCurrentGoal() {
            if (!this.allGoals[this.currentCardIndex]) {
                return [];
            }
            const traitsInCurrentGoal = this.allGoals[this.currentCardIndex].traits;
            return traitsInCurrentGoal.filter((trait) => trait.traitCmsData);
        },
    },
    watch: {
        currentCardIndex(value) {
            this.$store.commit("setSelectedFocusIndex", value);
        },
    },
    methods: {
        nextCard(currentCardIndex, goalsCount) {
            if (currentCardIndex === goalsCount - 1) {
                return 0;
            }
            return currentCardIndex + 1;
        },
        previousCard(currentCardIndex, goalsCount) {
            if (currentCardIndex === 0) {
                return goalsCount - 1;
            }
            return currentCardIndex - 1;
        },
        applyTraitsToGoal(goal) {
            return {
                ...goal,
                traits: this.traits.filter(this.isTraitInGoal(goal)),
            };
        },
        isTraitInGoal(goal) {
            return (trait) =>
                trait.traitCmsData &&
                trait.traitCmsData.goals &&
                trait.traitCmsData.goals.map((g) => g.name).includes(goal.name);
        },
        fitnessTraits() {
            return [
                "enduranceBloodVessel",
                "enduranceCardiacResponse",
                "enduranceTrainability",
                "injuryRisk",
                "flexiblity",
                "recoveryTime",
                "strength",
            ];
        },
    },
};
</script>

<style>
/* a.text-grey-700 {
    @apply text-grey-700 !important;
} */
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
}
</style>
