<template>
    <form ref="uploadForm">
        <Empty v-show="!haveFile" @file-added="handleFileAdded" />
        <InProgress
            v-if="haveFile && (!uploadCompleted || !processingCompleted)"
            :file-name="file.name"
            :progress="uploadProgress"
            @cancelled="cancelUpload"
        />
        <Complete
            :embedded="embedded"
            :finished-saving="finishedSaving"
            v-if="haveFile && uploadCompleted && processingCompleted"
            :file-name="file.name"
            @cancelled="cancelUpload"
        >
            <template v-slot:upload-completed>
                <slot name="upload-completed"></slot>
            </template>
        </Complete>
    </form>
</template>

<script>
import { mapGetters } from "vuex";
import uuid from "uuid/v1";
import Empty from "./Empty.vue";
import InProgress from "./InProgress.vue";
import Complete from "./Complete.vue";

export default {
    components: { Empty, InProgress, Complete },
    data() {
        return {
            randomName: uuid(),
            file: {},
            uploadCompleted: false,
        };
    },
    props: {
        finishedSaving: Boolean,
        embedded: Boolean,
        processingCompleted: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        handleFileAdded(event) {
            this.file =
                (event.target && event.target.files && event.target.files[0]) ||
                event.dataTransfer.files[0] ||
                event.dataTransfer.items[0];
            this.getSignedUrl()
                .then((signedUrlResponse) => {
                    const mode = "real";
                    const signedUrl =
                        mode === "fake" ? "https://httpbin.org/put" : signedUrlResponse.data.url;
                    this.$store
                        .dispatch("uploadToSignedUrl", { signedUrl, file: this.file })
                        .then(() => {
                            this.uploadCompleted = true;
                            this.$emit("file-uploaded", this.randomName);
                        })
                        .catch((err) => {
                            console.log(err);
                            alert("Something went wrong with your upload");
                        });
                })
                .catch((err) => console.error(err));
        },
        getSignedUrl() {
            return new Promise((resolve, reject) => {
                const SPLITNAME = "--REFRESHTOKEN--";
                const token = localStorage.getItem("token").split(SPLITNAME)[1];
                const payload = {
                    filePath: this.randomName,
                    contentType: this.file.type,
                    refreshToken: token,
                };
                this.$store
                    .dispatch("getSignedURL", payload)
                    .then((signedUrl) => resolve(signedUrl))
                    .catch((e) => reject(e));
            });
        },
        cancelUpload() {
            this.file = {};
            this.$refs.uploadForm.reset();
            this.$store.dispatch("cancelUploadRequest");
        },
    },
    computed: {
        ...mapGetters(["whoDoesThisDNABelongTo", "uploadProgress"]),
        haveFile() {
            return !!this.file.size;
        },
    },
};
</script>
