<template>
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 280.173 280.173"
        style="enable-background:new 0 0 280.173 280.173;"
        xml:space="preserve"
    >
        <g>
            <path
                d="M140.086,68.171c-39.654,0-71.915,32.261-71.915,71.915s32.261,71.916,71.915,71.916
		c39.655,0,71.916-32.262,71.916-71.916S179.741,68.171,140.086,68.171z M140.086,197.002c-31.383,0-56.915-25.532-56.915-56.916
		c0-31.383,25.532-56.915,56.915-56.915c31.384,0,56.916,25.532,56.916,56.915C197.002,171.47,171.47,197.002,140.086,197.002z"
            />
            <path
                d="M140.086,54.001c4.142,0,7.5-3.357,7.5-7.5V7.5c0-4.142-3.358-7.5-7.5-7.5
		c-4.142,0-7.5,3.358-7.5,7.5v39.001C132.586,50.644,135.944,54.001,140.086,54.001z"
            />
            <path
                d="M140.086,226.172c-4.142,0-7.5,3.357-7.5,7.5v39.001c0,4.143,3.358,7.5,7.5,7.5
		c4.142,0,7.5-3.357,7.5-7.5v-39.001C147.586,229.529,144.228,226.172,140.086,226.172z"
            />
            <path
                d="M68.608,79.216c1.464,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.732,5.303-2.196
		c2.929-2.93,2.929-7.677,0-10.607L51.637,41.03c-2.928-2.927-7.677-2.929-10.606,0c-2.929,2.93-2.929,7.678,0,10.607L68.608,79.216
		z"
            />
            <path
                d="M211.564,200.958c-2.929-2.928-7.678-2.928-10.606,0c-2.929,2.93-2.929,7.678,0,10.608
		l27.578,27.578c1.464,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.732,5.303-2.196c2.929-2.93,2.929-7.678,0-10.607
		L211.564,200.958z"
            />
            <path
                d="M54.001,140.086c0-4.143-3.358-7.5-7.5-7.5h-39c-4.142,0-7.5,3.357-7.5,7.5
		c0,4.143,3.358,7.5,7.5,7.5h39C50.643,147.586,54.001,144.229,54.001,140.086z"
            />
            <path
                d="M272.673,132.586h-39.001c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h39.001
		c4.142,0,7.5-3.357,7.5-7.5C280.173,135.943,276.815,132.586,272.673,132.586z"
            />
            <path
                d="M206.261,81.412c1.919,0,3.839-0.732,5.303-2.196l27.578-27.578c2.929-2.93,2.929-7.678,0-10.607
		c-2.929-2.928-7.678-2.928-10.606,0l-27.578,27.578c-2.929,2.931-2.929,7.678,0,10.607
		C202.422,80.68,204.342,81.412,206.261,81.412z"
            />
            <path
                d="M68.608,200.958l-27.578,27.578c-2.929,2.93-2.929,7.678,0,10.607
		c1.464,1.464,3.384,2.196,5.303,2.196c1.919,0,3.839-0.732,5.303-2.196l27.578-27.578c2.929-2.93,2.929-7.678,0-10.608
		C76.286,198.03,71.537,198.03,68.608,200.958z"
            />
        </g>
    </svg>
</template>
