<template>
    <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style="enable-background:new 0 0 512 512;"
        xml:space="preserve"
    >
        <g>
            <g>
                <path
                    d="M489.924,111.231c-14.257-14.257-33.212-22.108-53.377-22.108c-3.684,0-7.331,0.262-10.93,0.784
                    c3.442-23.269-4.152-47.134-21.346-64.328C390.012,11.32,371.054,3.467,350.888,3.467s-39.124,7.853-53.383,22.111
                    c-13.366,13.365-21.19,31.118-22.034,49.988c-0.7,15.663,3.442,30.988,11.77,44.013L120.246,286.576
                    c-12.901-9.526-28.475-14.683-44.756-14.683c-20.164,0-39.121,7.852-53.378,22.11C7.854,308.262,0,327.22,0,347.386
                    s7.854,39.124,22.112,53.383c14.257,14.257,33.212,22.108,53.376,22.108c3.684,0,7.331-0.262,10.93-0.784
                    c-3.443,23.269,4.152,47.134,21.346,64.328c14.259,14.259,33.216,22.111,53.383,22.111c20.165,0,39.124-7.852,53.383-22.111
                    c14.067-14.067,21.918-32.768,22.107-52.657c0.157-16.551-5.001-32.378-14.679-45.478l165.934-165.934
                    c13.596,11.494,30.642,17.756,48.649,17.756c20.165,0,39.124-7.853,53.382-22.111C519.358,188.56,519.358,140.666,489.924,111.231
                    z M475.781,203.853c-10.482,10.481-24.417,16.253-39.24,16.253c-14.823,0-28.759-5.772-39.241-16.253
                    c-0.695-0.696-1.397-1.437-2.084-2.204c-1.833-2.044-4.427-3.244-7.172-3.318c-2.756-0.073-5.4,0.983-7.343,2.925l-179.37,179.369
                    c-3.85,3.849-3.913,10.071-0.142,13.998c20.758,21.617,20.397,56.454-0.803,77.655c-10.482,10.481-24.418,16.253-39.24,16.253
                    c-14.823,0-28.758-5.772-39.239-16.253c-15.959-15.958-20.669-39.737-12.002-60.577c1.555-3.739,0.701-8.048-2.162-10.912
                    c-1.913-1.914-4.471-2.929-7.073-2.929c-1.293,0-2.597,0.251-3.838,0.767c-6.78,2.819-13.961,4.249-21.343,4.249
                    c-14.821,0-28.754-5.771-39.233-16.25c-10.483-10.483-16.255-24.418-16.255-39.241c0-14.822,5.772-28.758,16.254-39.24
                    c10.48-10.481,24.414-16.252,39.235-16.252c14.4,0,28.044,5.486,38.419,15.449c3.926,3.769,10.148,3.708,13.998-0.143
                    l179.513-179.512c3.646-3.646,3.922-9.466,0.638-13.441c-18.295-22.144-16.752-54.184,3.59-74.526
                    c10.482-10.482,24.418-16.254,39.24-16.254c14.823,0,28.758,5.772,39.24,16.254c15.958,15.959,20.668,39.737,12.001,60.578
                    c-1.555,3.739-0.701,8.048,2.163,10.912c2.863,2.864,7.172,3.717,10.912,2.163c6.78-2.82,13.962-4.249,21.344-4.249
                    c14.821,0,28.754,5.771,39.234,16.25C497.417,147.011,497.417,182.217,475.781,203.853z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M219.28,251.186c-3.905-3.905-10.238-3.905-14.143,0l-46.639,46.638c-3.905,3.905-3.905,10.237,0,14.143
                    c1.953,1.953,4.511,2.929,7.071,2.929c2.559,0,5.119-0.977,7.071-2.929l46.639-46.638
                    C223.185,261.424,223.185,255.093,219.28,251.186z"
                />
            </g>
        </g>
        <g>
            <g>
                <path
                    d="M240.528,229.938c-3.905-3.905-10.238-3.905-14.143,0l-0.229,0.23c-3.905,3.905-3.905,10.238,0,14.143
                    c1.953,1.953,4.512,2.929,7.071,2.929c2.559,0,5.118-0.977,7.071-2.929l0.229-0.23
                    C244.433,240.176,244.433,233.843,240.528,229.938z"
                />
            </g>
        </g>
    </svg>
</template>
