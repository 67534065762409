<template>
    <router-link :to="link" class="block h-full">
        <div
            class="text-grey-700 shadow-key hover:shadow-key-hover rounded-lg flex overflow-hidden h-full"
        >
            <div
                v-if="trait.phenotypeCmsData && trait.phenotypeCmsData.keyTakeawayImage"
                class="w-1/3 bg-cover fade-right"
                :style="keyTakeawayImage"
            ></div>
            <div class="w-2/3 p-4 flex flex-col h-full">
                <div v-if="trait.phenotypeCmsData" v-html="keyTakeaway" class="mb-4"></div>
                <router-link
                    :to="link"
                    class="flex items-center text-cobalt-500 mt-4 font-semibold mt-auto"
                >
                    <div class="mr-2 flex items-center">
                        {{ title }}
                    </div>
                    <Feather type="arrow-right" class="h-4 w-4 flex-2 flex-none" />
                </router-link>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        trait: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return this.trait.traitCmsData.title;
        },
        link() {
            return { name: "wellbeing-report", params: { report: this.trait.traitCmsData.slug } };
        },
        hasImage() {
            return (
                this.trait.phenotypeCmsData &&
                this.trait.phenotypeCmsData.keyTakeawayImage &&
                this.trait.phenotypeCmsData.keyTakeawayImage[0] &&
                this.trait.phenotypeCmsData.keyTakeawayImage[0].urlRaw
            );
        },
        keyTakeawayImage() {
            return {
                backgroundImage: `url(${this.trait.phenotypeCmsData.keyTakeawayImage[0].urlRaw}`,
            };
        },
        keyTakeaway() {
            return this.trait.phenotypeCmsData.keyTakeaway;
        },
    },
};
</script>
