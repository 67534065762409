<template>
    <form @submit.prevent="avatarService.send('UPLOAD_IMAGE')">
        <template
            v-if="
                currentAvatarState.matches('idle') ||
                currentAvatarState.matches('convertingHeicToPng') ||
                currentAvatarState.matches('creatingPreviewImage')
            "
        >
            <label
                for="fileInput"
                class="mt-6 py-6 text-center cursor-pointer block relative rounded"
                :class="[
                    isDraggedOver ? 'bg-cobalt-500' : 'bg-grey-50',
                    {
                        'loading-grey':
                            currentAvatarState.matches('convertingHeicToPng') ||
                            currentAvatarState.matches('creatingPreviewImage'),
                    },
                ]"
            >
                <Feather type="upload" class="fill-current text-grey-500 h-5 w-5" />
                <p class="text-grey-500 font-semibold">Drag and drop your file or</p>
                <p class="text-cobalt-500 mt-5 font-semibold">Browse</p>
                <input
                    ref="fileInput"
                    @dragover="isDraggedOver = true"
                    @dragleave="isDraggedOver = false"
                    @change="avatarService.send('FILE_ADDED', { data: $event.target.files[0] })"
                    type="file"
                    id="fileInput"
                    class="absolute top-0 bottom-0 w-full h-full block opacity-0 cursor-pointer"
                />
            </label>
        </template>
        <div v-else-if="currentAvatarState.matches('failedToCreatePreviewImage')">
            <ErrorMessageTag class="my-6" @message-dismissed="avatarService.send('RESTART')">
                <div>Error: Couldn't create preview</div>
            </ErrorMessageTag>
        </div>
        <div v-else-if="currentAvatarState.matches('fileIsTooLarge')">
            <ErrorMessageTag class="my-6" @message-dismissed="avatarService.send('RESTART')">
                <div>Error: File too large (over 5MB)</div>
            </ErrorMessageTag>
        </div>
        <div v-else-if="currentAvatarState.matches('errorUploadingImage')">
            <ErrorMessageTag class="my-6" @message-dismissed="avatarService.send('RESTART')">
                <div>Error: Error uploading file</div>
            </ErrorMessageTag>
        </div>
        <div v-else-if="currentAvatarState.matches('fileIsUnsupportedFormat')">
            <ErrorMessageTag class="my-6" @message-dismissed="avatarService.send('RESTART')">
                <div>Error: Invalid file type</div>
            </ErrorMessageTag>
        </div>
        <div
            v-else-if="
                currentAvatarState.matches('previewingImage') ||
                currentAvatarState.matches('uploadingImage') ||
                currentAvatarState.matches('savingProfile') ||
                currentAvatarState.matches('viewingExistingImage')
            "
        >
            <div class="text-center relative my-10">
                <button
                    class="absolute h-12 w-12 flex bg-rose-500 rounded-full hover:shadow-key-hover z-20"
                    style="top: 10%; right: 18%"
                    @click="avatarService.send('DELETE_FILE')"
                >
                    <Feather type="x" class="m-auto h-6 w-6 text-white" />
                </button>
                <label
                    for="fileInput"
                    class="mt-6 py-6 text-center cursor-pointer block relative rounded"
                    :class="{
                        'loading-grey':
                            currentAvatarState.matches('convertingHeicToPng') ||
                            currentAvatarState.matches('creatingPreviewImage'),
                    }"
                >
                    <img
                        :src="currentAvatarState.context.imagePreviewUrl"
                        class="h-56 w-56 rounded-full object-cover inline"
                        alt="Preview of your avatar"
                    />
                    <!--                    <div class="absolute h-12 w-12 flex bg-cobalt-500 rounded-full" style="bottom: 10%; right: 18%">-->
                    <!--                        <Feather type="camera" class="m-auto h-6 w-6 text-white" />-->
                    <!--                    </div>-->
                    <input
                        ref="fileInput"
                        @dragover="isDraggedOver = true"
                        @dragleave="isDraggedOver = false"
                        @change="avatarService.send('FILE_ADDED', { data: $event.target.files[0] })"
                        type="file"
                        id="fileInput"
                        class="absolute top-0 bottom-0 w-full h-full block opacity-0 cursor-pointer"
                    />
                </label>
            </div>
        </div>
        <div v-else-if="currentAvatarState.matches('imageUploaded')">
            <p>Success!</p>
        </div>
        <div class="text-xs text-grey-600 text-center mt-2 mb-6">
            <strong class="block">We accept .JPEG, .PNG, .HEIC, and .BMP only</strong> (5MB file
            size limit)
        </div>
        <button
            type="submit"
            class="w-full py-2 px-4 rounded flex items-center justify-center bg-cobalt-500 text-white font-semibold"
            :class="{
                'bg-grey-500 cursor-default': !currentAvatarState.matches('previewingImage'),
                loading:
                    currentAvatarState.matches('uploadingImage') ||
                    currentAvatarState.matches('savingProfile'),
            }"
            :disabled="!currentAvatarState.matches('previewingImage')"
        >
            <Feather type="save" class="mr-2" />
            Save
        </button>
        <!--        {{ currentAvatarState.value }}-->
        <!--        {{ currentAvatarState.context }}-->
    </form>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import heic2any from "heic2any";
import { avatarUploader } from "@/services/cloudinary";
import ProfileService from "@/services/profile";
import ErrorMessageTag from "@/components/utilities/MessageTags/ErrorMessageTag.vue";

const avatarMachineFactory = (profile) =>
    Machine(
        {
            id: "avatar-machine",
            initial: "entry",
            context: {
                profile,
                uploadableImage: {},
                previewableImage: {},
                imagePreviewUrl: "",
                uploadedImageUrl: "",
            },
            states: {
                entry: {
                    on: {
                        "": [
                            {
                                cond: "hasExistingAvatar",
                                actions: "cacheExistingAvatar",
                                target: "viewingExistingImage",
                            },
                            {
                                target: "idle",
                            },
                        ],
                    },
                },
                viewingExistingImage: {
                    on: {
                        FILE_ADDED: [
                            {
                                cond: "isFileUnsupportedFormat",
                                target: "fileIsUnsupportedFormat",
                            },
                            {
                                cond: "isFileTooLarge",
                                target: "fileIsTooLarge",
                            },
                            {
                                cond: "fileIsHeic",
                                actions: "attachUploadableImage",
                                target: "convertingHeicToPng",
                            },
                            {
                                actions: ["attachUploadableImage", "attachPreviewableImage"],
                                target: "creatingPreviewImage",
                            },
                        ],
                        DELETE_FILE: "deletingFile",
                    },
                },
                idle: {
                    on: {
                        FILE_ADDED: [
                            {
                                cond: "isFileUnsupportedFormat",
                                target: "fileIsUnsupportedFormat",
                            },
                            {
                                cond: "isFileTooLarge",
                                target: "fileIsTooLarge",
                            },
                            {
                                cond: "fileIsHeic",
                                target: "convertingHeicToPng",
                            },
                            {
                                actions: ["attachUploadableImage", "attachPreviewableImage"],
                                target: "creatingPreviewImage",
                            },
                        ],
                    },
                },
                fileIsUnsupportedFormat: {
                    on: {
                        RESTART: "idle",
                    },
                },
                fileIsTooLarge: {
                    on: {
                        RESTART: "idle",
                    },
                },
                convertingHeicToPng: {
                    invoke: {
                        src: "convertHeicToPng",
                        onDone: {
                            actions: ["attachUploadableImage", "attachPreviewableImage"],
                            target: "creatingPreviewImage",
                        },
                        onError: "failedToCreatePreviewImage",
                    },
                },
                creatingPreviewImage: {
                    invoke: {
                        src: "createPreviewImage",
                        onDone: {
                            actions: "cacheImagePreview",
                            target: "previewingImage",
                        },
                        onError: "failedToCreatePreviewImage",
                    },
                },
                failedToCreatePreviewImage: {
                    on: {
                        RESTART: "idle",
                    },
                },
                previewingImage: {
                    on: {
                        UPLOAD_IMAGE: "uploadingImage",
                        DELETE_FILE: "idle",
                        FILE_ADDED: [
                            {
                                cond: "isFileUnsupportedFormat",
                                target: "fileIsUnsupportedFormat",
                            },
                            {
                                cond: "isFileTooLarge",
                                target: "fileIsTooLarge",
                            },
                            {
                                cond: "fileIsHeic",
                                target: "convertingHeicToPng",
                            },
                            {
                                actions: ["attachUploadableImage", "attachPreviewableImage"],
                                target: "creatingPreviewImage",
                            },
                        ],
                    },
                },
                uploadingImage: {
                    invoke: {
                        src: "uploadImageToCloudinary",
                        onDone: "savingProfile",
                        onError: "errorUploadingImage",
                    },
                },
                savingProfile: {
                    invoke: {
                        src: "saveImageToProfile",
                        onDone: "imageUploaded",
                        onError: "errorUploadingImage",
                    },
                },
                imageUploaded: {
                    type: "final",
                },
                errorUploadingImage: {
                    on: {
                        RESTART: "idle",
                    },
                },
                deletingFile: {
                    invoke: {
                        src: "deleteFile",
                        onDone: "idle",
                        onError: "viewingExistingImage",
                    },
                },
            },
        },
        {
            services: {
                convertHeicToPng: async (context, event) =>
                    new Promise(async (resolve, reject) => {
                        try {
                            const convertedPng = await heic2any({
                                blob: event.data,
                                toType: "image/png",
                            });
                            resolve(convertedPng);
                        } catch (e) {
                            console.log("error thrown", e.message);
                            reject(new Error(e.message));
                        }
                    }),
                createPreviewImage: (context) =>
                    new Promise((resolve, reject) => {
                        try {
                            const reader = new FileReader();
                            reader.readAsDataURL(context.previewableImage);
                            reader.onload = (e) => {
                                resolve(e.target.result);
                            };
                        } catch (e) {
                            reject(new Error(e.message));
                        }
                    }),
                uploadImageToCloudinary: (context) =>
                    new Promise(async (resolve, reject) => {
                        try {
                            resolve(await avatarUploader(context.uploadableImage));
                        } catch (e) {
                            reject(new Error(e.message));
                        }
                    }),
                saveImageToProfile: (context, event) =>
                    new Promise((resolve, reject) => {
                        try {
                            resolve(ProfileService.saveAvatar(context.profile, event.data));
                        } catch (e) {
                            reject(new Error(e.message));
                        }
                    }),
                deleteFile: (ctx) =>
                    new Promise(async (resolve, reject) => {
                        try {
                            await ProfileService.deleteAvatar(ctx.profile.barcode);
                            resolve();
                        } catch (e) {
                            reject();
                        }
                    }),
            },
            actions: {
                attachPreviewableImage: assign({
                    previewableImage: (context, event) => event.data,
                }),
                attachUploadableImage: assign({
                    uploadableImage: (context, event) => event.data,
                }),
                cacheImagePreview: assign({
                    imagePreviewUrl: (context, event) => event.data,
                }),
                cacheExistingAvatar: assign({
                    imagePreviewUrl: (context) => context.profile.photo,
                }),
            },
            guards: {
                hasExistingAvatar: (context) => context.profile.photo,
                isFileUnsupportedFormat: (context, event) => {
                    const allowedFormats = ["heic", "jpg", "jpeg", "png", "bmp"];
                    return !allowedFormats.includes(event.data.type.split("/")[1]);
                },
                isFileTooLarge: (context, event) => {
                    const fiveMegabytes = 5000000;
                    return event.data.size > fiveMegabytes;
                },
                fileIsHeic: (context, event) => {
                    return event.data.type === "image/heic";
                },
            },
        }
    );

export default {
    data() {
        return {
            isDraggedOver: false,
            previewImage: "",
            avatarService: interpret(avatarMachineFactory(this.profile)).start(),
            currentAvatarState: avatarMachineFactory(this.profile).initialState,
        };
    },
    props: {
        profile: {
            type: Object,
        },
    },
    components: { ErrorMessageTag },
    created() {
        this.avatarService.onTransition(async (state) => {
            if (state.done) {
                this.$emit("avatar-saved");
            }
            if (state.value === "idle") {
                await this.$store.dispatch("getDefaultProfile");
                console.log("default profile loaded", this.$store.getters.profile);
            }
            this.currentAvatarState = state;
        });
    },
};
</script>
