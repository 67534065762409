import { defineStore } from "pinia";
import axios from "axios";
import endpoints from "@/endpoints";

export const useMatchGroupStore = defineStore("matchGroup", {
    state: () => ({
        matchGroups: [],
        hasChromosomeBrowserAccess: undefined,
    }),
    actions: {
        async getMatchGroups() {
            return await axios
                .get(endpoints.matchGroups)
                .then((res) => {
                    this.matchGroups = res.data.groups;
                    this.hasChromosomeBrowserAccess = res.data.has_chromosome_browser;
                })
                .catch((e) => console.log(e));
        },
        async addMatchGroup(details) {
            return await axios.post(endpoints.matchGroups, details).then(this.getMatchGroups);
        },
        async editGroup(group) {
            return await axios
                .put(`${endpoints.matchGroups}/${group.id}`, group)
                .then(this.getMatchGroups);
        },
        async deleteGroup(group) {
            return await axios
                .delete(`${endpoints.matchGroups}/${group.id}`)
                .then(this.getMatchGroups);
        },
    },
});
