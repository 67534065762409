<template>
    <main>
        <h1 class="font-normal text-2xl">Name</h1>

        <p class="my-4">Change the name associated with this profile</p>

        <form @submit.prevent="nameService.send('SAVE')">
            <text-input
                label="Name"
                :modelValue="currentNameState.context.name"
                @input.native="handleInput($event)"
            />

            <div
                v-if="currentNameState.matches('errorSavingName')"
                class="mt-2 text-sm text-rose-500"
            >
                {{ currentNameState.context.errorMessage }}
            </div>

            <SubmitButton
                icon="save"
                :disabled="matchesAny(['idle', 'hasInvalidInput'])"
                :class="{ loading: matchesAny(['savingName']) }"
                hide-icon
            >
                Save
            </SubmitButton>
            <div class="text-center">
                <router-link
                    :to="{ name: 'profile-view' }"
                    class="mt-2 p-2 inline-block text-grey-500"
                >
                    Cancel
                </router-link>
            </div>
        </form>
    </main>
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import profileService from "@/services/profile";
import TextInput from "@/components/inputs/TextInput.vue";
import SubmitButton from "@/components/button/SubmitButton.vue";

const nameIsLongerThanZeroCharacters = (name) => name.length > 0;
const nameHasChanged = (currentName, initialName) => currentName !== initialName;

const profileNameMachineFactory = (profile, barcode, initialName) =>
    new Machine(
        {
            id: "profile-name-machine",
            initial: "idle",
            context: {
                profile,
                barcode,
                initialName,
                name: initialName,
                errorMessage: "",
            },
            states: {
                idle: {
                    on: {
                        INPUT: "receivedInput",
                    },
                },
                hasValidInput: {
                    on: {
                        SAVE: "savingName",
                        INPUT: "receivedInput",
                    },
                },
                receivedInput: {
                    entry: "cacheName",
                    on: {
                        "": [
                            {
                                cond: "isInputValid",
                                target: "hasValidInput",
                            },
                            {
                                target: "hasInvalidInput",
                            },
                        ],
                    },
                },
                hasInvalidInput: {
                    on: {
                        INPUT: "receivedInput",
                    },
                },
                savingName: {
                    invoke: {
                        src: "saveName",
                        onDone: "nameSavedSuccessfully",
                        onError: {
                            target: "errorSavingName",
                            actions: "cacheErrorMessage",
                        },
                    },
                },
                nameSavedSuccessfully: {
                    type: "final",
                },
                errorSavingName: {
                    on: {
                        INPUT: "receivedInput",
                    },
                },
            },
        },
        {
            actions: {
                cacheName: assign((context, event) => ({
                    name: event.data,
                })),
                cacheErrorMessage: assign({
                    errorMessage: (context, event) => event.data.message,
                }),
            },
            guards: {
                isInputValid: (context) =>
                    nameIsLongerThanZeroCharacters(context.name) &&
                    nameHasChanged(context.name, context.initialName),
            },
            services: {
                saveName: (context) =>
                    new Promise(async (resolve, reject) => {
                        try {
                            await profileService.saveName(
                                context.barcode,
                                context.name,
                                context.profile
                            );
                            resolve();
                        } catch (e) {
                            reject(new Error(e.body.errors[0].detail));
                        }
                    }),
            },
        }
    );

export default {
    name: "EditName",

    data() {
        return {
            nameService: undefined,
            currentNameState: undefined,
        };
    },

    props: {
        profile: {
            type: Object,
            required: true,
        },
    },

    components: {
        TextInput,
        SubmitButton,
    },

    created() {
        const profileNameMachine = profileNameMachineFactory(
            this.profile,
            this.profile.barcode,
            this.profile.name
        );
        this.nameService = interpret(profileNameMachine);
        this.currentNameState = profileNameMachine.initialState;
        this.nameService
            .onTransition(async (state) => {
                if (state.done) {
                    this.$router.push({
                        name: "profile-view",
                        params: {
                            barcode: this.profile.barcode,
                        },
                    });
                }
                this.currentNameState = state;
            })
            .start();
    },

    methods: {
        matchesAny(states) {
            return !!states.find((state) => this.currentNameState.matches(state));
        },
        handleInput(event) {
            if (event.target) {
                this.nameService.send("INPUT", { data: event.target.value });
            }
        },
    },
};
</script>

<style></style>
