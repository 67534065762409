<template>
    <div class="mt-4">
        <label class="inline-flex items-center">
            <input
                type="checkbox"
                class="form-checkbox h-6 w-6"
                :value="value"
                @input="$emit('input', $event.target.checked)"
            />
            <span class="ml-3 text-xs" v-html="label"></span>
        </label>
    </div>
</template>
<script>
export default {
    name: "checkbox-input",
    props: {
        value: Boolean,
        label: {
            type: String,
            required: true,
        },
        // displayLabel: {
        //     type: Boolean,
        //     default: true,
        // },
        // placeholder: String,
        // type: {
        //     type: String,
        //     default: 'text',
        // },
        // errorBag: {
        //     type: Array,
        //     default: () => [],
        // },
        // errorKey: String,
        // disabled: {
        //     type: Boolean,
        //     default: false,
        // },
    },
};
</script>
