import axios from "axios";
import endpoints from "@/endpoints";

class UsersService {
    static async getUserInformation(uuid) {
        const { data } = await axios.get(`${endpoints.users}/${uuid}`);
        return data;
    }

    static async setUsersRegion(uuid, region) {
        const { data } = await axios.put(`${endpoints.users}/${uuid}/region`, {
            region,
        });
        return data;
    }
}

export default UsersService;
