import axios from "axios";
import endpoints from "@/endpoints";

export async function getArchaicResult(barcode) {
    const { data } = await axios.get(endpoints.archaicResult(barcode));
    return data;
}

export async function getArchaicIntroduction() {
    const { data } = await axios.get(endpoints.archaicCms("introduction"));
    return data;
}

export async function getArchaicUnderstanding() {
    const { data } = await axios.get(endpoints.archaicCms("understanding_your_results"));
    return data;
}

export async function getArchaicResultsCard() {
    const { data } = await axios.get(endpoints.archaicCms("archaic_results_card"));
    return data;
}

export async function getArchaicMapCard() {
    const { data } = await axios.get(endpoints.archaicCms("archaic_map_card"));
    return data;
}

export async function getArchaicTraitsCard() {
    const { data } = await axios.get(endpoints.archaicCms("archaic_traits_card"));
    return data;
}

export async function getArchaicNeanderthal() {
    const { data } = await axios.get(endpoints.archaicCms("neanderthal"));
    return data;
}

export async function getArchaicDenisovan() {
    const { data } = await axios.get(endpoints.archaicCms("denisovan"));
    return data;
}

export async function getArchaicTrait(trait) {
    const { data } = await axios.get(endpoints.archaicCms(trait));
    return data;
}

export async function getArchaicReferences() {
    const { data } = await axios.get(endpoints.archaicCms("references"));
    return data;
}

export async function getArchaicTraits() {
    const { data } = await axios.get(endpoints.archaicCms("archaic_traits"));
    return data;
}

export async function getClosestSampleInfo(sample) {
    const { data } = await axios.get(endpoints.archaicCms(sample));
    return data;
}
