<template>
    <div>
        <div class="prose m-auto">
            <h3>{{ sharingStore.name }}'s Fatherline</h3>
            <div class="not-prose">
                <HaplogroupCards
                    class="max-w-full"
                    type="ydna"
                    border
                    :result="{
                        haplogroup: sharingStore.fatherlineResult.haplogroup,
                        subclade: sharingStore.fatherlineResult.subclade,
                    }"
                />
            </div>
            <p>
                The Y chromosome is one of a pair of your sex chromosomes. It is passed from father
                to sons, relatively unchanged which allows us to explore your paternal lineage.
            </p>

            <p>
                By looking at genetic variants we are able to establish patterns of similarity
                between Y Chromosomes. Like a tree as the trunk grows and becomes stronger, it also
                branches off in different directions.
            </p>

            <p>
                This means we are able to provide you with a unique code that is used to describe a
                group of people who all share a common ancestor, known as your paternal haplogroup.
            </p>
        </div>
        <SharingFatherlineMap class="mt-16" />
    </div>
</template>
<script setup>
import HaplogroupCards from "@/components/ancestry/HaplogroupCards.vue";
import SharingFatherlineMap from "@/views/sharing/SharingFatherlineMap.vue";
import { useSharingStore } from "@/store/sharing";

const sharingStore = useSharingStore();
</script>
