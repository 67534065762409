<template>
    <MigrationMap :result="fatherlineStore.result" type="ydna" />
</template>

<script setup>
import MigrationMap from "@/components/ancestry/MigrationMap.vue";
import { useFatherlineStore } from "@/store/parentalStore";

const fatherlineStore = useFatherlineStore();
</script>
