<template>
    <NavBar :key="store.getters.kitTests.autosomal.updateAvailable" :tabs="tabs" />
</template>

<script setup>
import { watch } from "vue";
import NavBar from "@/components/partials/NavBar.vue";
import store from "@/store/store";

let tabs;

watch(
    () => store.getters.kitTests.autosomal.updateAvailable,
    () => {
        console.log("got called");
        tabs = [
            {
                name: "Overview",
                to: { name: "recent-overview" },
                icon: "home",
            },
            {
                name: "Map",
                to: { name: "recent-map" },
                icon: "map-pin",
            },
            {
                name: "Versions",
                to: { name: "recent-versions" },
                icon: "list",
                label: store.getters.kitTests.autosomal.updateAvailable ? "Update" : false,
            },
            {
                name: "Book",
                to: { name: "book-pdf" },
                icon: "book-open",
                label: "New",
            },
        ];
    },
    { immediate: true }
);
</script>
