import endpoints from "@/endpoints";

export default {
    state: {
        whoIsThisKitFor: {},
        activationCode: "",
    },
    actions: {
        setActivationUserInformation({ commit }, info) {
            return new Promise(resolve => {
                commit("setActivationUserInformation", info);
                resolve();
            });
        },
        setActivationCode({ commit }, { code, http }) {
            return new Promise((resolve, reject) => {
                http.get(`${endpoints.verifyActivation}/${code}`)
                    .then(() => {
                        commit("setActivationCode", code);
                        resolve();
                    })
                    .catch(reject);
            });
        },
        activateKit(_, { activationDetails, http }) {
            return http.post(endpoints.kitActivationActivate, activationDetails);
        },
        checkActivationCode({ commit }, { activationCode, http }) {
            // fails ETLTBJ6PX5GA
            // passes YZJJ997XMT53
            return new Promise((resolve, reject) => {
                http.get(`${endpoints.checkActivationCode}/${activationCode}`)
                    .then(() => {
                        commit("setActivationCode", activationCode);
                        resolve();
                    })
                    .catch(() => {
                        reject(new Error("Can not be activated"));
                    });
            });
        },
    },

    // eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1Njk1ODAwMTQsImV4cCI6MTU2OTYwODgxNCwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImVtYWlsIjoidGVzdDNAdGVzdC5jb20iLCJiZXRhIjp0cnVlLCJzdWIiOiI3OThlOTk5ZC05M2IxLTExZTctYWI1Ni01MjU0MDAyZmQxYTQiLCJpbnZpdGVkQnlPdGhlclVzZXIiOmZhbHNlLCJwYXJ0bmVycyI6W10sImNvYnJhbmRlZFByb2ZpbGVzIjpbXSwicHJldmlvdXNMb2dpbiI6bnVsbH0.NIjmKOheqMQZKwpJxqYsB9RRbvR_3g06CtpIKWk-Cqaplsj5PeeaE_Td-f5fj6wv7ayI4vfuznAetGrSgi6ciwbyHm06npY5ZBhcQoQsrSshbOg1AREEKuFTnrhbzzr4pLT20Ip-ppN3IrbDtVUTHVxaC3ojjZURFkrpUCGckDC0xJy5UPk8Z-oOXbuQ_J7xzMNh-g5lH4ksxefEiT54QVGcM_hJz7dZlXnp6E4yscWrW-9P6nJqta7FuxBpwRy1UlnaD7bSV_OLz4CypvfjZU8rsuna6bUHa8ez95CRlLgnfQzlBQEexdgh94Mw_VsDVWYqD5g63JY9gH8bxriwQzkWStJKOpw8VZDkbAO5YSQ_6SfTq_VrXs2sSf4AFIIwq4X_WRRwSrtQ0pW3It6UskcLQnos6ibEhGqkJFmCfZl-unXIM-5Yfh-R2iHm1BGgJk6zQ_ro5HhH0iq7lw3tbUMzYtz_NldUv58vQFfo-nCgqs39Qy1HR_5azDfbnqnwWea5RxapRvjs5UT9ZmVvMjY7CG9tuqnr2-SHzmiR-Qhi69xTakIJuaSz1OxQtvRFMtBixKTpmOQTya0QqzKiX-JaNzm-RLFBk48jOljJCpEkjOcT-BfKiSUYUcQhOSO8T0fxZBt8wq-3oqFQf-SrAbDu4qL4EBuVfdd58dExbqw--REFRESHTOKEN--fea8f0b0d104a57f3fa866620792b401af76a544fe732bfc9b7208c165b3be4482331ff37d0ab6cf213eb6ef17a3e766cc9e7450e1c7ab5289a20b01ec033ae4
    mutations: {
        setActivationUserInformation(state, data) {
            state.whoIsThisKitFor = data;
        },
        setActivationCode(state, activationCode) {
            state.activationCode = activationCode;
        },
    },
};
