<template>
    <div class="mt-auto ml-auto mb-8 mr-8 z-10" :class="{ 'text-white': white }">
        <div class="text-sm">
            <a
                href="https://support.livingdna.com/hc/en-us"
                target="_blank"
                rel="noopener"
                class="mr-5"
                :class="{ 'text-white': white }"
            >
                <span :class="{ 'text-white': white }"> Help </span>
            </a>

            <a
                href="https://livingdna.com/uk/legal/Terms-Of-Use"
                target="_blank"
                rel="noopener"
                class="mr-5"
                :class="{ 'text-white': white }"
            >
                <span :class="{ 'text-white': white }"> Terms </span>
            </a>

            <a
                href="https://livingdna.com/uk/privacy-centre"
                target="_blank"
                rel="noopener"
                :class="{
                    'text-white': white,
                    'mr-5': isLoggedIn,
                }"
            >
                <span
                    :class="{
                        'text-white': white,
                        'mr-5': isLoggedIn,
                    }"
                >
                    Privacy
                </span>
            </a>

            <button
                v-if="isLoggedIn"
                :class="white ? 'text-white' : 'text-cobalt-500'"
                @click.prevent="onLogout"
            >
                Sign out
            </button>
        </div>

        <div class="text-xs mt-4 md:text-right pb-4 md:pb-0">
            &copy; 2019-{{ new Date().getFullYear() }} Living DNA
        </div>
    </div>
</template>

<script>
export default {
    name: "FooterLinks",

    props: {
        white: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        onLogout() {
            this.$emit("logout");
            this.$auth.logout();
        },
    },

    computed: {
        isLoggedIn() {
            return this.$auth.check();
        },
    },
};
</script>
