<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="currentColor"
        stroke="none"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <g fill-rule="evenodd">
            <path
                d="M16.6610864,4.00353713 L14.5023957,4 C12.0771806,4 10.5099001,5.64211479 10.5099001,8.18372294 L10.5099001,10.1126994 L8.33943312,10.1126994 C8.151879,10.1126994 8,10.2679796 8,10.4595154 L8,13.2543824 C8,13.4459183 8.15205218,13.6010216 8.33943312,13.6010216 L10.5099001,13.6010216 L10.5099001,20.6533608 C10.5099001,20.8448966 10.6617791,21 10.8493333,21 L13.6811753,21 C13.8687294,21 14.0206084,20.8447198 14.0206084,20.6533608 L14.0206084,13.6010216 L16.5583906,13.6010216 C16.7459447,13.6010216 16.8978237,13.4459183 16.8978237,13.2543824 L16.8988628,10.4595154 C16.8988628,10.3675499 16.8630145,10.2794753 16.7994574,10.214392 C16.7359002,10.1493087 16.6493102,10.1126994 16.5592565,10.1126994 L14.0206084,10.1126994 L14.0206084,8.47748198 C14.0206084,7.69153064 14.2040062,7.29254185 15.2065462,7.29254185 L16.6607401,7.29201128 C16.848121,7.29201128 17,7.13673106 17,6.94537208 L17,4.35017634 C17,4.15899421 16.8482942,4.00389085 16.6610864,4.00353713 Z"
            ></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: "FacebookOfficial",
};
</script>
