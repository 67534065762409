<template>
    <div class="flex flex-wrap -mx-3">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <WellbeingReportCard
                v-if="randomNutritionReport && randomNutritionReport.phenotypeCmsData"
                type="Nutrition"
                :image="
                    randomNutritionReport.phenotypeCmsData.keyTakeawayImage
                        ? randomNutritionReport.phenotypeCmsData.keyTakeawayImage[0].urlRaw
                        : ''
                "
                :text="randomNutritionReport.phenotypeCmsData.keyTakeaway"
                :slug="randomNutritionReport.traitCmsData.slug"
                :name="randomNutritionReport.traitCmsData.title"
            />
            <div class="bg-white p-4 rounded shadow" v-else>No result data could be loaded</div>
        </div>
        <div class="w-full md:w-1/2 px-3">
            <WellbeingReportCard
                v-if="randomFitnessReport && randomFitnessReport.phenotypeCmsData"
                type="Fitness"
                :image="
                    randomFitnessReport.phenotypeCmsData.keyTakeawayImage
                        ? randomFitnessReport.phenotypeCmsData.keyTakeawayImage[0].urlRaw
                        : ''
                "
                :text="randomFitnessReport.phenotypeCmsData.keyTakeaway"
                :slug="randomFitnessReport.traitCmsData.slug"
                :name="randomFitnessReport.traitCmsData.title"
            />
            <div class="bg-white p-4 rounded shadow" v-else>No result data could be loaded</div>
        </div>
    </div>
</template>

<script>
import WellbeingReportCard from "@/components/Dashboard/Wellbeing/WellbeingReportCard.vue";
import { mapGetters } from "vuex";

export default {
    components: { WellbeingReportCard },
    computed: {
        ...mapGetters(["randomFitnessReport", "randomNutritionReport"]),
    },
};
</script>
