<template>
    <div v-if="type === 'disabled'" class="rounded-full h-12 w-12 flex" :class="classes">
        <feather :type="`chevron-${side}`" class="fill-current m-auto h-6 w-6" />
    </div>
    <button v-else class="rounded-full h-12 w-12 flex" :class="classes" @click="handleClick">
        <feather :type="`chevron-${side}`" class="fill-current m-auto h-6 w-6" />
    </button>
</template>

<script>
import Feather from "vue-feather";

export default {
    components: { Feather },
    props: {
        side: {
            required: true,
            type: String,
        },
        type: {
            type: String,
            default: "primary",
        },
    },
    computed: {
        sideNameToMarginCharacter() {
            return {
                right: "r",
                left: "l",
            }[this.side];
        },
        classes() {
            return {
                primary: "bg-cobalt-500 text-white",
                secondary: "bg-white text-cobalt-500 shadow",
                disabled: "bg-grey-50 text-grey-500 shadow",
            }[this.type];
        },
    },
    methods: {
        handleClick() {
            if (this.type !== "disabled") {
                console.log("inner was called");
                this.$emit("button-click");
            }
        },
    },
};
</script>
