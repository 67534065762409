export default {
    actions: {
        LOGOUT_SUCCESS({ dispatch }) {
            dispatch("CLEAR_TESTS_MODULE");
            dispatch("CLEAR_PROFILE_MODULE");
            dispatch("CLEAR_MATERNAL_MODULE");
            dispatch("CLEAR_PATERNAL_MODULE");
            dispatch("CLEAR_AUTOSOMAL_MODULE");
            dispatch("CLEAR_DASHBOARD_MODULE");

            dispatch("CLEAR_TOKEN");
            dispatch("CLEAR_ACCOUNT_DATA");
        },
    },
};
