<template>
    <div>
        <label class="block font-semibold text-sm" :class="{ 'sr-only': hideLabel }">
            {{ label }}
        </label>
        <p v-if="description" class="text-sm leading-5 text-gray-500">
            {{ description }}
        </p>
        <fieldset class="space-y-5 mt-2">
            <div class="relative flex items-start" v-for="option in options" :key="option.id">
                <div class="flex h-5 items-center">
                    <input
                        v-model="selected"
                        :id="option.id"
                        :name="option.id"
                        :value="option.id"
                        type="checkbox"
                        class="h-4 w-4 rounded border-gray-300 text-cobalt-500 focus:ring-cobalt-500"
                        @change="$emit('update:modelValue', selected)"
                    />
                </div>
                <div class="ml-3">
                    <label :for="option.id" class="font-medium text-gray-700 cursor-pointer">
                        {{ option.title }}
                    </label>
                    <p class="text-gray-500" v-if="option.description">
                        {{ option.description }}
                    </p>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
    label: String,
    description: String,
    options: Array,
    modelValue: undefined,
    hideLabel: Boolean,
});

const selected = ref(props.modelValue);
</script>
