<script>
export default {
    render() {
        return this.$scopedSlots.default({
            dateString: this.dateString,
        });
    },
    props: {
        dateObject: {
            required: true,
        },
    },
    computed: {
        dateString() {
            if (this.dateObject === null) {
                return null;
            }
            if (this.dateObject.date === null) {
                return null;
            }
            if (this.dateObject.date.date === null) {
                return null;
            }
            const [date] = this.dateObject.date.date.split(" ");
            return date;
        },
    },
};
</script>
