<template>
    <router-link :to="{ name: 'product', params: { slug: 'Wellbeing-Upgrade' } }">
        <div class="bg-tangerine-500 flex justify-center">
            <div class="flex items-center text-white my-2 px-6">
                <feather type="alert-circle" class="h-5 w-5 fill-current mr-2 flex-2" />
                <p class="my-0 flex-1">
                    Click here to refine your results with a Living DNA Wellbeing upgrade.
                </p>
            </div>
        </div>
    </router-link>
</template>

<script>
import Feather from "vue-feather";

export default {
    components: { Feather },
    props: { alertText: String },
};
</script>
