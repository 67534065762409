<template>
    <!--    <div class="shadow rounded-lg bg-white h-full flex flex-col ">-->
    <!--        <div class="h-16 overflow-hidden rounded-t-lg fade-bottom">-->
    <!--            <img :src="image" alt="Explore your motherline" class="w-full" />-->
    <!--        </div>-->
    <!--        <div class="px-6 pb-6">-->
    <!--            <p style="font-size: 15px">Your maternal haplogroup is {{ maternalData.group }}.</p>-->
    <!--            <p class="text-grey-600 text-sm">-->
    <!--                Your maternal DNA is most commonly found in {{ maternalData.region }}.-->
    <!--            </p>-->
    <!--        </div>-->
    <!--        <router-link-->
    <!--            :to="{ path: '/ancestry/maternal/migration-map' }"-->
    <!--            class="flex items-center mt-auto px-6 pb-6 mt-auto"-->
    <!--        >-->
    <!--            <feather-wrapper icon="female" />-->
    <!--            <span class="ml-2 font-semibold">Maternal Ancestry</span>-->
    <!--        </router-link>-->
    <!--    </div>-->
    <div class="shadow rounded-lg bg-white h-full flex flex-col p-6 border-t-4 border-rose-500">
        <div>
            <div class="text-sm text-gray-600">Maternal haplogroup</div>
            <div class="text-rose-500 text-2xl font-semibold">
                {{ motherlineStore.result.haplogroup }}
            </div>
        </div>
        <div v-if="motherlineStore.result.subclade" class="mt-4">
            <div class="text-sm text-gray-600">Maternal haplotype</div>
            <div class="text-rose-500 text-2xl font-semibold">
                {{ motherlineStore.result.subclade }}
            </div>
        </div>
        <p class="mt-4">
            Your maternal DNA is most commonly found in <strong>{{ maternalData.region }}</strong
            >.
        </p>
        <router-link :to="{ name: 'motherline' }" class="flex items-center text-rose-500 mt-4">
            <div class="flex-grow-0">
                <feather-wrapper icon="female" />
            </div>

            <span class="ml-2 font-semibold">Maternal Ancestry</span>
        </router-link>
    </div>
</template>

<script setup>
import { useMotherlineStore } from "@/store/parentalStore";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

const motherlineStore = useMotherlineStore();

motherlineStore.getResult();

defineProps({
    maternalData: Object,
});
</script>
