<template>
    <svg id="Icons" viewBox="0 0 74 74" width="512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m59.329 39.42a1 1 0 0 1 -.785-1.618c.611-.779 1.2-1.558 1.766-2.338a1 1 0 0 1 1.621 1.171c-.578.8-1.186 1.6-1.814 2.4a1 1 0 0 1 -.788.385z"
        />
        <path
            d="m32.93 59.67a1 1 0 0 1 -.707-.293l-17.6-17.6a1 1 0 0 1 -.191-1.147 94.064 94.064 0 0 1 4.558-8.248 78.14 78.14 0 0 1 7.394-10.156c10.577-12.279 22.096-17.064 29.897-18.916a41.121 41.121 0 0 1 14.656-1.078 1 1 0 0 1 .831.832c.082.516 1.913 12.811-5.948 27.381a1 1 0 0 1 -1.759-.949 46.6 46.6 0 0 0 5.816-25.369c-4.122-.357-24.451-.942-41.977 19.406a76.167 76.167 0 0 0 -7.206 9.9c-1.472 2.37-2.865 4.867-4.147 7.435l16.583 16.588c2.574-1.285 5.071-2.678 7.432-4.145a76.441 76.441 0 0 0 9.9-7.207c1.158-.992 2.324-2.068 3.47-3.2a1 1 0 0 1 1.408 1.42c-1.18 1.17-2.382 2.28-3.573 3.3a78.4 78.4 0 0 1 -10.157 7.392 94 94 0 0 1 -8.245 4.556.991.991 0 0 1 -.435.098z"
        />
        <path
            d="m68.521 22.669a1 1 0 0 1 -.707-.293l-16.191-16.19a1 1 0 0 1 1.414-1.415l16.192 16.191a1 1 0 0 1 -.708 1.707z"
        />
        <path
            d="m47.456 34.994a8.452 8.452 0 1 1 5.978-14.428 8.451 8.451 0 0 1 -5.978 14.428zm0-14.9a6.446 6.446 0 1 0 4.564 1.886 6.435 6.435 0 0 0 -4.564-1.886z"
        />
        <path
            d="m4.175 34.329a1 1 0 0 1 -.987-1.161 15.874 15.874 0 0 1 2.463-6.268 16.108 16.108 0 0 1 22.016-4.873 1 1 0 0 1 -1.051 1.7 14.114 14.114 0 0 0 -19.3 4.275 13.948 13.948 0 0 0 -1.64 3.366 16.08 16.08 0 0 1 14.654.671 1 1 0 1 1 -.99 1.738 14.083 14.083 0 0 0 -14.627.39 1 1 0 0 1 -.538.162z"
        />
        <path
            d="m40.671 70.825a1 1 0 0 1 -.841-1.54 14.085 14.085 0 0 0 .39-14.628 1 1 0 1 1 1.738-.99 16.074 16.074 0 0 1 .671 14.654 13.915 13.915 0 0 0 3.362-1.638 14.119 14.119 0 0 0 4.28-19.3 1 1 0 1 1 1.7-1.05 16.107 16.107 0 0 1 -4.871 22.018 15.865 15.865 0 0 1 -6.263 2.461.971.971 0 0 1 -.166.013z"
        />
        <path
            d="m16.657 72a1 1 0 0 1 -.707-1.707l8.8-8.8a1 1 0 0 1 1.414 1.414l-8.8 8.8a1 1 0 0 1 -.707.293z"
        />
        <path
            d="m4.329 70.671a1 1 0 0 1 -.707-1.707l8.8-8.8a1 1 0 1 1 1.414 1.414l-8.8 8.8a1 1 0 0 1 -.707.293z"
        />
        <path
            d="m3 58.342a1 1 0 0 1 -.707-1.707l8.8-8.8a1 1 0 1 1 1.414 1.414l-8.8 8.8a.993.993 0 0 1 -.707.293z"
        />
    </svg>
</template>
