import { toUpper, snakeCase } from "lodash";
import { pipe } from "lodash/fp";

export default (type, stateKey = "loadingStatus") => {
    const t = pipe(snakeCase, toUpper)(type);

    const PENDING = `${t}_LOADING_PENDING`;
    const SUCCESS = `${t}_LOADING_SUCCESS`;
    const FAILED = `${t}_LOADING_FAILED`;

    return {
        loadingState: {
            PENDING,
            SUCCESS,
            FAILED,
        },
        loadingMutations: {
            [PENDING](state) {
                state[stateKey] = PENDING;
            },
            [SUCCESS](state) {
                state[stateKey] = SUCCESS;
            },
            [FAILED](state) {
                state[stateKey] = FAILED;
            },
        },
    };
};

export function convertToCurrency(value, type) {
    if (value === 0) {
        return "FREE";
    }
    if (typeof type !== "undefined") {
        return Number(value)
            .toLocaleString(undefined, {
                style: "currency",
                currency: type,
            })
            .split(".")[0];
    }
    return Number(0).toLocaleString(undefined, { style: "currency", currency: "USD" });
}
