<template>
    <NavBar :tabs="tabs" />
</template>

<script>
import NavBar from "@/components/partials/NavBar.vue";

export default {
    components: { NavBar },

    setup() {
        const tabs = [
            {
                name: "Overview",
                to: { path: "/archaic/overview" },
                icon: "home",
            },
            {
                name: "Map",
                to: { path: "/archaic/maps" },
                icon: "map",
            },
            {
                name: "Results",
                to: { path: "/archaic/results" },
                icon: "list",
            },
            {
                name: "Traits",
                to: { path: "/archaic/traits" },
                icon: "helix",
            },
            {
                name: "References",
                to: { path: "/archaic/references" },
                icon: "book-open",
            },
        ];
        return { tabs };
    },
};
</script>
