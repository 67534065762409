import { findSubstring } from "./string-helper";

function detectRegion() {
    if (window.navigator) {
        return window.navigator.userLanguage || window.navigator.language;
    }
    // return 'US';
}

export default country => findSubstring(detectRegion(), country);
