<template>
    <div>
        <div class="mt-12 rounded bg-emerald-50 text-emerald-500">
            <div class="flex items-stretch justify-between">
                <div class="py-3 px-4">{{ fileName }}</div>
                <!--                <div class="hover:bg-emerald-50 flex px-4 cursor-pointer" @click="$emit('cancelled')">-->
                <!--                    <vue-feather type="x" class="my-auto fill-current text-emerald-500 h-5 w-5"></vue-feather>-->
                <!--                </div>-->
            </div>
        </div>
        <div class="text-center text-xs mt-2">File successfully uploaded.</div>
        <template v-if="!embedded">
            <a
                v-if="finishedSaving"
                href="/dashboard"
                class="bg-cobalt-500 text-white rounded w-full py-2 px-4 font-semibold mt-6 w-full inline-block text-center"
            >
                Finish
            </a>
            <span
                v-else
                href="/dashboard"
                class="text-white rounded w-full py-2 px-4 font-semibold mt-6 w-full inline-block text-center loading"
            >
                Finish
            </span>
        </template>
    </div>
</template>

<script>
import VueFeather from "vue-feather";

export default {
    components: { VueFeather },
    props: {
        fileName: {
            required: true,
            type: String,
        },
        finishedSaving: Boolean,
        embedded: Boolean,
    },
};
</script>
