<template>
    <div class="block xl:hidden">
        <div class="py-4 mx-2">
            <nav class="flex space-x-4" aria-label="Tabs">
                <button
                    v-for="tab in tabs"
                    :key="tab.name"
                    :href="tab.href"
                    :class="[
                        tab.current
                            ? 'bg-cobalt-50 text-cobalt-700'
                            : 'text-cobalt-500 hover:text-cobalt-700',
                        'px-3 py-2 font-medium text-sm rounded-md font-semibold',
                    ]"
                    :aria-current="tab.current ? 'page' : undefined"
                    @click="selectMode(tab.mode)"
                >
                    {{ tab.name }}
                </button>
            </nav>
        </div>
    </div>
</template>

<script>
import { ref, watch } from "vue";
const tabs = ref([
    { name: "Map", mode: "map", current: false },
    { name: "More info", mode: "info", current: false },
]);

export default {
    props: {
        modelValue: String,
    },
    setup(props, { emit }) {
        tabs.value = tabs.value.map((tab, index) => {
            if (tab.mode === props.modelValue) {
                tab.current = true;
            }
            return tab;
        });
        function selectMode(mode) {
            emit("update:modelValue", mode);
            tabs.value = tabs.value.map((tab, index) => {
                tab.current = false;
                if (tab.mode === mode) {
                    tab.current = true;
                }
                return tab;
            });
        }
        console.log(tabs.value);
        return {
            tabs,
            selectMode,
        };
    },
};
</script>
