<template>
    <Overview>
        <template #sidebar>
            <section class="prose">
                <div class="fade-bottom">
                    <img
                        src="https://images.unsplash.com/photo-1534821084228-d985662dfb56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80"
                        class="w-full h-64 object-cover m-0"
                    />
                </div>
                <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                    <h1 class="text-xl">Your motherline</h1>
                    <div>
                        <p>
                            Mitochondrial or MT DNA is found in the mitochondria cells. It contains
                            16,500 DNA bases, meaning it is much smaller than the autosomal genome
                            (3 billion) or the Y chromosome (50,000). You will see this if you
                            choose to download your raw data.
                        </p>
                        <p>
                            Your Mitochondrial DNA is passed directly to you by your mother, and to
                            her by her mother and so on without undergoing any changes
                            (recombination). This means your MT DNA information is identical to your
                            mother, because of this we can tell you about your ancestral information
                            from your mother.
                        </p>
                        <p>
                            Unlike Y Chromosome your mitochondrial DNA is found in both men and
                            women so anyone who takes a test will be able to receive these results.
                        </p>
                        <h3>You and your maternal Haplogroup</h3>

                        <p>
                            Your mitochondrial test provides you with your maternal haplogroup,
                            which is a code that describes a group of people that share a common
                            ancestor. From this test you can confirm whether you share a maternal
                            ancestor in the last 50 generations with someone with a high degree of
                            confidence.
                        </p>

                        <p>
                            Your haplogroup can tell you the storey of your ancestors from 10,000s
                            of thousands of years ago. You can discover where they originated from,
                            which migration routes they followed as well as their current
                            distribution throughout the world.
                        </p>
                    </div>
                    <!--                    <div v-html="" />-->
                </div>
            </section>
        </template>
        <template #desktopContent>
            <HaplogroupCards :result="motherlineStore.result" type="mtdna" />
            <OverviewCard
                :linkTo="{ name: 'motherline-history' }"
                :title-text="'History of your haplogroup'"
                :description-text="'Discover the history of your haplogroup (and your haplotype if you have one)'"
                link-text="Haplotype history"
                :image="{
                    src: motherlineHistory,
                    alt: 'Hello there',
                }"
            />
            <OverviewCard
                :linkTo="{ name: 'motherline-distribution-map' }"
                :title-text="'Distribution map'"
                :description-text="'See where people who share your haplogroup are living today'"
                link-text="View coverage map"
                :image="{
                    src: covererageMap,
                    alt: 'Coverage map',
                }"
            />
            <OverviewCard
                :linkTo="{ name: 'motherline-migration-map' }"
                :title-text="'Migration map'"
                :description-text="'Explore the journey your ancestors have taken'"
                link-text="View migration map"
                :image="{
                    src: migrationMap,
                    alt: 'Migration map',
                }"
            />
        </template>
        <template #mobileContent>
            <section>
                <div class="fade-bottom">
                    <img
                        src="https://images.unsplash.com/photo-1534821084228-d985662dfb56?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80"
                        class="w-full h-64 object-cover m-0"
                    />
                </div>
                <div class="py-6 px-4 sm:px-6 lg:px-8 prose mx-auto">
                    <h1 class="text-xl">Your motherline</h1>
                    <div>
                        <p>
                            Mitochondrial or MT DNA is found in the mitochondria cells. It contains
                            16,500 DNA bases, meaning it is much smaller than the autosomal genome
                            (3 billion) or the Y chromosome (50,000). You will see this if you
                            choose to download your raw data.
                        </p>
                        <p>
                            Your Mitochondrial DNA is passed directly to you by your mother, and to
                            her by her mother and so on without undergoing any changes
                            (recombination). This means your MT DNA information is identical to your
                            mother, because of this we can tell you about your ancestral information
                            from your mother.
                        </p>
                        <p>
                            Unlike Y Chromosome your mitochondrial DNA is found in both men and
                            women so anyone who takes a test will be able to receive these results.
                        </p>
                        <h3>You and your maternal Haplogroup</h3>

                        <p>
                            Your mitochondrial test provides you with your maternal haplogroup,
                            which is a code that describes a group of people that share a common
                            ancestor. From this test you can confirm whether you share a maternal
                            ancestor in the last 50 generations with someone with a high degree of
                            confidence.
                        </p>

                        <p>
                            Your haplogroup can tell you the storey of your ancestors from 10,000s
                            of thousands of years ago. You can discover where they originated from,
                            which migration routes they followed as well as their current
                            distribution throughout the world.
                        </p>
                    </div>
                    <!--                    <div v-html="" />-->
                </div>
            </section>
        </template>
    </Overview>
</template>
<script setup>
import Overview from "@/components/Overview.vue";
import OverviewCard from "@/components/OverviewCard.vue";
import migrationMap from "@/assets/images/motherline/example-migration-map.png";
import covererageMap from "@/assets/images/motherline/example-coverage-map.png";
import motherlineHistory from "@/assets/images/motherline/motherline-history.jpg";
import { useMotherlineStore } from "@/store/parentalStore";
import HaplogroupCards from "@/components/ancestry/HaplogroupCards.vue";

const motherlineStore = useMotherlineStore();
</script>
