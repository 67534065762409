<template>
    <div class="h-full w-full">
        <div id="map" class="h-full w-full"></div>
    </div>
</template>

<script>
import { onMounted, watch, onUnmounted } from "vue";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { vikingAccessToken, vikingMapStyle } from "@/views/ancestry/recent/mapboxConfig";

export default {
    props: ["mapData"],
    setup(props) {
        mapboxgl.accessToken = vikingAccessToken;
        onMounted(() => {
            const map = new mapboxgl.Map({
                container: "map",
                style: vikingMapStyle,
                center: [-2.3018512, 51.2343397],
                zoom: 1,
            });

            map.on("load", () => {
                const [feature] = JSON.parse(props.mapData).features;
                map.addSource("vikingLayer", {
                    type: "geojson",
                    data: {
                        type: "Feature",
                        geometry: {
                            type: feature.geometry.type,
                            coordinates: feature.geometry.coordinates,
                        },
                    },
                });

                map.addLayer({
                    id: "vikingLayer",
                    type: "fill",
                    source: "vikingLayer",
                    layout: {},
                    paint: {
                        "fill-color": "#E09800",
                        "fill-opacity": 0.5,
                    },
                });

                const bbox = turf.bbox(feature);
                map.fitBounds(bbox, { padding: 250 });
            });

            onUnmounted(() => {
                map.remove();
            });
        });
    },
};
</script>
