<template>
    <div>
        <div class="flex flex-col">
            <div class="bg-emerald-50 rounded-full p-2 inline-flex mx-auto">
                <Feather type="check" class="fill-current text-emerald-500 m-auto h-8 w-8" />
            </div>
            <h1 class="mt-4 mb-0 font-normal text-2xl text-center">Upload complete</h1>
            <template v-if="page === 1">
                <p class="mt-4 mb-0 text-grey-700 text-center">
                    Congratulations, your file has been uploaded! The first step is for us to run
                    some checks to make sure we can process the file. If there's any issues we'll
                    email you and you'll be able to reupload. If we're able to process the file then
                    you should get your taster results within 24 hours.
                </p>
                <p class="mt-4 mb-0 text-grey-700 text-center">
                    Explore your DNA with our taster results. You'll receive a detailed
                    continental-level ancestry breakdown along with two valuable trait reports on
                    Vitamin D and Strength. Discover your roots and well-being in a single package.
                </p>

                <p v-if="fullProfile.profile.fnConsent" class="mt-4 mb-0 text-grey-700 text-center">
                    You've opted into family networks! New family networks results are released
                    approximately every 10 days. Once your results have finished processing you will
                    be included in the next run of family networks. This means won't receive your
                    family networks results at the same time as the rest of your results.
                </p>
                <p v-else class="mt-4 mb-0 text-grey-700 text-center">
                    You haven't opted into family networks. If you change your mind you can opt in
                    at any time by visiting the Relatives page. If this was a mistake you can go
                    back and opt in now by
                    <router-link :to="{ name: 'upload-family-networks-opt-in' }">
                        clicking here </router-link
                    >.
                </p>
                <button
                    @click="page = 2"
                    class="bg-cobalt-500 text-white rounded py-2 text-center mt-6"
                >
                    <span class="text-white font-semibold">Next</span>
                </button>
            </template>
            <template v-else>
                <p class="mt-4 mb-0 text-grey-700 text-center">
                    Maximize the value of your upload with our Full Autosomal Upgrade! Unlock a
                    comprehensive breakdown of your ancestry, gain access to advanced family
                    matching tools, and discover your heritage like never before.
                </p>
                <router-link
                    to="/store/autosomal-upgrade"
                    class="bg-cobalt-500 text-white rounded py-2 text-center mt-6"
                >
                    <span class="text-white font-semibold">Upgrade to full autosomal</span>
                </router-link>
                <router-link
                    :to="{ name: 'dashboard' }"
                    class="text-center mt-4 text-cobalt-500 font-semibold"
                >
                    Go to dashboard
                </router-link>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    async created() {
        await this.$store.dispatch("getDefaultProfile");
        await this.$store.dispatch("GET_FULL_PROFILE", this.$store.getters.profile.barcode);
        console.log(this.$store.getters.fullProfile);
    },
    data() {
        return {
            page: 1,
        };
    },
    computed: {
        ...mapGetters(["fullProfile"]),
    },
};
</script>
