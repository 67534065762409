<template>
    <div>
        <h3 class="text-denim-700 text-2xl mt-12 flex items-center font-normal mb-6">
            <feather type="heart" class="h-5 w-5 mr-4"></feather>
            {{ matchesAny(["useNewWellbeing"]) ? "Health, Lifestyle & You" : "Wellbeing" }}
        </h3>

        <NewWellbeingDashboardPrompt class="max-w-3xl" v-if="matchesAny(['useNewWellbeing'])" />

        <template v-else-if="matchesAny(['fullCompleted'])">
            <div class="flex flex-wrap xl:-mx-3">
                <div class="w-full xl:w-1/2 xl:px-3">
                    <WellbeingReportCards />
                    <WellbeingSupplementCard class="mt-6" />
                </div>
                <div class="w-full xl:w-1/2 mt-6 xl:mt-0 xl:px-3">
                    <WellbeingReportList :reports="wellbeing" />
                    <div class="mt-6"></div>
                </div>
            </div>
        </template>

        <template v-else-if="matchesAny(['starterCompleted', 'upgrading'])">
            <div class="flex flex-wrap xl:-mx-3">
                <div class="w-full xl:w-1/2 xl:px-3">
                    <WellbeingReportCards />
                    <WellbeingSupplementCardExternal
                        class="mt-6"
                        v-if="state.context.profile.vitlabString"
                        :vitlab-string="state.context.profile.vitlabString"
                    />
                </div>
                <div class="w-full xl:w-1/2 mt-6 xl:mt-0 xl:px-3">
                    <WellbeingReportList :reports="wellbeing" />
                    <WellbeingUpgradeCard v-if="matchesAny(['starterCompleted'])" />
                </div>
            </div>
        </template>

        <template v-else-if="matchesAny(['processingFull', 'processingStarter'])">
            <div class="flex flex-wrap xl:-mx-3">
                <div class="w-full xl:w-64 xl:px-3">
                    <WellbeingResultsVideo />
                </div>
                <div class="w-full xl:w-auto mt-6 xl:mt-0 xl:px-3">
                    <WellbeingFullResultsProcessing class="w-full" image-type="wide" />
                </div>
            </div>
        </template>

        <template v-else-if="matchesAny(['onlyVitlabString', 'noWellbeing'])">
            <div class="flex flex-wrap xl:-mx-3">
                <div class="w-full xl:w-1/2 xl:px-3">
                    <WellbeingFullUpgradeCard class="w-full mb-6" />
                </div>
                <div class="w-full xl:w-1/2 xl:px-3">
                    <WellbeingSupplementCardExternal
                        v-if="matchesAny(['onlyVitlabString'])"
                        :vitlab-string="state.context.profile.vitlabString"
                    />
                </div>
            </div>
        </template>

        <!-- Loading state -->
        <div v-else>
            <div class="xl:flex space-y-8 xl:space-y-0 xl:space-x-4">
                <div
                    v-for="i in 4"
                    :key="i"
                    class="w-full xl:w-1/4 max-w-md shadow-key rounded-md p-4"
                >
                    <TextCardLoading />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { onMounted } from "vue";
import store from "@/store/store";
import { useActor } from "@xstate/vue";
import { wellbeingDashboardService } from "./wellbeingDashboardMachine";
import WellbeingReportCards from "./WellbeingReportCards.vue";
import WellbeingResultsVideo from "./WellbeingResultsVideo.vue";
import WellbeingReportList from "./WellbeingReportList.vue";
import WellbeingUpgradeCard from "./WellbeingUpgradeCard.vue";
import WellbeingFullUpgradeCard from "./WellbeingFullUpgradeCard.vue";
import WellbeingFullResultsProcessing from "./WellbeingFullResultsProcessing.vue";
import TextCardLoading from "@/components/loader/TextLoader.vue";
import WellbeingSupplementCard from "./WellbeingSupplementCard.vue";
import WellbeingSupplementCardExternal from "./WellbeingSupplementCardExternal.vue";
import NewWellbeingDashboardPrompt from "@/components/Dashboard/Wellbeing/NewWellbeingDashboardPrompt.vue";

export default {
    components: {
        WellbeingReportCards,
        WellbeingResultsVideo,
        WellbeingReportList,
        WellbeingUpgradeCard,
        WellbeingFullResultsProcessing,
        WellbeingSupplementCard,
        WellbeingSupplementCardExternal,
        WellbeingFullUpgradeCard,
        TextCardLoading,
        NewWellbeingDashboardPrompt,
    },
    setup() {
        const { state, send } = useActor(wellbeingDashboardService);

        onMounted(async () => {
            await store.dispatch("fetchWellbeingData", store.getters.profile.barcode);
            send({
                type: "INIT",
                profile: store.getters.profile,
                kitTests: store.getters.kitTests,
            });
            console.log(state.context);
        });

        return { state };
    },
    computed: {
        ...mapGetters(["kitTests", "wellbeing"]),
    },
    methods: {
        matchesAny(states) {
            return !!states.find((state) => this.state.matches(state));
        },
    },
    // beforeDestroy() {
    //     this.wellbeingDashboardService.stop();
    //     this.wellbeingDashboardService.start();
    // },
};
</script>
