<template>
    <div>
        <div v-if="loadStatus === 'loading'">
            <PageLoader class="mt-12">Loading your result</PageLoader>
        </div>
        <div class="bg-grey-50" v-else-if="loadStatus === 'loaded'">
            <div class="max-w-4xl m-auto pb-24">
                <ArchaicResultsSectionTitle>
                    Neanderthal Results
                    <template v-slot:info>
                        <div class="border-b border-grey-200 pb-5 mx-4">
                            <h3 class="text-xl font-semibold leading-6 text-grey-700">
                                {{ understanding.heading }}
                            </h3>
                        </div>

                        <div class="mx-4" v-html="understanding.text"
                    /></template>
                </ArchaicResultsSectionTitle>

                <div class="mt-16 px-4">
                    <h1 class="text-center text-xl">
                        Your neanderthal score is {{ archaicResult.neanderthal.score }}
                    </h1>
                    <DoughnutChart :chart-data="chartData" />
                    <dl class="mt-12 grid grid-cols-1 gap-5 md:grid-cols-2">
                        <ArchaicResultsInfoCard>
                            <template v-slot:title> Percentage </template>
                            <template v-slot:value>
                                {{ archaicResult.neanderthal.percentage }}%
                            </template>
                        </ArchaicResultsInfoCard>

                        <ArchaicResultsInfoCard>
                            <template v-slot:title> Most similar remnant</template>
                            <template v-slot:value>
                                {{ archaicResult.neanderthal.closest_sample.name }}
                            </template>
                            <template v-slot:info>
                                <div v-html="closestSampleInfo.text" />
                            </template>
                        </ArchaicResultsInfoCard>
                    </dl>
                </div>

                <div class="px-4">
                    <h2 class="text-center text-xl mt-24">Percentiles by region</h2>
                    <p class="text-center text-sm max-w-sm m-auto text-grey-700 pt-2">
                        Compare your level of Neanderthal DNA with the rest of our userbase, as a
                        whole and by region
                    </p>
                </div>

                <div class="max-w-3xl m-auto pt-12 pb-24 space-y-2 flex flex-wrap">
                    <ArchaicResultsPercentile
                        v-for="(percentile, region) in archaicResult.neanderthal.percentiles"
                        :key="region"
                        :percentile="percentile"
                        :region="region"
                    />
                </div>

                <!-- <ArchaicResultsMarkerTable :markers="archaicResult.neanderthal.positive_markers" /> -->

                <ArchaicResultsSectionTitle>
                    Denisovan results
                    <template v-slot:info>
                        <div class="border-b border-grey-200 pb-5 mx-4">
                            <h3 class="text-xl font-semibold leading-6 text-grey-700">
                                {{ understanding.heading }}
                            </h3>
                        </div>

                        <div class="mx-4" v-html="understanding.text"
                    /></template>
                </ArchaicResultsSectionTitle>

                <div class="mt-16 px-4">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Overview</h3>
                    <dl class="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
                        <ArchaicResultsInfoCard>
                            <template v-slot:title> Denisovan Score </template>
                            <template v-slot:value>
                                {{ archaicResult.denisova.score }}
                            </template>
                        </ArchaicResultsInfoCard>

                        <ArchaicResultsInfoCard>
                            <template v-slot:title> Percentage </template>
                            <template v-slot:value>
                                {{ archaicResult.denisova.percentage }}%
                            </template>
                        </ArchaicResultsInfoCard>
                    </dl>
                </div>

                <div class="px-4">
                    <h2 class="text-center text-xl mt-24">Percentiles by region</h2>
                    <p class="text-center text-sm max-w-sm m-auto text-grey-700 pt-2">
                        Compare your level of Denisovan DNA with the rest of our userbase, as a
                        whole and by region
                    </p>
                </div>

                <div class="max-w-3xl m-auto pt-12 pb-36 space-y-2 flex flex-wrap">
                    <ArchaicResultsPercentile
                        v-for="(percentile, region) in archaicResult.denisova.percentiles"
                        :key="region"
                        :percentile="percentile"
                        :region="region"
                    />
                </div>

                <!-- <ArchaicResultsMarkerTable :markers="archaicResult.denisova.positive_markers" /> -->
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { getArchaicUnderstanding, getClosestSampleInfo } from "@/services/archaic";
import PageLoader from "@/components/PageLoader.vue";
import ArchaicResultsSectionTitle from "./ArchaicResultsSectionTitle.vue";
import ArchaicResultsPercentile from "./ArchaicResultsPercentile.vue";
import ArchaicResultsInfoCard from "./ArchaicResultsInfoCard.vue";
import ArchaicResultsMarkerTable from "./ArchaicResultsMarkerTable.vue";
import DoughnutChart from "@/components/charts/Doughnut.vue";
import TextLinkRight from "@/components/TextLinkRight.vue";

export default {
    components: {
        PageLoader,
        ArchaicResultsSectionTitle,
        ArchaicResultsPercentile,
        ArchaicResultsInfoCard,
        ArchaicResultsMarkerTable,
        DoughnutChart,
        TextLinkRight,
    },
    props: {
        archaicResult: Object,
    },
    setup(props) {
        const loadStatus = ref("loading");
        const understanding = ref({});
        const closestSampleInfo = ref({});
        onMounted(async () => {
            understanding.value = await getArchaicUnderstanding();

            watch(
                () => props.archaicResult.neanderthal.closest_sample.slug,
                async () => {
                    closestSampleInfo.value = await getClosestSampleInfo(
                        props.archaicResult.neanderthal.closest_sample.slug
                    );
                },
                { immediate: true }
            );
            loadStatus.value = "loaded";
        });

        return { loadStatus, understanding, closestSampleInfo };
    },
    computed: {
        chartData() {
            return {
                datasets: [
                    {
                        data: [
                            this.archaicResult.neanderthal.score,
                            500 - this.archaicResult.neanderthal.score,
                        ],
                        backgroundColor: ["#173775", "#ddd"],
                    },
                ],
                labels: [],
            };
        },
    },
};
</script>
