import moment from "moment";
import store from "@/store/store";
import zeroDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/0-degree.png";
import firstDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/1st-degree.png";
import parentChildDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/parent-child.png";
import siblingDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/sibling.png";
import secondDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/2nd-degree.png";
import thirdDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/3rd-degree.png";
import fourthDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/4th-degree.png";
import fifthDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/5th-degree.png";
import sixthDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/6th-degree.png";
import seventhDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/7th-degree.png";
import eighthDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/8th-degree.png";
import ninthDegreeDesktop from "@/assets/images/family-network/relationship-diagrams/desktop/9th-degree.png";
import zeroDegreeMobile from "@/assets/images/family-network/relationship-diagrams/mobile/0-degree.png";
import parentChildMobile from "@/assets/images/family-network/relationship-diagrams/mobile/parent-child.png";
import siblingMobile from "@/assets/images/family-network/relationship-diagrams/mobile/sibling.png";
import secondDegreeMobile from "@/assets/images/family-network/relationship-diagrams/mobile/2nd-degree.png";
import thirdDegreeMobile from "@/assets/images/family-network/relationship-diagrams/mobile/3rd-degree.png";
import fourthDegreeMobile from "@/assets/images/family-network/relationship-diagrams/mobile/4th-degree.png";
import fifthDegreeMobile from "@/assets/images/family-network/relationship-diagrams/mobile/5th-degree.png";
import sixthDegreeMobile from "@/assets/images/family-network/relationship-diagrams/mobile/6th-degree.png";
import seventhDegreeMobile from "@/assets/images/family-network/relationship-diagrams/mobile/7th-degree.png";
import eighthDegreeMobile from "@/assets/images/family-network/relationship-diagrams/mobile/8th-degree.png";
import ninthDegreeMobile from "@/assets/images/family-network/relationship-diagrams/mobile/9th-degree.png";

const firstDegreeRelations = {
    P: {
        name: "Parent",
        desktop: parentChildDesktop,
        mobile: parentChildMobile,
        averagePercentage: 50,
        averageSharedCm: 3286.94,
        ordinal: "st",
    },
    C: {
        name: "Child",
        desktop: parentChildDesktop,
        mobile: parentChildMobile,
        averagePercentage: 50,
        averageSharedCm: 3286.94,
        ordinal: "st",
    },
    FS: {
        name: "Sibling",
        desktop: siblingDesktop,
        mobile: siblingMobile,
        averagePercentage: 50,
        averageSharedCm: 3286.94,
        ordinal: "st",
    },
};

const diagramMap = {
    0: {
        desktop: zeroDegreeDesktop,
        mobile: zeroDegreeMobile,
        averagePercentage: 100,
        averageSharedCm: 3400,
    },
    1: {
        name: "First degree",
        desktop: firstDegreeDesktop,
        mobile: firstDegreeDesktop,
        averagePercentage: 50,
        averageSharedCm: 3286.94,
        ordinal: "st",
    },
    ...firstDegreeRelations,
    2: {
        name: "Grandparent/Half-sibling/Avuncular",
        desktop: secondDegreeDesktop,
        mobile: secondDegreeMobile,
        averagePercentage: "",
        averageSharedCm: 1776.02,
        ordinal: "nd",
    },
    3: {
        name: "1st cousin",
        desktop: thirdDegreeDesktop,
        mobile: thirdDegreeMobile,
        averagePercentage: 12.5,
        averageSharedCm: 959.67,
        ordinal: "rd",
    },
    4: {
        name: "1st cousin 1x removed",
        desktop: fourthDegreeDesktop,
        mobile: fourthDegreeMobile,
        averagePercentage: 6.25,
        averageSharedCm: 429.95,
    },
    5: {
        name: "2nd cousin",
        desktop: fifthDegreeDesktop,
        mobile: fifthDegreeMobile,
        averagePercentage: 3.125,
        averageSharedCm: 208.61,
    },
    6: {
        name: "2nd - 3rd cousin",
        desktop: sixthDegreeDesktop,
        mobile: sixthDegreeMobile,
        averagePercentage: 1.563,
        averageSharedCm: 88.46,
    },
    7: {
        name: "2nd - 4th cousin",
        desktop: seventhDegreeDesktop,
        mobile: seventhDegreeMobile,
        averagePercentage: 0.781,
        averageSharedCm: 56.31,
    },
    8: {
        name: "3rd - 5th cousin",
        desktop: eighthDegreeDesktop,
        mobile: eighthDegreeMobile,
        averagePercentage: 0.28,
        averageSharedCm: 20,
    },
    9: {
        name: "4th cousin or greater",
        desktop: ninthDegreeDesktop,
        mobile: ninthDegreeMobile,
        averagePercentage: 0.28,
        averageSharedCm: 20,
    },
};

/**
 * Result is the inverse of what you might expect because what we are looking for is the name
 * of the relationship from the profile's point of view. Therefore if the profile is older than
 * their match the match is the match is their child and that's what we want to display.
 */
const determineParentChildRelationship = (match, profile) =>
    moment(match.dateOfBirth).isAfter(profile.dateOfBirth) ? "C" : "P";

export const getRelationshipDetailsFromMatch = (match, profile = store.getters.profile) => {
    const key =
        parseInt(match.degree) === 1 && match.predictedRelationship
            ? match.predictedRelationship
            : match.degree;
    if (key === "PC") {
        const parentChildKey = determineParentChildRelationship(match, profile);

        return diagramMap[parentChildKey];
    }
    return diagramMap[key];
};

export const getRelationshipDetailsFromDegree = degree => diagramMap[degree];
