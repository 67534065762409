<template>
    <svg
        width="248px"
        height="228px"
        viewBox="0 0 248 228"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="Portal-–-Wellbeing"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            fill-opacity="0.1"
        >
            <g
                id="Wellbeing-&gt;-Report-Detail-&gt;-Uploader"
                transform="translate(-208.000000, -1084.000000)"
                fill="#3377FF"
            >
                <g id="Body" transform="translate(208.000000, 0.000000)">
                    <g id="Views/Wellbeing/Report-Detail">
                        <g id="Science" transform="translate(0.000000, 1044.000000)">
                            <g id="Group" transform="translate(-16.000000, 40.000000)">
                                <g id="Group-2">
                                    <rect
                                        id="Rectangle"
                                        x="192"
                                        y="120"
                                        width="72"
                                        height="310"
                                        rx="36"
                                    ></rect>
                                    <rect
                                        id="Rectangle"
                                        x="96"
                                        y="0"
                                        width="72"
                                        height="310"
                                        rx="36"
                                    ></rect>
                                    <rect
                                        id="Rectangle"
                                        x="0"
                                        y="40"
                                        width="72"
                                        height="310"
                                        rx="36"
                                    ></rect>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
