const stageCoverageData = data => ({
    type: "geojson",
    data: {
        id: "coverageMapData",
        type: "FeatureCollection",
        features: data
            .map(({ geoJson, type, ...properties }) => ({
                ...geoJson,
                id: properties.id,
                properties,
            }))
            .filter(el => el.geometry !== undefined),
    },
});

const stagePercentageData = (data, color) =>
    data.map(el => ({
        ...el,
        color,
    }));

export default {
    getters: {
        maternalCoverage: (state, { maternalDistribution }) =>
            stageCoverageData(maternalDistribution),
        maternalCoveragePercentages: (state, { maternalDistribution, genderColor }) =>
            stagePercentageData(maternalDistribution, genderColor("female")),

        paternalCoverage: (state, { paternalDistribution }) =>
            stageCoverageData(paternalDistribution),
        paternalCoveragePercentages: (state, { paternalDistribution, genderColor }) =>
            stagePercentageData(paternalDistribution, genderColor("male")),
    },
};
