<template>
    <div>
        <h3 class="text-denim-700 text-2xl font-normal text-center">
            Who does this DNA belong to?
        </h3>
        <KitUser
            v-if="$store.getters.account"
            button-text="Continue"
            @submitted="saveWhoIsThisKitFor"
        />
    </div>
</template>

<script>
import KitUser from "@/components/activation/KitUser.vue";
import { isEmpty } from "lodash";

export default {
    components: { KitUser },
    data() {
        return {};
    },
    async created() {
        await this.$store.dispatch("getAccountData");
    },
    methods: {
        getType(user) {
            const types = {
                Myself: "FOR_ME",
                AnotherAdult: "FOR_ADULT",
                MyChild: "FOR_CHILD",
                Deceased: "FOR_DECEASED",
            };
            if (!isEmpty(user.thirdPartyInformation) && !user.thirdPartyInformation.living) {
                return types.Deceased;
            }
            return types[user.whoFor];
        },
        saveWhoIsThisKitFor(user) {
            this.$store
                .dispatch("setWhoDoesThisDNABelongTo", {
                    type: this.getType(user),
                    relationship: user.whoFor,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    gender: Number(user.biologicalSex),
                    countryOfBirth: user.countryOfBirth.code,
                    dateOfBirth: user.dob,
                    isAlive: user.thirdPartyInformation.living,
                    dateOfDeath: user.thirdPartyInformation.dateOfDeath,
                    hasPermission: user.thirdPartyInformation.permission,
                })
                .then(() => this.$router.push({ name: "upload-file" }));
        },
    },
};
</script>
