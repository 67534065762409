<template>
    <div class="my-12">
        <h2 class="flex items-center text-lg">
            <FeatherWrapper icon="share-2" class="mr-2" />
            Family matching
        </h2>
        <div
            class="w-full mt-6 shadow-key rounded-lg flex-1"
            style="border-spacing: 0"
            v-if="fnSharing"
        >
            <DetailsTr title="Opted in" :link="familyMatchingRoute" append>
                <div>{{ familyMatchingConsentText }}</div>
            </DetailsTr>
            <DetailsTr title="Bio" link="edit/bio" append>
                <div v-html="bioPreview" />
            </DetailsTr>
            <DetailsTr
                title="MT Haplogroup"
                :link="mtString ? false : 'edit/fn-sharing/mtHaplogroup'"
                :icon="mtString ? 'lock' : 'chevron-right'"
                append
            >
                {{ mtString ? mtString : fnSharing.mtHaplogroup ? "Displaying" : "Not displaying" }}
                <template #tooltip>
                    {{ mtString }}
                </template>
            </DetailsTr>
            <DetailsTr
                title="Y Haplogroup"
                :link="ydnaString ? false : 'edit/fn-sharing/yHaplogroup'"
                :icon="ydnaString ? 'lock' : 'chevron-right'"
                append
            >
                {{
                    ydnaString
                        ? ydnaString
                        : fnSharing.yHaplogroup
                        ? "Displaying"
                        : "Not displaying"
                }}
                <template #tooltip>
                    {{ ydnaString }}
                </template>
            </DetailsTr>
        </div>
    </div>
</template>

<script>
import { Machine, interpret } from "xstate";
import MyTests from "@/services/my-tests";
import ProfileService from "@/services/profile";
import DetailsTr from "@/components/Profile/View/DetailsTr.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

const mapStatusMachine = Machine(
    {
        initial: "idle",
        states: {
            idle: {
                on: {
                    GET_STATUS_FOR_TEST: [
                        {
                            cond: "isAutosomalPurchased",
                            target: "autosomalPurchased",
                        },
                        {
                            target: "autosomalNotPurchased",
                        },
                    ],
                },
            },
            autosomalPurchased: {},
            autosomalNotPurchased: {},
        },
    },
    {
        guards: {
            isAutosomalPurchased: (_, event) => event.autosomalTest.purchased,
        },
    }
);

const parentalTestStatusMachine = Machine(
    {
        initial: "idle",
        states: {
            idle: {
                on: {
                    GET_STATUS_FOR_TEST: [
                        {
                            cond: "isYTestNotAvailable",
                            target: "yTestNotAvailable",
                        },
                        {
                            cond: "isTestCompleted",
                            target: "testCompleted",
                        },
                        {
                            cond: "isTestFailed",
                            target: "testFailed",
                        },
                        {
                            cond: "isTestProcessing",
                            target: "testProcessing",
                        },
                        {
                            cond: "isTestNotPurchasedButCouldBe",
                            target: "testNotPurchasedButCouldBe",
                        },
                        {
                            cond: "isTestNotPurchasedAndCantBe",
                            target: "testNotPurchasedAndCantBe",
                        },
                    ],
                },
            },
            yTestNotAvailable: {},
            testCompleted: {},
            testFailed: {},
            testProcessing: {},
            testNotPurchasedButCouldBe: {},
            testNotPurchasedAndCantBe: {},
        },
    },
    {
        guards: {
            isTestCompleted: (_, event) => event.test.status === "completed",
            isTestFailed: (_, event) => event.test.status === "failed",
            isTestProcessing: (_, event) =>
                event.test.status === "processing" || event.test.status === "pending",
            isYTestNotAvailable: (_, event) =>
                event.test.type === "ydna" ? event.profile.gender === 2 : false,
            isTestNotPurchasedButCouldBe: (_, event) =>
                !event.test.purchased && event.profile.barcode.substr(0, 2) === "LD",
            isTestNotPurchasedAndCantBe: (_, event) =>
                !event.test.purchased && event.profile.barcode.substr(0, 2) === "LT",
        },
    }
);

const statusDictionary = {
    yTestNotAvailable: "You do not have a Y chromosome so can't display this result",
    testCompleted: false,
    testFailed: "This test failed so you can't display the result",
    testProcessing: false,
    testNotPurchasedButCouldBe:
        "In order to display this result you must get the deep ancestry upgrade",
    testNotPurchasedAndCantBe:
        "This feature is only available to people who took a Living DNA test",
    autosomalNotPurchased: "This profile needs the recent ancestry upgrade to share its map",
};

export default {
    data() {
        return {
            fnSharing: undefined,
            yService: undefined,
            mtService: undefined,
            mapService: undefined,
        };
    },
    props: {
        profile: {
            required: true,
            type: Object,
        },
    },
    async created() {
        const tests = await MyTests.getKitTests(this.profile.barcode);
        this.yService = interpret(parentalTestStatusMachine).start();
        this.yService.send("GET_STATUS_FOR_TEST", {
            test: tests.ydna,
            profile: this.profile,
        });
        this.mtService = interpret(parentalTestStatusMachine).start();
        this.mtService.send("GET_STATUS_FOR_TEST", {
            test: tests.mtdna,
            profile: this.profile,
        });
        this.mapService = interpret(mapStatusMachine).start();
        this.mapService.send("GET_STATUS_FOR_TEST", {
            autosomalTest: tests.autosomal,
        });
        try {
            this.fnSharing = await ProfileService.getFamilyNetworkSharingPermissions(
                this.profile.barcode
            );
        } catch (e) {
            this.fnSharing = {};
        }
    },

    computed: {
        familyMatchingConsent() {
            return this.profile.fnConsent;
        },
        familyMatchingConsentText() {
            return this.familyMatchingConsent ? "Opted in" : "Not opted in";
        },
        familyMatchingRoute() {
            return this.familyMatchingConsent
                ? "edit/family-matching"
                : "edit/family-matching-opt-in";
        },
        bioPreview() {
            if (!this.profile.bio) {
                return "None";
            }
            if (this.profile.bio.length > 30) {
                return `${this.profile.bio.substr(0, 30)}...`;
            }
            return this.profile.bio;
        },
        ydnaString() {
            return statusDictionary[this.yService.state.value];
        },
        mtString() {
            return statusDictionary[this.mtService.state.value];
        },
    },
    components: { DetailsTr, FeatherWrapper },
};
</script>
