import * as turf from "@turf/turf";
import { createMapItem, flattenChildren } from "@/services/autosomal";
import { find } from "lodash";
import { getClassicalColour } from "@/composables/useClassicalContent";
import { useAutosomalStore } from "@/store/ancestry/autosomal";

export function calculateOpacity(percentage) {
    if (Number.isNaN(percentage)) {
        return 1;
    }
    const max = 1.0;
    const min = 0.0;
    const rangeStart = 0.3;
    const rangeEnd = 1.0;
    const oldRange = max - min;
    const newRange = rangeEnd - rangeStart;
    return ((percentage / 100 - min) * newRange) / oldRange + rangeStart;
}

export function ActiveLayer(colour, code) {
    return {
        id: "activeLayer",
        type: "line",
        source: "tests",
        "source-layer": process.env.VUE_APP_MAP_TILESET_URL,
        paint: {
            "line-color": colour,
            "line-width": 2,
        },
        filter: ["==", "code", code],
    };
}

export function MapLayer(id, colour, code, isStarterTestMode, opacity, source) {
    return {
        id,
        type: "fill",
        source: "tests",
        "source-layer": process.env.VUE_APP_MAP_TILESET_URL,
        paint: {
            "fill-color": colour,
            "fill-opacity": !isStarterTestMode ? opacity : 1,
        },
        filter: ["==", "code", code],
    };
}

export function combineMatchAndCurrentProfileMapData(matchMapData, currentProfileMapData) {
    return matchMapData.map((layer) => {
        const sharedLayer = currentProfileMapData.find(
            (currentProfileLayer) => currentProfileLayer.properties.title === layer.properties.title
        );
        if (sharedLayer) {
            return {
                id: layer.id,
                properties: {
                    ...layer.properties,
                },
                comparison: {
                    currentProfile: sharedLayer.properties.percentage,
                    matchedProfile: layer.properties.percentage,
                },
            };
        }
        return {
            id: layer.id,
            properties: {
                ...layer.properties,
                colour: "#888",
            },
        };
    });
}

export function byPercentageDesc(a, b) {
    return parseFloat(b.properties.percentage) - parseFloat(a.properties.percentage);
}

export function constructMapDataFromResults(results) {
    const autosomalCompleteRegion = flattenChildren(results);
    const autosomalCompleteSubRegion = flattenChildren(autosomalCompleteRegion);
    return [
        ...autosomalCompleteRegion.map(createMapItem),
        ...autosomalCompleteSubRegion.map(createMapItem),
    ];
}

export function attachClassicalShapesToMap(map, { people, feature, type }) {
    map.addSource(`${people}_${type}_layer`, {
        type: "geojson",
        data: feature,
    });

    map.addLayer({
        id: `${people}_${type}_layer`,
        type: "fill",
        source: `${people}_${type}_layer`,
        layout: {},
        paint: {
            "fill-color": getClassicalColour(people),
            "fill-opacity": type === "sampleGroup" ? 0.8 : 0.4,
        },
    });
}

export function zoomToGroup(map, feature) {
    const bbox = turf.bbox(feature);
    map.fitBounds(bbox, { padding: 50 });
}

export function attachClassicalSitesToMap(map, { sites, people, closestSample }) {
    const filteredSites = sites.filter((f) => f.properties.type === people);

    const orderedSites = filteredSites.sort((s) => {
        const match = closestSample ? s.properties.id === closestSample.sample : true;
        return match ? 1 : -1;
    });

    map.addSource("points", {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features: orderedSites,
        },
    });

    map.addLayer({
        id: "population",
        type: "circle",
        source: "points",
        paint: {
            // Make circles larger as the user zooms from z12 to z22.
            "circle-radius": {
                base: 200,
                stops: [
                    [1, 5],
                    [5, 6],
                ],
            },
            "circle-color": [
                "match",
                ["get", "id"],
                closestSample?.sample,
                "hsl(141,96%,37%)",
                [
                    "match",
                    ["get", "group_slug"],
                    closestSample?.slug,
                    getClassicalColour(people),
                    /* other */
                    "#ccc",
                ],
            ],
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
        },
    });

    return orderedSites;
}

export function useMap(map) {
    const autosomalStore = useAutosomalStore();
    function boundingBoxMove(featureCollection, padding) {
        const bbox = turf.bbox(featureCollection);
        if (bbox.filter((item) => Number.isFinite(item)).length === 4) {
            console.log(featureCollection);
            map.fitBounds(bbox, { padding });
        }
    }

    function findFeaturesByCode(code) {
        return map.querySourceFeatures("tests", {
            sourceLayer: process.env.VUE_APP_MAP_TILESET_URL,
            filter: ["==", "code", code],
        });
    }

    function createActiveLayer(featureId) {
        if (map?.getLayer("activeLayer")) {
            map.removeLayer("activeLayer");
        }

        // console.log("looking in result", { result });

        const selectedGroup = autosomalStore.mapItems.find((popGroup) => popGroup.id === featureId);

        function getSelectedFeatures() {
            const features = autosomalStore.mapItems.flatMap(() =>
                findFeaturesByCode(selectedGroup.properties.code)
            );
            return {
                type: "FeatureCollection",
                features,
            };
        }
        boundingBoxMove(getSelectedFeatures(), 100);
        map.addLayer(
            new ActiveLayer(selectedGroup.properties.colour, selectedGroup.properties.code)
        );
    }

    function getFeatures() {
        const features = autosomalStore.mapItems.flatMap((popGroup) => {
            return findFeaturesByCode(popGroup.properties.code);
        });

        return {
            type: "FeatureCollection",
            features,
        };
    }

    return { boundingBoxMove, createActiveLayer, getFeatures };
}
