<template>
    <div>
        <input id="x" :value="editorContent" type="hidden" name="content" />
        <trix-editor input="x"></trix-editor>
    </div>
</template>

<script>
import { ref } from "vue";
import Trix from "trix";
import "trix/dist/trix.css";

// @trix-change="handleContentChange"
// @trix-file-accept="emitFileAccept"
// @trix-attachment-add="emitAttachmentAdd"
// @trix-attachment-remove="emitAttachmentRemove"
// @trix-selection-change="emitSelectionChange"
// @trix-initialize="handleInitialize"
// @trix-before-initialize="emitBeforeInitialize"
// @trix-focus="processTrixFocus"
// @trix-blur="processTrixBlur"

export default {
    props: {
        modelValue: String,
    },
    setup(props, { emit }) {
        const trix = {
            disabledEditor: false,
            computedId: 1,
            placeholder: "Placeholder",
            inputName: "bio",
        };
        console.log(props);
        const editorContent = ref(props.modelValue);
        function handleContentChange(event) {
            emit("update:modelValue", event.target.value);
        }
        return { trix, handleContentChange, editorContent };
    },
};
</script>
