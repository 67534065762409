<template>
    <main>
        <h1 class="font-normal text-2xl">Birthplace</h1>

        <p class="my-4">Change {{ $store.getters.profile.name }}'s birth place</p>

        <form @submit.prevent="onSubmit" data-vv-scope="country-picker">
            <CountryCodePicker v-model="country" />
            <SubmitButton
                icon="save"
                :class="state.matches('savingCountry') ? 'loading' : ''"
                hide-icon
            >
                Save
            </SubmitButton>
            <div class="text-center">
                <router-link
                    :to="{ name: 'profile-view' }"
                    class="mt-2 p-2 inline-block text-grey-500"
                >
                    Cancel
                </router-link>
            </div>
        </form>
    </main>
</template>

<script>
import { useRouter } from "vue-router";
import { Machine, interpret, assign } from "xstate";
import { useActor } from "@xstate/vue";
import profileService from "@/services/profile";
import CountryCodePicker from "@/components/CountryPicker/CountryCodePicker.vue";
import SubmitButton from "@/components/button/SubmitButton.vue";
import store from "@/store/store";

const profileNameMachine = Machine(
    {
        id: "profile-name-machine",
        initial: "idle",
        context: {
            profile: {},
            errorMessage: "",
        },
        states: {
            idle: {
                on: {
                    SET_PROFILE: {
                        target: "ready",
                        actions: "cacheProfile",
                    },
                },
            },
            ready: {
                on: {
                    SAVE: "savingCountry",
                },
            },
            savingCountry: {
                invoke: {
                    src: "saveCountry",
                    onDone: "success",
                    onError: "hasError",
                },
            },
            success: {
                type: "final",
            },
            hasError: {
                entry: "cacheErrorMessage",
                on: {
                    SAVE: "savingCountry",
                },
            },
        },
    },
    {
        actions: {
            cacheProfile: assign({
                profile: (context, event) => event.profile,
            }),
            cacheErrorMessage: assign({
                errorMessage: (context, event) => event.data.message,
            }),
        },
        services: {
            saveCountry: (context, event) =>
                new Promise(async (resolve, reject) => {
                    const updatedProfile = {
                        ...context.profile,
                        countryOfBirth: event.country,
                    };
                    try {
                        await profileService.editProfile(context.profile.barcode, {
                            profile: updatedProfile,
                        });
                        resolve();
                    } catch (e) {
                        reject(new Error(e.data.errors[0].detail));
                    }
                }),
        },
    }
);

export default {
    name: "EditName",

    data() {
        return {
            nameService: undefined,
            currentNameState: undefined,
            country: this.profile.countryOfBirth,
        };
    },

    props: {
        profile: {
            type: Object,
            required: true,
        },
    },

    components: { CountryCodePicker, SubmitButton },

    setup(props) {
        const router = useRouter();
        const nameService = interpret(profileNameMachine).start();
        const { state, send } = useActor(nameService);

        nameService.onTransition((state) => {
            if (state.done) {
                router.push({
                    name: "profile-view",
                    params: {
                        barcode: props.profile.barcode,
                    },
                });
            }
        });
        send({ type: "SET_PROFILE", profile: props.profile });
        return { state, send };
    },

    methods: {
        matchesAny(states) {
            return !!states.find((state) => this.currentNameState.matches(state));
        },
        onSubmit(event) {
            this.send({ type: "SAVE", country: this.country });
        },
    },
};
</script>

<style></style>
