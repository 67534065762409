<template>
    <div v-if="loadingState === 'loading'">
        <PageLoader class="pb-12 mt-12"> Loading shared DNA </PageLoader>
    </div>
    <div v-if="loadingState === 'not-allowed'" class="relative">
        <div class="cover-image" />

        <div
            class="flex flex-col items-start max-w-md m-auto gap-4 bg-white p-6 shadow-key rounded relative z-10 my-16 //border-t-4 border-cobalt-500"
        >
            <div class="bg-cobalt-50 p-4 inline-block rounded-full">
                <Feather type="lock" class="text-cobalt-500 block" />
            </div>

            <div>
                <h2 class="text-lg font-semibold m-0 pb-2">Upgrade required</h2>
                <div class="flex flex-col gap-4">
                    <div>
                        This tool can be used to establish how you and your match are related. You
                        will be able to see where and on which Chromosomes you share DNA with your
                        matches and which you do not.
                    </div>

                    <div>
                        Buy our autosomal upgrade to get access to chromosome browser, your ancestry
                        breakdown including maps, and the ability to share those maps in family
                        networks.
                    </div>
                </div>
                <router-link
                    :to="{ name: 'product', params: { slug: 'autosomal-upgrade' } }"
                    class="text-cobalt-500 font-semibold flex items-center mt-4"
                >
                    <feather-wrapper icon="zap" class="mr-2" />
                    Find out more
                </router-link>
            </div>
        </div>
    </div>
    <div v-if="loadingState === 'failed'" class="text-center py-6">
        Loading chromosome browser failed
    </div>
    <div v-else-if="loadingState === 'loaded'" class="p-6 bg-white relative">
        <h1 class="text-lg mb-2">Matching chromosome segments</h1>
        <p class="mt-0 mb-4">
            {{ match.username }} matches you on
            {{ Object.keys(groupBy(filteredResults, "CHROM")).length }} of 22 chromosomes for a
            total of {{ segmentSum }} cM
            <span v-if="filterStore.shouldFilterResults">(based on the filter you have set)</span>
        </p>
        <div class="mb-6 max-w-xl">
            <h3>What is a Chromosome Browser?</h3>
            <button v-if="!showMore" @click="showMore = true" class="text-cobalt-500 font-semibold">
                Click to show more
            </button>
            <template v-if="showMore">
                <p>
                    The Living DNA Chromosome Browser allows you to view and explore which parts of
                    your genome are shared with your genetic match.
                </p>
                <p>You will also be able to see which parts of your DNA are unique to you.</p>
                <h4>How to use Chromosome Browser?</h4>
                <p>
                    The 22 autosomal chromosomes, over which the Living DNA matching method
                    estimates relationships, are presented in Centimorgan (cM) units.
                </p>
                <p>
                    Here displayed are your matching segments highlighted for you to explore and
                    uncover which parts of the chromosome you share.
                </p>
                <button @click="showMore = false" class="text-cobalt-500 font-semibold">
                    Show less
                </button>
            </template>
        </div>

        <div
            class="flex flex-col items-start md:flex-row justify-between md:items-center gap-2 my-6 md:my-4"
        >
            <FilterToggle />
            <button
                @click="copy(parser.parse(formatResultsForCopy(filteredResults)))"
                class="inline-flex items-center gap-x-2 rounded-md //bg-cobalt-50 border border-cobalt-500 px-2.5 py-1.5 text-sm font-semibold text-cobalt-500 shadow-sm hover:bg-cobalt-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cobalt-500"
            >
                <span v-if="!copied">Copy segment data</span>
                <span v-else>Copied</span>
                <Feather :type="copied ? 'check' : 'clipboard'" class="h-5 w-5" />
            </button>
        </div>

        <BrowserViewModeSelector v-model="viewMode" class="mb-4" />

        <template v-if="viewMode === 'browser'">
            <Legend />

            <div class="space-y-8 browser">
                <div v-for="chromosome in chromosomes" class="flex space-x-6">
                    <div>{{ chromosome.CHROM }}</div>
                    <div
                        class="bg-grey-300 h-6 rounded-full relative overflow-hidden"
                        :style="{
                            width: `${
                                (Number(chromosome.CM_LENGTH) / lengthOfLongestChromosome) * 100
                            }%`,
                        }"
                    >
                        <div
                            v-for="result in getResultsForChromosome(chromosome)"
                            class="h-full flex absolute bg-denim-700 hover:bg-cobalt-700"
                            :style="{
                                left: `${
                                    (Number(result.CM_START) / Number(chromosome.CM_LENGTH)) * 100
                                }%`,
                                width: `${
                                    ((Number(result.CM_STOP) - Number(result.CM_START)) /
                                        Number(chromosome.CM_LENGTH)) *
                                    100
                                }%`,
                            }"
                        >
                            <tippy trigger="click">
                                <button class="h-full w-full"></button>
                                <template #content>
                                    <div class="bg-white shadow-md rounded p-6">
                                        <div>
                                            Segment length:
                                            <strong
                                                >{{
                                                    (
                                                        Number(result.CM_STOP) -
                                                        Number(result.CM_START)
                                                    ).toFixed(3)
                                                }}
                                                cM</strong
                                            >
                                        </div>
                                        <div>
                                            Start position:
                                            <strong
                                                >chr{{ result.CHROM }}:
                                                {{
                                                    Number(result.BP_START).toLocaleString("en-GB")
                                                }}</strong
                                            >
                                        </div>
                                        <div>
                                            End position:
                                            <strong
                                                >chr{{ result.CHROM }}:
                                                {{
                                                    Number(result.BP_STOP).toLocaleString("en-GB")
                                                }}</strong
                                            >
                                        </div>
                                    </div>
                                </template>
                            </tippy>
                        </div>
                        <div
                            v-for="area in getCommonAreasForChromosome(chromosome)"
                            :key="area.CM_START"
                            class="h-full flex absolute stripes opacity-50 pointer-events-none"
                            :style="{
                                left: `${
                                    (Number(area.CM_START) / Number(chromosome.CM_LENGTH)) * 100
                                }%`,
                                width: `${
                                    ((Number(area.CM_STOP) - Number(area.CM_START)) /
                                        Number(chromosome.CM_LENGTH)) *
                                    100
                                }%`,
                            }"
                        />
                        <Centromere :chromosome="chromosome" />
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="viewMode === 'table'">
            <div class="">
                <div class="">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    class="sm:hidden pb-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                    Segments
                                </th>
                                <th
                                    scope="col"
                                    class="hidden sm:table-cell pb-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                    Chromosome
                                </th>

                                <th
                                    scope="col"
                                    class="hidden px-3 pb-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                >
                                    Segment length (cM)
                                </th>
                                <th
                                    scope="col"
                                    class="hidden px-3 pb-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                >
                                    Start position
                                </th>
                                <th
                                    scope="col"
                                    class="hidden px-3 pb-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                >
                                    End position
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="result in filteredResults">
                                <td
                                    class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0"
                                >
                                    <span class="hidden sm:block">{{ result.CHROM }}</span>
                                    <dl class="font-normal sm:hidden">
                                        <dt class="mt-2">Chromosome</dt>
                                        <dd class="mt-1 truncate text-gray-700">
                                            {{ result.CHROM }}
                                        </dd>
                                        <dt class="mt-2">Segment length</dt>
                                        <dd class="mt-1 truncate text-gray-700">
                                            {{ result.CM_LENGTH }}
                                        </dd>
                                        <dt class="mt-2">Start position</dt>
                                        <dd class="mt-1 truncate text-gray-500">
                                            {{ Number(result.BP_START).toLocaleString("en-GB") }}
                                        </dd>
                                        <dt class="mt-2">End position</dt>
                                        <dd class="mt-1 truncate text-gray-500">
                                            {{ Number(result.BP_STOP).toLocaleString("en-GB") }}
                                        </dd>
                                    </dl>
                                </td>
                                <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                    {{ result.CM_LENGTH }}
                                </td>
                                <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                    {{ Number(result.BP_START).toLocaleString("en-GB") }}
                                </td>
                                <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                    {{ Number(result.BP_STOP).toLocaleString("en-GB") }}
                                </td>
                            </tr>

                            <!-- More people... -->
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import Centromere from "@/components/ChromosomeBrowser/Centromere.vue";
import PageLoader from "@/components/PageLoader.vue";
import { groupBy } from "lodash";
import { Tippy } from "vue-tippy";
import endpoints from "@/endpoints";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";
import { useFilterStore } from "@/store/chromosomeBrowser";
import { useClipboard } from "@vueuse/core";
import { Parser } from "@json2csv/plainjs";
import Legend from "@/components/ChromosomeBrowser/Legend.vue";
import FilterToggle from "@/components/ChromosomeBrowser/FilterToggle.vue";
import BrowserViewModeSelector from "../BrowserViewModeSelector.vue";

const viewMode = ref("browser");

const showMore = ref(false);

const props = defineProps({
    match: Object,
});

const filterStore = useFilterStore();

const chromosomes = [
    {
        CHROM: 1,
        CM_LENGTH: 293.397,
        CM_POSITION: 150.8336,
    },
    {
        CHROM: 2,
        CM_LENGTH: 274.87663,
        CM_POSITION: 118.3734,
    },
    {
        CHROM: 3,
        CM_LENGTH: 227.8292,
        CM_POSITION: 109.1464,
    },
    {
        CHROM: 4,
        CM_LENGTH: 220.01794,
        CM_POSITION: 69.7887,
    },
    {
        CHROM: 5,
        CM_LENGTH: 208.95622,
        CM_POSITION: 60.9361,
    },
    {
        CHROM: 6,
        CM_LENGTH: 198.25632,
        CM_POSITION: 78.3466,
    },
    {
        CHROM: 7,
        CM_LENGTH: 190.56406,
        CM_POSITION: 80.8752,
    },
    {
        CHROM: 8,
        CM_LENGTH: 178.1536,
        CM_POSITION: 69.485,
    },
    {
        CHROM: 9,
        CM_LENGTH: 180.46379,
        CM_POSITION: 64.3246,
    },
    {
        CHROM: 10,
        CM_LENGTH: 183.11295,
        CM_POSITION: 62.5184,
    },
    {
        CHROM: 11,
        CM_LENGTH: 161.85604,
        CM_POSITION: 65.4944,
    },
    {
        CHROM: 12,
        CM_LENGTH: 175.13532,
        CM_POSITION: 55.4598,
    },
    {
        CHROM: 13,
        CM_LENGTH: 129.79234,
        CM_POSITION: 0.0001,
    },
    {
        CHROM: 14,
        CM_LENGTH: 116.76934,
        CM_POSITION: 0.0001,
    },
    {
        CHROM: 15,
        CM_LENGTH: 150.84068,
        CM_POSITION: 0.0001,
    },
    {
        CHROM: 16,
        CM_LENGTH: 131.13381,
        CM_POSITION: 58.4528,
    },
    {
        CHROM: 17,
        CM_LENGTH: 128.53434,
        CM_POSITION: 46.5217,
    },
    {
        CHROM: 18,
        CM_LENGTH: 120.14257,
        CM_POSITION: 40.1999,
    },
    {
        CHROM: 19,
        CM_LENGTH: 106.85774,
        CM_POSITION: 49.2801,
    },
    {
        CHROM: 20,
        CM_LENGTH: 110.2054,
        CM_POSITION: 50.987,
    },
    {
        CHROM: 21,
        CM_LENGTH: 64.65146,
        CM_POSITION: 0.5841,
    },
    {
        CHROM: 22,
        CM_LENGTH: 75.11309,
        CM_POSITION: 0.0001,
    },
];

const commonAreas = [
    {
        CHROM: 1,
        CM_START: "150.314",
        CM_STOP: "168.046",
    },
    {
        CHROM: 2,
        CM_START: "114.780",
        CM_STOP: "121.685",
    },
    {
        CHROM: 2,
        CM_START: "153.600",
        CM_STOP: "165.310",
    },
    {
        CHROM: 2,
        CM_START: "207.605",
        CM_STOP: "212.226",
    },
    {
        CHROM: 8,
        CM_START: "18.806",
        CM_STOP: "24.292",
    },
    {
        CHROM: 8,
        CM_START: "124.666",
        CM_STOP: "159.178",
    },
    {
        CHROM: 9,
        CM_START: "63.796",
        CM_STOP: "83.174",
    },
    {
        CHROM: 10,
        CM_START: "67.098",
        CM_STOP: "74.141",
    },
    {
        CHROM: 14,
        CM_START: "0.001",
        CM_STOP: "4.034",
    },
    {
        CHROM: 15,
        CM_START: "0.001",
        CM_STOP: "31.947",
    },
    {
        CHROM: 15,
        CM_START: "37.912",
        CM_STOP: "44.024",
    },
    {
        CHROM: 16,
        CM_START: "38.705",
        CM_STOP: "44.576",
    },
    {
        CHROM: 16,
        CM_START: "127.017",
        CM_STOP: "130.981",
    },
    {
        CHROM: 17,
        CM_START: "87.203",
        CM_STOP: "93.128",
    },
    {
        CHROM: 17,
        CM_START: "119.400",
        CM_STOP: "124.781",
    },
    {
        CHROM: 22,
        CM_START: "0.001",
        CM_STOP: "23.021",
    },
];
const lengthOfLongestChromosome = Number(chromosomes[0].CM_LENGTH);

const loadingState = ref("loading");
const results = ref([]);

onMounted(async () => {
    try {
        results.value = (await axios.get(endpoints.sharedSegments(props.match.rel_id))).data;
        results.value = results.value.map((result) => ({
            ...result,
            CM_LENGTH: result.CM_STOP - result.CM_START,
        }));
        loadingState.value = "loaded";
    } catch (e) {
        if (e.response?.status === 402) {
            loadingState.value = "not-allowed";
        } else {
            loadingState.value = "failed";
        }
    }
});

const filteredResults = computed(() => {
    if (!filterStore.shouldFilterResults) {
        return results.value;
    }
    return results.value.filter((result) => {
        return result.CM_LENGTH >= filterStore.filterValue;
    });
});

const segmentSum = computed(() => {
    return filteredResults.value
        .reduce((a, b) => {
            return a + b.CM_LENGTH;
        }, 0)
        .toFixed(2);
});

function getResultsForChromosome(chromosome) {
    return filteredResults.value.filter((result) => result.CHROM === chromosome.CHROM);
}

function getCommonAreasForChromosome(chromosome) {
    return commonAreas.filter((area) => area.CHROM === chromosome.CHROM);
}

function formatResultsForCopy(results) {
    return results.map((result) => ({
        Name: props.match.name || props.match.username,
        Chr: result.CHROM,
        "Start Location": result.BP_START,
        "End Location": result.BP_STOP,
        "Centimorgans (cM)": result.CM_LENGTH,
    }));
}

const { copy, copied } = useClipboard();

const parser = new Parser({});

// try {
//   const opts = {};
//   const parser = new Parser(opts);
//   const csv = parser.parse(myData);
//   console.log(csv);
// } catch (err) {
//   console.error(err);
// }
</script>

<style>
.browser span[data-v-tippy] {
    @apply block w-full;
}

.stripes {
    background-image: linear-gradient(
        45deg,
        #f7f7f7 25%,
        hsl(220, 7%, 73%),
        hsl(220, 7%, 73%),
        #f7f7f7 50%,
        #f7f7f7 75%,
        hsl(220, 7%, 73%),
        hsl(220, 7%, 73%)
    );
    background-size: 10.57px 10.57px;
}

.cover-image {
    background-image: url("@/assets/images/chrom-status.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    filter: blur(3px);
}
</style>
