<template>
    <div class="mt-12 py-12 bg-white">
        <h2 class="text-center">{{ title }}</h2>
        <div class="max-w-3xl px-4 mx-auto md:flex items-stretch justify-around mt-8">
            <div
                v-for="effect in traitEffects"
                :key="effect.heading"
                class="text-center md:w-auto mb-12 flex-1"
            >
                <img
                    :src="effect.image"
                    :alt="`${effect.heading} image`"
                    class="h-24 w-auto inline-block"
                />
                <h4 class="text-grey-700 text-center mt-6 mb-0 font-normal">
                    {{ effect.heading }}
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            required: true,
            type: String,
        },
        traitEffects: {
            required: true,
            type: Array,
        },
    },
};
</script>
