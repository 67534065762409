<template>
    <div>
        <label :for="`${id}_${unique}`" class="text-grey-700 font-semibold block w-full text-sm">
            {{ label }}
        </label>
        <input
            v-model="inputValue"
            :type="type"
            :id="`${id}_${unique}`"
            class="mt-1 border border-grey-300 rounded w-full py-2 px-4 focus:outline-none focus:border-cobalt-500"
            @input="$emit('input', inputValue), $emit('update:modelValue', inputValue)"
        />
    </div>
</template>

<script>
import { shuffle } from "lodash";

const arrayOfRandomCharacters = "abcdefghijklmnopqurstuvwxy".split("");
export default {
    data() {
        return {
            inputValue: this.modelValue,
            unique: shuffle(arrayOfRandomCharacters).join(""),
        };
    },
    computed: {
        id() {
            return this.label.toLowerCase().replace(/\s+/g, "-");
        },
    },
    props: {
        modelValue: String,
        label: String,
        type: {
            type: String,
            default: "text",
        },
    },
    watch: {
        inputValue(value) {
            this.$emit("input", value);
        },
    },
};
</script>
