<template>
    <div v-if="!this.$auth.check()">
        <h3 class="text-denim-700 text-2xl font-normal text-center">Start your DNA adventure</h3>
        <p class="text-700 leading-normal text-center">
            Welcome to the Living DNA family! <br />
            Uploading your DNA is quick, easy, and FREE. <br />
            <br />
            We accept DNA from AncestryDNA, 23AndMe, MyHeritage, FamilyTreeDNA (Family Finder),
            Illumina, Gene by Gene, and National Geographic (Geno 2.0).
        </p>
        <router-link
            :to="{ name: 'signup' }"
            class="mt-10 bg-cobalt-500 rounded w-full py-2 px-4 text-white font-semibold block w-full text-center hover:bg-cobalt-700"
        >
            Sign up
        </router-link>
        <div class="text-grey-600 text-center my-4 text-sm">OR</div>
        <router-link
            :to="{ name: 'login' }"
            class="border-2 border-cobalt-500 hover:border-cobalt-700 rounded w-full py-2 px-4 text-cobalt-500 hover:text-cobalt-700 font-semibold block w-full text-center"
        >
            Log in
        </router-link>
        <div class="text-center mt-8">
            Don't have a DNA file or kit?
            <a
                href="https://livingdna.com/uk/#featured-products"
                target="_blank"
                rel="noopener"
                class="text-cobalt-500 font-semibold"
            >
                Buy kit
            </a>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        if (this.$auth.check()) {
            this.$router.push({ name: "who-does-this-dna-belong-to" });
        }
    },
};
</script>
