<template>
    <div class="h-full w-full text-xs">
        <MapLegend
            :items="[
                {
                    name: 'Neanderthal area',
                    colour: '#00d4ff',
                    type: 'area',
                },
                {
                    name: 'Neanderthal site',
                    colour: '#00d4ff',
                    type: 'site',
                },
                {
                    name: 'Denisovan area',
                    colour: '#d400ff',
                    type: 'area',
                },
                {
                    name: 'Denisovan site',
                    colour: '#d400ff',
                    type: 'site',
                },
                {
                    name: 'Neanderthal & Denisovan site',
                    colour: 'orange',
                    type: 'site',
                },
                {
                    name: 'Closest sample site',
                    colour: '#CC005C',
                    type: 'site',
                },
            ]"
        />
        <div id="map" class="w-full" style="height: calc(100% - 48px)"></div>
    </div>
</template>

<script>
import { onMounted, onUnmounted, watch } from "vue";
import store from "@/store/store";
import mapboxgl from "mapbox-gl";
import { vikingAccessToken, vikingMapStyle } from "@/views/ancestry/recent/mapboxConfig";
import ArchaicLegendMobile from "./ArchaicLegendMobile.vue";
import ArchaicLegend from "./ArchaicLegend.vue";
import MapLegend from "@/components/MapLegend.vue";

function addShape({ map, feature, name, opacity, colour }) {
    map.addSource(name, {
        type: "geojson",
        data: {
            type: "Feature",
            geometry: {
                type: feature[0].geometry.type,
                coordinates: feature[0].geometry.coordinates,
            },
        },
    });

    map.addLayer({
        id: name,
        type: "fill",
        source: name,
        layout: {},
        paint: {
            "fill-color": colour,
            "fill-opacity": opacity,
        },
    });
}

function addPoints(map, points, closestSample) {
    points.sort((_, b) => (b.properties.name === closestSample.site ? -1 : 0));

    map.addSource("points", {
        type: "geojson",
        data: {
            type: "FeatureCollection",
            features: points,
        },
    });

    map.addLayer({
        id: "population",
        type: "circle",
        source: "points",
        paint: {
            // Make circles larger as the user zooms from z12 to z22.
            "circle-radius": {
                base: 200,
                stops: [
                    [1, 4],
                    [5, 20],
                ],
            },
            "circle-color": [
                "match",
                ["get", "name"],
                closestSample.site,
                "hsl(333, 100%, 40%)",
                [
                    "match",
                    ["get", "type"],
                    "neanderthal",
                    "#00D4FF",
                    "denisovan",
                    "#D400FF",
                    "neanderthal and denisovan",
                    "orange",
                    /* other */
                    "#ccc",
                ],
            ],
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
        },
    });
}

function getFeature(feature) {
    return JSON.parse(feature).features;
}

export default {
    props: {
        mapData: {
            type: String,
            required: true,
        },
        mapDataTwo: {
            type: String,
            required: true,
        },
        mapPoints: {
            type: String,
            required: true,
        },
        closestSample: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        mapboxgl.accessToken = vikingAccessToken;
        onMounted(() => {
            const map = new mapboxgl.Map({
                container: "map",
                style: vikingMapStyle,
                center: [-2.3018512, 51.2343397],
                zoom: 1,
            });

            map.on("load", () => {
                addShape({
                    map,
                    feature: getFeature(props.mapData),
                    name: "vikingLayer",
                    colour: "#00D4FF",
                    opacity: 0.3,
                });

                addShape({
                    map,
                    feature: getFeature(props.mapDataTwo),
                    name: "vikingLayerTwo",
                    colour: "#D400FF",
                    opacity: 0.2,
                });

                addPoints(map, getFeature(props.mapPoints), props.closestSample);

                watch(
                    () => props.closestSample,
                    () => {
                        map.removeLayer("population");
                        map.removeSource("points");

                        addPoints(map, getFeature(props.mapPoints), props.closestSample);
                    }
                );

                map.fitBounds([-9.479736, 21.534847, 150.449219, 58.539595], { padding: 60 });

                map.on("click", "population", (e) => {
                    // Copy coordinates array.
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const siteName = e.features[0].properties.name;

                    // Ensure that if the map is zoomed out such that multiple
                    // copies of the feature are visible, the popup appears
                    // over the copy being pointed to.
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }

                    new mapboxgl.Popup({ closeButton: false, closeOnClick: true })
                        .setLngLat(coordinates)
                        .setHTML(siteName)
                        .addTo(map);
                });
                // });
            });

            onUnmounted(() => {
                map.remove();
            });
        });
    },
    components: { MapLegend, ArchaicLegendMobile, ArchaicLegend },
};
</script>
