<script>
import { h } from "vue";
import { mapGetters } from "vuex";
import PaternalCardNotPurchased from "./PaternalCardNotPurchased.vue";
import PaternalCard from "@/components/Dashboard/Ancestry/PaternalCard/PaternalCard.vue";
import PaternalCardLoading from "./PaternalCardLoading.vue";
import PaternalCardFailed from "./PaternalCardFailed.vue";
import NoPaternalDataCard from "./NoPaternalDataCard.vue";

export default {
    render() {
        return h(
            "div",
            this.$slots.default({
                component: this.component,
                paternalData: this.paternalData,
            })
        );
    },
    data() {
        return {
            component: {},
            paternalData: {},
        };
    },
    created() {
        if (!this.kitTests.autosomal.purchased) {
            this.component = PaternalCardNotPurchased;
            return;
        }
        this.component = PaternalCardLoading;
        this.$nextTick(() => {
            this.$store.getters.ancestryData
                .then(data => {
                    if (data.paternal && data.paternal.group) {
                        this.paternalData = data.paternal;
                        this.component = PaternalCard;
                        return;
                    }
                    this.component = NoPaternalDataCard;
                })
                .catch(() => {
                    this.component = PaternalCardFailed;
                });
        });
    },
    computed: {
        ...mapGetters(["ancestryData", "kitTests"]),
    },
};
</script>
