<template>
    <div>
        <div v-html="formattedAddress" />
    </div>
</template>

<script>
import countries from "@/countries";
import { clone } from "lodash";

export default {
    props: {
        address: Object,
    },
    computed: {
        formattedAddress() {
            const address = clone(this.address);
            console.log(this.address);
            address.countryCode = countries.find(
                ({ code }) => code === this.address.countryCode
            ).name;
            return Object.values(address).filter(Boolean).join("<br>");
        },
    },
};
</script>
