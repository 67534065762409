<template>
    <router-link :to="link" class="block h-full bg-grey-50">
        <div
            class="text-grey-700 shadow-key hover:shadow-key-hover rounded-lg flex overflow-hidden h-full"
        >
            <div class="w-1/3 h-full flex">
                <img :src="wellbeingNotPurchased" class="m-auto" />
            </div>
            <div class="w-2/3 p-4 flex flex-col h-full">
                <div class="mb-4">
                    Unlock {{ title }} for more personalized suggestions toward your goals.
                </div>
                <router-link
                    :to="link"
                    class="flex items-center text-cobalt-500 mt-4 font-semibold mt-auto"
                >
                    <div class="mr-2 flex items-center">
                        <Feather type="unlock" class="h-4 w-4 flex-2 mr-2" />
                        <span class="text-cobalt-500">Unlock all traits</span>
                    </div>
                    <Feather type="arrow-right" class="h-4 w-4 flex-2 flex-none" />
                </router-link>
            </div>
        </div>
    </router-link>
</template>

<script>
import wellbeingNotPurchased from "@/assets/images/wellbeing/hellogeorge.png";

export default {
    props: {
        trait: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            wellbeingNotPurchased,
        };
    },
    computed: {
        title() {
            return this.trait.traitCmsData.title;
        },
        link() {
            return { name: "product", params: { slug: "Wellbeing-Upgrade" } };
        },
        hasImage() {
            return (
                this.trait.traitCmsData.phenotypes[0] &&
                this.trait.traitCmsData.phenotypes[0].keyTakeawayImage
            );
        },
        defaultKeyTakeawayImage() {
            return {
                backgroundImage: `url(${this.trait.traitCmsData.phenotypes[0].keyTakeawayImage[0].urlRaw}`,
            };
        },
    },
};
</script>
