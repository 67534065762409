<template>
    <div class="flex items-center justify-between w-full sm:w-1/2 px-12 space-x-2 py-2">
        <div>
            <div class="text-sm text-grey-700 capitalize">{{ region }}</div>
            <div class="text-xl font-semibold">{{ percentile }}</div>
        </div>

        <BarChart class="flex-shrink-0" :percentile="adjustedPercentile" />
    </div>
</template>

<script setup>
import BarChart from "@/components/charts/BarChart.vue";

const props = defineProps(["percentile", "region"]);

// If we leave it at 0 then we just display 10 grey bars, if we set it something > 0 then
// the first bar is displayed as dark blue. Something Gerard and I discussed and are going to try
const adjustedPercentile = props.percentile === 0 ? 1 : props.percentile;
</script>
