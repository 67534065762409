<template>
    <router-link
        :to="to"
        class="flex items-center text-cobalt-500"
        :class="{ 'text-grey-500 cursor-default': disabled }"
        :event="!disabled ? 'click' : ''"
    >
        <feather-wrapper :icon="icon" />
        <span class="ml-2 font-semibold">{{ linkText }}</span>
    </router-link>
</template>

<script>
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

export default {
    components: { FeatherWrapper },
    props: {
        to: Object,
        icon: String,
        linkText: String,
        disabled: Boolean,
    },
};
</script>
