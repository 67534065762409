<template>
    <BarChart class="h-12 //w-32" :chartData="testData" :options="options" />
</template>

<script>
import { defineComponent, ref } from "vue";
import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default defineComponent({
    name: "Home",
    components: { BarChart },
    props: {
        percentile: Number,
    },
    setup(props) {
        const backgroundColor = [
            "#ddd",
            "#ddd",
            "#ddd",
            "#ddd",
            "#ddd",
            "#ddd",
            "#ddd",
            "#ddd",
            "#ddd",
            "#ddd",
        ].map((item, index) =>
            index + 1 <= Math.ceil(props.percentile / 10)
                ? index + 1 === Math.ceil(props.percentile / 10)
                    ? "hsl(220, 100%, 60%)"
                    : "hsla(220, 100%, 60%, .20)"
                : item
        );

        const testData = {
            labels: ["", "", "", "", "", "", "", "", "", ""],
            datasets: [
                {
                    label: "",
                    data: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
                    barThickness: 20,
                    backgroundColor,
                },
            ],
        };

        const options = ref({
            plugins: {
                legend: {
                    display: false,
                },
            },
            maintainAspectRatio: false,
            responsive: false,
            events: [],
            animation: {
                duration: 0,
            },
            scales: {
                x: {
                    display: false,
                    grid: {
                        display: false,
                    },
                },
                y: {
                    display: false,
                    grid: {
                        display: false,
                    },
                },
            },
        });

        return { testData, options };
    },
});
</script>
