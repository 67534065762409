<template>
    <Card class="bg-white flex-1 flex flex-col">
        <div class="flex flex-col">
            <p class="my-0 mb-12">Find out about your maternal ancestry.</p>
            <!--                <p class="mb-0 mt-2 text-grey-500 text-2xl">Free</p>-->
            <Link
                :to="{ name: 'products' }"
                icon="shopping-cart"
                link-text="Upgrade to Starter"
                class="mt-auto"
            />
        </div>
    </Card>
</template>

<script>
import Card from "@/components/cards/Card.vue";
import Link from "@/components/utilities/DashboardLinkWithIcon.vue";

export default {
    components: { Card, Link },
};
</script>
