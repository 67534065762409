import Main from "@/views/signin/Main.vue";
import Login from "@/views/signin/Login.vue";
import SignUp from "@/views/signup/SignUp.vue";
import TermsConditions from "@/views/signup/TermsConditions.vue";
import VerificationEmailSent from "@/views/signup/VerificationEmailSent.vue";
import ChangeVerificationEmail from "@/views/signup/ChangeVerificationEmail.vue";
import SendResetEmail from "@/views/signin/reset-password/SendResetEmail.vue";
import ResetEmailSentSuccessfully from "@/views/signin/reset-password/ResetEmailSentSuccessfully.vue";
import CreateNewPassword from "@/views/signin/reset-password/CreateNewPassword.vue";
import CreatePasswordFromPartner from "@/views/signup/CreatePasswordFromPartner.vue";
import VerifyEmail from "@/views/signup/VerifyEmail.vue";
import CreatePasswordSuccess from "@/views/signup/CreatePasswordSuccess.vue";

import images from "@/assets/assetMaps";

export default [
    {
        path: "/",
        // name: "main",
        component: Main,
        children: [
            // {
            //     path: "/",
            //     redirect: "/signin",
            // },
            {
                path: "signin",
                name: "login",
                component: Login,
                meta: {
                    auth: false,
                    image: images.treeImage,
                },
            },
            {
                path: "/signup",
                name: "signup",
                component: SignUp,
                meta: {
                    image: images.peopleImage,
                    auth: false,
                },
            },
            {
                path: "/signup/view-terms",
                name: "/view-terms",
                component: TermsConditions,
                meta: {
                    image: images.peopleImage,
                    auth: false,
                },
            },
            {
                path: "/signup/verification-email-sent",
                name: "verification-email-sent",
                component: VerificationEmailSent,
                meta: {
                    image: images.peopleImage,
                },
            },
            {
                path: "/signin/validate-email/:token",
                name: "validate-email",
                component: VerifyEmail,
                meta: {
                    image: images.peopleImage,
                },
            },
            {
                path: "/signup/change-verification-email",
                name: "/change-verification-email",
                component: ChangeVerificationEmail,
                meta: {
                    image: images.peopleImage,
                },
            },
            {
                path: "signin/reset-password",
                name: "reset-password",
                component: SendResetEmail,
                meta: {
                    image: images.treeImage,
                    auth: false,
                },
            },
            {
                path: "signin/reset-email-sent",
                name: "reset-password-email-sent",
                component: ResetEmailSentSuccessfully,
                meta: {
                    image: images.treeImage,
                    auth: false,
                },
                beforeEnter(to, from, next) {
                    return to.query.email ? next() : next({ name: "reset-password" });
                },
            },
            {
                path: "signin/create-password/:token",
                name: "create-new-password",
                component: CreateNewPassword,
                meta: {
                    image: images.treeImage,
                    auth: false,
                },
            },
            {
                path: "signin/from-partner/success",
                name: "create-password-from-partner-success",
                component: CreatePasswordSuccess,
                meta: {
                    image: images.peopleImage,
                },
            },
            {
                path: "signin/from-partner/:partner/:token",
                name: "create-password-from-partner",
                component: CreatePasswordFromPartner,
                meta: {
                    image: images.peopleImage,
                },
            },
        ],
    },
];
