<template>
    <svg viewBox="0 0 513 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M240.986 4.19783C250.786 -1.40217 262.886 -1.40217 272.786 4.19783L444.586 102.298L448.886 103.998V104.898L496.786 132.298C509.386 139.498 515.586 154.298 511.886 168.298C508.186 182.298 495.486 192.098 480.986 192.098H32.8861C18.3861 192.098 5.68607 182.298 1.98607 168.298C-1.71393 154.298 4.48607 139.498 17.0861 132.298L64.8861 104.898V103.998L69.2861 102.398L240.986 4.19783ZM64.8861 223.998H99.9V321.999V416H181.9V321.999V223.998H217.9V321.999V416H291.9V321.999V223.998H329.9V321.999V416H415V321.999V223.998H448.886V420.3C449.486 420.6 450.086 421 450.686 421.4L498.686 453.4C510.386 461.2 515.686 475.8 511.586 489.3C507.486 502.8 494.986 512 480.886 512H32.8861C18.7861 512 6.38607 502.8 2.28607 489.3C-1.81393 475.8 3.38607 461.2 15.1861 453.4L63.1861 421.4C63.7861 421 64.3861 420.7 64.9861 420.3V223.998H64.8861Z"
            class="fill-current"
        />
        <path d="M61 158L260 42L459 158H61Z" fill="white" stroke="black" />
        <path d="M67 478L93 450H437L461 478H67Z" fill="white" stroke="black" />
    </svg>
</template>
