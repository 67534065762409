<template>
    <svg
        id="Capa_1"
        enable-background="new 0 0 512 512"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <g>
                <path
                    d="m307.262 254.146c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-96.183c-4.143 0-7.5 3.357-7.5 7.5v153.474c0 4.143 3.357 7.5 7.5 7.5h96.183c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-88.683v-61.736h87.868c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-87.868v-61.737h88.683z"
                />
                <path
                    d="m413.199 254.746c-9.009 0-17.674 1.485-25.787 4.186l-40.477-70.221c-2.069-3.59-6.656-4.821-10.243-2.753-3.589 2.068-4.821 6.655-2.753 10.243l39.655 68.796c-25.169 13.977-42.246 40.826-42.246 71.599 0 36.663 24.23 67.772 57.517 78.158-1.698 3.577-3.532 7.115-5.542 10.595-25.923 44.866-72.336 71.651-124.153 71.651s-98.229-26.785-124.152-71.65-25.952-98.452-.075-143.345l123.88-214.914c.055-.094.116-.2.348-.2s.294.107.348.201l56.942 98.786c2.068 3.589 6.654 4.819 10.243 2.753 3.589-2.068 4.821-6.655 2.753-10.243l-56.942-98.786c-2.783-4.829-7.772-7.711-13.345-7.711-5.573.001-10.562 2.884-13.343 7.711l-123.881 214.913c-28.583 49.589-28.552 108.781.084 158.34s79.904 79.145 137.141 79.145c57.236 0 108.504-29.587 137.14-79.146 2.827-4.893 5.36-9.883 7.624-14.946 3.043.345 6.131.539 9.265.539 15.953 0 31.431-4.61 44.758-13.333 3.466-2.269 4.437-6.917 2.168-10.383s-6.917-4.436-10.383-2.168c-10.879 7.12-23.516 10.884-36.543 10.884-36.861 0-66.851-29.989-66.851-66.851s29.989-66.851 66.851-66.851 66.851 29.989 66.851 66.851c0 11.567-3.02 22.985-8.732 33.019-2.05 3.6-.793 8.179 2.807 10.229 3.602 2.049 8.179.792 10.229-2.807 6.998-12.291 10.697-26.275 10.697-40.44-.002-45.133-36.72-81.851-81.853-81.851z"
                />
                <path
                    d="m444.066 311.868-35.006 35.007-23.38-23.379c-2.928-2.928-7.677-2.929-10.606.001-2.929 2.929-2.929 7.677.001 10.606l28.683 28.682c1.464 1.464 3.384 2.196 5.303 2.196s3.839-.732 5.304-2.196l40.31-40.31c2.929-2.93 2.929-7.678 0-10.607-2.932-2.928-7.68-2.928-10.609 0z"
                />
                <path
                    d="m497.59 142.318c-36.235-8.608-45.918-18.291-54.527-54.526-1.312-5.521-6.199-9.379-11.888-9.379-.001 0-.001 0-.002 0-5.688 0-10.577 3.855-11.89 9.377v.001c-8.608 36.236-18.29 45.919-54.527 54.527-5.521 1.313-9.379 6.202-9.378 11.892.001 5.688 3.857 10.577 9.379 11.888 36.235 8.608 45.918 18.291 54.526 54.528 1.313 5.521 6.202 9.378 11.892 9.377 5.688 0 10.576-3.857 11.888-9.378 8.609-36.236 18.292-45.919 54.526-54.527 5.522-1.311 9.379-6.199 9.38-11.888s-3.857-10.579-9.379-11.892zm-63.713-51.06c-.001.004-.002.007-.002.01 0-.002.001-.007.002-.01zm-2.704 115.603c-8.921-30.55-22.103-43.732-52.652-52.653 30.549-8.921 43.732-22.104 52.652-52.653 8.921 30.55 22.104 43.733 52.653 52.653-30.548 8.921-43.731 22.104-52.653 52.653z"
                />
                <path
                    d="m80.831 216.129c22.23 0 42.448-9.155 56.928-25.779 14.521-16.673 21.135-38.605 18.146-60.177-.567-4.103-4.356-6.971-8.458-6.399-4.104.568-6.968 4.355-6.399 8.458 2.394 17.273-2.928 34.865-14.6 48.266-11.588 13.305-27.788 20.632-45.616 20.632-21.928 0-41.568-11.335-52.539-30.321-10.972-18.987-10.983-41.665-.032-60.664l52.572-91.205 48.123 83.487c2.068 3.589 6.655 4.819 10.243 2.753 3.589-2.068 4.821-6.655 2.753-10.243l-51.53-89.396c-1.971-3.418-5.645-5.541-9.59-5.541s-7.62 2.123-9.592 5.542l-55.977 97.111c-13.658 23.694-13.643 51.979.04 75.658 13.684 23.681 38.18 37.818 65.528 37.818z"
                />
                <path
                    d="m325.88 54.367h14.161v14.16c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-14.16h14.16c4.143 0 7.5-3.357 7.5-7.5s-3.357-7.5-7.5-7.5h-14.16v-14.161c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v14.161h-14.161c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5z"
                />
                <path
                    d="m86.688 268.5h-18.5v-18.5c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v18.5h-18.5c-4.143 0-7.5 3.357-7.5 7.5s3.357 7.5 7.5 7.5h18.5v18.5c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-18.5h18.5c4.143 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5z"
                />
            </g>
        </g>
    </svg>
</template>
