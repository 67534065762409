<template>
    <TopImageCard :image="{ src: image }">
        <template v-slot:body>
            <h3 class="text-sm text-denim-700 mb-2">
                {{ type }}
            </h3>
            <div class="no-inner-p-margin" v-html="text"></div>
        </template>
        <template v-slot:footer>
            <router-link
                :to="{ name: 'wellbeing-report', params: { report: slug } }"
                class="flex items-center mt-auto text-cobalt-500"
            >
                <span class="font-semibold"> {{ name }} tips </span>
                <feather type="arrow-right" class="h-5 w-5 ml-2 flex-none" />
            </router-link>
        </template>
    </TopImageCard>
</template>

<script>
import TopImageCard from "@/components/cards/TopImageCard.vue";

export default {
    components: { TopImageCard },
    props: {
        type: String,
        image: String,
        text: String,
        slug: String,
        name: String,
    },
};
</script>
