<template>
    <div v-if="loadingStatus === 'loading'">
        <PageLoader class="mt-12">Loading</PageLoader>
    </div>
    <Overview v-else>
        <template #sidebar>
            <div class="fade-bottom" style="width: 511px; background: #fff">
                <img :src="archaicIntroduction.mainImage.urlRaw" alt="" class="fade-bottom" />
            </div>
            <div class="py-6 px-4 sm:px-6 lg:px-8 prose">
                <h1 class="text-xl">{{ archaicIntroduction.title }}</h1>
                <div v-html="archaicIntroduction.text" />
            </div>
        </template>
        <template #desktopContent>
            <ArchaicOverviewCard
                :linkTo="{ name: 'archaic-results' }"
                :title-text="archaicResultsCard.heading"
                :description-text="archaicResultsCard.text"
                link-text="Load your results"
                :image="{
                    src: archaicResultsCard.mainImage.urlRaw,
                    alt: 'Alt text',
                }"
            />

            <ArchaicOverviewCard
                :linkTo="{ name: 'archaic-map' }"
                :title-text="archaicMapCard.heading"
                :description-text="archaicMapCard.text"
                link-text="Load your results"
                :image="{
                    src: archaicMapCard.mainImage.urlRaw,
                    alt: 'Alt text',
                }"
            />

            <ArchaicOverviewCard
                :linkTo="{ name: 'archaic-traits' }"
                :title-text="archaicTraitsCard.heading"
                :description-text="archaicTraitsCard.text"
                link-text="Load your results"
                :image="{
                    src: archaicTraitsCard.mainImage.urlRaw,
                    alt: 'Alt text',
                }"
            />
        </template>
        <template #mobileContent>
            <div class="fade-bottom" style="background: #fff">
                <img :src="archaicIntroduction.mainImage.urlRaw" alt="" class="fade-bottom" />
            </div>
            <div class="py-6 px-4 sm:px-6 lg:px-8 prose m-auto">
                <h1 class="text-xl">{{ archaicIntroduction.heading }}</h1>
                <div v-html="archaicIntroduction.text" />
            </div>
        </template>
    </Overview>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
    getArchaicIntroduction,
    getArchaicMapCard,
    getArchaicResultsCard,
    getArchaicTraitsCard,
} from "@/services/archaic";
import PageLoader from "@/components/PageLoader.vue";
import Sidebar from "@/components/Sidebar.vue";
import ArchaicOverviewCard from "./ArchaicOverviewCard.vue";
import Overview from "@/components/Overview.vue";

const loadingStatus = ref("loading");
const archaicIntroduction = ref({});
const archaicResultsCard = ref({});
const archaicMapCard = ref({});
const archaicTraitsCard = ref({});

onMounted(async () => {
    const [
        archaicIntroductionResult,
        archaicMapCardResult,
        archaicResultCardResult,
        archaicTraitsCardResult,
    ] = await Promise.all([
        getArchaicIntroduction(),
        getArchaicMapCard(),
        getArchaicResultsCard(),
        getArchaicTraitsCard(),
    ]);

    archaicIntroduction.value = archaicIntroductionResult;
    archaicMapCard.value = archaicMapCardResult;
    archaicResultsCard.value = archaicResultCardResult;
    archaicTraitsCard.value = archaicTraitsCardResult;
    loadingStatus.value = "loaded";
});
</script>
