/*
 * Understanding this file
 * The key concept surrounding this file is that it categorizes steps in four key ways. Does the step
 * belong to a kit or a test, and is that kit or test an upload or a physical kit.
 * For any given state that a kit, or a test, could be in, we define a series steps in a simple
 * array. Each state that the kit or test could be in will have any number of incomplete, active,
 * and completed steps. Optionally, it may also have any number of failed steps.
 * The steps in this timeline associated with the current step the kit or test is at is then
 * "hydrated" using the decorated*Steps at the top of the file. This is what provides the human
 * friendly name of the step, a description, and a duration.
 * */

const decoratedLDSteps = {
    shipped: {
        name: "Activated",
        description: "Your kit has been successfully activated",
        duration: "-",
    },
    enRoute: {
        name: "En route",
        description: "Your sample is on its way to us",
        duration: "1-3 weeks",
    },
    returned: {
        name: "Received",
        description: "Your sample has been received at the lab",
        duration: "1 week",
    },
    genotyping: {
        name: "Genotyping",
        description: "The lab is sequencing your DNA",
        duration: "3-4 weeks",
    },
    testing: {
        name: "Testing",
        description: "We have received your sequenced DNA from the lab and are now analysing it",
        duration: "72 hours",
    },
    completed: {
        name: "Completed",
        description: "Your results are complete and ready to view",
        duration: "-",
    },
    updating: {
        name: "Updating",
        description: "Your results are updating",
        duration: "48 hours",
    },
    requestedUpdate: {
        name: "Requested update",
        description: "You requested your results update",
        duration: "-",
    },
    updateComplete: {
        name: "Complete",
        description: "Your results are updated and ready to view",
        duration: "-",
    },
    yNotAvailable: {
        name: "Not available",
        description: "Paternal haplogroup cannot be provided",
        duration: "-",
    },
    cancelled: {
        name: "Cancelled",
        description: "Your kit has been cancelled",
        duration: "-",
    },
};

const decoratedLTSteps = {
    uploaded: {
        name: "Uploaded",
        description: "Your file has been successfully uploaded",
        duration: "-",
    },
    testing: {
        name: "Testing",
        description: "Analysing your uploaded data",
        duration: "48 hours",
    },
    completed: {
        name: "Completed",
        description: "Your results are ready to view",
        duration: "-",
    },
};

const decoratedUpgradeSteps = {
    notAdded: {
        name: "Not added",
        description: "You need to add this test",
        duration: "-",
    },
    pending: {
        name: "Waiting",
        description: "Your additional test has been selected",
        duration: "-",
    },
    processing: {
        name: "Processing",
        description: "Your additional test is being processed",
        duration: "48 hours",
    },
    updating: {
        name: "Updating",
        description: "Your additional test is updating",
        duration: "48 hours",
    },
    completed: {
        name: "Completed",
        description: "Your additional test has been completed",
        duration: "-",
    },
    error: {
        name: "Failed",
        description: "Your additional test failed to process properly",
        duration: "-",
    },
};

const decoratedFNSteps = {
    notOptedIn: {
        name: "Not opted in",
        description: "You need to opt in to be included in our family networks service",
        duration: "-",
    },
    optedIn: {
        name: "Opted in",
        description: "You have opted in to the family networks service",
        duration: "-",
    },
    pending: {
        name: "Pending",
        description: "Waiting for one of our fortnightly runs of family networks",
        duration: "Up to two weeks",
    },
    findingMatches: {
        name: "Finding matches",
        description: "Comparing your DNA against everyone in our database",
        duration: "2 hours",
    },
    matching: {
        name: "Matching",
        description: "Comparing your DNA against everyone in our database",
        duration: "2 hours",
    },
    completed: {
        name: "Completed",
        description: "Check out your matches and wait for the next run of family networks",
        duration: "-",
    },
};

const decoratedVitaminSteps = {
    added: {
        name: "Sign up",
        description: "Sign up with vitamin lab",
        duration: "-",
    },
    expiringSoon: {
        name: "Expiring soon",
        description: "Your vitamin subscription is expiring soon",
    },
    expired: {
        name: "Expired",
        description: "Your vitamin subscription has expired",
    },
};

const decoratedHealthSteps = {
    create: {
        name: "Create",
        description: "Create your health profile",
        duration: "10 minutes",
    },
};

function d(step) {
    return decoratedLDSteps[step];
}

function dLT(step) {
    return decoratedLTSteps[step];
}

function du(step) {
    return decoratedUpgradeSteps[step];
}

function dFN(step) {
    return decoratedFNSteps[step];
}

function dVit(step) {
    return decoratedVitaminSteps[step];
}

function dHealth(step) {
    return decoratedHealthSteps[step];
}

const kitLT = {
    testing: {
        completeSteps: ["uploaded"].map(dLT),
        activeSteps: ["testing"].map(dLT),
        incompleteSteps: ["completed"].map(dLT),
    },
    completed: {
        completeSteps: ["uploaded", "testing", "completed"].map(dLT),
        activeSteps: [],
        incompleteSteps: [],
    },
    transfer_qc_failed: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: [],
        failedSteps: ["testing"].map(dLT),
    },
    failed: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: [],
        failedSteps: ["testing"].map(dLT),
    },
    testing_failed: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: [],
        failedSteps: ["testing"].map(dLT),
    },
    genotyping: {}, // we should probably fix this one
    cancelled: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: [],
        failedSteps: ["testing"].map(dLT),
    },
};

const kitLD = {
    shipped: {
        completeSteps: ["shipped"].map(d),
        activeSteps: ["enRoute"].map(d),
        incompleteSteps: ["returned", "genotyping", "testing", "completed"].map(d),
    },
    returned: {
        completeSteps: ["shipped", "enRoute", "returned"].map(d),
        activeSteps: ["genotyping"].map(d),
        incompleteSteps: ["testing", "completed"].map(d),
    },
    genotyping: {
        completeSteps: ["shipped", "enRoute", "returned"].map(d),
        activeSteps: ["genotyping"].map(d),
        incompleteSteps: ["testing", "completed"].map(d),
    },
    testing: {
        completeSteps: ["shipped", "enRoute", "returned", "genotyping"].map(d),
        activeSteps: ["testing"].map(d),
        incompleteSteps: ["completed"].map(d),
    },
    completed: {
        completeSteps: ["shipped", "enRoute", "returned", "genotyping", "testing", "completed"].map(
            d
        ),
        activeSteps: [],
        incompleteSteps: [],
    },
    cancelled: {
        completeSteps: [],
        activeSteps: [],
        incompleteSteps: [],
        failedSteps: ["cancelled"].map(d),
    },
    testing_failed: {
        completeSteps: ["shipped", "enRoute", "returned"].map(d),
        activeSteps: [],
        incompleteSteps: [],
        failedSteps: ["testing"].map(d),
    },
};

const testLT = {
    // upgrades: {
    //     pending: {
    //         completeSteps: [],
    //         activeSteps: ['pending'].map(du),
    //         incompleteSteps: ['testing', 'completed'].map(du),
    //     },
    //     processing: {
    //         completeSteps: ['uploaded'].map(dLT),
    //         activeSteps: ['testing'].map(dLT),
    //         incompleteSteps: ['completed'].map(dLT),
    //     },
    //     completed: {
    //         completeSteps: ['pending', 'completed'].map(du),
    //         activeSteps: [],
    //         incompleteSteps: [],
    //     },
    //     failed: {
    //         completeSteps: ['pending', 'completed'].map(du),
    //         activeSteps: [],
    //         incompleteSteps: [],
    //     },
    // },
    other: {
        familyMatching: {
            notOptedIn: {
                incompleteSteps: ["notOptedIn", "pending", "findingMatches", "completed"].map(dFN),
                completeSteps: [],
                activeSteps: [],
            },
            failed: {
                completeSteps: ["optedIn"].map(dFN),
                failedSteps: ["matching"].map(dFN),
            },
            pending: {
                completeSteps: ["optedIn"].map(dFN),
                activeSteps: ["pending"].map(dFN),
                incompleteSteps: ["findingMatches", "completed"].map(dFN),
            },
            processing: {
                completeSteps: ["optedIn"].map(dFN),
                activeSteps: ["findingMatches"].map(dFN),
                incompleteSteps: ["completed"].map(dFN),
            },
            completed: {
                completeSteps: ["optedIn", "pending", "findingMatches", "completed"].map(dFN),
                activeSteps: [],
                incompleteSteps: [],
            },
        },
    },
    pending: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: ["testing", "completed"].map(dLT),
        activeSteps: [],
    },
    processing: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: ["completed"].map(dLT),
        activeSteps: ["testing"].map(dLT),
    },
    completed: {
        completeSteps: ["uploaded", "completed", "testing"].map(dLT),
        incompleteSteps: [],
        activeSteps: [],
    },
    updating: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: ["completed"].map(dLT),
        activeSteps: ["updating"].map(dLT),
    },
    failed: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: [],
        activeSteps: [],
        failedSteps: ["testing"].map(dLT),
    },
    error: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: [],
        activeSteps: [],
        failedSteps: ["testing"].map(dLT),
    },
    noData: {
        completeSteps: ["uploaded"].map(dLT),
        incompleteSteps: [],
        activeSteps: [],
        failedSteps: ["testing"].map(dLT),
    },
};

const testLD = {
    other: {
        yNotAvailable: {
            completeSteps: ["shipped", "returned", "testing", "completed"].map(d),
            activeSteps: [],
            incompleteSteps: ["yNotAvailable"].map(d),
            useIncomplete: true,
        },
        familyMatching: {
            notOptedIn: {
                incompleteSteps: ["notOptedIn", "pending", "findingMatches", "completed"].map(dFN),
                completeSteps: [],
                activeSteps: [],
            },
            pending: {
                completeSteps: ["optedIn"].map(dFN),
                activeSteps: ["pending"].map(dFN),
                incompleteSteps: ["findingMatches", "completed"].map(dFN),
            },
            processing: {
                completeSteps: ["optedIn"].map(dFN),
                activeSteps: ["findingMatches"].map(dFN),
                incompleteSteps: ["completed"].map(dFN),
            },
            completed: {
                completeSteps: ["optedIn", "pending", "findingMatches", "completed"].map(dFN),
                activeSteps: [],
                incompleteSteps: [],
            },
        },
    },
    testing: {
        completeSteps: ["shipped", "enRoute", "returned"].map(d),
        activeSteps: ["testing"].map(d),
        incompleteSteps: ["completed"].map(d),
    },
    updating: {
        completeSteps: ["requestedUpdate"].map(d),
        activeSteps: ["updating"].map(d),
        incompleteSteps: ["updateComplete"].map(d),
    },
    processing: {
        completeSteps: ["shipped", "enRoute", "returned"].map(d),
        activeSteps: ["testing"].map(d),
        incompleteSteps: ["completed"].map(d),
    },
    pending: {
        completeSteps: ["shipped", "returned"].map(d),
        activeSteps: [],
        incompleteSteps: ["testing", "completed"].map(d),
    },
    completed: {
        completeSteps: ["shipped", "enRoute", "returned", "testing", "completed"].map(d),
        activeSteps: [],
        incompleteSteps: [],
    },
    error: {
        completeSteps: ["shipped", "enRoute", "returned"].map(d),
        activeSteps: [],
        incompleteSteps: [],
        failedSteps: ["testing"].map(d),
    },
    failed: {
        completeSteps: ["shipped", "enRoute", "returned"].map(d),
        activeSteps: [],
        incompleteSteps: [],
        failedSteps: ["testing"].map(d),
    },
    noData: {
        completeSteps: ["shipped", "enRoute", "returned"].map(d),
        activeSteps: [],
        incompleteSteps: [],
        failedSteps: ["testing"].map(d),
    },
};

export const upgradeSteps = {
    notAdded: {
        incompleteSteps: ["notAdded", "processing", "completed"].map(du),
    },
    pending: {
        completeSteps: ["pending"].map(du),
        activeSteps: [],
        incompleteSteps: ["processing", "completed"].map(du),
    },
    processing: {
        completeSteps: ["pending"].map(du),
        activeSteps: ["processing"].map(du),
        incompleteSteps: ["completed"].map(du),
    },
    updating: {
        completeSteps: ["pending"].map(du),
        activeSteps: ["updating"].map(du),
        incompleteSteps: ["completed"].map(du),
    },
    completed: {
        completeSteps: ["pending", "processing", "completed"].map(du),
        activeSteps: [],
        incompleteSteps: [],
    },
    failed: {
        completeSteps: ["pending"].map(du),
        failedSteps: ["processing"].map(du),
    },
    error: {
        completeSteps: ["pending"].map(du),
        failedSteps: ["processing"].map(du),
    },
    noData: {
        completeSteps: ["pending"].map(du),
        failedSteps: ["processing"].map(du),
    },
};

export const kitSteps = {
    lt: kitLT,
    ld: kitLD,
};

export const kitTestSteps = {
    lt: testLT,
    ld: testLD,
};

export const vitaminSubscription = {
    notAdded: {
        completeSteps: [],
        activeSteps: [],
        incompleteSteps: ["added"].map(dVit),
    },
    active: {
        completeSteps: ["added"].map(dVit),
        activeSteps: [],
        incompleteSteps: ["expiringSoon", "expired"].map(dVit),
    },
    expiringSoon: {
        completeSteps: ["added"].map(dVit),
        activeSteps: ["expiringSoon"].map(dVit),
        incompleteSteps: ["expired"].map(dVit),
    },
    expired: {
        completeSteps: ["added", "expiringSoon"].map(dVit),
        failedSteps: ["expired"].map(dVit),
        activeSteps: [],
        incompleteSteps: [],
    },
};

export const healthProfile = {
    notCreated: {
        completeSteps: [],
        incompleteSteps: ["create"].map(dHealth),
    },
    created: {
        completeSteps: ["create"].map(dHealth),
        incompleteSteps: [],
    },
};
