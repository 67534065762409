<template>
    <ThePage class="fn max-w-6xl">
        <DashboardLinkWithIcon
            :to="{ name: 'list-view', query: { loadPrevious: true } }"
            icon="chevron-left"
            link-text="Back to list"
        />
        <div class="h-6" />
        <template v-if="currentMatchState.matches('loading')"> Match details are loading </template>
        <template v-else-if="currentMatchState.matches('errorLoadingMatchDetails')">
            We could not load your match details
        </template>
        <template v-else-if="currentMatchState.matches('showingMatchDetails')">
            <div class="text-center mt-2 mb-6">
                <h1 class="text-2xl font-semibold">
                    {{ match.username || match.name }}
                </h1>
                <p class="mt-1 text-gray-500 leading-tight">{{ countryName }}</p>
            </div>

            <div class="pt-6">
                <div class="max-md:space-y-6 md:flex items-center">
                    <div class="relative mr-6 inline-block">
                        <img :src="match.photo || defaultAvatar" class="w-32 inline rounded-full" />
                        <span
                            :title="countryName"
                            class="rounded-full w-8 h-8 flag-icon-squared absolute bottom-0 right-0 border-2 border-white"
                            :class="[`flag-icon-${match.countryOfBirth.toLowerCase()}`]"
                            style="
                                background-size: contain;
                                background-position: 50%;
                                background-position-x: 50%;
                                background-position-y: center;
                                background-repeat: no-repeat;
                            "
                        />
                    </div>
                    <div class="flex">
                        <div class="space-y-3">
                            <div>
                                <div class="text-lg leading-tight">
                                    {{ name }}
                                </div>
                                <div class="text-sm leading-tight text-grey-600">
                                    {{ match.dnaSharedPercentage }} DNA shared ({{
                                        match.dnaShared
                                    }}
                                    cM)
                                </div>
                            </div>

                            <div v-if="sharedMatchesLoadingStatus === 'loaded'">
                                <div class="text-lg leading-tight">
                                    {{ totalCount }}
                                    shared
                                    {{ totalCount === 1 ? "match" : "matches" }}
                                </div>
                                <a
                                    v-if="totalCount > 0"
                                    href="#sharedMatches"
                                    class="text-sm leading-tight flex font-semibold text-cobalt-500 items-center"
                                >
                                    Click here to view
                                    <Feather type="chevron-right" class="h-5 w-5" />
                                </a>
                            </div>

                            <div>
                                <div class="text-lg leading-tight">Matchbox groups</div>
                                <div class="flex items-center gap-2 mt-1">
                                    <div
                                        v-for="group in matchingGroups"
                                        class="rounded-full h-4 w-4"
                                        :style="{ backgroundColor: group.colour }"
                                    />
                                    <button
                                        @click="showModal = true"
                                        class="text-cobalt-500 font-semibold text-sm"
                                    >
                                        {{ matchingGroups.length ? "Edit" : "Add to group" }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="self-start ml-auto flex gap-4">
                        <div
                            v-if="match.mtHaplogroup"
                            class="shadow rounded-lg bg-white h-full flex flex-col p-6 border-t-4 border-rose-500"
                        >
                            <div v-if="match.mtHaplogroup.haplogroup">
                                <div class="text-sm text-gray-600">Maternal haplogroup</div>
                                <div class="text-rose-500 text-2xl font-semibold">
                                    {{ match.mtHaplogroup.haplogroup }}
                                </div>
                            </div>
                            <div v-if="match.mtHaplogroup.subclade" class="mt-4">
                                <div class="text-sm text-gray-600">Maternal haplotype</div>
                                <div class="text-rose-500 text-2xl font-semibold">
                                    {{ match.mtHaplogroup.subclade }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="match.yHaplogroup"
                            class="shadow rounded-lg bg-white h-full flex flex-col p-6 border-t-4 border-male-500"
                        >
                            <div v-if="match.yHaplogroup.haplogroup">
                                <div class="text-sm text-gray-600">Paternal haplogroup</div>
                                <div class="text-male-500 text-2xl font-semibold">
                                    {{ match.yHaplogroup.haplogroup }}
                                </div>
                            </div>
                            <div v-if="match.yHaplogroup.subclade" class="mt-4">
                                <div class="text-sm text-gray-600">Paternal haplotype</div>
                                <div class="text-male-500 text-2xl font-semibold">
                                    {{ match.yHaplogroup.subclade }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-12" />
            <div v-if="match.bio">
                <div v-if="match.bio" v-html="match.bio" class="mb-8 max-w-2xl" />
                <div class="md:flex">
                    <div v-if="match.yHaplogroup" class="mr-12 mb-4 md:mb-0">
                        Fatherline haplogroup <strong>{{ match.yHaplogroup.haplogroup }}</strong>
                        <br />
                        Fatherline subclade <strong>{{ match.yHaplogroup.subclade }}</strong>
                    </div>
                    <div v-if="match.mtHaplogroup">
                        Motherline haplogroup <strong>{{ match.mtHaplogroup.haplogroup }}</strong>
                        <br />
                        Motherline subclade <strong>{{ match.mtHaplogroup.subclade }}</strong>
                    </div>
                </div>
                <div class="h-12" />
            </div>
            <div class="flex flex-col lg:flex-row items-center lg:items-start">
                <div class="w-full shadow-key rounded flex flex-col overflow-hidden">
                    <nav class="flex sm:flex-row flex-col w-full shadow-key">
                        <router-link
                            v-slot="{ isActive }"
                            :to="navigateTo('match-conversation')"
                            class="sm:w-1/4 w-full text-center text-grey-500 border-transparent border-b-4 py-4 font-semibold text-sm hover:text-grey-700"
                        >
                            <span :class="isActive ? 'text-cobalt-500' : 'text-grey-600'"
                                >Conversation</span
                            >
                        </router-link>
                        <router-link
                            v-slot="{ isActive }"
                            :to="navigateTo('match-relationship')"
                            class="sm:w-1/4 w-full text-center text-grey-500 border-transparent border-b-4 py-4 font-semibold text-sm hover:text-grey-700"
                        >
                            <span :class="isActive ? 'text-cobalt-500' : 'text-grey-600'"
                                >Relationship</span
                            >
                        </router-link>
                        <router-link
                            v-slot="{ isActive }"
                            :to="navigateTo('match-notes')"
                            class="sm:w-1/4 w-full text-center text-grey-500 border-transparent border-b-4 py-4 font-semibold text-sm hover:text-grey-700"
                        >
                            <span :class="isActive ? 'text-cobalt-500' : 'text-grey-600'"
                                >Notes</span
                            >
                        </router-link>
                        <router-link
                            v-slot="{ isActive }"
                            :to="navigateTo('map')"
                            class="sm:w-1/4 w-full text-center text-grey-500 border-transparent border-b-4 py-4 font-semibold text-sm hover:text-grey-700"
                        >
                            <div :class="isActive ? 'text-cobalt-500' : 'text-grey-600'">
                                Shared map
                            </div>
                        </router-link>
                        <router-link
                            v-slot="{ isActive }"
                            :to="navigateTo('chromosome-browser')"
                            class="sm:w-1/4 w-full text-center text-grey-500 border-transparent border-b-4 py-4 font-semibold text-sm hover:text-grey-700"
                        >
                            <div :class="isActive ? 'text-cobalt-500' : 'text-grey-600'">
                                Shared DNA
                            </div>
                        </router-link>
                    </nav>
                    <router-view :match="match" />
                </div>
            </div>
            <div class="mt-2">
                <BlockButton
                    :block-state="state"
                    @block-conversation="send({ type: 'BLOCK' })"
                    @unblock-conversation="send({ type: 'UNBLOCK' })"
                />
            </div>
            <div class="mt-10">
                <div class="flex justify-between">
                    <h2 class="text-base text-grey-600 font-normal">
                        Relatives {{ combinedNames }} have in common
                    </h2>
                    <MatchboxBulkAddModal v-if="false" :shared-matches-count="totalCount" />
                </div>

                <SharedMatches :match="match" />
            </div>
        </template>
    </ThePage>
    <MatchboxModal :show-modal="showModal" :match="match" @closed="showModal = false" />
</template>

<script>
import { Machine, interpret, assign } from "xstate";
import { getMatchDetails } from "@/services/family-matching";
import { getRelationshipDetailsFromMatch } from "@/services/relationship";
import defaultAvatar from "@/assets/images/avatar-default.png";
import ThePage from "@/components/Base/ThePage.vue";
import DashboardLinkWithIcon from "@/components/utilities/DashboardLinkWithIcon.vue";
import SharedMatches from "@/views/FamilyNetworks/FamilyMatching/DetailsView/SharedMatches.vue";
import countries from "@/countries";
import BlockButton from "@/views/FamilyNetworks/FamilyMatching/DetailsView/BlockButton.vue";
import { state, send } from "@/views/FamilyNetworks/machines/messagingMachine.js";
import MatchboxModal from "@/components/FamilyMatching/Matchbox/MatchboxModal.vue";
import { useMatchGroupStore } from "@/store/matchGroups";
import { totalCount, sharedMatchesLoadingStatus } from "@/composables/useSharedMatches";
import MatchboxBulkAddModal from "@/components/MatchboxBulkAddModal.vue";

export default {
    async created() {
        const matchMachine = Machine(
            {
                id: "match-machine",
                initial: "loading",
                context: {
                    currentBarcode: this.$store.getters.profile.barcode,
                    matchBarcode: this.$route.params.barcode,
                    match: {},
                },
                states: {
                    loading: {
                        invoke: {
                            src: "getMatchDetails",
                            onDone: {
                                actions: "cacheMatchDetails",
                                target: "showingMatchDetails",
                            },
                            onError: "errorLoadingMatchDetails",
                        },
                    },
                    showingMatchDetails: {
                        on: {
                            RELOAD: {
                                target: "loading",
                                actions: "setMatchBarcode",
                            },
                        },
                    },
                    errorLoadingMatchDetails: {
                        on: {
                            RELOAD: {
                                target: "loading",
                                actions: "setMatchBarcode",
                            },
                        },
                    },
                },
            },
            {
                services: {
                    getMatchDetails: (context) =>
                        new Promise(async (resolve, reject) => {
                            try {
                                resolve(
                                    await getMatchDetails(
                                        context.currentBarcode,
                                        context.matchBarcode
                                    )
                                );
                            } catch (e) {
                                reject(new Error(e.message));
                            }
                        }),
                },
                actions: {
                    cacheMatchDetails: assign({
                        match: (context, event) => event.data,
                    }),
                    setMatchBarcode: assign({
                        matchBarcode: (context, event) => event.data,
                    }),
                },
            }
        );
        this.matchService = interpret(matchMachine);
        this.currentMatchState = matchMachine.initialState;

        this.matchService
            .onTransition(async (state) => {
                this.currentMatchState = state;
            })
            .start();
    },
    data() {
        return {
            blockStatus: "",
            matchService: undefined,
            currentMatchState: undefined,
            channel: undefined,
            defaultAvatar,
            state,
            send,
            showModal: false,
            showBulkModal: false,
            sharedMatchesLoadingStatus,
        };
    },
    computed: {
        match() {
            return this.currentMatchState.context.match;
        },
        combinedNames() {
            return `${this.$store.getters.firstName} and ${this.match.username || this.match.name}`;
        },
        name() {
            return getRelationshipDetailsFromMatch(this.match).name;
        },
        countryName() {
            const profilesCountry = countries.find(
                (country) => country.code === this.match.countryOfBirth
            );
            return profilesCountry ? profilesCountry.name : "";
        },
        matchingGroups() {
            const matchGroupStore = useMatchGroupStore();

            return matchGroupStore.matchGroups.filter((group) => {
                return !!group.members.map((member) => member.barcode).includes(this.match.barcode);
            });
        },
        totalCount() {
            return totalCount.value;
        },
    },
    methods: {
        navigateTo(location) {
            return {
                name: location,
                params: {
                    barcode: this.match.barcode,
                },
            };
        },
    },
    watch: {
        $route(value) {
            if (
                value.params.barcode &&
                value.params.barcode !== this.currentMatchState.context.matchBarcode
            ) {
                this.matchService.send("RELOAD", { data: value.params.barcode });
            }
        },
    },
    components: {
        MatchboxBulkAddModal,
        MatchboxModal,
        BlockButton,
        DashboardLinkWithIcon,
        ThePage,
        SharedMatches,
    },
};
</script>
