<template>
    <Card class="bg-white h-full">
        <div>
            <Status :circle-bg="circleClass" class="mb-2">Kit Status</Status>
            <p class="mt-2">
                {{ cardDescription }}
            </p>
            <p class="text-grey-500 text-xs">{{ cardSecondaryText }}</p>
            <button @click="handleClick" class="flex items-center mt-6 text-cobalt-500">
                <feather-wrapper :icon="buttonIcon" class="h-5 w-5 mr-2"></feather-wrapper>
                <span class="font-semibold">{{ buttonText }}</span>
            </button>
            <modal
                name="how-to-swab-video"
                height="auto"
                :adaptive="true"
                width="90%"
                :max-width="576"
            >
                <div style="padding:56.25% 0 0 0;position:relative;">
                    <iframe
                        src="https://player.vimeo.com/video/359544490"
                        style="position:absolute;top:0;left:0;width:100%;height:100%;"
                        frameborder="0"
                        allow="autoplay; fullscreen"
                        allowfullscreen
                    />
                </div>
            </modal>
        </div>
    </Card>
</template>

<script>
import Card from "@/components/cards/Card.vue";
import Status from "@/components/utilities/StatusIndicator.vue";
import Link from "@/components/utilities/DashboardLinkWithIcon.vue";
import FeatherWrapper from "@/components/feather/FeatherWrapper.vue";

export default {
    components: { FeatherWrapper, Card, Status },
    props: {
        circleClass: String,
        cardDescription: String,
        cardSecondaryText: String,
        buttonHandler: Function,
        buttonText: String,
        buttonIcon: String,
    },
    methods: {
        handleClick() {
            this.buttonHandler(this);
        },
    },
};
</script>
